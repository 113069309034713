import { LoadingOutlined, PaperClipOutlined, WarningOutlined, EyeOutlined } from '@ant-design/icons';
import { Form, Button, Modal } from 'antd';
import React, { useState } from 'react';
import { sendTestMail } from '../../../utils/service';
import { showToastMessage } from '../../../utils/Toast';
import isValidForm from '../shared-functions/inputValidator';
import { onFileDropHandler } from '../shared-functions/onFileDropHandler';
import { onSystemFileSelected } from '../shared-functions/onSystemFileSelected';
import { ReactComponent as AttachIcon } from './assets/attachIcon.svg';
// import { ReactComponent as CampaignIntervalIcon } from './assets/camp-interval-icon.svg';
import { errorMessage } from '../../../utils/getErrors';
import { useEffect } from 'react';
import { growthBookId, intervals } from '../../../utils/constants';

import convertSecondsToNearestDur from '../../../utils/convertSecondsToNearestDuration';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { useRef } from 'react';

// const TestMailDropdown = ({ mail, setImLoading, campaignId, setMail, emailIntervalForm, testMail }) => {
//   const { Option } = Select;
//   const [testMailForm] = Form.useForm();

//   return (
//     <div className="emmaccen" onClick={(e) => e.preventDefault()}>
//       <div className="p-5" style={{ maxWidth: '300px' }}>
//         <p className="text-sm fade5">Campaign interval (optional)</p>
//         <p className="text-xs fade2">Schedule a time to send this email after the previous one.</p>
//         <Form
//           name="basic"
//           form={testMailForm}
//           onFinish={({ number, seconds }) => testMail({ mail, setImLoading, campaignId })}
//           onFinishFailed={() => null}
//           autoComplete="off"
//           size="large"
//         >
//           <div className="flex-space-btw mt-5">
//             <Form.Item name="number" className="sm:w-full md:w-full lg:w-1/4 mr-4">
//               <Input
//                 name="number"
//                 onChange={(e) => setMail({ ...mail, [e.target.name]: e.target.value })}
//                 placeholder="Number"
//                 type="number"
//                 min={1}
//               />
//             </Form.Item>
//             <Form.Item name="seconds" className="w-full">
//               <Select
//                 showSearch
//                 placeholder="Select Interval"
//                 optionFilterProp="children"
//                 filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
//                 onChange={(value) => setMail({ ...mail, seconds: value })}
//               >
//                 <Option value={60}>Minute(s)</Option>
//                 <Option value={60 * 60}>Hour(s)</Option>
//                 <Option value={24 * 60 * 60}>Day(s)</Option>
//                 <Option value={7 * 24 * 60 * 60}>Week(s)</Option>
//                 <Option value={30 * 24 * 60 * 60}>Month(s)</Option>
//                 <Option value={365 * 24 * 60 * 60}>Year(s)</Option>
//               </Select>
//             </Form.Item>
//           </div>
//         </Form>
//       </div>
//     </div>
//   );
// };

export const EditorActionsTab = ({
  imLoading,
  filesProps,
  handleEmailCreation,
  setImLoading,
  campaignId,
  setMail,
  mail,
  saveDraft,
  deleteFunction,
}) => {
  // Interval Start
  const [emailIntervalForm] = Form.useForm();
  const [intervalDeclaration, setIntervalDeclaration] = useState(null);
  const disableEmail = useFeatureIsOn(growthBookId.disableEmail);
  /* eslint-disable */

  const fileInputRef = useRef(null);

  useEffect(() => {
    if (mail?.emailInterval !== undefined && intervalDeclaration === null) {
      const { emailInterval } = mail;
      if (emailInterval) {
        const interval = convertSecondsToNearestDur(emailInterval);
        emailIntervalForm.setFieldsValue({
          ['number']: interval?.int,
          ['seconds']: intervals[interval?.unit],
        });
        setIntervalDeclaration(interval);
      }
    }
  }, [mail]);

  // Interval End

  const [isAttachmentModalVisible, setIsAttachmentModalVisible] = useState(false);

  const testMail = ({ mail, setImLoading, campaignId, files }) => {
    if (isValidForm([mail.body, mail.subject])) {
      setImLoading({ id: 'testMail' });
      const awaitimgUpload = files.filter((item) => item.status !== 'UPLOADED');
      if (awaitimgUpload.length > 0) {
        showToastMessage({
          type: 'warning',
          title: `${awaitimgUpload.length} file(s) in progress`,
          description: 'Please wait while all items in the list are complete',
          placement: 'topRight',
        });
        setTimeout(() => {
          setIsAttachmentModalVisible(true);
        }, 200);
        return;
      }
      const attachments = files.filter((file) => file.status !== 'ERROR').map((file) => file);
      sendTestMail({ ...mail, attachments }, campaignId)
        .then((mail) => {
          showToastMessage({
            type: 'success',
            title: 'Test Mail',
            description: mail?.data?.data?.message || 'Test mail has been sent to your registered email address',
          });
        })
        .catch((e) => {
          const errMsg = errorMessage(e);
          showToastMessage({
            type: 'error',
            title: 'Error',
            description: errMsg,
          });
        })
        .finally(() => setImLoading({ id: '' }));
    } else {
      setImLoading({ id: '' });
      showToastMessage({
        type: 'warning',
        title: 'Required Fields',
        description: 'Please, provide both email-body and subject',
      });
    }
  };

  const [files, setFiles, setIsUploading] = filesProps;

  // console.log({ files });

  const LoadFilesUi = () =>
    files?.map((fileData, index) => {
      const { src, file, id, status, filename } = fileData;
      return (
        <div
          className={fileData.status + ' flex justify-between items-center h-10 w-full rounded borderShade1 p-6'}
          key={index}
          data-test={`attachment-${index}`}
        >
          <div title={filename} className="flex">
            <PaperClipOutlined className="text-xl" />
            <p className="mx-3">{filename.slice(0, 15).concat('...')}</p>
            {fileData?.status === 'WAITING' ? (
              <div className="flex items-center" data-test={`uploading-attachment-${index}`}>
                <LoadingOutlined />
              </div>
            ) : (
              fileData?.status === 'ERROR' && (
                <div className="flex items-center">
                  <WarningOutlined className="text-xl" />
                </div>
              )
            )}
          </div>
          <div className="flex items-center">
            {fileData?.status === 'ERROR' && (
              <p
                // onClick={() => setIsUploading(true)}
                className="mr-3 pointer underline"
              >
                Retry
              </p>
            )}
            {<EyeOutlined className="text-xl mr-3" onClick={() => window.open(fileData.src)} />}
            <Button loading={imLoading?.id === id} onClick={() => deleteFunction(fileData)} danger size="small">
              Remove
            </Button>
          </div>
        </div>
      );
    });
  const onDragHandler = (ev) => {
    ev.preventDefault();
  };

  const awaitimgUpload = files.filter((item) => item.status !== 'UPLOADED');

  const confirmEmailCreation = () => {
    if (awaitimgUpload.length > 0) {
      showToastMessage({
        type: 'warning',
        title: `${awaitimgUpload.length} file(s) in progress`,
        description: 'Please wait while all items in the list are complete',
        placement: 'topRight',
      });
      setTimeout(() => {
        setIsAttachmentModalVisible(true);
      }, 200);
      return;
    }
    handleEmailCreation(mail.number * mail.seconds);
  };

  return (
    <div>
      <Modal
        className="emmaccen"
        open={isAttachmentModalVisible}
        onOk={() => setIsAttachmentModalVisible(false)}
        onCancel={() => setIsAttachmentModalVisible(false)}
        footer={null}
        centered
      >
        <div className="p-5 attachFileModal">
          <h1 className="text-center title">Attach Files</h1>
          <label
            htmlFor="fileUpload"
            title="Drag and Drop file(s) to be attached to this email"
            onDrop={(e) => onFileDropHandler(e, files, setFiles, setIsUploading)}
            onDragOver={onDragHandler}
            className="block h-32 w-full my-5 cursor-pointer fileUpload"
          >
            <input
              type="file"
              onChange={(e) => onSystemFileSelected(e, files, setFiles, setIsUploading, fileInputRef)}
              id="fileUpload"
              multiple={true}
              ref={fileInputRef}
            ></input>
            <div className="centered h-full">
              <p className="details">
                Attach files by dragging & dropping or <span className="primaryColor">selecting them</span>.
              </p>
            </div>
          </label>
        </div>
        <div className="flex flex-wrap my-5">{LoadFilesUi()}</div>
        <div className="flex justify-end">
          <Button onClick={() => setIsAttachmentModalVisible(false)} type="primary" data-test="fileupload-btn">
            Done
          </Button>
        </div>
      </Modal>
      <div className="flex align-items-center actions">
        {/**<Dropdown
          menu={{
            items: [
              {
                key: 'teastmail',
                disabled: true,
                label: (
                  <TestMailDropdown
                    campaignId={campaignId}
                    emailIntervalForm={emailIntervalForm}
                    mail={mail}
                    setImLoading={setImLoading}
                    setMail={setMail}
                    testMail={testMail}
                  />
                ),
              },
            ],
          }}
        >
          <a className="ant-dropdown-link flex justify-end" onClick={(e) => e.preventDefault()}>
            <Button size={'large'} type="default" className="mr-3 ghostBtn">
              <CampaignIntervalIcon />
            </Button>
          </a>
        </Dropdown>*/}
        <Button
          onClick={() => setIsAttachmentModalVisible(true)}
          size={'large'}
          type="default"
          className="mr-3 ghostBtn flex align-items-center"
          data-test="attach-btn"
        >
          Attach
          <div style={{ marginLeft: '10px' }}>
            <AttachIcon />
          </div>
        </Button>
        <Button
          loading={imLoading?.id === 'draftingEmail'}
          onClick={() => saveDraft()}
          size={'large'}
          type="default"
          className="mr-3 ghostBtn"
          data-test="save-draft-btn"
        >
          Save to draft
        </Button>
        <Button
          loading={imLoading?.id === 'testMail'}
          onClick={() => {
            testMail({ mail, setImLoading, campaignId, files });
          }}
          size={'large'}
          type="default"
          className="mr-3 ghostBtn"
          disabled={disableEmail}
        >
          Send test email
        </Button>
        <Button
          // data-test="campaign-btn"
          onClick={() => confirmEmailCreation()}
          loading={imLoading?.id === 'emailAction'}
          size={'large'}
          type="primary"
          className="primaryBtn"
          disabled={disableEmail || imLoading?.id === 'testMail'}
          data-test="campaign-btn"
        >
          Send to campaign
        </Button>
      </div>
    </div>
  );
};
