import { Spin, Typography } from 'antd';
import DeleteLandingPageIcon from 'asset/Icons/DeleteLandingPageIcon';
import EditLandingPageIcon from 'asset/Icons/EditLandingPageIcon';
import { MdGlobalButton, MdTypography } from 'components/global';
import { LoadingOutlined } from '@ant-design/icons';
import React from 'react';
import { Link } from 'react-router-dom';

const { Paragraph } = Typography;

export const OptInPageListItem = ({ datas, styles, togglePublish, loading, webUrl, deleteOptInPage }) => {
  return (
    <div className={styles.content_container}>
      <div className={styles.part_one}>
        <p data-test="title">{datas?.title}</p>
        <div className={!datas.published ? styles.publishStatus : styles.status}>
          {datas?.published === true ? 'published' : 'unpublished'}
        </div>
      </div>
      <div className={styles.part_two}>
        <Paragraph
          copyable={{
            icon: [
              <div
                key="copy-icon"
                style={{
                  fontFamily: 'Paralucent-Medium',
                  color: '#5264f5',
                  marginTop: '1rem',
                  marginRight: '1rem',
                  textDecorationColor: '#5264f5',
                  textDecoration: 'underline',
                  textDecorationThickness: '.5px',
                }}
              >
                Copy url
              </div>,
              <div
                key="copied-icon"
                style={{
                  fontFamily: 'Paralucent-Medium',
                  marginTop: '1rem',
                  marginRight: '1rem',
                  color: '#5264f5',
                  textDecorationColor: '#5264f5',
                  textDecorationThickness: '.5px',
                }}
              >
                Copied
              </div>,
            ],
            tooltips: false,
            text: datas?.page_url || `${webUrl}/${datas?.title}/${datas?._id}`,
          }}
        />{' '}
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Link data-test="edit-optin-page" to={`/opt-in-page/${datas.title}/${datas._id}/edit-opt-in-page?template_id=${datas?.template_id || '1'}`}>
          <EditLandingPageIcon />
        </Link>
        &nbsp;&nbsp;&nbsp;
        {loading?.id === datas?._id && loading?.type === 'delete' ? (
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        ) : (
          <DeleteLandingPageIcon deleteOptInPage={deleteOptInPage} id={datas?._id} />
        )}
        &nbsp;&nbsp;&nbsp;
        <MdGlobalButton
          id={'primary_lg'}
          className={datas.published === true ? styles.unpublishedStatus : styles.publishedStatus}
          htmlType="submit"
          dataTestId="create-landing page"
          style={{ width: '125px', marginLeft: '10px' }}
          onClick={() => togglePublish(datas)}
        >
          {loading?.id === datas?._id && loading?.type === 'publish' ? (
            <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
          ) : (
            <MdTypography
              variant={'h6Medium'}
              style={{
                color: datas.published === true ? '#717171' : '#5264f5',
                fontSize: '14px',
              }}
            >
              {datas.published === true ? 'Unpublish' : 'Publish'}
            </MdTypography>
          )}
        </MdGlobalButton>
      </div>
    </div>
  );
};
