import { CKEditor } from '@ckeditor/ckeditor5-react';
import isValidForm from '../../components/emmaccen/shared-functions/inputValidator';
import { BreadCrumb } from '../../components/emmaccen/sharedComponents/BreadCrumb';
import { EditorActionsTab } from '../../components/emmaccen/sharedComponents/EditorActionsTab';
import DecoupledDocumentEditor from 'custom-ckeditor5/dist/bundle';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import '../../styles/editorStyles.scss';
import { deleteAttachment } from '../../utils/service';
import { getSingleCampaignInformation, getSingleEmailById, uploadFile, uploadMarkdownImage } from '../../utils/service';
import { showToastMessage } from '../../utils/Toast';
import {
  useCreateCampaignMailMutation,
  useEditEmailsMutation,
  useSaveDraftCampaignEmailMutation,
} from 'redux/featureApi/emailApiSlice';
import { AttachmentBox } from './AttachmentBox';
import { MentionsInput, Mention } from 'react-mentions';

export const DocumentEditor = () => {
  const [files, setFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [imLoading, setImLoading] = useState({ id: '' });
  const [variables, setVariables] = useState([
    // Init with custom variables
    'FIRST_NAME',
    'LAST_NAME',
    'FULL_NAME',
    'EMAIL_ADDRESS',
  ]);
  /* eslint-disable */
  const [awaitingVariables, setAwaitingVariables] = useState(true);
  const [editor, setEditor] = useState({});

  const [createCampaignMail, { isLoading, isError }] = useCreateCampaignMailMutation();
  const [editEmails] = useEditEmailsMutation();
  const [saveDraftEmail] = useSaveDraftCampaignEmailMutation();

  const location = useLocation();

  const firstMail = location.state?.firstMail;

  const { campaignId, emailId } = useParams();
  const [mail, setMail] = useState({
    subject: 'Message to {{FIRST_NAME}}',
    body: 'Hello {{FIRST_NAME}} {{LAST_NAME}}',
    number: null,
    seconds: null,
    typeOfMail: 'text-editor',
    emailInterval: '',
    attachments: [],
    order: 0,
  });

  const [breadCrumbs, setBreadCrumbs] = useState([]);

  const navigate = useNavigate();
  useEffect(() => {
    const crumbs = [];
    (() => {
      getSingleCampaignInformation(campaignId).then(({ data: { data } }) => {
        setVariables(() => {
          const vars = variables;
          for (const v of data.variables) {
            vars.push(v.name);
          }
          return vars;
        });
        const crumb = {
          name: data?.name,
          link: `/campaign/${campaignId}/campaign-info`,
          index: 1,
        };
        crumbs.push(crumb);
        setBreadCrumbs([...breadCrumbs, ...crumbs]);
        setAwaitingVariables(false);
      });

      if (emailId) {
        getSingleEmailById(campaignId, emailId)
          .then(({ data: { data } }) => {
            setFiles(
              data?.email?.attachments.map((data, index) => {
                return data;
              })
            );
            setMail({ ...mail, ...data.email });
            const crumb = {
              name: data?.email.subject,
              link: `/campaign/${campaignId}/emails`,
              index: 2,
            };
            crumbs.push(crumb);
            setBreadCrumbs([...breadCrumbs, ...crumbs]);
          })
          .catch((error) => {
            showToastMessage({
              type: 'error',
              title: 'Error',
              description: 'Something went wrong, please try again later.',
            });
          });
      }
    })();
  }, []);

  useEffect(() => {
    const uploadFiles = async () => {
      const createDraftEmail = async () => {
        try {
          const res = await saveDraftEmail({ campaignId, mail }).unwrap();
          return res;
        } catch (err) {
          showToastMessage({
            type: 'error',
            title: 'Error',
            description: 'An error occurred, please try again',
          });
          return {};
        }
      };

      if (isUploading) {
        for (const file of files) {
          if (file.status !== 'UPLOADED') {
            let mailId = emailId;

            // Create draft email if mailId is not available
            if (!mailId) {
              const response = await createDraftEmail();

              if (!response.success) {
                showToastMessage({
                  type: 'error',
                  title: 'Error',
                  description: 'An error occurred, please try again',
                });
                updateFileState(file.id, 'ERROR', file.src, file.filename, file.createdAt, file.id);
                return;
              }

              mailId = response.data.createdEmail._id;
              navigate(`/campaign/${campaignId}/${mailId}/text-editor`, { replace: true });
            }

            try {
              const {
                data: {
                  data: { id, location, filename, status, createdAt },
                },
              } = await uploadFile(file.file, file.id, mailId);

              updateFileState(id, status, location, filename, createdAt, file.id);
            } catch (err) {
              updateFileState(file.id, 'ERROR', file.src, file.filename, file.createdAt, file.id);
            } finally {
              setIsUploading(false);
            }
          }
        }
      }
    };

    uploadFiles();
  }, [isUploading]);

  const updateFileState = (id, status, location, filename, createdAt, oldId) => {
    setFiles((previousFiles) => {
      return previousFiles.map((file) =>
        file.id === oldId ? { ...file, id, status, src: location, filename, createdAt } : file
      );
    });
  };

  const handleEmailCreation = (interval) => {
    if (isValidForm([mail.body, mail.subject])) {
      setImLoading({ id: 'emailAction' });
      const attachments = files.filter((file) => file.status !== 'ERROR').map((file) => file);
      setMail({ ...mail, attachments, emailInterval: interval, status: 0 });

      emailId
        ? editEmails({
            payload: {
              ...mail,
              attachments,
              emailInterval: interval,
              moveToActive: true,
            },
            campaignId,
            emailId,
          })
            .unwrap()
            .then((data) => {
              showToastMessage({
                type: 'success',
                title: 'Email',
                description: 'Your mail has been updated successfully',
              });
              navigate(
                {
                  pathname: `/campaign/${campaignId}/emails`,
                  state: {
                    firstMail,
                  },
                },
                { replace: true }
              );
            })
            .catch((err) => {
              const spamDetected = err?.data?.error.includes('spam');
              showToastMessage({
                type: 'error',
                title: spamDetected ? 'Spam Detected' : 'Oops',
                description: spamDetected ? err?.data?.error : 'An error occurred, please try again',
              });
              setImLoading({ id: '' });
            })
        : createCampaignMail({ payload: { ...mail, attachments, emailInterval: interval }, campaignId })
            .unwrap()
            .then((data) => {
              // console.log('this request has been fufiled', data);
              navigate(`/campaign/${campaignId}/emails`, { replace: true });
            })
            .catch((err) => {
              const spamDetected = err?.data?.error.includes('spam');
              showToastMessage({
                type: 'error',
                title: spamDetected ? 'Spam Detected' : 'Oops',
                description: spamDetected ? err?.data?.error : 'An error occurred, please try again',
              });
              setImLoading({ id: '' });
            });
    } else {
      setImLoading({ id: '' });
      showToastMessage({
        type: 'warning',
        title: 'Required Fields',
        description: 'Please provide both email-body and subject',
      });
    }
  };

  const saveDraft = () => {
    if (isValidForm([mail.body, mail.subject])) {
      setImLoading({ id: 'draftingEmail' });
      const attachments = files.filter((file) => file.status !== 'ERROR').map((file) => file);
      setMail({ ...mail, attachments });

      emailId && mail.status !== 0
        ? editEmails({
            payload: {
              ...mail,
              attachments,
              moveToActive: false,
            },
            campaignId,
            emailId,
          })
            .unwrap()
            .then((data) => {
              showToastMessage({
                type: 'success',
                title: 'Email',
                description: 'Your mail has been updated successfully',
              });
              navigate(
                {
                  pathname: `/campaign/${campaignId}/emails`,
                  state: {
                    firstMail,
                  },
                },
                { replace: true }
              );
            })
            .catch((err) => {
              const spamDetected = err?.data?.error.includes('spam');
              showToastMessage({
                type: 'error',
                title: spamDetected ? 'Spam Detected' : 'Oops',
                description: spamDetected ? err?.data?.error : 'An error occurred, please try again',
              });
              setImLoading({ id: '' });
            })
        : saveDraftEmail({ campaignId, mail })
            .unwrap()
            .then((mail) => {
              showToastMessage({
                type: 'success',
                title: 'Draft Email',
                description: 'Your mail has been saved as draft',
              });
              navigate(`/campaign/${campaignId}/emails`, { replace: true });
            })
            .catch((err) => {
              const spamDetected = err?.data?.error.includes('spam');
              showToastMessage({
                type: 'error',
                title: spamDetected ? 'Spam Detected' : 'Oops',
                description: spamDetected ? err?.data?.error : 'An error occurred, please try again',
              });
              setImLoading({ id: '' });
            });
    } else {
      setImLoading({ id: '' });
      showToastMessage({
        type: 'warning',
        title: 'Required Fields',
        description: 'Please provide both email-body and subject',
      });
    }
  };

  const deleteFile = ({ src, id }) => {
    setImLoading({ id });
    if(!emailId){
      setFiles(files.filter((file) => file.src !== src));
      return;
    }
    deleteAttachment(id, emailId)
      .then(() => {
        showToastMessage({
          type: 'success',
          title: 'File',
          description: 'File Deleted',
        });
        setFiles(files.filter((file) => file.src !== src));
      })
      .catch((e) => {
        showToastMessage({
          type: 'error',
          title: 'Error',
          description: 'An error occurred',
        });
      })
      .finally(() => {
        setImLoading({ id: '' });
      });
  };
  class MyUploadAdapter {
    constructor(loader) {
      // The file loader instance to use during the upload.
      this.loader = loader;
    }
    // Starts the upload process.
    upload() {
      const file = this.loader.file;
      return file.then((blob) => {
        return uploadMarkdownImage(blob).then(({ data }) => {
          return { default: data?.data?.resource };
        });
      });
    }
    abort() {
      console.log('upload aborted');
    }
  }

  const mergeTags = [
    { id: 1, display: 'FIRST_NAME' },
    { id: 2, display: 'LAST_NAME' },
    { id: 3, display: 'FULL_NAME' },
    { id: 4, display: 'EMAIL_ADDRESS' },
  ];

  const displayStyle = {
    suggestions: {
      marginTop: '35px',
      zIndex: '10',
      list: {
        backgroundColor: 'white',
        border: '1px solid rgba(0,0,0,0.15)',
        fontSize: 14,
      },
      item: {
        padding: '5px 15px',
        borderBottom: '1px solid rgba(0,0,0,0.15)',
        '&focused': {
          backgroundColor: '#cee4e5',
        },
      },
    },
  };

  const handleChange = (event, newValue, newPlainTextValue, mentions) => {
    setMail({ ...mail, subject: newValue }); // Update the subject in the state
  };

  return (
    <div className="emmaccen">
      <div className="editorContainer">
        <div className="flex flex-space-btw align-items-center mb-5 editorHeaderTab">
          <BreadCrumb crumbs={breadCrumbs} />

          <EditorActionsTab
            imLoading={imLoading}
            setImLoading={setImLoading}
            campaignId={campaignId}
            setMail={setMail}
            mail={mail}
            handleEmailCreation={handleEmailCreation}
            saveDraft={saveDraft}
            filesProps={[files, setFiles, setIsUploading]}
            deleteFunction={deleteFile}
          />
        </div>
        <div className="editorInputArea">
          <MentionsInput
            value={mail?.subject}
            singleLine
            placeholder="Enter Email Subject"
            data-test="email-subject"
            className="email-subject-mention"
            style={displayStyle}
            onChange={(e) => setMail({ ...mail, subject: e.target.value })}
          >
            <Mention
              trigger="@"
              data={mergeTags}
              markup="{{__display__}}"
              displayTransform={(display) => `@${display}`}
            />
          </MentionsInput>

          {
            // Start only when variables are good to go
            !awaitingVariables && (
              <div className="ckeditorStyles">
                <CKEditor
                  onReady={(editor) => {
                    // console.log('Editor is ready to use!', editor);

                    setEditor(editor);
                    // Insert the toolbar before the editable area.
                    editor.ui
                      .getEditableElement()
                      .parentElement.insertBefore(editor.ui.view.toolbar.element, editor.ui.getEditableElement());
                    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
                      return new MyUploadAdapter(loader);
                    };
                    // This is not optimal but it's the way to go
                    removeUnwantedCKEBgColor();
                  }}
                  onError={(error, { willEditorRestart }) => {
                    // If the editor is restarted, the toolbar element will be created once again.
                    // The `onReady` callback will be called again and the new toolbar will be added.
                    // This is why you need to remove the older toolbar.
                    if (willEditorRestart) {
                      this.editor.ui.view.toolbar.element.remove();
                    }
                    console.log({ error });
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setMail((prev) => {
                      return { ...prev, body: data };
                    });
                  }}
                  editor={DecoupledDocumentEditor}
                  data={mail?.body}
                  onBlur={(event, editor) => {}}
                  onFocus={(event, editor) => {}}
                  config={{
                    placeholderConfig: {
                      variables: variables,
                    },
                  }}
                />
              </div>
            )
          }
        </div>
        {files?.length > 0 && <AttachmentBox files={files} deleteFunction={deleteFile} imLoading={imLoading} />}
      </div>
    </div>
  );
};

function removeUnwantedCKEBgColor() {
  const editable = document.querySelector("div[contenteditable='true']");
  if (editable) {
    editable.addEventListener('mouseleave', () => {
      editable.style.background = 'white';
    });
  }
}
