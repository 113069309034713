import React from 'react';

import { Button, Form, Input, Modal, Select } from 'antd';
import { useDispatch } from 'react-redux';
import { toggleCreateNewTeam } from '../../../../redux/featureSlices/uiSlice';

const CreateTeamModal = ({ workspace }) => {
  const dispatch = useDispatch();

  const { Option } = Select;
  const [creatTeamForm] = Form.useForm();

  const onFinish = (values) => {};

  const children = [];
  for (let i = 10; i < 36; i++) {
    children.push(
      <Option key={i.toString(36) + i} value={i.toString(36) + i}>
        {i.toString(36) + i}
      </Option>
    );
  }

  return (
    <Modal
      className="emmaccen"
      open={false}
      // onOk={() => handleContactAddition()}
      onCancel={() => {
        // setImVisible({ id: '' });
      }}
      afterClose={() => {
        // setImVisible({ id: '' });
      }}
      destroyOnClose={true}
      footer={null}
      maskClosable={false}
      closable={false}
      centered
    >
      <div className="createNewTeam">
        <h2 className="text-center title">Create Team</h2>
        <Form form={creatTeamForm} onFinish={onFinish} onFinishFailed={() => null} autoComplete="off" size="large">
          <Form.Item
            label="Name"
            name="name"
            required
            rules={[
              {
                required: true,
                message: 'Name of team must be provided',
              },
            ]}
          >
            <Input placeholder="Create a team" type="text" />
          </Form.Item>
          <Form.Item label="Select team members" name="teammembers">
            <Select mode="tags" style={{ width: '100%', marginBottom: '20px' }} placeholder="Select team members">
              {children}
            </Select>
          </Form.Item>
          <div className="emmaccen">
            <div className="flex-space-btw actionBtns">
              <Button
                size={'large'}
                type=""
                onClick={() => dispatch(toggleCreateNewTeam())}
                // icon={<PlusOutlined />}
              >
                Back
              </Button>
              <Button
                size={'large'}
                type="primary"
                htmlType="submit"
                // icon={<PlusOutlined />}
                loading={false}
                onClick={() => dispatch(toggleCreateNewTeam())}
              >
                Done
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

CreateTeamModal.propTypes = {};

export default CreateTeamModal;
