// import Cookie from 'js-cookie';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { AuthNav } from '../components/emmaccen/sharedComponents/AuthNav';
// import WhatsNewModalPop from './WhatsNewModalPop';
import { getWidth } from './emmaccen/shared-functions/getSideBarWidth';
import Sidebar from './emmaccen/sharedComponents/Sidebar';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { growthBookId } from 'utils/constants';

const styles = {
  active: true,
  width: '80px',
  activeWidth: '250px',
  hide: true,
  topNavOpen: true,
  /**
   * @param {string} val
   */
  set w(val) {
    this.val = val;
  },
  get getWidth() {
    return this.active ? this.activeWidth : this.width;
  },
};

function Layout({ children }, props) {
  const { pathname } = useLocation();
  const { landingPageName, landingPageId } = useParams();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userDetails, setUserDetails] = useState();
  const [userActiveWorkSpace, setUserActiveWorkSpace] = useState();
  const [activeSideBar, setActiveSideBar] = useState(styles);
  // const [isModalActive, setIsModalActive] = useState(false);

  const landingPage = landingPageName?.replace(/%20/g, ' ');
  const pathnamRe = pathname?.replace(/%20/g, ' ');

  const route = `/${landingPage}/${landingPageId}`;

  let navWidthHandler = () => {
    let windowWidth = window.innerWidth;

    const splitPath = pathname.split('/');

    if (windowWidth > 600 || splitPath[splitPath.length - 1] === 'speditor') {
      setActiveSideBar({ ...activeSideBar, topNavOpen: true });
      windowWidth <= 700 && windowWidth > 600 && setActiveSideBar({ ...activeSideBar, active: false, hide: false });
    }
    if (windowWidth >= 700 && splitPath[splitPath.length - 1] === 'speditor') {
      setActiveSideBar({ ...activeSideBar, active: false, hide: true });
    } else if (windowWidth >= 700) {
      setActiveSideBar({ ...activeSideBar, active: true, hide: false });
    } else if (windowWidth <= 600) {
      setActiveSideBar({ ...activeSideBar, topNavOpen: false, active: false, hide: true });
    }
    return windowWidth;
  };
  const disableEmail = useFeatureIsOn(growthBookId.disableEmail);
  const disableUser = userDetails?.isBlocked;

  useEffect(() => {
    window.addEventListener('resize', navWidthHandler);
    return () => window.removeEventListener('resize', navWidthHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    navWidthHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    const userDetails = JSON.parse(localStorage?.getItem('userDetails'));
    if (userDetails) {
      setIsLoggedIn(true);
      setUserDetails(userDetails?.user);
      setUserActiveWorkSpace(userDetails?.activeWorkSpace);
    }

    // const firstTimeLogCheck = Cookie.get('firstTimeLog');

    // if (firstTimeLogCheck === undefined && isLoggedIn) {
    //   setIsModalActive(true);
    //   Cookie.set('firstTimeLog', 'true');
    // } else if (firstTimeLogCheck === 'true' && isLoggedIn) {
    //   setIsModalActive(true);
    // } else {
    //   setIsModalActive(false);
    // }
  }, [isLoggedIn, pathname]);

  return (
    <>
      <div className="relative h-full w-full">
        {route === pathnamRe ? (
          ''
        ) : (
          <>
            {isLoggedIn && disableEmail && (
              <div className="error-banner">
                We are currently experiencing technical difficulties and as a result sending emails on Maildrip has been
                temporarily disabled. Please bear with us!
              </div>
            )}
            {isLoggedIn && disableUser && (
              <div className="error-banner">
                Dear user, your account has been temporarily suspended for breaching our compliance regulations. To
                appeal this decision, kindly send an email to support@maildrip, and our customer support team would
                follow up swiftly
              </div>
            )}
            {isLoggedIn && (
              <Sidebar
                activeSideBar={activeSideBar}
                setActiveSideBar={setActiveSideBar}
                disableEmail={disableEmail}
                disableUser={disableUser}
              />
            )}
          </>
        )}
        <div className="emmaccen">
          {route === pathnamRe ? (
            <div>{children}</div>
          ) : (
            <>
              {isLoggedIn && (
                <AuthNav
                  data={userDetails}
                  activeSideBar={activeSideBar}
                  setActiveSideBar={setActiveSideBar}
                  disableEmail={disableEmail}
                  disableUser={disableUser}
                  userActiveWorkSpace={userActiveWorkSpace}
                />
              )}
              {/* body */}
              <div
                style={{
                  width: `${window.innerWidth <= 500 ? '100%' : `calc(100% - ${getWidth(activeSideBar)})`}`,
                  top: `${
                    window.innerWidth <= 500 ? '80px' : activeSideBar.hide ? '50px' : disableEmail ? '5.87rem' : '54px'
                  }`,
                }}
                className="pageContent "
              >
                {children}
              </div>
            </>
          )}
          {/* <WhatsNewModalPop modalIsActive={isModalActive} setModalIsActive={setIsModalActive} /> */}
        </div>
      </div>
    </>
  );
}

export default Layout;

export const Card = ({ children }) => (
  <div className="bg-white border border-gray-200 rounded-lg shadow">{children}</div>
);
