import React from 'react';

export const MdSend = ({ color = '#273DF2' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path
        d="M5.86781 12.5888C5.28447 12.5888 4.69531 12.1921 3.90781 11.4105L1.88947 9.39215C0.174475 7.67715 0.349474 7.01798 1.88947 5.47215L5.13864 2.21715C6.28781 1.06798 7.23864 0.397147 9.05864 2.21715L11.077 4.23548C12.7161 5.87465 12.3195 6.91298 11.077 8.15548L7.82781 11.4046C7.04031 12.1921 6.45697 12.5888 5.86781 12.5888ZM2.50781 8.76798L4.52614 10.7863C5.76281 12.023 5.96697 12.0288 7.20947 10.7863L10.4586 7.53715C11.4328 6.56298 11.6836 6.07881 10.4586 4.85381L8.44031 2.83548C7.16281 1.55798 6.77781 1.82048 5.75697 2.83548L2.50781 6.08465C1.27114 7.32715 1.16614 7.42631 2.50781 8.76798ZM11.7478 12.5176C11.0828 12.5176 10.5578 12.261 10.3011 11.8118C10.0445 11.3626 10.097 10.7793 10.4411 10.2076L10.8261 9.57181C11.0536 9.19848 11.3861 8.98265 11.7478 8.98265C12.1095 8.98265 12.4478 9.19848 12.6695 9.57181L13.0545 10.2076C13.3986 10.7793 13.4453 11.3626 13.1945 11.8118C12.9436 12.261 12.407 12.5176 11.7478 12.5176ZM11.7478 9.85765C11.7186 9.85765 11.6486 9.90431 11.5728 10.021L11.1878 10.6568C11.0128 10.9485 10.9661 11.211 11.0595 11.3801C11.1528 11.5493 11.4036 11.6426 11.742 11.6426C12.0803 11.6426 12.3311 11.5493 12.4245 11.3801C12.5178 11.211 12.4711 10.9543 12.2961 10.6568L11.9111 10.021C11.847 9.89848 11.777 9.85765 11.7478 9.85765Z"
        fill={color}
      />
      <path
        d="M1.16749 7.57762C1.06138 7.57793 0.958801 7.53951 0.879002 7.46956C0.799202 7.39962 0.747667 7.30296 0.734065 7.19773C0.720463 7.09249 0.745729 6.9859 0.805122 6.89797C0.864516 6.81004 0.953955 6.7468 1.05666 6.72012C4.37292 5.82026 7.86564 5.79413 11.195 6.64428L11.4867 6.72012C11.5919 6.75483 11.6801 6.82819 11.7333 6.9254C11.7866 7.0226 11.801 7.13639 11.7736 7.24378C11.7462 7.35117 11.679 7.44416 11.5857 7.50396C11.4924 7.56376 11.3798 7.58592 11.2708 7.56595L10.9792 7.49012C7.79748 6.67494 4.45852 6.70108 1.28999 7.56595C1.24333 7.57762 1.20249 7.57762 1.16749 7.57762Z"
        fill={color}
      />
    </svg>
  );
};
