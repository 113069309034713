import React, { useState } from 'react';

import { Modal } from 'antd';
import MdCSVReader from '../../../../components/MdCsvReader';
import ConfirmCsvUpload from './ConfirmCsvUpload';

const UploadCsvModal = ({
  imVisible,
  setImVisible,
  csvResult,
  setCsvResult,
  currentGroup = { _id: '' },
  setRefresh = () => {},
  uploadContactsTo,
  campaignId,
  onSuccess = () => {},
  onError = () => {},
  formInstance,
}) => {
  const [uploadStep, setUploadStep] = useState('1');

  return (
    <Modal
      className="emmaccen"
      open={imVisible?.id === 'confirmCsvUpload'}
      // onOk={() => handleContactAddition()}
      onCancel={() => setImVisible({ id: '' })}
      afterClose={() => {
        setUploadStep('1');
        setCsvResult([]);
      }}
      footer={null}
      maskClosable={false}
      closable={false}
      destroyOnClose={true}
      centered
    >
      <div className="confirmCsvUpload">
        {uploadStep === '1' ? (
          <MdCSVReader
            setUploadStep={setUploadStep}
            imVisible={imVisible}
            setImVisible={setImVisible}
            setCSVResult={setCsvResult}
            uploadContactsTo={uploadContactsTo}
          />
        ) : uploadStep === '2' ? (
          <ConfirmCsvUpload
            csvResult={csvResult}
            setCsvResult={setCsvResult}
            uploadContactsTo={uploadContactsTo}
            imVisible={imVisible}
            setImVisible={setImVisible}
            currentGroup={currentGroup}
            setRefresh={setRefresh}
            setUploadStep={setUploadStep}
            campaignId={campaignId}
            onSuccess={onSuccess}
            onError={onError}
          />
        ) : (
          ''
        )}
      </div>
    </Modal>
  );
};

UploadCsvModal.propTypes = {};

export default UploadCsvModal;
