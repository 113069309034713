import { Divider, Tabs } from 'antd';
import LoadingScreen from 'components/LoadingScreen';
import { MdTypography } from 'components/global';
import MdGlobalButton from '../../../components/global/Button';
import { useState } from 'react';
import TemplateCard from './TemplateCard';
import { EmptyStateHourGlass } from 'asset/Icons/EmptyStateHourGlass';
import { ComingSoonRocket } from 'asset/Icons/ComingSoonRocket';
import { MdOptInPageTemplates } from '../Templates';

const TemplateSelection = ({ submitTemplateSelected }) => {
  const { TabPane } = Tabs;
  // eslint-disable-next-line no-unused-vars
  const [tabKey, setTabKey] = useState('1');
  const [currentCard, setCurrentCard] = useState(null);
  const [isTemplateLoading] = useState(false);
  const [fetchDefaultTemplates] = useState(MdOptInPageTemplates());
  const [mySavedFetchTemplates] = useState([]);

  const handleRoute = () => {
    // navigate(`/${optInPageName}/${optInPageId}/edit-opt-in-page`);
    setTabKey('1');
  };

  return (
    <div className="opt-in-page-template mail_subject__template_select">
      <Tabs onTabClick={(key) => setTabKey(key)} activeKey={tabKey}>
        <TabPane
          tab={
            <MdTypography variant={'h6Light'} dataTestId="opt-in-page-templates">
              Templates
            </MdTypography>
          }
          key="1"
        >
          {isTemplateLoading ? (
            <LoadingScreen />
          ) : (
            <>
              {!fetchDefaultTemplates.length ? (
                <div className="w-full flex justify-center items-center flex-column">
                  <ComingSoonRocket />
                  <MdTypography variant={'btnLight'}>This feature is coming soon</MdTypography>
                </div>
              ) : (
                <div className="templatePane  flex flex-wrap overflow-y-auto">
                  {fetchDefaultTemplates.map((temp, index) => {
                    return (
                      <TemplateCard
                        key={temp.id}
                        id={temp.id}
                        tabKey="2"
                        icon={temp.previewImage}
                        data-index={index}
                        submitTemplateSelected={submitTemplateSelected}
                        temp={temp}
                      />
                    );
                  })}
                </div>
              )}
            </>
          )}
        </TabPane>

        <TabPane tab={<MdTypography variant={'h6Light'}>My Saved Templates</MdTypography>} key="2">
          {isTemplateLoading ? (
            <LoadingScreen />
          ) : (
            <>
              {!mySavedFetchTemplates.length ? (
                <div className="w-full emptyBox">
                  <EmptyStateHourGlass />
                  <MdTypography variant={'btnLight'}>You have not saved any template yet</MdTypography>
                  <MdGlobalButton dataTestId="" onClick={handleRoute} id={'primary_lg'}>
                    Use existing templates
                  </MdGlobalButton>
                </div>
              ) : (
                <div className="mb-8 template-grid">
                  {mySavedFetchTemplates.map((temp, index) => {
                    return (
                      <TemplateCard
                        key={temp._id}
                        id={temp._id}
                        tabKey="3"
                        icon={temp.previewImage}
                        data-index={index}
                        onClick={(e) => {
                          setCurrentCard(temp._id);
                        }}
                        fetchedTemplate
                        currentCard={currentCard}
                      />
                    );
                  })}
                </div>
              )}
            </>
          )}
        </TabPane>
        <TabPane tab={<MdTypography variant={'h6Light'}>Create New</MdTypography>} key="3">
          <div className="w-full emptyBox">
            <ComingSoonRocket />
            <MdTypography variant={'btnLight'}>This feature is coming soon</MdTypography>
            <MdGlobalButton
              dataTestId=""
              id={'primary_lg'}
              // onClick={() => {
              //   return document.querySelector('[data-test="opt-in-page-templates"]').click();
              // }}
              onClick={handleRoute}
            >
              Use existing templates
            </MdGlobalButton>
          </div>
        </TabPane>
      </Tabs>
      <Divider />
    </div>
  );
};

export default TemplateSelection;
