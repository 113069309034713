import React from 'react';
import { RightOutlined } from '@ant-design/icons';

function MenuItems({ onClickDesignFunc, onClickFormFieldFunc, onClickConfirmationFunc,setLink }) {
  
  return (
    <div className="contactListMenu">
      <CampaignItems onClick={onClickDesignFunc} title="Design" description="Heading, Tagline, Call-to-Action" />
      <CampaignItems
        onClick={onClickFormFieldFunc}
        title="Form Field"
        description="Add, remove, or customize form fields"
      />
      <CampaignItems onClick={onClickConfirmationFunc} title="Confirmation Page" description={setLink ||"No active link"} />
      
    </div>
    
  );
}

export default MenuItems;

const CampaignItems = ({ title, onClick, description }) => {
  return (
    <div onClick={onClick} className="flex justify-between border p-5 mb-5 cursor-pointer">
      <div className="pointer padLR">
        <div className="sidebar-content">
          <p className="sidebar-title">{title}</p>
          <span className="sidebar-description">{description}</span>
        </div>
      </div>
      <RightOutlined className="right-icon" />
    </div>
  );
};
