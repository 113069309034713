import { MdTypography } from '../../../components/global';
import React from 'react';

function EmailDetailedReportSummary({ emailReportData }) {
  return (
    <>
      <div className="flex pt-10 pb-6 w-full instantEmail-subject-full">
        <div className="instantEmail-subject sm: pb-3 mr-16">
          <MdTypography variant={'smallTextRegular'} className="instantEmail-header">
            Subject:
          </MdTypography>
          <MdTypography variant={'smallTextRegular'} className="instantEmail-info">
            {emailReportData?.mailInfo?.subject}
          </MdTypography>
        </div>
        <div className="instantEmail-details">
          <div>
            <MdTypography variant={'smallTextRegular'} className="instantEmail-header">
              From:
            </MdTypography>
            <MdTypography variant={'smallTextRegular'} className="instantEmail-info">
              {emailReportData?.mailInfo?.from}
            </MdTypography>
          </div>
          <div>
            <MdTypography variant={'smallTextRegular'} className="instantEmail-header">
              Reply-to:
            </MdTypography>
            <MdTypography variant={'smallTextRegular'} className="instantEmail-info">
              {emailReportData?.mailInfo?.replyTo}
            </MdTypography>
          </div>
        </div>
      </div>

      <div className="instantEmail-chart pt-10 w-full ">
        <div className="instantEmailChart-info item-center">
          <MdTypography variant={'smallTextRegular'} className="instantEmail-rate">
            Open Rate
          </MdTypography>
          <MdTypography
            dataTestId="open-rate-testId"
            variant={'h2'}
            className="instantEmailChart-percent pb-3 pt-1 green"
          >
            {emailReportData?.mailAnalytics?.openRate}%
          </MdTypography>
          <MdTypography variant={'captionMedium'} className="instantEmail-rate">
            {emailReportData?.mailAnalytics?.totalOpens} Open(s)
          </MdTypography>
        </div>
        <div className="instantEmailChart-info">
          <MdTypography variant={'smallTextRegular'} className="instantEmail-rate">
            Click Rate
          </MdTypography>
          <MdTypography
            dataTestId="click-rate-testId"
            variant={'h2'}
            className="instantEmailChart-percent pb-3 pt-1 orange"
          >
            {emailReportData?.mailAnalytics?.clickRate}%
          </MdTypography>
          <MdTypography variant={'captionMedium'} className="instantEmail-rate">
            {emailReportData?.mailAnalytics?.totalClicks} Click(s)
          </MdTypography>
        </div>
        <div className="instantEmailChart-info">
          <MdTypography variant={'smallTextRegular'} className="instantEmail-rate">
            Delivery Rate
          </MdTypography>
          <MdTypography
            dataTestId="delivery-rate-testId"
            variant={'h2'}
            className="instantEmailChart-percent pb-3 pt-1 purple"
          >
            {emailReportData?.mailAnalytics?.deliveryRate}%
          </MdTypography>
          <MdTypography variant={'captionMedium'} className="instantEmail-rate">
            {emailReportData?.mailAnalytics?.totalDeliveries} Delivered
          </MdTypography>
        </div>
        <div className="instantEmailChart-info">
          <MdTypography variant={'smallTextRegular'} className="instantEmail-rate">
            Click-through Rate
          </MdTypography>
          <MdTypography dataTestId="ctr-testId" variant={'h2'} className="instantEmailChart-percent pb-3 pt-1 black">
            {emailReportData?.mailAnalytics?.ctr}%
          </MdTypography>
          <MdTypography variant={'captionMedium'} className="instantEmail-rate">
            {emailReportData?.mailAnalytics?.ctrTotal} Clicked Through
          </MdTypography>
        </div>
        <div className="instantEmailChart-info">
          <MdTypography variant={'smallTextRegular'} className="instantEmail-rate">
            Bounce Rate
          </MdTypography>
          <MdTypography
            dataTestId="bounce-rate-testId"
            variant={'h2'}
            className="instantEmailChart-percent pb-3 pt-1 brown"
          >
            {emailReportData?.mailAnalytics?.bounceRate}%
          </MdTypography>
          <MdTypography variant={'captionMedium'}>{emailReportData?.mailAnalytics?.totalBounces} Bounced</MdTypography>
        </div>
        <div className="instantEmailChart-info">
          <MdTypography variant={'smallTextRegular'} className="instantEmail-rate">
            Spam Rate
          </MdTypography>
          <MdTypography variant={'h2'} className="instantEmailChart-percent pb-3 pt-1 green">
            {emailReportData?.mailAnalytics?.spamRate}%
          </MdTypography>
          <MdTypography variant={'captionMedium'} className="instantEmail-rate">
            {emailReportData?.mailAnalytics?.totalSpams} Spam
          </MdTypography>
        </div>
      </div>
    </>
  );
}

export default EmailDetailedReportSummary;
