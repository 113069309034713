import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card } from 'antd';
import { MdTypography } from '../../../components/global';

const MdTotalAnalytics = () => {
  const [totalAnalytics, setTotalAnalytics] = useState({});

  const api__url = process.env.REACT_APP_API_URL;

  const FetchTotalAnalytics = async () => {
    const { data } = await axios.get(`${api__url}/dashboard/analysis`);
    setTotalAnalytics(data.data);
  };
  /* eslint-disable */
  useEffect(() => {
    FetchTotalAnalytics();
  }, []);

  return (
    <>
      <MdTypography variant={'h6Medium'} className="totalAnalyticsTitle">
        Analytics
      </MdTypography>
      <Card className="margLR">
        <div>
          <div className="totalAnalytics">
            <MdTypography variant={'smallTextRegular'}>Total Emails Sent</MdTypography>
            <MdTypography variant={'smallTextRegular'}>{totalAnalytics.totalEmailsSent}</MdTypography>
          </div>
          <div className="totalAnalytics">
            <MdTypography variant={'smallTextRegular'}>Total Recipients</MdTypography>
            <MdTypography variant={'smallTextRegular'}>{totalAnalytics.totalRecipents}</MdTypography>
          </div>
          <div className="totalAnalytics">
            <MdTypography variant={'smallTextRegular'}>Total Opens</MdTypography>
            <MdTypography variant={'smallTextRegular'}>{totalAnalytics.totalOpens}</MdTypography>
          </div>
          <div className="totalAnalytics">
            <MdTypography variant={'smallTextRegular'}>Total Clicks</MdTypography>
            <MdTypography variant={'smallTextRegular'}>{totalAnalytics.totalClicks}</MdTypography>
          </div>
          <div className="totalAnalytics">
            <MdTypography variant={'smallTextRegular'}>Unsubscribed</MdTypography>
            <MdTypography variant={'smallTextRegular'}>{totalAnalytics.totalUnsubscribes}</MdTypography>
          </div>
          <div className="totalAnalytics">
            <MdTypography variant={'smallTextRegular'}>Spam</MdTypography>
            <MdTypography variant={'smallTextRegular'}>{totalAnalytics.totalSpams}</MdTypography>
          </div>
        </div>
        <p className="totalAnalyticsFooter">Contains total email analytics of all time</p>
      </Card>
    </>
  );
};

export default MdTotalAnalytics;
