import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { showToastMessage } from 'utils/Toast';
import { instantEmailsActionTypes } from 'utils/constants';
import Editor from './InstantEmailEditor';

const InstantEmailGrapeEditor = () => {
  const [emailEditRes, setEmailEditRes] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [isEditingEmail, setIsEditingEmail] = useState(false);
  const [mailEdited, setMailEdited] = useState(false);
  const [emailEditReqFailed, setEmailEditReqFailed] = useState(false);

  const { mailId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const query = new URLSearchParams(location?.search);

  useEffect(() => {
    if (mailEdited) {
      const email = emailEditRes.email;
      showToastMessage({
        type: 'success',
        title: 'Saved',
        description: 'Mail template saved successfully',
      });
      const action = query.get('action');
      if (action === instantEmailsActionTypes.edit) {
        navigate(`/instant-emails/drafts/${mailId}`);
      } else if (action === instantEmailsActionTypes.create) {
        navigate(`/instant-emails/drafts/${mailId}`, { state: { email } });
      }
    } else if (emailEditReqFailed) {
      showToastMessage({
        type: 'error',
        title: 'Error',
        description: 'Something went wrong, please try again later',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailEditReqFailed, mailEdited]);

  const updateEditEmailStatus = (data, success) => {
    setEmailEditRes((prevData) => ({ ...prevData, ...data }));
    setMailEdited(success);
  };

  return (
    <Editor
      mailId={mailId}
      query={query}
      updateEditEmailStatus={updateEditEmailStatus}
      setEmailEditReqFailed={setEmailEditReqFailed}
      setIsEditingEmail={setIsEditingEmail}
    />
  );
};

export default InstantEmailGrapeEditor;
