import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Cookies from 'js-cookie';
import { showToastMessage } from '../../utils/Toast';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  // credentials: 'include',
  mode: 'cors',
  prepareHeaders: (headers, { endpoint }) => {
    const token = Cookies.get('token');
    const noAuthApi = ['getBuilderTemplate'];
    if (token && !noAuthApi.includes(endpoint)) {
      headers.set('Authorization', `Bearer ${token}`);
      headers.set('x-access-token', `${token}`);
    }
    return headers;
  },
});

// create wrapper for Base query
const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  // In case the token is expired
  if (result?.error?.status === 401) {
    if (!window.location.href.includes('/login')) {
      //Doing this because a/some request(s) is sent to the server when the login page is loaded, showing the error message even before the user has logged in
      // Show error message
      showToastMessage({
        type: 'error',
        title: 'Session Expired',
        description: 'Session has expired. Please login again',
      });

      // Clear token
      Cookies.remove('token');

      // Redirect to login
      window.location.replace('/login');
    }
  } else {
    // Handle other errors or continue with the result
  }
  return result;
};

export const apiAuthSlice = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({}),
  tagTypes: [
    'UserDetails',
    'CampaignList',
    'SpecificCampaignData',
    'CampaignVariables',
    'EmailTableReport',
    'EmailReport',
    'optInPageList',
    'ContactGroups'
  ],
});
