import React from 'react';

export const CampaignLaptop = () => {
  return (
    <svg width="182" height="173" viewBox="0 0 182 173" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_207_4403)">
        <path
          d="M7.86078 79.3153C8.87767 79.3153 9.70202 78.491 9.70202 77.4741C9.70202 76.4571 8.87767 75.6328 7.86078 75.6328C6.84389 75.6328 6.01953 76.4571 6.01953 77.4741C6.01953 78.491 6.84389 79.3153 7.86078 79.3153Z"
          stroke="#2C2C2C"
          strokeWidth="0.708171"
        />
        <path
          d="M11.4684 77.6612C15.1822 77.2789 21.0096 77.731 21.6984 80.6038C22.3872 83.4765 19.757 84.5272 18.356 84.6935C13.0215 85.5413 5.94824 85.1423 5.94824 80.6038"
          stroke="#2C2C2C"
          strokeWidth="0.708171"
          strokeLinecap="round"
        />
        <path
          d="M10.1271 82.0062C10.3617 82.0062 10.552 81.816 10.552 81.5813C10.552 81.3466 10.3617 81.1564 10.1271 81.1564C9.89238 81.1564 9.70215 81.3466 9.70215 81.5813C9.70215 81.816 9.89238 82.0062 10.1271 82.0062Z"
          stroke="#2C2C2C"
          strokeWidth="0.708171"
        />
        <path
          d="M14.2336 81.5813C14.2336 81.7876 14.0413 82.0062 13.7379 82.0062C13.4345 82.0062 13.2422 81.7876 13.2422 81.5813C13.2422 81.3749 13.4345 81.1564 13.7379 81.1564C14.0413 81.1564 14.2336 81.3749 14.2336 81.5813Z"
          stroke="#2C2C2C"
          strokeWidth="0.708171"
        />
        <path
          d="M17.3497 82.0062C17.5844 82.0062 17.7746 81.816 17.7746 81.5813C17.7746 81.3466 17.5844 81.1564 17.3497 81.1564C17.1152 81.1564 16.9248 81.3466 16.9248 81.5813C16.9248 81.816 17.1152 82.0062 17.3497 82.0062Z"
          stroke="#2C2C2C"
          strokeWidth="0.708171"
        />
        <path
          d="M147.582 27.4769C148.521 27.4769 149.282 26.7159 149.282 25.7772C149.282 24.8386 148.521 24.0776 147.582 24.0776C146.643 24.0776 145.883 24.8386 145.883 25.7772C145.883 26.7159 146.643 27.4769 147.582 27.4769Z"
          stroke="#2C2C2C"
          strokeWidth="0.708171"
        />
        <path
          d="M150.922 24.2905C152.167 24.2905 155.727 24.2905 157.401 24.2905C158.756 24.2905 159.409 24.5905 159.409 26.6901C159.409 28.7301 159.392 31.3725 159.409 32.439C159.42 33.1888 159.048 34.0187 157.802 34.1386C156.557 34.2586 151.558 34.1886 149.214 34.1386C148.343 34.1386 146.804 33.6452 146.804 31.6391V29.3895"
          stroke="#2C2C2C"
          strokeWidth="0.708171"
          strokeLinecap="round"
        />
        <path d="M150.062 27.8314L153.177 29.9559" stroke="#2C2C2C" strokeWidth="0.708171" strokeLinecap="round" />
        <path d="M153.178 29.9556L158.843 24.7151" stroke="#2C2C2C" strokeWidth="0.708171" strokeLinecap="round" />
        <path
          d="M154.613 28.6807L156.161 30.2386L157.709 31.7966"
          stroke="#2C2C2C"
          strokeWidth="0.708171"
          strokeLinecap="round"
        />
        <path d="M148.928 31.7966L151.212 28.6807" stroke="#2C2C2C" strokeWidth="0.708171" strokeLinecap="round" />
        <path
          d="M160.038 133.7C159.568 134.171 159.161 134.578 158.882 134.858C158.153 135.587 157.388 135.162 157.096 134.858L152.448 130.378C151.761 129.74 151.18 129.11 148.587 126.635C148.321 126.369 147.9 125.69 148.342 125.101C148.783 124.512 151.263 121.419 152.448 119.946C152.806 119.426 153.588 118.806 154.584 119.733C155.442 120.53 158.384 123.292 160.896 125.391"
          stroke="#2C2C2C"
          strokeWidth="0.708171"
          strokeLinecap="round"
        />
        <path
          d="M152.398 120.105C152.544 122.792 153.53 127.117 158.63 128.745"
          stroke="#2C2C2C"
          strokeWidth="0.708171"
          strokeLinecap="round"
        />
        <path
          d="M165.331 128.953C165.276 129.456 165.24 129.983 165.249 130.432M165.48 128.126C165.427 128.437 165.374 128.567 165.331 128.953L165.48 128.126ZM167.553 131.987C167.355 132.59 166.062 133.876 163.675 133.843C161.287 133.811 160.557 131.705 160.48 130.725C160.411 129.829 160.758 128.101 162.028 127.022C163.307 125.935 164.933 125.905 166.375 127.022C167.57 127.948 167.569 129.364 167.356 130.103C167.229 130.543 166.75 130.974 165.864 131.5C165.422 131.762 165.266 131.219 165.249 130.432L167.553 131.987ZM165.331 128.953C165.097 128.359 163.303 127.667 162.624 128.953C161.925 130.28 162.46 131.397 162.915 131.616C163.495 131.896 164.348 132.042 165.249 130.432L165.331 128.953Z"
          stroke="#2C2C2C"
          strokeWidth="0.708171"
          strokeLinecap="round"
        />
        <path
          d="M39.6577 111.264C39.1485 109.298 37.4577 105.673 34.7689 106.898C32.08 108.124 33.6484 110.319 34.7689 111.264C35.5228 111.774 37.1437 112.321 37.5952 110.421C38.0467 108.522 36.1439 107.741 35.6091 108.583C35.3271 108.915 35.0085 109.747 35.9911 110.421"
          stroke="#2C2C2C"
          strokeWidth="0.708171"
          strokeLinecap="round"
        />
        <path
          d="M51.5222 14.5534C51.5145 14.5306 51.5172 14.5173 51.52 14.509C51.524 14.4981 51.5327 14.4849 51.5474 14.4741C51.5621 14.4634 51.5771 14.459 51.5887 14.4586C51.5977 14.4581 51.6111 14.4597 51.6307 14.4737L54.5196 16.5474C54.7936 16.7442 55.1633 16.7421 55.4352 16.5421L58.3002 14.4355C58.3196 14.4213 58.3329 14.4196 58.3419 14.4199C58.3535 14.4202 58.3686 14.4244 58.3835 14.435C58.3982 14.4457 58.4072 14.4587 58.4111 14.4696C58.4142 14.478 58.4169 14.4911 58.4096 14.5141L57.3302 17.9024C57.2276 18.2239 57.3439 18.5749 57.6181 18.7716L57.8246 18.4839L57.6181 18.7716L60.5069 20.8454C60.5264 20.8594 60.5322 20.8716 60.5348 20.8801C60.538 20.8913 60.5386 20.907 60.5331 20.9244C60.5276 20.9417 60.5179 20.9542 60.5089 20.9614C60.5018 20.9669 60.4902 20.9736 60.4661 20.9737L56.9099 20.9941C56.5726 20.996 56.2747 21.2151 56.1722 21.5366L55.0928 24.9249C55.0854 24.9478 55.0755 24.957 55.0683 24.962C55.0586 24.9687 55.0439 24.974 55.0256 24.9742C55.0074 24.9743 54.9926 24.9689 54.983 24.9626C54.9756 24.9576 54.9656 24.9484 54.9581 24.9256L53.8397 21.5499C53.7336 21.2295 53.4334 21.014 53.0959 21.0159L49.5399 21.0363C49.5158 21.0365 49.5039 21.0298 49.4969 21.0244C49.4876 21.0174 49.478 21.005 49.4722 20.9878C49.4665 20.9705 49.4669 20.9548 49.47 20.9436C49.4726 20.9349 49.4781 20.9226 49.4975 20.9084L52.3623 18.8018C52.6343 18.6018 52.7465 18.2495 52.6404 17.9292L51.5222 14.5534Z"
          stroke="#2C2C2C"
          strokeWidth="0.708171"
        />
        <path d="M95.3906 79.303V91.3419" stroke="#2C2C2C" strokeWidth="0.708171" strokeLinecap="round" />
        <path d="M104.455 80.7192V92.1916" stroke="#2C2C2C" strokeWidth="0.708171" strokeLinecap="round" />
        <path
          d="M83.2529 132.853C80.2598 133.226 73.2527 134.601 71.7178 137.396C71.2573 138.234 70.7232 140.275 72.2703 141.728C73.8177 143.181 76.645 142.8 77.8651 142.426L84.9797 139.562"
          stroke="#2C2C2C"
          strokeWidth="0.708171"
          strokeLinecap="round"
        />
        <path
          d="M124.043 111.761C118.458 111.94 93.7907 123.882 82.1552 129.831C81.1645 136.386 85.3456 141.092 87.5601 142.626C99.1581 139.259 122.962 132.435 125.394 132.076C127.826 131.717 130.236 129.981 131.137 129.158C137.442 121.795 132.788 115.539 129.673 113.332C127.455 111.761 125.541 111.712 124.043 111.761Z"
          fill="#2C2C2C"
        />
        <path
          d="M71.7276 110.09C75.6847 110.354 92.9471 117.044 101.126 120.551L82.4207 129.898C74.0492 132.353 68.9066 131.544 67.336 131.015C58.2705 126.647 61.8224 115.872 65.4243 111.88C67.7457 109.905 70.3097 109.995 71.7276 110.09Z"
          fill="#2C2C2C"
        />
        <path
          d="M115.769 134.128C118.242 135.316 119.58 136.873 120.258 138.15C121.711 141.476 119.102 143.474 116.123 142.535C114.477 142.015 114.089 141.667 113.217 140.944C112.775 140.577 110.709 138.657 108.35 136.39"
          stroke="#2C2C2C"
          strokeWidth="0.708171"
          strokeLinecap="round"
        />
        <path
          d="M68.2786 77.3776L70.1774 111.886C70.2188 112.636 70.8396 113.224 71.5917 113.224H124.568C125.35 113.224 125.984 112.59 125.984 111.808V76.5601C125.984 75.7704 125.338 75.1333 124.549 75.1439L69.6737 75.8837C68.8685 75.8945 68.2342 76.5736 68.2786 77.3776Z"
          fill="#2C2C2C"
        />
        <path
          d="M124.638 113.72H71.242C70.4989 113.72 69.8965 114.322 69.8965 115.065C69.8965 115.808 70.4989 116.411 71.242 116.411H124.638C125.381 116.411 125.984 115.808 125.984 115.065C125.984 114.322 125.381 113.72 124.638 113.72Z"
          fill="#2C2C2C"
        />
        <path
          d="M104.598 60.1821C109.531 60.1113 120.39 63.8646 124.71 75.691"
          stroke="#2C2C2C"
          strokeWidth="0.708171"
          strokeLinecap="round"
        />
        <path
          d="M93.7612 60.1119C82.5012 62.3073 77.1191 67.1228 72.8701 76.3999"
          stroke="#2C2C2C"
          strokeWidth="0.708171"
          strokeLinecap="round"
        />
        <path d="M131.437 71.3125L134.198 66.7094" stroke="#2C2C2C" strokeWidth="0.708171" strokeLinecap="round" />
        <path d="M134.198 72.5876L137.952 69.4717" stroke="#2C2C2C" strokeWidth="0.708171" strokeLinecap="round" />
        <path
          d="M134.765 74.7121C135.496 74.9953 137.158 75.2644 137.951 74.0747"
          stroke="#2C2C2C"
          strokeWidth="0.708171"
          strokeLinecap="round"
        />
        <path d="M62.3193 70.4508L58.0703 66.0602" stroke="#2C2C2C" strokeWidth="0.708171" strokeLinecap="round" />
        <path d="M66.6383 69.7423L64.0889 63.1563" stroke="#2C2C2C" strokeWidth="0.708171" strokeLinecap="round" />
        <path d="M61.2571 72.9295L58.0703 71.7256" stroke="#2C2C2C" strokeWidth="0.708171" strokeLinecap="round" />
        <path
          d="M127.542 83.6348C130.162 87.7422 133.137 92.6286 127.542 101.823"
          stroke="#2C2C2C"
          strokeWidth="0.708171"
          strokeLinecap="round"
        />
        <path
          d="M104.667 60.5369C105.305 58.6957 104.724 57.4567 104.158 57.2206C100.758 60.3365 95.6029 59.1798 93.6908 57.2914C93.011 57.9146 93.4075 59.758 93.6908 60.3245C97.3733 64.4036 102.614 62.1184 104.667 60.5369Z"
          fill="#2C2C2C"
        />
        <path
          d="M89.584 71.868L90.7213 70.1223C91.8297 68.4209 93.9871 67.7416 95.8704 68.501L98.5809 69.5939C100.272 70.2759 102.209 69.8028 103.396 68.4185L106.509 64.7863"
          stroke="#2C2C2C"
          strokeWidth="0.708171"
          strokeLinecap="round"
        />
        <path
          d="M67.4172 86.2434C66.3549 88.7457 65.0944 94.8123 68.5502 99.0613"
          stroke="#2C2C2C"
          strokeWidth="0.708171"
          strokeLinecap="round"
        />
        <path
          d="M114.433 31.5876C112.875 37.1822 112.096 37.6779 109.83 40.1565C109.83 37.7069 109.409 33.5953 109.409 31.8832C101.989 31.8832 95.7268 28.8542 93.5232 27.3397C93.6022 32.4758 88.7871 35.8341 88.7871 35.8341C88.7871 35.8341 89.4777 37.9083 89.8724 42.5507C90.1881 46.2645 89.2804 45.5468 88.7871 44.7236C87.3662 40.1406 85.0375 41.3653 84.0507 42.5507C71.9428 22.8771 90.5631 19.0756 94.1151 19.4378C96.7989 17.1463 100.542 15.3505 103.173 15.0872C112.663 14.1372 116.959 22.5162 114.433 31.5876Z"
          fill="#2C2C2C"
        />
        <path
          d="M88.9521 48.2248C90.2269 56.007 98.7827 61.6028 104.957 55.2988C111.131 48.9947 109.895 37.4555 109.236 32.0708"
          stroke="#2C2C2C"
          strokeWidth="0.708171"
          strokeLinecap="round"
        />
        <path
          d="M83.9103 42.0681C83.1313 43.9801 83.8621 48.217 88.9524 48.217"
          stroke="#2C2C2C"
          strokeWidth="0.708171"
          strokeLinecap="round"
        />
        <path
          d="M86.1557 43.1188C86.0431 43.5278 86.1332 44.4973 87.3949 45.1017"
          stroke="#2C2C2C"
          strokeWidth="0.708171"
          strokeLinecap="round"
        />
        <path
          d="M109.064 47.226C110.726 46.5333 111.897 43.9684 111.446 39.861"
          stroke="#2C2C2C"
          strokeWidth="0.708171"
          strokeLinecap="round"
        />
        <path
          d="M99.7158 51.8999C101.345 52.3248 101.242 51.1555 102.478 50.1295"
          stroke="#2C2C2C"
          strokeWidth="0.708171"
          strokeLinecap="round"
        />
        <path
          d="M104.579 43.0688C105.166 43.0688 105.641 42.5932 105.641 42.0065C105.641 41.4199 105.166 40.9443 104.579 40.9443C103.992 40.9443 103.517 41.4199 103.517 42.0065C103.517 42.5932 103.992 43.0688 104.579 43.0688Z"
          fill="#2C2C2C"
        />
        <path
          d="M95.2312 43.2106C95.8179 43.2106 96.2935 42.735 96.2935 42.1484C96.2935 41.5617 95.8179 41.0861 95.2312 41.0861C94.6445 41.0861 94.1689 41.5617 94.1689 42.1484C94.1689 42.735 94.6445 43.2106 95.2312 43.2106Z"
          fill="#2C2C2C"
        />
        <path
          d="M92.918 39.7189C93.1415 39.0607 94.106 37.8431 96.1756 38.2379"
          stroke="#2C2C2C"
          strokeWidth="0.708171"
          strokeLinecap="round"
        />
        <path
          d="M102.832 38.4449C103.326 38.0173 104.668 37.4188 106.09 38.4449"
          stroke="#2C2C2C"
          strokeWidth="0.708171"
          strokeLinecap="round"
        />
        <path
          d="M99.4053 41.4189C99.2403 42.8174 99.1282 45.6533 99.9992 45.8095"
          stroke="#2C2C2C"
          strokeWidth="0.708171"
          strokeLinecap="round"
        />
        <path
          d="M140.218 105.659C159.126 110.899 193.09 116.919 177.68 99.0727C172.818 94.2808 165.641 88.6628 154.098 83.7056"
          stroke="#2C2C2C"
          strokeWidth="0.708171"
          strokeLinecap="round"
        />
        <path
          d="M160.684 69.0449C172.44 61.5147 191.376 45.9586 173.077 43.9757C154.778 41.9928 138.07 48.8621 132.003 52.5446"
          stroke="#2C2C2C"
          strokeWidth="0.708171"
          strokeLinecap="round"
        />
        <path
          d="M115.149 58.0407C122.939 41.2571 135.545 6.47175 123.647 1.59954C108.776 -4.49074 89.5134 13.0719 73.792 54.9956"
          stroke="#2C2C2C"
          strokeWidth="0.708171"
          strokeLinecap="round"
        />
        <path
          d="M56.4666 43.5827C39.0456 34.046 3.48123 19.3774 0.591891 36.9967C-2.29745 54.616 33.0025 69.313 51.0137 74.459"
          stroke="#2C2C2C"
          strokeWidth="0.708171"
          strokeLinecap="round"
        />
        <path
          d="M55.6382 83.7056C30.2148 96.6651 -1.64019 114.588 16.0047 123.93C23.2279 127.754 41.7111 125.417 50.2092 121.947"
          stroke="#2C2C2C"
          strokeWidth="0.708171"
          strokeLinecap="round"
        />
        <path
          d="M97.2322 172.794C116.592 172.794 132.287 170.892 132.287 168.545C132.287 166.198 116.592 164.296 97.2322 164.296C77.8722 164.296 62.1777 166.198 62.1777 168.545C62.1777 170.892 77.8722 172.794 97.2322 172.794Z"
          fill="#2C2C2C"
        />
      </g>
      <defs>
        <clipPath id="clip0_207_4403">
          <rect width="182" height="172.794" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
