import React from 'react';

export default function DeleteIcon({ onClick, ...props }) {
  return (
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClick} {...props}>
      <path
        d="M15.7488 5.54754C15.7338 5.54754 15.7113 5.54754 15.6888 5.54754C11.7213 5.15004 7.76128 5.00004 3.83878 5.39754L2.30878 5.54754C1.99378 5.57754 1.71628 5.35254 1.68628 5.03754C1.65628 4.72254 1.88128 4.45254 2.18878 4.42254L3.71878 4.27254C7.70878 3.86754 11.7513 4.02504 15.8013 4.42254C16.1088 4.45254 16.3338 4.73004 16.3038 5.03754C16.2813 5.33004 16.0338 5.54754 15.7488 5.54754Z"
        fill="#DB2222"
      />
      <path
        d="M6.37556 4.78994C6.34556 4.78994 6.31556 4.78994 6.27806 4.78244C5.97806 4.72994 5.76806 4.43744 5.82056 4.13744L5.98556 3.15494C6.10556 2.43494 6.27056 1.43744 8.01806 1.43744H9.98306C11.7381 1.43744 11.9031 2.47244 12.0156 3.16244L12.1806 4.13744C12.2331 4.44494 12.0231 4.73744 11.7231 4.78244C11.4156 4.83494 11.1231 4.62494 11.0781 4.32494L10.9131 3.34994C10.8081 2.69744 10.7856 2.56994 9.99056 2.56994H8.02556C7.23056 2.56994 7.21556 2.67494 7.10306 3.34244L6.93056 4.31744C6.88556 4.59494 6.64556 4.78994 6.37556 4.78994Z"
        fill="#DB2222"
      />
      <path
        d="M11.4058 17.5626H6.59084C3.97334 17.5626 3.86834 16.1151 3.78584 14.9451L3.29834 7.39259C3.27584 7.08509 3.51584 6.81509 3.82334 6.79259C4.13834 6.77759 4.40084 7.01009 4.42334 7.31759L4.91084 14.8701C4.99334 16.0101 5.02334 16.4376 6.59084 16.4376H11.4058C12.9808 16.4376 13.0108 16.0101 13.0858 14.8701L13.5733 7.31759C13.5958 7.01009 13.8658 6.77759 14.1733 6.79259C14.4808 6.81509 14.7208 7.07759 14.6983 7.39259L14.2108 14.9451C14.1283 16.1151 14.0233 17.5626 11.4058 17.5626Z"
        fill="#DB2222"
      />
      <path
        d="M10.2436 13.4375H7.74609C7.43859 13.4375 7.18359 13.1825 7.18359 12.875C7.18359 12.5675 7.43859 12.3125 7.74609 12.3125H10.2436C10.5511 12.3125 10.8061 12.5675 10.8061 12.875C10.8061 13.1825 10.5511 13.4375 10.2436 13.4375Z"
        fill="#DB2222"
      />
      <path
        d="M10.875 10.4374H7.125C6.8175 10.4374 6.5625 10.1824 6.5625 9.87494C6.5625 9.56744 6.8175 9.31244 7.125 9.31244H10.875C11.1825 9.31244 11.4375 9.56744 11.4375 9.87494C11.4375 10.1824 11.1825 10.4374 10.875 10.4374Z"
        fill="#DB2222"
      />
    </svg>
  );
}
