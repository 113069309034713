import React, { useState } from 'react';

import FormItemHeader from './FormItemHeader';

const FormItem = ({ title, disabled, defaultChecked, deletable, children,disableDefaultChecked }) => {
  const [isActive, setIsActive] = useState(false);

  const toggleCollapseBar = (e) => {
    setIsActive((value) => !value);
  };

  return (
    <div className="form-item-parent  mb-5  border-r-0">
      <div className="panel-dropdown">
        <FormItemHeader
          // hasArrow={component ? true : false}
          isActive={isActive}
          title={title}
           defaultChecked={defaultChecked}
          //Removed disabled here!
          disabled={['First Name', 'Last Name', 'Email Address'].includes(title)}
           deletable={deletable}
          onClick={toggleCollapseBar}
        />
        {isActive && <div className="border-l border-b border-r p-5">{children}</div>}
      </div>
    </div>
  );
};
export default FormItem;
