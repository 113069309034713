import { useNavigate } from 'react-router-dom';
import { Input } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { LeftArrowIcon } from '../../../asset/Icons/LeftArrow';
import { CloseCircleIcon } from '../../../asset/Icons/CloseCircleIcon';
import {  CheckCircleIcon } from '../../../asset/Icons/CheckCircleIcon';
import { EditTitleIcon } from '../../../asset/Icons/EditTitleIcon';
import { MdTypography } from 'components/global';
import { editActions } from '../../../utils/constants';

const InstantEmailTitle = ({
  editAction,
  instantEmailTitle,
  newInstantEmailTitle,
  setNewInstantEmailTitle,
  editInstantEmailTitle,
  setEditInstantEmailTitle,
  isEditingMail,
  handleUpdateInstantEmailTitle,
}) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="back-arrow">
        <LeftArrowIcon onClick={() => navigate(-1)} />
      </div>
      {editInstantEmailTitle ? (
        <>
          <Input
            placeholder="Instant Email Title"
            type="text"
            data-test="email-title-update-input"
            defaultValue={instantEmailTitle}
            value={newInstantEmailTitle}
            onChange={(e) => setNewInstantEmailTitle(e.target.value)}
          />
          {isEditingMail && editAction === editActions.editTitle ? (
            <LoadingOutlined />
          ) : (
            <>
              <CloseCircleIcon
                data-test="email-title-update-cancel"
                alt="close"
                className="close-icon"
                onClick={() => {
                  setNewInstantEmailTitle(instantEmailTitle);
                  setEditInstantEmailTitle(false);
                }}
              />
              <CheckCircleIcon
                data-test="email-title-update-submit"
                alt="check"
                className="check-icon"
                onClick={() => handleUpdateInstantEmailTitle()}
              />
            </>
          )}
        </>
      ) : (
        <>
          <MdTypography dataTestId="email-title" variant={'h4Medium'}>
            {instantEmailTitle}
          </MdTypography>

          <EditTitleIcon
            data-test="email-title-update"
            alt="edit"
            className="edit-icon"
            onClick={() => {
              setEditInstantEmailTitle(true);
            }}
          />
        </>
      )}
    </>
  );
};

export default InstantEmailTitle;
