import React from 'react';
import { useParams } from 'react-router-dom';
import InstantEmailSubject from 'pages/InstantEmail/components/InstantEmailSubject';
import './editor.scss';

const InstantEmailSubjectOfMail = () => {
  const { mailId } = useParams();

  return <InstantEmailSubject mailId={mailId} />;
};

export default InstantEmailSubjectOfMail;
