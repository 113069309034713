import axios from 'axios';
import Cookie from 'js-cookie';
import { useCallback, useState } from 'react';
import { GoogleLogin } from 'react-google-login';
import { useLocation, useNavigate } from 'react-router-dom';
import { reactivateAccount } from '../../utils/services/authService';

import GoogleIcon from '../../asset/Icons/Google';
import ButtonBase from '../../components/Buttons/ButtonBase';
import CircularSpinner from '../../components/loaders/CircularSpinner';
import { me, setAuthToken } from '../../utils/service';

function GoogleAuthButton({ children }) {
  const [isLoading, setIsloading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const ClientId = process.env.REACT_APP_CLIENTID;
  const [showError, setShowError] = useState(false);

  const handleSuccess = useCallback(
    ({ tokenId }) => {
      (async () => {
        setIsloading(true);
        try {
          const response = await axios.post(`${process.env.REACT_APP_API_URL}/users/oauth/google`, { tokenId });

          const referer = (location.state && location.state.from) || '/';

          if (response.data.success === true) {
            Cookie.set('token', response.data.data.token, { expires: 1 });
            setAuthToken(response.data.data.token);
            await me();
            navigate(referer);
          }
        } catch (error) {
          console.log('error', error.response);
          (async () => {
            const err = error?.response?.data;
            console.log({ err });
            if (err.error_code === 'account_deactivated') {
              try {
                await reactivateAccount(err.email, navigate);
              } catch (error) {
                console.log({ error });
              }
            }
          })();
        } finally {
          setIsloading(false);
        }
      })();
    },
    [navigate, location.state]
  );

  return (
    <>
      <GoogleLogin
        // clientId="565081404950-k8lm1lu83pdtnio8vf1padu3mgtk54b9.apps.googleusercontent.com"
        clientId={ClientId}
        onSuccess={handleSuccess}
        onFailure={(e) => {
          if (e.details === 'Cookies are not enabled in current environment.') {
            setShowError(true);
          }
        }}
        cookiePolicy={'single_host_origin'}
        render={({ onClick, disabled }) => (
          <ButtonBase
            onClick={onClick}
            className="border bg-white text-black flex rounded w-full items-center"
            disabled={disabled}
          >
            <GoogleIcon className="ml-5" />
            <div className="flex-1 items-center justify-center flex">
              {children}
              {isLoading && <CircularSpinner className="absolute right-0 mr-5" />}
            </div>
          </ButtonBase>
        )}
      />
      {showError && (
        <div className="flex align-items-baseline font-bold">
          <span className="text-xs text-blue" style={{ marginTop: '3px' }}>
            <span class="iconify" data-icon="ant-design:info-circle-twotone"></span>
          </span>
          <span className="ml-1" style={{ fontSize: '11px' }}>
            Cookies are not enabled in this current environment. <br />
            Enable third party cookie and click{' '}
            <a
              href="https://support.google.com/chrome/answer/95647#zippy=%2Callow-or-block-cookies"
              className="text-blue click"
              target="_blanc"
            >
              here
            </a>{' '}
            for more details on what enabling third party cookies means to your privacy
          </span>
        </div>
      )}
    </>
  );
}

export default GoogleAuthButton;
