import React, { useEffect, useState } from 'react';
import 'highlight.js/styles/github.css';
import MdEmbeddedForm from './MdEmbeddedForm';
import { getCredentials } from '../../../../utils/service';
import { showToastMessage } from '../../../../utils/Toast';
import MdLandinPageComp from './MdLandinPageComp';
import { MdTypography } from '../../../../components/global';

const FormsTabContent = ({ img, title, subTitle, desc, rightComp }) => (
  <div className="contentContain">
    <div className="left_disp">
      <img src={img} alt="" />
      <div>
        <div className="flex">
          <MdTypography variant={'h6Light'} className="mb-3">
            {title}
          </MdTypography>
          <MdTypography className="intSubTitle">{subTitle}</MdTypography>
        </div>
        <MdTypography variant={'smallTextRegular'}>{desc}</MdTypography>
      </div>
    </div>
    <div className="right_disp">{rightComp}</div>
  </div>
);

const MdFormsTab = ({ data, campaignId }) => {
  const [credencials, setCredencials] = useState({
    apiKey: '',
    secretKey: '',
  });

  useEffect(() => {
    getCredentials()
      .then(({ data: { data } }) => {
        setCredencials(data);
      })
      .catch((e) => {
        showToastMessage({
          type: 'error',
          title: 'Error',
          description: 'Unable to fetch API keys',
        });
      });
  }, []);

  return (
    <div className="formsTab">
      <FormsTabContent
        img="/assets/illustrate-landingpage.svg"
        title="Edit Join Page"
        desc="Build a landing page with signup form and seemlessly collect data to your campaign"
        rightComp={<MdLandinPageComp data={data} campaignId={campaignId} />}
      />
      <FormsTabContent
        img="/assets/illustrate-embeddedform.svg"
        title="Embedded Form"
        desc="Build signup forms and generate HTML code to embed in your site"
        rightComp={<MdEmbeddedForm campaignId={campaignId} credencials={credencials} />}
      />
      <FormsTabContent
        img="/assets/illustrate-integrations.svg"
        title="Integrations"
        subTitle="COMING SOON"
        desc="Collect information from various applications using our integrations"
        rightComp={''}
      />
    </div>
  );
};

export default MdFormsTab;
