import { showToastMessage } from '../../../utils/Toast';
export const onFileDropHandler = (ev, files, setFiles, setIsUploading) => {
  let allFiles = [];
  const storeFiles = (loadedFiles) => {
    setFiles([...files, ...loadedFiles]);
  };
  ev.preventDefault();
  const allFileSizes = files?.reduce((a, b) => a + b?.size, 0) || 0;
  let totalSize = allFileSizes;
  if (ev.dataTransfer.items) {
    // Use DataTransferItemList interface to access the file(s)
    for (let i = 0; i < ev.dataTransfer.items.length; i++) {
      // If dropped items aren't files, reject them
      if (ev.dataTransfer.items[i].kind === 'file') {
        let file = ev.dataTransfer.items[i].getAsFile();
        const fileSize = file.size; // Get the size of the current file in bytes
        totalSize += fileSize;
        // console.log("... file[" + i + "].name = " + file.name);
        allFiles.push({
          file,
          id: String(Date.now() + Math.random() * 1000),
          src: `${URL.createObjectURL(file)}`,
          status: 'WAITING',
          filename: file.name,
          size: fileSize,
          createdAt: new Date(),
        });
      }
    }
  } else {
    // Use DataTransfer interface to access the file(s)
    for (let i = 0; i < ev.dataTransfer.files.length; i++) {
      const file = ev.dataTransfer.files[i];
      const fileSize = file.size; // Get the size of the current file in bytes
      totalSize += fileSize;
      allFiles.push({
        file,
        id: String(Date.now() + Math.random() * 1000),
        src: `${URL.createObjectURL(file)}`,
        status: 'WAITING',
        filename: file.name,
        size: fileSize,
      });
    }
  }

  // Check if the total file size exceeds 25MB (in bytes)
  const maxSizeBytes = 25 * 1024 * 1024; // 25MB in bytes
  if (totalSize > maxSizeBytes) {
    showToastMessage({
      type: 'error',
      title: 'Exceeds Limit',
      description: 'File size exceeds the limit of 25MB',
    });
    setIsUploading(false); // Set uploading state to false
    return; // Return without handling the files further
  }
  storeFiles(allFiles);
  setIsUploading(true);
};
