import { Button, Form, Modal } from 'antd';
import CSVReader from '../../../components/CSVReader';
import { jobContext } from '../../../contexts/job';
import { SET_NEW_JOB } from '../../../contexts/job/reducer/types';
import React, { useContext, useEffect, useState } from 'react';
import { importRecipientsCsvToCampaign } from '../../../utils/service';
import { showToastMessage } from '../../../utils/Toast';

const AddRecipientsToCampaign = ({ imLoading, setImLoading, imVisible, setImVisible, campaignId, setRefresh }) => {
  // const [imLoading, setImLoading] = useState({ id: '' });
  const [csvResult, setCsvResult] = useState([]);
  const { dispatch } = useContext(jobContext);

  const [contactForm] = Form.useForm();
  // console.log({ csvResult });

  const onSubmitContactDetail = () => {
    setImLoading({ id: 'addingRecipient' });
    importRecipientsCsvToCampaign({ contacts: csvResult }, campaignId)
      .then(({ data: { data } }) => {
        setRefresh(data);
        showToastMessage({
          type: 'success',
          title: 'Success',
          description: data.message,
        });

        dispatch({
          type: SET_NEW_JOB,
          payload: {
            status: data.status,
            ID: data.jobId,
          },
        });
      })
      .finally(() => {
        setImVisible({ id: '' });
        contactForm.resetFields();
      });
  };

  /* eslint-disable */

  useEffect(() => {
    contactForm.resetFields();
  }, [imVisible]);

  return (
    <Modal
      // title="Delete Email"
      className="emmaccen"
      open={imVisible?.id === 'campaignRecipient'}
      // onOk={() => handleContactAddition()}
      onCancel={() => {
        setImVisible({ id: '' });
      }}
      footer={null}
      maskClosable={false}
      closable={false}
      afterClose={() => {
        setImVisible({ id: '' });
      }}
      destroyOnClose={true}
      centered
    >
      <div className="addNewContactModal">
        <h2 className="text-center title">Add Recipients</h2>
        <Form
          form={contactForm}
          onFinish={(data) => onSubmitContactDetail(data)}
          onFinishFailed={() => null}
          autoComplete="off"
          size="large"
          // initialValues={{
          //   groups: [currentGroup._id],
          // }}
          layout={'vertical'}
        >
          <CSVReader setCSVResult={setCsvResult} setImVisible={setImVisible} />
          <div className="emmaccen">
            <div className="flex-space-btw actionBtns">
              <Button
                size={'large'}
                type=""
                onClick={() => setImVisible({ id: '' })}
                // icon={<PlusOutlined />}
              >
                Back
              </Button>
              <Button
                size={'large'}
                type="primary"
                htmlType="submit"
                // icon={<PlusOutlined />}
                loading={imLoading?.id === 'addingRecipient'}
              >
                Done
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default AddRecipientsToCampaign;
