export default function findTextComponent(component) {
  // Recursive function to find the text component
  if (component?.get('type') === 'text') {
    return component;
  }

  const components = component?.get('components');

  if (components) {
    // Check if it's an array or a collection
    const iterableComponents = Array.isArray(components) ? components : components?.models || [];

    // Traverse through child components
    for (const childComponent of iterableComponents) {
      const found = findTextComponent(childComponent);
      if (found) {
        return found;
      }
    }
  }

  return null;
}
