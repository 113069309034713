import { Modal } from 'antd';
import { Maildrip001 } from '../Templates/Maildrip001';

const PreviewTemplate = ({ title, visible, setVisible, templateId }) => {
  return (
    <Modal
      title={title}
      centered
      open={visible}
      footer={null}
      onCancel={setVisible}
      width={1000}
      style={{ margin: '20px 0' }}
    >
      <div>{templateId === '1' ? <Maildrip001 isPreview /> : <></>}</div>
    </Modal>
  );
};

export default PreviewTemplate;
