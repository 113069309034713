const type = 'placeholder-var';
const val = '{{ SOME_VAR_EXAMPLE_2 }}';
const plh = "I'm a placeholder var, check the code";

const block = (editor, opts = {}) => {
  const { blockCustomCode, blockLabel } = opts;
  editor.BlockManager.add(type, {
    // label: 'Placeholder var',
    // label: blockLabel,
    label: `<div style="height: 66px; width: 100%; display: flex; flex-direction: column; justify-content: space-between;"><img width='40' style='margin:0 auto;' src='/assets/code.png' />
    <div>${blockLabel}</div></div>`,
    content: {
      type,
      val,
      plh,
    },
    category: 'Basic',
    // attributes: { class: 'fa fa-share-alt' },
    select: true,
    ...blockCustomCode,
  });
};
export default block;
