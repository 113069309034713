import React from 'react';
import { Link } from 'react-router-dom';
import { RightOutlined } from '@ant-design/icons';

export const BreadCrumb = ({ crumbs = [], rightPan, boomContain, addClass }) => {
  const sortByCrumbIndex = (a, b) => {
    return a.index - b.index;
  };
  crumbs.sort((a, b) => sortByCrumbIndex(a, b));
  return (
    <div
      className={`${boomContain ? 'boomBreadCrumbContainer' : 'breadCrumbContainer'} ${
        rightPan && 'flex justify-between'
      } ${addClass && addClass}`}
    >
      <div className="flex items-center">
        {crumbs.map(({ name, link, state, search }) => {
          let linkTo = link;
          if (search) {
            linkTo = {
              pathname: link,
              search: search,
            };
          }
          return link ? (
            <Link title={name} key={name} to={linkTo} className="crumb" state={state ? { mailTitle: state.mailTitle } : null}>
              {name.length > 10 ? name.slice(0, 10).concat('...') : name} <RightOutlined />
            </Link>
          ) : (
            <span title={name} key={name} className="crumb">
              {name.length > 10 ? name.slice(0, 10).concat('...') : name} <RightOutlined />
            </span>
          );
        })}
      </div>
      {rightPan}
    </div>
  );
};
