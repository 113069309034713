import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card } from 'antd';

const TotalAnalytics = () => {
  const [totalAnalytics, setTotalAnalytics] = useState({});

  const api__url = process.env.REACT_APP_API_URL;

  const FetchTotalAnalytics = async () => {
    const { data } = await axios.get(`${api__url}/dashboard/analysis`);
    setTotalAnalytics(data.data);
  };
  /* eslint-disable */
  useEffect(() => {
    FetchTotalAnalytics();
  }, []);

  return (
    <>
      <h5 className="totalAnalyticsTitle">Analytics</h5>
      <Card className="margLR">
        <div>
          <div className="totalAnalytics">
            <p>Total Email Sent</p>
            <h6>{totalAnalytics.totalEmailsSent}</h6>
          </div>
          <div className="totalAnalytics">
            <p>Total Recipients</p>
            <h6>{totalAnalytics.totalRecipents}</h6>
          </div>
          <div className="totalAnalytics">
            <p>Total Opens</p>
            <h6>{totalAnalytics.totalOpens}</h6>
          </div>
          <div className="totalAnalytics">
            <p>Total Clicks</p>
            <h6>{totalAnalytics.totalClicks}</h6>
          </div>
          <div className="totalAnalytics">
            <p>Unsubscribed</p>
            <h6>{totalAnalytics.totalUnsubscribes}</h6>
          </div>
          <div className="totalAnalytics">
            <p>Spam</p>
            <h6>{totalAnalytics.totalSpams}</h6>
          </div>
        </div>
        <p className="totalAnalyticsFooter">Contains total email analytics of all time</p>
      </Card>
    </>
  );
};

export default TotalAnalytics;
