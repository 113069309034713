/* eslint-disable */
import { Spin } from 'antd';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useEditCampaignLandingPageMutation } from 'redux/featureApi/campaignApiSlice';
import { useUploadMarkdownImageMutation } from 'redux/featureApi/resourceApiSlice';
import { ReactComponent as LeftArrow } from '../../asset/Icons/left-arrow-clk.svg';
import defaultLogo from '../../asset/images/deliver.png';
import { joinCampaignContext } from '../../contexts/joincampaign';
import { SET_EDITING_MODE, SET_LINK, SET_PUBLISHING } from '../../contexts/joincampaign/reducer/types';
import '../../styles/editPage.scss';
import { showToastMessage } from '../../utils/Toast';
import { editModeEnum } from '../../utils/constants';
import ConfirmationPage from './ConfirmationPage';
import AddNewFormFieldsModal from './EditCampaign/AddNewFormFieldsModal';
import DesignCompaign from './EditCampaign/DesignCampaign';
import FormFields from './EditCampaign/FormFields';
import MenuItems from './EditCampaign/MenuItems';

export default function EditPage({ campaignId }) {
  const { button: buttonEnum } = editModeEnum;
  const [showingAddNewModal, setShowingAddNewModal] = useState(false);

  const [postPublishPageRequest] = useEditCampaignLandingPageMutation();
  const [uploadMarkdownImage] = useUploadMarkdownImageMutation();

  const { state, dispatch } = useContext(joinCampaignContext);
  const confirmationPageLinkInputRef = useRef(null);
  const navigate = useNavigate();

  const [sidebarItem, setSidebarItem] = useState({});
  const {
    btnText,
    alttext,
    sectionbackground,
    tagline,
    catchphrase,
    btnColor,
    logo,
    publishing,
    displayName,
    displayLastName,
    displayCompanyName,
    displayPhoneNumber,
    setLogoFiles,
    displayEmail,
    formFields,
    setLink,
  } = state;

  useEffect(() => {
    setMenuItemsActive();
  }, []);

  const setDesignActive = () => {
    setSidebarItem({ title: 'Design', component: <DesignCompaign /> });
  };

  const setFormFormActive = () => {
    setSidebarItem({
      title: 'Form Fields',
      component: <FormFields onClickAddField={() => setShowingAddNewModal(true)} />,
    });
  };
  const setConfirmationActive = () => {
    setSidebarItem({
      title: 'Confirmation Page',
      component: <ConfirmationPage ref={confirmationPageLinkInputRef} />,
    });
  };

  const setMenuItemsActive = () => {
    setSidebarItem({
      id: 0,
      title: 'Edit Join Page',
      component: (
        <MenuItems
          onClickDesignFunc={setDesignActive}
          onClickFormFieldFunc={setFormFormActive}
          onClickConfirmationFunc={setConfirmationActive}
          setLink={setLink}
        />
      ),
    });
  };

  const backToCampaign = () => {
    navigate(-1);
  };

  const publishPage = async () => {
    setPublishing(true);

    const fileReader = new FileReader();
    let fileResult = '';

    if (setLogoFiles) {
      fileReader.readAsDataURL(setLogoFiles[0]);
      fileReader.onloadend = () => {
        fileResult = fileReader.result;
      };
    }

    let joinCampaignConfirmationLink = setLink;

    if (confirmationPageLinkInputRef.current?.input) {
      joinCampaignConfirmationLink = confirmationPageLinkInputRef.current?.input?.value;
    }

    if (sidebarItem.title === 'Confirmation Page') {
      // Validate the joinCampaignConfirmationLink url with regex
      const urlRegex = /(https?:\/\/)(www\.)?[a-zA-Z]+\.([a-zA-Z]{2,})(\/[a-zA-Z0-9-_]*)?/;

      if (urlRegex.test(joinCampaignConfirmationLink) === false) {
        showToastMessage({
          type: 'error',
          title: 'Error',
          description: 'Please enter a valid url',
        });
        setConfirmationActive();
        setPublishing(false);
        return;
      }
    }

    let resourceData;

    if (setLogoFiles && logo !== fileResult) {
      const res = await uploadMarkdownImage(setLogoFiles[0]);
      resourceData = res?.data?.data?.resource;
    }

    const payload = {
      button_text: btnText,
      alttext,
      background_color: sectionbackground,
      tagline,
      headline: catchphrase,
      button_bg_color: btnColor,
      displayName,
      displayLastName,
      displayEmail,
      displayPhoneNumber,
      displayCompanyName,
      logo_url: resourceData || logo || '',
      form_fields: formFields,
      success_redirect_url: joinCampaignConfirmationLink,
    };
    const formData = new FormData();
    Object.entries(payload).forEach((element) => {
      formData.append(element[0], element[1]);
    });

    postPublishPageRequest({ payload, campaignId })
      .unwrap()
      .then((res) => {
        dispatch({ type: SET_LINK, payload: joinCampaignConfirmationLink });
        showToastMessage({
          type: 'success',
          title: 'Published',
          description: 'Your page has been published',
        });
      })
      .catch((error) => {
        setPublishing(false);
        showToastMessage({
          type: 'error',
          title: 'Error',
          description: 'An error occured',
        });
      })
      .finally(() => {
        setPublishing(false);
      });
  };

  const setEditMode = (mode) => {
    dispatch({
      type: SET_EDITING_MODE,
      payload: mode,
    });
  };

  const setPublishing = (bool) => {
    dispatch({
      type: SET_PUBLISHING,
      payload: bool,
    });
  };

  return (
    <div className="flex w-full">
      <AddNewFormFieldsModal isShowingModal={showingAddNewModal} closeModal={() => setShowingAddNewModal(false)} />
      <div className="campaign-sidebar flex-shrink-0 w-full border-r">
        <div className=" h-screen">
          <Sidebar
            onClickBackFunc={sidebarItem.id === 0 ? backToCampaign : setMenuItemsActive}
            title={sidebarItem.title}
            component={sidebarItem.component}
          />
          <button
            className="btn btn-blue bg-black pl-10 pr-10 float-right mr-5"
            disabled={publishing}
            onClick={publishPage}
            data-test="save-join-capaign-info"
          >
            {publishing ? (
              <>
                <Spin />
                Publishing...
              </>
            ) : (
              'Save'
            )}
          </button>
        </div>
      </div>
      <div className="admin-join-page-wrapper bg-white flex-grow ">
        <div className="left mx-auto">
          <div className="inner">
            {/* <p className="mb-5">Create engaging campaigns with your audience</p> */}

            <div
              className="edit-join-page-wrapper shadow-md "
              style={{
                backgroundColor: sectionbackground,
              }}
            >
              <div className="inner">
                <div className="edit-logo">
                  <img
                    src={logo || defaultLogo}
                    className="m-auto mb-3 click"
                    // onClick={() => {
                    //   setEditMode(logoEnum);
                    // }}
                    alt={alttext}
                  />
                </div>

                <h2
                  className="mb-3 leading-tight capitalize font-weight-bold click"
                  onClick={() => {
                    setEditMode(buttonEnum);
                  }}
                >
                  {tagline}
                </h2>
                <p
                  className="mb-8 click"
                  onClick={() => {
                    setEditMode(buttonEnum);
                  }}
                >
                  {catchphrase}
                </p>
                <div>
                  {formFields
                    .filter((item) => item.enabled)
                    .map((item, index) => (
                      <input
                        key={index}
                        placeholder={item.placeholder}
                        onChange={(e) => console.log(e)}
                        className={
                          'appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white mb-2 click'
                        }
                      />
                    ))}
                </div>
                <button
                  className="w-full p-3 mt-2 font-bold text-white rounded-md  bg-gray-600"
                  style={{ backgroundColor: btnColor }}
                  onClick={() => {
                    setEditMode(buttonEnum);
                  }}
                >
                  {btnText}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const Sidebar = ({ title, component, onClickBackFunc }) => (
  <>
    <div className="flex px-5 py-8 border-b">
      <LeftArrow onClick={onClickBackFunc} className="sidebar-left-arrow cursor-pointer" />
      <p className="flex-grow flex justify-center items-center">{title}</p>
    </div>
    <div className="p-5 ">{component}</div>
  </>
);
