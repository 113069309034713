import { ExclamationCircleOutlined, ExpandAltOutlined, PaperClipOutlined } from '@ant-design/icons';
import { Collapse, Form, Input, Select } from 'antd';
import { MdGlobalButton, MdTypography } from '../../../components/global';
import React, { useEffect, useState } from 'react';
import { intervals } from '../../../utils/constants';
import convertSecondsToNearestDur from '../../../utils/convertSecondsToNearestDuration';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../../../redux/featureSlices/authSlice';
import { showToastMessage } from '../../../utils/Toast';

export const MdEmailPreview = ({
  preview,
  setPreview,
  campaignId,
  firstMail,
  activeMail,
  campaignMailId,
  editEmail,
  isUpdatingEmailInterval,
  data,
}) => {
  const [isFullMode, setIsFullMode] = useState(false);
  const emailsList = data;
  const firstEmailCreated = emailsList?.at(0);

  const [intervalForm] = Form.useForm();

  const location = useLocation();

  const { Panel } = Collapse;
  const { Option } = Select;

  const appUser = useSelector(selectCurrentUser);
  /* eslint-disable */
  useEffect(() => {
    if (preview?.emailInterval) {
      const interval = convertSecondsToNearestDur(preview?.emailInterval);
      intervalForm.setFieldsValue({
        ['number']: interval?.int,
        ['seconds']: intervals[interval?.unit],
      });
    } else {
      intervalForm.resetFields();
    }
  }, [preview]);

  useEffect(() => {
    if (firstEmailCreated) {
      setPreview(firstEmailCreated);
    }
  }, [firstEmailCreated]);

  function handleEmailInterval(emailInterval, campaignId, id) {
    editEmail({ campaignId, pb: { emailInterval, id } })
      .then(() => {
        showToastMessage({
          type: 'success',
          title: 'Request Successful',
          description: 'Email interval updated successfully',
        });
      })
      .catch((e) => {
        showToastMessage({
          type: 'error',
          title: 'Error',
          description: e.response?.data?.fieldsError?.interval?.msg || e.response?.data?.message,
        });
      });
  }

  const Schedule1 = () => {
    return (
      (activeMail?.id === 'mail' || activeMail?.id === null || activeMail?.id === undefined) && (
        <Collapse bordered={false} expandIconPosition="end" defaultActiveKey={['1']}>
          <Panel header="Scheduling" className="scheduling" key="1">
            <div className="firstSched">
              <span className="mr-2">
                <ExclamationCircleOutlined style={{ color: '#1677FF' }} />
              </span>
              <MdTypography variant={'captionRegular'} style={{ color: '#1677FF' }}>
                {emailsList?.length > 0
                  ? ` This is the first email in your campaign sequence and is sent first when your campaign becomes active.
                Click on other emails to schedule them.`
                  : 'Information about scheduling your campaign in order of sequence and timing will show up here when you add emails.'}
              </MdTypography>
            </div>
          </Panel>
        </Collapse>
      )
    );
  };

  const Schedule2 = () => {
    return (
      (activeMail?.id === 'mail' || activeMail?.id === null || activeMail?.id === undefined) && (
        <Collapse bordered={false} expandIconPosition="end" defaultActiveKey={['1']}>
          <Panel header="Scheduling" className="scheduling" key="1">
            <div className="schedule2">
              <div>
                <MdTypography variant={'smallTextMedium'} className="scheduleSmallText">
                  Send this email after
                </MdTypography>
              </div>
              <div>
                <Form
                  name="basic"
                  onFinish={({ number, seconds }) =>
                    handleEmailInterval(`${number * seconds}`, campaignId, preview._id)
                  }
                  onFinishFailed={() => null}
                  autoComplete="off"
                  form={intervalForm}
                  size="large"
                  className="formOne"
                  layout="vertical"
                >
                  <div className="form_container">
                    <div className="flex form_inputs">
                      <Form.Item
                        name="number"
                        rules={[{ required: true, message: 'Please provide a value' }]}
                        style={{ width: 60 }}
                        className="mr-2"
                      >
                        <Input type="number" placeholder="5" min={1} style={{ width: 60 }} className='instant-email-preview-number-input'/>
                      </Form.Item>
                      <Form.Item name="seconds" rules={[{ required: true, message: 'Please provide a value' }]}>
                        <Select
                          className='select-duration-list'
                          showSearch
                          placeholder="Days"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          style={{
                            width: 80,
                          }}
                        >
                          <Option value={intervals.minutes} className="select-option">Minute(s)</Option>
                          <Option value={intervals.hours}>Hour(s)</Option>
                          <Option value={intervals.days}>Day(s)</Option>
                          <Option value={intervals.weeks}>Week(s)</Option>
                          <Option value={intervals.months}>Month(s)</Option>
                          <Option value={intervals.years}>Year(s)</Option>
                        </Select>
                      </Form.Item>
                    </div>
                    <div className="form_submit pt-1 flex-jst-right">
                      <MdGlobalButton id={'primary_default'} htmltype="submit" loading={isUpdatingEmailInterval} data-test = "instant-email-schedule-button">
                        <MdTypography variant={'smallTextMedium'} style={{ color: '#ffffff' }}>
                          Save
                        </MdTypography>
                      </MdGlobalButton>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </Panel>
        </Collapse>
      )
    );
  };

  return (
    <div className="emmaccen">
      <div className="emailPreviewContainer">
        {isFullMode && (
          <div
            className="fullPreviewModeContainer centered"
            onClick={(e) => {
              setIsFullMode(false);
            }}
          >
            <div className="previewFullMode">
              <h1 className="previewTitle">{preview.subject}</h1>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
                dangerouslySetInnerHTML={{ __html: preview?.body }}
              ></div>
              <div className="flex files">
                {preview?.attachments?.map((attachment, index) => (
                  <div
                    title={attachment.filename}
                    onClick={() => window.open(attachment.src, '_blank')}
                    className="content"
                    key={index}
                  >
                    <h1>{attachment.filename}</h1>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        <div className="campaignePreview">
          {location.pathname === `/campaign/${campaignId}/${campaignMailId}/mailtemplate-confirm` &&
          firstMail === 'yes' ? (
            <Schedule1 />
          ) : location.pathname === `/campaign/${campaignId}/${campaignMailId}/mailtemplate-confirm` &&
            firstMail === 'no' ? (
            <Schedule2 />
          ) : preview?._id !== firstEmailCreated?._id ? (
            <Schedule2 />
          ) : (
            <Schedule1 />
          )}
          {emailsList?.length > 0 ? (
            <>
              <div className="flex justify-end mt-1">
                <ExpandAltOutlined className="pointer mb-1" onClick={() => setIsFullMode(true)} />
              </div>
              <div className="userProfile">
                <div className="flex">
                  <div className="centered">
                    <img src={appUser.picture ?? '/assets/avatar.svg'} alt="user" className="userProfile"></img>
                  </div>
                  <div>
                    <p className="title">{appUser?.name}</p>
                    <p className="fade3">{appUser?.email}</p>
                  </div>
                </div>
              </div>
              <div className="margin-t-b">
                <h3 className="fade3 subject">Subject:</h3>
                <h2 className="bold title previewText overflow-hidden">{preview?.subject}</h2>
              </div>
              <div dangerouslySetInnerHTML={{ __html: preview?.body }}></div>
              <div className="margin-t-b">
                {preview?.attachments?.length > 0 ? (
                  <div className="bold items-center flex">
                    <PaperClipOutlined className="brownishColor" /> <p>{preview?.attachments?.length} Attachment(s)</p>
                  </div>
                ) : null}
              </div>
            </>
          ) : (
            <div className="flex actionBtns mt-4 justify-center">
              {/* <Button
                onClick={() => setModalVisible({ id: 'createNewEmailModal' })}
                size={'large'}
                type="primary"
                className="flex items-center justify-center text-sm btnPrimary w-full"
              >
                Add new email
              </Button> */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
