import CircularSpinner from '../../components/loaders/CircularSpinner';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../../styles/unsubscribePage.scss';
import { unsubscribeFromCampaign } from '../../utils/service';
import { showToastMessage } from '../../utils/Toast';

export const Unsubscribe = () => {
  const { search } = useLocation();
  const [loading, setLoading] = useState(true);
  const [isError, setError] = useState(false);

  const token = new URLSearchParams(search).get('token');
  const email = new URLSearchParams(search).get('email');
  /* eslint-disable */

  useEffect(() => {
    unsubscribeFromCampaign(token, email)
      .then(({ data }) => {
        showToastMessage({
          type: 'success',
          title: 'Unsubscribed',
          description: data.data.message,
        });
      })
      .catch((error) => {
        const err = error?.response?.data;
        setError(true);
        if (err) {
          showToastMessage({
            type: 'error',
            title: 'Error',
            description: err.error,
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <div className="emmaccen">
      <div className="unsubscribePageContainer">
        <div className="centered cardContainer">
          <div className="card">
            <h3 className="usePurple textCenter text-xl bold">Unsubscribe</h3>
            <div className="centered m-5 mb-10">
              <img src="/assets/unsubscribe.svg" alt="unsubscribe" />
            </div>

            {loading ? (
              <CircularSpinner className="mx-auto" />
            ) : !isError ? (
              <>
                <p className="paragraph text-center">
                  You have successfully unsubscribed from this campaign. You will no longer recieve emails from this
                  campaign.
                </p>
                <p className="paragraph text-center">
                  Unsubscribed by mistake?
                  <Link to={`subscribe?token=${token}&email=${email}`} className="purple ml-2 bold usePurple">
                    Subscribe back
                  </Link>
                </p>
              </>
            ) : (
              <p className="text-red-500 text-center">
                Oops! an error occured while trying to unsubscribe you from this campaign.
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
