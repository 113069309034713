import React from 'react';

export const MdPreview = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
      <g clipPath="url(#clip0_6387_18070)">
        <path
          d="M11.5318 6.56283H9.17518C8.00268 6.56283 7.43685 5.99116 7.43685 4.82449V2.46783C7.43685 1.29533 8.00852 0.729492 9.17518 0.729492H11.5318C12.7044 0.729492 13.2702 1.30116 13.2702 2.46783V4.82449C13.2702 5.99116 12.6985 6.56283 11.5318 6.56283ZM9.17518 1.60449C8.48685 1.60449 8.31185 1.77949 8.31185 2.46783V4.82449C8.31185 5.51283 8.48685 5.68783 9.17518 5.68783H11.5318C12.2202 5.68783 12.3952 5.51283 12.3952 4.82449V2.46783C12.3952 1.77949 12.2202 1.60449 11.5318 1.60449H9.17518ZM4.82352 6.56283H2.46685C1.29435 6.56283 0.728516 6.04366 0.728516 4.97033V2.32199C0.728516 1.24866 1.30018 0.729492 2.46685 0.729492H4.82352C5.99602 0.729492 6.56185 1.24866 6.56185 2.32199V4.96449C6.56185 6.04366 5.99018 6.56283 4.82352 6.56283ZM2.46685 1.60449C1.68518 1.60449 1.60352 1.82616 1.60352 2.32199V4.96449C1.60352 5.46616 1.68518 5.68199 2.46685 5.68199H4.82352C5.60518 5.68199 5.68685 5.46033 5.68685 4.96449V2.32199C5.68685 1.82033 5.60518 1.60449 4.82352 1.60449H2.46685ZM4.82352 13.2712H2.46685C1.29435 13.2712 0.728516 12.6995 0.728516 11.5328V9.17616C0.728516 8.00366 1.30018 7.43783 2.46685 7.43783H4.82352C5.99602 7.43783 6.56185 8.00949 6.56185 9.17616V11.5328C6.56185 12.6995 5.99018 13.2712 4.82352 13.2712ZM2.46685 8.31283C1.77852 8.31283 1.60352 8.48783 1.60352 9.17616V11.5328C1.60352 12.2212 1.77852 12.3962 2.46685 12.3962H4.82352C5.51185 12.3962 5.68685 12.2212 5.68685 11.5328V9.17616C5.68685 8.48783 5.51185 8.31283 4.82352 8.31283H2.46685ZM11.9577 10.6462H8.45768C8.21852 10.6462 8.02018 10.4478 8.02018 10.2087C8.02018 9.96949 8.21852 9.77116 8.45768 9.77116H11.9577C12.1968 9.77116 12.3952 9.96949 12.3952 10.2087C12.3952 10.4478 12.1968 10.6462 11.9577 10.6462Z"
          fill="#717171"
        />
        <path
          d="M10.209 12.3955C9.96982 12.3955 9.77148 12.1972 9.77148 11.958V8.45801C9.77148 8.21884 9.96982 8.02051 10.209 8.02051C10.4482 8.02051 10.6465 8.21884 10.6465 8.45801V11.958C10.6465 12.1972 10.4482 12.3955 10.209 12.3955Z"
          fill="#717171"
        />
      </g>
      <defs>
        <clipPath id="clip0_6387_18070">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
