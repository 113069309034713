import React from 'react';

export const TextEditorImage = () => {
  return (
    <svg width="166" height="134" viewBox="0 0 166 134" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.4497 36.4114C48.4767 36.3596 49.5319 35.963 50.4663 35.4924C53.7329 33.8456 56.5063 31.3646 58.505 28.3007C60.4855 30.2066 63.02 31.434 65.7433 31.8061L65.7174 31.994C66.9071 37.6355 65.4807 44.2926 61.9242 48.8306C61.3078 49.6179 60.6332 50.3572 59.9588 51.0959C59.1406 51.9921 58.3229 52.8879 57.6103 53.8689C56.3102 55.6585 55.3602 57.8569 55.6913 60.0431L55.8827 60.0742C57.774 60.2604 59.6379 60.5172 61.1224 60.7552C61.5553 60.8248 61.9553 60.8915 62.3156 60.9552L62.3323 60.9581C62.4433 60.9781 62.564 60.9997 62.688 61.0242C63.2207 61.1226 63.8756 61.2535 64.6018 61.4035C70.2417 59.7258 73.297 55.2274 75.2436 50.3687V50.367L75.3763 40.3374C75.4017 38.2425 76.2113 36.2334 77.6453 34.7061L77.6436 34.7044C76.4506 26.5144 71.3176 16.7332 57.136 16.2503C45.612 15.8576 40.7673 25.7828 38.2377 30.9652C37.4575 32.5636 36.8975 33.7108 36.4298 33.9648C35.3513 34.5491 34.107 34.6374 32.869 34.7251C32.4997 34.7511 32.131 34.7772 31.7675 34.8165C24.7827 35.5654 19.1205 41.6786 17.5515 48.5254C15.9826 55.3723 17.2687 64.9761 24.857 72.4866L24.8931 72.521C30.5211 66.6324 37.7316 62.969 43.9129 61.1036C44.5561 60.9086 45.1888 60.7345 45.8061 60.5777C46.701 60.3535 47.5648 60.169 48.3906 60.0224C48.4258 60.0152 48.461 60.009 48.4953 60.0033L48.513 60.0001C49.0422 52.1319 48.6861 44.2293 47.4516 36.4406L47.4497 36.4114ZM63.5872 18.7237C63.4468 18.6599 63.2812 18.722 63.2174 18.8624C63.1537 19.0028 63.2159 19.1686 63.3563 19.2323C66.0101 20.4374 68.38 22.1887 70.3111 24.3718C72.2423 26.5549 73.6915 29.1206 74.5639 31.9016C74.6101 32.0489 74.7669 32.1308 74.914 32.0847C75.0613 32.0385 75.143 31.8817 75.0969 31.7344C74.201 28.8785 72.7128 26.2435 70.7295 24.0016C68.7464 21.7598 66.3127 19.9613 63.5872 18.7237Z"
        fill="#171717"
      />
      <path
        d="M157.961 68.1898C159.526 70.0278 160.445 72.478 160.171 74.878C159.966 76.6712 159.123 78.3453 158.03 79.7816C156.777 81.4107 155.209 82.7709 153.42 83.7817C151.631 84.7924 149.656 85.4328 147.614 85.6646L147.473 85.6594L147.787 77.6763L147.495 77.5143C148.618 77.4471 149.726 77.2402 150.674 76.6626C152.171 75.7502 153.026 74.0194 153.247 72.2813C153.468 70.5433 153.128 68.783 152.69 67.0861C152.451 66.1637 152.181 65.2499 151.881 64.3448L151.937 64.3262C154.229 65.0898 156.395 66.3519 157.961 68.1898Z"
        fill="white"
      />
      <path
        d="M146.453 37.0891C146.528 35.1756 145.862 33.3066 144.593 31.8726C143.323 30.4385 141.549 29.5499 139.641 29.3924L139.642 29.3665L141.599 29.3752C142.629 29.3251 143.657 29.4898 144.62 29.8588C145.583 30.2279 146.458 30.793 147.19 31.5185C147.922 32.2441 148.495 33.1139 148.873 34.0731C149.251 35.032 149.425 36.0591 149.384 37.0891L148.507 60.0657L148.255 66.6727L148.282 66.8643C147.295 66.9988 146.318 67.0986 145.331 67.1744L145.308 66.8951L146.453 37.0891Z"
        fill="#171717"
      />
      <path
        d="M136.086 102.784C137.085 102.876 138.092 102.764 139.046 102.454C139.999 102.144 140.88 101.643 141.634 100.982C142.388 100.321 142.999 99.5136 143.431 98.6086C143.863 97.7035 144.106 96.7201 144.145 95.7182L144.853 77.6762L144.858 77.5158C145.379 77.5345 145.887 77.5434 146.375 77.5434C146.746 77.5434 147.12 77.5365 147.492 77.5142L147.784 77.6762L147.47 85.6592L147.075 95.7182C146.908 99.9477 143.275 103.33 139.061 102.941H136.986L136.981 102.994L136.074 102.912L136.086 102.784Z"
        fill="#171717"
      />
      <path
        d="M130.429 105.571C130.531 105.583 130.625 105.628 130.699 105.7C130.772 105.772 130.819 105.866 130.833 105.968C130.847 106.07 130.826 106.173 130.775 106.262C130.724 106.351 130.644 106.42 130.55 106.459L102.195 118.144C101.445 118.452 100.613 118.502 99.8313 118.285L59.1077 106.979C59.013 106.953 58.929 106.898 58.8679 106.821C58.8068 106.744 58.7718 106.65 58.7679 106.551C58.7642 106.453 58.7916 106.356 58.8465 106.275C58.9014 106.193 58.9807 106.132 59.0733 106.098L77.4687 99.4223C77.7076 99.3342 77.9642 99.3048 78.2171 99.3361L130.429 105.571Z"
        fill="#171717"
      />
      <path
        d="M130.33 45.9205V49.969L104.363 51.4173L104.677 46.9688H104.687C107.577 46.6895 110.236 45.0652 113.136 44.9809C115.122 44.9222 117.052 45.5929 118.908 46.3017C120.765 47.0104 122.64 47.7757 124.621 47.9217C126.602 48.0676 128.755 47.4818 129.985 45.9197L130.33 45.9205Z"
        fill="white"
      />
      <path
        d="M130.33 41.9202V45.9203H129.985C128.756 47.4824 126.602 48.0669 124.621 47.9222C122.64 47.7775 120.764 47.0098 118.909 46.3022C117.054 45.5946 115.123 44.9228 113.137 44.9814C110.236 45.0658 107.578 46.6901 104.688 46.9694H104.678L104.931 43.3865L130.33 41.9202Z"
        fill="#171717"
      />
      <path
        d="M136.075 102.912L77.0174 97.5245C76.2983 97.4578 75.5732 97.5467 74.8915 97.7852L52.7341 105.503C52.5604 105.563 52.4008 105.657 52.2651 105.781C51.0773 106.195 51.5687 111.054 52.7669 111.436L73.8985 118.173L73.8968 118.18C73.8968 118.18 73.7312 118.68 73.4277 119.497C73.0864 120.419 72.5691 121.745 71.9195 123.208C70.3453 126.754 67.9918 131.106 65.4581 132.475C60.9563 134.91 18.9669 129.554 15.6808 101.928C14.122 88.8202 18.4791 79.2289 24.8913 72.5213C30.5191 66.6333 37.7297 62.9689 43.9109 61.1039C43.1137 62.5121 42.6834 64.0981 42.6592 65.716C42.6397 67.3513 43.165 68.9467 44.1522 70.2505C45.6074 72.1093 47.9592 73.0422 50.2766 73.4818C52.2504 73.8576 54.3147 73.93 56.2458 73.3783C57.4841 73.0245 58.6378 72.423 59.6372 71.6106C60.6368 70.7982 61.4609 69.7913 62.0602 68.6514C62.6594 67.5112 63.0212 66.2614 63.1236 64.9775C63.2262 63.6936 63.0672 62.4025 62.6564 61.1817L62.6856 61.0249C63.2184 61.1232 63.8736 61.2541 64.5995 61.4042C68.6893 62.249 75.0619 63.7111 75.0619 63.7111L74.6826 92.25C74.6643 93.5754 75.1466 94.8589 76.0333 95.8442C76.9199 96.8296 78.1455 97.4442 79.4655 97.5657L136.086 102.785L136.075 102.912Z"
        fill="white"
      />
      <path
        d="M62.6559 61.1813C63.0668 62.4019 63.2257 63.6933 63.1233 64.9772C63.0209 66.2611 62.6591 67.5109 62.0598 68.6508C61.4606 69.791 60.6363 70.7976 59.6369 71.6102C58.6375 72.4227 57.4838 73.0239 56.2453 73.3779C54.3142 73.9296 52.2503 73.8572 50.2761 73.4814C47.9587 73.0418 45.6069 72.1089 44.1517 70.2502C43.1645 68.9462 42.6394 67.3509 42.6587 65.7157C42.6829 64.0977 43.1134 62.5116 43.9104 61.1035C44.5536 60.9085 45.1863 60.7344 45.8036 60.5776L45.8053 60.5845C44.1553 62.6948 44.4742 65.8829 45.9983 68.0848C47.1407 69.7355 48.8324 70.9265 50.7718 71.4453C52.7109 71.964 54.7715 71.7767 56.5854 70.9166C58.3994 70.0567 59.8486 68.5801 60.6745 66.7504C61.5003 64.9207 61.6491 62.857 61.094 60.9279L61.1199 60.7555C61.5528 60.8245 61.9528 60.8918 62.3131 60.9554C62.4285 60.9761 62.5552 60.9987 62.6855 61.0245L62.6559 61.1813Z"
        fill="white"
      />
      <path
        d="M75.2402 50.3674L75.373 40.3377C75.4032 38.1974 76.2474 36.1489 77.7343 34.6092C79.2212 33.0695 81.2389 32.1542 83.3767 32.0494L138.665 29.3753C138.989 29.3593 139.314 29.365 139.637 29.3924C141.546 29.55 143.32 30.4385 144.589 31.8727C145.858 33.3067 146.525 35.1756 146.449 37.0893L145.304 66.8953"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M144.855 77.6763L144.148 95.7183C144.108 96.7203 143.865 97.7038 143.433 98.6088C143.001 99.5138 142.39 100.321 141.636 100.982C140.882 101.644 140.001 102.144 139.048 102.454C138.094 102.764 137.087 102.876 136.089 102.784L79.467 97.5649C78.147 97.4437 76.9214 96.829 76.0348 95.8436C75.1481 94.8582 74.6658 93.5747 74.6841 92.2493L75.0634 63.7104L75.2427 50.3687"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M139.402 77.1299L138.905 91.9648C138.883 92.6417 138.721 93.3065 138.432 93.9185C138.142 94.5306 137.73 95.0767 137.221 95.5235C136.712 95.9702 136.117 96.3078 135.473 96.5156C134.828 96.7234 134.148 96.7969 133.474 96.7318L84.1157 93.2581C82.7687 93.1275 81.52 92.495 80.6176 91.4863C79.7151 90.4776 79.2252 89.1663 79.2447 87.813L80.907 43.0581C80.9286 41.5235 81.541 40.0562 82.6167 38.9615C83.6926 37.8668 85.149 37.2289 86.6829 37.1804L134.707 35.6561C135.488 35.6316 136.265 35.7675 136.991 36.0554C137.717 36.3433 138.377 36.7769 138.928 37.3298C139.48 37.8828 139.913 38.5429 140.199 39.2697C140.485 39.9963 140.62 40.7741 140.593 41.5546L139.735 67.2097"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M78.7845 116.292L52.7682 107.988C52.5509 107.918 52.3553 107.793 52.201 107.624C52.0466 107.456 51.9388 107.25 51.8882 107.028C51.8375 106.805 51.8459 106.573 51.9122 106.354C51.9785 106.136 52.1006 105.938 52.2663 105.781C52.4023 105.657 52.5618 105.563 52.7354 105.503L74.8929 97.7851C75.5746 97.5466 76.2997 97.4578 77.0189 97.5244L136.076 102.913L136.983 102.995L140.031 103.273C140.26 103.294 140.475 103.389 140.644 103.545C140.812 103.701 140.924 103.909 140.962 104.135C141 104.361 140.962 104.594 140.854 104.796C140.746 104.999 140.573 105.16 140.364 105.254L108.467 119.637C103.345 121.947 97.5493 122.28 92.1963 120.571L83.9218 117.993"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M55.8809 60.0742C57.7722 60.2604 59.6361 60.5172 61.1205 60.7552C61.5535 60.8243 61.9535 60.8915 62.3138 60.9552C62.4292 60.9758 62.5558 60.9984 62.6862 61.0242C63.2189 61.1226 63.8742 61.2535 64.6 61.4036C68.6899 62.2484 75.0624 63.7105 75.0624 63.7105"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M73.8968 118.18C73.8968 118.18 73.7313 118.68 73.4277 119.497C73.0864 120.419 72.5691 121.745 71.9195 123.207C70.3453 126.754 67.9918 131.106 65.4581 132.475C60.9563 134.909 18.967 129.554 15.6808 101.927C14.122 88.8201 18.4791 79.2288 24.8913 72.5212C30.5191 66.6331 37.7298 62.9688 43.9109 61.1037C44.5541 60.9087 45.1868 60.7346 45.8041 60.5779C46.699 60.3544 47.5628 60.1682 48.3886 60.0226C48.43 60.014 48.4713 60.0071 48.511 60.0002"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.8066 77.4626C38.8066 77.4626 23.9579 86.9561 41.2396 104.117"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M147.785 77.6768L147.471 85.6598L147.076 95.7187C146.909 99.9483 143.276 103.331 139.062 102.941H136.986"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M139.641 29.3665L141.597 29.3752C142.627 29.3251 143.656 29.4898 144.618 29.8588C145.581 30.2279 146.456 30.793 147.188 31.5185C147.92 32.2441 148.493 33.1139 148.871 34.0731C149.249 35.032 149.423 36.0591 149.382 37.0891L148.505 60.0657L148.253 66.6727"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M74.5456 118.38L73.8991 118.173L52.7674 111.436C51.5692 111.053 51.0778 106.195 52.2656 105.781"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M140.595 103.306C140.84 103.329 141.011 103.603 141.118 104.018C141.476 105.405 141.111 108.363 140.362 108.701L108.466 123.085C103.344 125.395 97.5474 125.728 92.1945 124.019L92.1307 123.998L80.6113 120.323"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M73.4297 119.497C74.7056 118.218 75.7055 118.285 77.216 117.294C77.7301 116.954 78.272 116.657 78.836 116.409C79.9211 115.91 81.1053 115.663 82.2995 115.688C82.9927 115.716 83.7875 115.935 84.0565 116.573C84.2496 117.035 84.091 117.585 83.7823 117.981C83.4506 118.358 83.0555 118.674 82.6151 118.914C81.9237 119.345 81.2318 119.776 80.5391 120.206C79.8149 120.656 79.0902 121.106 78.3649 121.557"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M72.0117 123.226C75.8772 124.361 82.5395 126.876 87.4965 126.552C91.7536 126.275 91.7121 125.899 92.1294 124.009"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M99.1872 41.9202L97.9355 89.1509"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M88.4707 49.5324C90.0531 49.3047 91.6524 49.217 93.2503 49.2702"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M86.9219 56.3049C89.3495 56.0928 91.7834 55.9945 94.2238 56.0102"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M87.2539 65.7919C89.3549 66.0593 91.4823 66.0403 93.5783 65.7351"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M87.1621 74.0063C89.245 73.9838 91.3278 73.9601 93.4107 73.9355"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M86.7578 79.8368C88.3264 79.9337 89.9006 79.8973 91.4633 79.7283"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M104.932 62.9106C114.006 62.2209 123.094 61.7749 132.196 61.5725"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M104.363 67.8801C111.802 67.7699 119.24 67.6589 126.678 67.5474"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M106.068 56.0413C107.015 55.9918 107.962 55.9428 108.91 55.8948"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M117.51 55.9443L122.943 56.1253"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M130.33 45.9203V41.9202L104.931 43.3858L104.677 46.9686L104.363 51.4171L130.33 49.9688V45.9203Z"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M104.689 46.9691C107.579 46.6898 110.238 45.0655 113.138 44.9811C115.124 44.9225 117.054 45.5932 118.91 46.3019C120.767 47.0106 122.642 47.7759 124.623 47.9219C126.604 48.0679 128.757 47.4821 129.987 45.92"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M146.528 74.1593C143.649 74.1249 140.892 74.0265 137.712 73.8833C137.164 73.8593 136.475 73.6007 136.009 73.8938C135.544 74.1869 135.399 74.892 135.523 75.4266C135.688 76.1342 136.514 76.4335 137.225 76.5956C137.916 76.7524 138.654 76.8904 139.418 77.0094C141.22 77.2826 143.037 77.4518 144.859 77.5162C145.381 77.5349 145.889 77.5438 146.377 77.5438C146.747 77.5438 147.121 77.5369 147.494 77.5146C148.616 77.4474 149.725 77.2405 150.673 76.6629C152.17 75.7504 153.025 74.0196 153.246 72.2816C153.466 70.5435 153.127 68.7833 152.689 67.0864C152.449 66.1639 152.179 65.25 151.88 64.3451C151.609 63.5226 151.313 62.7087 150.992 61.9035C150.782 61.3725 150.549 60.8311 150.137 60.4345C149.725 60.0379 149.087 59.8258 148.57 60.0673"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M146.527 74.1592C146.933 74.173 147.326 74.1778 147.705 74.1713C147.31 74.1694 146.917 74.1644 146.527 74.1592Z"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M141.388 70.4417C139.595 70.4435 137.819 70.4917 136.236 70.6416C135.789 70.6778 135.363 70.8452 135.011 71.1228C134.667 71.4055 134.469 71.9125 134.671 72.3073C134.9 72.7573 135.495 72.821 135.995 72.8884C139.511 73.3694 143.373 74.0522 146.528 74.1591"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M141.389 70.4417C143.744 70.6035 146.105 70.645 148.465 70.5659C146.377 70.5297 143.866 70.44 141.389 70.4417Z"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M148.789 66.7932C148.618 66.8174 148.449 66.8414 148.28 66.864C147.293 66.9984 146.316 67.0984 145.328 67.1742C143.545 67.3105 141.725 67.3708 139.738 67.414C139.488 67.4174 139.236 67.4226 138.978 67.4278C138.549 67.4364 136.909 67.557 136.543 67.7816C136.358 67.8892 136.208 68.0471 136.109 68.2371C136.011 68.427 135.968 68.641 135.987 68.854C136.116 69.7748 138.59 70.2319 141.388 70.4419"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M151.937 64.3262C154.229 65.0896 156.394 66.3521 157.96 68.19C159.526 70.0278 160.444 72.4781 160.17 74.8782C159.966 76.6713 159.122 78.3455 158.029 79.7817C156.776 81.4109 155.208 82.7711 153.419 83.7819C151.63 84.7926 149.655 85.433 147.613 85.6648"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M59.0731 106.098L77.4685 99.4223C77.7076 99.3342 77.9642 99.3048 78.2169 99.3361L130.429 105.571C130.531 105.583 130.626 105.628 130.699 105.7C130.772 105.772 130.82 105.866 130.833 105.968C130.847 106.07 130.827 106.173 130.776 106.262C130.724 106.351 130.645 106.42 130.55 106.459L102.195 118.144C101.445 118.452 100.613 118.502 99.8313 118.285L59.1078 106.979C59.013 106.953 58.929 106.898 58.8679 106.821C58.8069 106.744 58.7719 106.65 58.7679 106.551C58.764 106.453 58.7916 106.356 58.8465 106.275C58.9013 106.193 58.9808 106.132 59.0731 106.098Z"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M55.6894 60.0433C55.3583 57.857 56.3083 55.6587 57.6084 53.869C58.9085 52.0794 60.5585 50.5732 61.9223 48.8308C65.4789 44.2928 66.9052 37.6357 65.7155 31.9941"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M47.4512 36.4409C48.6857 44.2296 49.0418 52.1322 48.5126 60.0004"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M75.2414 50.3672V50.3689C73.2947 55.2276 70.2395 59.726 64.5996 61.4037"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.857 72.4868C17.2687 64.9764 15.9826 55.3725 17.5515 48.5257C19.1205 41.6788 24.7827 35.5657 31.7675 34.8167C33.3503 34.646 35.0297 34.7236 36.4298 33.965C38.4142 32.8873 42.0575 15.7368 57.136 16.2506C71.3176 16.7334 76.4506 26.5147 77.6436 34.7046"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.6816 36.0927C46.2341 36.345 46.8412 36.4547 47.4471 36.4117C48.4748 36.3599 49.53 35.9633 50.4636 35.4927C53.7301 33.8459 56.5031 31.3649 58.5018 28.301C60.4823 30.2067 63.0168 31.4342 65.7401 31.8064C66.4043 31.8984 67.075 31.9365 67.7453 31.9202"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51.6777 37.3182C51.9935 36.8942 52.4633 36.6112 52.9858 36.5304C53.5082 36.4494 54.0414 36.5769 54.4708 36.8855"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M58.8477 36.0375C59.2905 35.5811 59.8776 35.2921 60.5092 35.2191C61.1408 35.1461 61.7782 35.294 62.3133 35.6374"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M57.7383 33.1044C58.5261 32.5582 59.4344 32.2109 60.3856 32.092C61.3367 31.9732 62.3026 32.0862 63.2007 32.4215"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M58.5918 40.7653C58.8458 40.6839 59.1184 40.6805 59.3744 40.7556C59.6305 40.8306 59.858 40.9805 60.028 41.1861C60.1889 41.3989 60.2746 41.6591 60.2718 41.9257C60.269 42.1925 60.1777 42.4506 60.0124 42.6601C59.8361 42.8595 59.5932 42.9882 59.3292 43.0225C59.0652 43.0567 58.7976 42.9942 58.5762 42.8463"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M56.2799 47.5068C54.7763 47.8223 53.1125 47.2551 52.666 45.3308"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.8065 60.585C44.1565 62.6952 44.4755 65.8833 45.9996 68.0852C47.1418 69.7359 48.8337 70.9271 50.773 71.4459C52.7124 71.9648 54.7729 71.7773 56.587 70.9174C58.401 70.0573 59.8503 68.5805 60.6759 66.7507C61.5017 64.921 61.6503 62.8571 61.0952 60.928"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M48.4287 59.9104C48.4149 59.9467 48.4012 59.9849 48.3915 60.0221C48.1226 60.8301 48.1023 61.7 48.3336 62.5195C48.5647 63.3392 49.0365 64.0705 49.688 64.6188C51.0554 65.7275 53.2124 65.8327 54.6412 64.7998C56.0699 63.7669 56.5688 61.6671 55.6808 60.1703"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43.9259 61.0742C43.9208 61.0846 43.9156 61.0928 43.9104 61.1036C43.1134 62.5117 42.6831 64.0977 42.6587 65.7158C42.6394 67.3511 43.1646 68.9463 44.1517 70.2503C45.6069 72.109 47.9587 73.0419 50.2761 73.4816C52.2499 73.8573 54.3142 73.9298 56.2453 73.3781C57.4838 73.0241 58.6375 72.4228 59.6369 71.6102C60.6363 70.7978 61.4606 69.7911 62.0598 68.651C62.6591 67.511 63.0209 66.2612 63.1233 64.9773C63.2257 63.6934 63.0668 62.4021 62.6559 61.1815"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51.7956 66.7001C51.7706 66.8543 51.7835 67.0122 51.833 67.1602C51.8827 67.3083 51.9675 67.4422 52.0801 67.5502C52.2367 67.6732 52.4293 67.742 52.6283 67.7457C52.8276 67.7494 53.0225 67.688 53.1836 67.5708C53.3404 67.4487 53.4662 67.291 53.5506 67.1109C53.6351 66.9308 53.6759 66.7335 53.6698 66.5346C53.656 65.1535 51.9455 65.5674 51.7956 66.7001Z"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.8681 74.1509L34.7871 72.1226"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.4062 73.2429C34.3559 73.1854 35.2929 72.9951 36.1899 72.678"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.3498 97.4808L29.3574 96.1296"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.3867 98.1859L30.7985 95.322"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M55.1602 98.9067L57.1995 96.8892"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M56.6228 98.9725L54.6895 95.8804"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M65.6289 82.5238L67.3364 81.3933"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M66.8228 83.083L65.6152 80.7251"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.0535 85.8466L47.123 83.802"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M48.639 83.6831C48.0973 84.4875 47.5014 85.2543 46.8555 85.9779"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M63.1051 123.123L62.3789 120.383"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M61.1016 122.537L64.2824 121.271"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44.1448 124.566L41.1582 122.661"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.3594 125.283L43.5654 122.007"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.9209 111.703L33.7324 111.633"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.4648 113.443L34.83 110.156"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.2288 105.044L20.2207 103.766"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.7075 103.42L20.2441 105.426"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M69.8242 68.8394L71.8777 66.8784"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M71.6408 68.7752L69.8633 66.9038"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M152.92 80.8842L153.488 79.1804"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M154.061 80.3932L152.357 79.5815"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M71.377 15.321L75.9604 12.4309"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M68.3711 13.718C69.9864 9.43333 71.715 5.19395 73.5566 1"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M65.5379 11.6744L64.9902 6.56226"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M80.873 17.7989L82.701 17.3926"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M81.8692 18.6165L81.252 16.3848"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M85.5441 4.45772L83.2461 4.05029"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M84.1172 5.61231L84.5231 3.17505"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.8087 96.2395C10.6769 108.17 16.866 119.99 26.7465 126.678"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M53.6162 12.9908L52.0586 11.7246"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51.9609 13.3803L53.4134 11.332"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.3876 30.4025L31.3496 28.124"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.7012 31.2937L34.0401 27.2319"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42.2296 13.0796L40.3828 11.9075"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.3047 13.9783L42.1452 10.4692"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.05273 45.8833C2.88178 45.2394 3.89067 44.8696 4.93945 44.8249C5.98822 44.7804 7.02488 45.0632 7.9056 45.6343C8.76228 46.241 9.40887 47.0994 9.75566 48.0902C10.1024 49.081 10.1322 50.1552 9.84085 51.1637C9.4986 52.264 8.63814 53.3031 7.49334 53.4355C6.34854 53.5678 5.13893 52.3804 5.55678 51.3067C5.81635 50.641 6.58949 50.2692 7.30136 50.3318C8.01323 50.3943 8.65229 50.8189 9.13084 51.3496C9.65595 51.9464 10.0157 52.6703 10.1742 53.4494C10.3327 54.2283 10.2846 55.0353 10.0345 55.7899C9.34554 57.7858 7.35536 59.0535 6.40067 60.9368C6.01806 61.6915 5.81382 62.5239 5.80375 63.37C5.79366 64.2159 5.978 65.0531 6.34252 65.8166C6.70706 66.5802 7.24207 67.2498 7.90629 67.7738C8.57051 68.298 9.34621 68.6626 10.1736 68.8394"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M157.02 35.6118C154.771 38.2271 154.547 42.3764 156.5 45.219C157.057 46.0296 157.8 46.7546 158.731 47.0712C159.662 47.3878 160.799 47.2166 161.449 46.4798C162.1 45.743 162.051 44.4291 161.231 43.8854C160.104 43.1374 158.495 44.3643 158.435 45.7158C158.376 47.0673 159.322 48.2523 160.345 49.1374C162.136 50.6875 164.514 52.0629 164.832 54.4104C164.922 55.2098 164.751 56.0168 164.345 56.7108C163.938 57.405 163.318 57.9491 162.577 58.2619"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.44527 58.0137L1 58.2031"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.2385 59.3255L2.21094 56.8916"
        stroke="#171717"
        strokeWidth="0.558622"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.7464 4.74345L50.8563 5.68361C50.8899 5.71191 50.9139 5.7499 50.925 5.79235C50.9364 5.83481 50.9344 5.87969 50.9193 5.92084C50.9044 5.96218 50.877 5.99793 50.8411 6.02307C50.8051 6.04839 50.7625 6.06198 50.7185 6.06217L49.2641 6.06962C49.2216 6.0698 49.1801 6.08265 49.1449 6.10649C49.1097 6.13013 49.0823 6.16384 49.0663 6.20313L48.5153 7.54922C48.4986 7.58981 48.4699 7.62445 48.433 7.6481C48.3962 7.67193 48.353 7.68385 48.309 7.68236C48.2653 7.68087 48.2228 7.66597 48.1878 7.63972C48.1526 7.61327 48.1263 7.57697 48.1126 7.53525L47.6562 6.15415C47.6428 6.11393 47.6178 6.07855 47.5843 6.0523C47.5508 6.02623 47.5104 6.01059 47.4681 6.00742L46.0176 5.89942C45.9738 5.89626 45.9319 5.87968 45.8978 5.85194C45.8637 5.82438 45.839 5.78696 45.8267 5.74469C45.8146 5.70242 45.8157 5.65754 45.8299 5.61602C45.8438 5.57449 45.8705 5.53818 45.9058 5.51211L47.0789 4.65109C47.113 4.62614 47.1389 4.5915 47.1534 4.55165C47.1679 4.51199 47.1704 4.46879 47.1605 4.42764L46.8151 3.0147C46.8047 2.97206 46.8074 2.92718 46.8233 2.88622C46.8391 2.84525 46.867 2.81024 46.9033 2.78567C46.9398 2.76109 46.9829 2.74824 47.0268 2.7488C47.0706 2.74954 47.1132 2.76351 47.1489 2.7892L48.3301 3.63812C48.3645 3.66289 48.4055 3.67685 48.4479 3.67834C48.4904 3.67983 48.5323 3.66885 48.5684 3.6465L49.8056 2.88119C49.8428 2.8581 49.8864 2.84693 49.9301 2.84935C49.9741 2.85177 50.0162 2.8676 50.0508 2.8946C50.0852 2.9216 50.1108 2.95865 50.1238 3.00055C50.1366 3.04245 50.1365 3.08751 50.123 3.12922L49.6797 4.51367C49.6667 4.55426 49.6661 4.59783 49.678 4.6388C49.6899 4.67958 49.7138 4.71607 49.7464 4.74345Z"
        fill="#171717"
      />
      <g opacity="0.1">
        <path
          d="M58.5673 28.5135C57.7431 28.895 57.2389 29.9784 56.668 30.6642C56.0351 31.4485 55.3051 32.1494 54.4955 32.7497C52.869 33.923 50.9751 34.671 48.9858 34.9257C48.3631 35.0114 47.736 35.8454 47.8093 36.4712C48.2345 40.0516 48.4039 43.6575 48.3166 47.262C48.2704 49.0309 48.1175 50.7999 47.9097 52.5549C47.806 53.4316 47.6818 54.3054 47.5373 55.1761C47.3883 56.0802 47.0764 57.0494 47.1813 57.9661C47.3062 59.0573 48.7205 59.6351 49.3608 58.5565C49.8396 57.7505 49.9478 56.7973 50.1192 55.888C50.2943 54.9607 50.4494 54.0297 50.5848 53.0949C50.8479 51.2747 51.0386 49.4493 51.1734 47.6154C51.4551 43.9033 51.4022 40.1734 51.0153 36.4707L49.4128 38.0732C51.2836 37.8566 53.087 37.2457 54.7042 36.2808C56.3214 35.3156 57.7154 34.0187 58.7943 32.4751C59.4313 31.5839 60.2687 30.1914 59.932 29.0675C59.7475 28.452 59.1149 28.2603 58.569 28.5131L58.5673 28.5135Z"
          fill="#171717"
        />
      </g>
      <g opacity="0.1">
        <path
          d="M47.4318 60.3831C43.7334 60.4966 40.0755 62.1805 36.8327 63.8528C33.5089 65.5622 30.4156 67.6872 27.6277 70.1766C22.0214 75.2011 17.7262 81.965 16.1201 89.3629C14.4649 96.9843 15.285 105.175 19.1035 112.045C22.9642 118.99 29.6615 123.991 36.785 127.236C45.7245 131.308 55.5748 132.943 65.3613 132.701C67.6487 132.645 67.658 129.087 65.3613 129.14C57.2055 129.326 49.0339 128.192 41.3877 125.279C34.6787 122.723 28.0432 118.644 23.8408 112.693C19.7486 106.898 18.3205 99.6398 19.2395 92.6544C20.1537 85.7065 23.3483 79.3602 28.1936 74.3317C30.8555 71.5816 33.8916 69.2205 37.2126 67.318C38.8987 66.3385 40.6501 65.4762 42.4547 64.7373C44.3052 63.9877 46.2101 63.3141 47.9744 62.3844C48.9149 61.8887 48.4824 60.3516 47.4326 60.3838L47.4318 60.3831Z"
          fill="#171717"
        />
      </g>
    </svg>
  );
};
