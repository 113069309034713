import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/whatsNew.scss';

const WhatsNew = () => {
  return (
    <div>
      <article className="whats-new_block-contain">
        <h3>Campaign Information</h3>
        <p>
          With this new feature, you can now see the performance of your campaign in an overview. You can access
          analytics data and information about your campaign. You can find the feature in the campaign tab.
        </p>
      </article>
      <article className="whats-new_block-contain">
        <h3>Contact Page</h3>
        <div>
          <p>We simplified the process of adding subscribers to your email campaign</p>
        </div>
        <div>
          <p>With the new Contact flow, you can:</p>
          <ul>
            <li>Import subscribers</li>
            <li>Group subscribers</li>
            <li>Import subscribers to campaign from contact page</li>
            <li>And many more</li>
          </ul>
        </div>
        <img src="/assets/whats_new-contact_page.png" alt="" />
        <Link to="/contacts">Try Contact</Link>
      </article>
      <article className="whats-new_block-contain">
        <h3>Email Analytics</h3>
        <p>
          With email analytics, you can track how your subscribers interact with your emails. The information can help
          to draw valuable insights to optimize and strategize your email campaigns for better performance.
        </p>
        <img src="/assets/whats_new-email_analytics.png" alt="" />
      </article>
      <article className="whats-new_block-contain">
        <h3>Email Filter</h3>
        <p>
          Email filter cards allow you to see between your active, draft and deleted emails. With the tool, you can
          delete and restore emails easily.
        </p>
        <img src="/assets/whats_new-email_filter.png" alt="" />
      </article>
    </div>
  );
};

export default WhatsNew;
