import { Button, Form, Input, Modal } from 'antd';
import { useState } from 'react';
import {
  useCreateContactGroupMutation,
} from 'redux/featureApi/contactApiSlice';
import 'styles/modals.scss';
import { showToastMessage } from 'utils/Toast';
import { MdTypography } from 'components/global';
import styles from 'styles/newContactPage.module.scss';
import { MdClose } from 'pages/ContactPage/Icons/MdClose';
import MdCsvReader from './MdCsvReader';
import MdConfirmCsvUpload from './MdConfirmCsvUpload';
import SuccessIcon from 'asset/Icons/SuccessIcon';

export const CreateNewGroup = ({
  imVisible,
  setImVisible,
  contactGroups,
  currentGroup,
  setCsvResult,
  refetchContactGroupsData,
  refetchSingleInstantEmail,
  campaignId,
  csvResult,
  group,
  setContactGroups,
  setGroup,
  saveContactForm,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [loading, setImLoading] = useState({ id: '' });
  const [uploadStep, setUploadStep] = useState(0);
  const [instantForm] = Form.useForm();

  const [createContactGroup, { isLoading: creatingContactGroup }] = useCreateContactGroupMutation();

  const handleButtonClick = () => {
    setUploadStep(1);
  };

  const onSubmitContactDetail = async ({ title, description = '' }) => {
      const titles = contactGroups?.map((grp) => grp.title?.toLowerCase());
      const exists = contactGroups?.some(item => item.title === title);
      if (titles?.map((grp) => grp?.toLowerCase()).includes(title?.toLowerCase())) {
          showToastMessage({
              type: 'error',
              title: 'Contact Group',
              description: 'Group with this title already exist',
          });
          return;
      } else if (title?.length > 0 && title?.replace(/\s/g, '')?.length === 0) {
          showToastMessage({
              type: 'error',
              title: 'Contact Group',
              description: 'Please enter a valid group name',
          });
          return;
      } else if (title?.length === 0) {
          showToastMessage({
              type: 'error',
              title: 'Contact Group',
              description: 'Group cannot be empty',
          });
          return;
      } else if (!exists) {
          try {
              await createContactGroup({ payload: { title, description } })
                  .unwrap()
                  .then(async (res) => {
                      setGroup(res.data.group?._id);
                      handleButtonClick();
                      await refetchContactGroupsData();
                      await refetchSingleInstantEmail();

                      setImLoading({ id: '' });
          
                  });
          } catch (error) {
              showToastMessage({
                  type: 'error',
                  title: 'Error',
                  description: error?.message,
              });
              setImLoading({ id: '' });
          }
      }
  };
  
  return (
    <Modal
      className={`emmaccen ${styles['contact-modal']}`}
      open={imVisible.id === 'createNewContactGroup'}
      afterClose={() => {
        setUploadStep(0);
        setCsvResult([]);
      }}
      footer={null}
      maskClosable={false}
      onCancel={() => setImVisible({ id: '' })}
      closable={true}
      destroyOnClose={true}
      closeIcon={<MdClose />}
      centered
    >
      {uploadStep === 0 ? (
        <Form
          form={instantForm}
          onFinish={onSubmitContactDetail}
          onFinishFailed={() => null}
          autoComplete="off"
          size="large"
          layout={'vertical'}
          requiredMark={false}
        >
          <div className={styles['addNewContactModal']}>
            <MdTypography variant={'h5Medium'} className="text-center title mb-8">
              Create new group
            </MdTypography>

            <Form.Item
              name="title"
              label="Group name"
              rules={[{ required: true, message: 'Please, provide a title!' }]}
            >
              <Input placeholder="Enter group name" data-test="create-contact-grp-title-csv" />
            </Form.Item>
            <Form.Item name="description" label="Description (optional)">
              <Input placeholder="Enter group description" data-test="create-contact-grp-desc" />
            </Form.Item>
            <p className="instant-email-upload-contact">Or upload contacts to your new group</p>
            <MdCsvReader
              imVisible={imVisible}
              setImVisible={setImVisible}
              setCSVResult={setCsvResult}
              uploadContactsTo="createNewContactGroup"
              data-test="create-contact-grp-csv"
            />

            <div className={styles['info-box']}>
              Contacts in your uploaded csv will be added to the specified group, and will be visible under your saved
              contacts
            </div>

            <div className="emmaccen">
              <div className="flex-space-btw actionBtns">
                <Button
                  size={'large'}
                  type="primary"
                  htmlType="submit"
                  className={`${styles['contact-btn']} w-full  mt-0`}
                  loading={creatingContactGroup}
                  disabled={creatingContactGroup}
                  data-test="csv-contact-done"
                >
                  Proceed
                </Button>
              </div>
            </div>
          </div>
        </Form>
      ) : uploadStep === 1 ? (
        <MdConfirmCsvUpload
          saveContactForm={saveContactForm}
          csvResult={csvResult}
          setCsvResult={setCsvResult}
          uploadContactsTo="createNewContactGroup"
          imVisible={imVisible}
          setImVisible={setImVisible}
          setUploadStep={setUploadStep}
          campaignId={campaignId}
          refetchContactGroupsData={refetchContactGroupsData}
          group={group}
          setGroup={setGroup}
          instantForm={instantForm}
        />
      ) : uploadStep === 2 ? (
        <div
          style={{ height: '298px', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }}
          className="text-center"
        >
          <div className="text-green flex justify-content-center align-items-center">
            <SuccessIcon />
          </div>
          <p style={{ fontFamily: 'Paralucent-Medium' }} className="font18 mt-7 ">
            New contact group created
          </p>
          <p style={{ fontFamily: 'Paralucent-Light' }} className=" font14 mt-4">
            You can now select this group as part of your recipients for your instant email
          </p>
          <Button
            size={'large'}
            type="primary"
            htmlType="submit"
            className={`${styles['contact-btn']} w-full  mt-2`}
            loading={creatingContactGroup}
            data-test="contact-done-csv"
            onClick={() => setImVisible({ id: '' })}
          >
            Back to instant email
          </Button>
        </div>
      ) : (
        ''
      )}
    </Modal>
  );
};
