import React from 'react';

export const LinkedinIcon = () => {
    return(
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z" fill="#0A66C2"/>
<path d="M13.5371 13.5391H12.0527V11.2148C12.0527 10.6602 12.041 9.94727 11.2793 9.94727C10.5059 9.94727 10.3867 10.5508 10.3867 11.1738V13.5391H8.90234V8.75781H10.3281V9.41016H10.3477C10.5469 9.03516 11.0313 8.63867 11.7539 8.63867C13.2578 8.63867 13.5352 9.62891 13.5352 10.916L13.5371 13.5391ZM7.22852 8.10352C6.75 8.10352 6.36719 7.7168 6.36719 7.24219C6.36719 6.76758 6.75195 6.38086 7.22852 6.38086C7.70508 6.38086 8.08984 6.76758 8.08984 7.24219C8.08984 7.7168 7.70313 8.10352 7.22852 8.10352ZM7.97266 13.5391H6.48438V8.75781H7.97266V13.5391ZM14.2793 5H5.74023C5.33203 5 5 5.32227 5 5.72266V14.2969C5 14.6953 5.33008 15.0195 5.74023 15.0195H14.2773C14.6855 15.0195 15.0195 14.6973 15.0195 14.2969V5.72266C15.0195 5.32227 14.6855 5 14.2793 5Z" fill="white"/>
</svg>
    )
}
