import React, {  useContext } from 'react';
import {
  Form,
  Input,
} from 'antd';
import { joinCampaignContext } from '../../../contexts/joincampaign';
import { DeleteOutlined } from '@ant-design/icons';
import { FORM_FIELDS } from '../../../contexts/joincampaign/reducer/types';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';

export const FormFieldItemLP = ({ placeholder, name, deletable }) => {
  const { state, dispatch } = useContext(joinCampaignContext);
  const { formFields } = state;

  function updateFormField(value, name, change) {
    const updatedFormFields = formFields.map((field) => {
      if (field.name === name) {
        return { ...field, [change]: value };
      }
      return field;
    });
    return updatedFormFields;
  }

  function removeFormField(name) {
    return formFields.filter((item) => item.name !== name);
  }

  const handleTextChange = (e, field) => {
    if (e.target.name === name) {
      dispatch({ type: FORM_FIELDS, payload: updateFormField(e.target.value, name, field) });
    }
  };

  const { confirm } = Modal;
  const removeField = (name) => {
    confirm({
      title: 'Do you want to delete this field?',
      icon: <ExclamationCircleOutlined />,
      content: 'Click Ok button to proceed.',

      onOk() {
        dispatch({ type: FORM_FIELDS, payload: removeFormField(name) });
      },

      onCancel() {
        console.log('Cancel');
      },
    });
  };

  return (
    <>
      <Form>
        <div className="flex justify-between items-center mb-3" style={{ whiteSpace: 'nowrap !important' }}>
          <Form.Item className="input-label mb-0" label={name}></Form.Item>
          <div className="flex" style={{ whiteSpace: 'nowrap !important' }}>
            {deletable === true && (
              <div
                className="grow flex pl-1 text-red-500 text-base click items-center"
                onClick={() => removeField(name)}
                data-test="delete-field-input"
              >
                <DeleteOutlined />
              </div>
            )}
          </div>
        </div>
        <Input
          placeholder={placeholder}
          name={name}
          onChange={(e) => handleTextChange(e, 'placeholder')}
          style={{ height: '35px' }}
        />
      </Form>
    </>
  );
};
