export const DeleteWarningIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="77" height="77" viewBox="0 0 77 77" fill="none">
      <circle cx="38.5" cy="38.5" r="38.5" fill="#FEECEB" />
      <path
        d="M39 55.6668C48.1667 55.6668 55.6667 48.1668 55.6667 39.0002C55.6667 29.8335 48.1667 22.3335 39 22.3335C29.8334 22.3335 22.3334 29.8335 22.3334 39.0002C22.3334 48.1668 29.8334 55.6668 39 55.6668Z"
        stroke="#F44336"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M39 32.3335V40.6668"
        stroke="#F44336"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M38.9908 45.6665H39.0058"
        stroke="#F44336"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
