import React from 'react';
import { MdAngleLeft } from './Icons/MdAngleLeft';
import { MdAngleRight } from './Icons/MdAngleRight';

export const MdPagination = (page, type, originalElement) => {
  if (type === 'prev') {
    return (
      <div className="page-next">
        <MdAngleLeft /> <p>Prev</p>
      </div>
    );
  }
  if (type === 'next') {
    return (
      <div className="page-next ml-2">
        <p>Next</p>
        <MdAngleRight />
      </div>
    );
  }
  return originalElement;
};
