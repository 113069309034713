import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { Input, Select, Table, Tabs } from 'antd';
import useDebounce from '../hooks/useDebounce';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  useGetWorkspaceMembersQuery,
  useLeaveWorkspaceMutation,
  useRemoveWsMemberMutation,
  useSwitchWorkspaceMutation,
  useUpdateWsMemberRoleMutation,
} from '../redux/featureApi/teamCollabApiSlice';
import { selectCurrentUser, selectUserAccessLevel, selectWorkpaceList } from '../redux/featureSlices/authSlice';
import { toggleAddNewTeamMember } from '../redux/featureSlices/uiSlice';
import { errorMessage } from '../utils/getErrors';
import searchData from '../utils/searchData';
import { showToastMessage } from '../utils/Toast';
import '../styles/teamCollab.scss';
import { apostropheLastLetter } from '../utils/generics';
import AddTeamMemberModal from './emmaccen/modals/teamCollaboration/AddTeamMemberModal';
import CreateTeamModal from './emmaccen/modals/teamCollaboration/CreateTeamModal';
import LoadingScreen from './LoadingScreen';
import SingleTeamSection from './SingleTeamSection';
const TeamCollaboration = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const membersOfAccount = '/settings/account/teams';
  const teamsOfAccount = '/settings/account/teams?account-teams';

  const defaultToRequestedTab = () => {
    let defaultActiveKey = 1;
    if (location.pathname + location.search === teamsOfAccount) {
      defaultActiveKey = 2;
    }
    return defaultActiveKey.toString();
  };

  const [tabKeys] = useState({
    defaultActiveKey: defaultToRequestedTab(),
    membersOfAccount: '1',
    teamsOfAccount: '2',
  });
  const [currentTabKey] = useState(tabKeys?.defaultActiveKey);

  const [membersSearchData, setMembersSearchData] = useState([]);
  const [membersSearchTerm, setMembersSearchTerm] = useState('');
  const [imLoading, setImLoading] = useState({ id: '' });

  const [isLeavingWorkSpace, setIsLeavingWorkSpace] = useState(false);

  const { Option } = Select;
  const dispatch = useDispatch();

  const accessLevel = useSelector(selectUserAccessLevel);
  const currUserData = useSelector(selectCurrentUser);
  const workspaceList = useSelector(selectWorkpaceList);

  const { data: wsMembers, isLoading: wsMembersLoading } = useGetWorkspaceMembersQuery(
    {},
    {
      skip: accessLevel.role === 2 ? true : false,
    }
  );
  const [removeMember, { isLoading: isRemovingMember }] = useRemoveWsMemberMutation();
  const [updateMemberRole, { isLoading: updatingRole }] = useUpdateWsMemberRoleMutation();
  const [memberIdClicked, setMemberIdClicked] = useState('');

  const [leaveWSapi] = useLeaveWorkspaceMutation();
  const [switchWorkspace] = useSwitchWorkspaceMutation();

  const currentWorkspace = workspaceList.find((space) => space.workspaceName === accessLevel?.workspace);

  const debouncedMemberSearch = useDebounce(membersSearchTerm, 1000);
  const wsMembersData = wsMembers?.members;

  useEffect(
    () => {
      if (debouncedMemberSearch) {
        searchData(debouncedMemberSearch, wsMembersData, setMembersSearchData, ['name', 'email'], setImLoading);
      } else {
        setImLoading({ id: '' });
        searchData('', wsMembersData, setMembersSearchData, ['name', 'email'], setImLoading);
      }
    },
    [debouncedMemberSearch, wsMembersData] // Only call effect if debounced search term changes
  );

  const addMemberModalHandler = () => {
    dispatch(toggleAddNewTeamMember());
  };
  const addTeamHanler = () => {
    // dispatch(toggleCreateNewTeam());
  };

  const selectOptions = [
    {
      label: (
        <>
          <div>Co-Admin</div>
          <p>Can only edit teams, add or remove, modify access level for team members</p>
        </>
      ),
      value: 1,
    },
    {
      label: (
        <>
          <div>Member</div>
          <p>Can only edit, create and share emails & campaigns assigned to</p>
        </>
      ),
      value: 2,
    },
    {
      label: <p className="removeUser_from_team">Remove from Organization</p>,
      value: 'removeUser',
    },
  ];

  const columns = [
    {
      title: 'Img',
      dataIndex: 'picture',
      key: 'img',
      width: 60,
      render: (img) => <img src={img ?? '/assets/avatar.svg'} alt="" />,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text) => <span className="font-medium">{text}</span>,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (text) => <span>{text}</span>,
    },
    {
      title: 'Status',
      dataIndex: 'role',
      key: 'status',
      render: (text, record) =>
        text === 0 ? (
          <div className="admin_team_select_option">
            <Select defaultValue={text} style={{ width: 133 }} bordered={false}>
              <Option value={0}>
                <div>Owner / Admin</div>
                <p>
                  Full access to the account and can edit teams, add or remove, modify access level for team members
                </p>
              </Option>
            </Select>
          </div>
        ) : (
          <Select
            // defaultValue={text}
            value={text}
            style={{ width: 120 }}
            bordered={false}
            loading={
              (updatingRole && memberIdClicked === record?.member) ||
              (isRemovingMember && memberIdClicked === record?.member)
            }
            onChange={(optVal) => memberListHandler(record?.member, optVal)}
            options={selectOptions.map((d) => {
              return {
                label: d.label,
                value: d.value,
                disabled: d.value === text,
              };
            })}
          />
        ),
    },
  ];

  const children = [];
  for (let i = 10; i < 36; i++) {
    children.push(
      <Option key={i.toString(36) + i} value={i.toString(36) + i}>
        {i.toString(36) + i}
      </Option>
    );
  }

  const memberListHandler = (memberId, optVal) => {
    setMemberIdClicked(memberId);
    if (optVal === 'removeUser') {
      removeMember(memberId)
        .unwrap()
        .then((resp) => {
          console.log({ resp });
          showToastMessage({
            type: 'success',
            title: 'Success',
            description: resp.message ?? 'Member removed successfully',
          });
        })
        .catch((err) => {
          const errMsg = errorMessage(err);
          showToastMessage({
            type: 'error',
            title: 'Error',
            description: errMsg,
          });
        })
        .finally(() => setMemberIdClicked(''));
    } else {
      // change member's role
      updateMemberRole({ member: memberId, update: optVal })
        .unwrap()
        .then((resp) => {
          console.log({ resp });
          showToastMessage({
            type: 'success',
            title: 'Success',
            description: resp.message || resp.data.message || "Updated member's role successfully",
          });
        })
        .catch((err) => {
          const errMsg = errorMessage(err);
          showToastMessage({
            type: 'error',
            title: 'Error',
            description: errMsg,
          });
        })
        .finally(() => setMemberIdClicked(''));
    }
  };

  const leaveWorkspaceHandle = () => {
    // console.log('leaving workspace handle');
    const [ownerWorkspace] = workspaceList;
    setIsLeavingWorkSpace(true);
    leaveWSapi()
      .unwrap()
      .then((resp) => {
        showToastMessage({
          type: 'success',
          title: 'Success',
          description: resp.message ?? 'You have left the workspace successfully',
        });
        navigate('/campaigns');
        switchWorkspace(ownerWorkspace.workspaceId)
          .unwrap()
          //.then((resp) => )
          .catch((error) => {
            const errMsg = errorMessage(error);
            showToastMessage({
              type: 'error',
              title: 'Error',
              description: errMsg,
            });
          });
      })
      .catch((err) => {
        // const errMsg = errorMessage(err);
        showToastMessage({
          type: 'error',
          title: 'Error',
          description: err.data.error ?? 'Unable to leave Workspace',
        });
      })
      .finally(() => setIsLeavingWorkSpace(false));
  };

  const teamsAndMemberSearhHandle = (e) => {
    setImLoading({ id: 'searching' });
    if (currentTabKey === '1') {
      setMembersSearchTerm(e.target.value);
    } else {
    }
  };

  if (wsMembersLoading) {
    return <LoadingScreen />;
  }

  const tabItems = [
    {
      label: `Member (${wsMembers?.members.length})`,
      key: tabKeys.membersOfAccount,
      children: (
        <div>
          <Table columns={columns} rowKey={(data) => data?.member} dataSource={membersSearchData} />
        </div>
      ),
    },
    {
      label: 'Teams (0)',
      key: tabKeys.teamsOfAccount,
      children: !process.env.REACT_APP_TEAMCOLLAB_FEATURE ? (
        <LoadingScreen loaderTitle={'Coming soon...'} />
      ) : (
        <SingleTeamSection />
      ),
    },
  ];

  return (
    <div className="boomshakar">
      {isLeavingWorkSpace ? (
        <LoadingScreen />
      ) : accessLevel?.role === 2 ? (
        <div className="company_view-member my-8">
          <span>
            Your email <i>{currUserData?.email}</i>&nbsp;&nbsp;is currently a team member in this workspace. If you need
            admin access in this workspace, contact{' '}
            <a href={`mailto:${currentWorkspace.workspaceEmail}`}>{currentWorkspace.workspaceEmail}</a>
          </span>
          <div>
            <span>
              Clicking this button will remove you from <b>{apostropheLastLetter(currentWorkspace.workspaceName)}</b>{' '}
              workspace
            </span>
            <button onClick={leaveWorkspaceHandle}>Leave workspace</button>
          </div>
        </div>
      ) : (
        <div className="py-8">
          <div className="flex">
            <Input
              placeholder={currentTabKey === '1' ? 'Search members' : 'Search teams'}
              prefix={imLoading.id === 'searching' ? <LoadingOutlined /> : <SearchOutlined />}
              allowClear
              onChange={teamsAndMemberSearhHandle}
            />
            <button
              onClick={currentTabKey === '1' ? addMemberModalHandler : addTeamHanler}
              className="add-member_team-btn"
            >
              {currentTabKey === '1' ? <span>Add member +</span> : <span>Create a team</span>}
            </button>
          </div>
          <Tabs
            onTabClick={(key) => {
              if (key === tabKeys.teamsOfAccount) {
                navigate(teamsOfAccount);
              } else if (key === tabKeys.membersOfAccount) {
                navigate(membersOfAccount);
              }
            }}
            defaultActiveKey={tabKeys?.defaultActiveKey}
            items={tabItems}
          />
          <AddTeamMemberModal workspace={currentWorkspace} />
          <CreateTeamModal workspace={currentWorkspace} />
        </div>
      )}
    </div>
  );
};

export default TeamCollaboration;
