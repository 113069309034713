import { Form, Input } from 'antd';
import { MdGlobalButton, MdTypography } from '../../components/global';
import MdGlobalCTA from '../../components/global/MdGlobalCTA/MdGlobalCTA';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AppConstants } from '../../utils/appConstants';
import { passwordRules } from '../../components/emmaccen/shared-data/passwordRule';
import '../../styles/resetPassword.scss';
import { resetPasswordRequest } from '../../utils/services/authService';
import { showToastMessage } from '../../utils/Toast';

export const MdResetPasswordPage = () => {
  const { hash } = useParams();
  const [imLoading, setImLoading] = useState({ id: '' });
  const navigate = useNavigate();
  const [resetPasswordForm] = Form.useForm();

  const createNewPassword = ({ password }) => {
    setImLoading({ id: 'creatingpassword' });
    resetPasswordRequest(hash, { password })
      .then(() => {
        showToastMessage({
          type: 'success',
          title: 'Password Updated Successfully',
          description: 'You can now login with your new password',
        });
        navigate('/login', { replace: true });
      })
      .catch((error) => {
        showToastMessage({
          type: 'error',
          title: 'Request Failed',
          description: error?.response?.data?.error,
        });
      })
      .finally(() => {
        setImLoading({ id: '' });
      });
  };

  return (
    <div className="emmaccen">
      <div className="loginPageContainer resetPasswordContainer">
        <div className="loginSections flex">
          <MdGlobalCTA />
          <div className="w-50 formArea">
            <div className="formContent">
              <div className="container2 flex flex-col">
                <a
                  href={AppConstants.MAILDRIP_BLOG_URL}
                  target="_blank"
                  className="flex-jst-right pt-2 md-logo"
                  rel="noreferrer"
                >
                  <img src="/assets/smallLogo.svg" alt="company logo" className="mb-5" />
                </a>
                <div>
                  <div className="margin-t-b">
                    <div className="mobile-image">
                      <img src="/assets/loginCamp2.png" className="campagineImg mb-10" alt="campagne"></img>
                    </div>
                  </div>

                  <div className="resetPwdTitle pt-5">
                    <MdTypography variant={'h3Bold'} className="lets-get-your-account-back">
                      Let’s get your account back{' '}
                    </MdTypography>
                    <MdTypography variant={'h1'} className="title mb-2">
                      Set new password
                    </MdTypography>
                  </div>

                  <Form
                    name="basic"
                    form={resetPasswordForm}
                    initialValues={{
                      remember: true,
                    }}
                    onFinish={createNewPassword}
                    // onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    size="large"
                    layout="vertical"
                  >
                    <Form.Item
                      label="New Password"
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: 'Please provide a new password',
                        },
                        ...passwordRules,
                      ]}
                      hasFeedback
                    >
                      <Input.Password className="mb-2" />
                    </Form.Item>
                    <Form.Item
                      label="Retype new password"
                      name="match-pass"
                      rules={[
                        {
                          required: true,
                          message: 'Please confirm your new password',
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(new Error('The two passwords that you entered do not match!'));
                          },
                        }),
                      ]}
                      hasFeedback
                    >
                      <Input.Password className="mb-2" />
                    </Form.Item>
                    <Form.Item>
                      <div class="centered">
                        <MdGlobalButton
                          id={'primary_xl'}
                          style={{ width: '100%' }}
                          htmltype="submit"
                          loading={imLoading.id === 'creatingpassword'}
                        >
                          <MdTypography variant={'h6Medium'} style={{ color: '#ffffff' }}>
                            Reset Password
                          </MdTypography>
                        </MdGlobalButton>
                      </div>
                    </Form.Item>
                  </Form>
                </div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
