import React from 'react';
import { ArrowRightOutlined } from '@ant-design/icons';
import vectorIcon from '../../../asset/Icons/vectorIcon.png';
import personIcon from '../../../asset/Icons/personIcon.png';
import MdPlusIcon from '../../../asset/Icons/MdPlusIcon.png';
import MdGlobalIcon from '../../../asset/Icons/MdGlobalIcon.png';
import MdTypography from '../Typography';

import { AppConstants } from '../../../utils/appConstants';

export const redirectToTelegram = () => {
  window.open('https://t.me/maildripio', '_blank');
};

export const redirectToWhatsNew = () => {
  window.open('https://maildripio.notion.site/What-s-New-e174ff97dcd84397a2a1d3a0e74caac7');
};

export const redirectToHelpCenter = () => {
  window.open('https://maildripio.notion.site/Help-Center', '_blank');
};

export const redirectToBlog = () => {
  window.open(AppConstants.MAILDRIP_BLOG_URL, '_blank');
};

export const MdCTAContent = ({ title, paragraph, ctaSuffix, imageSrc, onClick }) => {
  return (
    <div className="ctaInnerContainer" onClick={onClick}>
      <div className="ctaPrefix">
        <img src={imageSrc} alt="ctaPrefix" className="object-contain" />
      </div>
      <div className="contentSuffix">
        <div className="ctaContent">
          <MdTypography variant={'h6Medium'} className="ctaTitle">
            {title}
          </MdTypography>
          <MdTypography variant={'smallTextRegular'} className="ctaParagraph">
            {paragraph}
          </MdTypography>
        </div>
        <div className="ctaSuffix">{ctaSuffix}</div>
      </div>
    </div>
  );
};

const MdGlobalCTA = () => {
  // const redirectToTelegram = () => {
  //   window.open('https://t.me/maildripio', '_blank');
  // };

  // const redirectToWhatsNew = () => {
  //   window.open('https://maildripio.notion.site/What-s-New-e174ff97dcd84397a2a1d3a0e74caac7');
  // };

  // const redirectToHelpCenter = () => {
  //   window.open('https://maildripio.notion.site/Help-Center', '_blank');
  // };

  // const redirectToBlog = () => {
  //   window.open('https://maildrip.io', '_blank');
  // };
  return (
    <div className="ctaContainer">
      <MdCTAContent
        onClick={redirectToTelegram}
        title="Join our Telegram community"
        paragraph="Rub minds with hundreds of Email marketing professionals"
        ctaSuffix={<ArrowRightOutlined className="ctaSuffix" />}
        imageSrc={vectorIcon}
      />

      <MdCTAContent
        onClick={redirectToWhatsNew}
        title="Check out what’s new on Maildrip"
        paragraph="See the list of new features we added based on your request"
        ctaSuffix={<ArrowRightOutlined className="ctaSuffix" />}
        imageSrc={personIcon}
      />

      <MdCTAContent
        onClick={redirectToHelpCenter}
        title="Visit our Help Center"
        paragraph="Read help articles and connect with the support team"
        ctaSuffix={<ArrowRightOutlined className="ctaSuffix" />}
        imageSrc={MdPlusIcon}
      />

      <MdCTAContent
        onClick={redirectToBlog}
        title="Read our Blog"
        paragraph="maildrip.io"
        ctaSuffix={<ArrowRightOutlined className="ctaSuffix" />}
        imageSrc={MdGlobalIcon}
      />
    </div>
  );
};

export default MdGlobalCTA;
