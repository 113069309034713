class CsvKeyValidator {
  // name;
  // email;
  /**
   * This is a helper class which validates the first row
   * object of an array or and object
   * passed to the constructor.
   * @returns boolean if it passes or fails either of the
   * methods you call;
   *
   * @method validateEmail
   * @method validateName
   */
  constructor(firstrowObj) {
    this.name = firstrowObj.name;
    this.email = firstrowObj.email;
  }
  /* eslint-disable */
  validateEmail() {
    const regexExp = /^([a-zA-Z0-9-_.]+)\@([a-zA-Z-_.]+)\.([a-zA-Z]{1,5})/;
    if (this.email === 'undefined') {
      return false;
    } else if (regexExp.test(String(this.email).trim())) {
      return true;
    }
    return false;
  }
  validateName() {
    const regexExp = /^[A-Za-z-. ]+$/;
    if (this.name === 'undefined') {
      return false;
    } else if (regexExp.test(String(this.name).trim())) {
      return true;
    }
    return false;
  }
}

export default CsvKeyValidator;
