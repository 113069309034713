import React from 'react';

export const Embed = () => {
  return (
    <div>
      <iframe
        title="MailDrip"
        src="https://app.maildrip.io/join/614d7e5d6187f800164c09a5"
        width="100%"
        height="500px"
      ></iframe>
    </div>
  );
};
