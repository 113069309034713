import { Checkbox, Form, Input } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { passwordRules } from '../../components/emmaccen/shared-data/passwordRule';
import '../../styles/loginPage.scss';
import { MdPasswordStrength } from '../../components/global/MdPasswordStrength';
import { maxDynamicChar, minDynamicChar, phoneNumberValidation } from '../../utils/formRules';
import { MdTypography } from '../../components/global';

export const MdSignUpForm2 = ({ passwordStrength, setPage, page }) => {
  return (
    <>
      <Form.Item
        dataTestId="signup-number"
        label="Phone Number"
        name="phone"
        rules={[
          {
            required: true,
            message: 'Please, provide a valid phone number!',
          },
          ...minDynamicChar(7),
          ...maxDynamicChar(15),
          ...phoneNumberValidation,
        ]}
      >
        <Input dataTestId="signup-number-input" placeholder="+234 800 000 0000" />
      </Form.Item>
      <Form.Item
        className="password-field"
        label="Password"
        name="password"
        rules={[
          {
            required: true,
            message: 'Create a password',
          },
          ...passwordRules,
        ]}
      >
        <Input.Password placeholder="At least 8 characters" />
      </Form.Item>
      <MdPasswordStrength passwordStrength={passwordStrength} />
      <Form.Item
        name="remember"
        valuePropName="checked"
        rules={[
          {
            validator: async (_, checked) => {
              if (!checked) {
                return Promise.reject(new Error('Please check the box'));
              }
            },
          },
        ]}
      >
        <Checkbox>
          <div className="flex tc_pp mt-3">
            <div>
              <MdTypography variant={'smallTextMedium'}>
                <span style={{ color: '#858A8F' }}>By creating an account you agree to the </span>
                <Link to="/" className="primaryColorLight ">
                  Terms of use
                </Link>
                <span style={{ color: '#858A8F' }}> and our </span>
                <Link to="/" className="primaryColorLight">
                  Privacy policy
                </Link>
              </MdTypography>
            </div>
          </div>
        </Checkbox>
      </Form.Item>

      <div className="centered pointer go-back-mobile">
        <MdTypography variant={'btnRegular'} style={{ color: '#273DF2' }} onClick={() => setPage(page - 1)}>
          Go Back
        </MdTypography>
      </div>
    </>
  );
};
