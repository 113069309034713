import React, { useState, useEffect } from 'react';
import moment from 'moment';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Space, Table, Divider } from 'antd';
import '../../../styles/dashboard.scss';
import { MdGlobalButton, MdTypography } from '../../../components/global';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';

const MdDashboardEmailCampaigns = () => {
  const [dataSource, setDataSource] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(4);

  const [descend, setDescend] = useState(true);
  const [descendDate, setDescendDate] = useState(true);

  const api__url = process.env.REACT_APP_API_URL;

  const FetchCampaignData = async () => {
    const { data } = await axios.get(`${api__url}/dashboard/campaign-table`);
    setDataSource(data.data.campaignTable);
  };
  /* eslint-disable */
  useEffect(() => {
    FetchCampaignData();
  }, []);

  const onSorterChangeName = () => {
    const sortedDataSourceName = [...dataSource];
    if (descend) {
      sortedDataSourceName.sort((a, b) => (a.name > b.name ? 1 : a.name === b.name ? 0 : -1));
    } else {
      sortedDataSourceName.sort((a, b) => (a.name < b.name ? 1 : a.name === b.name ? 0 : -1));
    }
    setDataSource(sortedDataSourceName);
    setDescend((descend) => !descend);
  };

  const onSorterChangeDate = () => {
    const sortedDataSourceDate = [...dataSource];
    if (descendDate) {
      sortedDataSourceDate.sort((a, b) => (a.launchDate < b.launchDate ? 1 : a.launchDate === b.launchDate ? 0 : -1));
    } else {
      sortedDataSourceDate.sort((a, b) => (a.launchDate > b.launchDate ? 1 : a.launchDate === b.launchDate ? 0 : -1));
    }
    setDataSource(sortedDataSourceDate);
    setDescendDate((descendDate) => !descendDate);
  };

  const columns = [
    {
      // title: 'Name',
      key: 'name',
      title: (
        <div style={{ display: 'flex', alignItems: 'center' }} onClick={onSorterChangeName}>
          Name{' '}
          {descend ? (
            <CaretDownOutlined style={{ marginLeft: '1.5rem' }} />
          ) : (
            <CaretUpOutlined style={{ marginLeft: '1.5rem' }} />
          )}
        </div>
      ),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: (
        <div style={{ display: 'flex', alignItems: 'center' }} onClick={onSorterChangeDate}>
          Launch Date{' '}
          {descendDate ? (
            <CaretDownOutlined style={{ marginLeft: '1.5rem' }} />
          ) : (
            <CaretUpOutlined style={{ marginLeft: '1.5rem' }} />
          )}
        </div>
      ),
      dataIndex: 'launchDate',
      key: 'launchDate',
      render: (launchDate) => <p>{moment(launchDate).format('DD/MM/YYYY')}</p>,
    },
    {
      title: 'Recipients',
      dataIndex: 'totalRecipents',
      key: 'totalRecipents',
    },
    {
      title: 'Opened',
      dataIndex: 'totalOpens',
      key: 'totalOpens',
      onCell: (text, record) => {
        return {
          children: <span>{text}</span>,
          style: { color: '#2eb67d' },
        };
      },
    },
    {
      title: 'Clicked',
      dataIndex: 'totalClicks',
      key: 'totalClicks',
      onCell: (text, record) => {
        return {
          children: <span>{text}</span>,
          style: { color: '#F79E1B' },
        };
      },
    },
    {
      title: ' ',
      key: 'action',
      render: ({ _, campaignId: id }) => (
        <Link to={`/campaign/${id}/campaign-info`}>
          <Space size="middle">
            <MdGlobalButton className="emailCampBtn">Open</MdGlobalButton>
          </Space>
        </Link>
      ),
    },
  ];

  return (
    <>
      <Divider />
      <MdTypography variant={'h6Medium'} className="dashboardEmailCampaignSection">
        Email Campaigns
      </MdTypography>
      <Table
        columns={columns}
        dataSource={dataSource}
        rowKey="name"
        pagination={{
          current: page,
          pageSize: pageSize,
          onChange: (page, pageSize) => {
            setPage(page);
            setPageSize(pageSize);
          },
        }}
      ></Table>
    </>
  );
};

export default MdDashboardEmailCampaigns;
