import { Modal } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';

export const PaymentSuccessPage = () => {
  const [successModal, setSuccessModal] = useState(true);
  const closeSuccessModal = () => setSuccessModal(false);
  const navigate = useNavigate();
  const routeToCampaign = () => {
    closeSuccessModal();
    navigate('/campaigns');
  };
  return (
    <div>
      <Modal
        title="Payment Success"
        open={successModal}
        onOk={routeToCampaign}
        centered
        onCancel={routeToCampaign}
        footer={[
          <button key="submit" className="btn btn-blue" type="primary" onClick={routeToCampaign}>
            Go To Campaigns
          </button>,
        ]}
      >
        <div className="text-center">
          <p className="text-green flex justify-content-center" style={{ fontSize: '86px' }}>
            <span class="iconify" data-icon="bi:patch-check-fill"></span>
          </p>
          <p className="font18">Congratulation!!!</p>
          <p className="mb-3 font16">You have successfully subscribed to a mailing plan!</p>
        </div>
      </Modal>
    </div>
  );
};
