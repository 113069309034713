export const SET_JOIN_CAMPAIGN_FIELDS = 'SET_JOIN_CAMPAIGN_FIELDS';
export const SET_EDITING_MODE = 'SET_EDITING_MODE';
export const SET_PUBLISHING = 'SET_PUBLISHING';
export const SET_TAGLINE = 'SET_TAGLINE';
export const SET_BTN_TEXT = 'SET_BTN_TEXT';
export const SET_CATCHPHRASE = 'SET_CATCHPHRASE';
export const SET_ALT_TEXT = 'SET_ALT_TEXT';
export const SET_SECTION_BACKGROUND = 'SET_SECTION_BACKGROUND';
export const SET_BTN_COLOR = 'SET_BTN_COLOR';
export const SET_LOGO_RESULT = 'SET_LOGO_RESULT';
export const SHOW_PHONE_NUMBER = 'SHOW_PHONE_NUMBER';
export const SHOW_COMPANY_NAME = 'SHOW_COMPANY_NAME';
export const DISPLAY_NAME = 'DISPLAY_NAME';
export const DISPLAY_LAST_NAME = 'DISPLAY_LAST_NAME';
export const DISPLAY_EMAIL = 'DISPLAY_EMAIL';
export const DISPLAY_PHONE_NUMBER = 'DISPLAY_PHONE_NUMBER';
export const DISPLAY_COMPANY_NAME = 'DISPLAY_COMPANY_NAME';
export const SET_LOGO_FILES = 'SET_LOGO_FILES';
export const SET_LINK = 'SET_LINK';
export const FORM_FIELDS = 'FORM_FIELDS';
