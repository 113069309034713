import { notification } from 'antd';
import React from 'react';
import { ReactComponent as ErrorIcon } from '../asset/Icons/errorIconM.svg';
import { ReactComponent as SuccessIcon } from '../asset/Icons/successIconM.svg';
import { ReactComponent as WarningIcon } from '../asset/Icons/warningIconM.svg';

export const showToastMessage = ({
  type = required('type'),
  title = required('title'),
  description = required('description'),
  duration = 5,
  placement = 'top',
  icon,
  key = '',
}) => {
  const iconImage =
    type === 'success' ? (
      <SuccessIcon />
    ) : type === 'warning' ? (
      <WarningIcon />
    ) : type === 'error' ? (
      <ErrorIcon />
    ) : (
      <WarningIcon />
    );
  notification[type]({
    message: title,
    description,
    duration,
    placement,
    icon: icon ?? iconImage,
    maxCount: 3,
    className: 'customNotification',
    key,
  });
};

export function required(varName) {
  throw new Error(`${varName} is required`);
}
