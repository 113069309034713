import React from 'react';

export default function EditLandingPageIcon() {
    return(
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 17.0625H2.25C1.53 17.0625 0.9375 16.47 0.9375 15.75V6C0.9375 2.685 2.685 0.9375 6 0.9375H12C15.315 0.9375 17.0625 2.685 17.0625 6V12C17.0625 15.315 15.315 17.0625 12 17.0625ZM6 2.0625C3.315 2.0625 2.0625 3.315 2.0625 6V15.75C2.0625 15.855 2.145 15.9375 2.25 15.9375H12C14.685 15.9375 15.9375 14.685 15.9375 12V6C15.9375 3.315 14.685 2.0625 12 2.0625H6Z" fill="#1C1C1C"/>
        <path d="M5.96231 13.3124C5.60981 13.3124 5.28731 13.1849 5.04731 12.9524C4.76231 12.6674 4.63479 12.2624 4.70229 11.8274L4.91229 10.3424C4.95729 10.0199 5.1598 9.60736 5.3923 9.37486L9.2848 5.48236C10.6198 4.14736 11.7823 4.74736 12.5173 5.48236C13.0948 6.05986 13.3573 6.66736 13.2973 7.27486C13.2523 7.76986 12.9898 8.23486 12.5173 8.71486L8.6248 12.6074C8.3923 12.8399 7.98729 13.0424 7.65729 13.0949L6.17231 13.3049C6.10481 13.3049 6.02981 13.3124 5.96231 13.3124ZM10.8973 5.81236C10.6198 5.81236 10.3723 5.99236 10.0873 6.26986L6.19481 10.1624C6.13481 10.2224 6.04481 10.4099 6.02981 10.4924L5.81981 11.9774C5.81231 12.0524 5.81979 12.1199 5.84979 12.1499C5.87979 12.1799 5.9473 12.1874 6.0223 12.1799L7.50731 11.9699C7.59731 11.9549 7.77731 11.8649 7.83731 11.8049L11.7298 7.91236C12.0148 7.62736 12.1648 7.37986 12.1873 7.15486C12.2098 6.89986 12.0598 6.59986 11.7298 6.26986C11.3998 5.95486 11.1373 5.81236 10.8973 5.81236Z" fill="#1C1C1C"/>
        <path d="M11.5645 9.4348C11.512 9.4348 11.4595 9.42729 11.4145 9.41229C10.057 9.02979 8.9695 7.94979 8.587 6.58479C8.5045 6.28479 8.67699 5.97729 8.97699 5.88729C9.27699 5.80479 9.5845 5.97729 9.667 6.27729C9.9445 7.25979 10.732 8.04729 11.7145 8.32479C12.0145 8.40729 12.187 8.72229 12.1045 9.02229C12.037 9.26979 11.812 9.4348 11.5645 9.4348Z" fill="#1C1C1C"/>
    </svg>
    )
}
