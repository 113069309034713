import React from 'react';
import styles from '../../../styles/optInPage.module.scss';
import blank from '../../../asset/Icons/deer.svg';
import { Button, Input,  Typography } from 'antd';
import { FacebookIcon } from '../../../asset/Icons/FacebookIcon';
import { InstagramIcon } from '../../../asset/Icons/InstagramIcon';
import { TwitterIcon } from '../../../asset/Icons/TwitterIcon';
import { LinkedinIcon } from '../../../asset/Icons/LinkedinIcon';
import { Youtube } from '../../../asset/Icons/Youtube';
import TiktokLogo from '../../../asset/Icons/TiktokLogo';
import { getContrastColor } from 'utils/miniFunctions/getColors';


const { Paragraph } = Typography;

export const Maildrip001 = ({ props, isPreview }) => {
  const {
    bannerColor,
    backgroundColor,
    file,
    heroFile,
    editableStr,
    editableText,
    footerColor,
    fb,
    insta,
    linked,
    tweet,
    buttonColor,
    buttonTitle,
    setEditableStr,
    setEditableText,
    formFields,
    redirectLink,
    fbLink,
    tweetLink,
    instaLink,
    linkedLink,
    youLink,
    youtub,
    tiktok,
    tikLink,
    businessMail,
    phoneNumber,
    businessMailLink,
    phoneNumberLink,
    defaultFields,
  } = props ?? {};

  return (
    <>
      <div className={`${isPreview ? 'w-100 p-8 bg-gray-200' : styles.second_child}`}>
        <div style={{ background: bannerColor || '#C7EACC' }} className={styles.announcement}>
          <div style={{ overflow: 'hidden', width: '80px', height: '80px' }}>
            <img
              style={{ objectFit: 'cover', width: '100%', height: '100%' }}
              src={file?.src || '/favicon.ico'}
              alt=""
            />
          </div>
        </div>
        <div className={styles.flex_container} style={{ backgroundColor: backgroundColor || '#544565' }}>
          <div
            style={{ overflow: 'hidden', width: '100%', height: '100%', borderRadius: '8px' }}
            // onClick={() => bannerImgRef?.current?.onBatchStart()}
          >
            <img
              style={{ objectFit: 'cover', width: '100%', height: '100%', borderRadius: '8px' }}
              src={heroFile?.src || blank}
              alt=""
            />
          </div>

          <div className={styles.design_content}>
            {isPreview ? (
              <div className={styles.heading}> Nice Heading Goes Here</div>
            ) : (
              <Paragraph
                className={styles.heading}
                editable={{
                  onChange: setEditableStr,
                }}
              >
                {editableStr || 'Nice Heading Goes Here'}
              </Paragraph>
            )}
            <p className={styles.design_body}>
              {isPreview ? (
                <div className={styles.design_body}>
                  Other texts e.g A brief introduction explaining the purpose of your opt-in page and the benefits for
                  the customer.Tip: Use concise bullet points or a short paragraph for easy readability.
                </div>
              ) : (
                <Paragraph
                  className={styles.design_body}
                  editable={{
                    onChange: setEditableText,
                  }}
                >
                  {editableText ||
                    'Other texts e.g A brief introduction explaining the purpose of your opt-in page and the benefits for the customer.Tip: Use concise bullet points or a short paragraph for easy readability.'}
                </Paragraph>
              )}
            </p>
            {(formFields || defaultFields)?.map((field, i) => (
              <Input className={styles.input} disabled placeholder={field?.placeholder} />
            ))}
            <a href={redirectLink} rel="noreferrer" target="_blank">
              <Button
                style={{ background: buttonColor || '#018615' }}
                data-test="cta-button-text"
                className={styles.subscribe_button}
              >
                {buttonTitle || 'Subscribe'}
              </Button>
            </a>
          </div>
        </div>
        <div style={{ background: footerColor || '#C7EACC' }} className={styles.footer}>
          <div className={styles.margin_left}>
            <p data-test="businessmail" style={{ color: getContrastColor(footerColor) }}>
              {businessMail && businessMailLink}
            </p>
            <p data-test="phonenumber" style={{ color: getContrastColor(footerColor) }}>
              {phoneNumber && phoneNumberLink}
            </p>
          </div>
          <div className={styles.social_media_icons}>
            {(isPreview || fb) && (
              <a href={fbLink} data-test="fbsocial" rel="noreferrer" target="_blank">
                <FacebookIcon />
              </a>
            )}
            {(isPreview || insta) && (
              <a href={instaLink} data-test="instasocial" rel="noreferrer" target="_blank">
                <InstagramIcon />
              </a>
            )}
            {(isPreview || tweet) && (
              <a href={tweetLink} rel="noreferrer" target="_blank">
                <TwitterIcon />
              </a>
            )}
            {(isPreview || linked) && (
              <a href={linkedLink} rel="noreferrer" target="_blank">
                <LinkedinIcon />
              </a>
            )}
            {(isPreview || youtub) && (
              <a href={youLink} rel="noreferrer" target="_blank">
                <Youtube />
              </a>
            )}
            {(isPreview || tiktok) && (
              <a href={tikLink} rel="noreferrer" target="_blank">
                <TiktokLogo />
              </a>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
