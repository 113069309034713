import { Modal } from 'antd';
import { Link } from 'react-router-dom';
import { MdTypography } from '../../../components/global';
import '../../../styles/instantemails.scss';
import { instantEmailsActionTypes } from '../../../utils/constants';
import { TextEditorImage } from '../../../asset/Icons/TextEditorImage';
import { StripoIcon } from 'asset/Icons/StripoIcon';
// import { NewsletterImage } from 'asset/Icons/NewsletterImage';

export const MdCreateEmailModal = ({ imVisible, setImVisible, mailId, mailTitle }) => {
  return (
    <div>
      <Modal
        // wrapClassName="createEmailModalWrapper-new"
        open={imVisible?.id === 'createEmailModals'}
        onCancel={() => setImVisible({ id: '' })}
        footer={null}
        centered
      >
        <div className="emmaccen">
          <div className="modal-header">
            <MdTypography variant={''} className="createmodaltitle" dataTestId="modal-title">
              Choose email builder
            </MdTypography>
          </div>
          <div className="createEmailModals-block">
            <Link
              to={{
                pathname: `/instant-emails/${mailId}/mail-subject`,
                search: `?type=speditor&action=${instantEmailsActionTypes.create}`,
              }}
              state={{ mailTitle }}
              data-test="strippo-test"
            >
              <div className="single-option">
                <div className="single-option-icon">
                  <StripoIcon />
                </div>
                <div className="single-option-text">
                  <div className="flex item-center gap-2">
                    <MdTypography variant={'btnRegular'} className="editor-head">
                      HTML EDITOR
                    </MdTypography>
                    <div className="beta-mode">Beta</div>
                  </div>

                  <MdTypography variant={'btnLight'} className="editor-body">
                    Leverage templates, upload your code or use intuitive drag and drop to create your own beautiful and
                    colourful emails.
                  </MdTypography>
                </div>
              </div>
            </Link>
            <Link
              to={`/instant-emails/${mailId}/text-editor?action=${instantEmailsActionTypes.create}`}
              data-test="rich-text-test"
            >
              <div className="single-option">
                <div className="single-option-icon">
                  <TextEditorImage />
                </div>
                <div className="single-option-text">
                  <MdTypography variant={'btnRegular'} className="editor-head">
                    Rich Text EDITOR
                  </MdTypography>

                  <MdTypography variant={'btnLight'} className="editor-body">
                    Create simple text emails. Include tables, images, and carryout various formatting
                  </MdTypography>
                </div>
              </div>
            </Link>
            {/* {showNewsletter && (
              <Link
                to={{
                  pathname: `/instant-emails/${mailId}/mail-subject`,
                  search: `?action=${instantEmailsActionTypes.create}`,
                }}
                state={{ mailTitle }}
              >
                <div className="single-option">
                  <div className="single-option-icon">
                    <NewsletterImage />
                  </div>
                  <div className="single-option-text">
                    <MdTypography variant={'btnRegular'} className="editor-head">
                      Newsletter Builder
                    </MdTypography>

                    <MdTypography variant={'btnLight'} className="editor-body">
                      Easily create responsive emails from a layout or template and add your own content
                    </MdTypography>
                  </div>
                </div>
              </Link>
            )} */}
          </div>
        </div>
      </Modal>
    </div>
  );
};
