import { Spin } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import empty from '../asset/images/empty.svg';

import { importBulkRecipient } from '../utils/service';
import { showToastMessage } from '../utils/Toast';

export const ImportRecIpient = (props) => {
  const navigate = useNavigate();
  const unsavedLists = JSON.parse(localStorage.getItem('unsavedLists'));
  const campaignId = props.match.params.campaignId;
  const [stage, setStage] = useState(1);
  const [lists, setLists] = useState([]);
  const [loading, setLoading] = useState(false);
  const [itemSelected, setItemSelected] = useState([]);
  const [warningModal, setWarningModal] = useState(false);
  const openWarningModal = () => setWarningModal(true);
  const closeWarningModal = () => setWarningModal(false);
  /* eslint-disable */

  useEffect(() => {
    if (JSON.parse(localStorage.getItem('unsavedLists'))) {
      setLists(unsavedLists);
      setStage(2);
    }
  }, []);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: '.xlsx',
  });

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const goback = () => {
    navigate(-1);
  };

  const nextStage = (val) => {
    if (acceptedFiles) {
      if (val === 1) {
        setStage(1);
        localStorage.removeItem('unsavedLists');
      } else {
        readFile();
      }
    }
  };

  const readFile = () => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();

      fileReader.readAsArrayBuffer(acceptedFiles[0]);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;

        const wb = XLSX.read(bufferArray, { type: 'buffer' });

        const wsname = wb.SheetNames[0];

        const ws = wb.Sheets[wsname];

        const data = XLSX.utils.sheet_to_json(ws);
        resolve(data);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
    promise.then((data) => {
      if (data.length > 0) {
        if (data[0].email && data[0].name) {
          let updatedData = data;
          if (!data[0].index) {
            data.map((data, index) => {
              return {
                email: data.email,
                name: data.name,
                index,
              };
            });
          }
          localStorage.setItem('unsavedLists', JSON.stringify(updatedData));
          setLists(data);
          setStage(2);
        } else {
          showToastMessage({
            type: 'warning',
            title: 'Error',
            description: 'make sure you follow the format laid in the sample sheet',
          });
        }
      } else {
        showToastMessage({
          type: 'warning',
          title: 'Error',
          description: 'Make sure Excel sheet is not empty!',
        });
      }
    });
  };

  const saveRecipient = async (force) => {
    try {
      setLoading(true);
      let payload;
      if (itemSelected.length > 0) {
        payload = itemSelected.map((each) => {
          return {
            name: each.name,
            email: each.email,
          };
        });
      } else {
        payload = lists.map((each) => {
          return {
            name: each.name,
            email: each.email,
          };
        });
      }
      const finalPayload = {
        force,
        recipients: payload,
      };
      let res = await importBulkRecipient(finalPayload, campaignId);
      if (res) {
        if (res.data.data?.type === 'will_exceed') {
          return openWarningModal();
        }
        setLoading(false);
        showToastMessage({
          type: 'success',
          title: 'Receipient',
          description: 'Recipients saved',
        });
        if (itemSelected.length === 0) {
          navigate(-1);
        }
        if (itemSelected.length !== lists.length) {
          let remainingList = lists.filter((each) => !itemSelected.includes(each));
          setLists(remainingList);
          setItemSelected([]);
          localStorage.setItem('unsavedLists', JSON.stringify(remainingList));
        } else {
          navigate(-1);
        }
      }
    } catch (error) {
      setLoading(false);
      showToastMessage({
        type: 'error',
        title: 'Error',
        description: 'Sorry, there was an error',
      });
    }
  };

  const toggleAll = () => {
    if (itemSelected.length === lists.length) {
      setItemSelected([]);
    } else {
      setItemSelected(lists);
    }
  };

  const selectItem = (item) => {
    let filteredItem = itemSelected.filter((each) => {
      return each.index === item.index;
    });
    return filteredItem.length === 1;
    // return (
    //   itemSelected.filter((each)=>(each.index === item.index).length === 1)
    // )
  };

  const toggleItem = (item) => {
    itemSelected.length === 0 ? setItemSelected([item]) : checkItemPresence(item);
  };

  const checkItemPresence = (item) => {
    const value = itemSelected.find((el) => {
      return el.index === item.index;
    });
    if (value) {
      let filteredItem = itemSelected.filter((el) => {
        return el.index !== item.index;
      });

      setItemSelected(filteredItem);
    } else {
      setItemSelected([...itemSelected, item]);
    }
  };

  const removeList = () => {
    let remainingList = lists.filter((each) => !itemSelected.includes(each));

    setLists(remainingList);
    setItemSelected([]);
    localStorage.removeItem('unsavedLists');
  };
  const removeItem = (index) => {
    let remainingList = lists.filter((each) => !(each.index === index));

    setLists(remainingList);
    setItemSelected([]);
    localStorage.setItem('unsavedLists', JSON.stringify(remainingList));
  };
  return (
    <div className="import-wrapper">
      {stage === 1 && (
        <div className="import-flex">
          <div className="center mb-5">
            <h2>What would you like to import?</h2>
            <p>Before you upload your files below, make sure your file is ready to be imported</p>

            <div {...getRootProps({ className: 'dropzone' })} className="import-box">
              <div>
                <input {...getInputProps()} />
                <span>
                  Drag and drop or <span className="bold text-black click">choose a file</span> to upload your Contacts.{' '}
                  <br /> All .xlsx file types are supported.
                </span>
                <aside>
                  <h4 className="my-3">Files</h4>
                  <ul className="text-blue">{files}</ul>
                </aside>
              </div>
            </div>
            <h5 className="mt-5 mb-4">Need help getting started?</h5>
            <div className="flex flex-wrap sheet-link">
              <a href="#!" className="mr-4">
                View our import guide
              </a>
              <a href="/assets/maildrip-recipient-list-sample/xlsx" download="maildrip-recipient-list-sample">
                Download sample spreadsheet
              </a>
            </div>
          </div>
          <div className="mt-auto flex justify-content-between">
            <div>
              <button className="btn btn-outline-grey" onClick={goback}>
                Cancel
              </button>
            </div>
            <div>
              {acceptedFiles.length > 0 && (
                <button className="btn btn-blue" onClick={() => nextStage(2)}>
                  Next
                </button>
              )}
            </div>
          </div>
        </div>
      )}
      {stage === 2 && (
        <div className="imported-recipient-table">
          <div onClick={() => nextStage(1)} className="flex align-items-center click w-fit-content">
            <span className="mr-3">
              <span className="iconify" data-icon="cil:arrow-left" data-inline="false"></span>
            </span>
            <span>Import List</span>
          </div>
          <p className="my-3">Your information is ready to be imported, make final changes and save</p>
          <div className="flex justify-end my-3">
            {itemSelected.length > 0 ? (
              <>
                <div className="mr-4">
                  <button className="btn btn-remove" onClick={removeList}>
                    Remove
                  </button>
                </div>
                <div>
                  <button className="btn btn-add" disabled={loading} onClick={() => saveRecipient(false)}>
                    <span className="mr-2">
                      <span className="iconify" data-icon="codicon:add" data-inline="false"></span>
                    </span>
                    Group Selected
                    {loading && (
                      <span className="white-spin ml-2">
                        <Spin />
                      </span>
                    )}
                  </button>
                </div>
              </>
            ) : (
              <div className="mr-4">
                <button className="btn btn-blue" disabled={loading} onClick={() => saveRecipient(false)}>
                  Save All
                  {loading && (
                    <span className="white-spin ml-2">
                      <Spin />
                    </span>
                  )}
                </button>
              </div>
            )}
          </div>
          {lists.length > 0 ? (
            <>
              <div className="list-wrapper">
                <div className="flex align-items-center">
                  <div className="mr-4">
                    <input type="checkbox" checked={itemSelected.length === lists.length} onChange={toggleAll} />
                  </div>
                  <h4 className="mr-5">Name</h4>
                </div>
                <h4>Imported Emails</h4>
                <div></div>
              </div>
              {lists.map((item) => (
                <div className="list-wrapper bg-white" key={item?.index}>
                  <div className="flex align-items-center">
                    <div className="mr-4">
                      <input type="checkbox" checked={selectItem(item)} onChange={() => toggleItem(item)} />
                    </div>
                    <h4 className="mr-5">{item?.name}</h4>
                  </div>
                  <h4 className="mr-4">{item?.email}</h4>
                  <div>
                    <button className="btn btn-remove" onClick={() => removeItem(item.index)}>
                      Remove
                    </button>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <>
              <div className="empty-import">
                <img src={empty} alt="" />
                Sorry you have no data to import !
              </div>
            </>
          )}
        </div>
      )}
      <Modal
        title="Upgrade Plan"
        open={warningModal}
        onOk={() => saveRecipient(true)}
        onCancel={closeWarningModal}
        centered
        footer={[
          <button
            key="back"
            className="btn btn-outline-blue mr-2"
            onClick={() => {
              closeWarningModal();
              navigate('/plans');
            }}
          >
            Upgrade
          </button>,
          <button
            key="submit"
            className="btn btn-blue"
            type="primary"
            onClick={() => {
              closeWarningModal();
              saveRecipient(true);
            }}
          >
            Proceed
          </button>,
        ]}
      >
        <div className="text-center">
          <p className="mb-3">
            You are currently on a free plan with a limit of 100 mails.
            <br /> When you exceed your limit your messages may not be delivered.
          </p>
          <p>Click "proceed" to continue with your free plan and "upgrade" to upgrade your plan.</p>
        </div>
      </Modal>
    </div>
  );
};
