/* eslint-disable */
import { Spin } from 'antd';
import { joinCampaignContext } from '../../contexts/joincampaign';
import { SET_JOIN_CAMPAIGN_FIELDS } from '../../contexts/joincampaign/reducer/types';
import React, { useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useLocation, useParams } from 'react-router-dom';
import EditPage from './EditPage';
import { useGetCampaignLandingPageQuery } from 'redux/featureApi/campaignApiSlice';

export default function JoinACampaign() {
  const query = useLocation().search;
  const campaignId  = new URLSearchParams(query).get('id');

  const { data: landingPageData, isLoading: pageLoading } = useGetCampaignLandingPageQuery(campaignId, {
    refetchOnMountOrArgChange: true,
  });

  const isEditing = new URLSearchParams(query).get('edit');
  const { dispatch, state } = useContext(joinCampaignContext);

  const userName = localStorage.getItem('userName');
  const campaignTitle = localStorage.getItem('campaignTitle');

  useEffect(() => {
    if (landingPageData) {
      getJoinPageValues();
    }
  }, [landingPageData]);

  const getJoinPageValues = () => {
    const payload = {
      tagline: landingPageData.data.tagline || 'Join Campaign',
      btnText: landingPageData.data.button.text || 'Join',
      catchphrase: landingPageData.data.headline || 'Some description or catch phrase goes in here',
      alttext: landingPageData.data.alttext,
      sectionbackground: landingPageData.data.background_color || '#ffffff',
      btnColor: landingPageData.data.button.background_color,
      logo: landingPageData.data.logo_url,
      formFields: landingPageData.data.form_fields || [],
      setLink: landingPageData.data.success_redirect_url,
    };

    dispatch({ type: SET_JOIN_CAMPAIGN_FIELDS, payload });
  };

  if (!pageLoading && isEditing === 'true') {
    return <EditPage campaignId={campaignId} />;
  } else if (!pageLoading) {
    return <Navigate to={`/join/${userName}/${campaignTitle}`} replace />;
  } else {
    return (
      <div className="centerLoadingPage">
        <Spin />
      </div>
    );
  }
}
