import React, { useContext, useEffect, useState } from 'react';
import { Checkbox } from 'antd';
import './style.scss';
import { ReactComponent as AdvancedIcon } from '../../../asset/Icons/advanced.svg';
import { ReactComponent as PremiumIcon } from '../../../asset/Icons/premium.svg';
import { planInverval, trxType } from '../../../utils/constants';
import { paymentContext } from '../../../contexts/payment';
import { SET_PLAN, SET_PRICE, SET_QUANTITY, SET_TRX_TYPE } from '../../../contexts/payment/reducer/types';
import { thousandFormatter, thousandFormatterFloat } from '../../../utils/thousandFormatter';

export default function PlansSection({ setPrompt }) {
  const {
    state: { plan, plans: contextPlans, currency, currencies },
    dispatch,
  } = useContext(paymentContext);

  const isOdd = (num) => {
    return num % 2;
  };

  const filterPlansByInterval = (interval) => {
    // Delete Free plan
    return contextPlans.filter((p) => p.interval === interval && p.priceInNaira > 0).sort((a, b) => a.limit - b.limit);
  };

  const selectPlan = (plan) => {
    dispatch({ type: SET_PLAN, payload: { ...plan, payg: false, topup: false } });
    dispatch({ type: SET_TRX_TYPE, payload: trxType.subscription });
    dispatch({ type: SET_PRICE, payload: currency === 'NGN' ? plan.priceInNaira : plan.priceInDollars });
    dispatch({ type: SET_QUANTITY, payload: 0 });
  };

  const [plans, setPlans] = useState(filterPlansByInterval(planInverval.monthly));
  const [planIntervalTab, setPlanIntervalTab] = useState(0);
  /* eslint-disable */
  useEffect(() => {
    if (planIntervalTab === 1) {
      setPlans(filterPlansByInterval(planInverval.yearly));
    } else {
      setPlans(filterPlansByInterval(planInverval.monthly));
    }
  }, [planIntervalTab]);

  // Select first plan by default
  useEffect(() => {
    if (plans[0]) {
      selectPlan(plans[0]);
    }
  }, [plans]);

  const PlanSelectionCapsule = ({ i, index }) => {
    const isChosenPlan = plan._id === i._id;

    return (
      <div
        className={`plan_box ${isChosenPlan ? 'selected' : ''}`}
        onClick={() => {
          selectPlan(i);
          setPrompt(true);
        }}
      >
        <div className="header flex-space-btw ">
          <div className="flex items-center">
            {!isOdd(index) ? <AdvancedIcon /> : <PremiumIcon />}
            <p className={`palnTitle ${!isOdd(index) ? 'palntitle__even' : 'palntitle__odd'}`}>{i.planType}</p>
          </div>
          <Checkbox checked={isChosenPlan} />
        </div>
        <div className="flex items-center gap-1">
          <h3 className="amount">{thousandFormatter(i.limit)} </h3>
          <p className="planDesc">Credits</p>
        </div>
        <div className="flex price">
          <h4>
            {currencies[currency]}
            {thousandFormatterFloat(i.interval === 'yearly' ? i.displayPrice[currency] / 12 : i.displayPrice[currency])}
          </h4>
          / <span>mo</span>
        </div>
      </div>
    );
  };
  return (
    <div className="paymentDetailsSection">
      <div className="flex items-center">
        <div
          className={`capsule flex gap-4 ${!planIntervalTab ? 'selected' : ''}`}
          onClick={() => {
            setPlanIntervalTab(0);
            setPrompt(true);
          }}
        >
          <p>Pay Monthly</p>
          <Checkbox checked={!planIntervalTab} />
        </div>
        <div
          className={`capsule flex gap-4 ${planIntervalTab ? 'selected' : ''}`}
          onClick={() => {
            setPlanIntervalTab(1);
            setPrompt(true);
          }}
        >
          <p>
            Pay Yearly <span className="capsule_discount">(75%)</span>
          </p>
          <Checkbox checked={planIntervalTab} />
        </div>
      </div>

      <div className={`planSelectionContainer gridItem-2 ${Boolean(plan._id) ? 'selected' : ''}`}>
        {plans.map((p, index) => (
          <PlanSelectionCapsule index={index} i={p} key={p._id} />
        ))}
      </div>
    </div>
  );
}
