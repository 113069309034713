import React from 'react';
import { useDeleteContactFromGroupMutation } from 'redux/featureApi/contactApiSlice';
import { showToastMessage } from 'utils/Toast';
import DeleteModal from 'components/Modal/DeleteModal';

export const MdDeleteContact = ({ imVisible, setImVisible, currentGroup, multiselectionData }) => {
  const [deleteContactFromGroup, { isLoading: deletingContactGroup }] = useDeleteContactFromGroupMutation();

  const onSubmitDeleteContact = async () => {
    try {
      await deleteContactFromGroup({
        contactData: {
          groupId: currentGroup._id === 'all_contacts' ? '' : currentGroup._id,
          contacts: multiselectionData,
        },
      })
        .unwrap()
        .then((data) => {
          showToastMessage({
            type: 'success',
            title: 'Success',
            description: data?.data?.message,
          });
          setImVisible({ id: '' });
        });
    } catch (error) {
      showToastMessage({
        type: 'error',
        title: 'Contact(s)',
        description: error?.data?.error || 'Error deleting contact',
      });
      setImVisible({ id: '' });
    }
  };

  return (
    <DeleteModal
      open={imVisible.id === 'deleteContact'}
      onCancel={() => {
        setImVisible({ id: '' });
        console.log(multiselectionData);
      }}
      deleteFunction={onSubmitDeleteContact}
      maskClosable={false}
      closable={false}
      destroyOnClose={true}
      header={`${multiselectionData?.length > 1 ? 'Contacts' : 'Contact'}`}
      description={`${multiselectionData?.length > 1 ? 'These contacts' : 'This contact'} will be permanetly deleted and you will not be able to retrieve it.`}
      disableDeleteButton={deletingContactGroup}
      multiple={multiselectionData?.length > 1}
      deleteButtonDataTestId={"delete-contact-submit"}
    />
  );
};
