import React from 'react';

const variantsMapping = {
  h1: 'h1',
  h2: 'h2',
  h3Bold: 'h3',
  h3MediumBold: 'h3',
  h4Medium: 'h4',
  h4Light: 'h4',
  h5Medium: 'h5',
  h5Light: 'h5',
  h6Medium: 'h6',
  h6Light: 'h6',
  pMedium: 'p',
  pRegular: 'p',
  pLight: 'p',
  smallTextMedium: 'p',
  smallTextRegular: 'p',
  captionBold: 'p',
  captionMedium: 'p',
  captionRegular: 'p',
  btnRegular: 'p',
  btnLight: 'p',
  tinyText: 'p',
};

const MdTypography = ({ variant, children, className = '', dataTestId = '', ...props }) => {
  const TypographyComponent = variant ? variantsMapping[variant] : 'p';

  return (
    <TypographyComponent className={[`typography--variant-${variant} ${className}`]} {...props} data-test={dataTestId}>
      {children}
    </TypographyComponent>
  );
};

export default MdTypography;
