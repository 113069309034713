import React from 'react';
import { MdGlobalButton, MdTypography } from '../../components/global';
import { MdClose } from './Icons/MdClose';

export const MdSelectionPopUp = ({ setImVisible, data, closeAll, setAddToGroup }) => {
  const closePopUp = () => {
    setImVisible({ id: '' });
    closeAll([]);
  };

  const openAddtoGrouporModal = (val) => {
    setImVisible({ id: 'addToCampaignOrGroup' });
    setAddToGroup(val);
  };
  return (
    <div className="fixed bottom-0 w-full z-10 selection-pop" style={{ left: '9%' }}>
      <div className="bg-white option-pop-up">
        <div className="text-[#555]">
          {data?.length} {data?.length === 1 ? 'contact' : 'contacts'} selected{' '}
        </div>
        <div className="divider" />
        <MdGlobalButton
          // data-test="add-new-contact-test"
          onClick={() => openAddtoGrouporModal(true)}
          // id={'primary_md'}
          className="mr-4 addGrpBtn solid"
        >
          <MdTypography variant={'btnRegular'} style={{ color: '#f7f7f7' }}>
            Add to Group
          </MdTypography>
        </MdGlobalButton>
        <MdGlobalButton className="addGrpBtn mr-4" onClick={() => openAddtoGrouporModal(false)}>
          <MdTypography variant={'btnRegular'} className="addGroupText">
            Add to Campaign
          </MdTypography>
        </MdGlobalButton>
        <MdTypography
          variant={'btnRegular'}
          style={{ color: '#EC615B', cursor: 'pointer' }}
          onClick={() => setImVisible({ id: 'deleteContact' })}
          className="mr-8 p-2"
          dataTestId="multiselection-delete-contact"
        >
          Delete Contact(s)
        </MdTypography>
        <div className="text-lg ml-auto pointer" onClick={closePopUp}>
          <MdClose />
        </div>
      </div>
    </div>
  );
};
