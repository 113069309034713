import { Button, Form, Input } from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
/* eslint-disable */
import {
  useGetCampaignMembersQuery,
  useRemoveCampaignMemberMutation,
  useShareWorkspaceMutation,
} from '../redux/featureApi/teamCollabApiSlice';
import { selectUserAccessLevel } from '../redux/featureSlices/authSlice';
import { errorMessage } from '../utils/getErrors';
import { showToastMessage } from '../utils/Toast';
import '../styles/shareAccessDrop.scss';
import LoadingScreen from './LoadingScreen';

const ShareAccessDrop = ({ campaignId }) => {
  const [shareWorkspace, { isLoading }] = useShareWorkspaceMutation();

  const [removeMember, { isLoading: isRemovingMember }] = useRemoveCampaignMemberMutation();
  const [memberIdClicked, setMemberIdClicked] = useState('');

  const { data: campaignMembers, isLoading: isCampaignMembersLoading } = useGetCampaignMembersQuery(campaignId);
  const [shareAccessForm] = Form.useForm();

  const accessLevel = useSelector(selectUserAccessLevel);

  const onFinish = async (values) => {
    shareWorkspace({ ...values, campaign: [campaignId] })
      .then(({ data }) => {
        showToastMessage({
          type: 'success',
          title: 'Success',
          description: `${data.message}`,
        });
      })
      .catch((err) => {
        const errMsg = errorMessage(err);
        showToastMessage({
          type: 'error',
          title: 'Error',
          description: errMsg,
        });
      })
      .finally(() => {
        shareAccessForm.resetFields();
      });
  };

  const onFinishFailed = (errorInfo) => {
    // setFailedPass(false);
    errorInfo.errorFields.map((val) => {
      // console.log({ val });
      if (val.name[0] === 'email') {
        // setFailedPass(true);
        return true;
      }
    });
  };

  const removeCampaignMember = (member) => {
    setMemberIdClicked(member);
    removeMember({ member: member, campaign: campaignId })
      .unwrap()
      .then(({ data }) => {
        showToastMessage({
          type: 'success',
          title: 'Success',
          description: `${data.message}`,
        });
      })
      .catch((err) => {
        console.log({ err });
        // const errMsg = errorMessage(err);
        showToastMessage({
          type: 'error',
          title: 'Error',
          description: err?.error || 'Sorry, an error occured',
        });
      })
      .finally(() => setMemberIdClicked(''));
  };
  return (
    <div className="share-access-dropdown">
      <div className="drop_title">
        <span>Share access</span>
        <p>Give your teammates access to this page and start collaborating in real time.</p>
      </div>

      <div className="text-base font-medium my-2">Email/Team name</div>
      <Form
        form={shareAccessForm}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        // size="large"
        layout="inline"
        initialValues={{
          note: 'A kind invite to collaborate on our campaigns',
        }}
      >
        <Form.Item name="email" rules={[{ required: true, type: 'email', message: 'Please provide an email!' }]}>
          <Input
            style={{
              // width: 'calc(100% - 100px)',
              // width: '100%',
              marginRight: '10px',
              outline: 'none',
              background: ' #F7F7F7',
              color: '#AAAAAA',
              borderRadius: '4px',
            }}
            placeholder="example@abc.com"
          />
        </Form.Item>
        <Form.Item name="note">
          <Input type="hidden" />
        </Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          loading={isLoading}
          style={{
            background: '#273df2',
            color: '#fff',
            borderRadius: '4px',
            border: '1px solid #273df2',
            width: '73px',
            // padding: '8px 26px',
          }}
        >
          Invite
        </Button>
      </Form>

      <div className="shared__members">
        <span>Members</span>

        {isCampaignMembersLoading ? (
          <LoadingScreen />
        ) : (
          campaignMembers?.members?.map((mkShared) => (
            <div key={mkShared?.member}>
              <div className="shared__members-data">
                <div className="shared__members-data_details">
                  <img src={mkShared?.picture ?? '/assets/avatar.svg'} alt="" />
                  <span>{mkShared.name}</span>
                </div>
                <div className="shared__members-data_remove">
                  <Button
                    type="text"
                    loading={isRemovingMember && memberIdClicked === mkShared.member}
                    danger
                    disabled={accessLevel?.memberEmail === mkShared.email}
                    onClick={() => removeCampaignMember(mkShared.member)}
                  >
                    Remove
                  </Button>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
      <div className="shared__teams">
        <span>
          Team(s) <small>coming soon...</small>
        </span>
      </div>
    </div>
  );
};

export default ShareAccessDrop;
