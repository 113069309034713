import React from 'react';
import { Input } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { MdSearchIcon } from 'pages/ContactPage/Icons/MdSearchIcon';
import { MdGlobalButton, MdTypography } from 'components/global';
import MdCreateNewLandingPage from './MdCreateNewLandingPage';

export const MdLandingPageHeader = ({
  title,
  description,
  descriptionLink,
  headerImage,
  searchPage,
  imvisible,
  setImvisible,
  landingPagesTitle,
}) => {
  const redirectToHelpCenter = () => {
    window.open('https://maildripio.notion.site/Help-Center', '_blank');
  };

  return (
    <>
      <MdCreateNewLandingPage imvisible={imvisible} setImvisible={setImvisible} landingPagesTitle={landingPagesTitle} />
      <div className="contactHeaderCont">
        <div className="contactTextPart">
          <div className="flex item-center gap-2">
            <MdTypography variant={'h2'} className="contHTitle">
              {title}
            </MdTypography>
            <div className="beta-mode">Beta</div>
          </div>
          <div className="flex">
            <MdTypography variant={'btnLight'} className="contHDesc">
              {description}
            </MdTypography>
            <MdTypography onClick={redirectToHelpCenter} variant={'btnRegular'} className="contHDescLink ml-1 pointer">
              {descriptionLink}
            </MdTypography>
          </div>

          <div className="flex">
            <Input
              placeholder="Search Landing Page"
              allowClear
              className="contactSearchInput"
              data-test="landing-page-search"
              prefix={
                imvisible.id === 'searchingLandingPage' ? (
                  <LoadingOutlined />
                ) : (
                  <MdSearchIcon color="rgba(142, 142, 142, 1)" />
                )
              }
              size="large"
              onChange={(e) => searchPage(e)}
            />
            <MdGlobalButton
              id={'primary_lg'}
              type="primary"
              htmlType="submit"
              dataTestId="create-landing-page"
              onClick={() => setImvisible({ id: 'createNewLandingPage' })}
              style={{ width: '185px', marginLeft: '10px' }}
            >
              <MdTypography variant={'h6Medium'} style={{ color: '#ffffff', fontSize: '14px' }}>
                Create New
              </MdTypography>
            </MdGlobalButton>
          </div>
        </div>
        <div className="contactImgPart">
          <img src={headerImage} alt="OptIn page header" className="object-contain contHImg" />
        </div>
      </div>
    </>
  );
};
