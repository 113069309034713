import React, { useContext, useState } from 'react';
import { Form, Input , Checkbox} from 'antd';
import { joinCampaignContext } from '../../../contexts/joincampaign';
import { DeleteOutlined } from '@ant-design/icons';
import { FORM_FIELDS } from '../../../contexts/joincampaign/reducer/types';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';

export const FormFieldItem = ({ placeholder, name, required, deletable }) => {
   const [isActive, setIsActive] = useState(required);
  const { state, dispatch } = useContext(joinCampaignContext);
  const { formFields } = state;

    function updateFormField(value, name, change) {
    const updatedFormFields = formFields.map((field) => {
      if (field.name === name) {
        return { ...field, [change]: value };
      }
      return field;
    });
    return updatedFormFields;
  }

  function removeFormField(name) {
    return formFields.filter((item) => item.name !== name);
  }

  const handleTextChange = (e, field) => {
    if (e.target.name === name) {
      dispatch({ type: FORM_FIELDS, payload: updateFormField(e.target.value, name, field) });
    }
  };
  const handleCheckboxChange = (e, field) => {
    setIsActive((prev) => !prev);
    if (e.target.name === name) {
      dispatch({ type: FORM_FIELDS, payload: updateFormField(e.target.checked, name, field) });
    }
  };

  const { confirm } = Modal;
  const removeField = (name) => {
    confirm({
      title: 'Do you want to delete this field?',
      icon: <ExclamationCircleOutlined />,
      content: 'Click Ok button to proceed.',

      onOk() {
        dispatch({ type: FORM_FIELDS, payload: removeFormField(name) });
      },

      onCancel() {
        console.log('Cancel');
      },
    });
  };

  return (
    <>
      <Form>
        <div className="flex justify-between items-center mb-3">
          <Form.Item className="input-label mb-0" label={name}></Form.Item>
          {deletable && (
            <div className="grow flex  text-red-500 text-base click items-center" onClick={() => removeField(name)}>
              <DeleteOutlined />
              <span className="pl-2 leading-none">Remove</span>
            </div>
          )}
        </div>
        <Input
          placeholder={placeholder}
          name={name}
          onChange={(e) => handleTextChange(e, 'placeholder')}
          style={{ height: '35px' }}
        />

        {deletable && (
          <Checkbox
            checked={isActive}
            className="pl-2 mt-5 mb-5"
            name={name}
            onChange={(e) => handleCheckboxChange(e, 'required')}
            data-test="set-as-required"
          >
            Set as required
          </Checkbox>
        )}
      </Form>
    </>
  );
};
