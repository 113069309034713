import React from 'react';
import '../../../styles/dashboard.scss';
import DashboardModal from './MdDashboardModal';
import TotalAnalytics from './MdTotalAnalytics';

const MdDashboardSidebar = ({analytics}) => {
  return (
    <>
      <div className="topComponents">
        <div className="webinarSection padLR padT">
          <DashboardModal />
        </div>
        {analytics && (

        <>
          <TotalAnalytics />
        </>
        )}
      </div>
      {/* <div className="advertSection">
        <DashboardAdvert />
      </div> */}
    </>
  );
};

export default MdDashboardSidebar;
