export const BatteryIcon = (props) => {
  return (
    <svg width="23" height="12" viewBox="0 0 23 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g id="Battery">
        <rect
          id="Border"
          opacity="0.35"
          x="1.03601"
          y="0.833313"
          width="19.0053"
          height="10.3333"
          rx="2.16667"
          stroke="#1C2127"
        />
        <path
          id="Cap"
          opacity="0.4"
          d="M21.4507 4V8C22.1824 7.66122 22.6583 6.87313 22.6583 6C22.6583 5.12687 22.1824 4.33878 21.4507 4Z"
          fill="#1C2127"
        />
        <rect id="Capacity" x="2.35468" y="2.33331" width="16.368" height="7.33333" rx="1.33333" fill="#1C2127" />
      </g>
    </svg>
  );
};
