import Command from '@ckeditor/ckeditor5-core/src/command';
export default class TagCommand extends Command {
  /**
   * @param {module:core/editor/editor~Editor} editor
   */
  constructor(editor) {
    super(editor);
    this.attributeKey = 'placeholder';
  }

  refresh() {
    const model = this.editor.model;
    const doc = model.document;

    this.value = this._getValueFromFirstAllowedNode();
    this.isEnabled = model.schema.checkAttributeInSelection(doc.selection, this.attributeKey);
  }

  execute(options = {}) {
    const model = this.editor.model;
    const doc = model.document;
    const selection = doc.selection;

    const value = 'placeholder-class';
    const { content } = options;
    // is selection a tag?
    let selectionIsTag = false;

    model.change((writer) => {
      selectionIsTag = Boolean(selection.getAttribute('placeholder'));

      if (selection.isCollapsed) {
        console.log('Collapsed');
        if (content) {
          model.insertContent(writer.createText(`{{${formatStr(content)}}}`, { placeholder: value }));
        }
      } else {
        console.log('Not Collapsed');
        const ranges = model.schema.getValidRanges(selection.getRanges(), this.attributeKey);

        for (const range of ranges) {
          if (!content) {
            // Modify Element
            if (selectionIsTag) {
              writer.removeAttribute(this.attributeKey, range);
            } else {
              let selectedText = '';

              // if command is called by Editor action
              for (const item of range.getItems()) {
                selectedText = item.data;
              }

              //Reformat variable and replace all spaces with underscores
              model.insertContent(writer.createText(`{{${formatStr(selectedText)}}}`, { placeholder: value }), range);
            }
          } else {
            // Modify Element
            //Reformat variable and replace all spaces with underscores
            model.insertContent(writer.createText(`{{${formatStr(content)}}}`, { placeholder: value }), range);
          }
        }
      }
    });
  }

  /**
   * Checks the attribute value of the first node in the selection that allows the attribute.
   * For the collapsed selection returns the selection attribute.
   *
   * @private
   * @returns {Boolean} The attribute value.
   */
  _getValueFromFirstAllowedNode() {
    const model = this.editor.model;
    const schema = model.schema;
    const selection = model.document.selection;

    if (selection.isCollapsed) {
      return selection.hasAttribute(this.attributeKey);
    }

    for (const range of selection.getRanges()) {
      for (const item of range.getItems()) {
        if (schema.checkAttribute(item, this.attributeKey)) {
          return item.hasAttribute(this.attributeKey);
        }
      }
    }

    return false;
  }
}

const formatStr = (text) => {
  return String(text).replace(RegExp(/\x20+/g), '_').toUpperCase();
};
