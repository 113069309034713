import { SET_JOBS, SET_NEW_JOB } from './types';

export const reducer = (state, { type, payload }) => {
  switch (type) {
    case SET_NEW_JOB:
      const job = payload;
      const jobs = [...state.jobs, job];
      // Update LS
      localStorage.setItem('jobs', JSON.stringify(jobs));
      state = {
        ...state,
        jobs: jobs,
      };
      return state;

    case SET_JOBS:
      // Update LS
      localStorage.setItem('jobs', JSON.stringify(payload));
      state = {
        ...state,
        jobs: payload,
      };
      return state;
    default:
      return state;
  }
};
