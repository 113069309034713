import React, { useState } from 'react';
// import { ZoomInOutlined } from '@ant-design/icons';
import PreviewTemplate from './PreviewTemplate';
import { Spin } from 'antd';
import { MdTypography } from 'components/global';
import { MdEmptyBox } from 'pages/ContactPage/Icons/MdEmptyBox';

const TemplateCard = ({
  id,
  icon,
  title,
  fetchedTemplate,
  onClick,
  tabKey,
  currentCard,
  submissionIsLoading,
  selectedPlateObj,
  submitTemplateSelected,
  isGrid,
  temp,
}) => {
  const [modalvisible, setModalVisible] = useState(false);

  const isCurrentCard = id === currentCard;

  return (
    <div className={`template-card ${isGrid ? 'w-100' : 't-card'} flex flex-wrap ${isCurrentCard && 'active'}`}>
      {fetchedTemplate || true ? (
        <div className="temp_card_contain text-center w-full h-full flex justify-center items-center" onClick={onClick}>
          {submissionIsLoading && selectedPlateObj?._id === id ? (
            <Spin tip="Preparing Template" />
          ) : (
            <>
              <img src={icon} alt="new-template" className="temp_img" />

              <MdEmptyBox />
              <div className="temp_overlay">
                <div className="temp_inner">
                  <button
                    className="preview-templates-btn fill"
                    data-test="select-template"
                    onClick={() => {
                      submitTemplateSelected(temp);
                    }}
                  >
                    Select
                  </button>
                  <button
                    className="preview-templates-btn outline"
                    onClick={() => {
                      setModalVisible(true);
                    }}
                  >
                    Preview
                  </button>
                </div>
              </div>

              {modalvisible && (
                <PreviewTemplate
                  title={title}
                  icon={icon}
                  visible={modalvisible}
                  setVisible={() => setModalVisible(false)}
                  templateId={temp?.id}
                />
              )}
            </>
          )}
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center w-full h-full" onClick={onClick}>
          {submissionIsLoading && selectedPlateObj?._id === id ? (
            <Spin tip="Preparing Template" />
          ) : (
            <>
              <img src={icon} alt="new-template" className="mb-4" />
              <MdTypography variant={'h6Light'}>{title}</MdTypography>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default TemplateCard;
