import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Radio } from 'antd';
import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import '../../../styles/dashboard.scss';
import { MdTypography } from '../../../components/global';
Chart.register(...registerables);

const MdOpenDeliveryPerformance = () => {
  const [dataSetDataWeekly, setDataSetDataWeekly] = useState([]);
  const [dataSetDataMonthly, setDataSetDataMonthly] = useState([]);
  const [dataSetPercentage, setDataSetPercentage] = useState({});

  const api__url = process.env.REACT_APP_API_URL;

  const [toggleSwitch, setToggleSwitch] = useState('Week');

  const toggleSwitchBtn = ({ target: { value } }) => setToggleSwitch(value);

  const FetchOpenDeliveryStatWeekly = async () => {
    const { data } = await axios.get(`${api__url}/dashboard/performance-chart/opens-delivers-stats/weekly`);
    setDataSetDataWeekly(data.data);
  };

  const FetchOpenDeliveryStatMonthly = async () => {
    const { data } = await axios.get(`${api__url}/dashboard/performance-chart/opens-delivers-stats/monthly`);
    setDataSetDataMonthly(data.data);
  };
  /* eslint-disable */
  useEffect(() => {
    FetchOpenDeliveryStatWeekly();
    FetchOpenDeliveryStatMonthly();
  }, []);

  useEffect(() => {
    (async () => {
      const { data } = await axios.get(
        `${api__url}/dashboard/performance-chart/stats/percentage?duration=${toggleSwitch.toLowerCase()}&openrate=true&deliveryrate=true`
      );
      setDataSetPercentage(data.data);
    })();
  }, [toggleSwitch]);

  const switchOptions = [
    {
      label: <span className="switchText">Week</span>,
      value: 'Week',
    },
    {
      label: <span className="switchText">Month</span>,
      value: 'Month',
    },
  ];

  const options = {
    indexAxis: 'x',
    barPercentage: 1,
    elements: {
      bar: {
        borderWidth: 0,
      },
    },
    responsive: true,
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label + ':' || '';
            if (context.parsed.y !== null) {
              label += ' ' + context.parsed.y + '%';
            }
            return label;
          },
        },
      },
      legend: {
        labels: {
          boxWidth: 10,
          boxHeight: 8,
          font: {
            size: 12,
            family: 'Inter',
            weight: 500,
          },
        },
        position: 'bottom',
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
      },
    },
  };

  const options2 = {
    indexAxis: 'x',
    barPercentage: 1,
    elements: {
      bar: {
        borderWidth: 0,
      },
    },
    responsive: true,
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label + ':' || '';
            if (context.parsed.y !== null) {
              label += ' ' + context.parsed.y + '%';
            }
            return label;
          },
        },
      },
      legend: {
        labels: {
          boxWidth: 10,
          boxHeight: 8,
          font: {
            fontSize: 12,
            family: 'Inter',
            weight: '500',
            color: '#000000',
          },
        },
        position: 'bottom',
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
      },
    },
  };

  const labelsWeek = ['Mon', 'Tue', 'Wed', 'Thurs', 'Fri', 'Sat', 'Sun'];
  const dataWeekly = {
    labels: labelsWeek,
    datasets: [
      {
        label: 'Open Rate',
        data: dataSetDataWeekly?.openRateDaily?.map((x) => x.total),
        backgroundColor: '#2EB67D',
      },
      {
        label: 'Delivery Rate',
        data: dataSetDataWeekly?.deliveryRateDaily?.map((item) => item.total),
        backgroundColor: '#1976D2',
      },
    ],
  };

  const labelsMonth = ['Week 1', 'Week 2', 'Week 3', 'Week 4'];
  const dataMonthly = {
    labels: labelsMonth,
    datasets: [
      {
        label: 'Open Rate',
        data: dataSetDataMonthly?.openRate?.map((x) => x.openRate),
        backgroundColor: '#2EB67D',
      },
      {
        label: 'Delivery Rate',
        data: dataSetDataMonthly?.deliveryRate?.map((x) => x.deliveryRate),
        backgroundColor: '#1976D2',
      },
    ],
  };
  return (
    <>
      {/* Week view */}
      <div className={`openDeliverySection ${toggleSwitch === 'Week' ? 'block' : 'hidden'}`}>
        <div className="performanceRatings">
          <div className="flex performance-rates">
            <div className="mr-4">
              <MdTypography variant={'captionMedium'} style={{ color: '#aaaaaa' }}>
                Open Rate
              </MdTypography>
              <MdTypography variant={'pMedium'}>{dataSetPercentage.openRate}%</MdTypography>
            </div>
            <div>
              <MdTypography variant={'captionMedium'} style={{ color: '#aaaaaa' }}>
                Delivery Rate
              </MdTypography>
              <MdTypography variant={'pMedium'}>{dataSetPercentage.deliveryRate}%</MdTypography>
            </div>
          </div>
          <div className="performanceSwitch">
            <Radio.Group options={switchOptions} onChange={toggleSwitchBtn} value={toggleSwitch} optionType="button" />
          </div>
        </div>
        <Bar data={dataWeekly} options={options} />
      </div>

      {/* Month view */}
      <div className={`openDeliverySection ${toggleSwitch === 'Week' ? 'hidden' : 'block'}`}>
        <div className="performanceRatings">
          <div className="flex performance-rates">
            <div className="mr-4">
              <MdTypography variant={'captionMedium'} style={{ color: '#aaaaaa' }}>
                Open Rate
              </MdTypography>
              <MdTypography variant={'pMedium'}>{dataSetPercentage.openRate}%</MdTypography>
            </div>
            <div>
              <MdTypography variant={'captionMedium'} style={{ color: '#aaaaaa' }}>
                Delivery Rate
              </MdTypography>
              <MdTypography variant={'pMedium'}>{dataSetPercentage.deliveryRate}%</MdTypography>
            </div>
          </div>
          <div className="performanceSwitch">
            <Radio.Group options={switchOptions} onChange={toggleSwitchBtn} value={toggleSwitch} optionType="button" />
          </div>
        </div>
        <Bar data={dataMonthly} options={options2} />
      </div>
    </>
  );
};

export default MdOpenDeliveryPerformance;
