import React, { useContext, useState } from 'react';
import { Modal } from 'antd';
import { modalsContext } from '../../../contexts/modal';
import { modalId } from '../../../utils/constants';
import { CLOSE_MODAL } from '../../../contexts/modal/reducer/types';
import { showToastMessage } from '../../../utils/Toast';
import { setCampaignStatus } from '../../../utils/service';

export default function TurnOnCampaign() {
  const { state, dispatch } = useContext(modalsContext);
  const [modal] = useState(() => {
    return state.find((m) => m.id === modalId.turnOnCampaign);
  });
  const [loading, setLoading] = useState(false);

  const closeModal = () => {
    dispatch({
      type: CLOSE_MODAL,
      payload: modal.id,
    });
  };
  const CampaignModal = {
    title: 'Campaign Inactive',
    content: `Recipients added to campaign successfully. This campaign is currently inactive and mails won't be delivered to recipients. 
      Kindly turn on campaign if you want them to start receiving mails.
      `,
  };

  return (
    <Modal
      title={CampaignModal.title}
      open={modal.open}
      okText={'Turn On'}
      data-test="campaign-status-modal"
      confirmLoading={loading}
      keyboard={false}
      closable={false}
      afterClose={() => {
        setLoading(false);
      }}
      centered
      onOk={async () => {
        try {
          setLoading(true);
          await setCampaignStatus(modal.props.campaignId);
          closeModal();
          showToastMessage({
            type: 'success',
            title: 'Activated',
            description: `Your campaign has been successfully activated`,
          });
        } catch (error) {
          setLoading(false);
          closeModal();
          showToastMessage({
            type: 'error',
            title: 'Error',
            description: 'Unable to process request',
          });
        }
      }}
      onCancel={() =>
        dispatch({
          type: CLOSE_MODAL,
          payload: modal.id,
        })
      }
    >
      <p>{CampaignModal.content}</p>
    </Modal>
  );
}
