import { message } from 'antd';
import React, { useState } from 'react';
import { cleanString } from 'utils/generics';

const withPopconfirm = (Component, idType) => {
  const PopConfirm = (props) => {
    const [inputUrlModal, setInputUrlModal] = useState(false);

    const popConfirm = (e) => {
      setInputUrlModal(true);
    };

    const popCancel = (e) => {
      const { data: campaignData } = props;
      const campaignTitle = cleanString(campaignData?.name,'-');
      const campaignId = campaignData?._id;
      const { user } = JSON.parse(localStorage.getItem('userDetails'));
      const userName = user.username;
      const JOIN_CAMPAIGN_REDIRECT = `${window.location.origin}/${userName}/join/${campaignTitle}?id=${campaignId}`;
      navigator.clipboard.writeText(JOIN_CAMPAIGN_REDIRECT);
      message.success('Link copied successfully');
    };
    return (
      <Component
        {...props}
        popConfirm={popConfirm}
        popCancel={popCancel}
        inputUrlModal={inputUrlModal}
        setInputUrlModal={setInputUrlModal}
      />
    );
  };
  return PopConfirm;
};

export default withPopconfirm;
