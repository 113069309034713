export const CellularIcon = (props) => {
  return (
    <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        id="Cellular Connection"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5902 0.666687H13.6809C13.1787 0.666687 12.7715 1.1144 12.7715 1.66669V10.3334C12.7715 10.8856 13.1787 11.3334 13.6809 11.3334H14.5902C15.0924 11.3334 15.4995 10.8856 15.4995 10.3334V1.66669C15.4995 1.1144 15.0924 0.666687 14.5902 0.666687ZM9.43732 3.00002H10.3466C10.8489 3.00002 11.256 3.44774 11.256 4.00002V10.3334C11.256 10.8856 10.8489 11.3334 10.3466 11.3334H9.43732C8.93511 11.3334 8.52798 10.8856 8.52798 10.3334V4.00002C8.52798 3.44774 8.93511 3.00002 9.43732 3.00002ZM6.10312 5.33335H5.19379C4.69158 5.33335 4.28445 5.78107 4.28445 6.33335V10.3334C4.28445 10.8856 4.69158 11.3334 5.19379 11.3334H6.10312C6.60533 11.3334 7.01245 10.8856 7.01245 10.3334V6.33335C7.01245 5.78107 6.60533 5.33335 6.10312 5.33335ZM1.85956 7.33335H0.950226C0.448015 7.33335 0.0408936 7.78107 0.0408936 8.33335V10.3334C0.0408936 10.8856 0.448015 11.3334 0.950226 11.3334H1.85956C2.36177 11.3334 2.76889 10.8856 2.76889 10.3334V8.33335C2.76889 7.78107 2.36177 7.33335 1.85956 7.33335Z"
        fill="#1C2127"
      />
    </svg>
  );
};
