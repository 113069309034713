import React from 'react';

import TimeAgo from 'javascript-time-ago';

// Load locale-specific relative date/time formatting rules.
import en from 'javascript-time-ago/locale/en';

// Add locale-specific relative date/time formatting rules.
TimeAgo.addLocale(en);

// Create relative date/time formatter.
const timeAgo = new TimeAgo('en-US');

function DateFromNow({ date }) {
  return <React.Fragment>{timeAgo.format(date)}</React.Fragment>;
}

export default DateFromNow;
