import { CKEditor } from '@ckeditor/ckeditor5-react';
// import { Input } from 'antd';
import DecoupledDocumentEditor from 'custom-ckeditor5/dist/bundle';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import isValidForm from '../../components/emmaccen/shared-functions/inputValidator';
import { InstantEmailEditorActionsTab } from '../../components/emmaccen/sharedComponents/InstantEmailEditorActionsTab';
import { deleteAttachment } from '../../utils/service';
import {
  useEditInstantEmailMutation,
  useGetSingleInstantEmailQuery,
} from '../../redux/featureApi/instantEmailApiSlice';
import '../../styles/editorStyles.scss';
import { showToastMessage } from '../../utils/Toast';
import { instantEmailsActionTypes, mailTypes } from '../../utils/constants';
import { uploadFile, uploadMarkdownImage } from '../../utils/service';
import { AttachmentBox } from './AttachmentBox';

export const InstantEmailDocumentEditor = () => {
  const { mailId } = useParams();
  const navigate = useNavigate();
  /* eslint-disable */
  const [files, setFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [imLoading, setImLoading] = useState({ id: '' });
  const action = new URLSearchParams(useLocation().search).get('action');

  const [variables, setVariables] = useState([
    // Init with custom variables
    'FIRST_NAME',
    'LAST_NAME',
    'FULL_NAME',
    'EMAIL_ADDRESS',
  ]);
  const [editor, setEditor] = useState({});
  const [mail, setMail] = useState({
    subject: 'Message to {{FIRST_NAME}}',
    body: 'Hello {{FIRST_NAME}} {{LAST_NAME}}',
    from: '',
    to: '',
    status: 1,
    duration: '',
    typeOfMail: 'text-editor',
    emailInterval: '',
    attachments: [],
    imageURL: '',
  });

  const [
    InstantEmailTextEditor,
    {
      isLoading: isEditingEmail,
      isSuccess: isTextEditorSuccess,
      isError: isTextEditorError,
      data,
      error: editingInstantEmailErrorMessage,
    },
  ] = useEditInstantEmailMutation();

  const {
    data: emailRes,
    isLoading: loadingEmailFetchReq,
    isSuccess: emailFetched,
    isError: emailFetchReqFailed,
    error: emailFetchReqErr,
  } = useGetSingleInstantEmailQuery({ mailId });

  useEffect(() => {
    if (emailFetched) {
      const { email } = emailRes?.data;
      setMail((prev) => ({
        ...prev,
        ...email,
        subject: email?.subject == '' ? 'Message to {{FIRST_NAME}}' : email?.subject,
        body: email?.body == '' ? 'Hello {{FIRST_NAME}} {{LAST_NAME}}' : email?.body,
      }));
      setFiles(email?.attachments);
    } else if (emailFetchReqFailed) {
      console.log(emailFetchReqErr);
    }
  }, [loadingEmailFetchReq]);

  useEffect(() => {
    if (isTextEditorSuccess) {
      const email = data.email;
      showToastMessage({
        type: 'success',
        title: 'Email',
        description: 'Your mail has been updated successfully',
      });

      if (action === instantEmailsActionTypes.edit) {
        navigate(`/instant-emails/drafts/${mailId}`);
      } else if (action === instantEmailsActionTypes.create) {
        navigate(`/instant-emails/drafts/${mailId}`, { state: { email } });
      }
    } else if (isTextEditorError) {
      const spamDetected = editingInstantEmailErrorMessage?.data?.error?.includes('spam');
      showToastMessage({
        type: 'error',
        title: spamDetected ? 'Spam Detected' : 'Oops',
        description: spamDetected
          ? editingInstantEmailErrorMessage?.data?.error
          : 'An error occurred, please try again',
      });
      setImLoading({ id: '' });
    }
  }, [isTextEditorSuccess, isTextEditorError]);

  useEffect(() => {
    isUploading &&
      files.forEach((file) => {
        file.status !== 'UPLOADED' &&
          uploadFile(file.file, file.id, mailId)
            .then(
              ({
                data: {
                  data: { id, location, filename, status, createdAt },
                },
              }) => {
                // console.log(id, location, filename, status);
                updateFileState(id, status, location, filename, createdAt, file.id);
              }
            )
            .catch((err) => {
              updateFileState(file.id, 'ERROR', file.src, file.filename, createdAt);
            })
            .finally(() => {
              setIsUploading(false);
            });
      });
  }, [isUploading]);

  const updateFileState = (id, status, location, filename, createdAt, oldId) => {
    setFiles((previousFiles) =>
      previousFiles.map((file) =>
        file.id === oldId ? { ...file, id, status, src: location, filename, createdAt } : { ...file, status }
      )
    );
  };

  const handleEmailCreation = (interval) => {
    if (isValidForm([mail.body, mail.subject])) {
      setImLoading({ id: 'emailAction' });
      const attachments = files.filter((file) => file.status !== 'ERROR').map((file) => file);
      setMail({ ...mail, attachments, emailInterval: interval });

      InstantEmailTextEditor({
        mailId: mailId,
        body: {
          subject: mail.subject,
          body: mail.body,
          attachments,
          typeOfMail: mailTypes.textEditor,
        },
      })
        .unwrap()
        .then((data) => {
          console.log({ data });
        })
        .catch((err) => console.log({ err }));
    } else {
      setImLoading({ id: '' });
      showToastMessage({
        type: 'warning',
        title: 'Required Fields',
        description: 'Please provide both email-body and subject',
      });
    }
  };

  class MyUploadAdapter {
    constructor(loader) {
      // The file loader instance to use during the upload.
      this.loader = loader;
    }
    // Starts the upload process.
    upload() {
      const file = this.loader.file;
      return file.then((blob) => {
        return uploadMarkdownImage(blob).then(({ data }) => {
          return { default: data?.data?.resource };
        });
      });
    }
    abort() {
      console.log('upload aborted');
    }
  }

  const deleteFile = ({ src, id }) => {
    setImLoading({ id });
    deleteAttachment(id, mailId)
      .then(() => {
        showToastMessage({
          type: 'success',
          title: 'File',
          description: 'File Deleted',
        });
        setFiles(files.filter((file) => file.src !== src));
      })
      .catch((e) => {
        showToastMessage({
          type: 'error',
          title: 'Error',
          description: 'An error occurred',
        });
      })
      .finally(() => {
        setImLoading({ id: '' });
      });
  };

  return (
    <div className="emmaccen">
      <div className="editorContainer">
        <div className="flex justify-end mb-5 editorHeaderTab">
          <InstantEmailEditorActionsTab
            imLoading={imLoading}
            setImLoading={setImLoading}
            setMail={setMail}
            mail={mail}
            handleEmailCreation={handleEmailCreation}
            filesProps={[files, setFiles, setIsUploading]}
            deleteFunction={deleteFile}
          />
        </div>
        <div className="editorInputArea">
          {/* <Input
            onChange={(subject) => setMail({ ...mail, subject: subject.target.value })}
            placeholder="Subject"
            size="large"
            value={mail?.subject}
            className="borderShade1"
          /> */}

          <div className="ckeditorStyles">
            <CKEditor
              onReady={(editor) => {
                console.log('Editor is ready to use!', editor);

                setEditor(editor);
                // Insert the toolbar before the editable area.
                editor.ui
                  .getEditableElement()
                  .parentElement.insertBefore(editor.ui.view.toolbar.element, editor.ui.getEditableElement());
                editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
                  return new MyUploadAdapter(loader);
                };
                // This is not optimal but it's the way to go
                removeUnwantedCKEBgColor();
              }}
              onError={(error, { willEditorRestart }) => {
                // If the editor is restarted, the toolbar element will be created once again.
                // The `onReady` callback will be called again and the new toolbar will be added.
                // This is why you need to remove the older toolbar.
                if (willEditorRestart) {
                  this.editor.ui.view.toolbar.element.remove();
                }
                console.log({ error });
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                setMail((prev) => ({ ...prev, body: data }));
              }}
              editor={DecoupledDocumentEditor}
              data={mail?.body || ''}
              config={{
                placeholderConfig: { variables },
              }}
            />
          </div>
          {files?.length > 0 && <AttachmentBox files={files} deleteFunction={deleteFile} imLoading={imLoading} />}
        </div>
      </div>
    </div>
  );
};

function removeUnwantedCKEBgColor() {
  const editable = document.querySelector("div[contenteditable='true']");
  if (editable) {
    editable.addEventListener('mouseleave', () => {
      editable.style.background = 'white';
    });
  }
}
