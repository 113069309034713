import { useState } from "react";
import { HourGlassIcon } from "asset/Icons/HourGlassIcon";
import { MdGlobalButton } from "components/global";
import { CompleteProfileModal } from "./CompleteProfileModal";

export const CompleteProfileCTA = ({setIsPhoneNumberNull}) => {
  const [imVisible, setImVisible] = useState({ id: '' });

  return (
    <>
    <CompleteProfileModal imVisible={imVisible} setImVisible={setImVisible} setIsPhoneNumberNull={setIsPhoneNumberNull}/>
    <div className="completeProfileCTA">
      <div className="complete-profile-left">
        <HourGlassIcon className="hour-glass" />
      </div>
      <div className="complete-profile-right">
        <div className="complete-profile info">
          <h1 className="complete-profile-header">Complete your profile in 1 step</h1>
          <p className="complete-profile-description">
            You're almost there. Add your profile details for a better experience on Maildrip. Click the complete
            profile button.
          </p>
        </div>

        <div className="complete-profile-button-container">
          <MdGlobalButton
            id={'primary_lg'}
            className="complete-profile-button"
            onClick={() => {
              setImVisible({ id: 'completeProfileModal' });
            }}
          >
            Complete profile
          </MdGlobalButton>
        </div>
      </div>
    </div>
    </>
  );
};