import React from 'react';

export const MdClose = ({ onClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      onClick={onClick}
      data-test="delete-btn"
    >
      <path d="M13 1L1 13" stroke="#1C2127" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M1 1L13 13" stroke="#1C2127" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
