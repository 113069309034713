import { apiAuthSlice } from './apiAuthSlice';

export const accountSettingsApiSlice = apiAuthSlice
  .enhanceEndpoints({ addTagTypes: ['AccountDetails', 'UserAccountDetails'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      updateAccountDetails: builder.mutation({
        query: (payload) => ({
          url: `/users/account-settings`,
          method: 'PATCH',
          body: payload,
        }),
        invalidatesTags: ['AccountDetails'],
      }),
      updatePassword: builder.mutation({
        query: (payload) => ({
          url: `/users/change-password`,
          method: 'PATCH',
          body: payload,
        }),
      }),
    }),
    overrideExisting: true,
  });

export const { useUpdateAccountDetailsMutation, useUpdatePasswordMutation } = accountSettingsApiSlice;
