import { MdAttach } from 'asset/Icons/MdIcons/MdAttach';
import { MdClose } from 'asset/Icons/MdIcons/MdClose';
import CircularSpinner from 'components/loaders/CircularSpinner';

export const AttachmentBox = ({ files, deleteFunction, imLoading }) => {
  const extractTimeStamp = (inputDate) => {
    const date = new Date(inputDate);

    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const month = monthNames[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12' in AM/PM format

    const formattedDate = `${month} ${day}, ${year} ${hours}:${minutes}${ampm}`;
    return formattedDate;
  };

  return (
    <div className="attach-box" data-test="attachment-box">
      <p className="attach-title">Attachments ({files?.length})</p>
      <div className="attachments-box">
        {files?.map((fileData, index) => (
          <div className="attachment-single" key={index}>
            <div className="attach-inner">
              <div className="attach-icon">
                <MdAttach />
              </div>
              <div className="attach-info">
                <p className="attach-info-text">{fileData?.filename}</p>
                <span className="attach-info-date">Uploaded; {extractTimeStamp(fileData?.createdAt)}</span>
              </div>
            </div>
            <div className="attach-remove">
              {imLoading?.id === fileData?.id ? (
                <CircularSpinner />
              ) : (
                <MdClose onClick={() => deleteFunction(fileData)} />
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
