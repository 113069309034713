import React from 'react';

function Info(props) {
  return (
    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0)">
        <path
          d="M24 12.267c0-.987-.082-1.707-.259-2.453H12.245v4.453h6.748c-.136 1.107-.87 2.773-2.503 3.893l-.023.15 3.635 2.76.252.024C22.666 19 24 15.92 24 12.267z"
          fill="#4285F4"
        />
        <path
          d="M12.245 24c3.306 0 6.082-1.067 8.109-2.907L16.49 18.16c-1.034.707-2.422 1.2-4.245 1.2a7.358 7.358 0 01-6.966-4.986l-.144.012-3.78 2.867-.05.135C3.32 21.306 7.456 24 12.246 24z"
          fill="#34A853"
        />
        <path
          d="M5.279 14.374A7.255 7.255 0 014.87 12c0-.827.15-1.627.395-2.374l-.007-.158-3.827-2.913-.125.058A11.817 11.817 0 000 12c0 1.933.476 3.76 1.306 5.387l3.973-3.013z"
          fill="#FBBC05"
        />
        <path
          d="M12.245 4.64c2.3 0 3.85.974 4.734 1.787l3.456-3.307C18.313 1.187 15.551 0 12.245 0 7.455 0 3.32 2.693 1.305 6.613l3.96 3.013c.993-2.893 3.741-4.985 6.979-4.985"
          fill="#EB4335"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Info;
