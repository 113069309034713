import { LoadingOutlined } from '@ant-design/icons';
import React from 'react';

const buttonsMapping = {
  primary_xl: 'button',
  primary_lg: 'button',
  primary_md: 'button',
  primary_sm: 'button',
  primary_default: 'button',
  primary_xs: 'button',
  light_default: 'button',
  light_xs: 'button',
  grey_default: 'button',
  grey_default2: 'button',
  error_default: 'button',
  outline_sm: 'button',
  outline_default: 'button',
  outline_xs: 'button',
  inactive_xl: 'button',
};

const MdGlobalButton = ({
  title,
  children,
  id,
  dataTestId = '',
  loading,
  disabled,
  suffix,
  prefix,
  className = '',
  ...props
}) => {
  const BtnComponent = id ? buttonsMapping[id] : 'button';

  return (
    <BtnComponent
      id={`btn--type-${id}`}
      data-test={dataTestId}
      className={`maildrip_btn${loading ? ' btn_active' : ''} ${className} ${disabled ? 'button-opacity' : ''}`}
      disabled={loading || disabled}
      {...props}
    >
      {loading && prefix ? (
        <div className="maildrip_btn__prefix">
          <LoadingOutlined />
        </div>
      ) : loading && !suffix ? (
        <div className="maildrip_btn__prefix">
          <LoadingOutlined />
        </div>
      ) : prefix ? (
        <div className="maildrip_btn__prefix">{prefix}</div>
      ) : (
        <></>
      )}
      {title || children}
      {loading && suffix ? (
        <div className="maildrip_btn__suffix">
          <LoadingOutlined />
        </div>
      ) : suffix ? (
        <div className="maildrip_btn__suffix">{suffix}</div>
      ) : (
        <></>
      )}
    </BtnComponent>
  );
};

export default MdGlobalButton;
