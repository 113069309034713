import { LeftArrowIcon } from '../../../asset/Icons/LeftArrow';
import { CellularIcon } from '../../../asset/Icons/CellularIcon';
import { WifiIcon } from '../../../asset/Icons/WifiIcon';
import { BatteryIcon } from '../../../asset/Icons/BatteryIcon';

const EmailDisplayPrototype = ({ name, subject }) => {
  return (
    <div className="prototype">
      <div className="header">
        <div className="time">
          <p>{new Date().toLocaleTimeString('en-US', { hour12: false, hour: 'numeric', minute: 'numeric' })}</p>
        </div>
        <div className="cellular-wifi-battery">
          <CellularIcon />
          <WifiIcon />
          <BatteryIcon />
        </div>
      </div>

      <div className="content">
        <div className="content-header">
          <LeftArrowIcon className="back-arrow" alt="left-arrow-icon" />
          <p className="inbox">Inbox</p>
        </div>

        <div className="content-body">
          <div className="info">
            <div className="grey-circle"></div>
            <div className="email-info">
              <div className="left">
                <p className="email-sender-name">{name}</p>
                <p className="email-subject">{subject}</p>
              </div>
              <div className="right">
                <p className="emai-time">
                  {new Date().toLocaleTimeString('en-US', { hour12: true, hour: 'numeric', minute: 'numeric' })}
                </p>
              </div>
            </div>
          </div>

          <div className="info dummy">
            <div className="grey-circle"></div>
            <div className="email-info">
              <div className="left">
                <div className="top">
                  <div className="one"></div>
                  <div className="two"></div>
                </div>
                <div className="bottom"></div>
              </div>
              <div className="right">
                <div className="dummy-time"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailDisplayPrototype;
