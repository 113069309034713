import grapesjs from 'grapesjs';
import gjsBasicBlock from 'grapesjs-blocks-basic';
import gjsPresetNewsletter from 'grapesjs-preset-newsletter';
import gjsInsertCustomCode from '../plugin/insertCustomCode';
import gjsSocialBlock from '../plugin/socialIcons';
import gjsVariableInsertion from '../plugin/variableInsertion';

export const API_HOST = process.env.REACT_APP_API_URL;

export const grapesInit = (assets, mailId, token, isStripoEnabled) => {
  return grapesjs.init({
    container: '#gjs',
    plugins: [
      gjsPresetNewsletter,
      gjsBasicBlock,
      gjsSocialBlock,
      gjsInsertCustomCode,
      gjsVariableInsertion,
      // gjsCountDownBlock
    ],
    fromElement: true,
    pluginsOpts: {
      gjsPresetNewsletter: {},
      gjsBasicBlock: {
        blocks: ['video', 'map'],
        category: 'New Basic',
      },
      gjsInsertCustomCode: {},
      gjsVariableInsertion: {},
      // gjsCountDownBlock: {},
      // gjsSocialBlock: {},
    },
    // parser: parserCss,
    // canvas: {
    //   styles: [
    //     'https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap',
    //   ],
    // },
    assetManager: {
      assets: assets,
      upload: `${API_HOST}${isStripoEnabled ? '/wb' : '/wb'}/${mailId}/assets`,
      // upload: `${API_HOST}/wb/${campaignId}/${campaignMailId}/assets`,
      // upload: `${API_HOST}assets/${campaignMailId}/content`,
      // upload: 0,
      uploadName: 'file',
      headers: {
        'x-access-token': token,
      },
      credentials: 'omit',
      // stylePrefix: 'am-',
      // // multiUpload: true,
      autoAdd: true,
      // custom: true,
      showUrlInput: true,
    },
    storageManager: {
      id: 'gjs-',
      type: 'remote',
      stepsBeforeSave: 1,
      autosave: true,
      autoload: true,
      // urlStore: `${API_HOST}pages/${campaignMailId}/content`,
      // urlLoad: `${API_HOST}pages/${campaignMailId}/content`,
      urlStore: `${API_HOST}${isStripoEnabled ? '/wb' : '/wb'}/${mailId}/edit`,
      urlLoad: `${API_HOST}${isStripoEnabled ? '/wb' : '/wb'}/${mailId}/components`,

      // urlStore: `${API_HOST}/wb/${campaignId}/${campaignMailId}/edit`,
      // urlLoad: `${API_HOST}/wb/${campaignId}/${campaignMailId}/components`,

      headers: {
        'Content-Type': 'application/json',
        // credentials: 'omit',
        'x-access-token': token,
      },
      credentials: 'omit',
      contentTypeJson: true,
      storeComponents: true,
      storeStyles: true,
      storeHtml: true,
      storeCss: true,
    },
  });
};
