import React from 'react';

export const MdEmptyBox = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="167" height="167" viewBox="0 0 167 167" fill="none">
      <g opacity="0.1">
        <path d="M13.9167 24.3542H153.083V142.646H13.9167V24.3542Z" fill="#BBDEFB" />
        <path d="M31.3125 41.75H135.688V59.1458H31.3125V41.75Z" fill="#3F51B5" />
        <path d="M31.3125 73.0625H76.5417V128.729H31.3125V73.0625Z" fill="#2196F3" />
        <path d="M90.4583 73.0625H135.688V128.729H90.4583V73.0625Z" fill="#2196F3" />
      </g>
    </svg>
  );
};
