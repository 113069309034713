import React from 'react';
import { Input } from 'antd';

const MdSearchInput = ({ placeholder, allowClear, className, prefix, size, onChange }) => {
  return (
    <Input
      placeholder={placeholder}
      allowClear={allowClear}
      prefix={prefix}
      className={className}
      size={size}
      onChange={(e) => onChange(e)}
    />
  );
};

export default MdSearchInput;
