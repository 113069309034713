import React, { createContext, useReducer } from 'react';
import { reducer } from './reducer';

export const joinCampaignContext = createContext();

export default function JoinCampaignContext({ children }) {
  const initialState = {
    editMode: undefined,
    btnText: 'Join',
    alttext: '',
    sectionbackground: 'white',
    tagline: 'Join Campaign',
    catchphrase: 'Some description or catch phrase goes in here',
    btnColor: 'black',
    logo: undefined,
    publishing: false,
    showPhoneNumber: 0,
    showCompanyName: 0,
    displayName: '',
    displayLastName: '',
    displayEmail: '',
    displayPhoneNumber: '',
    displayCompanyName: '',
    setLogoFiles: '',
    setLink: '',
    formFields: [],
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  return <joinCampaignContext.Provider value={{ state, dispatch }}>{children}</joinCampaignContext.Provider>;
}
