import { Tabs } from 'antd';
import PayComponent from '../../components/Payment/Pay';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { selectUserAccessLevel } from '../../redux/featureSlices/authSlice';
import '../../styles/accountSettings.scss';
import Billings from '../Payment/Billings';
import TeamCollaboration from '../TeamCollaboration';
import AccountDetails from './AccountDetails';
import AccountSecurityTab from './AccountSecurity';
import Apikeys from './Apikeys';
import EmailConfig from './EmailConfig';

// TODO: Convert All Components' files into folders with their stylesheets with them in the same folder
function AccountSettings() {
  const location = useLocation();

  const navigate = useNavigate();

  const plansAndBillingsPathname = '/settings/account/plans';
  const paymentPagePathname = '/payment/pay';
  const ApisPathname = '/settings/account/apikeys';
  const teamCollabPathname = '/settings/account/teams';
  const accountDetailsPathname = '/settings/account/details';
  const emailConfigPathname = '/settings/account/config';
  const accountSecurityPathname = '/settings/account/security';

  const accessLevel = useSelector(selectUserAccessLevel);

  const defaultToRequestedTab = () => {
    let defaultActiveKey;

    if (location.pathname === paymentPagePathname) {
      defaultActiveKey = 3;
    } else if (location.pathname === accountDetailsPathname) {
      defaultActiveKey = 1;
    } else if (location.pathname === emailConfigPathname) {
      defaultActiveKey = 2;
    } else if (location.pathname === plansAndBillingsPathname) {
      defaultActiveKey = 3;
    } else if (location.pathname === ApisPathname) {
      defaultActiveKey = 4;
    } else if (location.pathname === teamCollabPathname) {
      defaultActiveKey = 5;
    } else if (location.pathname === accountSecurityPathname) {
      defaultActiveKey = 6;
    }
    return String(defaultActiveKey);
  };

  const [tabKey, setTabKey] = useState(defaultToRequestedTab());

  //   Read Only
  const [tabKeys] = useState({
    defaultActiveKey: defaultToRequestedTab(),
    accountDetails: '1',
    emailConfiguration: '2',
    plansAndBillings: '3',
    apis: '4',
    teamCollab: '5',
    accountSecurity: '6',
  });

  useEffect(() => {
    const handleTabChange = () => {
      const currentPath = location.pathname;

      const tabKeyMap = {
        [accountDetailsPathname]: tabKeys.accountDetails,
        [emailConfigPathname]: tabKeys.emailConfiguration,
        [plansAndBillingsPathname]: tabKeys.plansAndBillings,
        [ApisPathname]: tabKeys.apis,
        [teamCollabPathname]: tabKeys.teamCollab,
        [accountSecurityPathname]: tabKeys.accountSecurity,
        [paymentPagePathname]: tabKeys.plansAndBillings,
      };

      const activeKey = tabKeyMap[currentPath] || accountDetailsPathname;

      setTabKey(String(activeKey));
      // Remove the navigate call
    };

    handleTabChange();
  }, [
    location.pathname,
    accountDetailsPathname,
    emailConfigPathname,
    teamCollabPathname,
    plansAndBillingsPathname,
    ApisPathname,
    accountSecurityPathname,
    tabKeys.accountDetails,
    tabKeys.emailConfiguration,
    tabKeys.plansAndBillings,
    tabKeys.apis,
    tabKeys.teamCollab,
    tabKeys.accountSecurity,
    setTabKey,
  ]);

  /***Redirect to default tab */
  if (location.pathname === '/settings' || location.pathname === '/settings/account')
    return <Navigate to="/settings/account/details" />;

  const PaymentOrAccountSettingsJSX = () => {
    if (location.pathname === paymentPagePathname) {
      return <PayComponent />;
    } else {
      return <AccountSettingsJSX />;
    }
  };
  const AccountSettingsJSX = () => {
    if (location.pathname === accountDetailsPathname) {
      return <AccountDetails />;
    } else if (location.pathname === emailConfigPathname) {
      return <EmailConfig />;
    } else if (location.pathname === plansAndBillingsPathname) {
      return <Billings />;
    } else if (location.pathname === ApisPathname) {
      return <Apikeys />;
    } else if (location.pathname === teamCollabPathname) {
      return <TeamCollaboration />;
    } else if (location.pathname === accountSecurityPathname) {
      return <AccountSecurityTab />;
    }
  };

  const tabItems = [
    {
      label: 'Account Details',
      key: tabKeys.accountDetails,
      children: <PaymentOrAccountSettingsJSX />,
    },
    accessLevel?.role === 0 && {
      label: 'Email Configuration',
      key: tabKeys.emailConfiguration,
      children: <PaymentOrAccountSettingsJSX />,
    },
    accessLevel?.role === 0 && {
      label: 'Plans & Billings',
      key: tabKeys.plansAndBillings,
      children: (
        <div className="mt-5">
          <PaymentOrAccountSettingsJSX />
        </div>
      ),
    },
    {
      label: "API's",
      key: tabKeys.apis,
      children: <PaymentOrAccountSettingsJSX />,
    },
    {
      label: 'Teams',
      key: tabKeys.teamCollab,
      children: <PaymentOrAccountSettingsJSX />,
    },
    accessLevel?.role === 0 && {
      label: 'Account Security',
      key: tabKeys.accountSecurity,
      children: <PaymentOrAccountSettingsJSX />,
    },
  ];

  return (
    <div>
      <div className="emmaccen">
        <div className="accountSettings">
          <Tabs
            onTabClick={(key) => {
              if (key === tabKeys.accountDetails) {
                navigate(accountDetailsPathname);
              } else if (key === tabKeys.emailConfiguration) {
                navigate(emailConfigPathname);
              } else if (key === tabKeys.plansAndBillings) {
                navigate(plansAndBillingsPathname);
              } else if (key === tabKeys.apis) {
                navigate(ApisPathname);
              } else if (key === tabKeys.teamCollab) {
                navigate(teamCollabPathname);
              } else if (key === tabKeys.accountSecurity) {
                navigate(accountSecurityPathname);
              }
            }}
            //defaultActiveKey={tabKeys?.defaultActiveKey}
            onChange={() => {}}
            items={tabItems}
            activeKey={tabKey}
          />
        </div>
      </div>
    </div>
  );
}

export default AccountSettings;
