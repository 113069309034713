import { useNavigate, useParams } from 'react-router-dom';
import TemplateSelection from './components/TemplateSelection';
import { MdTypography } from 'components/global';
import styles from '../../styles/optInPage.module.scss';

const OptInPageAction = () => {
  const navigate = useNavigate();
  const { optInPageId, optInPageName } = useParams();
  const submitTemplateSelected = (temp) => {
    navigate(`/opt-in-page/${optInPageName}/${optInPageId}/edit-opt-in-page?template_id=${temp?.id}`);
  };

  return (
    <div className={styles['landing-page-action']}>
      <div className={styles['landing-page-action-header']}>
        <MdTypography className={styles['btnLight']} variant="btnLight">
          Design opt-in page
        </MdTypography>
        <MdTypography className={styles['pLight']} variant="pLight">
          Begin with a hand-full of pre-made templates, or design your new opt-in page from scratch
        </MdTypography>
      </div>
      <TemplateSelection submitTemplateSelected={submitTemplateSelected} />
    </div>
  );
};

export default OptInPageAction;
