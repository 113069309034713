import { useEffect } from 'react';

const HideFormillaChatBox = () => {
  useEffect(() => {
    const observer = new MutationObserver((mutationsList, observer) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          const formillaIframe = document.querySelector('#formilla-frame');
          if (formillaIframe) {
            formillaIframe.addEventListener('load', () => {
              formillaIframe.style.setProperty('display', 'none', 'important');
            });
            observer.disconnect();
          }
        }
      }
    });

    observer.observe(document.body, { childList: true, subtree: true });

    return () => {
      observer.disconnect();
    };
  }, []);

  return null;
};

export default HideFormillaChatBox;
