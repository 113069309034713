import React from 'react';
import { MdTypography } from '../../components/global';
import MdGlobalButton from '../../components/global/Button';
import { MdSearchInput } from '../../lib';
import '../../styles/instantemails.scss';
import { LoadingOutlined } from '@ant-design/icons';
import Search from '../../asset/Icons/Search';

function PageHeader({ title, image, description, logic, loading,placeholder, searching, onSearchCallback }) {
  return (
    <div className="email-header">
      <div className="textPart">
        <MdTypography variant={'h2'} className="campHTitle">
          {title}
        </MdTypography>
        <MdTypography variant={'btnLight'} className="campHDesc">
          {description}
        </MdTypography>
        <div className="flex rightSection">
          <MdSearchInput
            placeholder={placeholder}
            allowClear
            className="fade3 campHeaderInput"
            prefix={searching ? <LoadingOutlined /> : <Search />}
            size="large"
            onChange={({ target: { value } }) => onSearchCallback(value)}
          />

          <MdGlobalButton data-test="createNewBtn" id="primary_lg" className="mx-2" loading={loading} onClick={logic}>
            Create New
          </MdGlobalButton>
        </div>
      </div>
      <div className="imgPart">
        <img src={image} alt="imgpart" />
      </div>
    </div>
  );
}
export default PageHeader;
