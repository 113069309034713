import { Modal, Tabs } from 'antd';
import TabPane from 'antd/es/tabs/TabPane';
import LoadingScreen from 'components/LoadingScreen';
import { MdTypography } from 'components/global';
import { MdEmptyBox } from 'pages/ContactPage/Icons/MdEmptyBox';
import TemplateCard from 'pages/InstantEmail/components/TemplateCard';
import emptyTableImg from '../../../asset/images/flat-color-icons_template.svg';
import React, { useEffect, useState } from 'react';
import { getTemplateList } from '../utils';
import axios from 'axios';
import { MdClose } from 'pages/ContactPage/Icons/MdClose';

export const ChangeTemplateModal = ({
  setModalVisible,
  modalVisible,
  handleTemplateChange,
  setCurrentTemplate,
  landingPageId,
}) => {
  const items = getTemplateList().map((obj, index) => ({
    _id: index,
    key: obj.sub,
    title: obj.title,
    typeOfMail: 'speditor',
    body: obj.htmlTemplate,
    content: obj.cssTemplate,
    type: obj.type,
    previewImage: obj?.imageUrl || emptyTableImg,
    isSaved: true,
  }));

  const [mySavedFetchTemplates, setMySavedFetchTemplates] = useState([]);
  const [loading, setLoading] = useState({ id: '' });
  const [isTemplateLoading, setIsTemplateLoading] = useState(false);
  useEffect(() => {
    setIsTemplateLoading(true);
    (async () => {
      try {
        const resp2 = await axios.get(`/templates?type=${'speditor'}`);
        setIsTemplateLoading(false);
        if (resp2?.status) {
          setMySavedFetchTemplates(resp2.data.data);
          return;
        }
        setMySavedFetchTemplates([]);
      } catch (error) {
        console.log({ error });
      }
    })();
  }, []);
  const onClose = () => setModalVisible({ id: '' });

  const selectTemplateAndClose = (temp) => {
    // handleTemplateChange(temp.title, temp.key);
    setCurrentTemplate({
      htmlTemplate: temp.body,
      cssTemplate: temp.content,
    });
    setLoading({ id: '' });
    onClose();
  };
  const selectMyTemplateAndClose = (temp) => {
    setCurrentTemplate({
      htmlTemplate: temp.body,
      cssTemplate: temp.content,
    });
    setLoading({ id: '' });
    onClose();
  };

  const myTemplates = mySavedFetchTemplates.map((obj, index) => ({
    _id: index,
    typeOfMail: obj.typeOfMail,
    body: obj.strippoHtml,
    content: obj.strippoCss,
    previewImage: obj?.previewImage || emptyTableImg,
    isSaved: true,
  }));

  return (
    <Modal
      open={modalVisible.id === 'changeTemplate'}
      onOk={() => setModalVisible({ id: '' })}
      //   onCancel={() => setModalVisible({ id: '' })}
      footer={null}
      closable={false}
      centered
      className="stripo-temp"
      width={'65%'}
    >
      <div className="">
        <div className="flex justify-between mb-8">
          <div className="w-1/2">
            <MdTypography variant={'h5Medium'} className="">
              Email templates
            </MdTypography>
            <MdTypography variant={'h6Light'} className="text-sm mt-1">
              Select a template from the pre-made templates or select one of the templates you have created previously
            </MdTypography>
          </div>
          <MdClose onClick={onClose} cursor="pointer" />
        </div>
        <div className="preview_template">
          <Tabs>
            <TabPane tab={<MdTypography variant={'h6Light'}>Templates</MdTypography>} key="1">
              <div>
                <div className="template-section">
                  <div className="my-3">
                    <MdTypography variant={'h6Medium'}>Quick Layout</MdTypography>
                  </div>
                  <div className="mb-8 template-grid grid-4">
                    {/* <div className="templatePane  flex flex-wrap overflow-y-auto"> */}
                    {items
                      .filter((obj) => obj.type === 'quick')
                      .map((temp, index) => {
                        return (
                          <TemplateCard
                            key={index}
                            id={temp._id}
                            icon={temp.previewImage}
                            title={temp.title}
                            data-index={index}
                            submitTemplateSelected={selectTemplateAndClose}
                            temp={temp}
                            setLoading={setLoading}
                            loading={loading}
                          />
                        );
                      })}
                  </div>
                </div>
                <div className="template-section">
                  <div className="mb-3">
                    <MdTypography variant={'h6Medium'}>Newsletters & Promotional Emails</MdTypography>
                  </div>
                  <div className="mb-8 template-grid grid-4">
                    {items
                      .filter((obj) => obj.type === 'newsletter')
                      .map((temp, index) => {
                        return (
                          <TemplateCard
                            key={index}
                            id={temp._id}
                            icon={temp.previewImage}
                            title={temp.title}
                            data-index={index}
                            submitTemplateSelected={selectTemplateAndClose}
                            temp={temp}
                            setLoading={setLoading}
                            loading={loading}
                          />
                        );
                      })}
                  </div>
                </div>
              </div>
            </TabPane>
            {!landingPageId && (
              <TabPane tab={<MdTypography variant={'h6Light'}>My Saved Templates</MdTypography>} key="2">
                {isTemplateLoading ? (
                  <LoadingScreen />
                ) : (
                  <>
                    {!mySavedFetchTemplates.length ? (
                      <div className="w-full emptyBox">
                        <MdEmptyBox />
                        <div className="emptyText">You have not created any template yet</div>
                      </div>
                    ) : (
                      <div className="mb-8 template-grid">
                        {myTemplates.map((temp, index) => {
                          return (
                            <TemplateCard
                              key={index}
                              id={temp._id}
                              icon={temp.previewImage}
                              title={temp.title}
                              data-index={index}
                              submitTemplateSelected={selectMyTemplateAndClose}
                              temp={temp}
                              fetchedTemplate
                              setLoading={setLoading}
                              loading={loading}
                            />
                          );
                        })}
                      </div>
                    )}
                  </>
                )}
              </TabPane>
            )}
          </Tabs>
        </div>
      </div>
    </Modal>
  );
};
