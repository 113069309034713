import { CLOSE_MODAL, OPEN_MODAL, SET_MODAL_PROPS } from './types';

export const reducer = (state, { type, payload }) => {
  switch (type) {
    case CLOSE_MODAL:
      state = state.map((modal) => {
        if (modal.id === payload) modal.open = false;
        return modal;
      });
      return state;

    case OPEN_MODAL:
      state = state.map((modal) => {
        if (modal.id === payload) modal.open = true;
        return modal;
      });
      return state;

    // case SET_MODAL_LOADING:
    //   state = state.map((modal) => {
    //     if (modal.id === payload) modal.loading = false;
    //     return modal;
    //   });
    //   return state;

    case SET_MODAL_PROPS:
      state = state.map((modal) => {
        if (modal.id === payload.id) modal.props = { ...modal.props, ...payload.data };
        return modal;
      });
      return state;
    default:
      return state;
  }
};
