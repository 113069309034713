import React from 'react';

export default function Folder() {
  return (
    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.53906 11.2812C7.28281 11.2812 7.07031 11.0687 7.07031 10.8125V7.6875C7.07031 7.43125 7.28281 7.21875 7.53906 7.21875C7.79531 7.21875 8.00781 7.43125 8.00781 7.6875V10.8125C8.00781 11.0687 7.79531 11.2812 7.53906 11.2812Z"
        fill="#273DF2"
      />
      <path
        d="M9.0625 9.71875H5.9375C5.68125 9.71875 5.46875 9.50625 5.46875 9.25C5.46875 8.99375 5.68125 8.78125 5.9375 8.78125H9.0625C9.31875 8.78125 9.53125 8.99375 9.53125 9.25C9.53125 9.50625 9.31875 9.71875 9.0625 9.71875Z"
        fill="#273DF2"
      />
      <path
        d="M10.625 14.7188H4.375C1.61875 14.7188 0.78125 13.8813 0.78125 11.125V4.875C0.78125 2.11875 1.61875 1.28125 4.375 1.28125H5.3125C6.40625 1.28125 6.75 1.6375 7.1875 2.21875L8.125 3.46875C8.33125 3.74375 8.3625 3.78125 8.75 3.78125H10.625C13.3813 3.78125 14.2188 4.61875 14.2188 7.375V11.125C14.2188 13.8813 13.3813 14.7188 10.625 14.7188ZM4.375 2.21875C2.14375 2.21875 1.71875 2.64375 1.71875 4.875V11.125C1.71875 13.3562 2.14375 13.7812 4.375 13.7812H10.625C12.8562 13.7812 13.2812 13.3562 13.2812 11.125V7.375C13.2812 5.14375 12.8562 4.71875 10.625 4.71875H8.75C7.95 4.71875 7.6875 4.44375 7.375 4.03125L6.4375 2.78125C6.1125 2.35 6.01875 2.21875 5.3125 2.21875H4.375Z"
        fill="#273DF2"
      />
    </svg>
  );
}
