import { Modal } from 'antd';
import { DeleteWarningIcon } from 'asset/Icons/DeleteWarningIcon';
import { MdGlobalButton, MdTypography } from 'components/global';

const DeleteModal = ({
  open,
  onCancel,
  deleteFunction,
  header,
  description,
  disableDeleteButton,
  deleteButtonDataTestId,
  multiple
}) => {
  return (
    <Modal open={open} onCancel={onCancel} centered footer={null} className="global-delete-modal" width={'385px'}>
      <DeleteWarningIcon />
      <MdTypography variant="h5Medium" className="delete-modal-title">
        Are you sure you would like to delete {multiple ? 'these' : 'this'} {header}?
      </MdTypography>
      <MdTypography variant="btnLight" className="delete-modal-description">
        {description}
      </MdTypography>

      <div className="delete-modal-actions">
        <MdGlobalButton className="delete-cancel" onClick={onCancel}>
          <MdTypography variant="btnRegular">Cancel</MdTypography>
        </MdGlobalButton>
        <MdGlobalButton
          className="delete-submit"
          onClick={deleteFunction}
          loading={disableDeleteButton ? disableDeleteButton : false}
          dataTestId={deleteButtonDataTestId}
        >
          <MdTypography variant="btnRegular">Delete</MdTypography>
        </MdGlobalButton>
      </div>
    </Modal>
  );
};

export default DeleteModal;
