import React, { useState, useContext } from 'react';
import Modal from '../../../../components/Modal';
import { marked } from 'marked';
import hljs from 'highlight.js';
import Paragraph from 'antd/lib/typography/Paragraph';
import { CheckCircleFilled, CopyOutlined } from '@ant-design/icons';
import copyIcon from '../../../../asset/Icons/copyIcon.png';
import '../../../../styles/modals.scss';
import { message, Popconfirm } from 'antd';
import RedirectUrlInput from '../../../../components/emmaccen/modals/RedirectUrlInput';
import { MdTypography } from '../../../../components/global';
import { joinCampaignContext } from '../../../../contexts/joincampaign';

const MdEmbeddedForm = ({ campaignId, credencials }) => {
  const [embeddedModal, setEmbeddedModal] = useState({ id: '' });
  const [inputUrlModal, setInputUrlModal] = useState(false);
  const { state } = useContext(joinCampaignContext);
  const { setLink: campaignRedirectUrl } = state;

  const STEP1_TEXT = `
\`\`\`html
<script src='https://integrations.maildrip.io/maildrip_html.min.js' type='module'></script>
\`\`\`
`;
  const STEP1_COPY = `<script src='https://integrations.maildrip.io/maildrip_html.min.js' type='module'></script>`;

  const STEP2_TEXT = `
\`\`\`html
<form id="maildrip-contact-form" data-maildrip-apikey="${credencials?.apiKey}"  data-maildrip-accesssecret="${credencials?.secretKey}" data-maildrip-campaignid="${campaignId}" data-maildrip-redirect-url="${campaignRedirectUrl}">
  <input type="text" class="maildrip-newsub-name" placeholder="Your Name" />
  <input type="email" class="maildrip-newsub-email" placeholder="Your Email" />
  <!-- Additional input fields can be added here -->
  <button type="submit">Submit</button>
</form>
\`\`\`
`;
  const STEP2_COPY = `

<form id="maildrip-contact-form" data-maildrip-apikey="${credencials?.apiKey}"  data-maildrip-accesssecret="${credencials?.secretKey}" data-maildrip-campaignid="${campaignId}">
  <input type="text" class="maildrip-newsub-name" placeholder="Your Name" />
  <input type="email" class="maildrip-newsub-email" placeholder="Your Email" />
  <!-- Additional input fields can be added here -->
  <button type="submit">Submit</button>
</form>
  `;

  marked.setOptions({
    langPrefix: 'hljs language-',
    highlight: function (code, lang) {
      // const hljs = require('highlight.js');
      const language = hljs.getLanguage(lang) ? lang : 'plaintext';
      return hljs.highlight(code, { language }).value;
    },
    breaks: true,
    gfm: true,
  });

  const confirm = (e) => {
    setInputUrlModal(true);
  };

  const cancel = (e) => {
    message.success('Form tag Copied successfully');
    navigator.clipboard.writeText(STEP2_COPY);
    setEmbeddedModal({ id: '' });
  };

  return (
    <>
      <Modal
        isActive={embeddedModal.id === 'embeddedModalActive'}
        onCancel={() => setEmbeddedModal({ id: '' })}
        className={'embedded_form'}
        title="Embed Code"
        centered
        // width={600}
      >
        <div className="embedded_form-child">
          <div className="step1">
            <div className="step_desc">
              <span>
                Step 1: Add the below script tag in the <code>{`<head>…</head>`}</code> section of the page{' '}
              </span>
            </div>
            <div className="code-block">
              <div dangerouslySetInnerHTML={{ __html: marked.parse(STEP1_TEXT, (err, html) => html) }} />
              <span className="copy_content">
                <Paragraph
                  onClick={() => message.success('Script tag Copied successfully')}
                  copyable={{
                    icon: [
                      <div className="copyIcon_campnForms  pl-2 pr-2 ">
                        <span>Copy</span>
                        <CopyOutlined className="pl-2" color="#fff" key="copy-icon" />{' '}
                      </div>,
                      <div className="copyIcon_campnForms">
                        <span>Copied</span>
                        <CheckCircleFilled className="pl-2" key="copied-icon" />{' '}
                      </div>,
                    ],
                    text: STEP1_COPY,
                    tooltips: ['Click here to copy', 'Code copied to clipboard'],
                  }}
                />
              </span>
            </div>
          </div>
          <div className="step2">
            <div className="step_desc">
              <span>
                Step 2: Add the below html code in the <code>{`<body>…</body>`}</code> section of the page{' '}
              </span>
            </div>
            <div className="code-block">
              <div dangerouslySetInnerHTML={{ __html: marked.parse(STEP2_TEXT, (err, html) => html) }} />
              <span className="copy_content">
                <Popconfirm
                  title="Would you like to redirect your users to a particular link after subscribing?"
                  onConfirm={confirm}
                  onCancel={cancel}
                  okText="Yes"
                  cancelText="No"
                >
                  <div className="copyIcon_campnForms pl-2 pr-2">
                    <span>Copy</span>
                    <CopyOutlined className="pl-2" color="#fff" key="copy-icon" />{' '}
                  </div>
                </Popconfirm>
              </span>
            </div>
          </div>
        </div>
      </Modal>
      <RedirectUrlInput
        imVisible={inputUrlModal}
        setImVisible={setInputUrlModal}
        credencials={credencials}
        campaignId={campaignId}
        context="htmlForm"
        setEmbeddedModal={setEmbeddedModal}
      />
      <div className="embedForm_copyCode" onClick={() => setEmbeddedModal({ id: 'embeddedModalActive' })}>
        <MdTypography variant={'btnLight'} style={{ color: '#383838' }}>
          Copy code
        </MdTypography>
        <img src={copyIcon} alt="copy icon" className="img-fluid ml-1" />
      </div>
    </>
  );
};

export default MdEmbeddedForm;
