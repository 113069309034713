export const HourGlassIcon = (props) => {
  return (
    <svg width="91" height="91" viewBox="0 0 91 91" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask
        id="mask0_7551_10427"
        // style="mask-type:luminance"
        maskUnits="userSpaceOnUse"
        x="13"
        y="3"
        width="65"
        height="85"
      >
        <path d="M77.5396 3.60205H13.4604V87.3979H77.5396V3.60205Z" fill="white" />
      </mask>
      <g mask="url(#mask0_7551_10427)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M43.6491 28.6385C48.9648 25.2096 53.6502 20.7301 57.0221 15.3831C57.1968 15.1069 57.3676 14.8283 57.5344 14.5472C58.0178 13.7337 58.4809 12.8733 58.5736 11.9327C58.7192 10.4543 57.9571 9.14785 57.1862 7.82634C56.5941 6.81155 55.9969 5.78789 55.8017 4.67065C55.7553 7.06718 49.8816 14.0736 41.7147 21.3096C33.1588 28.8909 25.1558 34.0034 23.1953 33.2209C23.8562 34.3553 24.4582 35.2866 25.3149 36.289C25.7312 36.1878 26.1467 36.0826 26.5615 35.9736C32.5838 34.3905 38.4179 32.0113 43.6491 28.6385ZM56.1063 7.81813C56.0572 7.75682 55.9677 7.74692 55.9064 7.796C55.8452 7.84508 55.8352 7.93456 55.8843 7.99586C56.7471 9.07344 57.3974 10.305 57.8007 11.6252C57.8237 11.7004 57.9032 11.7426 57.9783 11.7197C58.0533 11.6968 58.0956 11.6172 58.0727 11.5421C57.6588 10.1875 56.9916 8.9238 56.1063 7.81813Z"
          fill="#171717"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M43.9193 73.7815C51.4737 73.6792 59.0445 74.1914 66.4907 75.4563C66.2042 70.2039 65.16 65.8855 63.7147 62.3457L63.4777 62.4132C60.3855 63.4784 57.411 64.9768 54.9238 67.1002C54.5745 67.3993 54.2349 67.71 53.8982 68.0254L53.7458 67.8614C51.4539 68.1712 49.121 67.8451 47.0019 66.9188C46.4543 66.6786 45.9247 66.3998 45.3953 66.1211C44.7043 65.7572 44.0137 65.3935 43.2844 65.1166C41.9965 64.6274 40.7017 64.4325 39.46 65.0288L39.4551 65.0278C38.3553 68.8948 37.5313 72.5166 37.02 74.9488C37.5531 74.6924 38.1086 74.4854 38.6794 74.3304C40.3785 73.8654 42.1586 73.8055 43.9193 73.7815ZM63.981 65.2813C63.9587 65.206 63.8794 65.1632 63.8042 65.1857C63.7289 65.208 63.6861 65.2872 63.7085 65.3624C64.5527 68.1981 64.9747 71.1427 64.9608 74.1012C64.9604 74.1798 65.0239 74.2437 65.1023 74.244C65.1809 74.2444 65.2448 74.1811 65.2452 74.1025C65.2592 71.116 64.8332 68.1437 63.981 65.2813Z"
          fill="url(#paint0_linear_7551_10427)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.8315 41.6723C36.8094 44.6404 42.2547 47.5873 47.893 47.2271C47.8872 47.232 47.8805 47.2377 47.8746 47.2429C48.2428 47.3154 48.6222 47.3075 48.987 47.2198L49.0507 47.2265C53.0807 42.9187 55.2601 37.6952 56.3928 32.7343L56.1671 32.6841C55.1492 36.0271 52.6271 38.7816 49.6045 40.4854C46.5818 42.1893 43.0912 42.91 39.6324 43.0287C38.2094 43.0769 36.7611 43.0123 35.3776 42.6495C34.9213 42.53 33.6333 42.1585 32.873 41.6163L32.8315 41.6723ZM53.6463 38.7931C53.6735 38.7194 53.6358 38.6377 53.5621 38.6105C53.4884 38.5833 53.4066 38.621 53.3794 38.6946C52.535 40.9839 51.3058 43.1118 49.7447 44.987C49.6944 45.0473 49.7026 45.137 49.763 45.1872C49.8233 45.2374 49.9129 45.2293 49.9632 45.1689C51.545 43.2689 52.7906 41.1126 53.6463 38.7931Z"
          fill="url(#paint1_linear_7551_10427)"
        />
        <path
          d="M66.5433 76.9654C66.5038 75.8625 59.7367 75.2084 51.4288 75.5046C43.2743 75.795 36.6664 76.8939 36.4619 77.9764L36.4214 77.9687C36.4667 77.7169 36.6702 76.6103 37.0195 74.9488C37.5526 74.6924 38.1081 74.4854 38.6789 74.3304C40.378 73.8654 42.1578 73.8056 43.9188 73.7815C51.4732 73.6792 59.044 74.1915 66.4902 75.4564C66.5183 75.9542 66.5385 76.4604 66.551 76.9749H66.5433V76.9654Z"
          fill="url(#paint2_linear_7551_10427)"
        />
        <path
          d="M51.5718 79.4977C43.2629 79.7939 36.4968 79.1397 36.4573 78.0369C36.4564 78.0165 36.458 77.9962 36.4621 77.9762C36.6666 76.8936 43.2746 75.7947 51.429 75.5044C59.7369 75.2082 66.504 75.8623 66.5436 76.9651V76.9746C66.5437 78.0736 59.8555 79.2025 51.5718 79.4977Z"
          fill="url(#paint3_linear_7551_10427)"
        />
        <path
          d="M36.4571 78.037C36.4967 79.1398 43.2632 79.794 51.5717 79.4977C59.8554 79.2025 66.5435 78.0737 66.5435 76.9748H66.5512C66.5385 76.4596 66.5183 75.9534 66.4905 75.4562C67.1494 75.5671 67.8081 75.6848 68.4664 75.8093C68.8118 75.8739 69.1813 75.954 69.4177 76.2145C69.4806 76.2843 69.5309 76.3645 69.5663 76.4515L69.5875 76.442C70.2214 77.8639 70.7771 79.7802 70.5128 80.9901C70.2368 82.252 70.0293 83.5903 69.1524 84.5387C67.9328 85.8563 66.0399 86.2656 64.2637 86.5309C55.8062 87.793 47.1809 87.3326 38.9059 85.1774C36.9454 84.6655 34.819 83.9095 33.8485 82.1305C33.0043 80.5854 33.3082 78.5771 34.3289 77.1415C35.0082 76.1854 35.9584 75.4618 37.0197 74.9485C36.6704 76.6099 36.4669 77.7164 36.4216 77.9684L36.4621 77.976C36.458 77.996 36.4564 78.0165 36.4571 78.037Z"
          fill="#171717"
        />
        <path
          d="M23.1953 33.2212C23.0929 33.1819 23.001 33.1191 22.9271 33.038C21.5793 31.5165 27.8042 23.7981 36.833 15.7999C45.8618 7.80163 54.2713 2.54998 55.619 4.07177C55.7545 4.24069 55.8198 4.45522 55.8014 4.67094C55.755 7.06747 49.8814 14.0743 41.7144 21.3099C33.1585 28.8912 25.1555 34.0037 23.1953 33.2212Z"
          stroke="#171717"
          strokeWidth="0.284375"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.1675 33.1738C23.177 33.1902 23.1858 33.2057 23.1959 33.2212C23.8568 34.3559 24.4588 35.2869 25.3156 36.2893"
          stroke="#171717"
          strokeWidth="0.284375"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25.3149 36.2892C25.7308 36.1882 26.1463 36.083 26.5615 35.9738C32.5838 34.3908 38.4179 32.0115 43.6491 28.6383C48.9651 25.2094 53.6502 20.7299 57.0221 15.3829C57.1967 15.1071 57.3673 14.8286 57.534 14.5474C58.0174 13.7341 58.4805 12.8735 58.5731 11.9329C58.8306 9.31924 56.2507 7.24302 55.8012 4.67085C55.7838 4.57046 55.7693 4.46922 55.7587 4.36694"
          stroke="#171717"
          strokeWidth="0.284375"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M47.893 47.2273C47.8872 47.2321 47.8805 47.2379 47.8747 47.2437C44.3174 50.2847 41.4383 58.0553 39.4548 65.0278C38.3552 68.8948 37.5306 72.5164 37.0197 74.9488C36.6704 76.6103 36.4669 77.7168 36.4216 77.9687C36.4148 78.0053 36.4121 78.0237 36.4121 78.0237"
          stroke="#171717"
          strokeWidth="0.284375"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M57.0219 15.3811V15.383C57.048 15.5297 58.4595 23.6948 56.3929 32.735C55.2602 37.6959 53.0808 42.9194 49.0508 47.2272"
          stroke="#171717"
          strokeWidth="0.284375"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M26.5615 35.9734C26.5615 35.9734 29.0247 38.833 32.8318 41.6725C36.8096 44.6401 42.2549 47.5866 47.8932 47.2272"
          stroke="#171717"
          strokeWidth="0.284375"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M49.0508 47.2273C49.0508 47.2273 58.7499 50.1848 63.7151 62.3457C65.1603 65.8855 66.2042 70.2039 66.4907 75.4563C66.5187 75.9542 66.539 76.4604 66.5515 76.9749C66.5525 76.9933 66.5535 77.0116 66.5535 77.0299"
          stroke="#171717"
          strokeWidth="0.284375"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M53.0269 41.9257C54.6684 40.7544 56.4933 39.8646 58.4269 39.2927"
          stroke="#171717"
          strokeWidth="0.284375"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M58.7383 37.9419C59.0781 36.1389 59.9305 34.472 61.1934 33.1411C61.3177 34.399 61.0441 35.664 60.411 36.758C62.2304 34.642 64.7211 33.2146 67.4665 32.7146C66.3454 34.2806 64.8294 35.5216 63.0728 36.3114C64.0714 35.9071 65.0787 35.499 66.139 35.3066C67.1992 35.1142 68.331 35.1513 69.305 35.6106C67.6611 36.5767 65.826 37.1724 63.9281 37.3559C65.6205 37.2252 67.31 37.6429 68.7464 38.5474C68.9692 38.6883 69.2124 38.9478 69.0782 39.1745C68.9826 39.3366 68.7569 39.3501 68.5687 39.3451L59.1581 39.0759L59.4013 39.0962"
          stroke="#171717"
          strokeWidth="0.284375"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M33.5479 47.9304C35.3525 47.047 37.307 46.5101 39.3097 46.3474"
          stroke="#171717"
          strokeWidth="0.284375"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M32.2915 47.5656C30.946 47.103 29.4805 47.1293 28.1525 47.6399C27.592 47.857 26.996 48.2661 26.9771 48.8672C27.7074 48.8807 28.4378 48.8938 29.1682 48.9067C27.5485 50.3902 26.1673 52.1147 25.0732 54.0192C24.9873 54.1678 24.9101 54.3723 25.0317 54.492C25.1638 54.6241 25.3857 54.5151 25.5353 54.4042C26.6294 53.5928 27.7237 52.7817 28.8185 51.971C27.6645 53.2149 26.8435 54.7303 26.4315 56.3762C26.3786 56.5904 26.3862 56.8976 26.6033 56.9387C26.7519 56.9676 26.8782 56.8355 26.9719 56.7159C27.7798 55.6762 28.5225 54.5874 29.1958 53.4558C28.585 54.7178 27.9635 56.0211 27.8788 57.4211C27.8672 57.6044 27.9116 57.8465 28.093 57.8726C28.2599 57.8968 28.3728 57.7115 28.4384 57.5562L32.1724 48.6327"
          stroke="#171717"
          strokeWidth="0.284375"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M32.8731 41.6168C33.6334 42.159 34.9214 42.5305 35.3778 42.65C36.7612 43.0128 38.2095 43.0774 39.6325 43.0292C43.0913 42.9105 46.5819 42.1898 49.6046 40.4859C52.6272 38.782 55.1493 36.0276 56.1672 32.6846C55.2256 33.386 54.2843 34.0881 53.3432 34.7907C50.9323 36.5895 48.3833 38.4465 45.4147 38.8555C43.2439 39.1547 41.0543 38.6453 38.8694 38.4774C36.6846 38.3095 34.2857 38.5633 32.687 40.0779C32.5222 40.2155 32.3955 40.3932 32.3194 40.5941C32.2036 40.9762 32.4602 41.3216 32.8731 41.6168Z"
          stroke="#171717"
          strokeWidth="0.284375"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M39.46 65.0286C40.7017 64.4323 41.9965 64.6272 43.2844 65.1164C44.5724 65.6055 45.7395 66.3649 47.0019 66.9186C49.121 67.8449 51.454 68.171 53.7458 67.8612"
          stroke="#171717"
          strokeWidth="0.284375"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M44.5713 71.8616C46.4025 72.7232 48.6253 72.1481 50.3292 71.057C51.6462 70.2134 52.7528 69.0956 53.898 68.0256C54.2347 67.7101 54.5743 67.3995 54.9236 67.1003C57.4108 64.977 60.3853 63.4785 63.4775 62.4133"
          stroke="#171717"
          strokeWidth="0.284375"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M48.8814 48.2886C48.7313 49.3052 48.7992 50.3421 49.0804 51.3305"
          stroke="#171717"
          strokeWidth="0.284375"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M48.4196 51.7405C48.3994 53.2389 48.3789 54.7372 48.3579 56.2355"
          stroke="#171717"
          strokeWidth="0.284375"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M49.792 54.1487C49.985 55.6315 50.1779 57.115 50.3709 58.5992"
          stroke="#171717"
          strokeWidth="0.284375"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M48.8305 59.5942C48.709 60.9266 48.5874 62.2584 48.4658 63.5894"
          stroke="#171717"
          strokeWidth="0.284375"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M47.8711 47.2426C47.872 47.2426 47.874 47.2436 47.875 47.2436C48.2431 47.3161 48.6225 47.3081 48.9874 47.2205"
          stroke="#171717"
          strokeWidth="0.284375"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M36.4621 77.9762C36.6666 76.8936 43.2746 75.7947 51.429 75.5044C59.7369 75.2082 66.504 75.8623 66.5436 76.9651V76.9746C66.5436 78.0735 59.8555 79.2022 51.5718 79.4976C43.2629 79.7938 36.4968 79.1396 36.4573 78.0368C36.4564 78.0165 36.458 77.9962 36.4621 77.9762Z"
          stroke="#171717"
          strokeWidth="0.284375"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M67.7025 78.4085C68.2486 78.2705 68.8217 78.1182 69.2286 77.7294C69.5653 77.4062 69.7341 76.8658 69.5663 76.4519C69.5309 76.3649 69.4806 76.2847 69.4177 76.2149C69.1813 75.9545 68.8118 75.8744 68.4664 75.8097C67.8083 75.6863 67.1497 75.5686 66.4905 75.4566C59.0442 74.1917 51.4734 73.6795 43.919 73.7817C42.1583 73.8058 40.3782 73.8657 38.6792 74.3308C38.1082 74.4857 37.5528 74.6928 37.0197 74.9491C35.9581 75.4624 35.0082 76.1861 34.3289 77.1422C33.3082 78.5778 33.0043 80.5855 33.8485 82.1311C34.819 83.9101 36.9454 84.6656 38.9059 85.178C47.1809 87.3332 55.8062 87.7936 64.2637 86.5315C66.0399 86.2661 67.9328 85.8572 69.1524 84.5394C70.0294 83.591 70.2368 82.2527 70.5128 80.9908C70.7771 79.7809 70.2214 77.8647 69.5875 76.4426C69.5808 76.4262 69.573 76.4099 69.5663 76.3945"
          stroke="#171717"
          strokeWidth="0.284375"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.181 39.3838C20.1953 42.9208 21.2038 46.7424 19.9566 50.204C19.6552 51.0404 19.2013 51.86 18.4718 52.3682C17.7422 52.8763 16.6909 52.9954 15.9763 52.4664C15.2616 51.9373 15.1387 50.7002 15.8572 50.1768C16.7672 49.5132 18.1444 50.5174 18.1953 51.6426C18.2462 52.7678 17.4438 53.749 16.5806 54.4725C15.7174 55.1961 14.7227 55.8152 14.1295 56.7727C13.6284 57.5815 13.4742 58.6421 13.8926 59.4968C14.311 60.3514 15.3672 60.8947 16.2669 60.5849C17.1667 60.2751 17.6467 59.0318 17.0747 58.2714"
          stroke="#171717"
          strokeWidth="0.284375"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M66.6598 46.3323C66.2485 48.3513 65.8419 50.4792 66.4797 52.4388C67.1176 54.3983 69.1962 56.0515 71.1704 55.4609C72.0881 55.186 72.9051 54.1373 72.4311 53.3052C71.9477 52.458 70.4746 52.746 70.1015 53.6473C69.7283 54.5485 70.2415 55.6181 71.0222 56.2027C71.8028 56.7874 72.7889 57.0053 73.7378 57.2302C74.6868 57.455 75.6708 57.722 76.3958 58.3742C77.6038 59.4611 77.7073 61.5292 76.6667 62.7772C76.1239 63.4282 75.2687 63.8656 74.4317 63.7321C73.5947 63.5987 72.8691 62.7842 72.9961 61.9465"
          stroke="#171717"
          strokeWidth="0.284375"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.3804 22.525L21.1562 21.7051"
          stroke="#171717"
          strokeWidth="0.284375"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.249 22.8183C21.2762 22.5337 21.3643 22.2582 21.5072 22.0106C21.6502 21.7631 21.8447 21.5491 22.0776 21.3833"
          stroke="#171717"
          strokeWidth="0.284375"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.3829 46.9284L16.4634 46.1111"
          stroke="#171717"
          strokeWidth="0.284375"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.3247 47.2146L17.063 45.8997"
          stroke="#171717"
          strokeWidth="0.284375"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M73.3586 51.4235C73.341 51.2318 73.2765 51.0472 73.1709 50.8862C73.0651 50.7252 72.9215 50.5927 72.7524 50.5002"
          stroke="#171717"
          strokeWidth="0.284375"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M72.5459 51.4233L73.5675 50.606"
          stroke="#171717"
          strokeWidth="0.284375"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M72.0359 68.2783L71.3208 67.1545"
          stroke="#171717"
          strokeWidth="0.284375"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M70.9116 68.2784L72.0354 67.3589"
          stroke="#171717"
          strokeWidth="0.284375"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M31.296 43.2091C29.5408 42.3396 28.0544 41.0102 26.9951 39.3625"
          stroke="#171717"
          strokeWidth="0.284375"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M59.1748 29.4618C59.858 25.6765 59.8661 21.8003 59.1991 18.0122"
          stroke="#171717"
          strokeWidth="0.284375"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M63.2637 14.7528L64.1926 10.7473"
          stroke="#171717"
          strokeWidth="0.284375"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M64.688 19.1509L74.368 10.9492"
          stroke="#171717"
          strokeWidth="0.284375"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M65.7061 22.2891L69.4813 23.1265"
          stroke="#171717"
          strokeWidth="0.284375"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M35.9746 69.1979C36.2099 67.7159 36.7302 66.2936 37.5069 65.0095"
          stroke="#171717"
          strokeWidth="0.284375"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25.7549 17.9197L26.2639 18.5548C26.2793 18.5741 26.2884 18.5974 26.2901 18.622C26.2917 18.6465 26.2858 18.6709 26.2731 18.6919C26.2605 18.7131 26.2418 18.7296 26.2193 18.7397C26.1969 18.7497 26.1719 18.7527 26.1478 18.7481L25.3482 18.5965C25.3247 18.592 25.3006 18.5946 25.2786 18.6039C25.2567 18.6131 25.238 18.6287 25.2249 18.6486L24.7782 19.329C24.7647 19.3496 24.7452 19.3655 24.7224 19.3746C24.6996 19.3838 24.6746 19.3857 24.6506 19.3802C24.6266 19.3747 24.605 19.3619 24.5885 19.3437C24.572 19.3254 24.5616 19.3026 24.5585 19.2782L24.4563 18.4709C24.4532 18.4473 24.4434 18.4252 24.4278 18.4073C24.4122 18.3893 24.3917 18.3764 24.3688 18.3701L23.5837 18.1555C23.56 18.149 23.5388 18.1355 23.523 18.1166C23.5073 18.0978 23.4977 18.0745 23.4956 18.0501C23.4935 18.0256 23.4989 18.0011 23.5111 17.9797C23.5233 17.9584 23.5418 17.9414 23.5641 17.9309L24.3001 17.5835C24.3216 17.5734 24.3396 17.5571 24.3518 17.5368C24.364 17.5164 24.37 17.4929 24.3689 17.4691L24.3304 16.6562C24.3292 16.6316 24.3355 16.6073 24.3486 16.5865C24.3617 16.5657 24.3808 16.5494 24.4034 16.5398C24.426 16.5302 24.4511 16.5278 24.4751 16.5328C24.4991 16.5379 24.521 16.5502 24.5379 16.5681L25.0957 17.1607C25.112 17.1781 25.1331 17.1901 25.1562 17.1955C25.1793 17.2008 25.2035 17.1992 25.2258 17.1908L25.9871 16.9029C26.0101 16.8942 26.0352 16.8928 26.0591 16.8987C26.083 16.9048 26.1044 16.918 26.1206 16.9366C26.1367 16.9552 26.1468 16.9784 26.1493 17.0028C26.1519 17.0273 26.1469 17.052 26.135 17.0735L25.7437 17.7872C25.7324 17.808 25.7273 17.8316 25.7293 17.8552C25.7313 17.8788 25.7402 17.9013 25.7549 17.9197Z"
          fill="#171717"
        />
        <path
          d="M63.6742 51.3065L64.009 51.7241C64.0191 51.7368 64.0252 51.7522 64.0262 51.7683C64.0273 51.7844 64.0235 51.8005 64.0151 51.8143C64.0068 51.8282 63.9944 51.8391 63.9797 51.8458C63.9649 51.8523 63.9485 51.8542 63.9326 51.8512L63.4068 51.7514C63.3915 51.7486 63.3757 51.7503 63.3612 51.7564C63.3468 51.7625 63.3346 51.7727 63.326 51.7858L63.0321 52.2331C63.0233 52.2466 63.0105 52.2571 62.9955 52.263C62.9806 52.269 62.9641 52.2703 62.9483 52.2667C62.9326 52.263 62.9184 52.2547 62.9075 52.2428C62.8967 52.2307 62.8898 52.2157 62.8877 52.1997L62.82 51.6689C62.8181 51.6534 62.8116 51.6388 62.8013 51.627C62.7911 51.6152 62.7775 51.6067 62.7624 51.6025L62.2462 51.4615C62.2307 51.4572 62.2167 51.4483 62.2064 51.4359C62.196 51.4235 62.1897 51.4082 62.1883 51.3921C62.1869 51.376 62.1905 51.3599 62.1985 51.3458C62.2066 51.3318 62.2187 51.3206 62.2333 51.3137L62.7172 51.0854C62.7314 51.0787 62.7432 51.068 62.7513 51.0546C62.7593 51.0412 62.7632 51.0257 62.7624 51.0101L62.7371 50.4756C62.7364 50.4594 62.7406 50.4434 62.7492 50.4298C62.7578 50.4161 62.7704 50.4054 62.7853 50.3991C62.8001 50.3928 62.8166 50.3912 62.8324 50.3945C62.8481 50.3978 62.8625 50.4059 62.8736 50.4176L63.2404 50.8073C63.2511 50.8187 63.2649 50.8267 63.2801 50.8302C63.2953 50.8337 63.3111 50.8326 63.3257 50.8271L63.8263 50.6376C63.8415 50.6319 63.858 50.6309 63.8736 50.6349C63.8892 50.6388 63.9034 50.6475 63.9139 50.6597C63.9245 50.6719 63.9311 50.687 63.9328 50.7031C63.9345 50.7192 63.9313 50.7354 63.9236 50.7495L63.6663 51.2187C63.6588 51.2325 63.6556 51.2482 63.6569 51.2638C63.6583 51.2795 63.6644 51.2944 63.6742 51.3065Z"
          fill="#171717"
        />
        <g opacity="0.1">
          <path
            d="M47.9752 47.0319C47.5303 47.2864 47.2538 47.667 46.9266 48.054C46.5694 48.492 46.2375 48.95 45.9326 49.4259C45.3662 50.2772 44.8341 51.1502 44.3363 52.0447C43.3333 53.8534 42.4466 55.7243 41.6816 57.646C40.0405 61.7352 38.8829 65.9768 37.9563 70.2787C37.6866 71.5309 37.4332 72.7866 37.1878 74.0438C37.0708 74.6425 36.9555 75.2415 36.8416 75.8407C36.715 76.5043 36.4951 77.2141 36.5565 77.8911C36.6266 78.6639 37.7476 79.1627 38.1628 78.3262C38.4218 77.8042 38.4972 77.2728 38.6029 76.7046C38.7088 76.1348 38.8158 75.5651 38.9237 74.9956C39.1343 73.8865 39.3521 72.779 39.5772 71.6728C40.0247 69.4933 40.5157 67.3218 41.0919 65.1726C42.1621 61.1832 43.4567 57.2831 45.37 53.6093C45.8936 52.6041 46.4579 51.6217 47.0627 50.6618C47.3585 50.1947 47.68 49.7454 47.9802 49.2815C48.303 48.7828 48.6864 48.2735 48.8435 47.6993C48.8717 47.5865 48.8663 47.4679 48.828 47.3581C48.7898 47.2483 48.7202 47.1521 48.628 47.0812C48.5358 47.0103 48.425 46.968 48.309 46.9592C48.193 46.9504 48.0771 46.9757 47.9753 47.0319H47.9752Z"
            fill="#171717"
          />
        </g>
        <g opacity="0.1">
          <path
            d="M27.0472 36.2237C27.3926 36.9748 27.9486 37.5682 28.5086 38.1654C29.0584 38.7519 29.6339 39.3199 30.2207 39.8687C31.4077 40.979 32.7716 41.9434 34.1405 42.8138C36.8893 44.5594 39.8806 45.8899 43.0179 46.7621C44.7949 47.2498 46.61 47.5871 48.4436 47.7705C48.9193 47.8194 49.3279 47.329 49.3279 46.8862C49.3279 46.3674 48.9207 46.0511 48.4436 46.002C42.3491 45.3749 36.5078 42.8282 31.7887 38.94C31.1252 38.3933 30.4686 37.8499 29.8406 37.261C29.1764 36.6386 28.5778 35.9624 27.813 35.4583C27.3022 35.1215 26.8336 35.7603 27.047 36.2242L27.0472 36.2237Z"
            fill="#171717"
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_7551_10427"
          x1="37.02"
          y1="68.901"
          x2="66.4907"
          y2="68.901"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#9796F0" />
          <stop offset="1" stop-color="#FBC7D4" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_7551_10427"
          x1="32.8315"
          y1="39.988"
          x2="56.3928"
          y2="39.988"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#9796F0" />
          <stop offset="1" stop-color="#FBC7D4" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_7551_10427"
          x1="36.4214"
          y1="75.8726"
          x2="66.551"
          y2="75.8726"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FBD3E9" />
          <stop offset="1" stop-color="#BB377D" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_7551_10427"
          x1="36.457"
          y1="77.501"
          x2="66.5436"
          y2="77.501"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FBD3E9" />
          <stop offset="1" stop-color="#BB377D" />
        </linearGradient>
      </defs>
    </svg>
  );
};
