import { Form, Input } from 'antd';
import { MdGlobalButton } from 'components/global';
import EmailDisplayPrototype from '../EmailDisplayPrototype';
import { editActions } from '../../../../utils/constants';
import { min3char } from '../../../../utils/formRules';

const Step2FromandReplyTo = ({
  fromAndReplyToForm,
  fromName,
  setFromName,
  emailSubject,
  handleSaveFromAndReplyTo,
  isEditingMail,
  editAction,
  isFromAndReplyToValidated,
  setIsFromAndReplyToValidated,
}) => {
  return (
    <Form
      form={fromAndReplyToForm}
      name="basic"
      onFinish={(data) => {
        handleSaveFromAndReplyTo(data);
      }}
      onFinishFailed={() => null}
      autoComplete="off"
      size="large"
      className="instant-email-form-container"
      layout="vertical"
      onFieldsChange={(fields) => {
        if (fields[0].errors.length > 0) {
          setIsFromAndReplyToValidated(false);
        } else {
          setIsFromAndReplyToValidated(true);
        }
      }}
    >
      <div className="instant-email-form-wrapper">
        <Form.Item
          name="from"
          className="setting-mobile"
          label="From Name"
          rules={[
            {
              required: true,
              message: 'Please enter your name',
            },
            ...min3char,
            // ...containAzDchar,
          ]}
        >
          <Input
            placeholder="Default name"
            type="text"
            data-test="fromName-test"
            onChange={(e) => {
              if (e.target.value.length < 1) {
                setFromName('Default Name');
                return;
              }
              setFromName(e.target.value);
            }}
          />
        </Form.Item>
        <Form.Item
          name="to"
          className="setting-mobile "
          label="Reply-to"
          rules={[
            {
              required: true,
              message: 'Please enter a valid email',
              type: 'email',
            },
          ]}
        >
          <Input placeholder="Default Email" type="email" data-test="toEmail-test" />
        </Form.Item>
        <div className={`instant-email-form-button ${isFromAndReplyToValidated ? '' : 'button-opacity'}`}>
          <MdGlobalButton
            size={'large'}
            data-test="fromandreply-testbtn"
            htmlType="submit"
            loading={isEditingMail && editAction === editActions.editFromAndTo}
            disabled={!isFromAndReplyToValidated}
          >
            Save
          </MdGlobalButton>
        </div>
      </div>
      <div className="prototype-container">
        <EmailDisplayPrototype name={fromName} subject={emailSubject} />
      </div>
    </Form>
  );
};

export default Step2FromandReplyTo;
