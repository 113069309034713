import React, { useState, useRef, useEffect } from 'react';
import styles from '../../styles/selectcomponent.module.scss';
import arrow from 'asset/Icons/selectarrowdown.png';
import close from 'asset/Icons/closeIcon.png';

const SelectComponent = ({ options, handleSelectChange, loading, text, isRow, setSelectedValues, selectedValues }) => {
  const [show, setShow] = useState(false);

  const handleClick = () => {
    setShow(!show);
  };

  const removeItem = (id) => {
    // Create a new array with the item removed
    const updatedItems = selectedValues.filter((item) => item?.id !== id);
    // Update the state with the new array
    setSelectedValues(updatedItems);
  };

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShow(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  return (
    <div>
      <div ref={dropdownRef} onClick={handleClick} className={styles.SelectContainer} data-test="select-contact-group">
        <p>Select contact group(s)</p>
        <img src={arrow} alt="" />
      </div>
      <div style={{ display: show ? 'block' : 'none' }} className={styles.DropdownContainer}>
        {loading ||
          options?.map((option, index) => (
            <p
              data-test={`select-contact-group-${index}`}
              key={option?.id}
              onClick={() => handleSelectChange(option)}
              className={styles.DropdownValue}
              value={option?._id}
            >
              {option?.title}
            </p>
          ))}
      </div>
      <p className={styles.font}>{text}:</p>

      {selectedValues?.length === 0 ? (
        <p className={styles.font}>No contact group added</p>
      ) : (
        <div className={`flex ${isRow ? 'flex-row' : 'flex-wrap'} ${styles.DropdownValueContainer}`}>
          {selectedValues?.map((values) => (
            <div key={values.id} className={styles.value}>
              <div>{values.title} </div>
              <img src={close} onClick={() => removeItem(values.id)} style={{ marginLeft: '1rem' }} alt="" />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SelectComponent;
