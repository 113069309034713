import {
  SET_JOIN_CAMPAIGN_FIELDS,
  SET_ALT_TEXT,
  SET_BTN_COLOR,
  SET_BTN_TEXT,
  SET_CATCHPHRASE,
  SET_EDITING_MODE,
  SET_LOGO_RESULT,
  SET_PUBLISHING,
  SET_SECTION_BACKGROUND,
  SET_TAGLINE,
  SHOW_PHONE_NUMBER,
  SHOW_COMPANY_NAME,
  DISPLAY_NAME,
  DISPLAY_LAST_NAME,
  DISPLAY_EMAIL,
  DISPLAY_PHONE_NUMBER,
  DISPLAY_COMPANY_NAME,
  SET_LOGO_FILES,
  SET_LINK,
  FORM_FIELDS,
} from './types';

export const reducer = (state, { type, payload }) => {
  switch (type) {
    case SET_JOIN_CAMPAIGN_FIELDS:
      return { ...state, ...payload };
    case SET_EDITING_MODE:
      return (state = {
        ...state,
        editMode: payload,
      });
    case SET_PUBLISHING:
      return (state = {
        ...state,
        publishing: payload,
      });
    case SET_TAGLINE:
      return (state = {
        ...state,
        tagline: payload,
      });

    case SET_BTN_TEXT:
      return (state = {
        ...state,
        btnText: payload,
      });

    case SET_CATCHPHRASE:
      return (state = {
        ...state,
        catchphrase: payload,
      });

    case SET_ALT_TEXT:
      return (state = {
        ...state,
        alttext: payload,
      });

    case SET_SECTION_BACKGROUND:
      return (state = {
        ...state,
        sectionbackground: payload,
      });

    case SET_BTN_COLOR:
      return (state = {
        ...state,
        btnColor: payload,
      });

    case SET_LOGO_RESULT:
      return (state = {
        ...state,
        logo: payload,
      });

    case SHOW_COMPANY_NAME:
      return (state = {
        ...state,
        showCompanyName: payload,
      });

    case SHOW_PHONE_NUMBER:
      return (state = {
        ...state,
        showPhoneNumber: payload,
      });

    case DISPLAY_NAME:
      return (state = {
        ...state,
        displayName: payload,
      });
    case DISPLAY_LAST_NAME:
      return (state = {
        ...state,
        displayLastName: payload,
      });

    case DISPLAY_EMAIL:
      return (state = {
        ...state,
        displayEmail: payload,
      });
    case DISPLAY_PHONE_NUMBER:
      return (state = {
        ...state,
        displayPhoneNumber: payload,
      });

    case DISPLAY_COMPANY_NAME:
      return (state = {
        ...state,
        displayCompanyName: payload,
      });

    case SET_LOGO_FILES:
      return (state = {
        ...state,
        setLogoFiles: payload,
      });
    case SET_LINK:
      return (state = {
        ...state,
        setLink: payload,
      });
    case FORM_FIELDS:
      return (state = {
        ...state,
        formFields: payload,
      });

    default:
      return state;
  }
};
