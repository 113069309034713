import Plugin from '@ckeditor/ckeditor5-core/src/plugin';

export default class AllowSpanElement extends Plugin {
  init() {
    this.editor.model.schema.extend('$text', { allowAttributes: ['placeholder'] });

    // Add an upacast (view-to-model) converter for class attribute of a span.
    this.editor.conversion.for('upcast').elementToAttribute({
      view: {
        name: 'span',
        attributes: { class: true },
      },
      model: {
        key: 'placeholder',
        value: (viewElement) => viewElement.getAttribute('class'),
      },
    });

    // Add an downcast (model-to-view) converter for class attribute of a span.
    this.editor.conversion.for('downcast').attributeToElement({
      model: 'placeholder',
      view: (modelAttributeValue, viewWriter) => {
        return viewWriter.createAttributeElement('span', {
          class: modelAttributeValue,
        });
      },
    });
  }
}
