/* eslint-disable no-unused-vars */
import { BreadCrumb } from 'components/emmaccen/sharedComponents/BreadCrumb';
import { InstantEmailEditorActionsTab } from 'components/emmaccen/sharedComponents/InstantEmailEditorActionsTab';
import { useCallback, useEffect, useState } from 'react';
import { useEditInstantEmailMutation, useGetSingleInstantEmailQuery } from 'redux/featureApi/instantEmailApiSlice';
import { uploadFile } from '../../utils/service';
import { instantEmailsActionTypes } from '../../utils/constants';
import { Link, useLocation } from 'react-router-dom';
import { showToastMessage } from '../../utils/Toast';
import findTextComponent from 'utils/miniFunctions/findTextComponentGjs';
const InstantEmailEditorActionsContainer = ({
  mailId,
  mail,
  updateMailDetails,
  juice,
  juiceInit,
  editorState,
  setIsEditingEmail,
  updateEditStatus,
  setEmailEditReqFailed,
}) => {
  const [files, setFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [imLoading, setImLoading] = useState({ id: '' });
  const location = useLocation();
  const mailTitle = location?.state?.mailTitle;


  const [breadCrumbs] = useState([
    {
      name: `< Back`,
      link: `/instant-emails/${mailId}/mail-subject`,
      index: 0,
      state: {mailTitle},
      search: `?action=${instantEmailsActionTypes.create}`,
    },
  ]);

  const {
    data: emailRes,
    isLoading: loadingEmailFetchReq,
    isSuccess: emailFetched,
    isError: emailFetchReqFailed,
    error: emailFetchReqErr,
  } = useGetSingleInstantEmailQuery({ mailId });

  const [
    editMail,
    { isLoading: isEditingEmail, isSuccess: mailEdited, isError: emailEditReqFailed, data: emailEditRes },
  ] = useEditInstantEmailMutation();

  const updateFileState = (id, status, location, filename) => {
    setFiles((previousFiles) => {
      return previousFiles.map((file) => (file.id === id ? { ...file, status, src: location, filename } : file));
    });
  };

  useEffect(() => {
    if (editorState !== null) {
      if (emailFetched) {
        const { email } = emailRes?.data;
        setFiles(
          email?.attachments?.map(({ src, filename }, index) => {
            return { src, filename, status: 'UPLOADED', id: index };
          })
        );

        if (editorState !== null && email?.body === '') {
          editorState?.on('load', function () {
            const defaultTextComponent = findTextComponent(editorState.DomComponents.getWrapper().components().at(0));
            if (defaultTextComponent) {
              const newContent = `Hello {{FIRST_NAME}} {{LAST_NAME}},`;
              const newTextComponent = editorState.DomComponents.addComponent({
                type: 'text',
                content: newContent,
              });
              defaultTextComponent.replaceWith(newTextComponent);
              editorState.trigger('update', { target: 'canvas' });
            }
          });
        }
        if (email?.content) {
          const inlineCode = juiceInit(email?.content['gjs-html'], email?.content['gjs-css']);
          updateMailDetails((prevData) => {
            return { ...prevData, ...email, id: email?._id, body: inlineCode };
          });
        } else {
          updateMailDetails((prevData) => {
            return { ...prevData, ...email, id: email?._id };
          });
        }
      } else if (emailFetchReqFailed) {
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingEmailFetchReq, editorState]);

  useEffect(() => {
    updateMailDetails((prev) => ({
      ...prev,
      attachments: files?.filter((file) => file.status !== 'ERROR').map((file) => file),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  useEffect(() => {
    isUploading &&
      files.forEach((file) => {
        file.status !== 'UPLOADED' &&
          uploadFile(file.file, file.id)
            .then(
              ({
                data: {
                  data: { id, location, filename, status },
                },
              }) => {
                updateFileState(id, status, location, filename);
              }
            )
            .catch((err) => {
              updateFileState(file.id, 'ERROR', file.src, file.filename);
            })
            .finally(() => {
              setIsUploading(false);
            });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUploading]);

  useEffect(() => {
    if (isEditingEmail) setIsEditingEmail((prev) => isEditingEmail);

    if (mailEdited) updateEditStatus(emailEditRes, mailEdited);

    if (emailEditReqFailed) setEmailEditReqFailed((prev) => emailEditReqFailed);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditingEmail, mailEdited, emailEditReqFailed]);

  const convertStringToHTML = (htmlString) => {
    const parser = new DOMParser();
    const html = parser.parseFromString(htmlString, 'text/html');
    return html.body;
  };
  const updateEmail = useCallback(() => {
    // setWantSave(true);
    // setIsLoading(true);
    setImLoading({ id: 'emailAction' });
    editorState?.store((res) => {});
    editorState?.on('storage:store', async function (e) {
      try {
        const inlineCode = juice.inlineContent(e.html, e.css);
        const attachments = files?.filter((file) => file.status !== 'ERROR').map((file) => file);
        const body = convertStringToHTML(e.html);
        const textContent = body.textContent;
        const imgElements = body.querySelectorAll('img');

        if (textContent.trim() === '' && imgElements.length <= 0) {
          showToastMessage({
            type: 'warning',
            title: 'Empty Newsletter',
            description: 'Email body cannot be empty',
          });
          setImLoading({ id: '' });
          return;
        }

        updateMailDetails((prevData) => {
          return {
            ...prevData,
            body: inlineCode,
          };
        });
        await editMail({
          mailId: mailId,
          body: {
            body: inlineCode,
            attachments,
          },
        });
        setImLoading({ id: '' });
      } catch (error) {
        console.log(error);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorState, mail, files]);

  function containsText(element) {
    if (element.textContent.trim().length > 0) {
      return true;
    }
    if (element.children.length > 0) {
      for (let i = 0; i < element.children.length; i++) {
        const childElement = element.children[i];
        if (containsText(childElement)) {
          return true;
        }
      }
    }
    return false;
  }

  return (
    <div className="flex flex-space-btw align-items-center mb-5 editorHeaderTab">
      <BreadCrumb crumbs={breadCrumbs} />
      <InstantEmailEditorActionsTab
        imLoading={imLoading}
        setImLoading={setImLoading}
        updateMailDetails={updateMailDetails}
        mail={mail}
        handleEmailCreation={updateEmail}
        filesProps={[files, setFiles, setIsUploading]}
      />
    </div>
  );
};

export default InstantEmailEditorActionsContainer;
