import React from 'react'

const SuccessIcon = () => {
  return (
    <svg width="77" height="77" viewBox="0 0 77 77" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="38.5" cy="38.5" r="38.5" fill="#EBF7ED" />
      <path
        d="M38.9999 55.6668C48.1666 55.6668 55.6666 48.1668 55.6666 39.0002C55.6666 29.8335 48.1666 22.3335 38.9999 22.3335C29.8333 22.3335 22.3333 29.8335 22.3333 39.0002C22.3333 48.1668 29.8333 55.6668 38.9999 55.6668Z"
        stroke="#37B34A"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M31.9167 38.9999L36.6334 43.7165L46.0834 34.2832"
        stroke="#37B34A"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default SuccessIcon