import { useFeatureValue } from '@growthbook/growthbook-react';
import { useEffect, useState } from 'react';
import { growthBookId, returnPromoDates } from 'utils/constants';

const usePaygPromoDates = () => {
  const getStartDate = useFeatureValue(growthBookId.paygPromoStartDate);
  const getEndDate = useFeatureValue(growthBookId.paygPromoEndDate);

  const { paygPromoStartDate, paygPromoEndDate } = returnPromoDates(getStartDate, getEndDate);
  const now = new Date();

  const [withinPromoPeriod, setWithinPromoPeriod] = useState(now >= paygPromoStartDate && now <= paygPromoEndDate);

  useEffect(() => {
    const { paygPromoStartDate, paygPromoEndDate } = returnPromoDates(getStartDate, getEndDate);
    const now = new Date();
    setWithinPromoPeriod(now >= paygPromoStartDate && now <= paygPromoEndDate);
  }, [getStartDate, getEndDate]);

  return { paygPromoStartDate, paygPromoEndDate, withinPromoPeriod };
};

export default usePaygPromoDates;
