import { apiAuthSlice } from './apiAuthSlice';

export const contactApiSlice = apiAuthSlice.enhanceEndpoints({ addTagTypes: ['ContactGroups'] }).injectEndpoints({
  endpoints: (builder) => ({
    getAllContactGroups: builder.query({
      query: (params) => ({
        url: `/contacts/groups`,
        method: 'GET',
      }),
      transformResponse: (response) => response?.data,
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result?.groups.map(({ title }) => ({ type: 'ContactGroups', id: title })),
              { type: 'ContactGroups', id: 'LIST' },
            ]
          : [{ type: 'ContactGroups', id: 'LIST' }],
    }),
    postNewContactToGroup: builder.mutation({
      query: ({ groupId, payload }) => ({
        url: groupId ? `/contacts?groupId=${groupId}` : '/contacts',
        method: 'POST',
        body: { ...payload },
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'ContactGroups', id: 'LIST' }],
      // invalidatesTags: (result, error, arg) => [{ type: 'ContactGroups', id: arg.title }],
    }),
    getGroupContacts: builder.query({
      query: ({ groupId = '', page = 1, limit, search = '' }) => ({
        url: `/contacts/group?groupId=${groupId}&page=${page}&search=${search}&limit=${limit}`,
        method: 'GET',
      }),
      providesTags: ['ContactGroups'],
    }),
    editContact: builder.mutation({
      query: ({ contactId, contactData }) => ({
        url: `/contacts/${contactId}`,
        method: 'PATCH',
        body: contactData,
      }),
      invalidatesTags: ['ContactGroups'],
    }),
    modifyContactGroup: builder.mutation({
      query: ({ contactData, groupId }) => ({
        url: `contacts/modify/${groupId}`,
        method: 'POST',
        body: contactData,
      }),
      invalidatesTags: ['ContactGroups'],
    }),
    deleteContactFromGroup: builder.mutation({
      query: ({ contactData }) => ({
        url: `contacts/delete`,
        method: 'PUT',
        body: contactData,
      }),
      invalidatesTags: ['ContactGroups'],
    }),
    createContactGroup: builder.mutation({
      query: ({ payload }) => ({
        url: '/contacts/groups',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['ContactGroups'],
    }),
    addNewBulkContactToGroup: builder.mutation({
      query: ({ payload }) => ({
        url: '/contacts/bulk',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['ContactGroups'],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetAllContactGroupsQuery,
  usePostNewContactToGroupMutation,
  useGetGroupContactsQuery,
  useEditContactMutation,
  useModifyContactGroupMutation,
  useCreateContactGroupMutation,
  useAddNewBulkContactToGroupMutation,
  useDeleteContactFromGroupMutation,
} = contactApiSlice;
