import React from 'react';

export default function Search() {
  return (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.95833 10.2917C8.35157 10.2917 10.2917 8.35157 10.2917 5.95833C10.2917 3.5651 8.35157 1.625 5.95833 1.625C3.5651 1.625 1.625 3.5651 1.625 5.95833C1.625 8.35157 3.5651 10.2917 5.95833 10.2917Z"
        stroke="#8E8E8E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M11.3758 11.375L9.01953 9.01874" stroke="#8E8E8E" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
