import { Form } from 'antd';
import { MdGlobalButton, MdTypography } from 'components/global';
import EmailDisplayPrototype from '../EmailDisplayPrototype';
import { editActions } from '../../../../utils/constants';
import { minDynamicChar } from '../../../../utils/formRules';
import { MentionsInput, Mention } from 'react-mentions';

const Step3Subject = ({
  subjectForm,
  fromName,
  emailSubject,
  setEmailSubject,
  isEditingMail,
  editAction,
  isValidSubject,
  setIsValidSubject,
  handleUpdateEmailSubject,
}) => {
  const mergeTags = [
    { id: 1, display: 'FIRST_NAME' },
    { id: 2, display: 'LAST_NAME' },
    { id: 3, display: 'FULL_NAME' },
    { id: 4, display: 'EMAIL_ADDRESS' },
  ];

  const displayStyle = {
    suggestions: {
      list: {
        backgroundColor: 'white',
        border: '1px solid rgba(0,0,0,0.15)',
        fontSize: 14,
      },
      item: {
        padding: '5px 15px',
        borderBottom: '1px solid rgba(0,0,0,0.15)',
        '&focused': {
          backgroundColor: '#cee4e5',
        },
      },
    },
  };

  const replaceVariables = (text, variables) => {
    const pattern = new RegExp(`{{(${variables.map((item) => item.display).join('|')})}}`, 'g');
    const replacedText = text?.replace(pattern, (match, variable) => {
      const foundVariable = variables.find((item) => item.display === variable);
      return foundVariable ? `@${foundVariable.display}` : match;
    });
    return replacedText;
  };

  const redefinedSubject = replaceVariables(emailSubject, mergeTags);
  return (
    <Form
      form={subjectForm}
      name="basic"
      autoComplete="off"
      size="large"
      layout="vertical"
      className="email-subject-panel-content"
      onFieldsChange={(fields) => {
        if (fields[0].errors.length > 0) {
          setIsValidSubject(false);
        } else {
          setIsValidSubject(true);
        }
      }}
      onFinish={(data) => {
        handleUpdateEmailSubject(data);
      }}
    >
      <div className="subject-container">
        <Form.Item
          name="subject"
          label="Subject Line"
          rules={[
            {
              required: true,
              message: 'Please enter email subject',
            },
            ...minDynamicChar(1),
          ]}
        >
          <MentionsInput
            value={emailSubject}
            singleLine
            placeholder="Enter Email Subject"
            data-test="email-subject-input"
            className="email-subject-mention"
            style={displayStyle}
            onChange={(e) => {
              if (e.target.value.length < 1) {
                setEmailSubject('Email Subject Here');
                return;
              }
              setEmailSubject(e.target.value);
            }}
          >
            <Mention
              trigger="@"
              data={mergeTags}
              markup="{{__display__}}"
              displayTransform={(display) => `@${display}`}
            />
          </MentionsInput>
          {/* <Input
            placeholder="Enter Email Subject"
            type="text"
            data-test="email-subject-input"
            className="email-subject"
            onChange={(e) => {
              if (e.target.value.length < 1) {
                setEmailSubject('Email Subject Here');
                return;
              }
              setEmailSubject(e.target.value);
            }}
          /> */}
        </Form.Item>
        <MdTypography className="input-info">
          Use ‘@‘ to personalize your title with attributes specific to the recipient
        </MdTypography>
        <MdGlobalButton
          data-test="email-subject-test-btn"
          className={`instant-email-button ${isValidSubject ? '' : 'button-opacity'}`}
          loading={isEditingMail && editAction === editActions.editSubject}
          disabled={!isValidSubject}
        >
          Save subject line 
        </MdGlobalButton>
      </div>
      <div className="prototype-container">
        <EmailDisplayPrototype name={fromName} subject={redefinedSubject} />
      </div>
    </Form>
  );
};

export default Step3Subject;
