import React, { useContext } from 'react';
import { ReactComponent as WarningIcon } from '../asset/Icons/warningIconM.svg';
import { LoadingOutlined } from '@ant-design/icons';
import { ReactComponent as SuccessIcon } from '../asset/Icons/successIconM.svg';
import Draggable from 'react-draggable';
import { getJobDetails } from '../utils/service';
import { jobContext } from '../contexts/job';
import { useQueries } from 'react-query';
import { SET_JOBS } from '../contexts/job/reducer/types';
import { jobStatus } from '../utils/constants';
import { Progress } from 'antd';

const DraggableUploadStatus = () => {
  const { state, dispatch } = useContext(jobContext);

  // Poll the job status endpoint for updates on job
  useQueries(
    state.jobs.map((job) => ({
      queryKey: ['job', job.ID],
      queryFn: async () => {
        if (job?.processed !== job?.total) {
          const data = await getJobDetails(job.ID);
          return data;
        }
        return { data: { data: { job: state.jobs.find((j) => j.ID === job.ID) } } };
      },
      refetchInterval: 15000,
      refetchIntervalInBackground: true,
      onSuccess: async ({ data }) => {
        const job_res = data.data.job;
        const jobIndex = state.jobs.findIndex((job) => job.ID === job_res._id);
        state.jobs[jobIndex] = {
          ...state.jobs[jobIndex], // Might contain campaign modal objecct, Might not hence the spread operator
          ...job_res,
          ID: job_res._id,
        };

        dispatch({
          type: SET_JOBS,
          payload: state.jobs,
        });
      },
      onError: (error) => {
        console.log({ error: error.response.data });
      },
    }))
  );

  return (
    <div style={{ zIndex: '99999', position: 'absolute' }}>
      {state.jobs
        // .filter((x) => x.processed === jobStatus.uploading)
        .map((job) => {
          const getJobPercent = Number((job.processed / job.total) * 100).toFixed(1);
          return (
            <React.Fragment key={job.ID}>
              <Draggable allowAnyClick={true} defaultPosition={{ x: 0, y: 0 }} handle=".handle" key={job.ID}>
                <div className="handle">
                  <div className="upload-notf_contain">
                    <div className="upload-notf_update">
                      {job.status === jobStatus.done ? <SuccessIcon /> : <WarningIcon />}

                      {job.status === jobStatus.done ? (
                        <span>Contacts uploaded successfully </span>
                      ) : job.status === jobStatus.uploading ? (
                        <span>Uploading contacts...</span>
                      ) : (
                        <span>Preparing your contacts for upload...</span>
                      )}
                      {job.status !== jobStatus.done && <LoadingOutlined />}
                    </div>
                    <Progress
                      strokeColor={{
                        '0%': '#a1d4f8',
                        '100%': '#273df2',
                      }}
                      percent={getJobPercent}
                      status="active"
                    />
                  </div>
                </div>
              </Draggable>
            </React.Fragment>
          );
        })}
    </div>
  );
};

export default DraggableUploadStatus;
