import { Divider, Form } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import Cookie from 'js-cookie';

import '../../styles/loginPage.scss';
import { signUp } from '../../utils/services/authService';
import { showToastMessage } from '../../utils/Toast';
import GoogleAuthButton from './GoogleAuthButton';
import { me, setAuthToken } from '../../utils/service';
import MdGlobalCTA from '../../components/global/MdGlobalCTA/MdGlobalCTA';
import { MdGlobalButton, MdTypography } from '../../components/global';
import { MdSignUpForm1 } from './MdSignUpForm1';
import { MdSignUpForm2 } from './MdSignUpForm2';
import { AppConstants } from '../../utils/appConstants';
import { pixelCompleteRegistration } from '../../utils/metaPixels/pageEvent';

export const MdSignUp = () => {
  const [signupForm] = Form.useForm();

  const location = useLocation();
  const query = new URLSearchParams(location?.search);
  const affilateCode = query.get('lmref');

  const [imLoading, setImLoading] = useState({ formValid: false });
  const [loading, setLoading] = useState(false);

  const [disableNextBtn, setDisableNextBtn] = useState(true);

  const [formFields, setFormFields] = useState([]);
  const navigate = useNavigate();

  const [page, setPage] = useState(1);

  const [passwordErrors, setPasswordErrors] = useState([]);
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [passwordValidator, setPasswordValidator] = useState({});
  /* eslint-disable */
  useEffect(() => {
    const checkField = formFields.filter((field) => field.value !== undefined && field.errors.length === 0);
    if (checkField.length === 7) {
      setImLoading({ ...imLoading, formValid: true });
    } else {
      setImLoading({ ...imLoading, formValid: false });
    }
    setPasswordErrors(passwordValidator?.errors);
  }, [formFields]);

  useEffect(() => {
    if (!!passwordValidator?.value?.length && passwordValidator?.touched) {
      setPasswordStrength(5 - passwordErrors.length);
    }
  }, [passwordErrors]);

  useEffect(() => {
    const checkFirstForm = formFields.splice(0, 4);
    const check = checkFirstForm.filter((field) => field.value !== undefined && field.errors.length === 0);
    if (check.length === 4) {
      setDisableNextBtn(false);
    } else {
      setDisableNextBtn(true);
    }
  }, [formFields]);

  const createAccount = (values) => {
    values.referralId = affilateCode;
    setLoading(true);
    signUp(values)
      .then((res) => {
        setLoading(false);
        pixelCompleteRegistration();
        const { verified, token } = res.data.data;
        if (!verified) {
          navigate(`/verify?lmref=${affilateCode}`, {
            state: {
              email: values.email,
              resendVerificationMail: false,
            },
          });
        } else {
          //  Log user in successfully
          Cookie.set('token', token, { expires: 1 });
          setAuthToken(token);
          me()
            .then((result) => {
              navigate('/dashboard');
            })
            .catch((err) => {
              showToastMessage({
                type: 'error',
                title: 'Error',
                description: 'Unable to get user details',
              });
            });
        }
      })
      .catch((err) => {
        setLoading(false);
        if (err.response) {
          const errors = err.response.data.fieldsError;
          console.error(errors);
          Object.entries(errors).forEach((field) =>
            showToastMessage({
              type: 'error',
              title: 'Error',
              description: field[1].message,
            })
          );
        } else
          showToastMessage({
            type: 'error',
            title: 'Error',
            description: 'Unable to process request',
          });
      });
  };

  const onFieldsChange = (changedFields, allField) => {
    setPasswordValidator(allField[5]);
    setFormFields(allField);
  };

  return (
    <div className="emmaccen">
      <div className="loginPageContainer signupContainer">
        <div className="loginSections flex">
          <MdGlobalCTA />
          <div className="w-50 formArea">
            <div className="formContent">
              <div className="container2">
                <a href={AppConstants.MAILDRIP_BLOG_URL} target="_blank" className="flex-jst-right pt-2 md-logo">
                  <img src="/assets/smallLogo.svg" alt="company logo" className="mb-5" />
                </a>
                <div className="flex centered ">
                  <div className="signUpTitleCont">
                    <MdTypography variant={'h1'} className="signUpTitle">
                      Create an account
                    </MdTypography>
                  </div>
                  <div className="flex-jst-right signup-progress-step-lg">
                    <MdTypography variant={'h6Medium'}>Step {page}/2</MdTypography>
                  </div>
                </div>
                <div className="margin-t-b s-up-wg-lg">
                  <GoogleAuthButton>
                    <MdTypography variant={'h6Medium'}>Sign up with Google</MdTypography>
                  </GoogleAuthButton>
                </div>
                <div className="orDivider">
                  <Divider plain>
                    <MdTypography variant={'smallTextMedium'}>Or</MdTypography>
                  </Divider>
                </div>

                {/* form */}
                <Form
                  name="basic"
                  form={signupForm}
                  onFinish={createAccount}
                  initialValues={{
                    remember: true,
                  }}
                  onFieldsChange={onFieldsChange}
                  autoComplete="off"
                  size="large"
                  layout="vertical"
                  requiredMark={false}
                >
                  <div className={`${page === 1 ? 'showForm' : page === 2 ? 'hideForm' : ''}`}>
                    <MdSignUpForm1 loading={loading} imLoading={imLoading} setImLoading={setImLoading} />
                  </div>

                  <div className={`${page === 2 ? 'showForm' : page === 1 ? 'hideForm' : ''}`}>
                    <MdSignUpForm2
                      loading={loading}
                      imLoading={imLoading}
                      setImLoading={setImLoading}
                      passwordValidator={passwordValidator}
                      passwordStrength={passwordStrength}
                      setPage={setPage}
                      page={page}
                    />
                  </div>
                  {page === 2 && (
                    <Form.Item>
                      <div className="centered">
                        <MdGlobalButton
                          id={'primary_xl'}
                          style={{ width: '100%' }}
                          htmltype="submit"
                          // loading={imLoading.id === 'creatingAccount'}
                          loading={loading}
                          disabled={!imLoading?.formValid}
                        >
                          <MdTypography variant={'h6Medium'} style={{ color: '#ffffff' }}>
                            Create Account
                          </MdTypography>
                        </MdGlobalButton>
                      </div>
                    </Form.Item>
                  )}
                </Form>

                {page > 1 && (
                  <div className="centered pointer go-back-lg">
                    <MdTypography variant={'btnRegular'} style={{ color: '#273DF2' }} onClick={() => setPage(page - 1)}>
                      Go Back
                    </MdTypography>
                  </div>
                )}

                {page < 2 && (
                  <>
                    <div className="centered nextBtn">
                      <MdGlobalButton
                        id={'primary_xl'}
                        style={{ width: '100%' }}
                        disabled={disableNextBtn}
                        onClick={() => setPage(2)}
                      >
                        <MdTypography variant={'h6Medium'} style={{ color: '#ffffff' }}>
                          Next
                        </MdTypography>
                      </MdGlobalButton>
                    </div>
                  </>
                )}
                <div className="margin-t-b s-up-wg-mobile">
                  <GoogleAuthButton>
                    <MdTypography variant={'h6Medium'}>Sign up with Google</MdTypography>
                  </GoogleAuthButton>
                </div>

                <MdTypography
                  variant={'h6Medium'}
                  className="text-center already-have-an-account-mobile"
                  style={{ color: '#858A8F' }}
                >
                  Already have an account?
                  <Link to="/login" className="primaryColorLight ml-2">
                    Login
                  </Link>
                </MdTypography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
