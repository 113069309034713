import React from 'react';

function CircularSpinner({ className, style }) {
  return (
    <svg
      style={{ height: '40px', ...style }}
      className={`spinner fill-current stroke-current ${className}`}
      viewBox="0 0 50 50"
    >
      <circle className="path" cx="25" cy="25" r="15" fill="none" strokeWidth="4"></circle>
    </svg>
  );
}

export default CircularSpinner;
