import React from 'react';

export default function NoFolder() {
  return (
    <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_207_4674)">
        <path
          d="M4.5 0.5C3.70888 0.5 2.93552 0.734596 2.27772 1.17412C1.61992 1.61365 1.10723 2.23836 0.804484 2.96927C0.501733 3.70017 0.42252 4.50444 0.576861 5.28036C0.731202 6.05629 1.11216 6.76902 1.67157 7.32843C2.23098 7.88784 2.94372 8.2688 3.71964 8.42314C4.49556 8.57748 5.29983 8.49827 6.03074 8.19552C6.76164 7.89277 7.38635 7.38008 7.82588 6.72228C8.26541 6.06448 8.5 5.29113 8.5 4.5C8.5 3.43913 8.07857 2.42172 7.32843 1.67157C6.57828 0.921427 5.56087 0.5 4.5 0.5ZM1 4.5C1.00024 3.65897 1.30483 2.84644 1.8575 2.2125L6.7875 7.1425C6.28048 7.58033 5.65845 7.86346 4.99529 7.95826C4.33213 8.05306 3.65569 7.95555 3.0463 7.67731C2.43691 7.39907 1.92017 6.95179 1.55745 6.38858C1.19472 5.82538 1.00125 5.1699 1 4.5ZM7.1425 6.7875L2.2125 1.8575C2.88334 1.29698 3.73966 1.0078 4.61297 1.04684C5.48629 1.08589 6.31338 1.45033 6.93153 2.06848C7.54967 2.68662 7.91411 3.51371 7.95316 4.38703C7.9922 5.26035 7.70302 6.11666 7.1425 6.7875Z"
          fill="#8E8E8E"
        />
      </g>
      <defs>
        <clipPath id="clip0_207_4674">
          <rect width="9" height="9" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
