import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Bar } from 'react-chartjs-2';
import { Radio } from 'antd';
import { Chart, registerables } from 'chart.js';
import '../../../styles/dashboard.scss';
import { MdTypography } from '../../../components/global';
Chart.register(...registerables);
/* eslint-disable */
const ClickRate = () => {
  const [dataSetDataWeekly, setDataSetDataWeekly] = useState([]);
  const [dataSetDataMonthly, setDataSetDataMonthly] = useState([]);
  const [dataSetPercentage, setDataSetPercentage] = useState({});
  const api__url = process.env.REACT_APP_API_URL;

  const [toggleSwitch, setToggleSwitch] = useState('Week');

  const toggleSwitchBtn = ({ target: { value } }) => setToggleSwitch(value);

  const FetchClickThroughStatWeekly = async () => {
    const { data } = await axios.get(`${api__url}/dashboard/performance-chart/click-through-stats/weekly`);
    setDataSetDataWeekly(data.data);
  };

  const FetchClickThroughStatMonthly = async () => {
    const { data } = await axios.get(`${api__url}/dashboard/performance-chart/click-through-stats/monthly`);
    setDataSetDataMonthly(data.data);
  };

  useEffect(() => {
    FetchClickThroughStatWeekly();
    FetchClickThroughStatMonthly();
  }, []);

  useEffect(() => {
    (async () => {
      const { data } = await axios.get(
        `${api__url}/dashboard/performance-chart/stats/percentage?duration=${toggleSwitch.toLowerCase()}&clickrate=true&clickthroughrate=true`
      );
      setDataSetPercentage(data.data);
    })();
  }, [toggleSwitch]);

  const switchOptions = [
    {
      label: <span className="switchText">Week</span>,
      value: 'Week',
    },
    {
      label: <span className="switchText">Month</span>,
      value: 'Month',
    },
  ];

  const options = {
    indexAxis: 'x',
    barPercentage: 1,
    elements: {
      bar: {
        borderWidth: 0,
      },
    },
    responsive: true,
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label + ':' || '';
            if (context.parsed.y !== null) {
              label += ' ' + context.parsed.y + '%';
            }
            return label;
          },
        },
      },
      legend: {
        labels: {
          boxWidth: 10,
          boxHeight: 8,
          font: {
            size: 12,
            family: 'Inter',
            weight: 500,
          },
        },
        position: 'bottom',
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
      },
    },
  };

  const options2 = {
    indexAxis: 'x',
    barPercentage: 1,
    elements: {
      bar: {
        borderWidth: 0,
      },
    },
    responsive: true,
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label + ':' || '';
            if (context.parsed.y !== null) {
              label += ' ' + context.parsed.y + '%';
            }
            return label;
          },
        },
      },
      legend: {
        labels: {
          boxWidth: 10,
          boxHeight: 8,
          font: {
            size: 12,
            family: 'Inter',
            weight: 500,
          },
        },
        position: 'bottom',
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
      },
    },
  };

  const labelsWeek = ['Mon', 'Tue', 'Wed', 'Thurs', 'Fri', 'Sat', 'Sun'];
  const dataWeek = {
    labels: labelsWeek,
    datasets: [
      {
        label: 'Click Rate',
        data: dataSetDataWeekly?.clickRateDaily?.map((click) => click.total),
        backgroundColor: '#F79E1B',
      },
      {
        label: 'Click-through Rate',
        data: dataSetDataWeekly?.ctrRateDaily?.map((ctr) => ctr.total),
        backgroundColor: '#191058',
      },
    ],
  };

  const labelsMonth = ['Week 1', 'Week 2', 'Week 3', 'Week 4'];
  const dataMonth = {
    labels: labelsMonth,
    datasets: [
      {
        label: 'Click Rate',
        data: dataSetDataMonthly?.clickRateMonthly?.map((clik) => clik.clickRate),
        backgroundColor: '#F79E1B',
      },
      {
        label: 'Click-through Rate',
        data: dataSetDataMonthly?.ctrRateMonthly?.map((ctr) => ctr.ctrRate),
        backgroundColor: '#191058',
      },
    ],
  };

  return (
    <>
      {/* Week view */}
      <div className={`clickRateSection ${toggleSwitch === 'Week' ? 'block' : 'hidden'}`}>
        <div className="performanceRatings">
          <div className="flex performance-rates">
            <div className="mr-3">
              <MdTypography variant={'captionMedium'} style={{ color: '#aaaaaa' }}>
                Click Rate
              </MdTypography>
              <MdTypography variant={'pMedium'}>{dataSetPercentage.clicksRate}%</MdTypography>
            </div>
            <div>
              <MdTypography variant={'captionMedium'} style={{ color: '#aaaaaa' }}>
                Click-through Rate
              </MdTypography>
              <MdTypography variant={'pMedium'}>{dataSetPercentage.clickThroughRate}%</MdTypography>
            </div>
          </div>
          <div className="performanceSwitch">
            <Radio.Group options={switchOptions} onChange={toggleSwitchBtn} value={toggleSwitch} optionType="button" />
          </div>
        </div>
        <Bar data={dataWeek} options={options} />
      </div>

      {/* Month view */}
      <div className={`clickRateSection ${toggleSwitch === 'Week' ? 'hidden' : 'block'}`}>
        <div className="performanceRatings">
          <div className="flex performance-rates">
            <div className="mr-3">
              <MdTypography variant={'captionMedium'} style={{ color: '#aaaaaa' }}>
                Click Rate
              </MdTypography>
              <MdTypography variant={'pMedium'}>{dataSetPercentage.clicksRate}%</MdTypography>
            </div>
            <div>
              <MdTypography variant={'captionMedium'} style={{ color: '#aaaaaa' }}>
                Click-through Rate
              </MdTypography>
              <MdTypography variant={'pMedium'}>{dataSetPercentage.clickThroughRate}%</MdTypography>
            </div>
          </div>
          <div className="performanceSwitch">
            <Radio.Group options={switchOptions} onChange={toggleSwitchBtn} value={toggleSwitch} optionType="button" />
          </div>
        </div>
        <Bar data={dataMonth} options={options2} />
      </div>
    </>
  );
};

export default ClickRate;
