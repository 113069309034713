import { useContext, useState, useEffect } from 'react';
import { Modal } from 'antd';
import { ReactComponent as CheckCircleFilled } from '../../../asset/Icons/checked.svg';
import './style.scss';
import LoadingScreen from '../../LoadingScreen';
import { paymentContext } from '../../../contexts/payment';
import {
  SET_CURRENCY,
  SET_LOADING,
  SET_PLAN,
  SET_PLANS,
  SET_PRICE,
  SET_QUANTITY,
  SET_TOPUP_RATES,
  SET_TRX_TYPE,
} from '../../../contexts/payment/reducer/types';
import { trxType } from '../../../utils/constants';
import { thousandFormatterFloat } from '../../../utils/thousandFormatter';
import { useGetTopupRateQuery } from 'redux/featureApi/paymentApiSlice';
import { showToastMessage } from 'utils/Toast';
import { CheckoutToPaymentHandler } from '../CheckoutCard';
import { getUserLocation } from 'utils/services/authService';

export default function TopupModal({ props }) {
  const { openModal, setOpenModal } = props;
  const [isLoading, setIsLoading] = useState(true);

  const {
    data: topupPrice,
    isFetching: fetchingTopupPrice,
    isSuccess: fetchedTopupPrice,
    isError: topupPriceError,
  } = useGetTopupRateQuery({}, { refetchOnMountOrArgChange: true });

  const {
    state: { currency, plans, plan, topup_rates },
    dispatch,
  } = useContext(paymentContext);

  useEffect(() => {
    if (fetchedTopupPrice) {
      (async () => {
        const currLoc = await getUserLocation();
        if (currLoc === 'NG') {
          dispatch({ type: SET_CURRENCY, payload: 'NGN' });
        }
        else{
          dispatch({ type: SET_CURRENCY, payload: 'USD' });
        }

        dispatch({
          type: SET_TOPUP_RATES,
          payload: { NGN: topupPrice?.data?.topupInNaira, USD: topupPrice?.data?.topupInDollar },
        });

        const topupPriceInDollar = +topupPrice?.data?.topupInDollar;

        // Calculate the minimum number of units to meet the 0.5 dollars threshold
        let baseLimit = 0.5 / topupPriceInDollar;

        // Round up the base limit to ensure it never falls below 0.5 dollars
        baseLimit = Math.ceil(baseLimit / 100) * 100;

        const defaultPlans = [
          {
            _id: '1',
            limit: baseLimit,
            topup: true,
          },
          {
            _id: '2',
            limit: 2000,
            topup: true,
          },
          {
            _id: '3',
            limit: 4500,
            topup: true,
          },
        ];

        dispatch({
          type: SET_PLANS,
          payload: defaultPlans,
        });
        dispatch({ type: SET_PLAN, payload: defaultPlans[0] });
        dispatch({
          type: SET_PRICE,
          payload:
            defaultPlans[0].limit *
            (currLoc === 'NG' ? topupPrice?.data?.topupInNaira : topupPrice?.data?.topupInDollar),
        });
        dispatch({ type: SET_QUANTITY, payload: defaultPlans[0].limit });
        dispatch({ type: SET_LOADING, payload: false });
        setIsLoading(false);
      })();
    }

    if (topupPriceError) {
      showToastMessage('error', 'An error occurred while fetching topup packages and rates. Please try again');
    }
    // eslint-disable-next-line
  }, [fetchingTopupPrice]);

  return (
    <Modal
      style={{ borderRadius: '10px' }}
      width="900px"
      className="topUpWrapper"
      title={''}
      open={openModal}
      onCancel={() => setOpenModal(false)}
      footer={null}
      closable={true}
      centered={true}
    >
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <div className="body">
          <div className="packageWrapper">
            <h5>Top-up Credit</h5>

            <p className="sp">Select a package</p>

            {!plans && fetchingTopupPrice ? (
              <LoadingScreen
                loaderTitle={'Fetching topup packages. If loading takes longer than 1 minute, kindly refresh this page'}
              />
            ) : (
              plans.map((p) => {
                const isChosenPlan = plan._id === p._id;
                return (
                  <div
                    key={p._id}
                    className={`topup ${isChosenPlan && 'selected_plan__border'}`}
                    onClick={() => {
                      dispatch({ type: SET_PLAN, payload: p });
                      dispatch({ type: SET_QUANTITY, payload: p.limit });
                      dispatch({ type: SET_PRICE, payload: p.limit * topup_rates[currency] });
                      dispatch({ type: SET_TRX_TYPE, payload: trxType.topup });
                    }}
                  >
                    <div className="slotsWrapper">
                      <p className="slots">{p.limit}</p>
                      <p className="credit">email credits</p>
                    </div>
                    <div className="topupPrice">
                      <p>
                        {currency === 'NGN' ? '₦' : '$'}
                        {thousandFormatterFloat(p.limit * topup_rates[currency])}
                      </p>
                      <CheckCircleFilled />
                    </div>
                  </div>
                );
              })
            )}

            <div className="microcopy">
              <p>
                <span>*</span>All top-up packages are valid for the existing subscription period
              </p>
            </div>
          </div>

          <div className="formWrapper">
            <CheckoutToPaymentHandler />
            {/* <h2>You pay: ₦{thousandFormatterFloat(state.price)}</h2>
            <MdGlobalButton
              dataTestId="topup-button"
              id={'primary_lg'}
              type="primary"
              htmlType="submit"
              className="topup-button"
              loading={requestingPaygRedirectionLink}
              onClick={handleTopUp}
            >
              Continue
            </MdGlobalButton> */}
          </div>
        </div>
      )}
    </Modal>
  );
}
