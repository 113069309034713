import Plugin from '@ckeditor/ckeditor5-core/src/plugin';

import { addListToDropdown, createDropdown } from '@ckeditor/ckeditor5-ui/src/dropdown/utils';
import Collection from '@ckeditor/ckeditor5-utils/src/collection';
import Model from '@ckeditor/ckeditor5-ui/src/model';
import tagIcon from '../ckeditor5-icons/theme/icons/tag.svg';
import SplitButtonView from '@ckeditor/ckeditor5-ui/src/dropdown/button/splitbuttonview';

export default class TagUI extends Plugin {
  init() {
    const editor = this.editor;
    const t = editor.t;
    const placeholderNames = editor.config.get('placeholderConfig.variables');

    // Check if  placeholderNames is empty
    if (!placeholderNames.length) {
      throw Error('placeholderConfig.variables cannot be empty');
    }

    const PLACEHOLDER = 'placeholder';
    let view;
    // The "placeholder" dropdown must be registered among the UI components of the editor
    // to be displayed in the toolbar.
    editor.ui.componentFactory.add(PLACEHOLDER, (locale) => {
      view = createDropdown(locale, SplitButtonView);

      // Populate the list in the dropdown with items.
      addListToDropdown(view, getDropdownItemsDefinitions(placeholderNames));

      // Set attributes of buttonView
      view.buttonView.set({
        label: t('Merge Tags'),
        icon: tagIcon,
        tooltip: true,
        withText: false,
      });

      // Listen to the dropdown icon click
      this.listenTo(view, 'execute', (evt) => {
        // Pass the content of the clicked el to command
        editor.execute(PLACEHOLDER, { content: evt.source.commandParam });
        editor.editing.view.focus();
      });

      // Listen to the real button click
      this.listenTo(view.buttonView, 'execute', (evt) => {
        editor.execute(PLACEHOLDER);
        editor.editing.view.focus();
      });

      return view;
    });

    // Flag to know the state of selection
    let selectionIsTag = false;
    editor.model.document.on('change', (evt) => {
      selectionIsTag = Boolean(editor.model.document.selection.getAttribute('placeholder'));
      // Confirm view is registered properly
      if (view) {
        // Update isOn property of view button
        // The isOn property controls the active state of the view button
        view.buttonView.set('isOn', selectionIsTag);
      }
    });

    console.log('TagUI#init() got called');
  }
}

function getDropdownItemsDefinitions(placeholderNames) {
  const itemDefinitions = new Collection();

  for (const name of placeholderNames) {
    const definition = {
      type: 'button',
      model: new Model({
        commandParam: name,
        label: name,
        withText: true,
      }),
    };

    // Add the item definition to the collection.
    itemDefinitions.add(definition);
  }

  return itemDefinitions;
}
