import { Button, Modal } from 'antd';
import React, { useState } from 'react';
import styles from '../../../styles/newContactPage.module.scss';
import { showToastMessage } from 'utils/Toast';
import { MdClose } from 'pages/ContactPage/Icons/MdClose';
import { instantEmailsActionTypes } from 'utils/constants';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEditLandingPageMutation } from 'redux/featureApi/landingPageApiSlice';

export const SaveTemplateModal = ({
  imVisible,
  setImVisible,
  mailId,
  campaignId,
  landingPageId,
  handleSaveEmailToDB,
}) => {
  const [loading, setLoading] = useState({ id: '' });
  const { search } = useLocation();
  const navigate = useNavigate();
  const forInstantEmail = new URLSearchParams(search).get('action');

  const [editLandingPage] = useEditLandingPageMutation();

  const editLandingPageFn = async (data, value) => {
    try {
      await editLandingPage({ landingPageId, body: { ...data, published: value === true ? true : false } }).unwrap();
      showToastMessage({
        type: 'success',
        title: 'Success',
        description: 'Landing page saved successfully',
      });
      setLoading(false);
      closeModal();
    } catch (error) {
      showToastMessage({
        type: 'error',
        title: 'Error',
        description: error?.message || 'Error saving landing page',
      });
      setLoading(false);
    }
  };

  const redirectToInstantEmail = () => {
    if (forInstantEmail === instantEmailsActionTypes.edit) {
      navigate(`/instant-emails/drafts/${mailId}`);
    } else if (forInstantEmail === instantEmailsActionTypes.create) {
      navigate(`/instant-emails/drafts/${mailId}`);
    }
  };

  const redirectToCampaign = () => {
    navigate(`/campaign/${campaignId}/emails`);
  };

  const redirectToLandingPage = () => {
    navigate(`/landing-page`);
  };

  const closeModal = () => {
    setImVisible(false);
    if (landingPageId) {
      redirectToLandingPage();
    } else if (campaignId) {
      redirectToCampaign();
    } else {
      redirectToInstantEmail();
    }
  };

  const onSubmitSaveTemplate = async (value) => {
    if (value === true) {
      setLoading({ id: 'saveAndPublish' });
    } else {
      setLoading({ id: 'save' });
    }

    try {
      const data = await handleSaveEmailToDB(value);
      if (landingPageId) {
        await editLandingPageFn(data, value);
      } else {
        showToastMessage({
          type: 'success',
          title: 'Success',
          description: value === true ? 'Email added to your saved templates' : 'Email saved successfully',
        });
        setLoading(false);
        closeModal();
      }
    } catch (error) {
      const spamDetected = error?.message.includes('spam');
      showToastMessage({
        type: 'error',
        title: spamDetected ? 'Spam detected' : 'Error',
        description: landingPageId ? 'Error occurred saving landing page' : error?.message || 'Error saving template',
      });
      setLoading(false);
      setImVisible(false);
    }
  };

  return (
    <Modal
      open={imVisible}
      className={`emmaccen ${styles['tempModal']}`}
      onCancel={() => {
        setImVisible(false);
        setLoading({ id: '' });
        return;
      }}
      footer={null}
      closeIcon={<MdClose />}
      destroyOnClose={true}
      centered
      maskClosable={false}
    >
      <div className={styles['template-modal']}>
        <div>
          <h2 className={`${styles['template-title']} mx-auto`}>
            {landingPageId
              ? 'Would you like to publish this landing page now?'
              : 'Would you like to save this email as a re-useable template?'}
          </h2>
          {!landingPageId && (
            <h2 className={styles['template-text']}>
              You would be able to find this email design under your saved templates
            </h2>
          )}
        </div>
        <Button
          size={'large'}
          htmlType="submit"
          loading={loading.id === 'saveAndPublish'}
          disabled={loading.id === 'saveAndPublish' || loading.id === 'save'}
          className={`${styles['template-btn']} mt-8 ${
            loading.id === 'saveAndPublish' || loading.id === 'save' ? styles['template-btn-loading'] : ''
          }`}
          onClick={() => onSubmitSaveTemplate(true)}
          data-test={landingPageId ? 'publish-landing-page' : 'save-and-proceed'}
        >
          {landingPageId ? 'Publish' : 'Save and proceed'}
        </Button>
        <Button
          loading={loading.id === 'save'}
          disabled={loading.id === 'saveAndPublish' || loading.id === 'save'}
          size={'large'}
          onClick={() => onSubmitSaveTemplate(false)}
          className={styles['outline-btn']}
          data-test={landingPageId ? 'save-landing-page' : 'save-email-template'}
        >
          No, thanks
        </Button>
      </div>
    </Modal>
  );
};
