import { MdGlobalButton, MdTypography } from '../../components/global';
import MdGlobalCTA from '../../components/global/MdGlobalCTA/MdGlobalCTA';
import React, { useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';
import { useNavigate, useLocation } from 'react-router-dom';
import '../../styles/unverifiedPage.scss';
import { resendEmailVerificationRequest } from '../../utils/service';
import { emailVerification } from '../../utils/services/authService';
import { showToastMessage } from '../../utils/Toast';
import { pixelCompleteVerification } from '../../utils/metaPixels/pageEvent';

export const VerifyUserWithMdCTA = () => {
  const [imLoading, setImLoading] = useState({ id: '' });

  const [otp, setOtp] = useState('');

  const navigate = useNavigate();
  const location = useLocation();
  const { email, resendVerificationMail } = location?.state;
  /* eslint-disable */
  useEffect(() => {
    if (resendVerificationMail) {
      requestVerificationMail(email);
    }
  }, [email]);

  const handleSubmit = () => {
    setImLoading({ id: 'verifyingAccount' });
    emailVerification(otp)
      .then(() => {
        showToastMessage({
          type: 'success',
          title: 'Account',
          description: 'Account verified',
        });

        pixelCompleteVerification();
        if (typeof LinkMink === 'function') {
          LinkMink('pub_live_V8RF8N70WWFGeP4wXNAM').initTracking().then();
        }
        navigate('/login');
      })
      .catch((error) => {
        showToastMessage({
          type: 'error',
          title: 'Error',
          description: error.response?.data?.error || 'Unable to process your',
        });
      })
      .finally(() => {
        setImLoading({ id: '' });
      });
  };
  const requestVerificationMail = () => {
    setImLoading({ id: 'resendingVerificationLink' });
    resendEmailVerificationRequest({
      email,
    })
      .then(() => {
        showToastMessage({
          type: 'success',
          title: 'Verification',
          description: 'Verification code sent 📬',
        });
      })
      .catch((error) => {
        showToastMessage({
          type: 'error',
          title: 'Error',
          description: error.response?.data?.error || error.message || 'Unable to process your request',
        });
      })
      .finally(() => {
        setImLoading({ id: '' });
      });
  };

  return (
    <div className="emmaccen">
      <div className="loginPageContainer">
        <div className="loginSections flex">
          <MdGlobalCTA />
          <div className="verifySectionContainer centered">
            <div className="verifySectionInnerContainer">
              <MdTypography variant={'h3Bold'} className="title">
                Verify Your Account
              </MdTypography>
              <div className="textWrapper">
                <MdTypography variant={'smallTextMedium'}>
                  We sent a mail to <span className="primaryColorLight">{email}</span>. Copy the code and paste it here
                  to verify your account.
                </MdTypography>
              </div>
              <div className="centered pt-10 pb-16">
                <OtpInput
                  value={otp}
                  onChange={(otp) => setOtp(otp)}
                  numInputs={5}
                  inputStyle={{
                    borderBottom: '3px solid #E0E2E9',
                    fontSize: '32px',
                    fontWeight: 600,
                    marginRight: '5px',
                    width: '40px',
                    color: 'black',
                  }}
                  focusStyle={{
                    borderBottom: '3px solid rgba(39, 61, 242, 0.37)',
                  }}
                />
              </div>

              <div className="flex flex-wrap justify-between pb-5 resendCodeContainer">
                <div>
                  <MdTypography variant={'smallTextRegular'}>It may take a minute to receive your code.</MdTypography>
                  <MdTypography variant={'smallTextRegular'}>
                    <span>Haven't recieved it?</span>
                    <span onClick={requestVerificationMail} className="pointer primaryColorLight pl-3">
                      {imLoading.id === 'resendingVerificationLink' ? 'Sending...' : 'Send a new code.'}
                    </span>
                  </MdTypography>
                </div>
                <div className="mt-3 pl-2 flex justify-end submit-btn-lg">
                  <MdGlobalButton
                    loading={imLoading.id === 'verifyingAccount'}
                    style={{ width: '100%' }}
                    disabled={otp.length < 5}
                    id="primary_default"
                    size="large"
                    onClick={handleSubmit}
                  >
                    <MdTypography variant={'smallTextMedium'} style={{ color: '#ffffff' }}>
                      Submit
                    </MdTypography>
                  </MdGlobalButton>
                </div>
              </div>
            </div>
            <div className="submit-btn-mobile">
              <MdGlobalButton
                loading={imLoading.id === 'verifyingAccount'}
                style={{ width: '100%' }}
                disabled={otp.length < 5}
                id="primary_default"
                size="large"
                onClick={handleSubmit}
              >
                <MdTypography variant={'smallTextMedium'} style={{ color: '#ffffff' }}>
                  Submit
                </MdTypography>
              </MdGlobalButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
