import { Form, Input } from 'antd';
import { MdGlobalButton, MdTypography } from '../../components/global';
import MdGlobalCTA from '../../components/global/MdGlobalCTA/MdGlobalCTA';
import React, { useState } from 'react';
import { AppConstants } from '../../utils/appConstants';
import '../../styles/forgotPassword.scss';
import { forgotPasswordRequest } from '../../utils/services/authService';
import { showToastMessage } from '../../utils/Toast';

export const MdForgotPasswordPage = () => {
  const [imLoading, setImLoading] = useState({ id: '' });
  const [forgotPasswordForm] = Form.useForm();

  const resetPassword = (email) => {
    setImLoading({ id: 'sendingPasswordResetLink' });
    forgotPasswordRequest(email)
      .then((res) => {
        showToastMessage({
          type: 'success',
          title: 'Request Successful',
          description: res.data.data.message,
        });
      })
      .catch((error) => {
        showToastMessage({
          type: 'error',
          title: 'Request Failed',
          description: error?.response?.data?.error,
        });
      })
      .finally(() => {
        setImLoading({ id: '' });
      });
  };

  return (
    <div className="emmaccen">
      <div className="loginPageContainer forgotPasswordContainer">
        <div className="loginSections flex">
          <MdGlobalCTA />
          <div className=" formArea w-1/2">
            <div className="formContent">
              <div className="container2 flex flex-col">
                <a
                  href={AppConstants.MAILDRIP_BLOG_URL}
                  target="_blank"
                  rel="noreferrer"
                  className="flex-jst-right pt-2 md-logo"
                >
                  <img src="/assets/smallLogo.svg" alt="company logo" className="mb-5" />
                </a>
                <div>
                  <div className="margin-t-b">
                    <div className="mobile-image">
                      <img src="/assets/loginCamp2.png" className="campagineImg mb-10" alt="campagne"></img>
                    </div>
                  </div>

                  <div className="forgtPwdTitle pt-5">
                    <MdTypography variant={'h3Bold'} className="lets-get-your-account-back-lg">
                      Let’s get your account back{' '}
                    </MdTypography>
                    <MdTypography variant={'h1'} className="title mb-2">
                      Reset password
                    </MdTypography>
                  </div>

                  <Form
                    name="basic"
                    form={forgotPasswordForm}
                    initialValues={{
                      remember: true,
                    }}
                    onFinish={resetPassword}
                    autoComplete="off"
                    size="large"
                    layout="vertical"
                  >
                    <Form.Item
                      label="Your email"
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: 'Please input a valid email!',
                          type: 'email',
                        },
                      ]}
                    >
                      <Input type="email" placeholder="What’s your email?" />
                    </Form.Item>

                    <Form.Item>
                      <div class="centered">
                        <MdGlobalButton
                          id={'primary_xl'}
                          style={{ width: '100%' }}
                          htmltype="submit"
                          loading={imLoading.id === 'sendingPasswordResetLink'}
                        >
                          <MdTypography variant={'h6Medium'} className="text-center" style={{ color: '#ffffff' }}>
                            Reset Password
                          </MdTypography>
                        </MdGlobalButton>
                      </div>
                    </Form.Item>
                  </Form>
                </div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
