import Layout from '../../components/Layout';
import LoadingScreen from '../../components/LoadingScreen';
// import CurrentCompanyViewContext from '../../contexts/CurrentCompanyViewContext';
import { growthbook } from '../../growthBook.instance';
import useRequireAuth from '../../hooks/useRequireAuth';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { useGetUserDetailsQuery } from '../../redux/featureApi/userApiSlice';
import { setUserDetails } from '../../redux/featureSlices/authSlice';

export const ProtectedRoute = ({ includeLayout = true }) => {
  const [isLoading, setIsLoading] = useState(true);
  const auth = useRequireAuth(() => setIsLoading(false));
  const { data, isSuccess } = useGetUserDetailsQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const dispatch = useDispatch();
  /* eslint-disable */
  useEffect(() => {
    if (isSuccess) {
      dispatch(setUserDetails(data?.data));
    }
  }, [data]);

  useEffect(() => {
    if (!isLoading && auth && data) {
      const user = data?.data?.user;
      const userPlan = user?.isPaidUser;
      growthbook.setAttributes({
        // update growthbook attributes when user is logged in
        ...growthbook.getAttributes(),
        id: user?.email,
        company: user?.companyName || user?.name,
        paid: !!userPlan,
      });
    }
  }, [auth, isLoading, data]);

  return isLoading ? (
    <LoadingScreen />
  ) : (
    !isLoading && auth && (
      // <CurrentCompanyViewContext>
        <Layout>
          <div className={includeLayout ? `p-5 relative h-full w-full` : ''}>
            <Outlet />
          </div>
        </Layout>
      // </CurrentCompanyViewContext>
    )
  );
};
