import { Form, Select } from 'antd';
import { useState, useEffect, useCallback } from 'react';
import { MdGlobalButton, MdTypography } from 'components/global';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { growthBookId } from 'utils/constants';
import { CreateNewGroup } from './CreateNewGroup';

const Step1Subscribers = ({
  saveContactForm,
  setImVisible,
  imVisible,
  contactGroups,
  setContactGroups,
  refetchContactGroupsData,
  refetchSingleInstantEmail,
  fetchingContactGroups,
  groupsAdded,
  setGroupsAdded,
  subscribersCount,
  savedGroup,
  savedGroupsToInstantEmailsLoading,
  handleSaveInstantEmailContact,
}) => {
  const { Option } = Select;
  const instantEmailCsvUpload = useFeatureIsOn(growthBookId.hideContactUploadIE);
  const [filteredGroups, setFilteredGroups] = useState([]);
  const [csvResult, setCsvResult] = useState(null);
  const [group, setGroup] = useState(null);



  const filterGroups = useCallback((groups) => {
    return groups?.filter((group) => !(group?._id === 'risky_contacts' || group?._id === 'all_contacts'));
  }, []);

  const [selectedValue, setSelectedValue] = useState([...savedGroup]);

  const filterAndSetGroups = useCallback(() => {
    selectedValue.length > 0 ? setGroupsAdded(true) : setGroupsAdded(false);
    if (contactGroups) {
      const filtered = filterGroups(contactGroups);
      setFilteredGroups(filtered.reverse());
    }
  }, [contactGroups, filterGroups, selectedValue, setGroupsAdded]);

  useEffect(() => {
    filterAndSetGroups();
  }, [filterAndSetGroups]);
  
  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    
    if (group?.length > 0 || selectedValue?.length > 0) {
      const total = [...selectedValue, group];
      const uniqueItems = Array.from(new Map(total.map((item) => [item?._id, item])).values());
      setSelectedValue((pre) => [...pre, uniqueItems]);
    } else if (Array.isArray(selectedValue) && selectedValue?.length=== 0 && group?.length===1) {
      const total = [group];
      const uniqueItems = Array.from(new Map(total?.map((item) => [item?._id, item])).values());
      setSelectedValue(uniqueItems);
    }
    // eslint-disable-next-line
  }, [group]);


  const handleChange = (value) => {
    if (selectedValue > 0) {
      setGroupsAdded(true);
      setSelectedValue((pre) => [...pre, ...value]);
    } else {
      setGroupsAdded(false);    
        setSelectedValue(value);
     
    }
  };

  return (
    <Form
      form={saveContactForm}
      name="basic"
      onFinish={({ groups }) => handleSaveInstantEmailContact(selectedValue.flat())}
      onFinishFailed={() => null}
      autoComplete="off"
      size="large"
      className="formOne"
      layout="vertical"
    >
      <CreateNewGroup
        imVisible={imVisible}
        setImVisible={setImVisible}
        contactGroups={filteredGroups}
        setCsvResult={setCsvResult}
        csvResult={csvResult}
        refetchSingleInstantEmail={refetchSingleInstantEmail}
        refetchContactGroupsData={refetchContactGroupsData}
        group={group}
        setGroup={setGroup}
        setContactGroups={setContactGroups}
        saveContactForm={saveContactForm}
      />
      <div className="instant-email-form-header">
        <Form.Item name="groups" label="Groups" className="form-group">
          <Select
            mode="multiple"
            data-test="select-test"
            style={{ width: '100%' }}
            placeholder={'Select Group'}
            onChange={handleChange}
            filterOption={(input, option) => {
              return option.label.toLowerCase().includes(input.trim().toLowerCase());
            }}
          >
            {filteredGroups?.map((data) => (
              <Option
                key={data?._id}
                data-test="option-test"
                field={data?.totalContacts}
                value={data?._id}
                label={data.title}
              >
                <div className="demo-option-label-item">{data.title}</div>
              </Option>
            ))}
          </Select>
        </Form.Item>
        <div>
          <p
            onClick={() => setImVisible({ id: 'createNewContactGroup' })}
            data-test="create-new-contact-group"
            className="instant-email-create-group"
          >
            Create new group
          </p>
        </div>
      </div>

      {instantEmailCsvUpload && (
        <div className="flex upload-contact">
          <MdTypography variant={'btnRegular'} className="or">
            OR
          </MdTypography>
          <MdTypography
            variant={'btnRegular'}
            onClick={() => {
              setImVisible({ id: 'confirmCsvUpload' });
            }}
            className="instant-email-form-link"
          >
            Upload Contact
          </MdTypography>
        </div>
      )}

      <MdTypography variant={'btnRegular'} className="mt-4 or">
        Total Subscribers: <span data-test="subscribers-count"> {subscribersCount} </span>
      </MdTypography>

      <div className={`instant-email-form-button-header ${!groupsAdded ? 'button-opacity' : ''}`}>
        <MdGlobalButton
          data-test="subscribers-save-btn"
          size={'large'}
          htmlType="submit"
          loading={savedGroupsToInstantEmailsLoading}
          disabled={!groupsAdded}
        >
          Save
        </MdGlobalButton>
      </div>
    </Form>
  );
};

export default Step1Subscribers;
