import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Select, Form, Button, Alert } from 'antd';
import { jobContext } from '../../../../contexts/job';
import { SET_NEW_JOB } from '../../../../contexts/job/reducer/types';
import { showToastMessage } from '../../../../utils/Toast';
import { modalsContext } from '../../../../contexts/modal';
import { SET_MODAL_PROPS } from '../../../../contexts/modal/reducer/types';
import { modalId } from '../../../../utils/constants';
import CsvKeyValidator from './CsvKeyValidator';
import { MdTypography } from '../../../../components/global';
import { useImportRecipientsCsvToCampaignMutation } from '../../../../redux/featureApi/campaignApiSlice';
import { useAddNewBulkContactToGroupMutation } from 'redux/featureApi/contactApiSlice';
import { getJobDetails } from 'utils/service';
import Cookies from 'js-cookie';

const MdConfirmCsvUpload = ({
  setImVisible,
  csvResult,
  setCsvResult,
  contactGroups,
  setRefresh,
  uploadContactsTo,
  campaignId,
  setUploadStep,
  refetchContactDetails,
}) => {
  const [imLoading, setImLoading] = useState({ id: '' });
  const { dispatch } = useContext(jobContext);
  const { dispatch: modalDispatch } = useContext(modalsContext);
  const [group, setGroup] = useState();
  const { Option } = Select;

  const styles = {
    importBtn: {
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      backgroundColor: '#fbfbfb',
      borderRadius: 4,
      fontFamily: 'Paralucent-Medium',
      fontSize: 14,
      fontStyle: 'normal',
      color: '#273df2',
      border: 0,
      padding: 8,
    },
    contactBtn: {
      borderRadius: 4,
      backgroundColor: '#273df2',
      fontSize: 14,
      color: 'white',
      fontFamily: 'Paralucent-light',
      padding: 8,
    },
  };

  const [importRecipientsCsvToCampaign] = useImportRecipientsCsvToCampaignMutation();
  const [addNewBulkContactToGroup] = useAddNewBulkContactToGroupMutation();

  const [MdCsvUploadForm] = Form.useForm();

  const csvFoundHeaders = Object.keys(Object.assign({}, ...csvResult));

  const onSubmitContactDetail = ({ name, email, phone }) => {
    const [firstName, lastName] = name;
    let fullName;

    const convertResults = csvResult.map((data, i) => {
      fullName = data[firstName];
      if (data[lastName]) {
        fullName += ` ${data[lastName]}`;
      }
      return {
        ...data,
        name: String(fullName).trim(),
        email: String(data[email]).trim(),
        phone: String(data[phone] ?? '').trim(),
      };
    }, {});

    const [firstContact] = convertResults;

    const firstRowValidator = new CsvKeyValidator(firstContact);
    // if (!firstRowValidator.validateEmail()) {
    //   return showToastMessage({
    //     type: 'error',
    //     title: 'Error',
    //     description: 'Seems your csv file is empty',
    //   });
    // } else if (!firstRowValidator.validateName()) {
    //   return showToastMessage({
    //     type: 'warning',
    //     title: 'Warning',
    //     description: 'The name field can only accept alphabets and hyphens',
    //   });
    // } else
    if (!firstRowValidator.validateEmail()) {
      return showToastMessage({
        type: 'warning',
        title: 'Warning',
        description: 'Invalid Email format',
      });
    } else {
      const removeLastCsvIndex = convertResults.splice(0, convertResults.length - 1);

      /**
    if uploading csv to a contact group,
    @return { addNewBulkContactToGroup({payload: { contacts: [...csvResult,  ], groupId: currentGroup._id },}) }
    else if uploading to a Campaign as recipient
    @return { importRecipientsCsvToCampaign({ payload: {contacts: [...csvResult], groupId: currentGroup._id }, campaignId}) }
     */

      if (uploadContactsTo === 'addNewContactGroup') {
        setImLoading({ id: 'creatingContact' });
        addNewBulkContactToGroup({
          payload: { contacts: [...removeLastCsvIndex], groupId: group },
        })
          .then(({ data: { data } }) => {
            setRefresh(data?.contacts);
            showToastMessage({
              type: 'success',
              title: 'Contacts Upload',
              description: 'Contacts Queued',
            });

            checkUploadProgress(data?._id);

            dispatch({
              type: SET_NEW_JOB,
              payload: {
                ...data,
                ID: data._id,
              },
            });
          })
          .finally(() => {
            setImLoading({ id: '' });
            setImVisible({ id: '' });
            setCsvResult([]);
          });
      } else if (uploadContactsTo === 'campaignRecipient') {
        setImLoading({ id: 'creatingContact' });
        // SET PROPS ON MODAL
        modalDispatch({
          type: SET_MODAL_PROPS,
          payload: {
            id: modalId.turnOnCampaign, // Modal unique ID
            data: { campaignId },
          },
        });

        importRecipientsCsvToCampaign({ payload: { contacts: removeLastCsvIndex, groupId: group }, campaignId })
          .unwrap()
          .then(async ({ data }) => {
            setRefresh(data);
            showToastMessage({
              type: 'success',
              title: 'Success',
              description: 'Contacts Queued',
            });
            dispatch({
              type: SET_NEW_JOB,
              payload: {
                ...data,
                ID: data._id,
                // Add to job object so that we can persist modal data with LS even if user refreshes the app
                campaign: {
                  id: modalId.turnOnCampaign, // Modal unique ID
                  props: { campaignId },
                },
              },
            });
          })
          .finally(() => {
            setImLoading({ id: '' });
            setImVisible({ id: '' });
            setCsvResult([]);
          });
      }
    }
  };

  const checkUploadProgress = (jobId) => {
    if (!Cookies.get('token')) {
      return;
    }
    refetchContactDetails();
    // set interval to call jobs api to check upload progress
    const interval = setInterval(() => {
      if (!Cookies.get('token')) {
        clearInterval(interval);
        return;
      }
      refetchContactDetails();
      getJobDetails(jobId).then((data) => {
        if (data?.data?.data?.job?.status === 2) {
          clearInterval(interval);
        }
      });
    }, 20000);
  };

  return (
    <div className="addNewContactModal">
      <div className="flex items-center">
        <MdTypography variant={'h5Medium'} className="text-center w-full">
          Tag your contacts
        </MdTypography>
        <span className="text-bold bold">2/2</span>
      </div>
      <Alert
        message="Match the titles in your upload with our predefined tags"
        type="info"
        className="csvTitle my-4"
        showIcon
      />

      <div>
        <Form
          form={MdCsvUploadForm}
          onFinish={(data) => onSubmitContactDetail(data)}
          onFinishFailed={() => null}
          autoComplete="off"
          size="large"
          layout="vertical"
          requiredMark={false}
        >
          <Form.Item
            name="name"
            label="Match Name with"
            rules={[{ required: true, message: 'A column in your file must be matched with Name!' }]}
          >
            <Select
              showSearch
              mode="tags"
              placeholder="Select value for Name"
              optionFilterProp="children"
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {csvFoundHeaders?.map((foundHead, i) => (
                <Option key={i} value={foundHead} data-test="match-name-test">
                  {foundHead.trim()}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="email"
            label="Match Email Address with"
            rules={[{ required: true, message: 'A column in your file must be matched with Email!' }]}
          >
            <Select
              showSearch
              mode="tags"
              placeholder="Select value for Email"
              optionFilterProp="children"
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {csvFoundHeaders?.map((foundHead, i) => (
                <Option key={i} value={foundHead} data-test="match-email-test">
                  {foundHead.trim()}
                </Option>
              ))}
            </Select>
          </Form.Item>
          {/* <Form.Item name="phone" label="Match Phone Number with">
            <Select
              showSearch
              mode="tags"
              placeholder="Select value for Phone Number"
              optionFilterProp="children"
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {csvFoundHeaders?.map((foundHead, i) => (
                <Option key={i} value={foundHead} data-test="match-phone-test">
                  {foundHead.trim()}
                </Option>
              ))}
            </Select>
          </Form.Item> */}
          <Form.Item
            name="groups"
            label="Add Contacts to Group"
            rules={[{ required: true, message: 'Please, select group to import contacts to' }]}
          >
            <Select
              showSearch
              mode="tags"
              placeholder="Select Group"
              onChange={(group) => setGroup(group)}
              optionLabelProp="label"
            >
              {contactGroups?.map((data) => {
                return (
                  <Option key={data._id ?? data.title} value={data._id} label={data.title} data-test="match-group-test">
                    <div className="demo-option-label-item">{data.title}</div>
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <div className="emmaccen pt-4">
            <div className="flex-space-btw actionBtns ">
              <Button
                size={'large'}
                type=""
                onClick={() => {
                  setUploadStep('1');
                }}
                style={styles.importBtn}
              >
                Back
              </Button>
              <Button
                size={'large'}
                data-test="match-btn-test"
                type="primary"
                htmlType="submit"
                loading={imLoading?.id === 'creatingContact'}
                style={styles.contactBtn}
              >
                Done
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

MdConfirmCsvUpload.propTypes = {
  uploadContactsTo: PropTypes.oneOf(['addNewContactGroup', 'campaignRecipient']).isRequired,
  imVisible: PropTypes.any.isRequired,
  setImVisible: PropTypes.any.isRequired,
  csvResult: PropTypes.array.isRequired,
  currentGroup: PropTypes.any,
  setRefresh: PropTypes.any,
  campaignId: PropTypes.string,
  formInstance: PropTypes.any,
};

export default MdConfirmCsvUpload;
