import React, { useState } from 'react';

import { Modal } from 'antd';
import MdConfirmCsvUpload from './MdConfirmCsvUpload';
import MdCsvReader from './MdCsvReader';
import styles from 'styles/newContactPage.module.scss';

const MdUploadCsvModal = ({
  imVisible,
  setImVisible,
  csvResult,
  setCsvResult,
  uploadContactsTo,
  campaignId,
  contactGroups,
  formInstance,
  refetchContactDetails,
}) => {
  const [uploadStep, setUploadStep] = useState('1');

  return (
    <Modal
      className={`emmaccen ${styles['contact-modal']}`}
      open={imVisible?.id === 'confirmCsvUpload'}
      // onOk={() => handleContactAddition()}
      onCancel={() => setImVisible({ id: '' })}
      afterClose={() => {
        setUploadStep('1');
        setCsvResult([]);
      }}
      footer={null}
      maskClosable={false}
      closable={false}
      destroyOnClose={true}
      centered
    >
      <div className="confirmCsvUpload">
        {/* <div className="title">{uploadStep == 1 ? 'Import' : 'Tag your contacts'}</div> */}
        {uploadStep === '1' ? (
          <MdCsvReader
            setUploadStep={setUploadStep}
            imVisible={imVisible}
            setImVisible={setImVisible}
            setCSVResult={setCsvResult}
            uploadContactsTo={uploadContactsTo}
          />
        ) : uploadStep === '2' ? (
          <MdConfirmCsvUpload
            csvResult={csvResult}
            setCsvResult={setCsvResult}
            uploadContactsTo={uploadContactsTo}
            imVisible={imVisible}
            setImVisible={setImVisible}
            contactGroups={contactGroups}
            setUploadStep={setUploadStep}
            campaignId={campaignId}
            refetchContactDetails={refetchContactDetails}
          />
        ) : (
          ''
        )}
      </div>
    </Modal>
  );
};

MdUploadCsvModal.propTypes = {};

export default MdUploadCsvModal;
