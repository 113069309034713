import { ArrowUpOutlined } from '@ant-design/icons';
import { Card } from 'antd';
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import '../../../styles/dashboard.scss';
import MdTypography from '../../global/Typography';
import MdGlobalButton from '../../global/Button';
import { Link } from 'react-router-dom';
import AdditionIcon from '../../../asset/Icons/AdditionIcon';
import MdDashboardModal from '../../../components/emmaccen/MdDashboardDetails/MdDashboardModal';
import price from 'asset/images/Price-tag.svg';
import usePaygPromoDates from 'hooks/usePaygPromoDates';

const MdDashboardUserDetails = () => {
  const { withinPromoPeriod } = usePaygPromoDates();
  const [userStatistics, setUserStatistics] = useState({});

  const api__url = process.env.REACT_APP_API_URL;

  const FetchData = async () => {
    const { data } = await axios.get(`${api__url}/dashboard/statistics`);
    setUserStatistics(data.data);
  };
  /* eslint-disable */
  useEffect(() => {
    FetchData();
  }, []);

  let dateStrings = new Date();
  let mDate = moment(dateStrings);
  let dateFormatString = 'ddd DD, MMM YYYY';
  let dateString = mDate.format(dateFormatString);

  const nameData = JSON.parse(localStorage.getItem('userDetails') || '{}')?.user;

  let nameD = nameData?.user?.name;

  return (
    <>
      {withinPromoPeriod && (
        <div className="promo-container mb-10">
          <div>
            <img src={price} alt="" />
            <div className="promo-text-container">
              <p className="promo-text-header">Limited Time Offer</p>
              <p className="promo-text">
                To celebrate our milestone of hitting 1,000 users, we are currently running a 10% discount on our
                pay-as-you-go price, take advantage of this discount now.
              </p>
            </div>
          </div>
          <div>
            <Link to="/payment/pay" className="promo-button">
              Get started
            </Link>
          </div>
        </div>
      )}

      <div className="heading">
        <MdTypography variant={'captionMedium'} className="mb-2">
          {dateString}
        </MdTypography>
        <MdTypography variant="h5Light" className="mb-1">
          Welcome back, {nameD}
        </MdTypography>
        <div className="dashboard-create-new-container">
          <Link to="/create-new">
            <MdGlobalButton className="btn-create" loading={false}>
              <MdTypography variant={'btnRegular'} className="create-text">
                Create New
              </MdTypography>
              <AdditionIcon />
            </MdGlobalButton>
          </Link>
        </div>
      </div>

      <div className="dashboard-modal-mobile">
        <MdDashboardModal />
      </div>

      <div className="userAnalytics">
        <div className="userAnaly">
          <Card className="userAnalyCard" product-tutor-id="email-credits">
            <MdTypography variant={'captionMedium'} className="mb-1">
              Email Credits
            </MdTypography>
            <MdTypography variant={'h4Medium'} dataTestId="total-email-credits">
              {userStatistics.totalEmailCredits}
            </MdTypography>
          </Card>
        </div>
        <div className="userAnaly">
          <Card className="userAnalyCard">
            <MdTypography variant={'captionMedium'} className="mb-1">
              Contacts
            </MdTypography>
            <div className="flex justify-between align-items-center">
              <MdTypography variant={'h4Medium'}>{userStatistics.totalContacts}</MdTypography>
              <div className="contactUptrend pb-1">
                <MdTypography variant={'captionMedium'} className="contactUptrendCount">
                  {userStatistics.contactsAddedToday} <ArrowUpOutlined className="contactUptrendIcon" />
                </MdTypography>
              </div>
            </div>
          </Card>
        </div>
        <div className="userAnaly">
          <Card className="userAnalyCard">
            <MdTypography variant={'captionMedium'} className="mb-1">
              Campaigns
            </MdTypography>
            <MdTypography variant={'h4Medium'}>{userStatistics.totalCampaigns}</MdTypography>
          </Card>
        </div>
        <div className="userAnaly">
          <Card className="userAnalyCard">
            <MdTypography variant={'captionMedium'} className="mb-1">
              Total Emails Sent
            </MdTypography>
            <MdTypography variant={'h4Medium'}>{userStatistics.sentEmails}</MdTypography>
          </Card>
        </div>
      </div>
    </>
  );
};

export default MdDashboardUserDetails;
