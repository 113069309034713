import { apiAuthSlice } from './apiAuthSlice';

export const userApiSlice = apiAuthSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUserDetails: builder.query({
      query: (params) => ({
        url: `/users/me`,
        method: 'GET',
      }),
      keepUnusedDataFor: 0.0001,

      providesTags: ['UserDetails'],
    }),
  }),
  overrideExisting: true,
});

export const { useGetUserDetailsQuery } = userApiSlice;
