import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  modal: {
    add_new_team_member: false,
    create_new_team: false,
  },
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    toggleAddNewTeamMember: (state, action) => {
      state.modal.add_new_team_member = !state.modal.add_new_team_member;
    },
    toggleCreateNewTeam: (state, action) => {
      state.modal.create_new_team = !state.modal.create_new_team;
    },
  },
});

export const { toggleAddNewTeamMember, toggleCreateNewTeam } = uiSlice.actions;

export default uiSlice.reducer;

export const selectAddNewTeamMember = (state) => state.ui.modal.add_new_team_member;
export const selectCreateNewTeam = (state) => state.ui.modal.create_new_team;
