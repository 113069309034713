import { useEffect, useState } from 'react';
import '../styles/dashboard.scss';
import MdDashboardSidebar from '../components/emmaccen/MdDashboardDetails/MdDashboardSidebar';
import MdDashboardEmailCampaigns from '../components/emmaccen/MdDashboardDetails/MdDashboardEmailCampaigns';
import MdDashboardPerformance from '../components/emmaccen/MdDashboardDetails/MdDashboardPerformance';
import MdDashboardUserDetails from '../components/emmaccen/MdDashboardDetails/MdDashboardUserDetails';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { growthBookId } from 'utils/constants';
import { useSelector } from 'react-redux';
import { CompleteProfileCTA } from 'components/Complete Profile/CompleteProfileCTA';
import { MailServerErrorModal } from 'components/emmaccen/modals/MailServerErrorModal';
import { useLocation } from 'react-router-dom';
import { LowEmailCreditsCTA } from 'components/Low Credits/LowEmailCreditsCTA';

const MdDashboard = () => {
  const disableEmail = useFeatureIsOn(growthBookId.disableEmail);
  const [showModal, setShowModal] = useState();
  const showDashboardAnalytics = useFeatureIsOn(growthBookId.dashboardGraph);
  const showEmailCreditWarning = useFeatureIsOn(growthBookId.emailCreditWarningExperience);
  const userDetails = useSelector((state) => state.auth.user);
  const [isPhoneNumberNull, setIsPhoneNumberNull] = useState(false);
  // const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.state === 'login' && disableEmail) {
      setShowModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const creditBalance = userDetails?.wallet?.paygBalance + userDetails?.wallet?.subBalance;
  const lowCreditLimit = userDetails?.lowCreditLimit;

  useEffect(() => {
    if (Object.keys(userDetails).length !== 0 && !userDetails?.phoneNumber) {
      setIsPhoneNumberNull(true);
    }
  }, [userDetails]);

  return (
    <>
      {showEmailCreditWarning && creditBalance < lowCreditLimit && <LowEmailCreditsCTA />}
      <div className="dashboardContainer">
        <div className="dashboardContentContainer">
          {isPhoneNumberNull && <CompleteProfileCTA setIsPhoneNumberNull={setIsPhoneNumberNull} />}

          <div className="userDetails">
            <MdDashboardUserDetails />
          </div>
          {showDashboardAnalytics && (
            <div style={{ marginTop: '2rem' }}>
              <MdDashboardPerformance />
            </div>
          )}
          <div className="dbemails">
            <MdDashboardEmailCampaigns />
          </div>
        </div>
        <div className="sidebarContainer">
          <MdDashboardSidebar analytics={showDashboardAnalytics} />
        </div>
      </div>
     
      {showModal && <MailServerErrorModal imVisible={showModal} setImVisible={setShowModal} />}
    </>
  );
};
export default MdDashboard;
