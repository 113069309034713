import { Dropdown } from 'antd';
import * as moment from 'moment';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import DetailedIcon from '../../asset/Icons/DetailedIcon';
import optionsMenu from '../../asset/Icons/Options-icon.svg';
import { ReactComponent as ActiveMailIcon } from '../../asset/Icons/bi_send-check.svg';
import { ReactComponent as DraftMailIcon } from '../../asset/Icons/draftIcon.svg';
import { ReactComponent as QueuedMailIcon } from '../../asset/Icons/queueIcon.svg';
import { ReactComponent as ScheduledMailIcon } from '../../asset/Icons/scheduledIcon.svg';
import { ReactComponent as ProcessingMailIcon } from '../../asset/Icons/processIcon.svg';
import { ReactComponent as CollapseAllIcon } from '../../asset/Icons/carbon_collapse-all.svg';
import { MdGlobalButton, MdTabWithTable, MdTypography } from '../../components/global';
import {
  useGetAllInstantEmailsQuery,
  useDeleteInstantEmailMutation,
} from '../../redux/featureApi/instantEmailApiSlice';
import { xProcessedStatusEnum } from '../../utils/constants';
import { showToastMessage } from 'utils/Toast';
import DeleteModal from 'components/Modal/DeleteModal';

const menuItems = [
  {
    key: '',
    label: <p data-test="all">All</p>,
    icon: <CollapseAllIcon />,
  },
  {
    key: xProcessedStatusEnum.draft,
    label: <p data-test="draft">Drafts</p>,
    icon: <DraftMailIcon />,
  },
  {
    key: xProcessedStatusEnum.scheduled,
    label: <p data-test="scheduled">Scheduled</p>,
    icon: <ScheduledMailIcon />,
  },
  {
    key: xProcessedStatusEnum.queued,
    label: <p data-test="queued">Queued</p>,
    icon: <QueuedMailIcon />,
  },
  
  {
    key: xProcessedStatusEnum.processing,
    label: <p data-test="processing">Processing</p>,
    icon: <ProcessingMailIcon />,
  },
  {
    key: xProcessedStatusEnum.failed,
    label: <p data-test="failed">Failed</p>,
    icon: <QueuedMailIcon />,
  },
  {
    key: xProcessedStatusEnum.completed,
    label: <p data-test="completed">Completed</p>,
    icon: <ActiveMailIcon />,
  },
];

const InstantEmailTableWrapper = ({
  status,
  searchTerm,
  emailDetails,
  cloneInstantEmailFn,
  updateMailDetails,
  updateImVisibility,
  changeListView,
  tableHeight,
}) => {
  const [instantEmailList, setInstantEmailList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // currentPage should be passed in the query parameter
  const [totalDcuments, setTotalDocuments] = useState(0); // This should be gotten from the api response as  the total number of instantEmailData the user has
  const [emailStatus, setEmailStatus] = useState(emailDetails?.x_processed_status);
  const [pageTotal, setPageTotal] = useState(0);
  const [imVisible, setImVisible] = useState({ id: '' });
  const navigate = useNavigate();

  const {
    data: getAllInstantEmailsData,
    isFetching: fetchingAllInstantEmails,
    isSuccess: allInstantEmailsFetched,
    isLoading: loadingAllInstantEmails,
    refetch: refetchAllInstantEmails,
  } = useGetAllInstantEmailsQuery(
    { status, search: searchTerm, page: currentPage },
    { pollingInterval: 60000, refetchOnMountOrArgChange: true }
  ); // page should be currentPage - 1 because the api starts counting from 0: removed it because it is showing same data on different pages

  const [
    deleteInstantEmail,
    { isLoading: deletingInstantEmail, isSuccess: deletedInstantEmail, isError: deletedInstantEmailFailed },
  ] = useDeleteInstantEmailMutation();

  useEffect(() => {
    refetchAllInstantEmails();
    // }, [status, searchTerm, refetch]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (deletedInstantEmail) {
      showToastMessage({
        type: 'success',
        title: 'Instant Email Deleted',
        description: '',
      });
      refetchAllInstantEmails();
    } else if (deletedInstantEmailFailed) {
      showToastMessage({
        type: 'error',
        title: 'Error',
        description: 'Oops! An error occured',
      });
    }
    // eslint-disable-next-line
  }, [deletingInstantEmail, deletedInstantEmail, deletedInstantEmailFailed]);

  useEffect(() => {
    if (allInstantEmailsFetched) {
      setTotalDocuments(getAllInstantEmailsData?.data?.totalPages * 10);
      setInstantEmailList(getAllInstantEmailsData?.data?.emails);
      setPageTotal(getAllInstantEmailsData?.data?.emails.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allInstantEmailsFetched, loadingAllInstantEmails, getAllInstantEmailsData]);

  useEffect(() => {
    if (pageTotal === 0) {
      setCurrentPage((prev) => (prev > 1 ? prev - 1 : 1));
    }
  }, [pageTotal]);

  useEffect(() => {
    if (emailDetails) setEmailStatus(emailDetails.x_processed_status);
  }, [emailDetails]);

  const menuOptions = {
    items: [
      {
        key: 'delete',
        label: (
          <div
            data-test="dropdown-delete" 
            onClick={() => {
              setImVisible({ id: 'deletingInstantEmailModal' });
            }}
          >
            <p className="text-red">Delete Email</p>
          </div>
        ),
      },
    ],
  };

  if (emailStatus === 'COMPLETED') {
    menuOptions.items.unshift(
      {
        key: 'preview',
        label: (
          <div data-test="dropdown-preview" onClick={() => updateImVisibility({ id: 'previewInstantEmailModal' })}>
            <p>Preview</p>
          </div>
        ),
      },
      {
        key: 'duplicate',
        label: (
          <div
            data-test="dropdown-duplicate"
            onClick={() => {
              cloneInstantEmailFn();
            }}
          >
              <p>Duplicate Email</p>
          </div>
        ),
      }
    );
  }  else if (emailStatus === 'DRAFT' || emailStatus === 'SCHEDULED') {
    menuOptions.items.unshift({
      key: 'edit',
      label: (
        <div className="option-edit" data-test="dropdown-edit">
          <Link to={`/instant-emails/drafts/${emailDetails._id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
            <p>Edit Email</p>
          </Link>
        </div>
      ),
    });
  }

  const columns = [
    {
      width: 400,
      render: (record) => (
        <div>
          {record.x_processed_status === 'DRAFT' || record.x_processed_status === 'SCHEDULED' ? (
            <Link to={`/instant-emails/drafts/${record._id}`}>
              <MdTypography variant={'h6Medium'}>{record.title}</MdTypography>
            </Link>
          ) : (
            <MdTypography  variant={'h6Medium'}>{record.title}</MdTypography>
           )} 
          <MdTypography variant={'smallTextRegular'} className="table-data">
            Launched {moment.utc(record.createdAt).format('ddd MMM, Do')}
          </MdTypography>
        </div>
      ),
    },
    {
      width: 150,
      render: (record) => {
        const statusMapping = {
          [xProcessedStatusEnum.failed]: {
            className: 'table-status-failed',
            text: 'Failed',
          },
          [xProcessedStatusEnum.queued]: {
            className: 'table-status-queued',
            text: 'Queued',
          },
          [xProcessedStatusEnum.draft]: {
            className: 'table-status-draft',
            text: 'Draft',
          },
          [xProcessedStatusEnum.processing]: {
            className: 'table-status-processing',
            text: 'Processing',
          },
          [xProcessedStatusEnum.scheduled]: {
            className: 'table-status',
            text: 'Scheduled',
          },
          [xProcessedStatusEnum.completed]: {
            className: 'table-status-completed',
            text: 'Completed',
          },
        };

        const status = statusMapping[record.x_processed_status];
        const { className, text } = status;

        return (
          <div>
            <MdTypography variant={'smallTextRegular'} className={className}>
              {text}
            </MdTypography>
          </div>
        );
      },
    },
    {
      width: 200,
      render: (record) => (
        <div>
          <MdTypography variant={'btnLight'} className="table-mode">
            Delivery Rate
          </MdTypography>
          <MdTypography variant={'h6Medium'} className="table-mode-per">
            {record.deliveryRate}%
          </MdTypography>
        </div>
      ),
    },
    {
      width: 200,
      render: (record) => (
        <div>
          <MdTypography variant={'btnLight'} className="table-mode">
            Open Rate
          </MdTypography>
          <MdTypography variant={'h6Medium'} className="table-mode-per">
            {record.openRate}%
          </MdTypography>
        </div>
      ),
    },
    {
      width: 200,
      render: (record) => (
        <div>
          <MdTypography variant={'btnLight'} className="table-mode">
            Click Rate
          </MdTypography>
          <MdTypography variant={'h6Medium'} className="table-mode-per">
            {record.clickRate}%
          </MdTypography>
        </div>
      ),
    },
    {
      width: 150,
      render: ({ _id: instantMailId }) => (
        <div
          onClick={(event) => {
            event.stopPropagation();
          }}
          className="detailed_report_button"
          data-test="detailed-report-button"
        >
          <Link to={`/emails/${instantMailId}/report`} state={{ page: 'Instant Email' }} data-test="test-Link">
            <MdGlobalButton id="grey_default" className="mx-2">
              <MdTypography variant={'captionMedium'} className="button-text">
                Detailed Report
              </MdTypography>
              <DetailedIcon />
            </MdGlobalButton>
          </Link>
        </div>
      ),
    },
    {
      width: 150,
      render: (emailDetails) => (
        <Dropdown
          menu={menuOptions}
          onClick={(event) => {
            event.stopPropagation();
          }}
           overlayStyle={{ width: '170px' }}
          
        >
          <img
            src={optionsMenu}
            alt="options icon"
            data-test="instant-email-dropdown"
            onMouseEnter={() => updateMailDetails(emailDetails)}
          />
        </Dropdown>
      ),
    },
  ];

  const deleteInstantEmailFunc = (mailId) => {
    deleteInstantEmail({ mailId }).unwrap().then().catch();
  };

  const onRow = (record) => {
    const url = `/instant-emails/drafts/${record._id}`;
    if (record.x_processed_status === 'COMPLETED') {
      return {
        style: { cursor: 'not-allowed !important' }, // Optionally change cursor to indicate disabled state
        onClick: (e) => {
          e.preventDefault(); // Prevent any default action
        },
      };
    } else {
      return {
        onClick: (e) => {
          if (e.target.nodeName === 'P') {
            return;
          }
          navigate(url);
        },
      };
    }
  };

 const rowClassName = (record) => {
   return record?.x_processed_status === 'COMPLETED' ? '' : 'table-row';
 };

  return (
    <>
      <DeleteModal
        open={imVisible?.id === 'deletingInstantEmailModal'}
        deleteFunction={() => {
          setImVisible({ id: '' });
          deleteInstantEmailFunc(emailDetails?._id);
        }}
        onCancel={() => {
          setImVisible({ id: '' });
        }}
        header="Instant Email"
        description="This email will be permanently deleted and cannot be recovered"
        deleteButtonDataTestId={'delete-instant-email'}
      />

      <div className="instant-email-table">
        <MdTabWithTable
          onClick={({ key }) => changeListView(key)}
          menuItems={menuItems}
          columns={columns}
          campaignList={instantEmailList}
          isDataLoading={fetchingAllInstantEmails || deletingInstantEmail}
          folderExtension
          onRow={onRow}
          rowClassName={rowClassName}
          pagination={{
            current: currentPage,
            total: totalDcuments,
            // pageSize: 10, // Default is 10 and it is actually not needed , pageSize / Limit can be controlled from what ever we pass in the query parameter
            onChange: (page) => {
              setCurrentPage(page);
              refetchAllInstantEmails({ page: page - 1 });
            },
          }}
          tableHeight={tableHeight}
        />
      </div>
    </>
  );
};

export default InstantEmailTableWrapper;
