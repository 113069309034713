import React from 'react';

export const MdAttach = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path
        d="M7.11654 10.1385C6.70821 10.1385 6.29404 9.98094 5.98487 9.67178C5.68154 9.36844 5.51238 8.96596 5.51238 8.54013C5.51238 8.11429 5.68154 7.70596 5.98487 7.40846L6.80736 6.58596C6.97652 6.41679 7.25652 6.41679 7.42569 6.58596C7.59486 6.75512 7.59486 7.03512 7.42569 7.20429L6.60321 8.02679C6.46321 8.16679 6.38738 8.34763 6.38738 8.54013C6.38738 8.73263 6.46321 8.91928 6.60321 9.05345C6.88904 9.33928 7.34988 9.33928 7.63572 9.05345L8.9307 7.75845C9.67153 7.01761 9.67153 5.81596 8.9307 5.07513C8.18987 4.33429 6.98821 4.33429 6.24738 5.07513L4.83569 6.48678C4.53819 6.78428 4.37487 7.17513 4.37487 7.58929C4.37487 8.00346 4.53819 8.40012 4.83569 8.69178C5.00486 8.86095 5.00486 9.14095 4.83569 9.31011C4.66652 9.47928 4.38652 9.47928 4.21736 9.31011C3.75652 8.84928 3.50571 8.23679 3.50571 7.58345C3.50571 6.93012 3.75652 6.31762 4.21736 5.85679L5.62905 4.44512C6.70822 3.36595 8.46987 3.36595 9.54903 4.44512C10.6282 5.52428 10.6282 7.28595 9.54903 8.36512L8.25405 9.66012C7.93905 9.98095 7.53071 10.1385 7.11654 10.1385Z"
        fill="#273DF2"
      />
      <path
        d="M8.74999 13.2707H5.24999C2.08249 13.2707 0.729156 11.9173 0.729156 8.74984V5.24984C0.729156 2.08234 2.08249 0.729004 5.24999 0.729004H8.74999C11.9175 0.729004 13.2708 2.08234 13.2708 5.24984V8.74984C13.2708 11.9173 11.9175 13.2707 8.74999 13.2707ZM5.24999 1.604C2.56082 1.604 1.60416 2.56067 1.60416 5.24984V8.74984C1.60416 11.439 2.56082 12.3957 5.24999 12.3957H8.74999C11.4392 12.3957 12.3958 11.439 12.3958 8.74984V5.24984C12.3958 2.56067 11.4392 1.604 8.74999 1.604H5.24999Z"
        fill="#273DF2"
      />
    </svg>
  );
};
