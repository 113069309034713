import { LoadingOutlined } from '@ant-design/icons';
import { Form, Spin } from 'antd';
import { useCallback, useEffect, useState, useContext } from 'react';
import { jobContext } from 'contexts/job';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  useEditInstantEmailMutation,
  useGetSaveGroupsMutation,
  useGetSingleInstantEmailQuery,
  useSaveDraftEmailMutation,
} from 'redux/featureApi/instantEmailApiSlice';
// import { SET_NEW_JOB } from 'contexts/job/reducer/types';
import { AppConstants } from 'utils/appConstants';
import Calendar from '../../asset/Icons/Calendar';
// import { CreateEmailModal } from '../../components/emmaccen/modals/CreateEmailModal';
import Scheduler from '../../components/emmaccen/modals/Scheduler';
import UploadCsvModal from '../../components/emmaccen/modals/instantEmailUploadCsvModal';
import MdGlobalButton from '../../components/global/Button';
import '../../styles/contactPage.scss';
import '../../styles/instantemails.scss';
import { showToastMessage } from '../../utils/Toast';
import { componentKeys, editActions, mailStatusTypes, jobStatus, growthBookId } from '../../utils/constants';
import InstantEmailTitle from 'components/Instant Emails/InstantEmailAction/InstantEmailTitle';
import InstantEmailSteps from 'components/Instant Emails/InstantEmailAction/InstantEmailSteps';
import { MdCreateEmailModal } from 'components/emmaccen/modals/MdCreateEmailModal';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { MailSendingInfoModal } from 'components/emmaccen/modals/MailSendingInfoModal';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from 'redux/featureSlices/authSlice';
import { useGetAllContactGroupsQuery } from 'redux/featureApi/contactApiSlice';

const InstantEmailAction = () => {
  const navigate = useNavigate();
  const { mailId } = useParams();
  const [imVisible, setImVisible] = useState({ id: '' });
  const [contactGroups, setContactGroups] = useState([]);
  const [instantEmailTitle, setInstantEmailTitle] = useState('');
  const [newInstantEmailTitle, setNewInstantEmailTitle] = useState(instantEmailTitle);
  const [fromName, setFromName] = useState('');
  const [emailSubject, setEmailSubject] = useState('');
  const [editInstantEmailTitle, setEditInstantEmailTitle] = useState(false);
  const [pageInitialized, setPageInitialized] = useState(false);
  const [completeStep, setCompleteStep] = useState({
    stepOne: false,
    stepTwo: false,
    stepThree: false,
    stepFour: false,
  });
  const [email, setEmail] = useState(useLocation().state?.email);
  // eslint-disable-next-line no-unused-vars
  const [subscribersCount, setSubscribersCount] = useState(0);
  const [openScheduler, setOpenScheduler] = useState(false);
  const { state } = useContext(jobContext);
  // this state is needed to know what was edited so that we
  // won't display loading state for all buttons dependent on the editEmail Mutation
  const [editAction, setEditAction] = useState(null);
  const [csvResult, setCsvResult] = useState([]);
  const [saveContactForm] = Form.useForm();
  const [fromAndReplyToForm] = Form.useForm();
  const [subjectForm] = Form.useForm();
  // eslint-disable-next-line no-unused-vars
  const [isUploadCompleted, setIsUploadCompleted] = useState(false);
  const [groupsAdded, setGroupsAdded] = useState(false);
  const [savedGroup, setSavedGroup] = useState([]);
  const [isFromAndReplyToValidated, setIsFromAndReplyToValidated] = useState(false);
  const [isValidSubject, setIsValidSubject] = useState(false);
  const disableEmail = useFeatureIsOn(growthBookId.disableEmail);
  const [showPrompt, setShowPrompt] = useState(false);
  const userDetails = useSelector(selectCurrentUser);

  const [
    SaveGroupsToInstantEmail,
    {
      isLoading: savedGroupsToInstantEmailsLoading,
      isSuccess: savedGroupsToInstantEmails,
      isError: savedGroupsToInstantEmailsFailed,
    },
  ] = useGetSaveGroupsMutation();

  const [editEmail, { isLoading: isEditingMail }] = useEditInstantEmailMutation();

  const [saveDraftEmail, { isLoading: savingDraftEmail }] = useSaveDraftEmailMutation();

  const {
    data: getSingleInstantEmailData,
    isFetching: fetchingSingleInstantEmailData,
    isSuccess: getSingleInstantEmailFetched,
    isError: getSingleInstantEmailFailed,
    refetch: refetchSingleInstantEmail,
  } = useGetSingleInstantEmailQuery({ mailId }, { skip: !mailId, refetchOnMountOrArgChange: true });

  const {
    data: getAllContactGroups,
    isFetching: fetchingContactGroups,
    isSuccess: fetchedContactGroups,
    refetch: refetchContactGroupsData,
  } = useGetAllContactGroupsQuery({}, { refetchOnMountOrArgChange: true });

  const saveToDraftLogic = () => {
    saveDraftEmail({ mailId, body: { status: mailStatusTypes.draft } })
      .unwrap()
      .then(() => {
        showToastMessage({
          type: 'success',
          title: '',
          description: 'Draft saved successfully',
        });
        navigate('/instant-emails');
      })
      .catch((error) => {
        console.error(error);
      });
  };
  function calculateTimeToSendEmails(threshold, totalUsers) {
    const remainingHours = Math.ceil(totalUsers / threshold);
    const currentTime = new Date();
    const targetTime = new Date(currentTime.getTime() + remainingHours * 60 * 60 * 1000);
    return targetTime;
  }
  const userEmailThreshold = userDetails?.hourlyEmailLimit;
  const estimatedDeliveryTime = calculateTimeToSendEmails(userEmailThreshold, subscribersCount);

  const editEmailMutationHandle = useCallback(
    (payload, action) => {
      const isScheduleAction = action === editActions.schedule;
      const isUpdateMailTitle = action === editActions.editTitle;
      const isUpdateMailFromAndReplyTo = action === editActions.editFromAndTo;
      const isUpdateMailSubject = action === editActions.editSubject;
      const isSendNowAction = action === editActions.sendNow;

      editEmail({
        mailId,
        body: { ...payload },
      })
        .unwrap()
        .then((resp) => {
          refetchSingleInstantEmail();
          isSendNowAction
            ? setShowPrompt(true)
            : showToastMessage({
                type: 'success',
                title: resp?.message || resp?.data?.message,
                description: '',
              });
          if (isUpdateMailFromAndReplyTo) {
            setCompleteStep((prev) => ({ ...prev, stepTwo: true }));
          }
          if (isUpdateMailSubject) {
            setCompleteStep((prev) => ({ ...prev, stepThree: true }));
          }
          if (isUpdateMailTitle) {
            setInstantEmailTitle(newInstantEmailTitle);
            setEditInstantEmailTitle(false);
          }

          setEditAction(null);
          // if (isSendNowAction) setShowPrompt(true);
          if (isScheduleAction) navigate(AppConstants.INSTANT_EMAILS);
        })
        .catch((err) => {
          const spamDetected = err?.data?.error.includes('spam');
          showToastMessage({
            type: 'error',
            title: spamDetected ? 'Spam Detected' : 'Oops',
            description: spamDetected ? err?.data?.error : err?.data?.error || 'An error occurred, please try again',
          });
          setCompleteStep((prev) => ({ ...prev, stepTwo: false }));
          setEditAction(null);
          console.error(err);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mailId]
  );

  const sumTotalCounts = (arr1, arr2) => {
    const result = arr1.filter((item) => arr2.includes(item?._id)).reduce((acc, curr) => acc + curr.totalContacts, 0);
    return result;
  };

  // USE EFFECTS
  /* eslint-disable */
  useEffect(() => {
    if (getSingleInstantEmailFetched) {
      const data = getSingleInstantEmailData?.data?.email;
      setInstantEmailTitle(data?.title);
      fromAndReplyToForm.setFieldsValue({
        from: data?.from,
        to: data?.to,
      });
      setFromName(data?.from);
      setIsFromAndReplyToValidated(false);

      if (data?.subject !== '') {
        subjectForm.setFieldsValue({
          subject: data?.subject,
        });
        setEmailSubject(data?.subject);
      } else {
        subjectForm.setFieldsValue({
          subject: 'Message to {{FIRST_NAME}}',
        });
        setEmailSubject('Message to {{FIRST_NAME}}');
      }
      setCompleteStep({
        stepOne: Boolean(data?.totalSubscribers),
        stepTwo: Boolean(data?.from && data?.to),
        stepThree: Boolean(data?.subject),
        stepFour: Boolean(data?.body || data?.strippoJuicedHtml),
      });

      setSubscribersCount(data?.totalSubscribers);

      const groups = data?.groups?.map((g) => g._id);
      saveContactForm.setFieldsValue({
        groups: groups ?? [],
      });

      setEmail(data);
      setPageInitialized(true);
    } else if (getSingleInstantEmailFailed) {
      showToastMessage({
        type: 'error',
        title: 'Error',
        description: 'Unable to fetch instant email data, Please refresh the page',
      });
      setPageInitialized(true);
    }
    if (fetchingSingleInstantEmailData) {
      subjectForm.setFieldsValue({
        subject: emailSubject,
      });
      setEmailSubject(emailSubject);
    }
  }, [fetchingSingleInstantEmailData]);

  // Update Upload completed state
  useEffect(() => {
    if (state.jobs[0]?.status === jobStatus.done) {
      setIsUploadCompleted(false);
    }
  }, [state.jobs[0]?.status]);

  useEffect(() => {
    if (savedGroupsToInstantEmails) {
      showToastMessage({
        type: 'success',
        title: 'Contact Import',
        description: 'Contact(s) import successful',
      });
      setCompleteStep((prev) => ({ ...prev, stepOne: true }));
    } else if (savedGroupsToInstantEmailsFailed) {
      showToastMessage({
        type: 'error',
        title: 'Error',
        description: 'Unable to add empty group',
      });
      setCompleteStep((prev) => ({ ...prev, stepOne: false }));
    }
  }, [savedGroupsToInstantEmails, savedGroupsToInstantEmailsFailed]);

  useEffect(() => {
    fetchGroups();
  }, [getSingleInstantEmailFetched, fetchingContactGroups]);

  useEffect(() => {
    fetchGroups();
  }, []);

  useEffect(() => {
    setNewInstantEmailTitle(instantEmailTitle);
  }, [instantEmailTitle]);

  useEffect(() => {
    if (savedGroupsToInstantEmails) {
      refetchSingleInstantEmail();
    }
  }, [savedGroupsToInstantEmails, refetchSingleInstantEmail]);

  // FUNCTIONS
  const handleSaveInstantEmailContact = (groups = []) => {
    // Check if any of the selected groups is empty
    const emptyGroupDetected = groups.some((id) => contactGroups.find((cg) => cg._id === id)?.totalContacts === 0);
    if (emptyGroupDetected) {
      return showToastMessage({
        type: 'error',
        title: 'Empty Group(s) Detected',
        description: 'Empty group(s) cannot be added to instant email',
      });
    }
    //  const savedGroupp = groups?.map((g) => g._id);

    SaveGroupsToInstantEmail({
      mailId,
      groups,
    })
      .unwrap()
      .then(async ({ data }) => {
        // const totalSum = sumTotalCounts(contactGroups, groups);
        getSingleInstantEmailData && setSubscribersCount(getSingleInstantEmailData?.data?.email?.totalSubscribers);
        // dispatch({
        //   type: SET_NEW_JOB,
        //   payload: {
        //     ...data,
        //     ID: data._id,
        //     // Add to job object so that we can persist modal data with LS even if user refreshes the app
        //   },
        // });
      })
      .catch((e) => {
        showToastMessage({
          type: 'error',
          title: 'Error',
          description: e.response?.data?.error || e.message || 'Unable to process your request',
        });
      });
  };

  const handleSaveFromAndReplyTo = ({ from, to }) => {
    setEditAction(editActions.editFromAndTo);
    editEmailMutationHandle({ from, to }, editActions.editFromAndTo);
  };

  const handleUpdateEmailSubject = ({ subject }) => {
    setEditAction(editActions.editSubject);
    editEmailMutationHandle({ subject }, editActions.editSubject);
  };

  const handleUpdateInstantEmailTitle = () => {
    if (newInstantEmailTitle.replace(/^\s+/, '').length < 1) {
      showToastMessage({
        type: 'error',
        title: 'Error',
        description: 'Please enter a valid title',
      });
      return;
    }

    setEditAction(editActions.editTitle);
    editEmailMutationHandle({ title: newInstantEmailTitle }, editActions.editTitle);
  };

  // TODO: EXTRACT INTO A REUSABLE FILE
  const fetchGroups = () => {
    if (getSingleInstantEmailFetched && fetchedContactGroups) {
      const savedGroups = getSingleInstantEmailData?.data?.email.groups?.map((g) => g._id);
      setSavedGroup(getSingleInstantEmailData?.data?.email.groups);
      const allGroups = getAllContactGroups?.groups;
      setContactGroups(allGroups);
      const totalSum = sumTotalCounts(allGroups, savedGroups);
      setSubscribersCount(getSingleInstantEmailData?.data?.email?.totalSubscribers);
      setCompleteStep((prev) => ({ ...prev, stepOne: Boolean(totalSum > 0) }));
    }
  };

  const onCsvUploadedSuccess = () => {
    refetchTotalSubscribers();
    setCompleteStep((prev) => ({ ...prev, stepOne: true }));
  };

  const onCsvUploadedError = () => {
    setCompleteStep((prev) => ({ ...prev, stepOne: false }));
  };

  const onSchedulerChange = (dateAsISOString) => {
    setOpenScheduler(false);
    setEditAction(editActions.schedule);
    editEmailMutationHandle({ scheduledFor: dateAsISOString, status: mailStatusTypes.scheduled }, editActions.schedule);
  };

  const onSchedularClose = () => {
    setOpenScheduler(false);
  };

  return (
    <div className="action-hero">
      {!pageInitialized && <Spin indicator={<LoadingOutlined />} />}
      {getSingleInstantEmailFetched && (
        <>
          <Scheduler visible={openScheduler} onClose={onSchedularClose} onChangeCallback={onSchedulerChange} />

          {/* <CreateEmailModal
            imVisible={imVisible}
            setImVisible={setImVisible}
            mailId={mailId}
            mailTitle={instantEmailTitle}
          /> */}
          <MdCreateEmailModal
            imVisible={imVisible}
            setImVisible={setImVisible}
            mailId={mailId}
            mailTitle={instantEmailTitle}
          />

          <UploadCsvModal
            csvResult={csvResult}
            setCsvResult={setCsvResult}
            uploadContactsTo={componentKeys.uploadContactsToInstantEmail}
            imVisible={imVisible}
            setImVisible={setImVisible}
            onSuccess={onCsvUploadedSuccess}
            onError={onCsvUploadedError}
          />

          <div className="action-hero-title">
            <InstantEmailTitle
              editAction={editAction}
              instantEmailTitle={instantEmailTitle}
              newInstantEmailTitle={newInstantEmailTitle}
              setNewInstantEmailTitle={setNewInstantEmailTitle}
              editInstantEmailTitle={editInstantEmailTitle}
              setEditInstantEmailTitle={setEditInstantEmailTitle}
              isEditingMail={isEditingMail}
              handleUpdateInstantEmailTitle={handleUpdateInstantEmailTitle}
            />
          </div>

          <div className="action-header">
            <InstantEmailSteps
              completeStep={completeStep}
              contactGroups={contactGroups}
              setContactGroups={setContactGroups}
              refetchSingleInstantEmail={refetchSingleInstantEmail}
              refetchContactGroupsData={refetchContactGroupsData}
              editAction={editAction}
              editEmail={editEmail}
              email={email}
              emailSubject={emailSubject}
              fromAndReplyToForm={fromAndReplyToForm}
              fromName={fromName}
              groupsAdded={groupsAdded}
              savedGroup={savedGroup}
              handleSaveFromAndReplyTo={handleSaveFromAndReplyTo}
              handleSaveInstantEmailContact={handleSaveInstantEmailContact}
              handleUpdateEmailSubject={handleUpdateEmailSubject}
              instantEmailTitle={instantEmailTitle}
              isEditingMail={isEditingMail}
              isValidSubject={isValidSubject}
              isFromAndReplyToValidated={isFromAndReplyToValidated}
              mailId={mailId}
              saveContactForm={saveContactForm}
              savedGroupsToInstantEmailsLoading={savedGroupsToInstantEmailsLoading}
              setFromName={setFromName}
              setGroupsAdded={setGroupsAdded}
              setImVisible={setImVisible}
              imVisible={imVisible}
              subscribersCount={subscribersCount}
              subjectForm={subjectForm}
              setEmailSubject={setEmailSubject}
              setIsFromAndReplyToValidated={setIsFromAndReplyToValidated}
              setIsValidSubject={setIsValidSubject}
              fetchingContactGroups={fetchingContactGroups}
            />
          </div>

          <div className="action-button-wrapper">
            <div className="save-to-drafts">
              <MdGlobalButton
                data-test="save-to-drafts-btn"
                id=""
                className="drafts"
                disabled={!(completeStep.stepOne || completeStep.stepTwo || completeStep.stepThree)}
                loading={false}
                onClick={() => saveToDraftLogic()}
              >
                {savingDraftEmail ? (
                  <>
                    Saving Drafts <LoadingOutlined />
                  </>
                ) : (
                  'Save to Drafts'
                )}
              </MdGlobalButton>
            </div>
            <div className="action-button">
              <MdGlobalButton
                data-test="schedule-btn-test"
                className="schedule"
                disabled={
                  disableEmail ||
                  !(completeStep.stepOne && completeStep.stepTwo && completeStep.stepThree && completeStep.stepFour)
                }
                loading={isEditingMail && editAction === editActions.schedule}
                onClick={() => setOpenScheduler(true)}
              >
                <div>
                  Schedule <Calendar />
                </div>
              </MdGlobalButton>
              <MdGlobalButton
                data-test="send-btn"
                id="primary_sm"
                className={`${
                  !(completeStep.stepOne && completeStep.stepTwo && completeStep.stepThree && completeStep.stepFour) &&
                  'button-opacity'
                }`}
                disabled={
                  disableEmail ||
                  !(completeStep.stepOne && completeStep.stepTwo && completeStep.stepThree && completeStep.stepFour)
                }
                loading={isEditingMail && editAction === editActions.sendNow}
                onClick={() => {
                  setEditAction(editActions.sendNow);
                  editEmailMutationHandle({ status: mailStatusTypes.completed }, editActions.sendNow);
                }}
              >
                Send Now
              </MdGlobalButton>
            </div>
          </div>
        </>
      )}
      {getSingleInstantEmailFailed && <></>}
      {showPrompt && (
        <MailSendingInfoModal
          imVisible={showPrompt}
          setImVisible={setShowPrompt}
          user={userDetails}
          estimatedDeliveryTime={estimatedDeliveryTime}
        />
      )}
    </div>
  );
};

export default InstantEmailAction;
