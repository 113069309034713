import { apiAuthSlice } from './apiAuthSlice';

export const campaignApiSlice = apiAuthSlice.enhanceEndpoints({}).injectEndpoints({
  endpoints: (builder) => ({
    getUserCampaigns: builder.query({
      query: (params) => ({
        url: `/campaigns`,
        method: 'GET',
      }),
      providesTags: ['CampaignList'],
    }),
    addNewCampaign: builder.mutation({
      query: (params) => ({
        url: `/campaigns`,
        method: 'POST',
        body: { ...params },
      }),
      invalidatesTags: ['CampaignList', 'ContactGroups'],
    }),
    getCampaignLandingPage: builder.query({
      query: (campaignId) => ({
        url: `/campaigns/${campaignId}/landing-page`,
        method: 'GET',
      }),
      providesTags: ['LandingPage'],
    }),
    editCampaignLandingPage: builder.mutation({
      query: ({ payload, campaignId }) => ({
        url: `/campaigns/${campaignId}/landing-page`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['LandingPage'],
    }),
    getSpecificCampaignData: builder.query({
      query: ({ campaignId = '', currentPage, currentLimit }) => ({
        url: `/campaigns/${campaignId}?page=${currentPage}&limit=${currentLimit}`,
        method: 'GET',
      }),
      // transformResponse: (response) => response?.data,
      providesTags: ['CampaignList'],
    }),
    addRecipientToCampaign: builder.mutation({
      query: ({ firstName, lastName, email, campaignId }) => ({
        url: `/campaigns/${campaignId}/user`,
        method: 'POST',
        body: { firstName, lastName, email },
      }),
      invalidatesTags: ['SpecificCampaignData', 'ContactGroups'],
    }),
    importContactGroupsToCampaign: builder.mutation({
      query: ({ groups, campaignId }) => ({
        url: `/campaigns/${campaignId}/contacts`,
        method: 'POST',
        body: { groups },
      }),
      invalidatesTags: ['SpecificCampaignData', 'CampaignList', 'ContactGroups'],
    }),
    importRecipientsCsvToCampaign: builder.mutation({
      query: ({ payload, campaignId }) => ({
        url: `campaigns/${campaignId}/contacts/import`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['SpecificCampaignData', 'ContactGroups'],
    }),
    deleteCampaignUser: builder.mutation({
      query: ({ userId, campaignId }) => ({
        url: `/campaigns/${campaignId}/${userId}/user`,
        method: 'DELETE',
      }),
      invalidatesTags: ['SpecificCampaignData'],
    }),
    getCampaignVariables: builder.query({
      query: (campaignId) => ({
        url: `/campaigns/${campaignId}/variables`,
        method: 'GET',
      }),
      providesTags: ['CampaignVariables'],
    }),
    addCampaignVariable: builder.mutation({
      query: ({ name, value, campaignId }) => ({
        url: `/campaigns/${campaignId}/variables`,
        method: 'PUT',
        body: { name, value },
      }),
      invalidatesTags: ['CampaignVariables'],
    }),
    deleteCampaignVariable: builder.mutation({
      query: ({ campaignId, name }) => ({
        url: `/campaigns/${campaignId}/variables?name=${name}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['CampaignVariables'],
    }),
    setCampaignStatus: builder.mutation({
      query: ({ campaignId }) => ({
        url: `/campaigns/${campaignId}/status`,
        method: 'PATCH',
      }),
      invalidatesTags: ['CampaignList'],
    }),
    editCampaignEmail: builder.mutation({
      query: ({ campaignId, payload }) => ({
        url: `/campaigns/${campaignId}/addmail`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['CampaignList'],
    }),
    editCampaignEmailConfigurations: builder.mutation({
      query: ({ campaignId, payload }) => ({
        url: `/campaigns/${campaignId}/email-configuration`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ({ id }) => [{ type: 'SpecificCampaignData', id }],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetUserCampaignsQuery,
  useGetSpecificCampaignDataQuery,
  useAddRecipientToCampaignMutation,
  useGetCampaignLandingPageQuery,
  useEditCampaignLandingPageMutation,
  useAddNewCampaignMutation,
  useImportContactGroupsToCampaignMutation,
  useImportRecipientsCsvToCampaignMutation,
  useDeleteCampaignUserMutation,
  useGetCampaignVariablesQuery,
  useAddCampaignVariableMutation,
  useDeleteCampaignVariableMutation,
  useSetCampaignStatusMutation,
  useEditCampaignEmailMutation,
  useEditCampaignEmailConfigurationsMutation,
} = campaignApiSlice;
