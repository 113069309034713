import LoadingScreen from '../components/LoadingScreen';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useAcceptWsInviteMutation } from 'redux/featureApi/teamCollabApiSlice';
import { logOutUser } from 'redux/featureSlices/authSlice';
import { errorMessage } from 'utils/getErrors';
import { showToastMessage } from 'utils/Toast';
/* eslint-disable */

const InviteToWorkspace = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useParams();

  const [acceptInvite] = useAcceptWsInviteMutation();

  const autoAcceptInvitation = async (id) => {
    dispatch(logOutUser());
    console.log('------------------');
    acceptInvite(id)
      .unwrap()
      .then((resp) =>
        showToastMessage({
          type: 'success',
          title: 'Success',
          description: resp?.data?.message ?? 'Joined workspace successfully',
        })
      )
      .catch((error) => {
        const errMsg = errorMessage(error);
        showToastMessage({
          type: 'error',
          title: 'Error',
          description: errMsg,
        });
      })
      .finally(() => {
        console.log('------------------');
        setTimeout(() => {
          navigate('/login');
        }, 4000);
      });
  };

  useEffect(() => {
    autoAcceptInvitation(token);
    return () => {};
  }, []);
  return <LoadingScreen />;
};

export default InviteToWorkspace;
