import axios from 'axios';
import Cookie from 'js-cookie';
import juice from 'juice';
import { useEffect, useState } from 'react';
import InstantEmailEditorActionsContainer from './InstantEmailEditorActionsContainer';
import InstantEmailEditorContent from './InstantEmailEditorContent';
import { grapesInit } from './config/grapesInit';
import './editor.scss';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { growthBookId } from 'utils/constants';

export const API_HOST = process.env.REACT_APP_API_URL;

const Editor = ({ mailId, query, updateEditEmailStatus, setEmailEditReqFailed, setIsEditingEmail }) => {
  const [editorState, setEditorState] = useState(null);

  const [mail, setMail] = useState({
    subject: '',
    body: '',
    // css: "",
    number: null,
    seconds: null,
    typeOfMail: 'maildrip-editor',
    emailInterval: '',
    attachments: [],
    order: 0,
  });

  const token = Cookie.get('token');

  const juiceInit = (html, css) => juice.inlineContent(html, css);
  const strippoFeature = useFeatureIsOn(growthBookId.strippoIE);

  // USE EFFECTS

  async function getAllAssets() {
    try {
      const response = await axios.get(`/wb/${mailId}/assets`);
      return response.data;
    } catch (error) {
      console.log({ error });
    }
  }

  useEffect(() => {
    let assets = [];
    (async function () {
      assets = await getAllAssets();

      const edit = await grapesInit(assets, mailId, token, strippoFeature);
      setEditorState(edit);
      const head = edit?.Canvas?.getDocument()?.head;
      if (head) {
        head.insertAdjacentHTML(
          'beforeend',
          `<link href="https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap" rel="stylesheet">`
        );
      }
      let I18nManager = edit.I18n;
      I18nManager.addMessages({
        en: {
          domComponents: {
            // overriding the default Table, Table Cell
            names: {
              table: 'Components',
              cell: 'Elements',
            },
          },
          selectorManager: {
            // overriding the default Classes, Selected
            label: 'Selector',
            selected: 'Element Inheriting',
          },
        },
      });

      edit?.on('load', function () {
        const prop = edit.StyleManager.getProperty('typography', 'font-family');
        // prop.set('options', [{ value: "'Inter', sans-serif", name: 'Inter' }]);
        var list = [];
        prop.set('list', list);
        prop.addOption({ id: 'Inter', label: 'Inter' });
        prop.set('list', list);
        prop.set('default', 'Inter');
        prop.set('defaults', 'Inter');

        // edit.StyleManager.render();

        //open import template modAL when a query === 'upload-code'
        const modal = edit.Modal;
        const uploadQuery = query.get('key');
        if (uploadQuery === 'upload-code') {
          const importClick = document.querySelector('.gjs-pn-btn.fa.fa-download');
          importClick.click();
          edit?.on('modal', (a, b, c) => {
            if (a.open && a.title.textContent === 'Import template') {
              modal.setTitle('<div>Import your Template [inline-html/css]</div>');
            }
          });
        }
      });

      edit?.on('storage:start:store', function (e) {
        // Update body in the mail variable declaration when storage request is started
        setMail((prevData) => ({ ...prevData, body: juiceInit(e.html, e.css) }));
      });

      edit?.on('asset:remove', async (e) => {
        await axios.delete(`/wb/${mailId}/assets?key=${e?.attributes?.setid}`);
      });
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="emmaccen">
        <div className="pt-8 grapesForMobileWidth">
          <InstantEmailEditorActionsContainer
            mailId={mailId}
            mail={mail}
            updateMailDetails={setMail}
            juice={juice}
            juiceInit={juiceInit}
            editorState={editorState}
            setIsEditingEmail={setIsEditingEmail}
            updateEditStatus={updateEditEmailStatus}
            setEmailEditReqFailed={setEmailEditReqFailed}
          />
          <InstantEmailEditorContent />
        </div>
      </div>
    </>
  );
};

export default Editor;
