import { apiAuthSlice } from './apiAuthSlice';

export const landingPageApiSlice = apiAuthSlice.enhanceEndpoints({}).injectEndpoints({
  endpoints: (builder) => ({
    getLandingPages: builder.query({
      query: () => ({
        url: `/landing-page/get-all-pages`,
        method: 'GET',
      }),
      providesTags: ['landingPages'],
    }),

    getSingleLandingPage: builder.query({
      query: (landingPageId) => ({
        url: `/landing-page/get-page/${landingPageId}`,
        method: 'GET',
      }),
      // transformResponse: (response) => response?.data,
      providesTags: ['specificLandingPage'],
    }),

    createLandingPage: builder.mutation({
      query: (payload) => ({
        url: `/landing-page/create-page`,
        method: 'POST',
        body: { ...payload },
      }),
      invalidatesTags: ['landingPages'],
    }),

    editLandingPage: builder.mutation({
      query: ({ landingPageId, body }) => ({
        url: `/landing-page/edit-page/${landingPageId}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['landingPages'],
    }),

    deleteLandingPage: builder.mutation({
      query: (pageId) => ({
        url: `/landing-page/${pageId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['landingPages'],
    }),

    toggleLandingPagePublishStatus: builder.mutation({
      query: ({ landingPageId, type  }) => ({
        url: `/landing-page/${landingPageId}/${type}`,
        method: 'PUT',
        body: { published: type === 'publish' ? true : false },
      }),
      invalidatesTags: ['landingPages'],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetLandingPagesQuery,
  useCreateLandingPageMutation,
  useGetSingleLandingPageQuery,
  useEditLandingPageMutation,
  useDeleteLandingPageMutation,
  useToggleLandingPagePublishStatusMutation,
} = landingPageApiSlice;
