import { CautionIcon } from 'asset/Icons/CautionIcon';
import { CloseIcon } from 'asset/Icons/CloseIcon';
import { useState } from 'react';
import { Link } from 'react-router-dom';

export const LowEmailCreditsCTA = () => {
  const [showBanner, setShowBanner] = useState(true);
  return (
    <>
      {showBanner && (
        <div className="low-email-credits-notf">
          <CautionIcon />
          <h3 className="warning-heading">Email Credit Alert: </h3>
          <p className="warning-text">
            Your email credit is running low – act promptly to top up and maintain uninterrupted access to our services
          </p>
          <Link to={'/payment/pay'} className="top-up">Top up credit</Link>
          <CloseIcon
            width={'16px'}
            className="close-icon"
            onClick={() => {
              setShowBanner(false);
            }}
          />
        </div>
      )}
    </>
  );
};
