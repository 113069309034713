import { Button, Modal } from 'antd';
import React from 'react';
import styles from '../../../styles/newContactPage.module.scss';
import { MdClose } from 'pages/ContactPage/Icons/MdClose';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { AppConstants } from 'utils/appConstants';

export const MailSendingInfoModal = ({ imVisible, setImVisible, user, estimatedDeliveryTime }) => {
  const navigate = useNavigate();
  moment.updateLocale('en', {
    relativeTime: {
      future: '%s',
    },
  });
  const time = moment(estimatedDeliveryTime).fromNow();

  return (
    <Modal
      open={imVisible}
      className={`emmaccen`}
      onCancel={() => {
        navigate(AppConstants.INSTANT_EMAILS);
        setImVisible(false);
      }}
      footer={null}
      closeIcon={<MdClose />}
      destroyOnClose={true}
      centered
    >
      <div className={styles['template-modal']} data-test="prompt-modal">
        <div>
          <h2 className={`${styles['template-title']} mx-auto`}>Attention {user?.fullname} !</h2>
          <h2 className={`${styles['template-text']} text-left`}>
            To ensure compliance with our data protection policy, your instant emails will be delivered in batches.{' '}
            {/* <br /> <br /> */}
            Based on your current threshold, we estimate complete delivery within{' '}
            <b style={{ color: '#273df2' }}>[{time}] </b>.
            <br /> <br /> Need your email threshold increased manually? Reach out to our support team{' '}
            <Link style={{ color: '#273df2' }} to={'https://chat.whatsapp.com/KBom4W94unnIzl2msfOhRv'} target="_blank">
              here
            </Link>{' '}
            <br /> <br />
            Thank you for your understanding and cooperation
          </h2>
        </div>
        <Button
          size={'large'}
          htmlType="submit"
          className={`${styles['template-btn']} mt-8`}
          onClick={() => navigate(AppConstants.INSTANT_EMAILS)}
          data-test="prompt-btn"
        >
          Okay, close
        </Button>
      </div>
    </Modal>
  );
};
