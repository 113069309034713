import React, { useState, useContext } from 'react';
import { Form, Input, Checkbox, Modal } from 'antd';
import { FORM_FIELDS } from '../../../contexts/joincampaign/reducer/types';
import { joinCampaignContext } from '../../../contexts/joincampaign';

function AddNewFormFieldsModal({ isShowingModal, closeModal }) {
  const [isEnabled, setIsEnabled] = useState(false);
  const [isRequired, setIsRequired] = useState(false);
  const [name, setName] = useState('');
  const [placeholder, setPlaceholder] = useState('');
  const { state, dispatch } = useContext(joinCampaignContext);
  const { formFields } = state;

  // console.log({ formFields });
  const [error, setError] = useState(false);

  const updateName = (event) => {
    const name = (event.target.value).trim();
    setName(name);
  };

  const updatePlaceholder = (event) => {
    const placeholder = (event.target.value).trim();
    setPlaceholder(placeholder);
  };
  const updateRequired = (event) => {
    setIsRequired(event.target.checked);
  };

  const handleSubmit = (e) => {
    e.preventDefault();


  const trimmedName = name.trim().replace(/\s+/g, ' ');
  const trimmedPlaceholder = placeholder.trim().replace(/\s+/g, ' ');
    if (trimmedName.length === 0 || trimmedPlaceholder.length === 0) {
      setError(true);
      return;
    }
  const newData = { name:trimmedName,placeholder:trimmedPlaceholder, required: isRequired, enabled: isEnabled, deletable: true };
  dispatch({ type: FORM_FIELDS, payload: [...formFields, newData] });
  resetStates();
  closeModal();
};
  const resetStates = () => {
    setError(false);
    setIsRequired(false);
    setIsEnabled(false);
    setName('');
    setPlaceholder('');
  };
  return (
    <>
      <Modal
        footer={null}
        style={{
          top: 20,
        }}
        open={isShowingModal}
        closable={true}
        onCancel={() => {
          resetStates();
          closeModal();
        }}
        destroyOnClose={true}
        className="add-fields-modal"
        centered
      >
        <Form className="flex flex-col">
          <>
            <label>Name</label>
            <Form.Item className="input-label">
              <Input
                data-test="name"
                type=""
                name="name"
                onChange={updateName}
                placeholder="Please enter input name"
                style={{ height: '35px' }}
              />
            
           {error && name.trim().length === 0 && (
              <div data-test="add-field-name-error" className="text-red-400 font-light text-base error-text">
                Name cannot be empty
              </div>
            )}
            {error && name.trim().length > 0 && name.trim() !== name && (
              <div data-test="add-field-name-error" className="text-red-400 font-light text-base error-text">
                Name cannot contain only spaces
              </div>
            )}
            </Form.Item>
          </>
          <>
            <label>Placeholder</label>
            <Form.Item className="input-label">
              <Input
                data-test="placeholder"
                type=""
                name="placeholder"
                onChange={updatePlaceholder}
                placeholder="Please enter input placeholder"
                style={{ height: '35px' }}
              />
            {error && placeholder.trim().length === 0 && (
              <div data-test="add-field-placeholder-error" className="text-red-400 font-light text-base error-text">
                Placeholder cannot be empty
              </div>
            )}
            {error && placeholder.trim().length > 0 && placeholder.trim() !== placeholder && (
              <div data-test="add-field-placeholder-error" className="text-red-400 font-light text-base error-text">
                Placeholder cannot contain only spaces
              </div>
            )}
            </Form.Item>
          </>
          <>
            <Form.Item className="input-label">
              <Checkbox className="" onClick={updateRequired}>
                Set as required
              </Checkbox>
            </Form.Item>
          </>
          <></>

          <button
            className="bg-black text-white p-3 mt-10 hover:text-zinc-200"
            onClick={handleSubmit}
            data-test="submit"
          >
            Submit
          </button>
        </Form>
      </Modal>
    </>
  );
}

export default AddNewFormFieldsModal;
