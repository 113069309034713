import React from 'react';
import { Input } from 'antd';
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import MdGlobalButton from '../Button';
import MdTypography from '../Typography';

export const MdCampaignHeader = ({
  title,
  description,
  headerIllustration,
  imLoading,
  setImLoading,
  setSearchValue,
  setImVisible,
}) => {
  return (
    <div className="campaignHeaderCont">
      <div className="textPart">
        <MdTypography variant={'h2'} className="campHTitle">
          {title}
        </MdTypography>
        <MdTypography variant={'btnLight'} className="campHDesc">
          {description}
        </MdTypography>
        <div className="flex rightSection">
          <Input
            placeholder="Search Campaigns"
            allowClear
            className="fade3 campHeaderInput"
            prefix={imLoading.id === 'searchingCampaigns' ? <LoadingOutlined /> : <SearchOutlined />}
            size="large"
            onChange={({ target: { value } }) => {
              setImLoading({ id: 'searchingCampaigns' });
              setSearchValue(value);
            }}
          />

          <MdGlobalButton
            id="primary_lg"
            className="mx-2"
            loading={false}
            dataTestId='create-btn'
            onClick={() => setImVisible({ id: 'createNewCampaign' })}
          >
            Create New
          </MdGlobalButton>
        </div>
      </div>
      <div className="imgPart">
        <div className="illustration">{headerIllustration}</div>
      </div>
    </div>
  );
};
