import React from 'react';

import { Button, Form, Input, Modal, Select } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { selectAddNewTeamMember, toggleAddNewTeamMember } from '../../../../redux/featureSlices/uiSlice';
import { useGetUserCampaignsQuery } from '../../../../redux/featureApi/campaignApiSlice';
import { showToastMessage } from '../../../../utils/Toast';
import { errorMessage } from '../../../../utils/getErrors';
import { containValidEmail } from '../../../../utils/formRules';
import { useShareWorkspaceMutation } from '../../../../redux/featureApi/teamCollabApiSlice';

const AddTeamMemberModal = ({ workspace }) => {
  const [addTeamMemberForm] = Form.useForm();
  const { data: campaignList, isLoading: isCampaignLoading } = useGetUserCampaignsQuery(
    {},
    { refetchOnMountOrArgChange: true }
  );
  const [shareWorkspace, { isLoading }] = useShareWorkspaceMutation();

  const modalState = useSelector(selectAddNewTeamMember);
  const dispatch = useDispatch();

  const { Option } = Select;

  const onFinish = (values) => {
    shareWorkspace(values)
      .then(({ data }) => {
        console.log({ data });
        showToastMessage({
          type: 'success',
          title: 'Success',
          description: `${data.message}`,
        });
      })
      .catch((err) => {
        const errMsg = errorMessage(err);
        showToastMessage({
          type: 'error',
          title: 'Error',
          description: errMsg,
        });
      })
      .finally(() => {
        addTeamMemberForm.resetFields();
        dispatch(toggleAddNewTeamMember());
      });
  };

  const closeModal = () => {
    dispatch(toggleAddNewTeamMember());
    addTeamMemberForm.resetFields();
  };

  return (
    <Modal
      className="emmaccen"
      open={modalState}
      // onOk={() => handleContactAddition()}
      onCancel={() => {
        // setImVisible({ id: '' });
      }}
      afterClose={() => {
        // setImVisible({ id: '' });
      }}
      destroyOnClose={true}
      footer={null}
      maskClosable={false}
      centered
      closable={false}
    >
      <div className="addNewTeamMember">
        <h2 className="text-center title mb-4">{`Invite team members to ${workspace?.workspaceName}`}</h2>
        <Form
          form={addTeamMemberForm}
          layout="vertical"
          onFinish={onFinish}
          initialValues={{
            note: 'A kind invite to collaborate on our campaigns',
          }}
          onFinishFailed={() => null}
          autoComplete="off"
          size="large"
        >
          <Form.Item label="Email" name="email" required rules={[...containValidEmail]}>
            <Input placeholder="example@abc.com" type="email" />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'At least one campaign must be selected',
              },
            ]}
            label="Select Campaigns to share"
            name="campaign"
          >
            <Select
              mode="tags"
              loading={isCampaignLoading}
              style={{ width: '100%', marginBottom: '20px' }}
              placeholder="Select campaign"
            >
              {campaignList?.data?.campaigns?.map((camp, i) => (
                <Option key={i} value={camp._id}>
                  {camp.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Note" name="note" requiredMark="optional">
            <Input.TextArea autoSize={{ minRows: 3, maxRows: 5 }} />
          </Form.Item>
          <div className="emmaccen">
            <div className="flex-space-btw actionBtns">
              <Button
                size={'large'}
                type=""
                onClick={closeModal}
                // icon={<PlusOutlined />}
              >
                Back
              </Button>
              <Button
                size={'large'}
                type="primary"
                htmlType="submit"
                // icon={<PlusOutlined />}
                loading={isLoading}
                onClick={() => {}}
              >
                Done
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

AddTeamMemberModal.propTypes = {};

export default AddTeamMemberModal;
