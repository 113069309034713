import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const useNoAuth = (callback = () => {}) => {
  const [userAuth, setUserAuth] = useState(false);
  const navigate = useNavigate();
  const token = Cookies.get('token');
  useEffect(() => {
    if (token) {
      setUserAuth(true);
      navigate('/dashboard');
    }
    callback();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return userAuth;
};

export default useNoAuth;
