import React, { useContext } from 'react';
import { Button, Modal } from 'antd';
import { jobStatus } from '../../../../utils/constants';
import { SET_JOBS } from '../../../../contexts/job/reducer/types';
import { jobContext } from '../../../../contexts/job';
import { thousandFormatter } from '../../../../utils/thousandFormatter';
import { getSingleCampaignInformation } from '../../../../utils/service';
import { modalsContext } from '../../../../contexts/modal';
import { OPEN_MODAL } from '../../../../contexts/modal/reducer/types';

const ContactUploadStats = () => {
  const { state, dispatch } = useContext(jobContext);
  const { dispatch: modalDispatch } = useContext(modalsContext);

  const closeModal = (jobID) => {
    // on click of the close button, filter out the completed job
    // and close the modal
    dispatch({ type: SET_JOBS, payload: state.jobs.filter((j) => j.ID !== jobID) });
    if (localStorage.getItem('previousJobs')) {
      localStorage.setItem('previousJobs', JSON.stringify(state.jobs.filter((j) => j.ID !== jobID)));
    }
  };

  const showTurnOnCampaignNotf = async (job) => {
    // Optimistic rendering
    if (job.campaign) {
      const { campaign } = job;
      try {
        const { data } = await getSingleCampaignInformation(campaign.props.campaignId);
        // If campaignStatus is false
        if (data.data.status === false) {
          modalDispatch({ type: OPEN_MODAL, payload: campaign.id });
        }
      } catch (error) {
        console.log({ error });
      }
    }
  };
  return (
    <>
      {state.jobs
        .filter((job) => job.status === jobStatus.done)
        .map((job, index) => {
          return (
            <React.Fragment key={index}>
              <Modal
                wrapClassName="emmaccen"
                closable={false}
                footer={null}
                open={true}
                centered={true}
                // onCancel={() => closeModal(job.ID)}
              >
                <div className="contact_upload_stats">
                  <h2 className="text-center title">Upload Stats</h2>
                  <div className="upload_info">
                    <div className="total_upd">
                      <h2>{thousandFormatter(job.total)}</h2>
                      <p>Total Upload</p>
                    </div>
                    <div className="success_upd">
                      <h2>{thousandFormatter(job.successful_uploads)}</h2>
                      <p>Successful </p>
                    </div>
                    <div className="failed_upd">
                      <h2>{thousandFormatter(job.failed_uploads)}</h2>
                      <p>Failed</p>
                    </div>
                  </div>
                  <div className="insight_stats ">
                    <div>
                      <div className="flex items-center items">
                        <span className="warningColorBg "></span>
                        <p>Duplicates: </p>
                        <h3 className="title">{thousandFormatter(job.stats.duplicates)}</h3>
                      </div>
                      <div className="flex items-center items">
                        <span className="successColorLightBg "></span>
                        <p>No Required Parameters: </p>
                        <h3 className="title">{thousandFormatter(job.stats.no_required_parameters)}</h3>
                      </div>
                    </div>
                    <div>
                      <div className="flex items-center items">
                        <span className="skyColorBg "></span>
                        <p>Invalid Emails: </p>
                        <h3 className="title">{thousandFormatter(job.stats.invalid_emails)}</h3>
                      </div>
                    </div>
                  </div>
                  <div className="close_btn">
                    <Button
                      size={'large'}
                      type="primary"
                      onClick={async () => {
                        showTurnOnCampaignNotf(job);
                        closeModal(job.ID);
                      }}
                    >
                      Close
                    </Button>
                  </div>
                </div>
              </Modal>
            </React.Fragment>
          );
        })}
    </>
  );
};

ContactUploadStats.propTypes = {};

export default ContactUploadStats;
