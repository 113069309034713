import { Modal } from 'antd';

const PreviewTemplate = ({ title, visible, icon, setVisible, temp }) => {
  const cssAppliedContent = (body) => `
    <div>
      <style>
       ${temp.css}
      </style>
      ${body}
    <div>
    `;
  return (
    <Modal
      title={title}
      centered
      open={visible}
      footer={null}
      onCancel={setVisible}
      width={1000}
      style={{ margin: '20px 0' }}
    >
      <div>
        <div dangerouslySetInnerHTML={{ __html: cssAppliedContent(temp.html) }}></div>
        {/* <img src={icon} alt="preview_img" width="100%" height="100%" /> */}
      </div>
    </Modal>
  );
};

export default PreviewTemplate;
