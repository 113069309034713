import React, { useRef, useState } from 'react';
import styles from '../../styles/optInPage.module.scss';
import { Input, message, Switch, Upload, ColorPicker } from 'antd';
import { FacebookIcon } from '../../asset/Icons/FacebookIcon';
import { InstagramIcon } from '../../asset/Icons/InstagramIcon';
import { TwitterIcon } from '../../asset/Icons/TwitterIcon';
import { LinkedinIcon } from '../../asset/Icons/LinkedinIcon';
import { Youtube } from '../../asset/Icons/Youtube';
import TiktokLogo from 'asset/Icons/TiktokLogo';
import axios from 'axios';
import AddFormFieldModal from './components/AddFormFieldModal';
import { Maildrip001 } from './Templates/Maildrip001';
import FormItem from 'antd/es/form/FormItem';
import { FormFieldItemLP } from './components/FormFieldItemLP';
import { shouldHaveHttps, containValidEmail } from 'utils/formRules';

const { Dragger } = Upload;

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const Design = ({
  file,
  setFile,
  backgroundColor,
  heroFile,
  setHeroFile,
  heroErrorMessage,
  setHeroErrorMessage,
  errorMessage,
  setErrorMessage,
  setBackgroundColor,
  bannerColor,
  setBannerColor,
  footerColor,
  setFooterColor,
  buttonColor,
  setButtonColor,
  buttonTitle,
  setButtonTitle,
  editableStr,
  setEditableStr,
  editableText,
  setEditableText,
  setFb,
  fb,
  setInsta,
  insta,
  setTweet,
  tweet,
  linked,
  setLinked,
  fbLink,
  redirectLink,
  tweetLink,
  instaLink,
  linkedLink,
  setFbLink,
  setTweetLink,
  setInstaLink,
  setLinkedLink,
  setRedirectLink,
  formFields,
  templateId,
  defaultFields,
  youLink,
  setYouLink,
  youtub,
  setYoutub,
  tikLink,
  setTikLink,
  tiktok,
  setTiktok,
  businessMail,
  phoneNumber,
  setBusinessMail,
  setPhoneNumber,
  businessMailLink,
  phoneNumberLink,
  setBusinessMailLink,
  setPhoneNumberLink,
}) => {
  const bannerImgRef = useRef();
  const [showingAddNewModal, setShowingAddNewModal] = useState(false);
  const beforeUpload = (file) => {
    const isLt2M = file.size / 1024 / 1024 < 12;
    if (!isLt2M) {
      message.error('Image must smaller than 12MB!');
      setErrorMessage('Max size: 12mb');
      return Upload.LIST_IGNORE;
    }
    return true;
  };

  const beforeHeroUpload = (heroFile) => {
    const isLt2M = heroFile.size / 1024 / 1024 < 12;
    if (!isLt2M) {
      message.error('Image must smaller than 12MB!');
      setHeroErrorMessage('Max size: 12mb');
      return Upload.LIST_IGNORE;
    }
    return true;
  };
  const customRequest = async ({ file, onSuccess, onError }) => {
    // const id = String(Date.now() + Math.random() * 1000);
    const formData = new FormData();
    formData.append('file', file);

    const data = await axios.post(`/opt-in-pages/image-upload`, formData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    onSuccess(data);
  };
  const [redirectError, setRedirectError] = useState();
  const updateRedirectURl = (value) => {
    setRedirectError('');console.log(shouldHaveHttps[0].pattern.test(value),value);
    setRedirectLink(value);
    if (shouldHaveHttps[0].pattern.test(value)===true ) {     
     
      setRedirectLink(value);
      setRedirectError('');
    } else if (shouldHaveHttps[0].pattern.test(value) === false) {
      setRedirectError("url must be in this format: 'https://example.com'");
    }
  };

  const templateProps = {
    bannerColor,
    backgroundColor,
    file,
    heroFile,
    setEditableStr,
    editableStr,
    setEditableText,
    editableText,
    formFields,
    redirectLink,
    buttonColor,
    buttonTitle,
    footerColor,
    fbLink,
    fb,
    tweet,
    tweetLink,
    insta,
    instaLink,
    linked,
    linkedLink,
    defaultFields,
    youLink,
    youtub,
    tiktok,
    tikLink,
    businessMail,
    phoneNumber,
    businessMailLink,
    phoneNumberLink,
  };

  const props = {
    name: 'file',
    multiple: false,
    customRequest,
    onChange(info) {
      const { status, response, name } = info.file;
      if (status !== 'uploading') {
        setFile(info);
      }
      if (status === 'done') {
        setFile({ src: response?.data?.data?.imageUrl, fileName: name });
        setErrorMessage(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        setErrorMessage(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      // console.log('Dropped files', e.dataTransfer.files);
    },
  };

  const prop = {
    name: 'file',
    multiple: false,
    customRequest,
    onChange(info) {
      const { status, response, name } = info.file;
      if (status !== 'uploading') {
        setHeroFile(info);
      }
      if (status === 'done') {
        setHeroFile({ src: response?.data?.data?.imageUrl, fileName: name });
        setHeroErrorMessage(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        setHeroErrorMessage(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      // console.log('Dropped files', e.dataTransfer.files);
    },
  };

  const [mailError, setMailError] = useState();

  const updateMail = (value) => {
    setMailError('');
    setBusinessMailLink(value);
    if (containValidEmail[0].pattern.test(value)) {
      setMailError('');
      setBusinessMailLink(value);
    } else {
      setMailError('Please input a valid email e.g example@gmail.com');
    }
  };

  const acceptedFileTypes = '.jpeg, .jpg, .png, .svg';

  return (
    <div className={styles.edit_container}>
      <div className={styles.first_child}>
        <p>Logo</p>
        <Dragger
          multiple={false}
          beforeUpload={beforeUpload}
          accept={acceptedFileTypes}
          className={styles.dragger}
          {...props}
          maxCount={1}
        >
          <p className={styles.ant_upload_text}>
            Drag & drop image or <span className={styles.color}>select file</span>.
          </p>
        </Dragger>
        <p style={{ fontSize: '12px', marginTop: '.5rem', color: '#717171' }} className={styles.ant_upload_text}>
          {props.status === 'error' && errorMessage}
        </p>
        <div className={styles.color_box}>
          <div className={styles.input_container}>
            <p>Background colour</p>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div type="color" disabled className={styles.first_child_input}>
                {backgroundColor}
              </div>
              <ColorPicker
                value={backgroundColor}
                data-test="opt-in-page-bg-color"
                onChange={(inputString) => setBackgroundColor(inputString)}
                showText
                className={styles.input_color}
              />
            </div>
          </div>
        </div>
        <div className={styles.color_box}>
          <div className={styles.input_container}>
            <p>Banner colour</p>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div
                type="color"
                disabled
                data-test="opt-in-page-banner-color-input"
                className={styles.first_child_input}
              >
                {bannerColor}
              </div>
              <ColorPicker
                value={bannerColor}
                data-test="opt-in-page-banner-color"
                onChange={(bannerString) => setBannerColor(bannerString)}
                showText
                className={styles.input_color}
              />
            </div>
          </div>
        </div>
        <div className={styles.color_box}>
          <div className={styles.input_container}>
            <p>Footer colour</p>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div
                type="color"
                disabled
                data-test="opt-in-page-footer-color-input"
                className={styles.first_child_input}
              >
                {footerColor}
              </div>
              <ColorPicker
                data-test="opt-in-page-footer-color-input"
                value={footerColor}
                onChange={(footerString) => setFooterColor(footerString)}
                showText
                className={styles.input_color}
              />
            </div>
          </div>
        </div>
        <div className={styles.hero_container}></div>
        <p className={styles.hero_container_text}>Hero Image</p>
        <Dragger
          className={styles.dragger}
          accept={acceptedFileTypes}
          beforeUpload={beforeHeroUpload}
          {...prop}
          ref={bannerImgRef}
          maxCount={1}
        >
          <p className={styles.ant_upload_text}>
            Drag & drop image or <span className={styles.color}>select file</span>.
          </p>
        </Dragger>
        <p style={{ fontSize: '12px', marginTop: '.5rem', color: '#717171' }} className={styles.ant_upload_text}>
          {prop.status === 'error' && heroErrorMessage}
        </p>
        <div className={styles.hero_container}>
          <p className={styles.hero_container_text}>Form Fields</p>
          <div data-test="field-input">
            {formFields.map((item, index) => (
              <FormItem key={index} defaultChecked={item.enabled} disabled={item.enabled} title={item.name}>
                <FormFieldItemLP
                  placeholder={item.placeholder}
                  name={item.name}
                  required={item.required}
                  deletable={item.deletable}
                  // enabled={item.enabled}
                />
              </FormItem>
            ))}
          </div>
          <div
            className={`${styles.hero_container_text} flex justify-center pointer`}
            onClick={() => setShowingAddNewModal(true)}
          >
            {' '}
            + Add New Field
          </div>
        </div>
        <div className={styles.hero_container}>
          <p className={styles.hero_container_text}>Button</p>
          <label className={styles.label}>Title</label>
          <Input
            className={styles.hero_input}
            value={buttonTitle}
            onChange={(e) => setButtonTitle(e.target.value)}
            placeholder="Enter CTA"
            data-test="cta-button-input"
          />
          <label className={styles.label}>Redirect link (optional)</label>
          <Input
            className={styles.hero_input}
            value={redirectLink || 'https://'}
            onChange={(e) => updateRedirectURl(e.target.value)}
            placeholder="Enter link"
            data-test="redirect-input"
          />
          {redirectError && (
            <p data-test="redirect-error" className="text-red mt-2 mb-2">
              {redirectError}
            </p>
          )}
          <div className={styles.color_box}>
            <div className={styles.input_container}>
              <p>Button colour</p>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div type="color" disabled className={styles.first_child_input}>
                  {buttonColor}
                </div>
                <ColorPicker
                  value={buttonColor}
                  onChange={(buttonString) => setButtonColor(buttonString)}
                  showText
                  className={styles.input_color}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.hero_container}>
          <p className={styles.hero_container_text}>Footer</p>
          <p>Business Mail</p>
          <div>
            <div className={styles.social_media_switch_container}>
              <Input
                style={{ width: '233px', marginRight: '1.5rem' }}
                placeholder="Business mail"
                value={businessMailLink || ''}
                onChange={(e) => updateMail(e.target.value)}
                disabled={!businessMail}
                data-test="footer-mail-input"
              />
              {mailError && <p className="text-red mt-2 mb-2">{mailError}</p>}
              <Switch
                onChange={() => {
                  setBusinessMail(!businessMail);
                }}
                checked={businessMail}
                data-test="footer-mail"
              />
            </div>
          </div>
          <p>Phone number</p>
          <div>
            <div className={styles.social_media_switch_container}>
              <Input
                style={{ width: '233px', marginRight: '1.5rem' }}
                placeholder="Phone number"
                value={phoneNumberLink || ''}
                onChange={(e) => setPhoneNumberLink(e.target.value)}
                disabled={!phoneNumber}
                data-test="footer-phone-input"
              />
              <Switch
                onChange={() => {
                  setPhoneNumber(!phoneNumber);
                }}
                data-test="footer-phone"
                checked={phoneNumber}
              />
            </div>
          </div>
          <p>Social Platforms</p>
          <div>
            <div className={styles.social_media_switch_container}>
              <Input
                style={{ width: '233px', marginRight: '1.5rem' }}
                addonBefore={<FacebookIcon />}
                placeholder="Enter profile link address"
                value={fbLink || 'https://'}
                onChange={(e) => setFbLink(e.target.value)}
                disabled={!fb}
                data-test="footer-fb-input"
              />
              <Switch
                onChange={() => {
                  setFb(!fb);
                }}
                checked={fb}
                data-test="footer-fb"
              />
            </div>

            <div className={styles.social_media_switch_container}>
              <Input
                style={{ width: '233px', marginRight: '1.5rem' }}
                addonBefore={<InstagramIcon />}
                placeholder="Enter profile link address"
                value={instaLink || 'https://'}
                onChange={(e) => setInstaLink(e.target.value)}
                disabled={!insta}
                data-test="footer-insta-input"
              />
              <Switch
                onChange={() => {
                  setInsta(!insta);
                }}
                checked={insta}
                data-test="footer-insta"
              />
            </div>
            <div className={styles.social_media_switch_container}>
              <Input
                style={{ width: '233px', marginRight: '1.5rem' }}
                addonBefore={<TwitterIcon />}
                placeholder="Enter profile link address"
                value={tweetLink || 'https://'}
                onChange={(e) => setTweetLink(e.target.value)}
                data-test="footer-tweet-input"
                disabled={!tweet}
              />
              <Switch
                data-test="footer-tweet"
                onChange={() => {
                  setTweet(!tweet);
                }}
                className={styles.switch}
                checked={tweet}
              />
            </div>
            <div className={styles.social_media_switch_container}>
              <Input
                style={{ width: '233px', marginRight: '1.5rem' }}
                addonBefore={<LinkedinIcon />}
                placeholder="Enter profile link address"
                value={linkedLink || 'https://'}
                onChange={(e) => setLinkedLink(e.target.value)}
                disabled={!linked}
                data-test="footer-link-input"
              />
              <Switch
                onChange={() => {
                  setLinked(!linked);
                }}
                data-test="footer-link"
                checked={linked}
              />
            </div>

            <div className={styles.social_media_switch_container}>
              <Input
                style={{ width: '233px', marginRight: '1.5rem' }}
                addonBefore={<Youtube />}
                placeholder="Enter profile link address"
                value={youLink || 'https://'}
                onChange={(e) => setYouLink(e.target.value)}
                disabled={!youtub}
                data-test="footer-yb-input"
              />
              <Switch
                onChange={() => {
                  setYoutub(!youtub);
                }}
                checked={youtub}
                data-test="footer-yb"
              />
            </div>

            <div className={styles.social_media_switch_container}>
              <Input
                style={{ width: '233px', marginRight: '1.5rem' }}
                addonBefore={<TiktokLogo />}
                placeholder="Enter profile link address"
                value={tikLink || 'https://'}
                onChange={(e) => setTikLink(e.target.value)}
                disabled={!tiktok}
                data-test="footer-tik-input"
              />
              <Switch
                onChange={() => {
                  setTiktok(!tiktok);
                }}
                checked={tiktok}
                data-test="footer-tik"
              />
            </div>
          </div>
        </div>
      </div>
      {templateId === '1' ? <Maildrip001 props={templateProps} /> : <></>}
      {showingAddNewModal && (
        <AddFormFieldModal isShowingModal={showingAddNewModal} closeModal={() => setShowingAddNewModal(false)} />
      )}
    </div>
  );
};

export default Design;
