import { Button, Form, Modal, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useModifyContactGroupMutation } from '../../redux/featureApi/contactApiSlice';
import { fetchCampaigns } from '../../utils/service';
import { showToastMessage } from '../../utils/Toast';
import { MdTypography, MdGlobalButton } from 'components/global';
import styles from '../../styles/newContactPage.module.scss';
import { MdClose } from './Icons/MdClose';
import { MdArrowDown } from './Icons/MdArrowDown';

export const MdAddToGroup = ({
  imVisible,
  setImVisible,
  contactGroups,
  currentGroup,
  multiselectionData,
  addToGroup,
}) => {
  const [imLoading, setImLoading] = useState({ id: '' });
  const [campaginList, setCampaignList] = useState([]);
  const { Option } = Select;
  const [contactForm] = Form.useForm();

  // console.log({ addToGroup, multiselectionData, currentGroup });

  //modify contact group with rtk
  const [
    modifyContactGroup,
    {
      isLoading: modifyingContactGroup,
      isSuccess: modifiedContactGroup,
      isError: modifyConntactGroupFailed,
      error: modifyContactGroupError,
    },
  ] = useModifyContactGroupMutation();

  const fetchAllCampaigns = () => {
    setImLoading({ id: 'loadingCampaigns' });
    fetchCampaigns(
      '',
      '',
      {
        cancelLoading: () => setImLoading({ id: '' }),
      },
      setCampaignList
    );
  };

  const onSubmitContactDetail = ({ campaignId = '', groups = [] }) => {
    modifyContactGroup({
      groupId: currentGroup._id,
      contactData: { contacts: multiselectionData, groups, campaignId },
    });
  };

  useEffect(() => {
    if (modifiedContactGroup) {
      let description = '';
      if (addToGroup) {
        description = 'Contact selections added to group(s) successfully';
      } else {
        description = 'Contact selections added to campaign successfully';
      }
      showToastMessage({
        type: 'success',
        title: 'Contact(s)',
        description,
      });
      setImLoading({ id: '' });
      setImVisible({ id: '' });
    } else if (modifyConntactGroupFailed) {
      showToastMessage({
        type: 'error',
        title: 'Contact(s)',
        description: 'Failed to add contact(s) to campaign or group(s)',
      });
      setImLoading({ id: '' });
      setImVisible({ id: '' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modifyingContactGroup, modifiedContactGroup, modifyConntactGroupFailed, modifyContactGroupError, setImVisible]);

  useEffect(() => {
    contactForm.resetFields();
  }, [imVisible, contactForm]);
  return (
    <Modal
      className={`emmaccen ${styles['contact-modal']}`}
      open={imVisible.id === 'addToCampaignOrGroup'}
      onCancel={() => {
        setImVisible({ id: '' });
      }}
      footer={null}
      maskClosable={false}
      closable={false}
      afterClose={() => {
        setImVisible({ id: '' });
      }}
      destroyOnClose={true}
      closeIcon={<MdClose />}
      centered
    >
      <div className={styles['addNewContactModal']}>
        <MdTypography variant={'h5Medium'} className="text-center title mb-8">
          Edit Contact
        </MdTypography>
        <Form
          form={contactForm}
          onFinish={(data) => onSubmitContactDetail(data)}
          onFinishFailed={() => null}
          autoComplete="off"
          size="large"
          layout="vertical"
        >
          {addToGroup ? (
            <Form.Item
              name="groups"
              label="Add to Group"
              rules={[{ required: true, message: 'Select atleast one group' }]}
            >
              <Select
                mode="multiple"
                style={{ width: '100%' }}
                placeholder="Select Group"
                onChange={(e) => console.log(e)}
                optionLabelProp="label"
                filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
              >
                {contactGroups?.map((data) => {
                  return (
                    <Option key={data._id ?? data.title} value={data._id} label={data.title}>
                      <div className="demo-option-label-item">{data.title}</div>
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          ) : (
            <Form.Item
              name="campaignId"
              label="Add to Campaign"
              rules={[{ required: true, message: 'Select atleast one campaign' }]}
            >
              <Select
                onFocus={() => fetchAllCampaigns()}
                loading={imLoading.id === 'loadingCampaigns'}
                showSearch
                placeholder="Select campaign"
                optionFilterProp="children"
                // onChange={onChange}
                // onSearch={onSearch}
                suffixIcon={<MdArrowDown />}
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {campaginList.map((camp, i) => (
                  <Option key={i} value={camp._id}>
                    {camp.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}

          <div className="emmaccen">
            <div className="flex-space-btw actionBtns">
              <Button
                size={'large'}
                type=""
                onClick={() => setImVisible({ id: '' })}
                className={styles['import-btn']}
                style={{ color: '#1C1C1C' }}
              >
                Back
              </Button>
              <MdGlobalButton
                size={'large'}
                type="primary"
                htmlType="submit"
                loading={modifyingContactGroup}
                className={`${styles['contact-btn']} mt-0`}
              >
                Done
              </MdGlobalButton>
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );
};
