const InstantEmailEditorContent = () => {
  return (
    <div className="borderShade1 p-1">
      <div className="flex flex-row panel__top">
        <div className="panel__editor"></div>
      </div>
      <div id="gjs">
        <></>
      </div>
    </div>
  );
};

export default InstantEmailEditorContent;
