const getTemplateUrl = (title = '', variant = '', type = 'html', sub = '') => {
  const template = `https://raw.githubusercontent.com/ardas/stripo-plugin/master/Public-Templates/${variant}-Templates/${sub}/`;
  const remodifiedTitle = title === 'Fathers-day-newsletter' ? 'Fathers-Day' : title;
  return encodeURI(`${template}${title}/${remodifiedTitle}.${type}`);
};

const generateTemplateList = (title, type, variant = 'Basic', sub = '') => {
  return {
    title,
    type,
    sub,
    htmlTemplate: getTemplateUrl(title, variant, 'html', sub),
    cssTemplate: getTemplateUrl(title, variant, 'css', sub),
    imageUrl:
      title === 'New-editor-template'
        ? 'https://test-maily-attachments.s3.us-east-2.amazonaws.com/650979eba07be80014ae26cb-1709140614360_screenshot.png'
        : getTemplateUrl(title, variant, 'png', sub),
  };
};

const remoteTemplateList = [
  { title: 'New-editor-template', variant: 'Basic', type: 'quick' },
  // { title: 'Empty-Template', variant: 'Basic', type: 'quick' },
  // { title: '1-Column-Template', variant: 'Basic', type: 'quick' },
  // { title: '2-Column-Template', variant: 'Basic', type: 'quick' },
  // { title: 'Promo newsletter mockup', variant: 'Basic', type: 'newsletter' },
  // { title: 'Trigger newsletter mockup', variant: 'Basic', type: 'newsletter' },
  // { title: 'Fathers-day-newsletter', variant: 'Custom', sub: 'Cars', type: 'newsletter' },
  // { title: 'Promo-newsletter', variant: 'Custom', sub: 'Cars', type: 'newsletter' },
  { title: 'Trigger-newsletter', variant: 'Custom', sub: 'Cars', type: 'newsletter' },
  { title: 'Trigger-newsletter', variant: 'Custom', sub: 'Fashion', type: 'newsletter' },
  // { title: 'Promo-newsletter', variant: 'Custom', sub: 'Fashion', type: 'newsletter' },
  // { title: 'Black-friday', variant: 'Custom', sub: 'Fashion', type: 'newsletter' },
  // { title: 'Christmas', variant: 'Custom', sub: 'Finance', type: 'newsletter' },
  // { title: 'Promo-newsletter', variant: 'Custom', sub: 'Finance', type: 'newsletter' },
  // { title: 'Trigger-newsletter', variant: 'Custom', sub: 'Finance', type: 'newsletter' },
  // { title: 'Promo-newsletter', variant: 'Custom', sub: 'Restaurants', type: 'newsletter' },
  { title: 'Trigger-newsletter', variant: 'Custom', sub: 'Restaurants', type: 'newsletter' },
  { title: 'Thanksgiving', variant: 'Custom', sub: 'Restaurants', type: 'newsletter' },
  // { title: 'Independence-Day', variant: 'Custom', sub: 'Sport', type: 'newsletter' },
  // { title: 'Promo-newsletter', variant: 'Custom', sub: 'Sport', type: 'newsletter' },
  // { title: 'Trigger-newsletter', variant: 'Custom', sub: 'Sport', type: 'newsletter' },
];

const landingPageRemoteTemplateList = [
  // { title: 'Fathers-day-newsletter', variant: 'Custom', sub: 'Cars', type: 'newsletter' },
  { title: 'Trigger-newsletter', variant: 'Custom', sub: 'Restaurants', type: 'newsletter' },
  { title: 'Thanksgiving', variant: 'Custom', sub: 'Restaurants', type: 'newsletter' },
];

export const templateList = (
  window.location.pathname.includes('landing-page') ? landingPageRemoteTemplateList : remoteTemplateList
).map(({ title, type, variant, sub }) => generateTemplateList(title, type, variant, sub));

export const getTemplateList = () => {
  const list = window.location.pathname.includes('landing-page') ? landingPageRemoteTemplateList : remoteTemplateList;
  return list.map(({ title, type, variant, sub }) => generateTemplateList(title, type, variant, sub));
};

export const STRIPO_CONSTANT = {
  initial: '',
  editorLoading: 'stripo-editor',
  isSavingMail: 'saving-stripo-mail',
  isSendingTestMail: 'sending-stripo-test-mail',
};
