import React from 'react';
import { Button, Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import '../../../styles/recipients.scss';
import moment from 'moment';
import { MdTypography } from '../../../components/global';
import { MdExportIcon } from 'pages/ContactPage/Icons/MdExportIcon';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import axios from 'axios';
import { showToastMessage } from 'utils/Toast';

const MdReceipients = ({
  recipients,
  imLoading,
  setImLoading,
  isDeletingCampaignUser,
  campaignId,
  isCampaignDataFetching,
  campaigns,
  deleteUserFromCampaign,
  totalPages,
  page,
  currentPage,
  setPage,
}) => {
  const navigate = useNavigate();

  const columns = [
    {
      title: 'Name',
      // dataIndex:
      width: 200,
      render: (recipientData) => {
        return (
          <p data-test="recipient-name">
            {recipientData?.name || `${recipientData?.firstName || ''} ${recipientData?.lastName || ''}` || ''}
          </p>
        );
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      width: 250,
    },
    {
      title: 'Received',
      dataIndex: 'numberOfEmailRecieved',
      width: 150,
      render: (numberOfEmailRecieved) => (
        <MdTypography variant={'pRegular'}>{numberOfEmailRecieved} mail(s)</MdTypography>
      ),
    },
    {
      title: 'Source',
      dataIndex: 'recipientOrigin',
      width: 150,
    },
    {
      title: 'Date Added',
      dataIndex: 'createdAt',
      width: 150,
      render: (createdAt) => (
        <MdTypography variant={'pRegular'}>{moment.utc(createdAt).format('DD/MM/YYYY')}</MdTypography>
      ),
    },
    {
      title: '',
      render: ({ email, _id }) => (
        <div className="flex receipientActionBtns">
          <Button className="viewBtn" onClick={() => navigate(`/campaign/${campaignId}/recipient-details/${email}`)}>
            View
          </Button>
          <Button
            className="deleteBtn"
            onClick={() => deleteUserFromCampaign(campaignId, _id)}
            danger
            loading={isDeletingCampaignUser && imLoading.id === _id}
          >
            Delete
          </Button>
        </div>
      ),
    },
  ];

  const csvExpHeaders = [
    {
      key: 'name',
      displayLabel: 'Name',
    },
    {
      key: 'email',
      displayLabel: 'Email',
    },
  ];

  const csvConfig = mkConfig({
    filename: `${campaigns?.name}_contacts`,
    columnHeaders: csvExpHeaders,
  });

  const api_url = process.env.REACT_APP_API_URL;
  async function fetchAndExportData() {
    setImLoading({ id: 'exporting' });
    try {
      const result = await axios.get(`${api_url}/campaigns/${campaignId}?page=${page}&limit=all`);
      if (result.status) {
        const formattedResult = result?.data?.data?.users?.map(({ name, email }) => ({
          name,
          email,
        }));
        const csv = generateCsv(csvConfig)(formattedResult);
        download(csvConfig)(csv);
        setImLoading({ id: '' });
        return;
      }
      showToastMessage({
        type: 'error',
        title: 'Error',
        description: result?.message || 'An error occurred while exporting data. Please try again after 5 minutes',
      });
      setImLoading({ id: '' });
    } catch (error) {
      setImLoading({ id: '' });
      showToastMessage({
        type: 'error',
        title: 'Error',
        description: error?.message || 'An error occurred while exporting data. Please try again after 5 minutes',
      });
    }
  }

  return (
    <>
      <div className="flex justify-end px-5 pt-2" title="Export Recipients as CSV file">
        <Button
          loading={imLoading.id === 'exporting'}
          className="ghostBtn border px-5 py-2"
          onClick={fetchAndExportData}
        >
          {/* <MdTypography variant={'btnRegular'} style={{ color: '#8e8e8e', marginRight: '.5rem' }}>
            Export
          </MdTypography> */}
          <MdExportIcon />
        </Button>
      </div>

      <div className="antdTable">
        <Table
          loading={isCampaignDataFetching}
          scroll={{ y: '300px', x: true }}
          sticky={true}
          rowKey={(id) => id._id}
          columns={columns}
          dataSource={recipients.source}
         data-test='md-recipient-page-table'
          rowClassName="table-row"
          pagination={{
            onChange: (newPage, newPageSize,currentPage) => {
              setPage({
                pagination: {
                  current: newPage||currentPage,
                  pageSize: newPageSize||10,
                },
              });
            },
            total: totalPages,
            current: currentPage,
          }}
        />
      </div>
    </>
  );
};

export default MdReceipients;
