import { useEffect, useState } from 'react';
import { Table } from 'antd';
import { useFetchEmailReportQuery, useGetEmailTableReportQuery } from '../../redux/featureApi/emailReportApiSlice';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { MdTypography } from '../../components/global';
import returnIcon from '../../asset/Icons/returnIcon.svg';
import '../../styles/instantEmailDetailedReport.scss';
import EmailDetailedReportSummary from './components/EmailDetailedReportSummary';
import { showToastMessage } from 'utils/Toast';

function EmailDetailedReport({ btnText, btnLink }) {
  const { mailId } = useParams();
  const [emailTableReport, setEmailTableReport] = useState([]);
  const navigate = useNavigate();

  const { state: { page } } = useLocation();

  const {
    data: emailTableData,
    isLoading: isLoadingEmailTableData,
    isSuccess: emailTableDataFetched,
    isError: emailTableDataFailed,
    error: emailTableDataError,
  } = useGetEmailTableReportQuery(mailId, { pollingInterval: 60000 });

  const { data: emailReportData } = useFetchEmailReportQuery(mailId, { pollingInterval: 60000 });

  useEffect(() => {
    if (emailTableDataFetched) {
      // setEmailTableReport(table);
      setEmailTableReport(emailTableData?.data);
    }
    if (emailTableDataFailed) {
      showToastMessage({
        type: 'error',
        title: 'Could not fetch email data',
        description: 'Something went wrong, please try again later',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailTableData, emailTableDataFetched, emailTableDataFailed, emailTableDataError]);

  const safeStringCompare = (a, b) => {
    const stringA = a ? a.toString() : '';
    const stringB = b ? b.toString() : '';
    return stringA.localeCompare(stringB);
  };

  const columns = [
    {
      title: 'Name',
      width: 150,
      dataIndex: 'name',
      sorter: (a, b) => safeStringCompare(a.name, b.name),
      render: (text) => <MdTypography variant={'pRegular'}>{text}</MdTypography>,
    },
    {
      title: 'Email Address',
      width: 250,
      dataIndex: 'emailAddress',
      sorter: (a, b) => safeStringCompare(a.emailAddress, b.emailAddress),
      render: (text) => <MdTypography variant={'pRegular'}>{text}</MdTypography>,
    },
    {
      title: 'Delivered',
      width: 100,
      dataIndex: 'delivered',
      sorter: (a, b) => safeStringCompare(a.delivered, b.delivered),
      render: (text) =>
        text === true ? (
          <div className="deliveredStyle ml-2">
            <MdTypography variant={'pRegular'} className="delivered">
              Yes
            </MdTypography>
          </div>
        ) : (
          <div className="deliveredStyle ml-2">
            <MdTypography variant={'pRegular'} className="not-delivered">
              No
            </MdTypography>
          </div>
        ),
    },
    {
      title: 'Total Opens',
      width: 100,
      dataIndex: 'totalOpens',
      sorter: (a, b) => (a.totalOpens || 0) - (b.totalOpens || 0),
      render: (text) => <MdTypography variant={'pRegular'} className="opens ml-2">{text}</MdTypography>,
    },
    {
      title: 'Total Clicks',
      width: 100,
      dataIndex: 'totalClicks',
      sorter: (a, b) => (a.totalClicks || 0) - (b.totalClicks || 0),
      render: (text) => <MdTypography variant={'pRegular'} className="clicks ml-2">{text}</MdTypography>,
    },
  ];

  const configureComponentProps = () => {
    let btnText = '';
    let btnLink = '';
    if (page === 'Campaign') {
      btnText = 'Return To Campaigns';
      btnLink = '/campaigns';
    } else if (page === 'Instant Email') {
      btnText = 'Return To Instant Emails';
      btnLink = '/instant-emails';
    }
    return { btnLink, btnText };
  };

  return (
    <div className="instantEmail-reporting">
      <div className="">
        <button
          onClick={() => navigate(configureComponentProps().btnLink)}
          className="flex border px-5 py-2 email-back-btn"
        >
          <img className="pr-2" src={returnIcon} alt="icon" />
          <span>{configureComponentProps().btnText}</span>
        </button>
      </div>
      <EmailDetailedReportSummary emailReportData={emailReportData} />
      <div>
        <div className="pt-20 email-table">
          <Table columns={columns} dataSource={emailTableReport} loading={isLoadingEmailTableData} rowKey="key" />
        </div>
      </div>
    </div>
  );
}

export default EmailDetailedReport;

