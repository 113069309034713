import React from 'react';

export default function DeleteLandingPageIcon({ deleteOptInPage, id }) {
  return (
    <div onClick={() => deleteOptInPage(id)} style={{ cursor: 'pointer' }} data-test="delete-opt-in-page">
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15.7498 5.04736C15.7348 5.04736 15.7123 5.04736 15.6898 5.04736C11.7223 4.64986 7.76225 4.49986 3.83975 4.89736L2.30975 5.04736C1.99475 5.07736 1.71725 4.85236 1.68725 4.53736C1.65725 4.22236 1.88225 3.95236 2.18975 3.92236L3.71975 3.77236C7.70975 3.36736 11.7523 3.52486 15.8023 3.92236C16.1098 3.95236 16.3348 4.22986 16.3048 4.53736C16.2823 4.82986 16.0348 5.04736 15.7498 5.04736Z"
          fill="#DB2222"
        />
        <path
          d="M6.37458 4.29C6.34458 4.29 6.31458 4.29 6.27708 4.2825C5.97708 4.23 5.76708 3.9375 5.81958 3.6375L5.98458 2.655C6.10458 1.935 6.26958 0.9375 8.01708 0.9375H9.98208C11.7371 0.9375 11.9021 1.9725 12.0146 2.6625L12.1796 3.6375C12.2321 3.945 12.0221 4.2375 11.7221 4.2825C11.4146 4.335 11.1221 4.125 11.0771 3.825L10.9121 2.85C10.8071 2.1975 10.7846 2.07 9.98958 2.07H8.02458C7.22958 2.07 7.21458 2.175 7.10208 2.8425L6.92958 3.8175C6.88458 4.095 6.64458 4.29 6.37458 4.29Z"
          fill="#DB2222"
        />
        <path
          d="M11.4078 17.0627H6.59279C3.97529 17.0627 3.87029 15.6152 3.78779 14.4452L3.30029 6.89268C3.27779 6.58518 3.51779 6.31518 3.82529 6.29268C4.14029 6.27768 4.40279 6.51018 4.42529 6.81768L4.91279 14.3702C4.99529 15.5102 5.02529 15.9377 6.59279 15.9377H11.4078C12.9828 15.9377 13.0128 15.5102 13.0878 14.3702L13.5753 6.81768C13.5978 6.51018 13.8678 6.27768 14.1753 6.29268C14.4828 6.31518 14.7228 6.57768 14.7003 6.89268L14.2128 14.4452C14.1303 15.6152 14.0253 17.0627 11.4078 17.0627Z"
          fill="#DB2222"
        />
        <path
          d="M10.2455 12.9375H7.74805C7.44055 12.9375 7.18555 12.6825 7.18555 12.375C7.18555 12.0675 7.44055 11.8125 7.74805 11.8125H10.2455C10.553 11.8125 10.808 12.0675 10.808 12.375C10.808 12.6825 10.553 12.9375 10.2455 12.9375Z"
          fill="#DB2222"
        />
        <path
          d="M10.875 9.9375H7.125C6.8175 9.9375 6.5625 9.6825 6.5625 9.375C6.5625 9.0675 6.8175 8.8125 7.125 8.8125H10.875C11.1825 8.8125 11.4375 9.0675 11.4375 9.375C11.4375 9.6825 11.1825 9.9375 10.875 9.9375Z"
          fill="#DB2222"
        />
      </svg>
    </div>
  );
}
