import { configureStore } from '@reduxjs/toolkit';
import { injectStore } from '../utils/services/authService';
import { apiAuthSlice } from './featureApi/apiAuthSlice';
import authReducer from './featureSlices/authSlice';
import uiReducer from './featureSlices/uiSlice';

export const store = configureStore({
  reducer: {
    [apiAuthSlice.reducerPath]: apiAuthSlice.reducer,
    auth: authReducer,
    ui: uiReducer,
  },
  middleware: (defaultMiddleware) => defaultMiddleware().concat(apiAuthSlice.middleware),
  devTools: true,
});

// passing store to custom middleware inother to avoid circular dependency problems
injectStore(store);
