export const LandingPageIcon = () => {
  return (
    <svg width="246" height="166" viewBox="0 0 246 166" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M127.279 66.4641C126.368 67.2831 125.342 68.2056 124.232 69.4106C121.894 71.9507 121.787 76.1653 121.936 78.4919H121.939C122.19 78.5981 134.448 83.5906 140.407 70.657C146.426 57.5905 147.193 38.5014 142.326 33.6341C137.459 28.7669 127.851 20.4395 110.685 26.4611C93.5184 32.4826 89.5467 44.0252 89.5467 44.0252C89.5467 44.0252 72.5405 84.4535 29.1123 49.9924C43.7945 77.5156 63.8904 84.863 80.3246 85.206C85.7134 85.2995 91.0922 84.7075 96.3316 83.4448C102.405 81.9938 106.859 80.0227 108.669 79.0184C108.888 78.895 109.093 78.7492 109.281 78.5828C114.132 74.3278 111.574 55.1549 110.452 48.1532C119.411 48.3373 128.699 45.8602 132.647 44.6596C133.197 47.3929 132.08 57.8872 131.152 61.2438C130.508 63.5607 129.131 64.7993 127.279 66.4641ZM129.9 27.8169C129.675 27.7698 129.454 27.914 129.407 28.1388C129.36 28.3637 129.505 28.5841 129.729 28.631C135.272 29.7892 139.674 34.4906 141.603 39.8933C143.533 45.2956 143.292 51.2811 142.169 56.9771C142.125 57.2023 142.272 57.4211 142.497 57.4657C142.722 57.5101 142.941 57.3634 142.986 57.138C144.123 51.3699 144.387 45.2152 142.387 39.6135C140.386 34.0123 135.795 29.0488 129.9 27.8169Z"
        fill="#171717"
      />
      <path
        d="M183.459 95.2554C183.459 95.2554 201.9 99.9948 202.732 116.201C203.495 131.037 200.811 130.996 194.661 136.762C194.661 136.757 194.66 136.753 194.658 136.748C194.585 136.119 192.107 115.411 184.981 116.775L184.006 103.005L183.459 95.2554Z"
        fill="white"
      />
      <path
        d="M169.226 18.5068L216.398 50.6186L197.445 76.9648L154.786 49.7039L169.226 18.5068ZM190.898 47.8002L194.486 47.0191L191.686 44.6451L192.05 40.9892L188.925 42.9218L185.563 41.4445L186.437 45.0136L183.993 47.7547L187.654 48.027L189.508 51.1984L190.898 47.8002Z"
        fill="white"
      />
      <path
        d="M194.485 47.0201L190.898 47.8012L189.506 51.1986L187.653 48.0271L183.991 47.7549L186.436 45.0137L185.562 41.4447L188.924 42.9228L192.049 40.9902L191.684 44.6461L194.485 47.0201Z"
        fill="#171717"
      />
      <path
        d="M42.4074 94.5481C42.4074 94.9912 42.5391 95.424 42.7854 95.7922C43.0316 96.1602 43.3815 96.4472 43.7907 96.6163C44.2 96.7857 44.6503 96.8298 45.0848 96.743C45.519 96.6565 45.918 96.443 46.231 96.1294C46.5438 95.8161 46.757 95.4171 46.843 94.9826C46.9292 94.5481 46.8846 94.0978 46.7149 93.6888C46.5449 93.2796 46.2576 92.9302 45.8894 92.6843C45.5209 92.4386 45.0878 92.3075 44.645 92.3077C44.3508 92.308 44.0597 92.366 43.788 92.4785C43.5165 92.5911 43.2697 92.7564 43.0618 92.9643C42.8541 93.1726 42.6894 93.4193 42.5768 93.6911C42.4645 93.9628 42.4069 94.2542 42.4074 94.5481ZM51.1787 96.7887C51.6218 96.7893 52.0551 96.6582 52.4239 96.4122C52.7927 96.1663 53.0802 95.8164 53.2499 95.4071C53.4199 94.9976 53.4645 94.547 53.3783 94.1122C53.2921 93.6775 53.0788 93.2782 52.7655 92.9646C52.4522 92.651 52.0529 92.4375 51.6184 92.3507C51.1837 92.2642 50.7331 92.3083 50.3236 92.478C49.914 92.6474 49.5638 92.9347 49.3176 93.3032C49.0714 93.6717 48.94 94.105 48.94 94.5481C48.9397 94.8423 48.9974 95.1334 49.1097 95.4052C49.2222 95.6769 49.3869 95.9239 49.5949 96.1319C49.8026 96.3398 50.0493 96.5048 50.3208 96.6174C50.5925 96.7303 50.8837 96.7882 51.1778 96.7882L51.1787 96.7887ZM57.4398 96.7887C57.8829 96.7896 58.3165 96.6584 58.6853 96.4128C59.0541 96.1668 59.3419 95.8172 59.5118 95.4077C59.6821 94.9984 59.7267 94.5478 59.6408 94.1131C59.5545 93.678 59.3413 93.2785 59.0283 92.9649C58.715 92.6513 58.3157 92.4375 57.8809 92.3507C57.4462 92.2642 56.9956 92.3083 56.5861 92.4777C56.1762 92.6474 55.8263 92.9347 55.5798 93.3032C55.3336 93.6717 55.2022 94.105 55.2022 94.5481C55.2016 95.1418 55.437 95.7113 55.8566 96.1313C56.2761 96.5514 56.8453 96.7879 57.4389 96.7882L57.4398 96.7887ZM70.6263 96.7887L159.623 99.2252V94.0848L70.6255 92.3072L70.6263 96.7887ZM183.133 90.6444L183.459 95.256L184.006 103.006L183.565 103.036L37.0608 100.638L36.8975 87.1846L70.6263 87.982L171.024 90.3585L183.133 90.6444Z"
        fill="white"
      />
      <path
        d="M153.344 153.48L160.936 142.525L177.558 156.937H178.07V165.567C178.07 166.328 177.768 167.057 177.23 167.596C176.692 168.134 175.962 168.436 175.201 168.436H133.812C133.051 168.436 132.321 168.134 131.783 167.596C131.245 167.057 130.942 166.328 130.942 165.567V155.72L142.969 138.829L153.344 153.48Z"
        fill="#171717"
      />
      <path
        d="M172.177 141.886C172.068 143.146 174.736 143.503 178.07 143.473V156.937H177.559L160.936 142.525L153.344 153.48L142.969 138.829L130.942 155.721V120.715C130.942 119.954 131.245 119.224 131.783 118.686C132.321 118.148 133.051 117.845 133.812 117.845H175.201C175.962 117.845 176.692 118.148 177.23 118.686C177.768 119.224 178.07 119.954 178.07 120.715V126.657L178.095 126.79C175.645 127.272 173.714 127.963 173.714 128.946C173.714 129.948 175.647 130.558 178.072 130.931L178.07 130.953V132.374L178.089 132.507C175.342 133.04 173.098 133.838 173.202 135.03C173.297 136.157 175.442 136.737 178.089 137.02L178.07 137.214V138.829L178.091 138.914C174.951 139.688 172.282 140.691 172.177 141.886ZM154.506 130.221C154.507 129.232 154.214 128.265 153.665 127.442C153.116 126.619 152.335 125.978 151.421 125.599C150.508 125.22 149.502 125.12 148.532 125.313C147.561 125.506 146.67 125.982 145.97 126.681C145.271 127.38 144.794 128.271 144.601 129.241C144.408 130.212 144.507 131.217 144.885 132.131C145.264 133.045 145.905 133.826 146.727 134.376C147.549 134.926 148.516 135.219 149.506 135.219C150.162 135.219 150.812 135.09 151.419 134.839C152.026 134.588 152.577 134.22 153.041 133.756C153.506 133.292 153.874 132.741 154.125 132.134C154.377 131.528 154.506 130.877 154.506 130.221Z"
        fill="white"
      />
      <path
        d="M127.345 81.5462L127.383 83.4439L127.497 83.4411C127.497 83.4411 148.344 82.9592 171.023 90.3557V90.3582L70.626 87.9817C70.626 87.9817 79.0434 85.2187 80.3255 85.2187V85.2043C85.7143 85.2977 91.0932 84.7057 96.3325 83.443C102.405 81.9921 106.859 80.0209 108.671 79.0166C108.89 78.8933 109.095 78.7471 109.284 78.5811L122.038 80.6745L127.345 81.5462Z"
        fill="white"
      />
      <path
        d="M122.686 134.04H114.562C113.409 134.04 112.475 134.974 112.475 136.127V137.331C112.475 138.484 113.409 139.419 114.562 139.419H122.686C123.839 139.419 124.774 138.484 124.774 137.331V136.127C124.774 134.974 123.839 134.04 122.686 134.04Z"
        fill="white"
      />
      <path
        d="M72.8409 123.815L68.5454 128.002L69.558 133.917L64.2496 131.124L58.9385 133.917L59.9511 128.002L55.6553 123.815L61.5925 122.952L64.2496 117.573L66.9037 122.952L72.8409 123.815Z"
        fill="#171717"
      />
      <path
        d="M31.1309 24.5386L57.6512 2.44189L61.9714 7.77774L61.3644 8.27045L35.911 29.3654L31.1309 24.5386Z"
        fill="white"
      />
      <path
        d="M53.4179 94.548C53.4176 94.9908 53.2862 95.4236 53.04 95.7915C52.794 96.1598 52.4441 96.4465 52.0351 96.6159C51.6259 96.7853 51.1759 96.8294 50.7416 96.7431C50.3072 96.6566 49.9084 96.4434 49.5954 96.1304C49.2821 95.8171 49.0689 95.4183 48.9826 94.9839C48.8961 94.5496 48.9402 94.0996 49.1096 93.6904C49.279 93.2814 49.5657 92.9315 49.9339 92.6855C50.3019 92.4393 50.7347 92.3079 51.1775 92.3076C51.4717 92.3073 51.7634 92.3653 52.0351 92.4776C52.3071 92.5902 52.5542 92.7551 52.7621 92.9634C52.9704 93.1713 53.1353 93.4184 53.2479 93.6904C53.3605 93.9624 53.4182 94.2538 53.4179 94.548Z"
        fill="white"
      />
      <path
        d="M47.8809 29.0557L49.7239 30.9913L47.0668 31.2772L45.7955 33.6265L44.7011 31.1873L42.0742 30.7054L44.0562 28.9115L43.7049 26.2627L46.0215 27.5939L48.4307 26.4396L47.8809 29.0557Z"
        fill="#171717"
      />
      <path
        d="M46.8849 94.548C46.8846 94.9908 46.7532 95.4236 46.507 95.7915C46.2608 96.1598 45.9111 96.4465 45.5021 96.6159C45.0929 96.7853 44.6429 96.8294 44.2087 96.7431C43.7742 96.6566 43.3755 96.4434 43.0621 96.1304C42.7491 95.8173 42.5359 95.4183 42.4494 94.9841C42.3629 94.5499 42.4072 94.0996 42.5764 93.6906C42.7458 93.2814 43.0325 92.9318 43.4007 92.6855C43.7686 92.4393 44.2014 92.3079 44.6443 92.3076C44.9384 92.3073 45.2299 92.3653 45.5019 92.4776C45.7739 92.5902 46.0209 92.7551 46.2289 92.9634C46.4371 93.1713 46.6021 93.4184 46.7146 93.6904C46.8272 93.9624 46.8849 94.2538 46.8849 94.548Z"
        fill="#171717"
      />
      <path
        d="M37.4378 132.151C49.3422 145.868 88.9703 144.063 91.6627 145.985C92.0763 146.282 92.3949 146.832 92.6129 147.566C93.4724 150.457 92.7925 156.212 90.3151 160.627C89.3918 162.366 88.077 163.865 86.474 165.008C82.3064 167.828 56.4609 170.493 37.8542 164.812C27.3001 161.589 19.0759 155.684 18.4498 145.601C16.7185 117.735 37.1464 107.936 37.1464 107.936L37.4378 132.151Z"
        fill="white"
      />
      <path
        d="M185.6 125.538L184.98 116.775L184.005 103.006L183.458 95.2555L183.132 90.6439L171.023 90.358L70.6253 87.9815L36.8965 87.1841L37.0598 100.637L37.1469 107.936L37.4383 132.151L37.4438 132.567"
        fill="white"
      />
      <path
        d="M185.6 125.538L184.98 116.775L184.005 103.006L183.458 95.2555L183.132 90.6439L171.023 90.358L70.6253 87.9815L36.8965 87.1841L37.0598 100.637L37.1469 107.936L37.4383 132.151L37.4438 132.567"
        stroke="#171717"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M37.8301 164.897L38.0505 182.88L189.278 177.498L186.847 143.141" fill="white" />
      <path
        d="M37.8301 164.897L38.0505 182.88L189.278 177.498L186.847 143.141"
        stroke="#171717"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.911 29.3654L51.3083 44.9096L73.9848 22.6168L61.9714 7.77774L57.6512 2.44189L31.1309 24.5386L35.911 29.3654Z"
        fill="white"
        stroke="#171717"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M169.226 18.507L154.787 49.7041L197.445 76.965L216.398 50.6188L220.506 44.9103L172.659 11.0889L169.226 18.507Z"
        fill="white"
        stroke="#171717"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M121.938 78.492C121.788 76.1643 121.895 71.9503 124.233 69.4105C127.598 65.7572 130.192 64.701 131.153 61.2437C132.081 57.887 133.198 47.3928 132.647 44.6595C132.631 44.5763 132.612 44.4962 132.592 44.4282"
        stroke="#171717"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M122.12 80.1686C122.12 80.1686 122.006 79.5098 121.94 78.4917"
        stroke="#171717"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M134.609 44.0254C134.609 44.0254 133.896 44.2785 132.647 44.6595C128.699 45.8601 119.411 48.3373 110.452 48.1532C109.856 48.1423 109.26 48.1177 108.669 48.0797"
        stroke="#171717"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.1133 49.9929C43.7943 77.515 63.8902 84.8625 80.3256 85.2066C85.7144 85.3 91.0932 84.708 96.3325 83.4453C102.405 81.9943 106.859 80.0232 108.671 79.0189C108.89 78.8955 109.095 78.7497 109.284 78.5833C114.136 74.3283 111.576 55.1554 110.454 48.1538C110.198 46.5503 110.016 45.584 110.016 45.584"
        stroke="#171717"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M121.937 78.4924H121.94C122.189 78.5986 134.449 83.5912 140.408 70.6575C146.427 57.5907 147.197 38.5049 142.327 33.6346C137.457 28.7643 127.853 20.4398 110.686 26.4616C93.5185 32.4834 89.5477 44.0257 89.5477 44.0257C89.5477 44.0257 72.5414 84.4541 29.1133 49.9929"
        stroke="#171717"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.1464 107.936C37.1464 107.936 16.7185 117.736 18.4498 145.601C19.0759 155.684 27.3001 161.589 37.8542 164.812C56.4609 170.493 82.3064 167.828 86.474 165.008C88.077 163.865 89.3918 162.366 90.3151 160.627C92.7925 156.212 93.473 150.457 92.6129 147.566C92.3949 146.831 92.0763 146.281 91.6626 145.985C88.9703 144.063 49.3422 145.868 37.4378 132.151"
        stroke="#171717"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M183.459 95.2554C183.459 95.2554 201.9 99.9948 202.732 116.201C203.495 131.037 200.811 130.996 194.661 136.762C194.661 136.757 194.66 136.753 194.658 136.748C194.585 136.119 192.107 115.411 184.981 116.775"
        fill="white"
      />
      <path
        d="M183.459 95.2554C183.459 95.2554 201.9 99.9948 202.732 116.201C203.495 131.037 200.811 130.996 194.661 136.762C194.661 136.757 194.66 136.753 194.658 136.748C194.585 136.119 192.107 115.411 184.981 116.775"
        stroke="#171717"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M109.282 78.5815L122.036 80.675L127.345 81.5459L127.383 83.4435L127.426 85.5258"
        stroke="#171717"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M96.332 83.4414V83.4442V85.5947" stroke="#171717" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M70.626 87.9818C70.626 87.9818 79.0434 85.2188 80.3255 85.2188"
        stroke="#171717"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M127.496 83.441C127.496 83.441 148.343 82.9591 171.022 90.3556L127.496 83.441Z" fill="white" />
      <path
        d="M127.496 83.441C127.496 83.441 148.343 82.9591 171.022 90.3556"
        stroke="#171717"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M99.631 151.863C100.938 151.645 115.937 149.182 115.937 151.88C115.937 154.697 102.231 155.593 102.231 155.593C102.231 155.593 116.065 154.953 115.937 157.259C115.809 159.564 90.3154 160.629 90.3154 160.629"
        stroke="#171717"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M99.542 151.879C99.5459 151.879 99.5495 151.878 99.5531 151.876C99.5634 151.875 99.5733 151.873 99.5831 151.871"
        stroke="#171717"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M99.5811 151.871C99.5716 151.873 99.5618 151.875 99.5518 151.876"
        stroke="#171717"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M99.582 151.87C99.5981 151.87 99.6144 151.867 99.6299 151.862"
        stroke="#171717"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M92.6133 147.567C92.6133 147.567 93.6339 137.274 96.3318 137.146C99.0297 137.018 97.2357 148.292 97.2357 148.292C97.2357 148.292 113.504 144.321 113.632 146.754C113.754 149.074 100.837 151.63 99.6311 151.864"
        stroke="#171717"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M188.321 137.144C188.312 137.144 188.304 137.145 188.296 137.146C187.888 137.168 182.342 137.479 178.09 137.021C175.444 136.738 173.299 136.158 173.204 135.031C173.1 133.839 175.343 133.041 178.09 132.508C182.282 131.697 187.645 131.51 187.645 131.51C187.645 131.51 182.16 131.561 178.074 130.932C175.648 130.559 173.715 129.95 173.715 128.948C173.715 127.965 175.646 127.274 178.096 126.792C180.587 126.345 183.101 126.043 185.626 125.888C187.151 125.785 188.191 125.747 188.191 125.747"
        stroke="#171717"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M194.421 136.762C194.421 136.762 193.844 142.141 189.985 142.525C189.228 142.602 187.804 142.77 186.075 142.947C183.663 143.195 180.666 143.451 178.072 143.473C174.737 143.503 172.069 143.146 172.178 141.886C172.281 140.691 174.951 139.689 178.093 138.913C182.52 137.824 187.881 137.194 188.297 137.146"
        stroke="#171717"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M37.0596 100.637L183.564 103.036" stroke="#171717" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M70.626 92.3076V96.7886L159.623 99.225V94.0852L70.626 92.3076Z"
        stroke="#171717"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.8849 94.548C46.8846 94.9908 46.7532 95.4236 46.507 95.7915C46.2608 96.1598 45.9111 96.4465 45.5021 96.6159C45.0929 96.7853 44.6429 96.8294 44.2087 96.7431C43.7742 96.6566 43.3755 96.4434 43.0621 96.1304C42.7491 95.8173 42.5359 95.4183 42.4494 94.9841C42.3629 94.5499 42.4072 94.0996 42.5764 93.6906C42.7458 93.2814 43.0325 92.9318 43.4007 92.6855C43.7686 92.4393 44.2014 92.3079 44.6443 92.3076C44.9384 92.3073 45.2299 92.3653 45.5019 92.4776C45.7739 92.5902 46.0209 92.7551 46.2289 92.9634C46.4371 93.1713 46.6021 93.4184 46.7146 93.6904C46.8272 93.9624 46.8849 94.2538 46.8849 94.548Z"
        stroke="#171717"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M53.4179 94.548C53.4176 94.9908 53.2862 95.4236 53.04 95.7915C52.794 96.1598 52.4441 96.4465 52.0351 96.6159C51.6259 96.7853 51.1759 96.8294 50.7416 96.7431C50.3072 96.6566 49.9084 96.4434 49.5954 96.1304C49.2821 95.8171 49.0689 95.4183 48.9826 94.9839C48.8961 94.5496 48.9402 94.0996 49.1096 93.6904C49.279 93.2814 49.5657 92.9315 49.9339 92.6855C50.3019 92.4393 50.7347 92.3079 51.1775 92.3076C51.4717 92.3073 51.7634 92.3653 52.0351 92.4776C52.3071 92.5902 52.5542 92.7551 52.7621 92.9634C52.9704 93.1713 53.1353 93.4184 53.2479 93.6904C53.3605 93.9624 53.4182 94.2538 53.4179 94.548Z"
        stroke="#171717"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M59.679 94.548C59.6788 94.9908 59.5473 95.4233 59.3011 95.7913C59.0552 96.1592 58.7053 96.4462 58.2963 96.6153C57.8873 96.7847 57.4373 96.8288 57.0031 96.7423C56.5689 96.6561 56.1699 96.4429 55.8568 96.1298C55.5438 95.8168 55.3306 95.4178 55.2443 94.9839C55.1578 94.5496 55.2019 94.0994 55.3713 93.6904C55.5405 93.2814 55.8275 92.9318 56.1954 92.6855C56.5633 92.4393 56.9962 92.3079 57.4387 92.3076C57.7331 92.3073 58.0246 92.3653 58.2963 92.4776C58.5683 92.5902 58.8153 92.7551 59.0233 92.9634C59.2315 93.1713 59.3965 93.4184 59.5091 93.6904C59.6217 93.9624 59.6793 94.2538 59.679 94.548Z"
        stroke="#171717"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M72.3516 109.956H151.616" stroke="#171717" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M85.7607 124.637L124.449 124.618" stroke="#171717" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M101.219 129.221L124.037 129.091" stroke="#171717" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M64.2496 117.573L66.9037 122.952L72.8409 123.815L68.5454 128.002L69.558 133.917L64.2496 131.124L58.9385 133.917L59.9511 128.002L55.6553 123.815L61.5925 122.952L64.2496 117.573Z"
        stroke="#171717"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M122.686 134.04H114.562C113.409 134.04 112.475 134.974 112.475 136.127V137.331C112.475 138.484 113.409 139.419 114.562 139.419H122.686C123.839 139.419 124.774 138.484 124.774 137.331V136.127C124.774 134.974 123.839 134.04 122.686 134.04Z"
        stroke="#171717"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M178.07 143.68V165.567C178.07 166.328 177.768 167.058 177.23 167.595C176.692 168.134 175.962 168.436 175.201 168.436H133.812C133.051 168.436 132.321 168.134 131.783 167.595C131.245 167.058 130.942 166.328 130.942 165.567V120.715C130.942 119.954 131.245 119.224 131.783 118.686C132.321 118.148 133.051 117.845 133.812 117.845H175.201C175.962 117.845 176.692 118.148 177.23 118.686C177.768 119.224 178.07 119.954 178.07 120.715V126.657"
        stroke="#171717"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M178.071 130.953V132.374" stroke="#171717" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M169.226 18.5068L216.397 50.6186" stroke="#171717" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M173.843 46.0942L191.905 58.4889" stroke="#171717" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M177.559 53.4907L183.564 57.4624" stroke="#171717" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M35.9111 29.3654L61.3645 8.27051" stroke="#171717" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M53.2295 23.6768L61.3638 16.9502" stroke="#171717" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M53.2295 29.7278L64.5677 19.8325" stroke="#171717" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M61.3643 28.1898L65.2405 24.7788" stroke="#171717" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M127.496 49.0723L127.344 51.3129" stroke="#171717" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M117.16 49.0591L117.023 51.3294" stroke="#171717" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M124.97 55.4775C124.97 55.4775 125.291 60.4729 124.714 61.0507C124.137 61.6286 121.894 60.5389 121.894 60.5389"
        stroke="#171717"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M114.658 65.4004C114.658 65.4004 115.233 68.0328 116.899 68.9284C118.564 69.824 120.29 68.4792 120.29 68.4792"
        stroke="#171717"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M192.049 40.9902L191.684 44.6461L194.485 47.0201L190.898 47.8012L189.506 51.1986L187.653 48.0271L183.991 47.7549L186.436 45.0137L185.562 41.4447L188.924 42.9228L192.049 40.9902Z"
        stroke="#171717"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M48.4307 26.4396L47.8809 29.0557L49.7239 30.9913L47.0668 31.2772L45.7955 33.6265L44.7011 31.1873L42.0742 30.7054L44.0562 28.9115L43.7049 26.2627L46.0215 27.5939L48.4307 26.4396Z"
        stroke="#171717"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M175.572 19.4783C176.245 19.4783 176.789 18.9332 176.789 18.2614C176.789 17.5893 176.245 17.0444 175.572 17.0444C174.9 17.0444 174.355 17.5893 174.355 18.2614C174.355 18.9332 174.9 19.4783 175.572 19.4783Z"
        stroke="#171717"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M178.071 137.214V138.828" stroke="#171717" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M130.942 155.72L142.969 138.829L153.344 153.48L160.936 142.525L177.559 156.937"
        stroke="#171717"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M154.507 130.221C154.507 131.21 154.213 132.177 153.664 132.999C153.114 133.822 152.333 134.463 151.419 134.841C150.506 135.22 149.5 135.319 148.53 135.126C147.56 134.933 146.669 134.457 145.97 133.757C145.27 133.058 144.794 132.167 144.601 131.197C144.408 130.227 144.507 129.221 144.885 128.307C145.264 127.393 145.905 126.613 146.727 126.063C147.55 125.514 148.517 125.22 149.506 125.22C150.163 125.22 150.813 125.349 151.42 125.6C152.027 125.852 152.578 126.22 153.043 126.684C153.507 127.149 153.876 127.7 154.127 128.307C154.378 128.914 154.507 129.564 154.507 130.221Z"
        stroke="#171717"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.6262 64.0605C18.247 67.2805 19.5116 70.3341 20.5204 73.454C21.5291 76.5739 22.2949 79.864 21.8707 83.1156C21.4465 86.3672 19.6345 89.5921 16.658 90.966C13.6815 92.3399 9.58062 91.2857 8.21671 88.3042C6.85281 85.3227 9.4539 81.1447 12.7013 81.6019C14.4639 81.8515 15.906 83.2731 16.5637 84.9273C17.2214 86.5815 17.2015 88.4317 16.9369 90.1921C16.5074 93.0508 15.3454 95.9622 13.0063 97.6624C11.1941 98.9797 8.90768 99.4151 6.84033 100.279C4.86698 101.103 3.01729 102.392 1.92511 104.231C0.832936 106.07 0.623317 108.514 1.75459 110.329C2.88586 112.144 5.48336 113.015 7.32445 111.926C9.16554 110.838 9.49605 107.763 7.73814 106.545"
        stroke="#171717"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M222.252 82.0654C219.577 85.7593 217.823 90.0379 217.135 94.5458C216.497 98.7531 216.974 103.474 219.919 106.545C221.885 108.597 224.939 109.66 227.688 108.938C230.437 108.217 232.655 105.571 232.571 102.731C232.487 99.8899 229.848 97.2816 227.015 97.5178C224.009 97.7674 221.86 100.901 221.794 103.916C221.727 106.932 223.246 109.758 225.019 112.199C226.791 114.641 228.873 116.89 230.22 119.589C231.383 121.921 231.956 124.623 231.384 127.165C230.813 129.708 228.972 132.032 226.477 132.778C223.981 133.523 220.942 132.393 219.868 130.018C218.795 127.644 220.252 124.374 222.815 123.902"
        stroke="#171717"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M206.926 126.946C209.053 122.109 209.321 116.658 207.679 111.636"
        stroke="#171717"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M233.488 87.5057L239.048 85.4209" stroke="#171717" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M214.732 63.5399C218.917 59.7144 222.007 54.843 223.683 49.4268"
        stroke="#171717"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M240.099 90.6096L245.001 89.4653" stroke="#171717" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M154.499 23.483L151.328 18.583" stroke="#171717" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M150.752 22.906L155.652 18.8706" stroke="#171717" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M156.223 57.5215C154.965 57.2371 153.802 56.6351 152.843 55.7731C151.884 54.911 151.162 53.8177 150.746 52.5972"
        stroke="#171717"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M93.6752 22.5874L88.7764 23.4603" stroke="#171717" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M91.1564 25.4923L90.5645 20.3057" stroke="#171717" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M104.056 5.03543L107.226 1" stroke="#171717" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M107.192 4.78309L103.706 1.35156" stroke="#171717" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M33.1014 34.1863L29.0635 29.2886" stroke="#171717" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.51367 74.7903L10.0901 70.7549" stroke="#171717" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11.2434 73.9242L7.49609 72.4824" stroke="#171717" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14.4136 109.379L13.2607 107.073" stroke="#171717" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11.8379 109.405L15.3085 107.117" stroke="#171717" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M15.7107 151.962C13.2477 147.279 12.4935 141.885 13.5776 136.706"
        stroke="#171717"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M124.061 85.4552L124.154 83.4375" stroke="#171717" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M119.701 85.5502L119.798 83.2441" stroke="#171717" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M113.856 85.2629V82.8608" stroke="#171717" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M108.38 84.8779C108.596 84.0311 108.661 83.1529 108.572 82.2837"
        stroke="#171717"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M103.785 84.9758L104.072 82.6694" stroke="#171717" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M78.6621 166.997C83.6225 160.854 86.1457 153.1 85.7498 145.214"
        stroke="#171717"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M72.6167 159.83L71.5566 157.411" stroke="#171717" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M73.7637 149.665C74.3296 149.217 75.0017 148.923 75.7146 148.812"
        stroke="#171717"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M59.8271 162.51L61.877 161.614" stroke="#171717" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M62.6631 153.308L64.4789 151.798" stroke="#171717" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M57.9292 148.014L56.415 146.202" stroke="#171717" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M54.3535 158.005L53.0664 155.959" stroke="#171717" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M45.3389 162.366L47.3874 160.828" stroke="#171717" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M46.3594 150.56L48.2836 149.654L48.17 149.315"
        stroke="#171717"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M48.1821 141.894C47.5719 142.853 46.7292 143.641 45.7324 144.187"
        stroke="#171717"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.0925 155.703C38.4716 155.078 38.0391 154.292 37.8447 153.433"
        stroke="#171717"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M37.5823 146.023L36.4932 143.688" stroke="#171717" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M26.9102 150.349L28.5793 149.073" stroke="#171717" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M32.1934 138.49L34.6164 137.062" stroke="#171717" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M24.3684 139.189L23.6123 136.111" stroke="#171717" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M30.2197 129.067C30.7576 128.057 31.5071 127.175 32.4171 126.481L32.2616 126.141"
        stroke="#171717"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M31.7391 119.507L29.4932 117.671" stroke="#171717" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M196.774 122.414L199.208 120.876" stroke="#171717" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M196.526 111.394L195.85 108.199" stroke="#171717" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M191.253 112.84L188.951 112.055" stroke="#171717" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M198.08 133.298C197.327 129.255 195.727 125.416 193.386 122.035C192.374 120.574 191.099 119.125 189.368 118.722"
        stroke="#171717"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M145.563 88.0496L147.197 87.0894" stroke="#171717" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M137.698 87.373L137.761 87.645C137.156 86.8281 136.52 86.0357 135.854 85.2671"
        stroke="#171717"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M128.324 87.7247C128.715 87.2755 129.232 86.9555 129.808 86.8066"
        stroke="#171717"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M92.1435 87.2787L89.625 85.874" stroke="#171717" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M157.978 89.8503C157.486 89.4302 157.067 88.9328 156.736 88.3774"
        stroke="#171717"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M190.145 104.094C189.553 104.139 188.96 104.04 188.415 103.806"
        stroke="#171717"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g opacity="0.1">
        <path
          d="M132.188 43.5692C130.463 43.7461 128.906 44.3639 127.241 44.8266C125.689 45.2581 124.113 45.6149 122.533 45.9288C119.353 46.533 116.12 46.8089 112.884 46.7523C111.061 46.7382 109.558 48.6075 110.111 50.3926C111.463 54.7724 111.933 59.3768 111.494 63.9396C111.269 66.1597 110.813 68.3507 110.135 70.4772C109.435 72.7275 108.242 74.7369 107.469 76.9426C106.998 78.2885 107.895 79.8578 109.147 80.3617C110.599 80.9456 111.859 80.3556 112.767 79.1758C114.274 77.2185 115.143 74.3959 115.867 72.0573C116.643 69.4873 117.159 66.8457 117.405 64.1725C117.902 59.0033 117.306 53.7873 115.656 48.8634L112.883 52.5038C116.521 52.3735 120.138 51.8902 123.683 51.0603C126.994 50.2884 130.987 49.2486 133.63 47.0526C134.938 45.9654 133.91 43.3926 132.188 43.5692Z"
          fill="#171717"
        />
      </g>
      <g opacity="0.1">
        <path
          d="M35.1234 108.405C31.5077 109.545 28.8514 113.654 26.8052 116.645C24.6366 119.856 22.8263 123.295 21.4067 126.901C19.9779 130.483 18.9855 134.224 18.4509 138.043C17.8795 142.083 17.7109 146.269 18.848 150.235C21.1494 158.264 28.2389 162.416 35.7309 164.845C43.9212 167.462 52.4585 168.834 61.0564 168.913C65.9644 168.954 70.8677 168.593 75.7169 167.833C78.1276 167.464 80.5188 166.99 82.8908 166.411C85.3167 165.819 87.8928 165.272 90.0572 163.994C92.3544 162.637 91.5725 158.298 88.601 158.617C86.4721 158.846 84.5134 159.525 82.4486 160.062C80.4955 160.57 78.5257 161.007 76.5391 161.373C72.8416 162.046 69.1007 162.453 65.345 162.591C57.5453 162.907 49.7406 162.096 42.1725 160.184C36.1834 158.659 28.7414 156.677 25.6531 150.742C22.6386 144.949 24.1991 137.138 26.0005 131.216C27.6714 125.727 30.3143 120.581 33.8035 116.026C35.1148 114.319 36.6744 112.692 37.3457 110.628C37.766 109.335 36.4069 108 35.1234 108.405Z"
          fill="white"
        />
      </g>
    </svg>
  );
};
