import Cookies from 'js-cookie';
import { showToastMessage } from 'utils/Toast';
import { exPreviewPopup, exVideoLirary, extCustomFont, externalImgLib, externalLinkEventPopup } from './libs';

const API_HOST = process.env.REACT_APP_API_URL;
const token = Cookies.get('token');

async function stripoApiRequest(method = 'GET', url, body, callback, headers, isText) {
  const maxRetries = 1; // Number of retries allowed
  let retryCount = 0;

  async function makeRequest() {
    try {
      const resp = await fetch(url, {
        headers,
        method,
        body: body ? JSON.stringify(body) : null,
      });
      if (!resp?.ok) {
        if (retryCount < maxRetries) {
          retryCount++;
          console.log(`Retrying request (${retryCount}/${maxRetries})...`);
          return makeRequest(); // Retry the request
        } else {
          console.error(`Max retries (${maxRetries}) reached. Unable to complete the request.`);
          showToastMessage({
            type: 'error',
            title: 'Error',
            description:
              'An error occurred trying to load the editor, Kindly refresh your page. Page will auto refresh in 3s',
            duration: 8,
          });
          setTimeout(() => {
            window.location.reload();
          }, 3000);
          return console.error(resp.text());
        }
      }
      const respData = !isText ? await resp.json() : await resp.text();
      return callback(respData);
    } catch (err) {
      return console.error(err);
    }
  }

  return makeRequest();
}

export function initStripo(options, emailId, onTemplateLoaded) {
  const apiRequestData = {
    emailId,
  };
  const script = document.createElement('script');
  script.id = 'stripoScript';
  script.type = 'text/javascript';
  script.src = 'https://plugins.stripo.email/static/latest/stripo.js';
  script.onload = function () {
    window.Stripo.init({
      unSubscribeLink: 'http://unsubscribe.com',
      socialNetworks: [
        {
          name: 'twitter',
          href: 'https://twitter.com/my-twitter',
        },
        {
          name: 'facebook',
          href: 'https://facebook.com/my-facebook',
        },
      ],
      mergeTags: [
        {
          category: 'Default',
          entries: [
            {
              label: 'First Name',
              value: '{{FIRST_NAME}}',
            },
            {
              label: 'Last Name',
              value: '{{LAST_NAME}}',
            },
            {
              label: 'Email',
              value: '{{EMAIL_ADDRESS}}',
            },
            {
              label: 'Full Name',
              value: '{{FULL_NAME}}',
            },
          ],
        },
      ],
      specialLinks: [
        {
          category: 'eSputnik',
          entries: [
            { label: 'Unsubscribe', value: 'https://esputnik.com/unsubscribe' },
            {
              label: 'View in browser',
              value: 'https://esputnik.com/viewInBrowser',
            },
          ],
        },
        {
          category: 'Other',
          entries: [
            {
              label: 'Some special link',
              value: 'https://some.special.link.url',
            },
          ],
        },
      ],
      settingsId: 'stripoSettingsContainer',
      previewId: 'stripoPreviewContainer',
      codeEditorButtonId: 'codeEditor',
      undoButtonId: 'undoButton',
      redoButtonId: 'redoButton',
      locale: 'en',
      html: options.html,
      css: options.css,
      onTemplateLoaded,
      enableXSSSecurity: true,
      ampFormServices: true,
      // externalVideosLibrary: {
      //   buttonText: 'Pick up my video',
      //   open: window.ExternalVideosLibrary,
      // },
      // externalImagesLibrary: window.ExternalImagesLibrary,
      // externalFilesLibrary: {
      // 	buttonText: "Pick up my file",
      // 	open: window.ExternalFilesLibrary,
      // },
      apiRequestData: apiRequestData,
      userFullName: 'Plugin Demo User',
      versionHistory: {
        changeHistoryLinkId: 'changeHistoryLink',
        onInitialized: function (lastChangeIndoText) {
          // console.log({ lastChangeIndoText });
          // eslint-disable-next-line no-undef
          $('#changeHistoryContainer').show();
          // document.getElementById('changeHistoryContainer')
        },
      },
      getAuthToken: async function (callback) {
        stripoApiRequest(
          'GET',
          API_HOST + '/strippo/token',
          null,
          function (data) {
            callback(data?.data?.token);
          },
          { 'x-access-token': token }
        )
          .then(() => {
            onTemplateLoaded();
          })
          .catch(() => {
            showToastMessage({
              type: 'error',
              title: 'Error',
              description: 'An error occurred trying to load the editor, Kindly refresh your page',
              duration: 8,
            });
          });
      },
      canBeSavedToLibrary: function (moduleHtml) {
        //Disable to save as modules all containers/structures/stripes that contain image blocks
        if (moduleHtml.indexOf('esd-block-image') > -1) {
          // show notifications if needed
          // notifications.error('Image blocks can not be saved as modules');
          console.log('Image blocks can not be saved as modules');
          return false;
        }
        return true;
      },
      ignoreClickOutsideSelectors: [
        '#externalPopupContainer',
        '#externalImagesLibrary',
        '#externalFileLibrary',
        '#externalDisplayConditionsPopup',
        '#externalFileLibrary',
      ],
      externalLinkControlConfiguration: {
        getMarkup: function () {
          return (
            '<div class="form-group">' +
            '   <div class="col-xs-12">' +
            '       <label for="eventIdControl">Event ID:</label>' +
            '       <span id="eventIdControl" style="font-weight: bold"></span>' +
            '   </div>' +
            '   <div class="col-xs-12">' +
            '       <button id="customizeLinkEventId" class="btn btn-success btn-block">' +
            '           Customize' +
            '       </button>' +
            '   </div>' +
            '</div>'
          );
        },
        onRendered: function (getLinkDomElement, getControlDomContainer, applyChangesCallback) {
          const customizeButton = getControlDomContainer().querySelector('#customizeLinkEventId');
          customizeButton.addEventListener('click', function () {
            window.PopupLinksSettings.openPopup(getLinkDomElement(), function () {
              applyChangesCallback();
              getControlDomContainer().querySelector('#eventIdControl').innerHTML =
                getLinkDomElement().getAttribute('event-id');
            });
          });
        },
        updateControlValues: function (getLinkDomElement, getControlDomContainer) {
          getControlDomContainer().querySelector('#eventIdControl').innerHTML =
            getLinkDomElement().getAttribute('event-id');
        },
      },
      externalCustomFont: window.ExternalCustomFont,
      addCustomFontTitle: 'Add custom font',
      editorFonts: {
        showDefaultStandardFonts: true,
        showDefaultNotStandardFonts: true,
        customFonts: [
          {
            name: 'Oswald',
            fontFamily: "'Oswald', 'helvetica neue', helvetica, arial, sans-serif",
            url: 'https://fonts.googleapis.com/css?family=Oswald',
          },
          {
            name: 'Barriecito',
            fontFamily: "'Barriecito', cursive",
            url: 'https://fonts.googleapis.com/css?family=Barriecito&display=swap',
          },
          {
            name: 'Inter',
            fontFamily: "'Inter', sans-serif",
            url: 'https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap',
          },
        ],
      },
    });
  };
  document.body.appendChild(script);
}

export function loadDemoTemplate(
  callback,
  { htmlTemplate, cssTemplate, emailId, loadedFromMaildrip = false, ...rest },
  onTemplateLoaded = () => {}
) {
  if (loadedFromMaildrip) {
    callback({ html: htmlTemplate, css: cssTemplate, ...rest }, emailId, onTemplateLoaded);
  } else {
    stripoApiRequest(
      'GET',
      htmlTemplate,
      null,
      function (html) {
        stripoApiRequest(
          'GET',
          cssTemplate,
          null,
          function (css) {
            callback({ html, css, ...rest }, emailId, onTemplateLoaded);
          },
          {},
          true
        );
      },
      {},
      true
    );
  }
  window.ExternalPreviewPopup = exPreviewPopup();
  window.ExternalVideosLibrary = exVideoLirary();
  window.PopupLinksSettings = externalLinkEventPopup();
  window.ExternalImagesLibrary = externalImgLib();
  window.ExternalCustomFont = extCustomFont(); //This is for paid plans
}
