import { showToastMessage } from '../../../utils/Toast';
export const onSystemFileSelected = (e, files = [], setFiles, setIsUploading, inputRef, singleFile = false) => {
  let allFiles = [];
  const handleFile = (loadedFiles) => {
    inputRef.current.value = '';
    !singleFile ? setFiles([...files, ...loadedFiles]) : setFiles([...loadedFiles]);
  };
  const selectedFiles = e.target.files;
  const allFileSizes = files?.reduce((a, b) => a + b?.size, 0) || 0;
  let totalSize = allFileSizes;

  if (selectedFiles.length === 0) {
    console.log('No file chosen.');
  } else {
    for (let i = 0; i < selectedFiles.length; i++) {
      const file = selectedFiles[i];
      const fileSize = file.size; // Get the size of the current file in bytes
      totalSize += fileSize;

      allFiles.push({
        file: selectedFiles[i],
        id: String(Date.now() + Math.random() * 1000),
        src: `${URL.createObjectURL(selectedFiles[i])}`,
        status: 'WAITING',
        filename: selectedFiles[i].name,
        size: fileSize,
        createdAt: new Date(),
      });
    }
  }

  // Check if the total file size exceeds 25MB (in bytes)
  const maxSizeBytes = 25 * 1024 * 1024; // 25MB in bytes
  if (totalSize > maxSizeBytes) {
    showToastMessage({
      type: 'error',
      title: 'Exceeds Limit',
      description: 'File size exceeds the limit of 25MB',
    });
    setIsUploading(false); // Set uploading state to false
    return; // Return without handling the files further
  }
  handleFile(allFiles);
  setIsUploading(true);
};
