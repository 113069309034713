import React from 'react';

export const MdPasswordStrength = ({ passwordStrength }) => {
  return (
    <div className="passwordStrengthContainer">
      <span className={`strength${passwordStrength >= 1 ? ' strength-level1' : ''}`}></span>
      <span className={`strength${passwordStrength >= 2 ? ' strength-level2' : ''}`}></span>
      <span className={`strength${passwordStrength >= 3 ? ' strength-level3' : ''}`}></span>
      <span className={`strength${passwordStrength >= 4 ? ' strength-level4' : ''}`}></span>
      <span className={`strength${passwordStrength === 5 ? ' strength-level5' : ''}`}></span>
    </div>
  );
};
