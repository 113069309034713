import React from 'react'

export const PaymentError = () => {
  return (
    <div style={{textAlign: 'center'}}>
    <h2 className="payment_form__header">
      <span>An Error Occured</span>
    </h2>
    <div className="accordion">
      <h6>Please try again</h6>
    </div>  
  </div>
  )
}