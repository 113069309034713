import React, { useState, useContext } from 'react';
import {
  Form,
  Input,
  Modal
} from 'antd';
import { FORM_FIELDS } from '../../../contexts/joincampaign/reducer/types';
import { joinCampaignContext } from '../../../contexts/joincampaign';
import { showToastMessage } from 'utils/Toast';

function AddFormFieldModal({ isShowingModal, closeModal }) {
  const [name, setName] = useState('');
  const [placeholder, setPlaceholder] = useState('');
  const { state, dispatch } = useContext(joinCampaignContext);
  const [loading, setLoading]= useState(false)
  const { formFields } = state;


  const [addFormFieldForm] = Form.useForm();

  const updateName = (event) => {
    setName(event.target.value);
  };

  const updatePlaceholder = (event) => {
    setPlaceholder(event.target.value);
  };

  const handleSubmit = () => {
    setLoading(true);
    // e.preventDefault();
    if (
      name?.toLowerCase().includes('email') ||
      placeholder?.toLowerCase().includes('email') ||
      name?.toLowerCase().includes('email address') ||
      placeholder?.toLowerCase().includes('email address') ||
      name?.toLowerCase().includes('mail') ||
      placeholder?.toLowerCase().includes('mail')||
      name?.toLowerCase().includes('mail address') ||
      placeholder?.toLowerCase().includes('mail address') 
    ) {
      showToastMessage({
        type: 'error',
        title: 'Error',
        description: 'Cannot add email as field',
      });
      setLoading(false);
    } else {
      // const newData = { name, placeholder, required: isRequired, enabled: isEnabled, deletable: true };
      const newData = { name, placeholder, deletable: true };
      dispatch({ type: FORM_FIELDS, payload: [...formFields, newData] }) && setLoading(false);
      resetStates();
      closeModal();
    }
  };

  const resetStates = () => {
    // setIsRequired(false);
    // setIsEnabled(false);
    setName('');
    setPlaceholder('');
  };
  return (
    <>
      <Modal
        footer={null}
        style={{
          top: 20,
        }}
        open={isShowingModal}
        closable={true}
        onCancel={() => {
          resetStates();
          closeModal();
        }}
        destroyOnClose={true}
        className="add-fields-modal"
        centered
      >
        <Form
          form={addFormFieldForm}
          name="basic"
          onFinish={handleSubmit}
          size="large"
          // onFinishFailed={() => null}
          className="flex flex-col"
        >
          <>
            <label>Name</label>
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Please enter input name',
                  whitespace: true,
                },
              ]}
              className="input-label"
            >
              <Input
                data-test="name"
                // type=""
                // name="name"
                onChange={updateName}
                placeholder="Please enter input name"
                style={{ height: '35px' }}
              />
            </Form.Item>
          </>
          <>
            <label>Placeholder</label>
            <Form.Item
              name="placeholder"
              rules={[
                {
                  required: true,
                  message: 'Placeholder can not be empty',
                   whitespace: true,
                },
              ]}
              className="input-label"
            >
              <Input
                data-test="placeholder"
                // name="placeholder"
                onChange={updatePlaceholder}
                placeholder="Placeholder can not be empty"
                style={{ height: '35px' }}
              />
            </Form.Item>
          </>
          <button
            type="submit"
            data-test="submit"
            className="bg-black text-white p-3 mt-10 hover:text-zinc-200"
            disabled={loading && true}
          >
            Add
          </button>
        </Form>
      </Modal>
    </>
  );
}

export default AddFormFieldModal;
