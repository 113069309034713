export default function InformationIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width || '800px'} height={props.width || '800px'} viewBox="0 0 24 24" fill="none">
      <path
        d="M12 16.99V17M12 7V14M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
        stroke="#eed202"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
