export const WifiIcon = (props) => {
  return (
    <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        id="Wifi"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.01501 2.60802C9.02999 2.60811 10.9679 3.45955 12.4283 4.98635C12.5382 5.10422 12.714 5.10274 12.8223 4.98301L13.8735 3.81635C13.9283 3.75563 13.9589 3.67337 13.9585 3.58779C13.958 3.50221 13.9266 3.42036 13.8711 3.36035C10.0382 -0.679198 3.99127 -0.679198 0.158344 3.36035C0.102822 3.42032 0.0713192 3.50214 0.0708071 3.58772C0.0702955 3.67331 0.100816 3.75558 0.155616 3.81635L1.20711 4.98301C1.31534 5.10292 1.49125 5.1044 1.60115 4.98635C3.06168 3.45945 4.99983 2.60801 7.01501 2.60802ZM7.01502 6.40368C8.12213 6.40361 9.18972 6.85614 10.0104 7.67335C10.1214 7.78933 10.2962 7.78681 10.4044 7.66768L11.4544 6.50102C11.5097 6.43982 11.5404 6.3568 11.5396 6.27054C11.5388 6.18427 11.5066 6.10196 11.4501 6.04202C8.95112 3.48563 5.08105 3.48563 2.58202 6.04202C2.52558 6.10196 2.49337 6.18431 2.49263 6.27061C2.49189 6.3569 2.52267 6.43991 2.57808 6.50102L3.62776 7.66768C3.73596 7.78681 3.91081 7.78933 4.0218 7.67335C4.8419 6.85668 5.90865 6.40419 7.01502 6.40368ZM9.11829 8.9575C9.11989 9.04401 9.08897 9.12741 9.03282 9.18802L7.21659 11.2037C7.16334 11.2629 7.09076 11.2963 7.01502 11.2963C6.93928 11.2963 6.86669 11.2629 6.81345 11.2037L4.99692 9.18802C4.9408 9.12736 4.90993 9.04394 4.91159 8.95743C4.91326 8.87092 4.94731 8.789 5.00571 8.73102C6.16562 7.65213 7.86442 7.65213 9.02433 8.73102C9.08269 8.78905 9.11668 8.87099 9.11829 8.9575Z"
        fill="#1C2127"
      />
    </svg>
  );
};
