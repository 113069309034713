import React from 'react';
import '../../../styles/instantemails.scss';
import MdGlobalButton from '../../../components/global/Button';
import { MdTypography } from '../../../components/global';
import { DatePicker } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import dayjs from 'dayjs';

const Scheduler = ({ visible, onClose, onChangeCallback }) => {
  const [selectedDate, setSelectedDate] = useState({});
  const [selectedTime] = useState();
  const [dateFormat] = useState('YYYY-MM-DD HH:mm');

  const onChange = (date, dateString) => {
    const isoString = moment(dateString).toISOString();
    onChangeCallback(isoString);
  };

  return (
    <>
      <DatePicker
        open={visible}
        className="custom-picker"
        onSelect={(date) => setSelectedDate({ ...date })}
        format={dateFormat}
        showSecond={false}
        showNow={false}
        minuteStep={5}
        onChange={onChange}
        defaultValue={dayjs()}
        disabledDate={(current) => current.isBefore(moment().startOf('day').add(selectedTime, 'minutes'))}
        inputReadOnly
        showTime={{
          format: 'hh:mm',
          hideDisabledOptions: true,
          disabledHours: () => {
            const disabledHrs = [];

            if (moment(selectedDate.$d).isSame(moment(), 'day')) {
              const currentHour = moment().hours();

              if (currentHour > 0) {
                const prevHr = moment().subtract(1, 'hours').hours();

                for (let i = 0; i <= prevHr; i++) {
                  disabledHrs.push(i);
                }
              }
            }

            return disabledHrs;
          },
          disabledMinutes: (selectedHour) => {
            const disabledMins = [];

            if (moment(selectedDate.$d).isSame(moment(), 'day')) {
              const currentHr = moment().hours();

              if (currentHr === selectedHour) {
                const prevMin = moment().subtract(1, 'minutes').minutes();
                for (let i = 0; i <= prevMin; i++) {
                  disabledMins.push(i);
                }
              }
            }
            return disabledMins;
          },
        }}
        dateRender={(current) => (
          <div className="ant-picker-cell-inner" style={{ borderRadius: '50%' }}>
            {current.date()}
          </div>
        )}
        renderExtraFooter={() => (
          <MdGlobalButton
            id="primary_xs"
            dataTestId="schedule-closebtn-testId"
            className="mx-auto mt-5 mb-4"
            onClick={onClose}
          >
            <MdTypography variant={'btnRegular'}>Close</MdTypography>
          </MdGlobalButton>
        )}
        showToday={false}
      />
    </>
  );
};
export default Scheduler;
