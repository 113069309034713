export const CheckCircleGreen = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none" {...props}>
      <path
        d="M34.3468 20.51L24.3368 30.5433L20.4868 26.6933C20.2776 26.449 20.0202 26.2507 19.7307 26.1106C19.4412 25.9705 19.1259 25.8918 18.8045 25.8794C18.4832 25.867 18.1628 25.9212 17.8633 26.0385C17.5639 26.1558 17.292 26.3337 17.0646 26.5611C16.8372 26.7885 16.6592 27.0604 16.5419 27.3599C16.4246 27.6593 16.3705 27.9797 16.3829 28.3011C16.3953 28.6224 16.474 28.9377 16.6141 29.2272C16.7541 29.5167 16.9525 29.7741 17.1968 29.9833L22.6801 35.49C22.8981 35.7062 23.1567 35.8773 23.441 35.9934C23.7253 36.1095 24.0297 36.1684 24.3368 36.1666C24.9489 36.164 25.5355 35.921 25.9701 35.49L37.6368 23.8233C37.8555 23.6064 38.029 23.3483 38.1475 23.064C38.266 22.7796 38.327 22.4747 38.327 22.1666C38.327 21.8586 38.266 21.5536 38.1475 21.2693C38.029 20.9849 37.8555 20.7269 37.6368 20.51C37.1996 20.0754 36.6082 19.8314 35.9918 19.8314C35.3753 19.8314 34.7839 20.0754 34.3468 20.51ZM28.0001 4.66663C23.3852 4.66663 18.8739 6.0351 15.0368 8.599C11.1996 11.1629 8.20895 14.8071 6.4429 19.0707C4.67686 23.3343 4.21478 28.0258 5.1151 32.5521C6.01542 37.0783 8.23771 41.2359 11.5009 44.4991C14.7642 47.7623 18.9218 49.9846 23.448 50.8849C27.9742 51.7853 32.6658 51.3232 36.9294 49.5571C41.193 47.7911 44.8371 44.8004 47.401 40.9633C49.9649 37.1261 51.3334 32.6149 51.3334 28C51.3334 24.9358 50.7299 21.9016 49.5573 19.0707C48.3847 16.2397 46.6659 13.6675 44.4992 11.5008C42.3325 9.3341 39.7603 7.61538 36.9294 6.44277C34.0984 5.27016 31.0643 4.66663 28.0001 4.66663ZM28.0001 46.6666C24.3082 46.6666 20.6992 45.5718 17.6294 43.5207C14.5597 41.4696 12.1672 38.5543 10.7543 35.1434C9.34151 31.7325 8.97184 27.9793 9.6921 24.3583C10.4124 20.7373 12.1902 17.4112 14.8008 14.8006C17.4113 12.1901 20.7374 10.4122 24.3584 9.69197C27.9794 8.97171 31.7326 9.34137 35.1435 10.7542C38.5544 12.167 41.4697 14.5596 43.5209 17.6293C45.572 20.699 46.6668 24.308 46.6668 28C46.6668 32.9507 44.7001 37.6986 41.1994 41.1993C37.6987 44.7 32.9508 46.6666 28.0001 46.6666Z"
        fill="#37B34A"
      />
    </svg>
  );
};
