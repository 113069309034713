import React from 'react';
import { Input } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { MdSearchIcon } from 'pages/ContactPage/Icons/MdSearchIcon';
import { MdGlobalButton, MdTypography } from 'components/global';

export const MdOptInHeader = ({
  title,
  description,
  descriptionLink,
  OptInHeaderImg,
  searchPage,
  imLoading,
  onClick,
}) => {
  const redirectToHelpCenter = () => {
    window.open('https://maildripio.notion.site/Help-Center', '_blank');
  };

  return (
    <div className="contactHeaderCont">
      <div className="contactTextPart">
        <div className="flex item-center gap-2">
          <MdTypography variant={'h2'} className="contHTitle">
            {title}
          </MdTypography>
          <div className="beta-mode">Beta</div>
        </div>
        <div className="flex">
          <MdTypography variant={'btnLight'} className="contHDesc">
            {description}
          </MdTypography>
          <MdTypography onClick={redirectToHelpCenter} variant={'btnRegular'} className="contHDescLink ml-1 pointer">
            {descriptionLink}
          </MdTypography>
        </div>

        <div className="flex">
          <Input
            placeholder="Search Opt-In Page"
            allowClear
            className="contactSearchInput"
            prefix={imLoading ? <LoadingOutlined /> : <MdSearchIcon color="rgba(142, 142, 142, 1)" />}
            size="large"
            onChange={(e) => searchPage(e)}
          />
          <MdGlobalButton
            id={'primary_lg'}
            type="primary"
            htmlType="submit"
            //loading={isCreatingCampaign}
            dataTestId="create-opt-in-page"
            onClick={onClick}
            style={{ width: '185px', marginLeft: '10px' }}
          >
            <MdTypography variant={'h6Medium'} style={{ color: '#ffffff', fontSize: '14px' }}>
              Create New
            </MdTypography>
          </MdGlobalButton>
        </div>
      </div>
      <div className="contactImgPart">
        <img src={OptInHeaderImg} alt="OptIn page header" className="object-contain contHImg" />
      </div>
    </div>
  );
};
