export const UndoRedoIcon = ({ type }) => {
  return type === 'undo' ? (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.1299 19.06H7.12988C6.71988 19.06 6.37988 18.72 6.37988 18.31C6.37988 17.9 6.71988 17.56 7.12988 17.56H15.1299C17.4699 17.56 19.3799 15.65 19.3799 13.31C19.3799 10.97 17.4699 9.06 15.1299 9.06H4.12988C3.71988 9.06 3.37988 8.72 3.37988 8.31C3.37988 7.9 3.71988 7.56 4.12988 7.56H15.1299C18.2999 7.56 20.8799 10.14 20.8799 13.31C20.8799 16.48 18.2999 19.06 15.1299 19.06Z"
        fill="#717171"
      />
      <path
        d="M6.43006 11.56C6.24006 11.56 6.05006 11.49 5.90006 11.34L3.34006 8.78C3.05006 8.49 3.05006 8.01 3.34006 7.72L5.90006 5.16C6.19006 4.87 6.67006 4.87 6.96006 5.16C7.25006 5.45 7.25006 5.93 6.96006 6.22L4.93006 8.25001L6.96006 10.28C7.25006 10.57 7.25006 11.05 6.96006 11.34C6.82006 11.49 6.62006 11.56 6.43006 11.56Z"
        fill="#737A91"
      />
    </svg>
  ) : type === 'redo' ? (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.8701 19.06H8.87012C5.70012 19.06 3.12012 16.48 3.12012 13.31C3.12012 10.14 5.70012 7.56 8.87012 7.56H19.8701C20.2801 7.56 20.6201 7.9 20.6201 8.31C20.6201 8.72 20.2801 9.06 19.8701 9.06H8.87012C6.53012 9.06 4.62012 10.97 4.62012 13.31C4.62012 15.65 6.53012 17.56 8.87012 17.56H16.8701C17.2801 17.56 17.6201 17.9 17.6201 18.31C17.6201 18.72 17.2901 19.06 16.8701 19.06Z"
        fill="#717171"
      />
      <path
        d="M17.57 11.56C17.38 11.56 17.19 11.49 17.04 11.34C16.75 11.05 16.75 10.57 17.04 10.28L19.07 8.25001L17.04 6.22C16.75 5.93 16.75 5.45 17.04 5.16C17.33 4.87 17.81 4.87 18.1 5.16L20.66 7.72C20.95 8.01 20.95 8.49 20.66 8.78L18.1 11.34C17.95 11.49 17.76 11.56 17.57 11.56Z"
        fill="#737A91"
      />
    </svg>
  ) : (
    <></>
  );
};
