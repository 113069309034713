import React, { createContext, useReducer } from 'react';
import { reducer } from './reducer';

export const jobContext = createContext();

export default function JobContextProvider({ children }) {
  const jobs = JSON.parse(localStorage.getItem('jobs'));

  const initialState = {
    jobs: jobs ?? [],
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  return <jobContext.Provider value={{ state, dispatch }}>{children}</jobContext.Provider>;
}
