import React, { useState } from 'react';
import Folder from '../../../asset/Icons/Folder';
import NoFolder from '../../../asset/Icons/NoFolder';
import { MdTable } from '../../../lib';
import '../../../styles/instantemails.scss';
import MdTypography from '../Typography';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { growthBookId } from 'utils/constants';

const MdTabWithTable = ({
  menuItems = [{ label: '', key: '', disabled: false }],
  onClick = () => {},
  columns,
  onRow,
  rowClassName,
  defaultSelectedKey = menuItems[0].key,
  isDataLoading,
  campaignList,
  folderExtension,
  pagination,
  tableHeight,
}) => {
  const [selectedKey, setSelectedKey] = useState(defaultSelectedKey);

  const uiUpgradeFeature = useFeatureIsOn(growthBookId.uiUpgrade);

  const selectKeyTabHabler = (data) => {
    onClick(data);
    setSelectedKey(data.key);
  };

  const tableScrollProp = { y: '300px', x: true };
  if (tableHeight) {
    tableScrollProp.y = tableHeight;
  }

  return (
    <div className="mdtab-view flex ml-5">
      <div className="mdtab-view_selector  ">
        <div className="mdtab-view_selector__list">
          <MdTypography variant={'btnRegular'} className="title">
            By Status
          </MdTypography>
          {menuItems?.map((menuItem, index) => (
            <button
              onClick={() => selectKeyTabHabler(menuItem)}
              className={`mdtab-view_selector__list-item${selectedKey === menuItem.key ? ' selected' : ''}`}
              key={menuItem.key || index}
            >
              <span className="icon_contain">{menuItem.icon || 'N/A'}</span>
              <MdTypography variant={'btnRegular'}>{menuItem.label}</MdTypography>
            </button>
          ))}
        </div>
        {!uiUpgradeFeature
          ? folderExtension && (
              <div className="mt-12 ">
                <div className="mdtab-view_selector__list ">
                  <MdTypography variant={'btnRegular'} className="title">
                    By folder
                  </MdTypography>
                  <div className="flex mb-4 mt-4">
                    <NoFolder />
                    <MdTypography variant={'tinyText'} className="title ml-2 ">
                      No folder
                    </MdTypography>
                  </div>
                  <div className="flex mb-2">
                    <Folder />
                    <MdTypography variant={'btnRegular'} className="folder">
                      Create Folder
                    </MdTypography>
                  </div>
                  <div>
                    <MdTypography variant={'tinyText'} className="title">
                      Organize your emails by creating tags to group them
                    </MdTypography>
                  </div>
                </div>
              </div>
            )
          : ''}
      </div>
      <div className="mdtab-view_container ml-6">
        <MdTable
          scroll={tableScrollProp}
          sticky={true}
          rowKey={'_id'}
          onRow={onRow}
          rowClassName={rowClassName}
          rowSelection={null}
          showHeader={false}
          columns={columns}
          loading={isDataLoading}
          dataSource={campaignList}
          pagination={pagination}
        />
      </div>
    </div>
  );
};

export default MdTabWithTable;
