import { Button } from 'antd';
import { MdTypography } from 'components/global';
import React, { useEffect, useRef, useState } from 'react';
import { useCSVReader, lightenDarkenColor, formatFileSize } from 'react-papaparse';

const GREY = '#CCC';
const GREY_LIGHT = 'rgba(255, 255, 255, 0.4)';
const DEFAULT_REMOVE_HOVER_COLOR = '#A01919';
const REMOVE_HOVER_COLOR_LIGHT = lightenDarkenColor(DEFAULT_REMOVE_HOVER_COLOR, 40);
const GREY_DIM = '#686868';

const styles = {
  file: {
    background: 'linear-gradient(to bottom, #EEE, #DDD)',
    borderRadius: 20,
    display: 'flex',
    height: '100%',
    margin: '0 auto',
    width: 120,
    position: 'relative',
    zIndex: 10,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  info: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 10,
    paddingRight: 10,
  },
  size: {
    backgroundColor: GREY_LIGHT,
    borderRadius: 3,
    marginBottom: '0.5em',
    justifyContent: 'center',
    display: 'flex',
  },
  name: {
    backgroundColor: GREY_LIGHT,
    borderRadius: 3,
    fontSize: 12,
    marginBottom: '0.5em',
  },
  progressBar: {
    bottom: 14,
    position: 'absolute',
    width: '100%',
    paddingLeft: 10,
    paddingRight: 10,
  },
  zoneHover: {
    borderColor: GREY_DIM,
  },
  default: {
    borderColor: GREY,
  },
  remove: {
    height: 23,
    position: 'absolute',
    right: 6,
    top: 6,
    width: 23,
  },
  importBtn: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    backgroundColor: '#fbfbfb',
    borderRadius: 4,
    fontFamily: 'Paralucent-Medium',
    fontSize: 14,
    fontStyle: 'normal',
    color: '#273df2',
    border: 0,
    padding: 8,
  },
  contactBtn: {
    borderRadius: 4,
    backgroundColor: '#273df2',
    fontSize: 14,
    color: 'white',
    fontFamily: 'Paralucent-light',
    padding: 8,
  },
};

export default function MdCsvReader({ imVisible, setUploadStep, setCSVResult, setImVisible, uploadContactsTo }) {
  const { CSVReader } = useCSVReader();
  const [zoneHover, setZoneHover] = useState(false);
  const [removeHoverColor, setRemoveHoverColor] = useState(DEFAULT_REMOVE_HOVER_COLOR);

  const removeRef = useRef(null);

  const csvUploadDoneBtn = () => {
    if (removeRef?.current === null) {
      setImVisible((imVisible) => imVisible);
    } else {
      setUploadStep('2');
    }
  };

  useEffect(() => {
    if (imVisible?.id === '') {
      removeRef.current.click();
    }
  }, [imVisible]);
  /* eslint-disable */
  return (
    <div className="addNewContactModal">
      <div className="flex items-center">
        <MdTypography variant={'h5Medium'} className="text-center title w-full my-5">
          Import
        </MdTypography>
        <span>
          <span className="text-bold bold">1/</span>2
        </span>
      </div>
      <CSVReader
        onFileLoad={(e) => {
          console.log({ e });
        }}
        onUploadAccepted={(results) => {
          console.log('-------------------------');
          const [keys, ...values] = results?.data;
          const convertResults = values.map((array) => {
            return array.reduce((a, v, i) => {
              return { ...a, [keys[i].toLowerCase()]: v.trim() };
            }, {});
          });

          // Sanitize the converted results
          const sanitizedResults = convertResults.filter((contact) => {
            return Object.values(contact).some((value) => value); // Check if any property has a truthy value
          });

          setCSVResult(sanitizedResults);

          console.log('-------------------------');
          setZoneHover(false);
        }}
        onDragOver={(event) => {
          event.preventDefault();
          setZoneHover(true);
        }}
        onDragLeave={(event) => {
          event.preventDefault();
          setZoneHover(false);
        }}
      >
        {({ getRootProps, acceptedFile, ProgressBar, getRemoveFileProps, Remove }) => (
          <>
            <div
              {...getRootProps()}
              className="csvreader-container block h-32 w-full my-5 cursor-pointer fileUpload"
              style={Object.assign({}, styles.zone, zoneHover && styles.zoneHover)}
            >
              {acceptedFile ? (
                <>
                  <div style={styles.file}>
                    <div style={styles.info}>
                      <span style={styles.size}>{formatFileSize(acceptedFile.size)}</span>
                      <span style={styles.name}>{acceptedFile.name}</span>
                    </div>
                    <div style={styles.progressBar}>
                      <ProgressBar />
                    </div>
                    <div
                      {...getRemoveFileProps()}
                      style={styles.remove}
                      onMouseOver={(event) => {
                        event.preventDefault();
                        setRemoveHoverColor(REMOVE_HOVER_COLOR_LIGHT);
                      }}
                      onMouseOut={(event) => {
                        event.preventDefault();
                        setRemoveHoverColor(DEFAULT_REMOVE_HOVER_COLOR);
                      }}
                      ref={removeRef}
                    >
                      <Remove color={removeHoverColor} />
                    </div>
                  </div>
                </>
              ) : (
                <div className="centered h-full aboutto_upload">
                  <p>
                    <a
                      href="/assets/maildrip-recipient-sample.csv"
                      download="Maildrip Contact Sample.csv"
                      className="primaryColor"
                    ></a>
                  </p>
                  <MdTypography variant={'h6Light'} className="details block">
                    Upload .csv files by dragging & dropping
                  </MdTypography>
                  <MdTypography variant={'h6Light'} className="primaryColor mt-2">
                    or selecting them.
                  </MdTypography>
                  {/* <p className="details block">Only .csv file type is supported.</p> */}
                </div>
              )}
            </div>
          </>
        )}
      </CSVReader>
      <div className="emmaccen">
        <div className="flex-space-btw actionBtns mt-10">
          <Button
            size={'large'}
            type=""
            style={styles.importBtn}
            onClick={() => {
              setImVisible({ id: uploadContactsTo === 'campaignRecipient' ? '' : uploadContactsTo });
            }}
          >
            Back
          </Button>
          <Button size={'large'} type="primary" htmlType="submit" onClick={csvUploadDoneBtn} style={styles.contactBtn}>
            Proceed
          </Button>
        </div>
      </div>
    </div>
  );
}
