import { Form, Input } from 'antd';
import { MdTypography } from '../../components/global';
import React from 'react';
import { Link } from 'react-router-dom';
import '../../styles/loginPage.scss';
import { min3char, containAzDchar, mustHaveLetters, aZDigitSpeshChar } from '../../utils/formRules';

export const MdSignUpForm1 = () => {
  return (
    <>
      <Form.Item
        dataTestId="signup-name"
        label="Name"
        name="name"
        rules={[
          {
            required: true,
            message: 'Please input your name!',
          },
          ...min3char,
          ...mustHaveLetters,
          ...containAzDchar,
        ]}
      >
        <Input dataTestId="signup-name-input" placeholder="What should we call you" />
      </Form.Item>

      <div className="grid2">
        <Form.Item
          dataTestId="signup-company"
          label="Where do you work?"
          name="companyName"
          rules={[
            {
              required: true,
              message: 'Please, tell us where you work',
            },
            ...min3char,
            ...aZDigitSpeshChar,
          ]}
        >
          <Input dataTestId="signup-company-input" placeholder="Facebook Inc." />
        </Form.Item>
        <Form.Item
          dataTestId="signup-role"
          label="What’s your role?"
          name="companyRole"
          rules={[
            {
              required: true,
              message: 'Please tell us what you do',
            },
            ...min3char,
            ...aZDigitSpeshChar,
            { whitespace: true },
          ]}
        >
          <Input dataTestId="signup-role-input" placeholder="Growth Marketer" />
        </Form.Item>
      </div>
      <Form.Item
        dataTestId="signup-email"
        label="Your email"
        name="email"
        rules={[
          {
            required: true,
            message: 'Please input your email',
            type: 'email',
          },
        ]}
      >
        <Input dataTestId="signup-email-input" placeholder="What’s your email?" />
      </Form.Item>

      {/* <Link to="/"> */}
      <MdTypography
        variant={'h6Medium'}
        className="text-center already-have-an-account-lg"
        style={{ color: '#858A8F' }}
      >
        Already have an account?
        <Link to="/login" className="primaryColorLight ml-2">
          Login
        </Link>
      </MdTypography>
      {/* </Link> */}
    </>
  );
};
