import { apiAuthSlice } from './apiAuthSlice';

export const emailApiSlice = apiAuthSlice
  .enhanceEndpoints({ addTagTypes: ['ActiveCampaignEmails', 'DraftCampaignEmails', 'DeletedCampaignEmails'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getActiveCampaignEmails: builder.query({
        query: (campaignId) => ({
          url: `/campaigns/${campaignId}/active-mails`,
          method: 'GET',
        }),
        transformResponse: (response) => response?.data?.activeEmails || [],
        providesTags: ['ActiveCampaignEmails'],
      }),

      getDraftedCampaignEmails: builder.query({
        query: (campaignId, limit = 15, page = 0) => ({
          url: `/campaigns/${campaignId}/draft-mails?limit=${limit}&page=${page}`,
          method: 'GET',
        }),
        transformResponse: (response) => response?.data?.draftEmails || [],
        providesTags: ['DraftCampaignEmails'],
      }),

      getDeletedCampaignEmails: builder.query({
        query: (campaignId, limit = 15, page = 0) => ({
          url: `/campaigns/${campaignId}/deleted-mails?limit=${limit}&page=${page}`,
          method: 'GET',
        }),
        transformResponse: (response) => response?.data?.deletedEmails || [],
        providesTags: ['DeletedCampaignEmails'],
      }),

      deleteCampaignEmail: builder.mutation({
        query: ({ campaignId, emailId }) => ({
          url: `/campaigns/${campaignId}/${emailId}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['DeletedCampaignEmails'],
      }),

      sendCampaignEmailToTrash: builder.mutation({
        query: ({ campaignId, emailId }) => ({
          url: `campaigns/${campaignId}/${emailId}/send-mail-to-trash`,
          method: 'PATCH',
        }),
        invalidatesTags: ['DeletedCampaignEmails', 'ActiveCampaignEmails', 'DraftCampaignEmails'],
      }),

      restoreDeletedMailToDraft: builder.mutation({
        query: ({ campaignId, mailId }) => ({
          url: `campaigns/${campaignId + '/' + mailId}/restore-mail-to-draft`,
          method: 'PATCH',
        }),
        invalidatesTags: ['DeletedCampaignEmails', 'DraftCampaignEmails'],
      }),

      updateActiveEmailInterval: builder.mutation({
        query: ({ campaignId, pb }) => {
          const { body, subject, id, attachments, emailInterval, duration, number, designCode, img } = pb;
          let payload;
          if (designCode) {
            const formData = new FormData();
            formData.append('subject', subject);
            formData.append('body', body);
            formData.append('designCode', designCode);
            formData.append('emailInterval', emailInterval);
            formData.append('duration', duration);
            formData.append('number', number);
            formData.append('image', img);

            payload = formData;
          } else {
            payload = {
              subject,
              body,
              attachments,
              emailInterval,
              duration,
              number,
            };
          }
          return {
            url: `/campaigns/${campaignId}/${id}`,
            body: payload,
            method: 'PATCH',
          };
        },
        invalidatesTags: ['ActiveCampaignEmails', 'SpecificCampaignData'],
      }),

      restoreMailToEditing: builder.query({
        query: ({ campaignId, mailId }) => ({
          url: `campaigns/${campaignId + '/' + mailId}/restore-mail-to-editing`,
          method: 'GET',
        }),
      }),

      createCampaignMail: builder.mutation({
        query: ({ payload, campaignId }) => ({
          method: 'POST',
          url: `/campaigns/${campaignId}/addmail`,
          body: payload,
        }),
        invalidatesTags: ['ActiveCampaignEmails', 'DraftCampaignEmails', 'SpecificCampaignData'],
      }),

      editEmails: builder.mutation({
        query: ({ campaignId, emailId, payload }) => ({
          method: 'PATCH',
          url: `/campaigns/${campaignId}/${emailId}`,
          body: payload,
        }),
        invalidatesTags: ['ActiveCampaignEmails', 'DraftCampaignEmails'],
      }),

      saveDraftCampaignEmail: builder.mutation({
        query: ({ campaignId, mail }) => ({
          method: 'POST',
          url: `campaigns/${campaignId}/save-draft`,
          body: mail,
        }),
        invalidatesTags: ['DraftCampaignEmails'],
      }),

      confirmTemplate: builder.mutation({
        query: ({ emailId, isDefaultTemplate }) => ({
          url: `/templates`,
          method: 'POST',
          body: { emailId, isDefaultTemplate },
        }),
      }),

      getSpecificEmailAnalytics: builder.query({
        query: ({ emailId }) => ({
          url: `/analytics/email/${emailId}`,
          method: 'GET',
        }),
      }),
    }),
    overrideExisting: true,
  });

export const {
  useGetDeletedCampaignEmailsQuery,
  useGetDraftedCampaignEmailsQuery,
  useGetActiveCampaignEmailsQuery,
  useDeleteCampaignEmailMutation,
  useSendCampaignEmailToTrashMutation,
  useRestoreDeletedMailToDraftMutation,
  useRestoreMailToEditingQuery,
  useUpdateActiveEmailIntervalMutation,
  useCreateCampaignMailMutation,
  useEditEmailsMutation,
  useSaveDraftCampaignEmailMutation,
  useConfirmTemplateMutation,
  useGetSpecificEmailAnalyticsQuery,
} = emailApiSlice;
