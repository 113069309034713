import { notification } from 'antd';
import axios from 'axios';
import Cookie from 'js-cookie';
import juice from 'juice';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { BreadCrumb } from '../../components/emmaccen/sharedComponents/BreadCrumb';
import { EditorActionsTab } from '../../components/emmaccen/sharedComponents/EditorActionsTab';
import { showToastMessage } from '../../utils/Toast';
import { errorMessage } from '../../utils/getErrors';
import { getSingleEmailById, saveDraftEmail, uploadFile } from '../../utils/service';
import { grapesInit } from './config/grapesInit';
import './editor.scss';
import findTextComponent from 'utils/miniFunctions/findTextComponentGjs';
import { useGetSpecificCampaignDataQuery } from 'redux/featureApi/campaignApiSlice';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { growthBookId } from 'utils/constants';

export const API_HOST = process.env.REACT_APP_API_URL;
/* eslint-disable */
const Editor = () => {
  const [files, setFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [imLoading, setImLoading] = useState({ id: '' });
  const location = useLocation();
  const mailId = location?.state?.mailId;
  const firstMail = location?.state?.firstMail;
  const [editorState, setEditorState] = useState(null);
  const [mail, setMail] = useState({
    subject: '',
    body: '',
    // css: "",
    number: null,
    seconds: null,
    typeOfMail: 'maildrip-editor',
    emailInterval: '',
    attachments: [],
    order: 0,
  });
  const token = Cookie.get('token');
  // console.log(token);
  const { campaignId, campaignMailId } = useParams();
  const navigate = useNavigate();
  const juiceInit = (html, css) => juice.inlineContent(html, css);
  const [breadCrumbs] = useState([
    {
      name: `< Back`,
      link: `/campaign/${campaignId}/emails`,
      index: 0,
    },
  ]);

  const {
    data: campaignData,
    isLoading: loadingCampaign,
    isSuccess: campaignFetched,
    isError: campaignFailed,
    error: campaignFetchReqErr,
  } = useGetSpecificCampaignDataQuery({ campaignId });

  // useEffect(() => {
  //   (async function () {
  //     const response = await axios.get(`/campaigns/${campaignId}`, {
  //       'x-access-token': token,
  //     });

  //     const campaign = response.data.data;
  //     const pages = campaign.emails;
  //     const [fetchedMail] = pages.filter((f) => {
  //       if (f._id === campaignMailId) {
  //         return f;
  //       }
  //       return false;
  //     });
  //     setFiles(
  //       fetchedMail?.attachments?.map(({ src, filename, size }, index) => {
  //         return { src, filename, status: 'UPLOADED', id: index, size };
  //       })
  //     );
  //     console.log({ fetchedMail, editorState });
  //     if (editorState !== null && fetchedMail?.body === '') {
  //       console.log('yeah');
  //       // editorState?.on('load', function () {
  //       const defaultTextComponent = findTextComponent(editorState.DomComponents.getWrapper().components().at(0));
  //       console.log({ defaultTextComponent });
  //       if (defaultTextComponent) {
  //         const newContent = `Hello {{FIRST_NAME}} {{LAST_NAME}},`;
  //         const newTextComponent = editorState.DomComponents.addComponent({
  //           type: 'text',
  //           content: newContent,
  //         });
  //         defaultTextComponent.replaceWith(newTextComponent);
  //         editorState.trigger('update', { target: 'canvas' });
  //       }
  //       // });
  //     }
  //     if (fetchedMail?.content) {
  //       const inlineCode = juiceInit(fetchedMail?.content['gjs-html'], fetchedMail?.content['gjs-css']);
  //       setMail((prevData) => {
  //         return { ...prevData, ...fetchedMail, id: fetchedMail?._id, body: inlineCode };
  //       });
  //     } else {
  //       setMail((prevData) => {
  //         return { ...prevData, ...fetchedMail, id: fetchedMail?._id };
  //       });
  //     }
  //   })();
  // }, [campaignId, campaignMailId, token, editorState]);

  useEffect(() => {
    if (campaignFetched) {
      const pages = campaignData?.data?.emails;
      const [fetchedMail] = pages.filter((f) => {
        if (f._id === campaignMailId) {
          return f;
        }
        return false;
      });
      setFiles(
        fetchedMail?.attachments?.map(({ src, filename, size }, index) => {
          return { src, filename, status: 'UPLOADED', id: index, size };
        })
      );

      if (editorState !== null && fetchedMail?.body === '') {
        editorState?.on('load', function () {
          const defaultTextComponent = findTextComponent(editorState.DomComponents.getWrapper().components().at(0));
          if (defaultTextComponent) {
            const newContent = `Hello {{FIRST_NAME}} {{LAST_NAME}},`;
            const newTextComponent = editorState.DomComponents.addComponent({
              type: 'text',
              content: newContent,
            });
            defaultTextComponent.replaceWith(newTextComponent);
            editorState.trigger('update', { target: 'canvas' });
          }
        });
      }
      if (fetchedMail?.content) {
        const inlineCode = juiceInit(fetchedMail?.content['gjs-html'], fetchedMail?.content['gjs-css']);
        setMail((prevData) => {
          return { ...prevData, ...fetchedMail, id: fetchedMail?._id, body: inlineCode };
        });
      } else {
        setMail((prevData) => {
          return { ...prevData, ...fetchedMail, id: fetchedMail?._id };
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingCampaign, editorState]);

  useEffect(() => {
    setMail((prev) => ({ ...prev, attachments: files?.filter((file) => file.status !== 'ERROR').map((file) => file) }));
  }, [files]);

  async function getAllAssets() {
    try {
      const response = await axios.get(`/wb/${campaignMailId}/assets`);
      return response.data;
    } catch (error) {
      // setAssets(error.message);
    }
  }

  const strippoFeature = useFeatureIsOn(growthBookId.strippoIE);

  useEffect(() => {
    if (campaignFetched) {
      let assets = [];
      (async function () {
        assets = await getAllAssets();

        const edit = await grapesInit(assets, campaignMailId, token, strippoFeature);

        setEditorState(edit);
        const head = edit?.Canvas?.getDocument()?.head;
        head?.insertAdjacentHTML(
          'beforeend',
          `<link href="https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap" rel="stylesheet">`
        );

        let I18nManager = edit.I18n;
        I18nManager.addMessages({
          en: {
            domComponents: {
              // overriding the default Table, Table Cell
              names: {
                table: 'Components',
                cell: 'Elements',
              },
            },
            selectorManager: {
              // overriding the default Classes, Selected
              label: 'Selector',
              selected: 'Element Inheriting',
            },
          },
        });

        edit.on('load', function () {
          const prop = edit.StyleManager.getProperty('typography', 'font-family');
          // prop.set('options', [{ value: "'Inter', sans-serif", name: 'Inter' }]);
          var list = [];
          prop.set('list', list);
          prop.addOption({ id: 'Inter', label: 'Inter' });
          prop.set('list', list);
          prop.set('default', 'Inter');
          prop.set('defaults', 'Inter');
          // edit.StyleManager.render();

          //open import template modAL when a query === 'upload-code'
          const modal = edit.Modal;
          const query = new URLSearchParams(location?.search);
          const uploadQuery = query.get('key');
          if (uploadQuery === 'upload-code') {
            const importClick = document.querySelector('.gjs-pn-btn.fa.fa-download');
            importClick.click();
            edit.on('modal', (a, b, c) => {
              if (a.open && a.title.textContent === 'Import template') {
                modal.setTitle('<div>Import your Template [inline-html/css]</div>');
              }
            });
          }
        });
        // const catProps = edit.StyleManager;

        //Edit default input text to none

        edit.on('storage:load', function (e) {});
        edit.on('storage:start:store', function (e) {
          // Update body in the mail variable declaration when storage request is started
          setMail((prevData) => ({ ...prevData, body: juiceInit(e.html, e.css) }));
        });
        edit.on('storage:store', function (e) {
          // console.log('Stored ', e);
        });

        edit.on('asset:remove', async (e) => {
          await axios.delete(`/wb/${campaignMailId}/assets?key=${e?.attributes?.setid}`);
        });
      })();
    }
  }, [campaignFetched]);
  useEffect(() => {
    mailId &&
      getSingleEmailById(campaignId, mailId)
        .then(({ data: { data } }) => {
          setFiles(
            data?.email?.attachments.map((data, index) => {
              return data;
            })
          );
          setMail((prevData) => ({ ...prevData, ...data.email }));
        })
        .catch((error) => {
          openNotification('ERROR', 'Something went wrong, please try again later.');
        });
  }, [campaignId, mailId]);

  useEffect(() => {
    isUploading &&
      files.forEach((file) => {
        file.status !== 'UPLOADED' &&
          uploadFile(file.file, file.id)
            .then(
              ({
                data: {
                  data: { id, location, filename, status },
                },
              }) => {
                updateFileState(id, status, location, filename);
              }
            )
            .catch((err) => {
              updateFileState(file.id, 'ERROR', file.src, file.filename, file.size);
            })
            .finally(() => {
              setIsUploading(false);
            });
      });
  }, [isUploading]);

  const updateFileState = (id, status, location, filename) => {
    setFiles((previousFiles) => {
      return previousFiles.map((file) =>
        file.id === id ? { ...file, status, src: location, filename, size: file.size, createdAt: file.createdAt } : file
      );
    });
  };

  const openNotification = (message, description) => {
    notification.info({
      message,
      description,
    });
  };

  const saveDraft = () => {
    setImLoading({ id: 'draftingEmail' });
    saveDraftEmail({ campaignId, mail, setImLoading })
      .then((mail) => {
        showToastMessage({
          type: 'success',
          title: 'Draft Email',
          description: 'Your mail has been saved as draft',
        });
        navigate(`/campaign/${campaignId}/emails`, { replace: true });
      })
      .catch((error) => {
        const errMsg = errorMessage(error);
        console.log(errMsg);
        showToastMessage({
          type: 'error',
          title: 'Error',
          description: errMsg,
        });
      });
  };

  const convertStringToHTML = (htmlString) => {
    const parser = new DOMParser();
    const html = parser.parseFromString(htmlString, 'text/html');
    return html.body;
  };
  const emailPageUpdateFully = useCallback(() => {
    // setWantSave(true);
    // setIsLoading(true);
    setImLoading({ id: 'emailAction' });
    editorState?.store((res) => {});
    editorState?.on('storage:store', async function (e) {
      const inlineCode = juice.inlineContent(e.html, e.css);
      const attachments = files?.filter((file) => file.status !== 'ERROR').map((file) => file);
      const body = convertStringToHTML(e.html);
      const textContent = body.textContent;
      const imgElements = body.querySelectorAll('img');

      if (textContent.trim() === '' && imgElements.length <= 0) {
        showToastMessage({
          type: 'warning',
          title: 'Empty Newsletter',
          description: 'Email body cannot be empty',
        });
        setImLoading({ id: '' });
        return;
      }
      setMail((prevData) => {
        return {
          ...prevData,
          body: inlineCode,
        };
      });
      await axios.patch(`/campaigns/${campaignId}/${campaignMailId}`, {
        ...mail,
        body: inlineCode,
        attachments,
      });
      showToastMessage({
        type: 'success',
        title: 'Saved',
        description: 'Mail template saved successfully',
      });
      // window.location.replace(`/campaign/${campaignId}/${mail?._id}/mailtemplate-confirm`);
      navigate(
        {
          pathname: `/campaign/${campaignId}/${mail?._id}/mailtemplate-confirm`,
        },
        {
          replace: true,
          state: {
            firstMail,
          },
        }
      );
      setImLoading({ id: '' });
    });
  }, [editorState, mail, files]);

  return (
    <>
      <div className="emmaccen">
        <div className="pt-8 grapesForMobileWidth">
          <div className="flex flex-space-btw align-items-center mb-5 editorHeaderTab">
            <BreadCrumb crumbs={breadCrumbs}></BreadCrumb>
            <EditorActionsTab
              imLoading={imLoading}
              setImLoading={setImLoading}
              campaignId={campaignId}
              setMail={setMail}
              saveDraft={saveDraft}
              mail={mail}
              handleEmailCreation={emailPageUpdateFully}
              filesProps={[files, setFiles, setIsUploading]}
            />
          </div>
          <div className="borderShade1 p-1">
            <div className="flex flex-row panel__top">
              <div className="panel__editor"></div>
            </div>
            <div id="gjs">
              <h1></h1>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Editor;
