import { apiAuthSlice } from './apiAuthSlice';

export const emailReportApiSlice = apiAuthSlice
  .enhanceEndpoints({ addTagTypes: ['EmailTableReport', 'EmailReport'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getEmailTableReport: builder.query({
        query: (mailId) => ({
          url: `/reporting/${mailId}/delivered-table`,
          method: 'GET',
        }),
        providesTags: ['EmailTableReport'],
      }),
      fetchEmailReport: builder.query({
        query: (mailId) => ({
          url: `/reporting/${mailId}`,
          method: 'GET',
        }),
        providesTags: ['EmailReport'],
        transformResponse: (response) => response?.data ?? {},
      }),
    }),
  });

export const { useGetEmailTableReportQuery, useFetchEmailReportQuery } = emailReportApiSlice;
