import React, { useEffect, useState, useContext } from 'react';
// import { useParams } from 'react-router-dom';
import { Formik } from 'formik';
import { Spin } from 'antd';
import { SET_JOIN_CAMPAIGN_FIELDS } from 'contexts/joincampaign/reducer/types';
import defaultLogo from '../../asset/images/deliver.png';
import { joinCampaignContext } from '../../contexts/joincampaign';
import { guestJoinCampaign, getPublishPageRequest } from '../../utils/service';
import { showToastMessage } from '../../utils/Toast';
import { mustBeValidUrl, containValidEmail } from 'utils/formRules';
import HideFormillaChatBox from 'utils/miniFunctions/HideFormillaChatBox';
import { useLocation } from 'react-router-dom';

export default function JoinPage() {
  const [pageLoading, setPageLoading] = useState(true);
  const { state, dispatch } = useContext(joinCampaignContext);
  const query = useLocation().search;
  const campaignId = new URLSearchParams(query).get('id');

  const { btnText, sectionbackground, tagline, catchphrase, btnColor, logo } = state;
  const [campaignInfo, setCampaignInfo] = useState({});

  useEffect(() => {
    getJoinPageValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getJoinPageValues = () => {
    getPublishPageRequest(campaignId)
      .then(({ data: { data } }) => {
        setCampaignInfo(data);
        setCampaignInfo(data);

        const payload = {
          tagline: data.tagline || 'Join Campaign',
          btnText: data.button.text || 'Join',
          catchphrase: data.headline || 'Some description or catch phrase goes in here',
          alttext: data.alttext,
          sectionbackground: data.background_color || '#ffffff',
          btnColor: data.button.background_color,
          logo: data.logo_url,
          formFields: data.form_fields || [],
          setLink: data.success_redirect_url,
        };
        dispatch({ type: SET_JOIN_CAMPAIGN_FIELDS, payload });
        setPageLoading(false);
      })
      .catch((error) => {
        console.log({ error });
        setPageLoading(false);
        showToastMessage({
          type: 'error',
          title: 'Error',
          description: 'Sorry, an error occured',
        });
      });
  };

  const handleSubmit = (
    { e, email_address, first_name, last_name, ...rest },
    { setSubmitting, setFieldError, resetForm }
  ) => {
    setSubmitting(true);
    const payload = {
      name: `${first_name} ${last_name}`,
      email: email_address,
      metadata: {
        email: email_address,
        name: `${first_name} ${last_name}`,
        ...rest,
      },
    };

    guestJoinCampaign(payload, campaignId)
      .then((e) => {
        resetForm({});
        setSubmitting(false);
        showToastMessage({
          type: 'success',
          title: 'Success',
          description: 'Your email has been added, you can unsubscribe at any time',
        });

        const redirectUrl = campaignInfo?.success_redirect_url;
        if (
          campaignInfo?.success_redirect_url !== '' &&
          mustBeValidUrl[0].pattern.test(campaignInfo?.success_redirect_url)
        ) {
          window.location.replace(redirectUrl);
        }
      })
      .catch((error) => {
        setSubmitting(false);
        if (error.response?.data?.message === 'Email already exist in campaign')
          setFieldError('email', 'Email already exist in campaign');
        else
          showToastMessage({
            type: 'error',
            title: 'Error',
            description: 'An error occured',
          });
      });
  };

  if (pageLoading) {
    return (
      <div className="centerLoadingPage">
        <Spin />
      </div>
    );
  }

  const handleValidation = (values) => {
    const errors = {};

    campaignInfo?.form_fields
      ?.filter((field) => field.enabled)
      .forEach((field) => {
        // check each value if empty
        if (!field.id.includes('email') && field.required && !values[field.id]) {
          errors[field.id] = `Please enter your ${field.name}`;
        }
        // check email if valid
        if (field.id.includes('email') && field.required && !containValidEmail[0].pattern.test(values[field.id])) {
          errors[field.id] = `Please enter a valid email address`;
        }
      });
    return errors;
  };

  return (
    <div
      className="flex items-center justify-center w-full min-h-screen sm:px-5"
      id="section-bg"
      style={{
        backgroundColor: sectionbackground,
      }}
    >
      <HideFormillaChatBox />
      <div className="flex flex-col items-center justify-center w-full p-10 rounded-md lg:max-w-lg sm:max-w-sm md:max-w-md">
        <div className="flex flex-col items-center justify-center mb-4 text-center">
          <img src={logo ? logo : defaultLogo} alt="" className="h-24"></img>
          <h1 className="text-4xl font-extrabold leading-tight" id="tagline-text">
            {tagline}
          </h1>
          <p className="text-sm text-gray-700" id="catch-phrase">
            {catchphrase}
          </p>
        </div>
        {!pageLoading && (
          <Formik
            initialValues={{
              // Initialize other fields with empty strings
              ...campaignInfo?.form_fields?.reduce((acc, field) => {
                return { ...acc, [field.id]: '' };
              }, {}),
            }}
            validateOnMount
            onSubmit={handleSubmit}
            // validationSchema={validationSchema}
            validate={handleValidation}
          >
            {({
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldError,
              errors,
              touched,
              isValid,
            }) => {
              return (
                <form className="w-full max-w-lg mt-5" onSubmit={handleSubmit}>
                  <div className="flex flex-wrap -mx-3 ">
                    <div className="w-full px-3">
                      {campaignInfo?.form_fields
                        ?.filter((field) => field.enabled)
                        .map((field, index) => (
                          <div key={index}>
                            <input
                              name={field?.id}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              onFocus={handleBlur}
                              value={values[field?.id]}
                              placeholder={field?.placeholder}
                              className={
                                'appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white mb-2'
                              }
                              data-test="join-input"
                            />

                            <div className="text-xs leading-loose text-orange-600 feedback-fade-in">
                              {errors[field?.id] && touched[field?.id] && <p>{errors[field?.id]}</p>}
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className="mt-2">
                    <button
                      disabled={isSubmitting || !isValid}
                      className="w-full p-3 font-bold text-white rounded-md font bold disabled:bg-gray-600"
                      id="action-btn"
                      style={{ backgroundColor: btnColor }}
                    >
                      {isSubmitting ? `${btnText}...` : btnText}
                    </button>
                  </div>
                </form>
              );
            }}
          </Formik>
        )}

        <div className="mt-3 text-sm hover:underline">
          <a className="text-blue-600" href="/">
            Powered by MailDrip
          </a>
        </div>
      </div>
    </div>
  );
}
