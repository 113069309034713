import React, { useContext, useState, useEffect, useRef } from 'react';
import { paymentContext } from '../../../contexts/payment';
import { SET_PLAN, SET_PRICE, SET_QUANTITY } from '../../../contexts/payment/reducer/types';
import './style.scss';
import { thousandFormatter } from '../../../utils/thousandFormatter';
import discount from 'asset/images/Discount.svg';
import usePaygPromoDates from 'hooks/usePaygPromoDates';

export default function PAYG() {
  const {
    paygPromoStartDate: startDate,
    paygPromoEndDate: endDate,
    withinPromoPeriod: withinPromoPeriodInit,
  } = usePaygPromoDates();
  const [withinPromoPeriod, setWithinPromoPeriod] = useState(withinPromoPeriodInit);
  const {
    state: { plan, quantity, currency, currencies, payg_rates },
    dispatch,
  } = useContext(paymentContext);

  const [timeLeft, setTimeLeft] = useState({});
  const debounceTimeout = useRef(null); // Ref to store the debounce timer
  const [inputQuantity, setInputQuantity] = useState(thousandFormatter(quantity)); // Track input field value
  const [previousQuantity, setPreviousQuantity] = useState(quantity); // Track the previous quantity

  const padWithZero = (value) => String(value).padStart(2, '0');

  const calculateTimeLeft = () => {
    const now = new Date();
    let difference = endDate - now;

    if (now >= startDate && now <= endDate) {
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((difference / 1000 / 60) % 60);
      const seconds = Math.floor((difference / 1000) % 60);

      return {
        message: `${padWithZero(days)}:${padWithZero(hours)}:${padWithZero(minutes)}:${padWithZero(seconds)}`,
      };
    } else {
      return null;
    }
  };

  useEffect(() => {
    const checkPromoPeriod = () => {
      const now = new Date();
      if (now >= startDate && now <= endDate) {
        setWithinPromoPeriod(true);
        setTimeLeft(calculateTimeLeft());
      } else {
        setWithinPromoPeriod(false);
        setTimeLeft({});
      }
    };

    const timer = setInterval(checkPromoPeriod, 1000);

    return () => clearInterval(timer);
    // eslint-disable-next-line
  }, [startDate, endDate]);

  // Debounce effect for dispatching quantity change
  useEffect(() => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      if (inputQuantity !== previousQuantity) {
        const price = inputQuantity * payg_rates[currency];
        dispatch({ type: SET_QUANTITY, payload: +inputQuantity });
        dispatch({ type: SET_PRICE, payload: price });
        dispatch({ type: SET_PLAN, payload: { _id: null, payg: true, topup: false } });
        setPreviousQuantity(inputQuantity); // Update the previous quantity to the new value
      }
    }, 1500); // 1.5 seconds debounce

    return () => clearTimeout(debounceTimeout.current); // Cleanup on unmount
  }, [inputQuantity, previousQuantity, currency, payg_rates, dispatch]);

  return (
    <div className={'payGoContainer payGoContainer mti-0'}>
      <div className={'flex-space-btw desc flex-space-btw desc mti-0'}>
        <p>PAYG (Pay-As-You-Go)</p>
      </div>

      {withinPromoPeriod && (
        <>
          <div className="discount-container">
            <img src={discount} alt="Discount" />
            <p className="discount-text ml-5">
              10% discount promo ends in: {timeLeft.message ? timeLeft.message : 'Promo ended'}
            </p>
          </div>
          <p className="__microcopy">Discount applied automatically at checkout</p>
        </>
      )}

      <div className={`mt-3 payInputSection ${Boolean(plan.payg) ? 'selected_card__border' : ''}`}>
        <p className="__microcopy">How many email credits do you want to buy?</p>

        <div className="flex-space-btw">
          <div className="__desc ">
            <p>Enter quantity</p>
            <input
              className="mt-2 PayGoPaymentInput"
              type="text"
              placeholder={thousandFormatter(0)}
              maxLength={6}
              value={thousandFormatter(inputQuantity)}
              onChange={({ target: { value } }) => {
                let val = value.replace(RegExp(/[^0-9]/g), '');
                if (val.length > 1) {
                  val = val.replace(/^0+/, ''); // Remove preceding zeros
                }
                setInputQuantity(val); // Update the input value for debounce
              }}
            />
          </div>

          <div className="payGoPriceDisplay centered">
            <h3>
              {currencies[currency]}
              {thousandFormatter(parseFloat(plan.payg ? inputQuantity * payg_rates[currency] : 0).toFixed(2))}
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
}
