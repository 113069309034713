import React from 'react';

export const MdPlus = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path d="M8 3.33334V12.6667" stroke="#273DF2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3.33331 8H12.6666" stroke="#273DF2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
