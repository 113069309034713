import { LoadingOutlined, PaperClipOutlined, WarningOutlined, EyeOutlined } from '@ant-design/icons';
import { Form, Input, Select, Button, Modal } from 'antd';
import React, { useRef, useState } from 'react';
import { sendTestMail } from '../../../utils/service';
import { showToastMessage } from '../../../utils/Toast';
import isValidForm from '../shared-functions/inputValidator';
import { onFileDropHandler } from '../shared-functions/onFileDropHandler';
import { onSystemFileSelected } from '../shared-functions/onSystemFileSelected';
import { ReactComponent as AttachIcon } from './assets/attachIcon.svg';

import { errorMessage } from '../../../utils/getErrors';
import { useEffect } from 'react';
import { intervals } from '../../../utils/constants';
import convertSecondsToNearestDur from '../../../utils/convertSecondsToNearestDuration';
/* eslint-disable */
const TestMailDropdown = ({ mail, setImLoading, campaignId, setMail, emailIntervalForm }) => {
  const { Option } = Select;
  const [testMailForm] = Form.useForm();

  return (
    <div className="emmaccen" onClick={(e) => e.preventDefault()}>
      <div className="p-5" style={{ maxWidth: '300px' }}>
        <p className="text-sm fade5">Campaign interval (optional)</p>
        <p className="text-xs fade2">Schedule a time to send this email after the previous one.</p>
        <Form
          name="basic"
          form={testMailForm}
          onFinish={({ number, seconds }) => testMail({ mail, setImLoading, campaignId })}
          onFinishFailed={() => null}
          autoComplete="off"
          size="large"
        >
          <div className="flex-space-btw mt-5">
            <Form.Item name="number" className="sm:w-full md:w-full lg:w-1/4 mr-4">
              <Input
                name="number"
                onChange={(e) => setMail({ ...mail, [e.target.name]: e.target.value })}
                placeholder="Number"
                type="number"
                min={1}
              />
            </Form.Item>
            <Form.Item name="seconds" className="w-full">
              <Select
                showSearch
                placeholder="Select Interval"
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                onChange={(value) => setMail({ ...mail, seconds: value })}
              >
                <Option value={60}>Minute(s)</Option>
                <Option value={60 * 60}>Hour(s)</Option>
                <Option value={24 * 60 * 60}>Day(s)</Option>
                <Option value={7 * 24 * 60 * 60}>Week(s)</Option>
                <Option value={30 * 24 * 60 * 60}>Month(s)</Option>
                <Option value={365 * 24 * 60 * 60}>Year(s)</Option>
              </Select>
            </Form.Item>
          </div>
        </Form>
      </div>
    </div>
  );
};

const testMail = ({ mail, setImLoading, campaignId, deleteFunction }) => {
  if (isValidForm([mail.body, mail.subject])) {
    setImLoading({ id: 'testMail' });
    sendTestMail(mail, campaignId)
      .then((mail) => {
        showToastMessage({
          type: 'success',
          title: 'Test Mail',
          description: mail?.data?.data?.message || 'Test mail has been sent to your registered email address',
        });
      })
      .catch((e) => {
        const errMsg = errorMessage(e);
        showToastMessage({
          type: 'error',
          title: 'Error',
          description: errMsg,
        });
      })
      .finally(() => setImLoading({ id: '' }));
  } else {
    setImLoading({ id: '' });
    showToastMessage({
      type: 'warning',
      title: 'Required Fields',
      description: 'Please, provide both email-body and subject',
    });
  }
};

export const InstantEmailEditorActionsTab = ({
  imLoading,
  filesProps,
  handleEmailCreation,
  setImLoading,
  mail,
  deleteFunction,
}) => {
  // Interval Start
  const [emailIntervalForm] = Form.useForm();
  const [intervalDeclaration, setIntervalDeclaration] = useState(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (mail?.emailInterval !== undefined && intervalDeclaration === null) {
      const { emailInterval } = mail;
      if (emailInterval) {
        const interval = convertSecondsToNearestDur(emailInterval);
        emailIntervalForm.setFieldsValue({
          ['number']: interval?.int,
          ['seconds']: intervals[interval?.unit],
        });
        setIntervalDeclaration(interval);
      }
    }
  }, [mail]);

  // Interval End

  const [isAttachmentModalVisible, setIsAttachmentModalVisible] = useState(false);

  const [files, setFiles, setIsUploading] = filesProps;

  const LoadFilesUi = () =>
    files?.map((fileData, index) => {
      const { src, filename, id } = fileData;
      return (
        <div
          className={fileData.status + ' flex justify-between items-center h-10 w-full rounded borderShade1 p-6'}
          key={index}
        >
          <div title={filename} className="flex">
            <PaperClipOutlined className="text-xl" />
            <p className="mx-3">{filename.slice(0, 15).concat('...')}</p>
            {fileData?.status === 'WAITING' ? (
              <div className="flex items-center">
                <LoadingOutlined />
              </div>
            ) : (
              fileData?.status === 'ERROR' && (
                <div className="flex items-center">
                  <WarningOutlined className="text-xl" />
                </div>
              )
            )}
          </div>
          <div className="flex items-center">
            {fileData?.status === 'ERROR' && (
              <p
                // onClick={() => setIsUploading(true)}
                className="mr-3 pointer underline"
              >
                Retry
              </p>
            )}
            {<EyeOutlined className="text-xl mr-3" onClick={() => window.open(fileData.src)} />}
            <Button loading={imLoading?.id === id} onClick={() => deleteFunction(fileData)} danger size="small">
              Remove
            </Button>
          </div>
        </div>
      );
    });
  const onDragHandler = (ev) => {
    ev.preventDefault();
  };

  const awaitimgUpload = files.filter((item) => item.status !== 'UPLOADED');

  const confirmEmailCreation = () => {
    if (awaitimgUpload.length > 0) {
      showToastMessage({
        type: 'warning',
        title: `${awaitimgUpload.length} file(s) in progress`,
        description: 'Please wait while all items in the list are complete',
        placement: 'topRight',
      });
      setTimeout(() => {
        setIsAttachmentModalVisible(true);
      }, 200);
      return;
    }
    handleEmailCreation(mail.number * mail.seconds);
  };

  return (
    <div>
      <Modal
        className="emmaccen"
        open={isAttachmentModalVisible}
        onOk={() => setIsAttachmentModalVisible(false)}
        onCancel={() => setIsAttachmentModalVisible(false)}
        footer={null}
        centered
      >
        <div className="p-5 attachFileModal">
          <h1 className="text-center title">Attach Files</h1>
          <label
            htmlFor="fileUpload"
            title="Drag and Drop file(s) to be attached to this email"
            onDrop={(e) => onFileDropHandler(e, files, setFiles, setIsUploading)}
            onDragOver={onDragHandler}
            className="block h-32 w-full my-5 cursor-pointer fileUpload"
          >
            <input
              type="file"
              onChange={(e) => onSystemFileSelected(e, files, setFiles, setIsUploading, fileInputRef)}
              className=""
              id="fileUpload"
              data-test="instant-attachment-input"
              multiple={true}
              ref={fileInputRef}
            ></input>
            <div className="centered h-full">
              <p className="details">
                Attach files by dragging & dropping or <span className="primaryColor">selecting them</span>.
              </p>
            </div>
          </label>
        </div>
        <div className="flex flex-wrap my-5">{LoadFilesUi()}</div>
        <div className="flex justify-end">
          <Button onClick={() => setIsAttachmentModalVisible(false)} type="primary" data-test="instant-attachmement-done">
            Done
          </Button>
        </div>
      </Modal>
      <div className="flex align-items-center actions">
        <Button
          onClick={() => setIsAttachmentModalVisible(true)}
          size={'large'}
          type="default"
          className="mr-3 ghostBtn flex align-items-center"
          data-test = "instant-email-attachment"
        >
          Attach
          <div style={{ marginLeft: '10px' }}>
            <AttachIcon />
          </div>
        </Button>

        <Button
          onClick={() => {
            confirmEmailCreation();
          }}
          loading={imLoading?.id === 'emailAction'}
          size={'large'}
          type="primary"
          className="primaryBtn"
          disabled={imLoading?.id === 'testMail'}
          data-test="text-editor-btn"
        >
          Continue
        </Button>
      </div>
    </div>
  );
};
