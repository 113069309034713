import { Button, Form, Input, Modal, Tooltip } from 'antd';
import Cookie from 'js-cookie';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUpdatePasswordMutation } from 'redux/featureApi/accountSettingsApiSlice';
import { deactivateUser } from '../../utils/service';
import { showToastMessage } from '../../utils/Toast';
import { passwordRules } from '../emmaccen/shared-data/passwordRule';
import { MdGlobalButton } from 'components/global';

export default function AccountSecurityTab() {
  const [loading, setLoading] = useState({ id: '' });
  const navigate = useNavigate();
  const [isPasswordModalVisible, setIsPasswordModalVisible] = useState(false);
  const [showError, setShowError] = useState('');
  const [passwordForm] = Form.useForm();
  const [formValid, setFormValid] = useState(false);
  const [updatePassword, { isLoading: updatingUserPassword, isSuccess, isError, error }] = useUpdatePasswordMutation();

  const showModal = () => {
    setIsPasswordModalVisible(true);
  };

  const handleOk = () => {
    setIsPasswordModalVisible(false);
  };

  const handleCancel = () => {
    passwordForm.resetFields();
    setIsPasswordModalVisible(false);
  };

  const deactivateProfile = async () => {
    setLoading({ id: 'deactivateProfile' });
    try {
      const res = await deactivateUser({
        isActive: false,
      });

      const { success } = res.data;
      if (success) {
        showToastMessage({
          type: 'error',
          title: 'Account Deactivated Successfully',
          description: "We're unhappy to see you go. Do leave us a feedback so that we can do better next time.",
        });
        Cookie.remove('token');
        localStorage.removeItem('userDetails');
        navigate('/login');
      }
    } catch (error) {
      showToastMessage({
        type: 'error',
        title: 'Error',
        description: error.response?.data?.message,
      });
    } finally {
      setLoading({ id: '' });
    }
  };

  const onFinish = async () => {
    const { oldPassword, confirmPassword: newPassword } = passwordForm.getFieldsValue();

    updatePassword({ oldPassword, newPassword })
      .unwrap()
      .then(() => {
        showToastMessage({
          type: 'success',
          title: 'Password Update',
          description: 'Password Updated successfully!',
        });
        passwordForm.resetFields();
        handleCancel();
      })
      .catch(() => {
        showToastMessage({
          type: 'error',
          title: 'Error',
          description: 'Unable to update password',
        });
        passwordForm.setFieldsValue({ oldPassword: '' });
      });
  };

  useEffect(() => {
    if (isError) {
      setShowError(error?.data?.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isError]);

  const validateConfirmPassword = ({ getFieldValue }) => ({
    validator(_, value) {
      if (!value || getFieldValue('newPassword') === value) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('The two passwords do not match!'));
    },
  });

  const handleFieldsChange = (_, allFields) => {
    if (allFields[0].touched === true && allFields[1].touched === true && allFields[2].touched === true) {
      if (allFields[0].errors.length === 0 && allFields[1].errors.length === 0 && allFields[2].errors.length === 0) {
        setFormValid(true);
      } else {
        setFormValid(false);
      }
    }
  };

  return (
    <>
      <Modal
        footer={false}
        title="Change Password"
        onOk={handleOk}
        onCancel={handleCancel}
        open={isPasswordModalVisible}
        close={handleCancel}
        destroyOnClose={true}
        centered
      >
        <div className="settings-modal-wrapper">
          <Form name="basic" form={passwordForm} onFieldsChange={handleFieldsChange} onFinish={onFinish}>
            <div className="form-group">
              <Form.Item
                name="oldPassword"
                rules={[
                  {
                    required: true,
                    message: 'Enter old password',
                  },
                ]}
              >
                <Input.Password
                  placeholder="Old Password"
                  className={showError ? 'form-control form-error' : 'form-control'}
                />
              </Form.Item>
              {error && (
                <div className="text-xs text-red" style={{ marginTop: '-20px', marginBottom: '20px' }}>
                  {showError}
                </div>
              )}
            </div>
            <div className="form-group newPass-input">
              <Form.Item
                name="newPassword"
                rules={[
                  {
                    required: true,
                    message: 'Enter new password',
                  },
                  ...passwordRules,
                ]}
              >
                <Input.Password placeholder="New Password" className="form-control" />
              </Form.Item>
            </div>
            <div className="form-group newPass-input">
              <Form.Item
                name="confirmPassword"
                dependencies={['newPassword']}
                rules={[
                  {
                    required: true,
                    message: 'Confirm your password',
                  },
                  validateConfirmPassword,
                ]}
              >
                <Input.Password placeholder="Confirm Password" className="form-control" />
              </Form.Item>
            </div>
            <MdGlobalButton
              id="primary_sm"
              type="submit"
              loading={updatingUserPassword}
              disabled={updatingUserPassword || !formValid}
              dataTestId='change-password'
            >
              Save
            </MdGlobalButton>
          </Form>
        </div>
      </Modal>
      <div className="accountSecurity grid2 border">
        <div className="dangerSection">
          <p>
            Want to deactivate your account? If you're experiencing security problems or facing minor challenges, please
            send us a mail at{' '}
            <a className="primaryColorLight" href="mailto:maildrip.io@gmail.com">
              support@maildrip.com
            </a>{' '}
            our support team will reach out to you
          </p>
          <Tooltip
            placement="topLeft"
            title={'An account-reactivation mail will be sent to you the next time you try to login'}
            color="blue"
          >
            <Button
              onClick={deactivateProfile}
              danger
              style={{ backgroundColor: '#ff4d4f' }}
              type="primary"
              size="large"
              loading={loading.id === 'deactivateProfile'}
            >
              Deactivate Account
            </Button>
          </Tooltip>
        </div>
        <div>
          <p className="">Forgot your password? No worries, you can change your password here.</p>
          <Button onClick={showModal} type="primary" size="large" data-test="show-change-password-modal">
            Change Password
          </Button>
        </div>
      </div>
    </>
  );
}
