import { getSingleCampaignInformation } from '../../../../utils/service';

const type = 'placeholder-var';

const currCampaignId = (editor, opts = {}) => {
  let defaultOptions = ['Select a tag', 'FIRST_NAME', 'LAST_NAME', 'FULL_NAME', 'EMAIL_ADDRESS'];
 const pathNames = window.location.pathname.split('/');
 
 const isCampaign = pathNames[1] === 'campaign';
  const currCampaignId = pathNames[2] ;
  if(isCampaign){
    getSingleCampaignInformation(currCampaignId)
    .then(({ data: { data } }) => {
      for (const v of data.variables) {
        defaultOptions.push(v.name);
      }
    })
    .catch((error) => console.log(error));
  }
 

  editor.Components.addType(type, {
    model: {
      defaults: {
        tagName: 'span',
        textable: true,
        placeholder: '{{Select a tag}}',
      },
      getInnerHTML() {
        return this.get('placeholder');
      },
    },
    // The view below it's just an example for creating a different UX
    view: {
      events: {
        change: 'updatePlaceholder',
      },
      updatePlaceholder(ev) {
        this.model.set({ placeholder: ev.target.value });
      },
      onAttrUpdate() {
        const viewStyle = ' border-radius: 3px; ';
        this.el.setAttribute('style', viewStyle);
      },
      onRender() {
        const { model, el } = this;
        const select = document.createElement('select');

        select.innerHTML = defaultOptions
          .map((item, i) => {
            // console.log({ i });
            return `<option ${i === 0 && 'disabled'} value="{{${item}}}"> ${item} </option>`;
          })
          .join('');
        el.innerHTML = '';
        el.appendChild(select);
        select.setAttribute(
          'style',
          'padding: 5px; margin: 1px 0;  border-radius: 3px; background-color: lightblue; border: none; appearance: none;'
        );
        select.value = model.get('placeholder');
      },
    },
  });
};
export default currCampaignId;
