import React, { createContext, useReducer } from 'react';
import { modalId } from '../../utils/constants';
import { reducer } from './reducer';

export const modalsContext = createContext();

export default function ModalContextProvider({ children }) {
  const initialState = [{ id: modalId.turnOnCampaign, open: false, props: {} }];
  const [state, dispatch] = useReducer(reducer, initialState);
  return <modalsContext.Provider value={{ state, dispatch }}>{children}</modalsContext.Provider>;
}
