import { Spin } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { Field, Form, Formik } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import * as yup from 'yup';
import { showToastMessage } from '../utils/Toast';
import { createEmail, editEmail } from '../utils/service';

export const EnterMailDetails = ({
  location,
  match: {
    params: { campaignId },
  },
}) => {
  const navigate = useNavigate();
  const mailId = location.state?.mailId;
  const prevSubject = location.state?.prevSubject;
  const prevDuration = location.state?.prevDuration;
  const prevInterval = location.state?.prevInterval;
  const img = location.state?.img;
  const htmlCode = location.state?.htmlCode;
  const designCode = location.state?.designCode;
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [force, setForce] = useState(false);
  const [interval, setInterval] = useState(prevInterval ? prevInterval : '');
  const [duration, setDuration] = useState(prevDuration ? prevDuration : '');
  const [warningModal, setWarningModal] = useState(false);
  const openWarningModal = () => setWarningModal(true);
  const closeWarningModal = () => setWarningModal(false);

  if (!img || img === '') {
    navigate(`/campaign/${campaignId}/web-builder`);
  }

  const loadTemplate = () => {
    localStorage.setItem('templateJson', designCode);
    if (mailId) {
      navigate({
        pathname: `/campaign/${campaignId}/web-builder`,
        state: {
          prevSubject,
          mailId,
        },
      });
    } else {
      navigate(`/campaign/${campaignId}/web-builder`);
    }
  };
  const initialValues = {
    subject: prevSubject ? prevSubject : '',
    designCode: designCode,
    htmlCode: htmlCode,
  };
  const validationSchema = yup.object().shape({
    subject: yup.string().label('Subject').required(),
  });
  const sendMail = async (formValues, formikHelpers) => {
    let emailInterval = '';
    if (duration === 'minute') {
      emailInterval = interval * 60;
    }
    if (duration === 'hour') {
      emailInterval = interval * 60 * 60;
    }
    if (duration === 'day') {
      emailInterval = interval * 24 * 60 * 60;
    }
    if (duration === 'week') {
      emailInterval = interval * 7 * 24 * 60 * 60;
    }
    if (duration === 'month') {
      emailInterval = interval * 30 * 24 * 60 * 60;
    }
    if (duration === 'year') {
      emailInterval = interval * 365 * 24 * 60 * 60;
    }
    const number = interval;
    try {
      if (mailId) {
        let res = await editEmail(campaignId, {
          body: `${htmlCode}`,
          subject: formValues.subject,
          id: mailId,
          attachments: '',
          emailInterval,
          duration,
          number,
          designCode,
          img,
        });
        if (res) {
          setLoading(false);
          showToastMessage({
            type: 'success',
            title: 'Email Update',
            description: 'Update Successful',
          });
          navigate(`/campaign/${campaignId}/emails`);
        }
      } else {
        let res = await createEmail(
          force,
          campaignId,
          'web-builder',
          formValues.subject,
          `${htmlCode}`,
          '',
          emailInterval,
          duration,
          number,
          designCode,
          img
        );
        if (res) {
          // eslint-disable-next-line eqeqeq
          if (res.data.data?.type == 'will_exceed') {
            return openWarningModal();
          }
          setLoading(false);
          setForce(false);
          showToastMessage({
            type: 'success',
            title: 'Email Created',
            description: 'Your email has been created successfully',
          });
          navigate(`/campaign/${campaignId}/emails`);
        }
      }
    } catch (error) {
      setLoading(false);
      showToastMessage({
        type: 'error',
        title: 'Error',
        description: 'Sorry, an error occured',
      });
    }
  };

  return (
    <section className="enter-details-wrapper">
      <div className="form-wrapper">
        <h2 className="mb-5">Complete Mail Details</h2>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={sendMail}>
          {({ handleSubmit, isValid }) => {
            return (
              <>
                <Form onSubmit={handleSubmit}>
                  <div>
                    <label className="font-weight-normal">
                      Subject <span className="text-red">*</span>
                    </label>

                    <Field
                      className="form-control mb-5"
                      name="subject"
                      id="subject"
                      placeholder="What is your email’s subject line?"
                    />
                  </div>
                  <div>
                    <span className=" uppercase tracking-wide text-gray-700 text-xs mb-2" for="grid-first-name">
                      Email Interval
                    </span>
                    <span className="font14 ml-1">(Optional)</span>
                    <div className="flex interval-wrapper mb-2">
                      <input
                        value={interval}
                        name="interval"
                        placeholder="10"
                        type="number"
                        onChange={(e) => {
                          if (e.target.value === '0') {
                            return;
                          } else {
                            setInterval(e.target.value);
                          }
                        }}
                        min={1}
                        className="disabled:bg-white py-2 font-bold"
                      />

                      <select
                        value={duration}
                        name="duration"
                        className="form-control"
                        onChange={(e) => setDuration(e.target.value)}
                      >
                        <option value="" key="">
                          Select Time Interval
                        </option>
                        <option value="minute" key="minute">
                          Minute(s)
                        </option>
                        <option value="hour" key="hour">
                          Hour(s)
                        </option>
                        <option value="day" key="day">
                          Day(s)
                        </option>
                        <option value="week" key="week">
                          Week(s)
                        </option>
                        <option value="month" key="month">
                          Month(s)
                        </option>
                        <option value="year" key="year">
                          Year(s)
                        </option>
                      </select>
                    </div>
                  </div>
                  <div>
                    <div className="flex justify-between">
                      <div>
                        <label className="font-weight-normal">
                          <span className="font-bold">Design</span>
                        </label>
                        <div>(Drag and Drop)</div>{' '}
                        <div className="flex">
                          <div>
                            <img src={img} alt="" className="img" />
                          </div>
                          <span
                            className="font14 text-green click pl-4 my-auto"
                            onClick={() => {
                              setModalIsOpen(true);
                            }}
                          >
                            Preview
                          </span>
                        </div>
                      </div>
                      <div className="my-auto">
                        <button type="button" className="btn btn-outline-green" onClick={() => loadTemplate()}>
                          Edit Design
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-end mt-5">
                    <button type="submit" className="btn btn-green px-4" disabled={!isValid || loading}>
                      Send Mail
                      {loading && (
                        <span className="white-spin ml-2">
                          <Spin />
                        </span>
                      )}
                    </button>
                  </div>

                  <Modal
                    title="Upgrade Plan"
                    centered
                    open={warningModal}
                    onCancel={closeWarningModal}
                    footer={[
                      <button
                        key="back"
                        className="btn btn-outline-blue mr-2"
                        onClick={() => {
                          closeWarningModal();
                          navigate('/plans');
                        }}
                      >
                        Upgrade
                      </button>,
                      <button
                        key="submit"
                        type="submit"
                        className="btn btn-blue"
                        onClick={() => {
                          closeWarningModal();
                          setForce(true);
                          handleSubmit();
                        }}
                      >
                        Proceed
                      </button>,
                    ]}
                  >
                    <div className="text-center">
                      <p className="mb-3">
                        You are currently on a free plan with a limit of 100 mails.
                        <br /> When you exceed your limit your messages may not be delivered.
                      </p>
                      <p>Click "proceed" to continue with your free plan and "upgrade" to upgrade your plan.</p>
                    </div>
                  </Modal>
                </Form>
              </>
            );
          }}
        </Formik>

        <Modal
          title=""
          open={modalIsOpen}
          centered
          onCancel={() => setModalIsOpen(false)}
          footer={[
            <button
              key="back"
              className="btn btn-blue"
              onClick={() => {
                setModalIsOpen(false);
              }}
            >
              Close
            </button>,
          ]}
        >
          <div className="preview-modal-wrapper" dangerouslySetInnerHTML={{ __html: htmlCode }}></div>
        </Modal>
      </div>
    </section>
  );
};
