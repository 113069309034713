import React, { useState } from 'react';
import PreviewTemplate from './PreviewTemplate';
import { Button } from 'antd';
import { MdEmptyBox } from 'pages/ContactPage/Icons/MdEmptyBox';
import { showToastMessage } from 'utils/Toast';

const MdLandingPageTemplateCard = ({
  id,
  currentCard,
  icon,
  title,
  fetchedTemplate,
  onClick,
  submitTemplateSelected,
  isGrid,
  temp,
  loading,
  setLoading,
}) => {
  const [modalvisible, setModalVisible] = useState(false);
  const [data, setData] = useState();

  const getHtmlTemplate = async (temp) => {
    try {
      const htmlRes = await fetch(temp.body, {
        headers: {},
        method: 'GET',
        body: null,
      });
      const cssRes = await fetch(temp.content, {
        headers: {},
        method: 'GET',
        body: null,
      });
      if (!htmlRes?.ok || !cssRes.ok) {
        showToastMessage({
          type: 'error',
          title: 'Error',
          description: 'An error occurred trying to load preview, Please try again later.',
          duration: 8,
        });

        return;
      }
      const html = await htmlRes.text();
      const css = await cssRes.text();
      return { html, css };
    } catch (error) {
      showToastMessage({
        type: 'error',
        title: 'Error',
        description: 'An error occurred trying to load preview, Please try again later.',
        duration: 8,
      });
    }
  };

  const previewHtmlTemplate = async (temp) => {
    setLoading({ id: 'preview' });
    await getHtmlTemplate(temp).then((x) => {
      setLoading({ id: '' });
      setData({ html: x.html, css: x.css });
      setModalVisible(true);
    });
  };
  const previewSavedTemplate = async (temp) => {
    setData({ html: temp.body, css: temp.content });
    setModalVisible(true);
  };
  const selectSavedTemplate = async (temp) => {
    setLoading({ id: 'select' });
    await submitTemplateSelected(temp);
  };
  const selectHtmlTemplate = async (temp) => {
    setLoading({ id: 'select' });
    await getHtmlTemplate(temp).then((x) => {
      submitTemplateSelected({ ...temp, body: x.html, content: x.css });
    });
  };

  const isCurrentCard = id === currentCard;

  return (
    <div className={`template-card ${isGrid ? 'w-100' : 't-card'} flex flex-wrap ${isCurrentCard && 'active'}`}>
      <div className="temp_card_contain text-center w-full h-full flex justify-center items-center" onClick={onClick}>
        <>
          <img src={icon} alt="new-template" className="temp_img" />

          <MdEmptyBox />
          <div className="temp_overlay">
            <div className="temp_inner">
              <Button
                data-test="select-template"
                className="preview-templates-btn fill"
                onClick={() => {
                  fetchedTemplate ? selectSavedTemplate(temp) : selectHtmlTemplate(temp);
                }}
                loading={loading?.id === 'select'}
              >
                Select
              </Button>
              <Button
                className="preview-templates-btn outline"
                onClick={() => {
                  fetchedTemplate ? previewSavedTemplate(temp) : previewHtmlTemplate(temp);
                }}
                loading={loading?.id === 'preview'}
              >
                Preview
              </Button>
            </div>
          </div>
          {modalvisible && (
            <PreviewTemplate
              title={title}
              icon={icon}
              visible={modalvisible}
              temp={data}
              setVisible={() => setModalVisible(false)}
            />
          )}
        </>
      </div>
    </div>
  );
};

export default MdLandingPageTemplateCard;
