import { Form } from 'antd';
import { MdTypography } from 'components/global';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { useLocation, useNavigate } from 'react-router-dom';
import { BreadCrumb } from '../../components/emmaccen/sharedComponents/BreadCrumb';
import { showToastMessage } from '../../utils/Toast';
import { getCampaign } from '../../utils/service';
import './editor.scss';
import { MentionsInput, Mention } from 'react-mentions';
import { useEditCampaignEmailMutation } from 'redux/featureApi/campaignApiSlice';
import { useEditStrippoEmailMutation } from 'redux/featureApi/stripoApiSlice';
import TemplateSelection from 'pages/InstantEmail/components/TemplateSelection';

// const PreviewTemplate = ({ title, visible, icon, setVisible }) => {
//   return (
//     <Modal
//       title={title}
//       centered
//       open={visible}
//       footer={null}
//       onCancel={setVisible}
//       width={1000}
//       style={{ margin: '20px 0' }}
//     >
//       <div>
//         <img src={icon} alt="preview_img" width="100%" height="100%" />
//       </div>
//     </Modal>
//   );
// };

const SubjectOfMail = (props) => {
  const [submissionDisabled] = useState(true);
  const [subjectOfMail, setSubjectOfMail] = useState('Message to {{FIRST_NAME}}');

  const [form] = Form.useForm();

  const { campaignId } = useParams();
  const { search } = useLocation();
  const navigate = useNavigate();
  const typeOfMailParam = new URLSearchParams(search).get('type');

  const [breadCrumbs, setBreadCrumbs] = useState([
    {
      name: 'Overview',
      link: `/`,
      index: 0,
    },
    {
      name: '...',
      link: `/campaign/${campaignId}/emails`,
      index: 1,
    },
    // {
    //   name: '',
    //   link: `#`,
    //   index: 2,
    // },
  ]);

  const [selectedPlateObj, setSelectedPlateObj] = useState({});

  const [EditCampaignEmail] = useEditCampaignEmailMutation();
  const [EmailEditor] = useEditStrippoEmailMutation();
  useEffect(() => {
    getCampaign({ campaignId })
      .then((campaign) => {
        let updatedList = breadCrumbs.map((data) => {
          if (data.index === 1) {
            return { ...data, name: campaign.name };
          }
          return data;
        });
        setBreadCrumbs(updatedList);
      })
      .catch(() =>
        showToastMessage({
          type: 'error',
          title: 'Error',
          description: 'Unable to fetch data',
        })
      );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId, subjectOfMail]);

  const submitTemplateSelected = async (temp) => {
    if (subjectOfMail === '') {
      showToastMessage({
        type: 'error',
        title: 'Error',
        description: "Sorry, you can't have an empty subject",
      });
    } else if (temp === null) {
      showToastMessage({
        type: 'error',
        title: 'Error',
        description: 'Kindly select a template to continue',
      });
    } else {
      // return;
      const { body, content, typeOfMail, title, key, isFirst, isSaved } = temp;
      const updateCampaignEmail = async () => {
        const resp = await EditCampaignEmail({
          campaignId,
          payload: {
            subject: subjectOfMail,
            body,
            content,
            typeOfMail,
          },
        }).unwrap();
        return resp;
      };
      const updateStrippoEmail = async (id) => {
        const resp = await EmailEditor({
          mailId: id,
          body: { typeOfMail, strippoHtml: body, strippoCss: content },
        }).unwrap();
        return resp;
      };
      isFirst
        ? updateCampaignEmail()
            .then((resp) => {
              const getLatestData = resp.data.campaign.emails.slice(-1).pop();
              const route = `/campaign/${campaignId}/${getLatestData._id}/${
                typeOfMailParam ?? getLatestData.typeOfMail
              }?action=create`;
              updateStrippoEmail(getLatestData._id).then(() => navigate(route));
            })
            .catch((err) => {
              showToastMessage({
                type: 'error',
                title: 'Error',
                description: err?.message || 'An error occured',
              });
            })
        : isSaved
        ? updateStrippoEmail().then((resp) => {
            const getLatestData = resp.data.campaign.emails.slice(-1).pop();
            const route = `/campaign/${campaignId}/${getLatestData._id}/${
              typeOfMailParam ?? getLatestData.typeOfMail
            }?action=create`;
            navigate(route);
          })
        : updateCampaignEmail()
            .then((resp) => {
              const getLatestData = resp.data.campaign.emails.slice(-1).pop();
              const route = `/campaign/${campaignId}/${getLatestData._id}/${
                typeOfMailParam ?? getLatestData.typeOfMail
              }?action=create`;
              if (title || key) {
                navigate(`${route}&template=${title}&sub=${key}`);
                return;
              }
              if (selectedPlateObj?.key === 'upload-code') {
                navigate(`${route}&key=${selectedPlateObj?.key}`);
                return;
              } else {
                navigate(route);
              }
            })
            .catch((err) => {
              showToastMessage({
                type: 'error',
                title: 'Error',
                description: err?.message,
              });
            });
    }
    // return;
  };
  const targetButtonRef = useRef(null);

  const handleSubjectInput = (e) => {
    let updatedList = breadCrumbs.map((data) => {
      if (data.index === 2) {
        return { ...data, name: e.target.value };
      }
      return data;
    });
    setSubjectOfMail(e.target.value);
    setBreadCrumbs(updatedList);
  };

  const mergeTags = [
    { id: 1, display: 'FIRST_NAME' },
    { id: 2, display: 'LAST_NAME' },
    { id: 3, display: 'FULL_NAME' },
    { id: 4, display: 'EMAIL_ADDRESS' },
  ];

  const displayStyle = {
    suggestions: {
      marginTop: '35px',
      list: {
        backgroundColor: 'white',
        border: '1px solid rgba(0,0,0,0.15)',
        fontSize: 14,
        height: '85px',
        overflow: 'auto',
      },
      item: {
        padding: '5px 15px',
        borderBottom: '1px solid rgba(0,0,0,0.15)',
        '&focused': {
          backgroundColor: '#cee4e5',
        },
      },
    },
  };


  return (
    <>
      <div className="headerTab">
        <BreadCrumb crumbs={breadCrumbs} />
      </div>
      <div className="subject_of_mail_view">
        <div className="templateContaner relative h-full w-full">
          <div className="grid2" ref={targetButtonRef}>
            <div className="mailList">
              <MdTypography variant={'h3Bold'} className="header-title bold">
                Campaign details
              </MdTypography>
              <div className="subject_of_mail_form">
                <Form layout="vertical" form={form} name="control-hooks">
                  <Form.Item
                    name="subject"
                    label="Subject"
                    initialValue={subjectOfMail}
                    rules={[{ required: true }]}
                    className="subject_of_mail_form__item"
                  >
                    <MentionsInput
                      value={subjectOfMail}
                      singleLine
                      placeholder="Enter Email Subject"
                      data-test="campaign-email-subject"
                      className="email-subject-mention"
                      style={displayStyle}
                      onChange={handleSubjectInput}
                    >
                      <Mention
                        trigger="@"
                        data={mergeTags}
                        markup="{{__display__}}"
                        displayTransform={(display) => `@${display}`}
                      />
                    </MentionsInput>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
          <TemplateSelection
            selectedPlateObj={selectedPlateObj}
            setSelectedPlateObj={setSelectedPlateObj}
            submitTemplateSelected={submitTemplateSelected}
            submissionDisabled={submissionDisabled}
            typeOfMailParam={typeOfMailParam}
          />
        </div>
      </div>
    </>
  );
};

export default SubjectOfMail;
