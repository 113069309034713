export const SET_PLAN = 'SET_PLAN';
export const SET_PLANS = 'SET_PLANS';
export const SET_PRICE = 'SET_PRICE';
export const SET_LOADING = 'SET_LOADING';
export const SET_QUANTITY = 'SET_QUANTITY';
export const SET_RAW_CARD_DATA = 'SET_RAW_CARD_DATA';
// export const CREATE_TRANSACTION_WITH_CARD = 'CREATE_TRANSACTION_WITH_CARD';
// export const CREATE_TRANSACTION_WITH_AUTH_CODE = 'CREATE_TRANSACTION_WITH_AUTH_CODE';
export const SET_CARD = 'SET_CARD';
export const SET_CARDS = 'SET_CARDS';
export const GET_USER_CARDS = 'GET_USER_CARDS';
export const SET_MAKING_TRANSACTION = 'SET_MAKING_TRANSACTION';
export const SET_AUTHORIZATION_STEP = 'SET_AUTHORIZATION_STEP';
export const SET_TRX_REF = 'SET_TRX_REF';
export const SET_TRX_STATUS = 'SET_TRX_STATUS';
export const SET_TRX_TYPE = 'SET_TRX_TYPE';
export const SET_DISPLAY_TEXT = 'SET_DISPLAY_TEXT';
export const SET_DEFAULT_STATE = 'SET_DEFAULT_STATE';
export const SET_SAVE_CARD = 'SET_SAVE_CARD';
export const SET_CURRENCY = 'SET_CURRENCY';
export const SET_PAYG_RATES = 'SET_PAYG_RATES';
export const SET_TOPUP_RATES = 'SET_TOPUP_RATES';
