export function getContrastColor(backgroundColor) {
  // Convert hex color to RGB
  const hexToRgb = (hex) => {
    const bigint = parseInt(hex.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return [r, g, b];
  };

  // Calculate relative luminance
  const calculateLuminance = (rgb) => {
    const [r, g, b] = rgb.map((c) => {
      c /= 255;
      return c <= 0.03928 ? c / 12.92 : Math.pow((c + 0.055) / 1.055, 2.4);
    });
    return 0.2126 * r + 0.7152 * g + 0.0722 * b;
  };

  const rgb = hexToRgb(backgroundColor || '#000');
  const luminance = calculateLuminance(rgb);

  // Decide the text color based on luminance
  return luminance > 0.5 ? '#000000' : '#ffffff';
}
