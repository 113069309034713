import React, { useState, useContext, useCallback } from 'react';
import { Form, Input, ColorPicker } from 'antd';
import { joinCampaignContext } from '../../../contexts/joincampaign';
import { useDropzone } from 'react-dropzone';
import { UploadOutlined } from '@ant-design/icons';
import {
  SET_ALT_TEXT,
  SET_BTN_COLOR,
  SET_BTN_TEXT,
  SET_CATCHPHRASE,
  SET_SECTION_BACKGROUND,
  SET_TAGLINE,
  SET_LOGO_RESULT,
  SET_LOGO_FILES,
} from '../../../contexts/joincampaign/reducer/types';

const DesignComponent = () => {
  const [formatHex, setFormatHex] = useState('hex');

  const [form] = Form.useForm();
  const { state, dispatch } = useContext(joinCampaignContext);
  const fileReader = new FileReader();
  // console.log({ state });
  const { btnText, sectionbackground, tagline, catchphrase, btnColor } = state;

  const handleTextChange = ({ target: { name, value } }) => {
    if (name === 'tagline') {
      dispatch({ type: SET_TAGLINE, payload: value });
    }
    if (name === 'btnText') {
      dispatch({ type: SET_BTN_TEXT, payload: value });
    }
    if (name === 'catchphrase') {
      dispatch({ type: SET_CATCHPHRASE, payload: value });
    }
    if (name === 'alttext') {
      dispatch({ type: SET_ALT_TEXT, payload: value });
    }
  };

  const bgColorChangeHandler = (value) => {
    const color = typeof value === 'string' ? value : value.toHexString();

    dispatch({ type: SET_SECTION_BACKGROUND, payload: color });
  };

  const btnColorChangeHandler = (value) => {
    const color = typeof value === 'string' ? value : value.toHexString();

    dispatch({ type: SET_BTN_COLOR, payload: color });
  };

  /* eslint-disable */
  const onDrop = useCallback((acceptedFiles) => {
    fileReader.readAsDataURL(acceptedFiles[0]);
    fileReader.onloadend = () => {
      dispatch({ type: SET_LOGO_RESULT, payload: fileReader.result });
      dispatch({ type: SET_LOGO_FILES, payload: acceptedFiles });
    };
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: '.png, .jpg, .jpeg',
    onDrop,
    maxFiles: '1',
  });

  return (
    <div>
      <Form layout="vertical" form={form} name="control-hooks">
        <div className="design-form">
          <Form.Item name="tagline" className="input-label" label="Headline" rules={[{ required: true }]}>
            <Input
              defaultValue={tagline}
              // initialValues={tagline}
              name="tagline"
              placeholder="Join Campaign"
              onChange={handleTextChange}
              style={{ height: '35px' }}
            />
          </Form.Item>
        </div>

        <div>
          <Form.Item className="input-label " name="catchphrase" label="Tagline" rules={[{ required: true }]}>
            <Input
              defaultValue={catchphrase}
              name="catchphrase"
              placeholder="Tagline"
              onChange={handleTextChange}
              style={{ height: '35px' }}
            />
          </Form.Item>
        </div>
        <div>
          <Form.Item className="input-label" name="btnText" label="Call-to-Action" rules={[{ required: true }]}>
            <Input
              defaultValue={btnText}
              name="btnText"
              placeholder="Call to Action"
              onChange={handleTextChange}
              style={{ height: '35px' }}
            />
          </Form.Item>
        </div>
        <div>
          <Form.Item className="input-label" name="logo" label="Set Logo" rules={[{ required: true }]}>
            <div {...getRootProps({ className: 'dropzone' })} className="ip-box">
              <div
                className="flex space-x-5"
                style={{
                  height: '35px',
                  width: '100%',
                  minWidth: '295px',
                  border: '1px solid #d9d9d9',
                  transition: 'all 0.3s',
                  borderRadius: '2px',
                  fontSize: '14px',
                  textAlign: 'left',
                  alignItems: 'center',
                  cursor: 'pointer',
                  paddingLeft: '10px',
                }}
              >
                <UploadOutlined />
                <span>Click to Upload</span>
              </div>
              <input {...getInputProps()} />
            </div>
          </Form.Item>
        </div>
        <div></div>

        <div className="grid grid-cols-2">
          <div>
            <Form.Item
              className="input-label"
              name="sectionbackground"
              label="Set Background Color"
              rules={[{ required: true }]}
            >
              <ColorPicker
                defaultValue={sectionbackground}
                format={formatHex}
                onChange={bgColorChangeHandler}
                onFormatChange={setFormatHex}
                className="color-picker-1"
              />
            </Form.Item>
          </div>
          <div>
            <Form.Item
              name="btnColor"
              className="input-label"
              label={
                <span>
                  Set Button <br />
                  Color
                </span>
              }
              rules={[{ required: true }]}
            >
              <ColorPicker
                defaultValue={btnColor}
                format="hex"
                onChange={btnColorChangeHandler}
                onFormatChange={setFormatHex}
                className="color-picker-2"
                placement="top"
              />
            </Form.Item>
          </div>
        </div>
      </Form>
      <div></div>
    </div>
  );
};

export default DesignComponent;
