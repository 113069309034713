import { Modal, Typography } from 'antd';
import React from 'react';
import styles from '../../styles/share.module.scss';
import { ReactComponent as Copiable } from '../../asset/Icons/Copiable.svg';

const { Paragraph } = Typography;

const SuccessModal = ({ open, link, setIsModalOpen }) => {
  const closeSuccessModal = () => setIsModalOpen(false);
  const routeToCampaign = () => {
    closeSuccessModal();
    window.open(`${link}`);
  };
  return (
    <div>
      <Modal
        title=""
        centered
        className={styles.modalContainer}
        open={open}
        onOk={routeToCampaign}
        onCancel={closeSuccessModal}
        footer={[
          <button key="submit" className={styles.modalButton} type="primary" onClick={routeToCampaign}>
            View Page
          </button>,
        ]}
      >
        <div className="text-center">
          <p className="text-green flex justify-content-center align-items-center" style={{ fontSize: '86px' }}>
            <span class="iconify" data-icon="bi:check-circle"></span>
          </p>
          <p style={{ fontFamily: 'Paralucent-Medium' }} className="font18 mt-5 ">
            Opt-in page published 
          </p>
          <p style={{ fontFamily: 'Paralucent-Light' }} className=" font16 mt-3">
            Your new opt-in page has been published successfully, and is now live.
          </p>
          <div className={styles.clipbox}>
            <p className={styles.clipboard}>
              <Paragraph
                style={{ fontFamily: 'Paralucent-Light', marginRight: '1rem' }}
                copyable={{
                  icon: [<Copiable key="copy-icon" />],
                  tooltips: ['click here', 'you clicked!!'],
                }}
                className="mt-2"
              >
                {link}
              </Paragraph>
            </p>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SuccessModal;
