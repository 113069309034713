import { typeCustomCode } from './consts';

const editor = (editor, opts = {}) => {
  const bm = editor.BlockManager;
  const { blockCustomCode, blockLabel } = opts;

  blockCustomCode &&
    bm.add(typeCustomCode, {
      label: `<div style="height: 66px; width: 100%; display: flex; flex-direction: column; justify-content: space-between;"><img width='40' style='margin: 0 auto;' src='/assets/coding.png' />
      <div>${blockLabel}</div></div>`,
      category: 'Basic',
      activate: true,
      select: true,
      // attributes: { class: 'fa fa-code' },
      content: { type: typeCustomCode },
      ...blockCustomCode,
    });
};
export default editor;
