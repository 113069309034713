import React from 'react';
import { Alert } from 'antd';
import { MdTypography } from '../../../components/global';
import { Link } from 'react-router-dom';

const MdDashboardModal = () => {
  return (
    <>
      <Alert
        message={<MdTypography variant={'captionMedium'}>Hey, Tell a friend!</MdTypography>}
        description={
          <>
            <MdTypography variant={'captionRegular'}>
              Earn 10% commission when you refer your friends to us
            </MdTypography>

            <MdTypography variant={'captionMedium'}>
              <p>
                Click{' '}
                <Link
                  to="https://maildrip.io/develop/maildrip-affiliate/"
                  className="pt-3 border-b border-black  font-semibold leading-relaxed"
                >
                  here.
                </Link>
              </p>
            </MdTypography>
          </>
        }
        type="error"
        closable
      />
    </>
  );
};

export default MdDashboardModal;
