import React from 'react';
//import { Switch } from 'antd';
import OpenDeliveryPerformance from './MdOpenDeliveryPerformance';
import ClickRate from './MdClickRate';
import '../../../styles/dashboard.scss';
import { MdTypography } from '../../../components/global';
import { CaretDownOutlined } from '@ant-design/icons';
import TotalAnalytics from '../dashboardDetails/TotalAnalytics';

const MdDashboardPerformance = () => {
  return (
    <>
      <div className="performance-header">
        <MdTypography variant={'h6Medium'} className="performanceTitle mt-4 mb-5">
          Performance
        </MdTypography>
        <p className="view-analytics-dropdown-container">
          <div className="view-analytics-dropdown">
            <TotalAnalytics />
          </div>
          <span className="text">View all analytics</span>
          <CaretDownOutlined style={{ fontSize: '8px' }} />
        </p>
      </div>
      <div className="performanceSection">
        <OpenDeliveryPerformance />
        <ClickRate />
      </div>
    </>
  );
};

export default MdDashboardPerformance;
