import React, { useContext, useState } from 'react';
import { Switch } from 'antd';
import { FORM_FIELDS } from '../../../contexts/joincampaign/reducer/types';
import { joinCampaignContext } from '../../../contexts/joincampaign';
import { RightOutlined, UpOutlined } from '@ant-design/icons';

const FormItem = ({ title, defaultChecked, disabled, isActive, onClick }) => {
  const { state, dispatch } = useContext(joinCampaignContext);
  const [isCheckActive, setIsCheckActive] = useState(false);

  const { formFields } = state;

  function updateFormField(title, value) {
    const updatedFormFields = formFields.map((field) => {
      if (field.name === title) {
        return { ...field, enabled: value };
      }
      return field;
    });
    return updatedFormFields;
  }

  const handleSwitchChange = (e, title) => {
    dispatch({ type: FORM_FIELDS, payload: updateFormField(title, e ? true : false) });
    setIsCheckActive((value) => !value);
  };

  return (
    <div className="flex items-center rounded-none border p-5 w-full">
      <Switch
        className="form-switch"
        loading={false}
        defaultChecked={defaultChecked}
        disabled={disabled && isCheckActive === false}
        onChange={(e) => handleSwitchChange(e, title)}
      />
      <p className="flex-grow px-5 cursor-pointer" onClick={onClick}>
        {title}
      </p>

      {/* <span>{isActive ? <UpOutlined /> : <DownOutlined />}</span> */}
      <span onClick={onClick}>{isActive ? <UpOutlined /> : <RightOutlined />}</span>
    </div>
  );
};
export default FormItem;
