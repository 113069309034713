import React from 'react';

export const MdExportIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="vuesax/linear/export">
        <g id="export">
          <path
            id="Vector"
            d="M6.21333 4.33333L7.92 2.62666L9.62667 4.33333"
            stroke="#717171"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_2"
            d="M7.92 9.45333V2.67333"
            stroke="#717171"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_3"
            d="M2.66667 8C2.66667 10.9467 4.66667 13.3333 8 13.3333C11.3333 13.3333 13.3333 10.9467 13.3333 8"
            stroke="#717171"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </svg>
  );
};
