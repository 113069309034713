import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import GeneralHtmlSupport from '@ckeditor/ckeditor5-html-support/src/generalhtmlsupport';

/**
 * A plugin extending General HTML Support for example custom HTML elements.
 */
export default class ExtendHTMLSupport extends Plugin {
  static get requires() {
    return [GeneralHtmlSupport];
  }

  init() {
    // Extend schema with custom HTML elements.
    const dataFilter = this.editor.plugins.get('DataFilter');
    const dataSchema = this.editor.plugins.get('DataSchema');

    // Inline element
    dataSchema.registerInlineElement({
      view: 'variable',
      model: 'variable',
    });

    // Custom elements need to be registered using direct API instead of config.
    dataFilter.allowElement('variable');
    dataFilter.allowAttributes({ name: 'variable', attributes: {}, classes: [] });
  }
}
