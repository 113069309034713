import { Modal } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { MdTypography } from '../../../components/global';
import '../../../styles/instantemails.scss';

import { TextEditorImage } from '../../../asset/Icons/TextEditorImage';
import { StripoIcon } from 'asset/Icons/StripoIcon';
// import { NewsletterImage } from 'asset/Icons/NewsletterImage';

export const MdCreateCampaignEmailModal = ({ id, handleModal, isModalVisible }) => {
  const navigate = useNavigate();

  const createTextEditorEmail = () => {
    navigate(`/campaign/${id}/text-editor?action=create`);
  };
  return (
    <div>
      <Modal
        // wrapClassName="createEmailModalWrapper-new"
        open={isModalVisible}
        onCancel={() => handleModal({ id: '' })}
        footer={null}
        centered
      >
        <div className="emmaccen">
          <div className="modal-header">
            <MdTypography variant={''} className="createmodaltitle">
              Choose email builder
            </MdTypography>
          </div>
          <div className="createEmailModals-block">
              <Link to={`/campaign/${id}/mail-subject?type=speditor`} dataTest="strippo-editor-test">
                <div className="single-option">
                  <div className="single-option-icon">
                    <StripoIcon />
                  </div>
                  <div className="single-option-text">
                    <div className="flex item-center gap-2">
                      <MdTypography variant={'btnRegular'} className="editor-head">
                        HTML EDITOR
                      </MdTypography>
                      <div className="beta-mode">Beta</div>
                    </div>

                    <MdTypography variant={'btnLight'} className="editor-body">
                      Leverage templates, upload your code or use intuitive drag and drop to create your own beautiful
                      and colourful emails.
                    </MdTypography>
                  </div>
                </div>
              </Link>
            {/* <Link to={`/campaign/${id}/text-editor`} data-test="rich-text-test"> */}
            <div className="single-option" onClick={createTextEditorEmail} data-test="rich-text-test">
              <div className="single-option-icon">
                <TextEditorImage />
              </div>
              <div className="single-option-text">
                <MdTypography variant={'btnRegular'} className="editor-head">
                  Rich Text EDITOR
                </MdTypography>

                <MdTypography variant={'btnLight'} className="editor-body">
                  Create simple text emails. Include tables, images, and carryout various formatting
                </MdTypography>
              </div>
            </div>
            {/* </Link> */}
            {/* {showNewsletter && (
              <Link to={`/campaign/${id}/mail-subject`}>
                <div className="single-option">
                  <div className="single-option-icon">
                    <NewsletterImage />
                  </div>
                  <div className="single-option-text">
                    <MdTypography variant={'btnRegular'} className="editor-head">
                      Newsletter Builder
                    </MdTypography>

                    <MdTypography variant={'btnLight'} className="editor-body">
                      Easily create responsive emails from a layout or template and add your own content
                    </MdTypography>
                  </div>
                </div>
              </Link>
            )} */}
          </div>
        </div>
      </Modal>
    </div>
  );
};
