import LoadingScreen from 'components/LoadingScreen';
import { useEffect, useState } from 'react';
// import { exPreviewPopup, exVideoLirary, extCustomFont, externalImgLib, externalLinkEventPopup } from './libs';
import withStrippoEditor from 'HOC/withStrippoEditor';
import { EditorTopBarCtas } from './components/EditorTopBarCtas';
import { initStripo, loadDemoTemplate } from './stripo.helper';
import './stripo.scss';
import { STRIPO_CONSTANT, getTemplateList } from './utils';
import { defaultTemplate } from './defaultTemplate';
import { useNavigate } from 'react-router-dom';
import { AttachmentBox } from 'pages/documentEditor/AttachmentBox';
import { deleteAttachment } from 'utils/service';
import { showToastMessage } from 'utils/Toast';
const StripoEditor = ({
  imLoading,
  setImLoading,
  mailId,
  data,
  files,
  setFiles,
  handleSaveEmailToDB,
  sendTestMailHandler,
  template,
  sub,
  campaignId,
  landingPageId,
}) => {
  const [currentTemplate, setCurrentTemplate] = useState(
    data?.email
      ? { title: data?.email?.title, htmlTemplate: data?.email?.strippoHtml, cssTemplate: data?.email?.strippoCss }
      : null
  );
  const navigate = useNavigate();

  useEffect(() => {
    const currentEmailDoc = data?.email;
    window.scrollTo({ top: 0, behavior: 'smooth' });

    if (template) {
      handleTemplateChange(template, sub);
    }

    if (currentEmailDoc) {
      setFiles(
        currentEmailDoc?.attachments?.map(({ location, id, filename, createdAt }, index) => ({
          src: location,
          filename,
          status: 'UPLOADED',
          id,
          createdAt,
        }))
      );
    }
    if (!!currentTemplate?.htmlTemplate && !!currentEmailDoc) {
      loadDemoTemplate(
        initStripo,
        {
          htmlTemplate: currentEmailDoc?.strippoHtml,
          cssTemplate: currentEmailDoc?.strippoCss,
          emailId: mailId || landingPageId,
          loadedFromMaildrip:
            !!currentTemplate?.htmlTemplate && String(currentTemplate?.htmlTemplate).substring(0, 8) !== 'https://',
        },
        (e) => {
          setImLoading({ id: STRIPO_CONSTANT.initial, editorLoaded: true });
        }
      );
    } else if (!!currentEmailDoc?.strippoHtml) {
      setCurrentTemplate({
        title: currentEmailDoc?.title,
        htmlTemplate: currentEmailDoc?.strippoHtml,
        cssTemplate: currentEmailDoc?.strippoCss,
      });
    } else if (!currentEmailDoc?.strippoHtml && !!currentEmailDoc) {
      // setCurrentTemplate(templateList[2]);
      setCurrentTemplate({
        title: 'Default Template',
        htmlTemplate: defaultTemplate().html,
        cssTemplate: defaultTemplate().css,
      });
    }

    return () => {
      window.StripoApi?.stop();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTemplate, data]);

  const [isButtonDisabled, setButtonDisabled] = useState(false);

  const handlePreviewBtnClick = () => {
    setButtonDisabled(true);
    window.StripoApi.compileEmail((error, html, ampHtml) => {
      window.ExternalPreviewPopup.openPreviewPopup(html, ampHtml);
    });
    setTimeout(() => {
      setButtonDisabled(false);
    }, 2000);
  };

  const handleTemplateChange = (value, sub) => {
    setImLoading((prevData) => ({ ...prevData, id: STRIPO_CONSTANT.editorLoading }));
    let newTemplate;
    const sortTemplate = getTemplateList().filter((template) => template.title === value);
    if (sortTemplate.length > 1) {
      const deepSortTemplate = sortTemplate.find((template) => template.sub === sub);
      newTemplate = deepSortTemplate;
    } else newTemplate = sortTemplate[0];
    // const route = `/instant-emails/${mailId}/speditor?action=create`;
    const route = campaignId
      ? `/campaign/${campaignId}/${mailId}/speditor?action=create`
      : landingPageId
      ? `/landing-page/${landingPageId}/edit`
      : `/instant-emails/${mailId}/speditor?action=create`;

    if (newTemplate) {
      setCurrentTemplate(newTemplate);
      navigate(`${route}&template=${value}&sub=${sub}`, { replace: true });
    } else {
      navigate(route);
    }
  };

  const deleteFile = ({ src, id }) => {
    setImLoading({ id });
    deleteAttachment(id, mailId)
      .then(() => {
        showToastMessage({
          type: 'success',
          title: 'File',
          description: 'File Deleted',
        });
        setFiles(files.filter((file) => file.src !== src));
      })
      .catch((e) => {
        showToastMessage({
          type: 'error',
          title: 'Error',
          description: 'An error occurred',
        });
      })
      .finally(() => {
        setImLoading({ id: '' });
      });
  };

  const disableTopBtn = imLoading.id === STRIPO_CONSTANT.editorLoading;
  // console.log({ files, email: data?.email });
  return (
    <div className="speditor_builder">
      <EditorTopBarCtas
        campaignId={campaignId}
        mailId={mailId}
        landingPageId={landingPageId}
        files={files}
        setFiles={setFiles}
        disableTopBtn={disableTopBtn}
        isSavingMail={imLoading.id === STRIPO_CONSTANT.isSavingMail}
        isSendingTestMail={imLoading.id === STRIPO_CONSTANT.isSendingTestMail}
        handleTemplateChange={handleTemplateChange}
        handlePreviewBtnClick={handlePreviewBtnClick}
        handleSaveEmailToDB={handleSaveEmailToDB}
        handleSendTestEmail={sendTestMailHandler}
        isEditorLoaded={imLoading?.editorLoaded}
        isButtonDisabled={isButtonDisabled}
        deleteFile={deleteFile}
        imLoading={imLoading}
        setCurrentTemplate={setCurrentTemplate}
      />
      {/* <!--This is external system container where you can place plugin buttons --> */}

      {/* <EditorButton disabled={disableTopBtn} id="saveButton" onClick={handleSaveEmailToDB}>
          <span className="savemail">Save</span>
          {imLoading.id === STRIPO_CONSTANT.isSavingMail && <span className="isLoading"></span>}
        </EditorButton>
        <EditorButton disabled={disableTopBtn} id="undoButton" title={'Undo'} />
        <EditorButton disabled={disableTopBtn} id="redoButton" title={'Redo'} />
        <EditorButton disabled={disableTopBtn} id="codeEditor" title={'Code editor'} />
        <EditorButton disabled={disableTopBtn} id="previewButton" title={'Preview'} onClick={handlePreviewBtnClick} /> */}

      {/* <span id="changeHistoryContainer" style={{ display: 'none' }}>
          Last change: <span id="changeHistoryLink"></span>
        </span> */}

      <div className="notification-zone"></div>

      <div className={`speditor_builder__editor_view ${files?.length > 0 ? 'pb-32' : ''}`}>
        {/* <!--Plugin containers --> */}
        <div id="stripoSettingsContainer"></div>
        <div id="stripoPreviewContainer"></div>
      </div>
      {imLoading.id === STRIPO_CONSTANT.editorLoading && (
        <div className="speditor_builder__loading_state">
          <LoadingScreen />
        </div>
      )}
      {files?.length > 0 && <AttachmentBox files={files} deleteFunction={deleteFile} imLoading={imLoading} />}
    </div>
  );
};

export default withStrippoEditor(StripoEditor);
