import React from 'react';

export default function DetailedIcon() {
  return (
    <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.35714 1.21429C1.94037 1.21429 1.54067 1.3648 1.24597 1.6327C0.951275 1.90061 0.785714 2.26398 0.785714 2.64286V5.5H3.92857C4.34534 5.5 4.74504 5.65051 5.03974 5.91842C5.33444 6.18633 5.5 6.54969 5.5 6.92857V9.78571H8.64286C9.05963 9.78571 9.45932 9.6352 9.75402 9.3673C10.0487 9.09939 10.2143 8.73602 10.2143 8.35714V6.57143C10.2143 6.47671 10.2557 6.38587 10.3294 6.31889C10.403 6.25191 10.503 6.21429 10.6071 6.21429C10.7113 6.21429 10.8113 6.25191 10.8849 6.31889C10.9586 6.38587 11 6.47671 11 6.57143V8.35714C11 8.92546 10.7517 9.47051 10.3096 9.87237C9.86756 10.2742 9.26801 10.5 8.64286 10.5H2.35714C1.73199 10.5 1.13244 10.2742 0.690391 9.87237C0.248341 9.47051 0 8.92546 0 8.35714V2.64286C0 2.07454 0.248341 1.52949 0.690391 1.12763C1.13244 0.725765 1.73199 0.5 2.35714 0.5H4.32143C4.42562 0.5 4.52555 0.537628 4.59922 0.604605C4.6729 0.671582 4.71429 0.762423 4.71429 0.857143C4.71429 0.951863 4.6729 1.0427 4.59922 1.10968C4.52555 1.17666 4.42562 1.21429 4.32143 1.21429H2.35714ZM6.28571 0.857143C6.28571 0.762423 6.3271 0.671582 6.40078 0.604605C6.47445 0.537628 6.57438 0.5 6.67857 0.5H10.6071C10.7113 0.5 10.8113 0.537628 10.8849 0.604605C10.9586 0.671582 11 0.762423 11 0.857143V4.42857C11 4.52329 10.9586 4.61413 10.8849 4.68111C10.8113 4.74809 10.7113 4.78571 10.6071 4.78571C10.503 4.78571 10.403 4.74809 10.3294 4.68111C10.2557 4.61413 10.2143 4.52329 10.2143 4.42857V1.71929L6.95671 4.68143C6.88295 4.74849 6.7829 4.78617 6.67857 4.78617C6.57425 4.78617 6.4742 4.74849 6.40043 4.68143C6.32666 4.61437 6.28522 4.52341 6.28522 4.42857C6.28522 4.33373 6.32666 4.24278 6.40043 4.17571L9.65879 1.21429H6.67857C6.57438 1.21429 6.47445 1.17666 6.40078 1.10968C6.3271 1.0427 6.28571 0.951863 6.28571 0.857143Z"
        fill="white"
      />
    </svg>
  );
}
