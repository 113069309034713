import React from 'react';

import CircularSpinner from './loaders/CircularSpinner';

export default function LoadingScreen({ loaderTitle }) {
  return (
    <div
      className=""
      style={{
        display: 'grid',
        placeContent: 'center',
        height: '40vh',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <CircularSpinner style={{ height: '60px', color: '#8e8e8e', width: '50px' }} />
      </div>
      <h6
        style={{
          textAlign: 'center',
          color: '#8e8e8e',
        }}
      >
        {loaderTitle || 'Loading'}
      </h6>
    </div>
  );
}
