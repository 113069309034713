export const ComingSoonRocket = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="243" height="243" viewBox="0 0 243 243" fill="none">
        <mask
          id="mask0_8682_53296"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="43"
          y="9"
          width="157"
          height="225"
        >
          <path d="M199.715 9.61914H43.2842V233.382H199.715V9.61914Z" fill="white" />
        </mask>
        <g mask="url(#mask0_8682_53296)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M158.288 87.4562C157.261 79.5066 155.223 72.1164 149.679 68.688L149.357 68.6113C147.633 75.9021 144.859 82.9347 141.515 89.7197L141.839 89.8779C142.341 92.784 142.972 95.9455 143.602 99.1071C144.232 102.268 144.863 105.429 145.364 108.334C145.809 110.899 146.502 113.855 148.377 114.762C149.516 115.313 150.829 114.894 151.841 114.008C152.852 113.122 153.608 111.816 154.278 110.483C157.655 103.754 159.315 95.4059 158.288 87.4562ZM151.907 75.466C151.819 75.2736 151.591 75.1893 151.399 75.2776C151.207 75.366 151.122 75.5935 151.211 75.7859C156.31 86.8915 156.017 100.335 150.437 111.208C150.341 111.396 150.415 111.627 150.603 111.724C150.792 111.82 151.023 111.746 151.119 111.558C156.806 100.476 157.105 86.7852 151.907 75.466Z"
            fill="url(#paint0_linear_8682_53296)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M123.051 107.141C123.546 109.669 124.16 112.811 127.034 113.495C131.409 114.535 133.938 106.46 135.118 101.057C135.459 99.5016 135.686 98.1682 135.813 97.3379C136.699 91.2811 137.359 67.3196 128.101 68.3465C120.953 71.1744 120.78 87.7819 121.16 94.5151C121.363 97.2663 121.728 99.9998 122.198 102.713C122.412 103.944 122.65 105.173 122.903 106.396C122.953 106.636 123.001 106.885 123.051 107.141ZM131.846 73.9322C131.782 73.7305 131.566 73.6193 131.364 73.6836C131.163 73.7479 131.051 73.9636 131.116 74.1651C134.742 85.5324 134.235 97.815 129.685 108.845C129.605 109.041 129.698 109.265 129.893 109.346C130.089 109.426 130.313 109.333 130.394 109.137C135.013 97.9403 135.527 85.4717 131.846 73.9322Z"
            fill="url(#paint1_linear_8682_53296)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M115.682 30.2161C126.561 33.2771 138.035 33.5644 149.054 31.0514L149.343 30.9876C146.742 19.0556 141.781 10.1094 135.443 10.1094C126.464 10.1094 119.586 20.5703 115.562 30.1677L115.682 30.2161ZM136.294 11.9421C136.122 11.8202 135.882 11.8616 135.761 12.0346C135.639 12.2076 135.68 12.4467 135.853 12.5686C138.518 14.447 140.737 16.8889 142.352 19.7212C143.967 22.5535 144.939 25.7067 145.198 28.9568C145.215 29.1676 145.4 29.3251 145.611 29.3081C145.822 29.2914 145.979 29.1066 145.962 28.8958C145.694 25.5335 144.689 22.2716 143.018 19.3416C141.347 16.4115 139.052 13.8853 136.294 11.9421Z"
            fill="url(#paint2_linear_8682_53296)"
          />
          <path
            d="M176.804 185.255L154.449 198.733L139.118 207.975L123.97 192.648L155.258 174.127C155.299 174.115 155.34 174.105 155.381 174.092L176.804 185.255Z"
            fill="url(#paint3_linear_8682_53296)"
          />
          <path
            d="M155.379 174.092C155.338 174.105 155.297 174.115 155.256 174.128L155.346 174.074L155.379 174.092Z"
            fill="url(#paint4_linear_8682_53296)"
          />
          <path
            d="M102.395 110.11C101.708 109.288 101.284 108.174 100.921 107.063C98.4726 99.3476 98.2479 91.099 100.272 83.262C102.297 75.5728 105.081 69.1429 110.574 65.0623L110.751 65.0469C110.91 66.9729 111.12 68.8967 111.372 70.8072C112.083 76.4327 113.29 81.9842 114.979 87.3965C112.371 94.24 109.764 101.084 107.158 107.927C106.667 109.22 106.065 110.638 104.989 111.062C104.088 111.422 103.082 110.936 102.395 110.11Z"
            fill="url(#paint5_linear_8682_53296)"
          />
          <path
            d="M124.691 232.86C124.451 219.53 124.195 205.991 123.968 192.648L139.118 207.976L154.45 198.734L154.55 198.9L153.362 216.452L124.691 232.86Z"
            fill="#171717"
          />
          <path
            d="M123.968 192.648C124.196 205.991 124.451 219.53 124.691 232.86L96.3796 215.91L94.502 200.626L94.5526 200.5C98.2004 202.025 101.859 203.527 105.547 204.968C107.08 205.479 108.357 206.756 109.89 206.5C114.437 201.954 119.235 197.406 123.812 192.803C123.837 192.78 123.86 192.755 123.883 192.732C123.917 192.701 123.939 192.675 123.952 192.663L123.968 192.648Z"
            fill="url(#paint6_linear_8682_53296)"
          />
          <path
            d="M122.816 193.774C122.804 193.784 122.793 193.795 122.783 193.807C122.772 193.815 122.763 193.824 122.755 193.835C122.746 193.841 122.738 193.849 122.732 193.858C122.686 193.902 122.688 193.896 122.732 193.858C122.739 193.85 122.747 193.842 122.755 193.835L122.783 193.807L122.816 193.774ZM94.5524 200.5C87.8445 197.693 81.1666 194.811 74.3818 192.106C74.4099 192.106 74.9744 191.674 75.0255 191.628C75.0025 191.641 74.8289 191.769 74.3818 192.106C76.4253 190.407 78.7242 188.874 80.9927 187.117L81.0747 186.843C81.5754 187.059 82.089 187.244 82.6124 187.398C86.2574 188.468 99.9654 189.304 97.6177 178.362L97.9857 178.283C106.569 182.999 115.254 187.853 123.968 192.647L123.953 192.663L123.883 192.732C123.388 193.215 123.076 193.519 122.898 193.694C123.151 193.448 123.581 193.027 123.812 192.802C119.234 197.406 114.437 201.953 109.89 206.5C108.358 206.755 107.081 205.478 105.548 204.967C101.858 203.527 98.2001 202.025 94.5524 200.5Z"
            fill="url(#paint7_linear_8682_53296)"
          />
          <path
            d="M94.502 200.627L96.3796 215.911L124.691 232.86L153.361 216.453L154.549 198.9"
            stroke="#171717"
            strokeWidth="0.772791"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M97.9854 178.283C106.569 182.999 115.254 187.853 123.967 192.647"
            stroke="#171717"
            strokeWidth="0.772791"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M75.0281 191.626C75.0281 191.627 75.0276 191.627 75.0273 191.628C75.0268 191.628 75.026 191.629 75.0255 191.629C74.9749 191.674 74.4099 192.106 74.3818 192.106C76.4253 190.408 78.7242 188.875 80.9927 187.117"
            stroke="#171717"
            strokeWidth="0.772791"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M123.813 192.804C119.235 197.407 114.437 201.954 109.891 206.501C108.358 206.757 107.081 205.48 105.548 204.969C101.859 203.528 98.2011 202.026 94.5533 200.501C87.8455 197.693 81.1676 194.812 74.3828 192.107C74.8298 191.769 75.0035 191.642 75.0265 191.629"
            stroke="#171717"
            strokeWidth="0.772791"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M123.969 192.648L123.953 192.664"
            stroke="#171717"
            strokeWidth="0.772791"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M123.952 192.664C123.94 192.677 123.917 192.702 123.884 192.733"
            stroke="#171717"
            strokeWidth="0.772791"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M123.883 192.732C123.861 192.755 123.838 192.78 123.812 192.803"
            stroke="#171717"
            strokeWidth="0.772791"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M122.897 193.696C123.151 193.451 123.581 193.029 123.812 192.805"
            stroke="#171717"
            strokeWidth="0.772791"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M122.731 193.86C122.739 193.852 122.746 193.844 122.755 193.837L122.783 193.809L122.816 193.775"
            stroke="#171717"
            strokeWidth="0.772791"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M122.733 193.859C122.688 193.899 122.685 193.904 122.733 193.859Z"
            stroke="#171717"
            strokeWidth="0.772791"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M124.691 232.86C124.451 219.53 124.195 205.991 123.968 192.648L123.953 192.664L123.883 192.733C123.388 193.216 123.076 193.52 122.897 193.695C122.867 193.725 122.839 193.751 122.816 193.774C122.804 193.784 122.793 193.796 122.783 193.807C122.772 193.815 122.763 193.825 122.754 193.835C122.746 193.842 122.738 193.85 122.731 193.858"
            stroke="#171717"
            strokeWidth="0.772791"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M155.256 174.128L155.345 174.074L155.379 174.092L176.803 185.255L154.449 198.733L139.116 207.975L123.968 192.648L155.256 174.128Z"
            stroke="#171717"
            strokeWidth="0.772791"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M110.575 65.0645C105.082 69.1438 102.298 75.5737 100.273 83.2641C98.2488 91.1011 98.4736 99.3497 100.922 107.065C101.285 108.176 101.709 109.29 102.396 110.112C103.083 110.935 104.089 111.423 104.989 111.065C106.064 110.641 106.667 109.223 107.158 107.931C109.763 101.087 112.37 94.2437 114.979 87.4C114.981 87.3893 114.985 87.3792 114.989 87.3693"
            stroke="#171717"
            strokeWidth="0.766463"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M149.679 68.6895C155.223 72.1178 157.261 79.508 158.288 87.4577C159.315 95.4073 157.655 103.756 154.277 110.484C153.608 111.818 152.852 113.123 151.84 114.009C150.829 114.896 149.516 115.315 148.376 114.763C146.501 113.856 145.809 110.901 145.364 108.336C144.36 102.524 142.843 95.6911 141.839 89.8793"
            stroke="#171717"
            strokeWidth="0.766463"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M135.118 101.057C135.458 99.5016 135.685 98.1682 135.813 97.3379C136.699 91.2811 137.359 67.3196 128.101 68.3465C120.954 71.1744 120.78 87.7814 121.16 94.5151C121.363 97.2663 121.728 99.9998 122.198 102.713C122.413 103.944 122.65 105.173 122.903 106.396C123.445 109.009 123.869 112.742 127.034 113.495C131.409 114.535 133.938 106.46 135.118 101.057Z"
            stroke="#171717"
            strokeWidth="0.766463"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M124.293 43.223C122.747 45.5398 122.63 48.6768 123.792 51.2085C124.954 53.7403 127.294 55.6509 129.941 56.5143C131.361 56.9765 132.932 57.1529 134.332 56.6343C136.038 56.0015 137.234 54.4349 138.049 52.805C139.428 50.0437 139.96 46.6844 138.69 43.8697C136.289 38.5533 127.371 38.607 124.293 43.223Z"
            stroke="#171717"
            strokeWidth="0.766463"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M155.234 150.477C155.231 150.479 155.23 150.482 155.228 150.485C155.185 150.561 154.802 151.154 153.972 152.416C156.383 148.771 160.981 148.004 164.558 149.537C170.177 152.092 171.455 159.5 169.156 164.864C166.622 169.932 160.828 172.492 155.379 174.094C155.338 174.107 155.298 174.117 155.257 174.129C155.254 174.129 155.249 174.132 155.246 174.132"
            stroke="#171717"
            strokeWidth="0.766463"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M132.432 136.105C137.163 134.291 140.9 139.484 139.791 144.527C141.242 139.426 143.296 133.175 149.253 132.038C151.274 131.397 153.573 132.163 155.106 133.696C159.448 139.315 157.149 146.724 153.972 152.413C154.702 151.276 155.172 150.558 155.228 150.482"
            stroke="#171717"
            strokeWidth="0.766463"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M97.5897 178.229C97.6001 178.273 97.6102 178.319 97.6178 178.362C99.9656 189.303 86.2578 188.468 82.6126 187.398C82.0891 187.244 81.5755 187.059 81.0749 186.843C74.4202 183.977 70.2333 175.966 71.4417 168.875C72.1287 164.841 74.788 161.058 78.6914 159.587C75.884 157.303 73.6871 154.357 73.2348 150.689C73.0262 149.049 73.1462 147.385 73.5879 145.793C74.0296 144.2 74.7842 142.711 75.8073 141.413C80.0173 136.218 88.2864 135.581 93.1859 140.129C95.8758 142.627 97.4546 146.351 97.1556 150.033C100.367 142.257 106.712 133.482 116.264 134.503C118.53 134.746 119.84 134.841 121.529 136.376"
            stroke="#171717"
            strokeWidth="0.766463"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M119.312 97.9668C119.742 107.611 120.75 122.158 121.718 136.364C122.561 148.72 123.373 160.813 123.746 169.2"
            stroke="#171717"
            strokeWidth="0.766463"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M136.615 98.8809C136.079 108.409 134.196 122.314 132.431 136.106C130.758 149.175 129.186 162.144 128.963 171.185"
            stroke="#171717"
            strokeWidth="0.766463"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M129.289 169.201C130.16 167.482 131.404 165.908 133.049 164.901C134.694 163.895 136.769 163.507 138.595 164.12C140.422 164.733 141.906 166.439 142.006 168.365"
            stroke="#171717"
            strokeWidth="0.766463"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M124.773 172.686C124.657 171.481 124.242 170.325 123.565 169.321C122.183 167.227 119.799 165.76 117.278 165.72C113.543 165.658 110.016 168.726 109.558 172.433"
            stroke="#171717"
            strokeWidth="0.766463"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M123.045 174.78C123.533 174.021 124.151 173.353 124.871 172.808C126.061 171.901 127.478 171.342 128.966 171.191C129.314 171.159 129.664 171.15 130.014 171.166C131.387 171.246 132.716 171.679 133.872 172.422C135.029 173.166 135.975 174.196 136.617 175.412"
            stroke="#171717"
            strokeWidth="0.766463"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M70.9108 144.286C72.4637 141.371 71.069 137.279 68.0573 135.917C69.9145 133.248 68.701 129.002 65.7146 127.716C62.7283 126.43 58.8074 128.457 58.1381 131.64C55.9744 129.792 52.3922 129.928 50.3747 131.933C48.3573 133.939 48.1979 137.52 50.0325 139.697C47.7258 139.554 45.3806 141.063 44.6602 143.26C43.9398 145.457 44.9974 148.127 47.0793 149.136C49.1613 150.145 51.9866 149.258 53.0237 147.192C52.7556 149.284 55.2846 150.85 57.3587 150.467C59.1291 150.139 59.9057 148.911 61.349 148.101C63.0657 147.139 62.708 147.483 64.629 147.373C67.1683 147.229 69.5824 146.788 70.9108 144.286Z"
            stroke="#171717"
            strokeWidth="0.766463"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M63.8384 156.216C62.3569 154.748 59.695 154.712 58.1776 156.142C56.6601 157.573 56.531 160.23 57.9077 161.799C59.2845 163.368 61.9362 163.587 63.5506 162.264C65.1651 160.941 65.4769 158.299 64.2123 156.642"
            stroke="#171717"
            strokeWidth="0.766463"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M49.8815 209.977C49.1241 210.262 48.447 210.725 47.9071 211.328C47.5302 211.749 47.2495 212.247 47.0847 212.787C46.9196 213.328 46.8743 213.898 46.9518 214.457C47.2839 216.478 49.3886 217.775 51.4169 218.041C55.0343 218.514 58.8355 216.295 60.2003 212.909C60.1244 214.077 60.9233 215.18 61.9654 215.711C63.0075 216.243 64.2364 216.281 65.3912 216.102C69.4349 215.477 72.8963 212.078 73.5962 208.045C73.9412 206.058 73.5274 203.743 71.8849 202.576C73.254 203.414 75.2081 202.905 76.1687 201.618C77.1293 200.331 77.1319 198.473 76.368 197.063C75.604 195.654 74.1685 194.678 72.6217 194.241C71.0749 193.804 67.3491 192.194 63.2849 195.52"
            stroke="#171717"
            strokeWidth="0.766463"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M49.8838 209.977C50.2025 209.927 50.5126 209.835 50.8059 209.701C50.4915 209.767 50.183 209.86 49.8838 209.977Z"
            stroke="#171717"
            strokeWidth="0.766463"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M49.8848 209.979H49.8818"
            stroke="#171717"
            strokeWidth="0.766463"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M63.2857 195.522C63.1049 194.842 62.7906 194.206 62.3613 193.649C61.932 193.093 61.3961 192.627 60.7848 192.28C60.1735 191.933 59.4994 191.711 58.8013 191.628C58.1034 191.544 57.396 191.6 56.7199 191.793C56.044 191.985 55.4132 192.311 54.8644 192.75C54.3157 193.189 53.8598 193.733 53.5234 194.351C53.1872 194.968 52.9774 195.646 52.906 196.345C52.8346 197.045 52.9035 197.751 53.1083 198.423C52.2499 196.847 50.0224 196.406 48.3824 197.136C46.7424 197.867 45.6542 199.484 45.0361 201.167C44.2798 203.229 44.1368 205.632 45.2148 207.546C46.1319 209.173 48.0888 210.254 49.8819 209.978"
            stroke="#171717"
            strokeWidth="0.766463"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M175.517 147.12C176.478 147.874 177.852 147.971 178.999 147.554C180.146 147.138 181.089 146.27 181.804 145.278C182.443 144.389 182.936 143.242 182.553 142.218C182.264 141.439 181.513 140.905 180.718 140.662C179.911 140.461 179.078 140.384 178.248 140.435C178.814 139.797 179.212 139.027 179.405 138.197C179.599 137.366 179.581 136.5 179.354 135.678C178.856 134.053 177.329 132.755 175.63 132.674C173.93 132.592 172.225 133.838 171.918 135.509C171.774 135.128 171.529 134.793 171.208 134.543C170.887 134.292 170.504 134.133 170.099 134.086C169.283 134.002 168.464 134.205 167.78 134.658C166.877 135.215 166.171 136.039 165.759 137.016C165.347 137.992 165.25 139.073 165.481 140.108C165.712 141.143 166.26 142.079 167.049 142.788C167.837 143.497 168.827 143.942 169.881 144.061C168.823 144.508 168.309 145.852 168.644 146.947C168.979 148.042 170.016 148.842 171.142 149.067C172.269 149.292 174.459 149.093 175.517 147.12Z"
            stroke="#171717"
            strokeWidth="0.766463"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M190.126 159.252C189.768 157.503 188.179 156.114 186.411 155.863C185.522 155.76 184.62 155.888 183.794 156.235C182.968 156.581 182.245 157.135 181.696 157.842C180.983 158.707 180.627 159.81 180.7 160.928C180.855 162.525 182.128 163.879 183.645 164.402C185.012 164.872 187.201 164.949 188.325 163.85C189.487 162.719 190.46 160.895 190.126 159.252Z"
            stroke="#171717"
            strokeWidth="0.766463"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M189.022 171.588C187.149 171.095 184.997 172.357 184.513 174.232C183.246 173.169 181.375 172.76 179.877 173.468C178.379 174.176 177.506 176.117 178.204 177.616C178.681 178.646 179.76 179.3 180.871 179.52C181.982 179.739 183.134 179.581 184.245 179.356C183.969 179.843 183.814 180.389 183.793 180.949C183.771 181.508 183.884 182.065 184.121 182.571C184.359 183.078 184.714 183.521 185.158 183.862C185.602 184.203 186.121 184.433 186.672 184.533C187.223 184.632 187.79 184.598 188.325 184.434C188.86 184.269 189.348 183.979 189.748 183.587C190.148 183.195 190.448 182.713 190.623 182.182C190.798 181.65 190.844 181.084 190.756 180.531C191.289 180.92 191.927 181.14 192.586 181.163C193.246 181.186 193.898 181.012 194.457 180.662C195.545 179.923 196.033 178.37 195.469 177.182C195.107 176.416 194.384 175.987 193.584 175.8C193.017 175.67 192.319 175.731 191.924 175.225C191.477 174.653 191.505 173.734 191.073 173.095C190.593 172.362 189.865 171.827 189.022 171.588Z"
            stroke="#171717"
            strokeWidth="0.766463"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M122.606 42.0253C120.639 44.9732 120.491 48.9635 121.967 52.1848C123.444 55.406 126.422 57.8353 129.794 58.9364C131.598 59.5239 133.598 59.7464 135.376 59.087C137.55 58.2798 139.07 56.29 140.107 54.2181C141.862 50.703 142.539 46.4292 140.922 42.8478C137.869 36.0835 126.522 36.1523 122.606 42.0253Z"
            stroke="#171717"
            strokeWidth="0.766463"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M135.338 101.077C135.767 100.349 136.192 99.6167 136.613 98.8801C138.334 95.8785 139.985 92.8259 141.515 89.7198C144.859 82.9347 147.633 75.9022 149.358 68.6114C150.105 65.476 150.64 62.2934 150.957 59.0856C151.874 49.6491 151.217 39.5486 149.342 30.9857C146.742 19.0536 141.781 10.1074 135.443 10.1074C126.464 10.1074 119.585 20.5683 115.561 30.1658C114.084 33.646 112.915 37.249 112.067 40.9332C110.347 48.8292 110.095 56.978 110.75 65.0479C110.909 66.9739 111.118 68.8977 111.371 70.8083C112.082 76.4337 113.289 81.9853 114.978 87.3976C116.125 91.035 117.575 94.5701 119.311 97.9658C120.149 99.5954 121.064 101.186 122.057 102.737"
            stroke="#171717"
            strokeWidth="0.766463"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M115.682 30.2148C126.561 33.2759 138.035 33.5632 149.054 31.0502"
            stroke="#171717"
            strokeWidth="0.766463"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M184.741 46.8779L181.77 44.4375"
            stroke="#171717"
            strokeWidth="0.766463"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M181.631 47.8215L184.88 43.4961"
            stroke="#171717"
            strokeWidth="0.766463"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M187.934 91.9271L185.773 85.1641"
            stroke="#171717"
            strokeWidth="0.766463"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M183.813 90.009L189.251 86.8105"
            stroke="#171717"
            strokeWidth="0.766463"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M172.167 115.729L175.423 105.184"
            stroke="#171717"
            strokeWidth="0.766463"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M180.873 120.629L199.21 107.299"
            stroke="#171717"
            strokeWidth="0.766463"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M184.917 132.523L193.301 132.627"
            stroke="#171717"
            strokeWidth="0.766463"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M74.6469 124.325L50.1133 95.0469"
            stroke="#171717"
            strokeWidth="0.766463"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M81.6254 117.895L78.6211 109.25"
            stroke="#171717"
            strokeWidth="0.766463"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M53.4655 123.018L43.7275 122.215"
            stroke="#171717"
            strokeWidth="0.766463"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M109.716 156.602C107.223 155.373 104.081 155.068 101.744 156.573C99.4069 158.078 98.4815 161.72 100.368 163.761C97.8188 163.647 95.5432 166.319 96.0616 168.817C96.58 171.316 99.7319 172.862 102.024 171.744"
            stroke="#171717"
            strokeWidth="0.766463"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M141.028 155.63C142.146 153.858 144.804 153.296 146.542 154.467C148.28 155.637 148.76 158.312 147.537 160.013C148.693 159.742 149.907 159.871 150.98 160.378C152.054 160.886 152.923 161.742 153.448 162.807C153.972 163.873 154.12 165.084 153.867 166.244C153.614 167.404 152.975 168.444 152.055 169.195"
            stroke="#171717"
            strokeWidth="0.766463"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M123.792 51.2086C124.952 53.7426 127.294 55.6509 129.941 56.5144C131.361 56.9766 132.932 57.153 134.332 56.6338C136.038 56.0003 137.234 54.4344 138.049 52.8046C139.428 50.0437 139.96 46.6845 138.69 43.8692C136.289 38.5534 127.371 38.607 124.293 43.223C122.747 45.5386 122.632 48.6746 123.792 51.2086ZM134.552 41.48C134.363 41.3841 134.132 41.459 134.036 41.6476C133.94 41.8361 134.015 42.0667 134.204 42.1629C136.878 43.5263 138.239 47.0523 137.173 49.8589C137.098 50.0569 137.198 50.2781 137.396 50.3533C137.593 50.4282 137.815 50.3287 137.89 50.131C139.094 46.9586 137.575 43.021 134.552 41.48Z"
            fill="#171717"
          />
          <path
            d="M112.97 21.7047L111.527 17.918"
            stroke="#171717"
            strokeWidth="0.766463"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M110.058 20.9404L113.65 18.7539"
            stroke="#171717"
            strokeWidth="0.766463"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M99.626 19.1805L98.0029 16.2949"
            stroke="#171717"
            strokeWidth="0.766463"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M97.2383 18.5815L100.191 16.3262"
            stroke="#171717"
            strokeWidth="0.766463"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M100.209 114.346C98.685 112.842 97.4738 111.052 96.6448 109.079C95.8158 107.106 95.3853 104.988 95.3779 102.848"
            stroke="#171717"
            strokeWidth="0.766463"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M153.208 117.495C155.134 116.223 156.782 114.572 158.051 112.644C159.32 110.716 160.185 108.55 160.592 106.277"
            stroke="#171717"
            strokeWidth="0.766463"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M126.999 156.581L128.89 136.377"
            stroke="#171717"
            strokeWidth="0.766463"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M125.783 135.126L125.664 115.471"
            stroke="#171717"
            strokeWidth="0.766463"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M65.7399 76.692L63.7559 73.627"
            stroke="#171717"
            strokeWidth="0.766463"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M63.2285 76.3695L66.1215 73.4922"
            stroke="#171717"
            strokeWidth="0.766463"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M81.626 85.4798L78.9219 84.5762"
            stroke="#171717"
            strokeWidth="0.766463"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M80.517 83.3594L79.6182 86.9664"
            stroke="#171717"
            strokeWidth="0.766463"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M66.0889 95.6425L68.9745 94.3789"
            stroke="#171717"
            strokeWidth="0.766463"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M67.8881 96.8976L67.167 93.832"
            stroke="#171717"
            strokeWidth="0.766463"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M98.5947 35.1588L99.6832 31.1387"
            stroke="#171717"
            strokeWidth="0.964153"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M96.9795 32.5176L101.618 33.0998"
            stroke="#171717"
            strokeWidth="0.964153"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M163.087 27.4355L164.747 29.3871L167.221 29.1105L165.965 31.3104L167.028 33.6589L164.59 33.0668L162.773 34.7952L162.523 32.2295L160.337 30.9489L162.62 29.9554L163.087 27.4355Z"
            fill="#171717"
          />
          <path
            d="M84.4604 60.9492L84.8454 63.4827L87.0946 64.5499L84.8684 65.7588L84.5356 68.3152L82.7748 66.5289L80.3198 67.0414L81.4578 64.7286L80.2734 62.4892L82.7374 62.8459L84.4604 60.9492Z"
            fill="#171717"
          />
          <g opacity="0.1">
            <path
              d="M133.088 10.1149C127.62 12.6563 123.061 17.2487 119.723 22.2143C116.079 27.6372 113.738 33.9423 112.264 40.2793C109.261 53.1864 109.197 66.9546 112.317 79.8427C113.186 83.4586 114.297 87.012 115.643 90.4788C116.893 93.6823 118.064 97.7553 120.55 100.227C121.41 101.082 122.563 100.176 122.414 99.1415C122.2 97.6549 121.524 96.169 120.932 94.7877C120.286 93.2798 119.667 91.7593 119.133 90.2074C118.121 87.2595 117.248 84.2564 116.532 81.223C115.076 75.0974 114.236 68.8419 114.026 62.5492C113.633 50.1324 115.667 37.1691 121.55 26.1169C123.074 23.2531 124.822 20.4042 126.947 17.9468C129.089 15.518 131.443 13.2838 133.979 11.2704C134.674 10.7095 133.789 9.78911 133.088 10.1149Z"
              fill="#171717"
            />
          </g>
          <g opacity="0.1">
            <path
              d="M110.201 65.9658C108.676 65.879 107.675 67.7473 106.936 68.8383C106.011 70.1755 105.167 71.567 104.408 73.0055C102.953 75.8012 101.785 78.7372 100.922 81.7687C99.2107 87.8315 98.624 94.1563 99.1907 100.43C99.3461 102.095 99.576 103.757 99.8861 105.399C100.174 106.924 100.462 108.798 101.363 110.105C102.229 111.362 104.18 111.264 104.634 109.677C105.039 108.26 104.197 106.101 103.916 104.708C102.722 98.6622 102.734 92.4402 103.951 86.3986C104.646 82.9426 105.677 79.5629 107.028 76.307C107.72 74.6389 108.579 73.0551 109.357 71.4295C109.978 70.1312 111.787 68.2368 111.185 66.714C111.118 66.5029 110.988 66.3177 110.812 66.1838C110.636 66.0496 110.422 65.9731 110.201 65.965L110.201 65.9658Z"
              fill="#171717"
            />
          </g>
          <g opacity="0.1">
            <path
              d="M127.529 68.6484C126.113 69.5209 125.005 71.5477 124.27 72.9971C123.377 74.7262 122.639 76.5309 122.064 78.3899C120.957 82.1139 120.366 85.972 120.308 89.8565C120.222 93.7483 120.6 97.6365 121.435 101.438C121.856 103.333 122.372 105.204 122.981 107.046C123.57 108.83 124.137 110.816 125.207 112.37C126.097 113.663 127.941 112.846 127.679 111.328C127.376 109.574 126.613 107.894 126.117 106.182C125.615 104.451 125.195 102.699 124.856 100.926C124.151 97.3485 123.805 93.7095 123.825 90.0633C123.865 86.4904 124.325 82.9348 125.196 79.4692C125.635 77.7292 126.157 75.9607 126.747 74.2693C127.047 73.4087 127.338 72.5523 127.633 71.6927C127.921 70.8574 128.34 70.0312 128.384 69.1405C128.383 69.0407 128.356 68.943 128.305 68.8572C128.255 68.7711 128.182 68.6998 128.096 68.6502C128.01 68.6005 127.912 68.5745 127.812 68.5742C127.712 68.574 127.614 68.5998 127.527 68.6489L127.529 68.6484Z"
              fill="#171717"
            />
          </g>
          <g opacity="0.1">
            <path
              d="M143.094 88.2925L143.044 88.3105C142.778 88.3915 142.543 88.5545 142.374 88.7765C142.206 88.9982 142.111 89.2675 142.104 89.5462C142.094 93.6929 142.964 97.8657 143.713 101.932C144.329 105.275 144.642 108.971 146.334 111.994C146.934 113.066 148.072 114.561 149.395 114.71C149.702 114.749 150.014 114.682 150.28 114.522C150.545 114.362 150.749 114.117 150.858 113.826C151.078 113.105 150.626 112.578 150.128 112.14C147.66 109.969 147.737 106.277 147.297 103.235C146.621 98.5587 146.148 93.6954 144.621 89.2055C144.54 88.9385 144.377 88.7036 144.155 88.5345C143.933 88.3656 143.664 88.2712 143.385 88.2649C143.287 88.2601 143.189 88.2695 143.094 88.2925Z"
              fill="#171717"
            />
          </g>
          <g opacity="0.1">
            <path
              d="M119.5 100.832C118.951 102.93 119.084 105.464 119.212 107.617C119.346 109.879 119.477 112.14 119.639 114.4C119.97 119.007 120.301 123.614 120.631 128.219C121.299 137.434 122.046 146.647 122.767 155.858C122.964 158.371 123.169 160.883 123.382 163.395C123.602 165.982 123.574 168.743 124.285 171.248C124.678 172.633 126.45 172.186 126.616 170.933C126.909 168.708 126.568 166.382 126.438 164.149C126.301 161.803 126.158 159.458 126.009 157.114C125.714 152.59 125.407 148.068 125.087 143.546C124.444 134.419 123.886 125.286 123.231 116.159C123.051 113.645 122.855 111.133 122.643 108.622C122.532 107.299 122.383 106.011 122.165 104.702C121.95 103.406 121.842 102.05 121.332 100.832C120.997 100.032 119.762 99.8354 119.501 100.832H119.5Z"
              fill="#171717"
            />
          </g>
          <g opacity="0.1">
            <path
              d="M110.867 172.947C111.474 172.724 111.654 172.251 111.961 171.717C112.373 171.01 112.87 170.356 113.439 169.768C114.002 169.181 114.626 168.656 115.3 168.202C115.815 167.851 116.197 167.585 116.244 166.924C116.245 166.83 116.221 166.739 116.174 166.658C116.128 166.577 116.061 166.509 115.98 166.463C114.677 165.734 112.855 167.777 112.107 168.67C111.349 169.575 109.753 171.522 110.398 172.757C110.445 172.836 110.516 172.897 110.601 172.931C110.685 172.966 110.779 172.972 110.867 172.947L110.867 172.947Z"
              fill="#171717"
            />
          </g>
          <g opacity="0.1">
            <path
              d="M124.661 175.775C125.269 175.612 125.57 174.979 126.059 174.611C126.484 174.255 126.993 174.013 127.537 173.908C128.314 173.821 128.936 173.272 128.822 172.426C128.722 171.692 127.946 171.082 127.19 171.339C126.275 171.611 125.44 172.1 124.754 172.763C124.223 173.312 123.372 174.428 123.698 175.228C123.758 175.425 123.891 175.592 124.071 175.693C124.25 175.795 124.461 175.824 124.661 175.774V175.775Z"
              fill="#171717"
            />
          </g>
          <g opacity="0.1">
            <path
              d="M130.946 170.623C131.21 169.715 131.492 168.95 132.203 168.287C132.893 167.643 133.935 167.54 134.541 166.834C135.138 166.138 134.86 165.249 134.036 164.922C132.734 164.406 131.129 165.651 130.303 166.57C129.392 167.582 128.689 168.929 128.678 170.316C128.667 171.628 130.583 171.87 130.946 170.623V170.623Z"
              fill="#171717"
            />
          </g>
          <g opacity="0.1">
            <path
              d="M102.409 171.098C102.154 170.557 101.519 170.613 101.024 170.492C100.324 170.322 99.5367 169.902 99.2129 169.218C98.4573 167.622 100.059 166.544 101.385 166.247C102.233 166.057 102.814 164.846 102.258 164.101C101.057 162.49 101.61 160.86 101.312 159.034C101.283 158.931 101.215 158.844 101.122 158.791C101.029 158.738 100.918 158.724 100.815 158.752C98.126 159.771 98.7489 163.567 99.7354 165.576L100.997 163.377C98.0825 163.009 95.6363 166.341 96.3086 169.057C96.8604 171.287 99.8933 173.41 102.094 172.088C102.212 172.02 102.31 171.922 102.378 171.804C102.446 171.686 102.481 171.552 102.48 171.415C102.476 171.306 102.453 171.198 102.409 171.098H102.409Z"
              fill="#171717"
            />
          </g>
          <g opacity="0.1">
            <path
              d="M95.7464 178.413C95.0146 179.882 95.124 181.618 94.1575 183.011C93.1417 184.476 91.484 185.283 89.7339 185.452C85.6647 185.845 81.6486 183.421 79.0601 180.456C76.6555 177.7 75.2964 173.929 75.827 170.265C76.316 166.89 78.1193 163.329 80.9717 161.386C81.2072 161.241 81.4016 161.037 81.5362 160.796C81.6709 160.554 81.7418 160.282 81.7418 160.005C81.7418 159.729 81.6709 159.457 81.5362 159.215C81.4016 158.973 81.2072 158.77 80.9717 158.625C77.7839 156.448 76.2206 152.648 76.2525 148.85C76.2682 146.991 76.3626 145.183 77.1999 143.483C78.0679 141.72 79.5889 140.442 80.5273 138.74C80.9257 138.017 80.2554 136.955 79.3882 137.258C77.1812 138.031 75.8698 140.112 74.8945 142.135C73.8999 144.2 72.9469 146.524 72.9406 148.85C72.9282 153.591 75.4994 158.094 79.0341 161.136L79.3576 158.625C72.065 162.482 69.9112 171.342 73.215 178.613C74.8441 182.198 77.8074 185.238 81.2532 187.118C85.0159 189.168 89.9192 190.193 93.8801 188.054C95.6927 187.03 97.0923 185.407 97.8385 183.463C98.5082 181.755 98.6913 179.088 96.7364 178.154C96.5701 178.06 96.3736 178.035 96.1889 178.083C96.0041 178.131 95.8454 178.25 95.7462 178.413L95.7464 178.413Z"
              fill="#171717"
            />
          </g>
          <g opacity="0.1">
            <path
              d="M55.1695 131.039C54.7789 129.849 53.4136 129.836 52.4494 130.339C51.2656 130.957 50.3857 132.193 49.8574 133.384C49.2818 134.666 49.0279 136.07 49.1178 137.473C49.2077 138.876 49.6387 140.235 50.3736 141.433L52.4623 138.716C48.484 137.999 44.274 140.8 44.4968 145.126C44.598 147.069 45.7978 148.913 47.7059 149.505C49.421 150.036 51.4549 149.606 52.3348 147.931C52.4887 147.65 52.5471 147.326 52.5016 147.009C52.4563 146.692 52.309 146.398 52.0822 146.171C51.8556 145.944 51.5615 145.797 51.2441 145.751C50.9266 145.706 50.6031 145.764 50.3217 145.918C49.056 146.594 47.9109 145.792 48.2868 144.35C48.7171 142.699 50.4591 142.17 51.9708 142.351C53.3377 142.513 54.317 140.648 53.5677 139.566C53.0585 138.857 52.7195 138.041 52.5768 137.179C52.434 136.318 52.4915 135.436 52.7448 134.6C52.9858 133.78 53.4766 133.056 54.1489 132.528C54.5539 132.215 55.3877 131.701 55.1695 131.038V131.039Z"
              fill="#171717"
            />
          </g>
          <g opacity="0.1">
            <path
              d="M60.3851 155.266C58.2358 154.7 56.848 157.578 56.8188 159.353C56.7816 161.631 58.901 164.084 61.2673 163.942C61.669 163.929 62.053 163.774 62.3514 163.505C62.6498 163.235 62.843 162.869 62.8969 162.471C62.9508 162.072 62.8619 161.668 62.6458 161.329C62.4296 160.99 62.1005 160.738 61.7168 160.619C60.8116 160.313 59.7465 160.111 59.564 158.981C59.3792 157.838 60.4094 157.26 60.8106 156.312C60.9845 155.901 60.8752 155.395 60.3851 155.266Z"
              fill="#171717"
            />
          </g>
          <g opacity="0.1">
            <path
              d="M49.5671 196.463C46.0021 196.726 43.4838 200.299 43.8629 203.748C44.2826 207.567 47.8117 210.407 51.5716 210.429L50.6958 207.196C46.1122 209.131 46.0297 215.83 50.5171 217.935C52.6914 218.955 55.2948 218.564 57.2776 217.277C59.4261 215.882 60.7788 213.678 61.9351 211.453L58.3711 210.487C58.221 214.617 62.6056 217.086 66.2827 215.919C68.2515 215.294 69.6949 213.945 70.9056 212.323C72.0484 210.792 73.1396 209.204 73.4219 207.274C73.537 206.487 72.6139 205.707 71.9538 206.42C71.1184 207.323 70.1611 208.116 69.2438 208.934C68.3553 209.727 67.5122 210.656 66.551 211.354C65.3014 212.262 62.1636 212.941 62.196 210.487C62.2198 208.665 59.4856 207.783 58.6315 209.522C57.5487 211.728 55.852 215.18 52.88 214.964C52.3206 214.917 51.7941 214.679 51.3886 214.291C50.9831 213.903 50.7234 213.387 50.6525 212.83C50.526 211.587 51.3878 210.662 52.4459 210.192C54.0692 209.47 53.1891 206.937 51.5701 206.958C49.4282 206.986 47.4118 205.481 47.0263 203.319C46.6147 201.013 48.4222 199.626 50.1167 198.496C50.9472 197.943 50.7221 196.377 49.5656 196.462L49.5671 196.463Z"
              fill="#171717"
            />
          </g>
          <g opacity="0.1">
            <path
              d="M173.067 137.13C173.712 135.485 175.596 133.798 176.86 135.944C177.822 137.577 176.711 139.494 175.928 140.96C175.356 142.029 176.576 143.526 177.709 142.74C179.043 141.816 180.15 143.304 179.644 144.62C178.952 146.422 177.076 145.645 175.793 146.371C175.66 146.451 175.547 146.561 175.462 146.691C175.378 146.821 175.323 146.968 175.303 147.122C175.283 147.276 175.299 147.433 175.348 147.58C175.397 147.728 175.478 147.862 175.586 147.973C176.624 149.105 178.75 148.624 179.963 147.984C180.683 147.612 181.296 147.062 181.743 146.386C182.191 145.711 182.458 144.932 182.52 144.123C182.715 140.979 179.104 138.518 176.396 140.493L178.177 142.274C179.534 139.58 180.897 135.67 177.837 133.531C175.185 131.679 172.742 134.399 172.107 136.865C171.943 137.5 172.838 137.715 173.069 137.13H173.067Z"
              fill="#171717"
            />
          </g>
          <g opacity="0.1">
            <path
              d="M182.245 161.959C181.55 164.505 186.009 164.761 187.49 164.271C189.51 163.603 191.765 160.248 189.876 158.381C189.784 158.292 189.673 158.224 189.551 158.184C189.43 158.144 189.3 158.131 189.173 158.148C189.046 158.164 188.925 158.209 188.817 158.279C188.71 158.348 188.619 158.442 188.553 158.551C187.762 159.8 187.905 161.281 186.33 161.997C184.96 162.62 183.874 161.356 182.551 161.653C182.478 161.673 182.411 161.711 182.357 161.765C182.304 161.819 182.265 161.886 182.245 161.959Z"
              fill="#171717"
            />
          </g>
          <g opacity="0.1">
            <path
              d="M178.363 175.668C178.524 177.214 179.173 178.351 180.657 178.982C181.435 179.305 182.284 179.422 183.12 179.321C183.957 179.221 184.754 178.906 185.433 178.407L183.68 177.695C184.406 179.961 183.123 182.927 185.666 184.453C188.182 185.964 190.203 183.445 190.898 181.289C191.435 179.62 188.865 178.945 188.291 180.57C187.908 181.654 186.822 182.947 186.384 181.156C186.053 179.809 186.405 178.413 185.981 177.058C185.769 176.379 184.885 175.882 184.228 176.345C182.532 177.542 180.547 176.821 179.335 175.264C179.04 174.884 178.313 175.194 178.363 175.666V175.668Z"
              fill="#171717"
            />
          </g>
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_8682_53296"
            x1="141.515"
            y1="91.8106"
            x2="158.59"
            y2="91.8106"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#9796F0" />
            <stop offset="1" stopColor="#FBC7D4" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_8682_53296"
            x1="121.046"
            y1="90.9505"
            x2="136.291"
            y2="90.9505"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#9796F0" />
            <stop offset="1" stopColor="#FBC7D4" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_8682_53296"
            x1="115.562"
            y1="21.4273"
            x2="149.343"
            y2="21.4273"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#9796F0" />
            <stop offset="1" stopColor="#FBC7D4" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_8682_53296"
            x1="123.97"
            y1="191.033"
            x2="176.804"
            y2="191.033"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#9796F0" />
            <stop offset="1" stopColor="#FBC7D4" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_8682_53296"
            x1="155.256"
            y1="174.101"
            x2="155.379"
            y2="174.101"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#9796F0" />
            <stop offset="1" stopColor="#FBC7D4" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_8682_53296"
            x1="98.9033"
            y1="88.1145"
            x2="114.979"
            y2="88.1145"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#9796F0" />
            <stop offset="1" stopColor="#FBC7D4" />
          </linearGradient>
          <linearGradient
            id="paint6_linear_8682_53296"
            x1="94.502"
            y1="212.754"
            x2="124.691"
            y2="212.754"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#9796F0" />
            <stop offset="1" stopColor="#FBC7D4" />
          </linearGradient>
          <linearGradient
            id="paint7_linear_8682_53296"
            x1="74.3818"
            y1="192.408"
            x2="123.968"
            y2="192.408"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#9796F0" />
            <stop offset="1" stopColor="#FBC7D4" />
          </linearGradient>
        </defs>
      </svg>
    </>
  );
};
