export const isUserOnFreePlan = (user) => {
  if (user.isPaidUser === undefined) throw Error('Invalid User object');
  // Switch to user.isPaidUser for sub status
  return !user.isPaidUser;
};
export const firstLetterCaps = (str) => {
  return str[0].toUpperCase() + str.slice(1);
};

export const apostropheLastLetter = (str) => {
  if (str.slice(-1) === 's') {
    return str.replace(/.$/, "s'");
  } else {
    return str.concat("'s");
  }
};
export const convertNumber = (str) => {
  if (str.slice(0) === '+') {
    return Number(str.slice(0));
  } else {
    return Number(str);
  }
};

export const trunacteString = (str = '', length) => {
  if (str.length <= length) return str;
  return str.substring(0, Number(length)).concat('...');
};

export const isInValidName = (str) => {
  if (/^[A-Za-z-\x20]+$/.test(str) === false) {
    return 'This field only accepts letters, hyphens and spaces';
  }

  return false;
};

export const cleanString = (str, replaceWith = '-') => {
  let cleanedStr = str?.replace(/\s+/g, replaceWith);

  cleanedStr = cleanedStr?.replace(/[^a-zA-Z0-9-_]/g, '');

  return cleanedStr;
};


export const generateUsername = async(fullName, email) => {
  // Clean up input to remove spaces and non-alphanumeric characters
  const cleanFullName = fullName.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();

  // Combine the cleaned parts
  const baseUsername = `${cleanFullName}`;

  // Generate a short hash from the combination to ensure uniqueness
  const encoder = new TextEncoder();
  const data = `${fullName}${email}`;
  const encodedData = encoder.encode(data);

  const hashBuffer = await crypto.subtle.digest('SHA-1', encodedData); // Use SHA-1 for shorter hash
  const hashArray = Array.from(new Uint8Array(hashBuffer));

  // Convert to a short hexadecimal string (use first 5 chars for uniqueness)
  const shortHash = hashArray
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('')
    .slice(0, 5);

  // Combine base username and short hash to ensure uniqueness
  return `${baseUsername}${shortHash}`;
}