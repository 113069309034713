import { apiAuthSlice } from './apiAuthSlice';

export const stripePaymentApiSlice = apiAuthSlice.enhanceEndpoints({}).injectEndpoints({
  endpoints: (builder) => ({
    createPaygPaymentIntent: builder.mutation({
      query: ({ quantity }) => ({
        url: `payment/stripe/payment-intent/create`,
        method: 'POST',
        body: { quantity },
      }),
    }),
    validatePaymentIntent: builder.mutation({
      query: ({ paymentIntentId }) => ({
        url: `payment/stripe/payment-intent/validate`,
        method: 'POST',
        body: { paymentIntentId },
      }),
    }),
    createSubscriptionWithStripe: builder.mutation({
      query: (payload) => ({
        url: `payment/stripe/subscription/create`,
        method: 'POST',
        body: payload,
      }),
    }),
    updateSubscriptionWithStripe: builder.mutation({
      query: (payload) => ({
        url: `payment/stripe/subscription/update`,
        method: 'POST',
        body: payload,
      }),
    }),
    createTopupPaymentIntent: builder.mutation({
      query: ({ quantity }) => ({
        url: `payment/stripe/topup`,
        method: 'POST',
        body: { quantity },
      }),
    }),
    overrideExisting: true,
  }),
});

export const {
  useCreatePaygPaymentIntentMutation,
  useValidatePaymentIntentMutation,
  useCreateSubscriptionWithStripeMutation,
  useUpdateSubscriptionWithStripeMutation,
  useCreateTopupPaymentIntentMutation,
} = stripePaymentApiSlice;
