import { Modal, Input, Form } from 'antd';
import { useSelector } from 'react-redux';
import { MdTypography, MdGlobalButton } from 'components/global';
import { CloseSquareIcon } from '../../asset/Icons/CloseSquareIcon';
import { CheckCircleGreen } from '../../asset/Icons/CheckCirlcleGreen';
import { acceptedPhoneNumber, mustContainAtLeastOneChar, validatePhoneNumberKeyDown } from 'utils/formRules';
import { showToastMessage } from 'utils/Toast';
import { useUpdateAccountDetailsMutation } from 'redux/featureApi/accountSettingsApiSlice';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setUserPhoneNumber } from '../../redux/featureSlices/authSlice';

export const CompleteProfileModal = ({ imVisible, setImVisible, setIsPhoneNumberNull }) => {
  const [CompleteProfileForm] = Form.useForm();
  const userDetails = useSelector((state) => state.auth.user);
  const [updateAccountDetails, { isLoading: isUpdatingAccountDetails }] = useUpdateAccountDetailsMutation();
  const [step, setStep] = useState({ id: 'incomplete' });
  const dispatch = useDispatch();

  const validatePhoneNumber = async (_, value) => {
    const result = acceptedPhoneNumber(value);

    if (result.status) {
      return Promise.resolve();
    } else {
      return Promise.reject(result.message);
    }
  };

  const completeProfileFn = (values) => {
    const payload = {
      fullname: userDetails?.fullname,
      email: userDetails?.email,
      phoneNumber: values.phoneNumber,
      language: userDetails?.language,
      companyName: values.companyName,
      website: userDetails?.website,
      city: userDetails?.city,
      state: userDetails?.state,
      officeAddress: userDetails?.officeAddress ?? '',
    };

    updateAccountDetails(payload)
      .unwrap()
      .then(() => {
        dispatch(setUserPhoneNumber({ phoneNumber: values.phoneNumber }));
        setStep({ id: 'complete' });
        setImVisible({ id: '' });
        setIsPhoneNumberNull(false);
      })
      .catch(() => {
        showToastMessage({
          type: 'error',
          title: 'Error',
          description: 'Oops! An error occured, Please try again',
        });
      });
  };

  useEffect(() => {
    if (Object.keys(userDetails).length !== 0 && userDetails?.phoneNumber) {
      setStep({ id: 'complete' });
    }
  }, [userDetails]);

  return (
    <Modal
      open={imVisible.id === 'completeProfileModal'}
      onCancel={() => setImVisible({ id: '' })}
      footer={null}
      className="emmaccen complete-profile-modal"
      width={'428px'}
    >
      {step.id === 'incomplete' && (
        <>
          <div className="close-icon-container">
            <CloseSquareIcon onClick={() => setImVisible({ id: '' })} />
          </div>
          <div className="title-container">
            <MdTypography variant={'h5Light'} className="title">
              Please provide the following information to complete your profile
            </MdTypography>
          </div>
          <div className="complete-profile-form-container">
            <Form
              form={CompleteProfileForm}
              name="basic"
              onFinish={(values) => {
                completeProfileFn(values);
              }}
              size="large"
              onFinishFailed={() => null}
            >
              <div>
                <label className="mb-2 formLabel">Company name/preferred username</label>
                <Form.Item
                  name="companyName"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter a company name/username',
                    },
                    ...mustContainAtLeastOneChar,
                  ]}
                >
                  <Input placeholder="Enter Company name" />
                </Form.Item>
              </div>
              <div>
                <label className="mb-2 formLabel">Phone number</label>
                <Form.Item
                  name="phoneNumber"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter a phone number',
                    },
                    { validator: validatePhoneNumber },
                  ]}
                >
                  <Input placeholder="Enter phone number" onKeyDown={validatePhoneNumberKeyDown} />
                </Form.Item>
              </div>

              <div className="complete-profile-button-container">
                <MdGlobalButton
                  dataTestId="complete-profile-button"
                  id={'primary_lg'}
                  type="primary"
                  htmlType="submit"
                  className="complete-profile-button"
                  loading={isUpdatingAccountDetails}
                >
                  <MdTypography variant={'h6Medium'} style={{ color: '#ffffff' }}>
                    Complete Profile
                  </MdTypography>
                </MdGlobalButton>
              </div>
            </Form>
          </div>
        </>
      )}
      {step.id === 'complete' && (
        <>
          <div className="profile-completed-container">
            <CheckCircleGreen />
            <div className="completed-info">
              <MdTypography variant={'h6Medium'}>Profile Completed</MdTypography>
              <MdTypography variant={'pLight'}>Well-done, you have successfully completed your profile </MdTypography>
            </div>
            <div className="completed-button-container">
              <MdGlobalButton
                dataTestId="profile-completed-button"
                id={'primary_lg'}
                type="primary"
                htmlType="submit"
                className="profile-completed-button"
                loading={isUpdatingAccountDetails}
                onClick={() => {
                  setImVisible({ id: '' });
                  setIsPhoneNumberNull(false);
                }}
              >
                <MdTypography variant={'h6Medium'} style={{ color: '#ffffff' }}>
                  Go back to dashboard
                </MdTypography>
              </MdGlobalButton>
            </div>
          </div>
        </>
      )}
    </Modal>
  );
};
