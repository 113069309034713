/* eslint-disable */
export const min3char = [
  {
    min: 3,
    message: 'Must be a minimum of 3 characters',
  },
];
export const minDynamicChar = (num) => {
  return [
    {
      min: num,
      message: `Must be a minimum of ${num} characters`,
    },
  ];
};
export const maxDynamicChar = (num) => {
  return [
    {
      max: num,
      message: `Must be a maximum of ${num} characters`,
    },
  ];
};
export const containAzDchar = [
  {
    pattern: /^[A-Za-z\s-]+$/,
    message: 'Must contain letters between (Aa-Zz)',
  },
];

export const containAaZzHyphenSpace = [
  {
    pattern: /^[A-Za-z- \x20]+$/,
    message: 'This field only accepts letters, hyphens and spaces',
  },
];

export const containAzDcharDot = [
  {
    pattern: /^[A-Za-z-. ]+$/,
    message: 'Must contain letters between (Aa-Zz)',
  },
];

export const containValidEmail = [
  {
    required: true,
    pattern: /^([a-zA-Z0-9-_.]+)\@([a-zA-Z-_.]+)\.([a-zA-Z]{1,5})/,
    message: 'Must contain a valid email address',
  },
];

export const mustHaveLetters = [
  {
    pattern: /([A-Za-z])/,
    message: 'Must contain letters between (Aa-Zz)',
  },
];

export const containAzchar = [
  {
    pattern: /^[A-Za-z ]+$/,
    message: 'Must contain letters between (Aa-Zz)',
  },
];
export const containSpeshChar = [
  {
    pattern: new RegExp(/([!%@#$&*])/),
    message: 'Must contain special characters (!%@#$&*)',
  },
];
export const containNumChar = [
  {
    pattern: /^[0-9]+$/,
    whitespace: true,
    message: 'Must contain numbers between (0-9)',
  },
];
export const containLowAzChar = [
  {
    pattern: new RegExp(/([a-z])/),
    message: 'Must contain lowercase characters between (a-z)',
  },
];

export const mustBeValidUrl = [
  {
    pattern: new RegExp(
      /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{1,}(\.[a-zA-Z]{1,})(\.[a-zA-Z]{1,})?\/[a-zA-Z0-9]{1,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{1,}(\.[a-zA-Z]{1,})(\.[a-zA-Z]{1,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{1,}\.[a-zA-Z0-9]{1,}\.[a-zA-Z0-9]{1,}(\.[a-zA-Z0-9]{1,})?/g
    ),
    message: 'This field must be a valid url',
  },
];

export const shouldHaveHttps = [
  {
    pattern: new RegExp(
      /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?\w{1,}(\.\w{1,}|(org)|(io)|(co)|(com)|(me))(\/\w{1,}|(io)|(co)|(com)|(org))?/g
    ),
    message: 'This field must be a valid url',
  },
];

export const aZDigitSpeshChar = [
  {
    pattern: new RegExp(/^[\.a-zA-Z0-9,\- ]*$/gm),
    message: 'This field accepts letters, digits, hyphen, spaces, comma and full stop',
  },
];

export const phoneNumberValidation = [
  {
    pattern: new RegExp(/^[\+0-9\-\(\)]*$/gm),
    message: 'must be a valid phone number',
  },
];

export const mustContainAtLeastOneChar = [
  {
    pattern: new RegExp(/\S+/),
    message: 'Must contain at least one character',
  },
];

export const acceptedPhoneNumber = (value) => {
  // Remove all non-digit characters
  const phoneNumber = value.replace(/\D/g, '');

  if (phoneNumber.length < 7) {
    return {
      message: 'Must contain a minimum of 7 digits',
      status: false,
    };
  } else if (phoneNumber.length > 15) {
    return {
      message: 'Must contain a maximum of 15 digits',
      status: false,
    };
  } else {
    return {
      message: 'Valid phone number',
      status: true,
    };
  }
};

export const doNotAllowSpacesAndOtherCharacter = [
  {
    pattern: new RegExp(/^[A-Za-z_-]+$/),
    message: 'Cannot contain spaces and other characters',
  },
];

export const validatePhoneNumberKeyDown = (e) => {
  const allowedKeys = [
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '+',
    '-',
    '(',
    ')',
    'Backspace',
    'ArrowLeft',
    'ArrowRight',
    'ArrowUp',
    'ArrowDown',
  ];

  if (!allowedKeys.includes(e.key)) {
    e.preventDefault();
  }
};

export const mustNotContainSpecialCharacters = [
  {
    pattern: new RegExp(/^[A-Za-z0-9_ -]+$/),
    message: 'Cannot contain special characters',
  },
];