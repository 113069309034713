/* eslint-disable no-unused-vars */
import React, { useEffect , useMemo} from 'react';
import contactHeaderImg from '../../asset/images/contactHeaderImg.png';
import { MdContactHeader } from './MdContactHeader';
import styles from '../../styles/newContactPage.module.scss';
import { Button, Select, Space, Table } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { MdArrowDown } from './Icons/MdArrowDown';
import { MdGlobalButton, MdTypography } from 'components/global';
import { MdUserAdd } from './Icons/MdUserAdd';
import { MdExportIcon } from './Icons/MdExportIcon';
import { MdPlus } from './Icons/MdPlus';
import { useState } from 'react';
import { MdSelectionPopUp } from './MdSelectionPopUp';
import { MdPagination } from './MdPagination';
import moment from 'moment';
import { MdEmptyBox } from './Icons/MdEmptyBox';
import { MdDeleteContact } from './MdDeleteContact';
import { MdCreateNewContact } from './MdCreateNewContact';
import { MdEditContact } from './MdEditContact';
import { MdCreateGroup } from './MdCreateGroup';
import { MdAddToGroup } from './MdAddToGroup';
import useDebounce from 'hooks/useDebounce';
import { useLocation } from 'react-router';
import { useGetAllContactGroupsQuery, useGetGroupContactsQuery } from 'redux/featureApi/contactApiSlice';
import { Link } from 'react-router-dom';
import MdUploadCsvModal from './MdUploadCsvModal';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import { showToastMessage } from 'utils/Toast';
import axios from 'axios';

export const MdNewContactPage = () => {
  const [multiselectionData, setMultiselectionData] = useState();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [imVisible, setImVisible] = useState({ id: '' });
  const [tableSearchData, setTableSearchData] = useState([]);
  const [csvResult, setCsvResult] = useState([]);
  const [actionableContactData, setActionableContactData] = useState({});
  const [currentGroup, setCurrentGroup] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [tableData, setTableData] = useState([]);
  const { search } = useLocation();
  const groupId = new URLSearchParams(search).get('groupId');
  const [imLoading, setImLoading] = useState({ id: '' });
  const [contactGroups, setContactGroups] = useState([]);
  const [addToGroup, setAddToGroup] = useState(false);
  const [exportData, setExportData] = useState([]);
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setMultiselectionData(selectedRowKeys);
      setImVisible({ id: 'multiselect' });
    },
    getCheckboxProps: (record) => ({
      name: record._id,
    }),
  };

  const { Option } = Select;

  //get all contacts group with rtk
  const {
    data: getAllContactGroupsData,
    isLoading: fetchingGroups,
    isFetching,
    refetch: refetchContactGroups,
  } = useGetAllContactGroupsQuery();
  
  const {
    data: getGroupContactsData,
    refetch: refetchGroupContactsData,
    isLoading: loadingContactGroups,
    isFetching: fetchingContactGroups,
  } = useGetGroupContactsQuery(
    { groupId: groupId || '', page, limit: 100, search: debouncedSearchTerm },
    { refetchOnMountOrArgChange: true, }
  );
 
  const csvExpHeaders = [
    {
      key: 'firstName',
      displayLabel: 'First Name',
    },
    {
      key: 'lastName',
      displayLabel: 'Last Name',
    },
    {
      key: 'email',
      displayLabel: 'Email',
    },
    {
      key: 'date',
      displayLabel: 'Date Added',
    },
    {
      key: 'phone',
      displayLabel: 'Phone',
    },
  ];
  const csvConfig = mkConfig({
    filename: `${currentGroup?.title}_contacts`,
    columnHeaders: csvExpHeaders,
  });

  const api_url = process.env.REACT_APP_API_URL;
  async function fetchAndExportData() {
    setImLoading({ id: 'exporting' });
    try {
      const result = await axios.get(`${api_url}/contacts/group?groupId=${groupId}&page=${page}&limit=all`);
      if (result.status) {
        const formattedResult = result.data?.data?.group?.contacts?.map(({ name, email, phone, createdAt }) => ({
          firstName: name?.split(' ').at(0),
          lastName: name?.split(' ').at(1),
          email,
          phone: parseFloat(phone),
          date: moment(createdAt).format('MMMM D, YYYY'),
        }));
        const csv = generateCsv(csvConfig)(formattedResult);
        download(csvConfig)(csv);
        setImLoading({ id: '' });
        return;
      }
      showToastMessage({
        type: 'error',
        title: 'Error',
        description: result?.message || 'An error occurred while exporting data. Please try again after 5 minutes',
      });
      setImLoading({ id: '' });
    } catch (error) {
      setImLoading({ id: '' });
      showToastMessage({
        type: 'error',
        title: 'Error',
        description: error?.message || 'An error occurred while exporting data. Please try again after 5 minutes',
      });
    }
  }
  const filteredGroup = (contactGroups) =>
    contactGroups.filter((group) => !(group._id === 'risky_contacts' || group._id === 'all_contacts'));    
    useEffect(() => {
    setMultiselectionData([]);
    if (getAllContactGroupsData) {
      setContactGroups(getAllContactGroupsData?.groups);
      console.log(getAllContactGroupsData)
      setCurrentGroup(
        groupId ? getAllContactGroupsData?.groups.find((x) => x._id === groupId) : getAllContactGroupsData?.groups[0]
      );
  
    }
  }, [isFetching, getAllContactGroupsData, groupId]);  

  useEffect(() => {
    setMultiselectionData([]);
    setImLoading({ id: 'contactTableLoading' });
    refetchGroupContactsData({ groupId: groupId || '', page });
    refetchContactGroups()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId, loadingContactGroups, page]);
 
  useEffect(() => {
    if (getGroupContactsData) {
      setTotalPages(getGroupContactsData?.data?.group?.count);
      setTableData(getGroupContactsData?.data?.group?.contacts);
      setTableSearchData(getGroupContactsData?.data?.group?.contacts);
    }
  }, [getGroupContactsData]);
 

  const searchContact = (e) => {
    setPage(1);
    setSearchTerm(e.target.value);
  };

  const listGroups = (arr1, arr2) => {
    const result = arr1.filter((item) => arr2?.includes(item?._id));
    return result;
  };

  const columns = [
    {
      title: 'First Name',
      render: (contactData) => {
        return (
          <div className="flex mdTableActionBtns" data-test="first-name">
            {currentGroup?._id && (
              <p>{contactData?.name ? contactData?.name.split(' ').at(0) : contactData?.firstName}</p>
            )}
          </div>
        );
      },
    },
    {
      title: 'Last Name',
      render: (contactData) => {
        return (
          <div className="flex mdTableActionBtns" data-test="last-name">
            {currentGroup?._id && (
              <p>{contactData?.name ? contactData?.name.split(' ').at(1) : contactData?.lastName}</p>
            )}
          </div>
        );
      },
    },
    {
      title: 'Email',
      render: (contactData) => {
        return <div className="flex mdTableActionBtns">{currentGroup?._id && <p>{contactData?.email}</p>}</div>;
      },
    },
    {
      title: 'Date Added',
      render: (contactData) => {
        return (
          <div className="flex mdTableActionBtns">
            {currentGroup?._id && <p>{moment(contactData?.createdAt).format('MMMM D, YYYY')}</p>}
          </div>
        );
      },
    },
    {
      title: 'Groups',
      render: (contactData) => {
        const groups = listGroups(contactGroups, contactData?.groups);
        return (
          <div className="flex mdTableActionBtns">
            {currentGroup?._id && (
              <>
                <p
                  onClick={() => {
                    setActionableContactData(contactData);
                    setImVisible({ id: 'editContact' });
                  }}
                >
                  {groups?.at(0)?.title || 'All contacts'}
                </p>
                {groups?.length > 1 && <div className="circle ml-2">+ {groups.length - 1}</div>}
              </>
            )}
          </div>
        );
      },
    },
  ];

  const locale = {
    emptyText: (
      <div className={styles['empty-box']}>
        <div className={styles['empty-img']}>
          <MdEmptyBox />
        </div>
        <div className="empty-text">You have not added any contacts yet</div>
      </div>
    ),
  };
  
  const GroupList = (group) => {
    const { title, totalContacts, _id: id } = group;
  
    return (
      <Option
        className={`pointer padLR ${groupId === id ? 'active-opt' : ''}`}
         key={id ?? title}
        value={id ?? title}
        label={title}
        onClick={() => setCurrentGroup(group)}
        data-test="group-option"
      >
        <Link to={`/contacts${id ? `?groupId=${id}` : ''}`} data-test="contact-group-option">
          <div className="listMenuContent w-full flex justify-between">
            <MdTypography className="contact_title elipsis pr-2 mt-2" variant="btnLight">
              {title}
            </MdTypography>
            <MdTypography className="total_contacts faded mt-2" variant="btnLight">
              {totalContacts}
            </MdTypography>
          </div>
        </Link>
      </Option>
    );
  };
  
  
  const refetchContactDetails = async () => {
    try {
      await refetchContactGroups();
      await refetchGroupContactsData({ groupId: groupId || '', page });
    } catch (error) {
      console.error('Error while refetching contact details:', error);
    }
  };
  return (
    // <div className={styles.contactPageContainer}>
    <div className={styles.newContentContainer}>
      <div>
        <MdContactHeader
          title="Contacts"
          description="Add and manage subscribers to your account"
          descriptionLink="See how it works"
          contactHeaderImg={contactHeaderImg}
          imLoading={imLoading}
          searchContact={searchContact}
        />
      </div>
      <MdDeleteContact
        imVisible={imVisible}
        setImVisible={setImVisible}
        multiselectionData={multiselectionData}
        currentGroup={currentGroup}
      />
      <MdCreateNewContact
        imVisible={imVisible}
        setImVisible={setImVisible}
        contactGroups={filteredGroup(contactGroups)}
        currentGroup={currentGroup}
        setCsvResult={setCsvResult}
        refetch={refetchGroupContactsData}
      />
      {imVisible.id === 'editContact' && (
        <MdEditContact
          imVisible={imVisible}
          setImVisible={setImVisible}
          actionableContactData={actionableContactData}
          contactGroups={filteredGroup(contactGroups)}
          setMultiselectionData={setMultiselectionData}
        />
      )}
      <MdCreateGroup contactGroups={contactGroups} imVisible={imVisible} setImVisible={setImVisible} />
      <MdAddToGroup
        imVisible={imVisible}
        setImVisible={setImVisible}
        contactGroups={contactGroups}
        multiselectionData={multiselectionData}
        currentGroup={currentGroup}
        addToGroup={addToGroup}
      />
      <MdUploadCsvModal
        csvResult={csvResult}
        setCsvResult={setCsvResult}
        uploadContactsTo="addNewContactGroup"
        imVisible={imVisible}
        setImVisible={setImVisible}
        currentGroup={currentGroup}
        setCurrentGroup={setCurrentGroup}
        contactGroups={filteredGroup(contactGroups)}
        refetchContactDetails={refetchContactDetails}
      />
      <div className="contactWrapper">
        <div className="actionTab">
          <div className="flex justify-content-between">
            <div className="flex w-full max-w-2xl">
              <div className="contact_selectCont">
                <Select
                  placeholder="Select group to display contacts"
                  className="contact_select"
                  loading={fetchingGroups && <LoadingOutlined />}
                  suffixIcon={<MdArrowDown />}
                  showSearch
                  optionFilterProp="children"
                  value={isFetching?"loading...":groupId}
                  filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                  data-test="contact-select"
                >
                    {contactGroups.map(GroupList)}
                </Select>
              </div>
              <div data-test="new-group" onClick={() => setImVisible({ id: 'createContactGroup' })}>
                <MdGlobalButton className={`addGrpBtn ml-3`}>
                  <MdTypography variant={'btnRegular'} className="addGroupText">
                    New group
                  </MdTypography>
                  <MdPlus className="pointer" />
                </MdGlobalButton>
              </div>
            </div>
            <div className="actions">
              <Space>
                <MdGlobalButton
                  data-test="add-new-contact-test"
                  onClick={() => setImVisible({ id: 'addNewContactGroup' })}
                  className="addGrpBtn solid"
                >
                  <MdTypography variant={'btnRegular'} style={{ color: '#f7f7f7' }}>
                    Add new
                  </MdTypography>
                  <MdUserAdd />
                </MdGlobalButton>

                <Button loading={imLoading.id === 'exporting'} className="ghostBtn" onClick={fetchAndExportData}>
                  <MdTypography variant={'btnRegular'} style={{ color: '#8e8e8e', marginRight: '.5rem' }}>
                    Export
                  </MdTypography>
                  <MdExportIcon />
                </Button>
              </Space>
            </div>
          </div>
        </div>
        <div className="contactTableContainer antdTable">
          <Table
            loading={loadingContactGroups || fetchingContactGroups || fetchingGroups}
            rowSelection={{
              selectedRowKeys: multiselectionData,
              type: 'checkbox',
              
              ...rowSelection,
            }}
            scroll={{ y: '300px', x: true }}
            sticky={true}
            rowKey={(id) => id?._id}
            onRow={(record, rowIndex) => {
              return {
                onClick: () => {
                  setActionableContactData(record);
                  setImVisible({ id: 'editContact' });
                },
              };
            }}
            columns={columns}
            dataSource={tableSearchData}
            locale={locale}
            pagination={{
              onChange: (page) => {
                setPage(page);
              },
              total: totalPages,
              className: 'contact-page',
              pageSize: 100,
              current: page,
              itemRender: MdPagination,
              showSizeChanger: false,
              showLessItems: true,
              showTotal: (total, range) => `${range[0]} - ${range[1]} of ${total} items`,
            }}
          />

          {imVisible?.id === 'multiselect' && multiselectionData?.length > 0 && (
            <MdSelectionPopUp
              setImVisible={setImVisible}
              data={multiselectionData}
              setAddToGroup={setAddToGroup}
              closeAll={setMultiselectionData}
            />
          )}
        </div>
      </div>
    </div>
    // </div>
  );
};