import React from 'react';
import { Modal as AntModal } from 'antd';

const Modal = ({
  isActive,
  onCancel,
  closable = false,
  onOkay,
  okText,
  cancelText,
  title,
  maskClosable,
  className,
  width = 480,
  footer = null,
  children,
  // closable = false,
}) => {
  return (
    <>
      <AntModal
        open={isActive}
        onCancel={onCancel}
        onOkay={onOkay}
        okText={okText}
        cancelText={cancelText}
        footer={footer}
        closable={closable}
        width={width}
        maskClosable={maskClosable}
        centered
        destroyOnClose
        wrapClassName={`cust-modal-class ${className}`}
      >
        <div>
          <div className="text-lg text-center font-semibold">{title}</div>
          {children}
        </div>
      </AntModal>
    </>
  );
};

export default Modal;
