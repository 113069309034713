import { Button, Form, Input, Modal } from 'antd';
import { useCreateContactGroupMutation } from '../../redux/featureApi/contactApiSlice';
import { showToastMessage } from '../../utils/Toast';
import { MdTypography } from 'components/global';
import styles from '../../styles/newContactPage.module.scss';
import { MdClose } from './Icons/MdClose';

export const MdCreateGroup = ({ imVisible, setImVisible, contactGroups }) => {
  const [createContactGroupForm] = Form.useForm();

  const [createContactGroup, { isLoading: creatingContactGroup }] = useCreateContactGroupMutation();

  const onSubmit = ({ title, description = '' }) => {
    const titles = contactGroups?.map((grp) => grp.title?.toLowerCase());
    if (titles.map((grp) => grp?.toLowerCase()).includes(title?.toLowerCase())) {
      showToastMessage({
        type: 'error',
        title: 'Contact Group',
        description: 'Group with this title already exist',
      });
      return;
    } else if (title?.length > 0 && title?.replace(/\s/g, '').length === 0) {
      showToastMessage({
        type: 'error',
        title: 'Contact Group',
        description: 'Please enter a valid group name',
      });
      return;
    } else if (title.length === 0) {
      showToastMessage({
        type: 'error',
        title: 'Contact Group',
        description: 'Group cannot be empty',
      });
      return;
    }
    createContactGroup({ payload: { title, description } })
      .then((data) => {
        showToastMessage({
          type: 'success',
          title: 'Contact Group',
          description: 'New contact group created',
        });
        createContactGroupForm.resetFields();
      })
      .finally(() => {
        setImVisible({ id: '' });
      });
  };
  return (
    <Modal
      className={`emmaccen ${styles['contact-modal']}`}
      open={imVisible.id === 'createContactGroup'}
      onCancel={() => {
        setImVisible({ id: '' });
      }}
      footer={null}
      maskClosable={false}
      closable={true}
      destroyOnClose={true}
      closeIcon={<MdClose />}
      centered
    >
      <div className={styles['addNewContactModal']}>
        <MdTypography variant={'h5Medium'} className="text-center title mb-8">
          Create new group
        </MdTypography>
        <Form
          form={createContactGroupForm}
          onFinish={(data) => onSubmit(data)}
          onFinishFailed={() => null}
          autoComplete="off"
          layout={'vertical'}
          size="large"
        >
          <Form.Item
            name="title"
            label="Name of group"
            rules={[{ required: true, message: 'Please, provide a title!' }]}
          >
            <Input placeholder="Enter group name" data-test="create-contact-grp-title" />
          </Form.Item>
          <Form.Item name="description" label="Description (optional)">
            <Input placeholder="Enter group description" data-test="create-contact-grp-desc" />
          </Form.Item>
          <div className="emmaccen">
            <div className="flex-space-btw pad30">
              <Button
                size={'large'}
                type=""
                onClick={() => setImVisible({ id: '' })}
                className={styles['import-btn']}
                style={{ color: '#1C1C1C' }}
              >
                Back
              </Button>
              <Button
                size={'large'}
                type="primary"
                htmlType="submit"
                loading={creatingContactGroup}
                data-test="create-contact-grp-btn"
                className={`${styles['contact-btn']} mt-0`}
              >
                Create
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );
};
