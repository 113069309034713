import LoadingScreen from 'components/LoadingScreen';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetSingleLandingPageQuery } from 'redux/featureApi/landingPageApiSlice';
import { showToastMessage } from 'utils/Toast';
import styles from '../../styles/optInPage.module.scss';
import { StopOutlined } from '@ant-design/icons';
import HideFormillaChatBox from 'utils/miniFunctions/HideFormillaChatBox';

const MdLandingPageView = () => {
  const { landingPageId } = useParams();
  const [landingPageData, setLandingPageData] = useState({});
  const [isPublished, setIsPublished] = useState(false);
  const [loadingLandingPage, setLoadingLandingPage] = useState(true)
  const {
    data: singleLandingPageData,
    isLoading: loadingSingleLandingPageData,
    isSuccess: fetchedSingleLandingPageData,
    isError: fetchSingleLandingPageDataFailed,
  } = useGetSingleLandingPageQuery(landingPageId);

  // Adjusts the CSS by adding global styles, while excluding the styles from the outerParent and its descendants
  const adjustCss = (css) => {
    return `
      ${css}
      body:not(.no-reset),
      .es-content-body:not(.no-reset),
      .es-left:not(.no-reset),
      .es-right:not(.no-reset) {
        width: 100% !important;
        max-width: 100% !important;
      }
      table:not(.no-reset),
      td:not(.no-reset),
      th:not(.no-reset) {
        width: 100% !important;
      }
    `;
  };

  // Function to apply CSS and HTML content
  const cssAppliedContent = (body, css) => `
    <div class="landing-page-container" style="width: 100%;">
      <style>
       ${adjustCss(css)}
      </style>
      ${body}
    </div>
  `;

  // UseEffect to handle the fetching of data and setting state
  useEffect(() => {
    if (fetchedSingleLandingPageData && singleLandingPageData) {
      setLandingPageData(singleLandingPageData?.data?.landingPage);
      setIsPublished(singleLandingPageData?.data?.landingPage?.published);
      setLoadingLandingPage(false)
    }

    if (fetchSingleLandingPageDataFailed) {
      showToastMessage({
        type: 'error',
        description: 'Error',
        message: 'Error fetching single landing page data',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingSingleLandingPageData]);

  useEffect(() => {
    if (fetchedSingleLandingPageData && singleLandingPageData) {
      // Select all elements with the class 'links'
      const linksElements = document.querySelectorAll('.links');

      if (linksElements.length) {
        // Loop through each .links element
        linksElements.forEach((linksParent) => {
          linksParent.style.display = 'flex';
          linksParent.style.width = '100%';
          linksParent.style.gap = '45px';

          let parentElement = linksParent;
          let counter = 0;

          // Traverse up and add the no-reset class to every parent up to the 11th parent
          while (parentElement && counter < 14) {
            if (counter === 11) {
              parentElement.classList.add('no-reset-xyzz');
              parentElement.style.display = 'flex';
              parentElement.style.alignItems = 'center';
              parentElement.style.justifyContent = 'space-between';
            }
            parentElement.classList.add('no-reset');
            parentElement = parentElement.parentElement; // Move to the parent element
            counter++;
          }
        });

        linksElements[0].style.marginLeft = '50px';

        if (linksElements[1]) {
          linksElements[1].style.marginRight = '50px';
        }
      }
    }
    //eslint-disable-next-line
  }, [isPublished]);

  return (
    <>
      <HideFormillaChatBox />
      {loadingLandingPage ? (
        <LoadingScreen />
      ) : (
        <>
          {!isPublished ? (
            <div className="restricted">
              <div className="restricted-inner">
                <StopOutlined style={{ color: 'red', fontSize: '3rem', marginBottom: '1rem' }} />
                <div className={styles.heading}> Restricted, Page isn't live!</div>
                <p className={`${styles.design_body} text-md`}>
                  Sorry you can't view this page because the owner of this page has taken it down or is yet to publish
                  it. Kindly reach out to the owner of the link. And if you're the owner, kindly go to your dashboard
                  and publish your landing page to view it live!
                </p>
              </div>
            </div>
          ) : (
            <div>
              <div
                dangerouslySetInnerHTML={{
                  __html: cssAppliedContent(landingPageData?.strippoJuicedHtml, landingPageData?.strippoCss),
                }}
              ></div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default MdLandingPageView;
