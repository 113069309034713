import { GrowthBookProvider } from '@growthbook/growthbook-react';
import * as Sentry from '@sentry/react';
import 'antd/dist/reset.css';
import { createBrowserHistory } from 'history';
import StripoEditor from 'pages/stripoEditor/StripoEditor';
import React, { useEffect } from 'react';
import { QueryCache, QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import AccountSettingsPage from './components/Account/index';
import DraggableUploadStatus from './components/DraggableUploadStatus';
import JoinACampaign from './components/JoinCampaign';
import JoinPage from './components/JoinCampaign/JoinPage';
import NonProtectedRoute from './components/auth/NonProtectedRoute';
import { ProtectedRoute } from './components/auth/ProtectedRoute';
import CampaignStatusModal from './components/emmaccen/modals/TurnOnCampaign';
import ContactUploadStats from './components/emmaccen/modals/uploadCsvModal/ContactUploadStats';
import JobContextProvider from './contexts/job';
import JoinCampaignContext from './contexts/joincampaign';
import ModalContextProvider from './contexts/modal';
import PaymentContext from './contexts/payment';
import { growthbook } from './growthBook.instance';
import * as Pages from './pages';
import CampaignEmails from './pages/CampaignEmails/index';
import CreateNew from './pages/CreateNew';
import { EnterMailDetails } from './pages/EnterMailDetails';
import { ImportRecIpient } from './pages/Import-recipient';
import EmailDetailedReport from './pages/InstantEmail/EmailDetailedReport';
import InstantEmailAction from './pages/InstantEmail/InstantEmailAction';
import InstantEmails from './pages/InstantEmail/InstantEmails';
import CampaignHome from './pages/MdCampaigns';
import Dashboard from './pages/MdDashboard';
import { RecipientDetails } from './pages/RecipientDetails';
import * as Auth from './pages/auth';
import { VerifyUserWithMdCTA as VerifyUser } from './pages/auth/VerifyUserWithMdCTA';
import { CardSuccessPage } from './pages/card-success-page';
import { DocumentEditor } from './pages/documentEditor/DocumentEditor';
import { InstantEmailDocumentEditor } from './pages/documentEditor/InstantEmailDocumentEditor';
import { Embed } from './pages/embed';
import ConfirmMailTemplate from './pages/grapeEditor/ConfirmMailTemplate';
import GrapeEditor from './pages/grapeEditor/Editor';
import InstantEmailGrapeEditor from './pages/grapeEditor/InstantEmailGrapeEditor';
import InstantEmailSubjectOfMail from './pages/grapeEditor/InstantEmailSubjectOfMail';
import SubjectOfMail from 'pages/grapeEditor/SubjectOfMail';
import { PaymentSuccessPage } from './pages/payment-success-page';
import MdOptInPage from 'pages/OptInPage/MdOptInPage';
import { store } from './redux/store';
import OptInPageName from 'pages/OptInPage/OptInPageName';
import OptInPageAction from 'pages/OptInPage/OptInPageAction';
import OptInPagePreview from 'pages/OptInPage/OptInPagePreview';
import MdLandingPage from 'pages/LandingPage/MdLandingPage';
import MdSelectLandingPageTemplate from 'pages/LandingPage/MdSelectLandingPageTemplate';
import MdLandingPageView from 'pages/LandingPage/MdLandingPageView';

export const AppContext = React.createContext();

export const appHistory = createBrowserHistory();

const queryClient = new QueryClient({
  queryCache: new QueryCache(),
});

const App = () => {
  useEffect(() => {
    // Load features asynchronously when the app renders
    growthbook.loadFeatures({ autoRefresh: true });
    growthbook.setAttributes({
      url: window.location.hostname,
      userAgent: navigator.userAgent,
    });
  }, []);

  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

  return (
    <Provider store={store}>
      <GrowthBookProvider growthbook={growthbook}>
        <PaymentContext>
          <JoinCampaignContext>
            <JobContextProvider>
              <ModalContextProvider>
                {/* <ConfigProvider direction="ltr"> */}
                {/*TODO:  Wrap job context around necessary components only to avoid state changes affection the entire application*/}
                <QueryClientProvider client={queryClient}>
                  {/* <Router history={appHistory}> */}
                  <Router>
                    {/* <FeedbackForm /> */}
                    <DraggableUploadStatus />
                    <CampaignStatusModal />
                    <ContactUploadStats />
                    <SentryRoutes>
                      <Route path=":username/join/:campaignTitle" element={<JoinPage />} />
                      <Route path="/invite/:token" element={<Pages.InviteToWorkspace />} />
                      <Route path="/:optInPageName/:optInPageId" element={<OptInPagePreview />} />
                      <Route path="landing-page/:landingPageId" element={<MdLandingPageView />} />
                      <Route element={<NonProtectedRoute />}>
                        <Route path="/signup" element={<Auth.MdSignUp />} />
                        <Route path="/login" element={<Auth.LoginPage />} />
                        <Route path="/verify" element={<VerifyUser />} />
                        <Route path="/payment-success" element={<PaymentSuccessPage />} />
                        <Route path="/card-success" element={<CardSuccessPage />} />
                        <Route path="/reset-password/:hash" element={<Auth.ResetPasswordPage />} />
                        <Route path="/unsubscribe" element={<Auth.Unsubscribe />} />
                        <Route path="/forgot-password" element={<Auth.ForgotPasswordPage />} />
                      </Route>

                      <Route element={<ProtectedRoute includeLayout={false} />}>
                        {/* Campaign Routes */}
                        <Route path="/join/:username/:campaignTitle/admin-edit" element={<JoinACampaign />} />
                        <Route path="/campaign/:campaignId/:campaignMailId/web-builder" element={<GrapeEditor />} />
                        <Route path="/campaign/:campaignId/:mailId/speditor" element={<StripoEditor />} />
                        <Route path="/campaign/:campaignId/mail-subject" element={<SubjectOfMail />} />
                        <Route path="/campaign/:campaignId/:emailId/text-editor" element={<DocumentEditor />} />
                        <Route path="/campaign/:campaignId/text-editor" element={<DocumentEditor />} />
                        <Route
                          path="/campaign/:campaignId/:campaignMailId/mailtemplate-confirm"
                          element={<ConfirmMailTemplate />}
                        />
                        <Route path="/campaign/:campaignId/:tab" element={<CampaignEmails />} />
                        <Route path="/campaigns" exact element={<CampaignHome />} />

                        {/* Instant email routes */}
                        <Route path="/instant-emails/:mailId/text-editor" element={<InstantEmailDocumentEditor />} />
                        <Route path="/instant-emails/:mailId/mail-subject" element={<InstantEmailSubjectOfMail />} />
                        <Route path="/instant-emails/:mailId/web-builder" element={<InstantEmailGrapeEditor />} />
                        <Route path="/instant-emails/:mailId/speditor" element={<StripoEditor />} />
                        <Route path="/instant-emails" element={<InstantEmails />} />
                        <Route path="/instant-emails/drafts/:mailId" element={<InstantEmailAction />} />

                        {/* Opt-in Page Routes */}
                        <Route path="/opt-in-page" element={<MdOptInPage />} />
                        <Route
                          path="/opt-in-page/:optInPageName/:optInPageId/edit-opt-in-page"
                          element={<OptInPageName />}
                        />
                        <Route path="/opt-in-page/drafts/:optInPageName/:optInPageId" element={<OptInPageAction />} />

                        {/* Landing Page Routes */}
                        <Route path="/landing-page" element={<MdLandingPage />} />
                        <Route
                          path="/landing-page/:landingPageId/select-template"
                          element={<MdSelectLandingPageTemplate />}
                        />
                        <Route path="/landing-page/:landingPageId/edit" element={<StripoEditor />} />

                        {/* Others */}
                        <Route path="/dashboard" element={<Dashboard />} />
                        <Route path="/create-new" element={<CreateNew />} />
                        <Route path="/settings/account/:tab" element={<AccountSettingsPage />} />
                        <Route path="/contacts" element={<Pages.ContactPage />} />
                        <Route path="/payment/pay" element={<Pages.PaymentPage />} />
                      </Route>

                      <Route element={<ProtectedRoute />}>
                        <Route path="/whats-new" element={<Pages.WhatsNew />} />
                        <Route path="/campaign/:campaignId/:campaignMailId/maildrip-editor" element={<GrapeEditor />} />
                        <Route path="/" exact element={<Navigate replace to="/dashboard" />} />
                        <Route path="/campaign/:campaignId/recipient-details/:mail" element={<RecipientDetails />} />
                        <Route path="/campaign/:campaignId/import-recipient" element={<ImportRecIpient />} />
                        <Route path="/emails/:mailId/report" element={<EmailDetailedReport />} />
                        <Route path="/campaign/:campaignId/enter-details" element={<EnterMailDetails />} />
                        <Route path="/embed" element={<Embed />} />
                      </Route>
                    </SentryRoutes>
                  </Router>
                  {/*Enable RQ console ONLY in development mode  */}
                  {/* <ReactQueryDevtools initialIsOpen={false} position="bottom-right" /> */}
                </QueryClientProvider>
                {/* </ConfigProvider> */}
              </ModalContextProvider>
            </JobContextProvider>
          </JoinCampaignContext>
        </PaymentContext>
      </GrowthBookProvider>
    </Provider>
  );
};

export default App;
