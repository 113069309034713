import { Button, Modal } from 'antd';
import React from 'react';
import styles from '../../../styles/newContactPage.module.scss';
import { MdClose } from 'pages/ContactPage/Icons/MdClose';

export const MailServerErrorModal = ({ imVisible, setImVisible }) => {
  const closeModal = async () => {
    setImVisible(false);
  };

  return (
    <Modal
      open={imVisible}
      className={`emmaccen`}
      onCancel={() => setImVisible(false)}
      footer={null}
      closeIcon={<MdClose />}
      destroyOnClose={true}
      centered
    >
      <div className={styles['template-modal']}>
        <div>
          <h2 className={`${styles['template-title']} mx-auto`}>Important info:</h2>
          <h2 className={styles['template-text']}>
            Dear esteemed user this is to let you know that Maildrip is currently experiencing some unexpected technical
            difficulties. As a result, we've temporarily disabled the ability to send emails and run campaigns.We
            understand this disruption may cause you inconvenience, and we sincerely apologize for any impact it might
            have on your workflows. We're working diligently to identify and resolve the issue as quickly as possible.
          </h2>
        </div>
        <Button
          size={'large'}
          htmlType="submit"
          className={`${styles['template-btn']} mt-8`}
          onClick={() => closeModal()}
        >
          Okay, close
        </Button>
      </div>
    </Modal>
  );
};
