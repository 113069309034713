import React from 'react';

export default function AdditionIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 3.33331V12.6666" stroke="#F7F7F7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3.33301 8H12.6663" stroke="#F7F7F7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
 