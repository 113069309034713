import { useEffect, useState } from 'react';
import OptInHeaderImg from '../../asset/images/LandingHeaderImg.svg';
import { MdLandingPageHeader } from './components/MdLandingPageHeader';
import styles from '../../styles/optInPage.module.scss';
import box from '../../asset/images/null.svg';
import LoadingScreen from 'components/LoadingScreen';
import useDebounce from 'hooks/useDebounce';
import { useGetLandingPagesQuery } from 'redux/featureApi/landingPageApiSlice';
import { MdLandingPageListItem } from './components/MdLandingPageListItem';
import { showToastMessage } from 'utils/Toast';

const MdLandingPage = () => {
  const [landingPages, setLandingPages] = useState([]);
  const [imvisible, setImvisible] = useState({ id: '' });
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 1000) || '';

  const [landingPagesTitle, setLandingPagesTitle] = useState([]);

  const {
    data: landingPagesList,
    isFetching: fetchingLandingPagesList,
    isSuccess: fetchedLandingPagesList,
    isError: fetchingLandingPagesListFailed,
  } = useGetLandingPagesQuery();

  const searchPage = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    if (fetchedLandingPagesList && landingPagesList) {
      setLandingPages(landingPagesList?.data?.result);
      setLandingPagesTitle(landingPagesList?.data?.result.map((page) => page.title));
    }

    if (fetchingLandingPagesListFailed) {
      showToastMessage({
        type: 'error',
        title: 'Could not fetch landing pages',
        description: 'Something went wrong, please try again later',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchingLandingPagesList, fetchedLandingPagesList, fetchingLandingPagesListFailed]);

  useEffect(() => {
    if (debouncedSearchTerm === '') {
      setImvisible({ id: '' });
      setLandingPages(landingPagesList?.data?.result);
    } else {
      setImvisible({ id: 'searchingLandingPage' });
      const searchResult = landingPagesList?.data?.result.filter((page) =>
        page.title.toLowerCase().includes(debouncedSearchTerm.toLowerCase())
      );
      setLandingPages(searchResult);
      setImvisible({ id: '' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  return (
    <div className="container landing-page-container">
      <MdLandingPageHeader
        title="Landing Pages"
        description="Create a customised Landing page"
        headerImage={OptInHeaderImg}
        imvisible={imvisible}
        setImvisible={setImvisible}
        searchPage={searchPage}
        landingPagesTitle={landingPagesTitle}
      />
      {fetchingLandingPagesList ? (
        <LoadingScreen />
      ) : (
        <>
          {landingPages?.length === 0 && debouncedSearchTerm !== '' ? (
            <div className={styles.empty_container}>
              <img src={box} alt="" />
              <p>There are no landing pages that matched your search keyword</p>
            </div>
          ) : landingPages?.length === 0 ? (
            <div className={styles.empty_container}>
              <img src={box} alt="" />
              <p>There are no landing pages yet</p>
            </div>
          ) : (
            <div data-test="created-opt-in-page" className={styles.c_container}>
              {landingPages?.length > 0 && (
                <div className={styles.new}>
                  <div className={styles.new_status}>
                    <p>New</p>
                  </div>
                  {landingPages.map((data) => {
                    return (
                      <div data-test="landing-page-item" key={data?.id}>
                        <MdLandingPageListItem data={data} styles={styles} />
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default MdLandingPage;
