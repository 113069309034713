import { apiAuthSlice } from './apiAuthSlice';

export const resourceApiSlice = apiAuthSlice.injectEndpoints({
  endpoints: (builder) => ({
    uploadMarkdownImage: builder.mutation({
      query: (file) => {
        const formdata = new FormData();

        formdata.append('file', file);

        return {
          url: `/resources/upload`,
          method: 'POST',
          body: formdata,
        };
      },
    }),
  }),
});

export const { useUploadMarkdownImageMutation } = resourceApiSlice;
