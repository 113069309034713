import React from 'react';
import { Input } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { MdSearchIcon } from './Icons/MdSearchIcon';
import { MdTypography } from 'components/global';

export const MdContactHeader = ({
  title,
  description,
  descriptionLink,
  contactHeaderImg,
  searchContact,
  imLoading,
}) => {
  const redirectToHelpCenter = () => {
    window.open('https://maildripio.notion.site/Help-Center', '_blank');
  };

  return (
    <div className="contactHeaderCont">
      <div className="contactTextPart">
        <MdTypography variant={'h2'} className="contHTitle">
          {title}
        </MdTypography>
        <div className="flex">
          <MdTypography variant={'btnLight'} className="contHDesc">
            {description}
          </MdTypography>
          <MdTypography onClick={redirectToHelpCenter} variant={'btnRegular'} className="contHDescLink ml-1 pointer">
            {descriptionLink}
          </MdTypography>
        </div>

        <div>
          <Input
            placeholder="Search contacts"
            allowClear
            className="contactSearchInput"
            prefix={
              imLoading.id === 'searchingCampaigns' ? (
                <LoadingOutlined />
              ) : (
                <MdSearchIcon color="rgba(142, 142, 142, 1)" />
              )
            }
            size="large"
            onChange={(e) => searchContact(e)}
          />
        </div>
      </div>
      <div className="contactImgPart">
        <img src={contactHeaderImg} alt="contaact header" className="object-contain contHImg" />
      </div>
    </div>
  );
};
