import Cookies from 'js-cookie';
import { apiAuthSlice } from './apiAuthSlice';

export const teamCollabApiSlice = apiAuthSlice
  .enhanceEndpoints({ addTagTypes: ['WorkspaceList', 'WorkspaceMembers', 'CampaignMembers'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      shareWorkspace: builder.mutation({
        query: (params) => ({
          url: `/organizations/workspace/share`,
          method: 'POST',
          body: { ...params },
        }),
        transformResponse: (response) => response?.data,
      }),
      getWorkspaceList: builder.query({
        query: (params) => ({
          url: `/organizations/workspace/list`,
          method: 'GET',
        }),
        transformResponse: (response) => response?.data.data,
        providesTags: ['WorkspaceList'],
      }),
      switchWorkspace: builder.mutation({
        query: (workspaceId) => ({
          url: `/organizations/workspace/login/${workspaceId}`,
          method: 'POST',
          // body: { ...params },
        }),
        transformResponse: (response, meta) => {
          if (response?.data?.token) {
            const token = response?.data?.token;
            const headers = meta.request.headers;
            Cookies.set('token', token);
            headers.set('Authorization', `Bearer ${token}`);
            headers.set('x-access-token', `${token}`);
          }
          return response?.data;
        },
        invalidatesTags: ['WorkspaceList', 'UserDetails', 'CampaignList', 'CampaignVariables'],
      }),
      getWorkspaceMembers: builder.query({
        query: (params) => ({
          url: `/organizations/workspace/members`,
          method: 'GET',
        }),
        transformResponse: (response) => response?.data,
        providesTags: ['WorkspaceMembers'],
      }),
      removeWsMember: builder.mutation({
        query: (memberId) => ({
          url: `/organizations/workspace/remove`,
          method: 'PATCH',
          body: { member: memberId },
        }),
        invalidatesTags: ['WorkspaceMembers'],
      }),
      leaveWorkspace: builder.mutation({
        query: (memberId) => ({
          url: `/organizations/workspace/leave`,
          method: 'PATCH',
        }),
        invalidatesTags: ['WorkspaceMembers', 'WorkspaceList'],
      }),
      acceptWsInvite: builder.mutation({
        query: (token) => ({
          url: `/organizations/workspace/invite/${token}`,
          method: 'POST',
          body: { status: 0 },
        }),
      }),
      updateWsMemberRole: builder.mutation({
        query: (params) => ({
          url: `/organizations/workspace/role/update`,
          method: 'PATCH',
          body: { ...params },
        }),
        invalidatesTags: ['WorkspaceMembers', 'WorkspaceList', 'UserDetails'],
      }),
      // shareCampaignAccess: builder.mutation({
      //   query: (params) => ({
      //     url: `/organizations/workspace/member/add-campaign`,
      //     method: 'POST',
      //     body: { ...params },
      //   }),
      //   transformResponse: (response) => response?.data,
      // }),
      getCampaignMembers: builder.query({
        query: (campaignId) => ({
          url: `/organizations/workspace/members/${campaignId}`,
          method: 'GET',
        }),
        transformResponse: (response) => response?.data ?? [],
        providesTags: ['CampaignMembers'],
      }),
      removeCampaignMember: builder.mutation({
        query: (params) => ({
          url: `/organizations/workspace/member/remove-campaign`,
          method: 'POST',
          body: { ...params },
        }),
        invalidatesTags: ['CampaignMembers', 'WorkspaceList'],
      }),
      uploadProfilePicture: builder.mutation({
        query: (payload) => ({
          url: `/users/upload-photo`,
          method: 'POST',
          body: payload,
        }),
        invalidatesTags: ['WorkspaceList', 'WorkspaceMembers'],
      }),
    }),
    overrideExisting: true,
  });

export const {
  useShareWorkspaceMutation,
  useGetWorkspaceListQuery,
  useSwitchWorkspaceMutation,
  useGetWorkspaceMembersQuery,
  useRemoveWsMemberMutation,
  useLeaveWorkspaceMutation,
  useAcceptWsInviteMutation,
  useUpdateWsMemberRoleMutation,
  // useShareCampaignAccessMutation,
  useGetCampaignMembersQuery,
  useRemoveCampaignMemberMutation,
  useUploadProfilePictureMutation,
} = teamCollabApiSlice;
