import React from 'react';

export const MdUserOutline = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 13 14" fill="none">
      <path
        d="M6.24351 7.48837C4.17606 7.48837 2.49341 5.80837 2.49341 3.74419C2.49341 1.68 4.17606 0 6.24351 0C8.31095 0 9.99361 1.68 9.99361 3.74419C9.99361 5.80837 8.31095 7.48837 6.24351 7.48837ZM6.24351 0.976744C4.71738 0.976744 3.4717 2.22047 3.4717 3.74419C3.4717 5.26791 4.71738 6.51163 6.24351 6.51163C7.76964 6.51163 9.01532 5.26791 9.01532 3.74419C9.01532 2.22047 7.76964 0.976744 6.24351 0.976744Z"
        fill="#8E8E8E"
      />
      <path
        d="M11.8461 13.9998C11.5787 13.9998 11.357 13.7784 11.357 13.5115C11.357 11.265 9.06127 9.4417 6.24381 9.4417C3.42634 9.4417 1.13063 11.265 1.13063 13.5115C1.13063 13.7784 0.908886 13.9998 0.641487 13.9998C0.374089 13.9998 0.152344 13.7784 0.152344 13.5115C0.152344 10.731 2.88502 8.46496 6.24381 8.46496C9.60259 8.46496 12.3353 10.731 12.3353 13.5115C12.3353 13.7784 12.1135 13.9998 11.8461 13.9998Z"
        fill="#8E8E8E"
      />
    </svg>
  );
};
