import moment from 'moment';

// takes in time in seconds - 120
const convertSecondsToNearestDur = (dur) => {
  const newdur = moment.duration(Number(dur), 'seconds');

  let int = 0;
  let unit = 'years';
  int = newdur.asYears();

  const updateState = (time, duration) => {
    int = time;
    unit = duration;
  };

  if (int < 1) updateState(newdur.asMonths(), 'months');
  if (int < 1) updateState(newdur.asWeeks(), 'weeks');
  if (int < 1) updateState(newdur.asDays(), 'days');
  if (int < 1) updateState(newdur.asHours(), 'hours');
  if (int < 1) updateState(newdur.asMinutes(), 'minutes');
  if (int < 1) throw Error('Invalid campaign interval');

  int = Math.round(int);

  return { int, unit };
};

export default convertSecondsToNearestDur;
