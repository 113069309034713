import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

const userDetails = JSON.parse(localStorage.getItem('userDetails'));
const workspace = JSON.parse(localStorage.getItem('workspaceDetails'));

const initialState = {
  user: userDetails?.user ?? {},
  emailSettings: userDetails?.emailSettings ?? {},
  accessLevel: userDetails?.activeWorkSpace ?? {},
  workspace: workspace ?? [],
};
export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUserDetails: (state, action) => {
      const { payload } = action;
      state.user = payload.user;
      state.emailSettings = payload.emailSettings;
      state.accessLevel = payload.activeWorkSpace;
    },
    setUserPhoneNumber: (state, action) => {
      const { payload } = action;
      const newUserState = { ...state.user };
      newUserState.phoneNumber = payload.phoneNumber;
      state.user = { ...newUserState };
      state.emailSettings = { ...state.emailSettings };
      state.accessLevel = { ...state.accessLevel };
    },
    logOutUser: (state, action) => {
      // clear the cookie or LS & Cookie
      Cookies.remove('token');
      localStorage.removeItem('userDetails');
      localStorage.removeItem('workspaceDetails');

      const existingJobs = []; // refactored because it was causing the test to fail
      if (localStorage.getItem('jobs')) {
        existingJobs.push(...JSON.parse(localStorage.getItem('jobs')));
      }
      localStorage.setItem('previousJobs', JSON.stringify(existingJobs)); // Store it directly
      localStorage.removeItem('jobs');

      state.user = {};
      state.emailSettings = {};
      state.accessLevel = {};
      state.workspace = [];
    },
    setWorkspaceDetails: (state, action) => {
      const { payload } = action;
      localStorage.setItem('workspaceDetails', JSON.stringify(payload));
      state.workspace = payload;
    },
  },
});

export const { setUserDetails, logOutUser, setWorkspaceDetails, setUserPhoneNumber } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state) => state.auth.user;
export const selectUserAccessLevel = (state) => state.auth.accessLevel;
export const selectWorkpaceList = (state) => state.auth.workspace;
