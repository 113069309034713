import React from 'react';

export const FacebookIcon = () => {
    return(
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z" fill="#3C5A99"/>
<path d="M10.7773 15V10.4453H12.3125L12.543 8.66211H10.7773V7.52734C10.7773 7.01172 10.9199 6.66211 11.6582 6.66211H12.5937V5.07227C12.4316 5.05078 11.873 5.00195 11.2227 5.00195C9.86523 5.00195 8.93555 5.83008 8.93555 7.35156V8.66406H7.40625V10.4473H8.93555V15H10.7773Z" fill="white"/>
</svg>
    )
}
