export function thousandFormatter(number) {
  // Does not support floating ints
  return String(number).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function thousandFormatterFloat(number) {
  const parts = parseFloat(number).toFixed(2).split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
}
