export const CautionIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
      <path
        d="M8 10.3333C7.72667 10.3333 7.5 10.1067 7.5 9.83333V6.5C7.5 6.22667 7.72667 6 8 6C8.27333 6 8.5 6.22667 8.5 6.5V9.83333C8.5 10.1067 8.27333 10.3333 8 10.3333Z"
        fill="#DB2222"
      />
      <path
        d="M7.99992 12.5C7.95992 12.5 7.91325 12.4933 7.86659 12.4866C7.82659 12.48 7.78659 12.4666 7.74659 12.4466C7.70659 12.4333 7.66659 12.4133 7.62659 12.3866C7.59325 12.36 7.55992 12.3333 7.52659 12.3066C7.40659 12.18 7.33325 12.0066 7.33325 11.8333C7.33325 11.66 7.40659 11.4866 7.52659 11.36C7.55992 11.3333 7.59325 11.3066 7.62659 11.28C7.66659 11.2533 7.70659 11.2333 7.74659 11.22C7.78659 11.2 7.82659 11.1866 7.86659 11.18C7.95325 11.16 8.04659 11.16 8.12659 11.18C8.17325 11.1866 8.21325 11.2 8.25325 11.22C8.29325 11.2333 8.33325 11.2533 8.37325 11.28C8.40659 11.3066 8.43992 11.3333 8.47325 11.36C8.59325 11.4866 8.66659 11.66 8.66659 11.8333C8.66659 12.0066 8.59325 12.18 8.47325 12.3066C8.43992 12.3333 8.40659 12.36 8.37325 12.3866C8.33325 12.4133 8.29325 12.4333 8.25325 12.4466C8.21325 12.4666 8.17325 12.48 8.12659 12.4866C8.08659 12.4933 8.03992 12.5 7.99992 12.5Z"
        fill="#DB2222"
      />
      <path
        d="M12.0402 15.2733H3.96023C2.66023 15.2733 1.66689 14.8 1.16023 13.9467C0.660228 13.0933 0.726895 11.9933 1.36023 10.8533L5.40023 3.58665C6.06689 2.38665 6.98689 1.72665 8.00023 1.72665C9.01356 1.72665 9.93356 2.38665 10.6002 3.58665L14.6402 10.86C15.2736 12 15.3469 13.0933 14.8402 13.9533C14.3336 14.8 13.3402 15.2733 12.0402 15.2733ZM8.00023 2.72665C7.37356 2.72665 6.76023 3.20665 6.27356 4.07332L2.24023 11.3467C1.78689 12.16 1.71356 12.9067 2.02689 13.4467C2.34023 13.9867 3.03356 14.28 3.96689 14.28H12.0469C12.9802 14.28 13.6669 13.9867 13.9869 13.4467C14.3069 12.9067 14.2269 12.1667 13.7736 11.3467L9.72689 4.07332C9.24023 3.20665 8.62689 2.72665 8.00023 2.72665Z"
        fill="#DB2222"
      />
    </svg>
  );
};
