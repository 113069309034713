export const EmptyStateHourGlass = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="235" height="235" viewBox="0 0 235 235" fill="none">
        <mask
          id="mask0_8679_51048"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="34"
          y="9"
          width="167"
          height="217"
        >
          <path d="M200.24 9.30176H34.7607V225.698H200.24V9.30176Z" fill="white" />
        </mask>
        <g mask="url(#mask0_8679_51048)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M112.721 73.9565C126.448 65.1016 138.548 53.5338 147.255 39.7256C147.706 39.0122 148.148 38.2928 148.578 37.567C149.827 35.4662 151.023 33.2442 151.262 30.8151C151.638 26.9974 149.67 23.6235 147.679 20.2108C146.15 17.5902 144.608 14.9467 144.104 12.0615C143.984 18.2503 128.816 36.3438 107.725 55.0302C85.6302 74.6084 64.9632 87.811 59.9004 85.7902C61.6071 88.7196 63.1617 91.1247 65.3742 93.7134C66.4491 93.4519 67.522 93.1802 68.5932 92.8987C84.1453 88.8107 99.2115 82.6664 112.721 73.9565ZM144.89 20.1896C144.764 20.0313 144.532 20.0057 144.374 20.1325C144.216 20.2592 144.19 20.4903 144.317 20.6486C146.545 23.4314 148.224 26.6118 149.266 30.021C149.325 30.2152 149.531 30.3243 149.724 30.2651C149.918 30.2059 150.028 30.0005 149.968 29.8066C148.9 26.3083 147.176 23.0449 144.89 20.1896Z"
            fill="#171717"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M113.418 190.534C132.927 190.27 152.478 191.593 171.707 194.859C170.967 181.295 168.271 170.143 164.538 161.002L163.926 161.176C155.941 163.927 148.26 167.797 141.837 173.28C140.935 174.053 140.058 174.855 139.188 175.669L138.795 175.246C132.876 176.046 126.851 175.204 121.379 172.812C119.965 172.191 118.597 171.471 117.23 170.752C115.446 169.812 113.662 168.873 111.779 168.157C108.453 166.894 105.109 166.391 101.902 167.931L101.89 167.928C99.0497 177.914 96.922 187.267 95.6016 193.548C96.9783 192.886 98.4127 192.352 99.8869 191.951C104.275 190.751 108.871 190.596 113.418 190.534ZM165.226 168.583C165.168 168.389 164.964 168.278 164.769 168.336C164.575 168.394 164.464 168.598 164.522 168.792C166.703 176.115 167.792 183.719 167.756 191.36C167.755 191.562 167.919 191.727 168.122 191.728C168.325 191.729 168.49 191.566 168.491 191.363C168.527 183.651 167.427 175.975 165.226 168.583Z"
            fill="url(#paint0_linear_8679_51048)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M84.7852 107.616C95.0576 115.281 109.12 122.891 123.68 121.96C123.665 121.973 123.648 121.988 123.633 122.001C124.583 122.188 125.563 122.168 126.505 121.942L126.67 121.959C137.077 110.834 142.705 97.3452 145.63 84.534L145.047 84.4043C142.419 93.0374 135.905 100.151 128.1 104.551C120.294 108.951 111.28 110.812 102.348 111.118C98.6729 111.243 94.933 111.076 91.3603 110.139C90.1818 109.831 86.8556 108.871 84.8921 107.471L84.7852 107.616ZM138.537 100.18C138.608 99.9899 138.51 99.7789 138.32 99.7087C138.13 99.6387 137.919 99.7358 137.848 99.9261C135.668 105.838 132.493 111.333 128.462 116.176C128.332 116.331 128.353 116.563 128.509 116.693C128.665 116.822 128.896 116.801 129.026 116.645C133.111 111.739 136.328 106.17 138.537 100.18Z"
            fill="url(#paint1_linear_8679_51048)"
          />
          <path
            d="M171.844 198.757C171.742 195.909 154.267 194.22 132.812 194.985C111.754 195.734 94.6894 198.572 94.1612 201.368L94.0566 201.348C94.1737 200.697 94.6992 197.84 95.6013 193.549C96.978 192.887 98.4125 192.353 99.8866 191.952C104.274 190.751 108.87 190.597 113.418 190.535C132.927 190.271 152.478 191.593 171.707 194.86C171.779 196.146 171.832 197.453 171.864 198.782H171.844V198.757Z"
            fill="url(#paint2_linear_8679_51048)"
          />
          <path
            d="M133.181 205.296C111.724 206.061 94.2511 204.372 94.149 201.524C94.1468 201.471 94.151 201.419 94.1615 201.367C94.6898 198.572 111.754 195.734 132.812 194.984C154.267 194.219 171.742 195.908 171.844 198.756V198.781C171.845 201.619 154.573 204.534 133.181 205.296Z"
            fill="url(#paint3_linear_8679_51048)"
          />
          <path
            d="M94.1484 201.524C94.2505 204.372 111.724 206.061 133.18 205.296C154.573 204.534 171.844 201.618 171.844 198.781H171.864C171.831 197.45 171.779 196.143 171.707 194.859C173.409 195.145 175.11 195.449 176.81 195.771C177.702 195.938 178.656 196.145 179.266 196.817C179.429 196.997 179.559 197.205 179.65 197.429L179.705 197.405C181.342 201.077 182.777 206.025 182.094 209.15C181.382 212.409 180.846 215.865 178.581 218.314C175.432 221.716 170.544 222.773 165.957 223.458C144.116 226.718 121.842 225.529 100.472 219.963C95.4094 218.641 89.9182 216.689 87.4118 212.095C85.2317 208.105 86.0165 202.918 88.6524 199.211C90.4066 196.742 92.8606 194.873 95.6013 193.548C94.6992 197.838 94.1737 200.696 94.0566 201.346L94.1612 201.366C94.1506 201.418 94.1465 201.471 94.1484 201.524Z"
            fill="#171717"
          />
          <path
            d="M59.9008 85.7907C59.6362 85.6892 59.399 85.5271 59.208 85.3176C55.7276 81.3884 71.8028 61.4563 95.119 40.8015C118.435 20.1467 140.152 6.58476 143.632 10.5146C143.982 10.9509 144.151 11.5049 144.103 12.062C143.984 18.2508 128.815 36.3453 107.725 55.0307C85.6299 74.6089 64.9628 87.8115 59.9008 85.7907Z"
            stroke="#171717"
            strokeWidth="0.734375"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M59.8271 85.6689C59.8516 85.7113 59.8744 85.7512 59.9006 85.7913C61.6073 88.7215 63.1619 91.1258 65.3744 93.7145"
            stroke="#171717"
            strokeWidth="0.734375"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M65.374 93.714C66.4479 93.4531 67.5209 93.1814 68.593 92.8994C84.1451 88.8114 99.2113 82.6671 112.72 73.9562C126.449 65.1013 138.547 53.5334 147.255 39.7252C147.706 39.0129 148.147 38.2937 148.577 37.5676C149.825 35.4673 151.021 33.2449 151.26 30.8158C151.926 24.0662 145.263 18.7046 144.102 12.0621C144.057 11.8029 144.02 11.5415 143.993 11.2773"
            stroke="#171717"
            strokeWidth="0.734375"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M123.681 121.96C123.666 121.972 123.648 121.987 123.633 122.002C114.447 129.855 107.012 149.922 101.89 167.928C99.0502 177.914 96.9205 187.267 95.6013 193.549C94.6993 197.839 94.1737 200.696 94.0567 201.347C94.0391 201.442 94.0322 201.489 94.0322 201.489"
            stroke="#171717"
            strokeWidth="0.734375"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M147.256 39.7197V39.7246C147.323 40.1036 150.968 61.1892 145.631 84.5347C142.706 97.3459 137.078 110.835 126.671 121.96"
            stroke="#171717"
            strokeWidth="0.734375"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M68.5928 92.8984C68.5928 92.8984 74.9537 100.283 84.7852 107.616C95.0577 115.279 109.12 122.889 123.68 121.961"
            stroke="#171717"
            strokeWidth="0.734375"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M126.671 121.96C126.671 121.96 151.718 129.597 164.54 161.002C168.273 170.143 170.968 181.295 171.708 194.859C171.78 196.145 171.833 197.452 171.865 198.781C171.868 198.828 171.87 198.875 171.87 198.923"
            stroke="#171717"
            strokeWidth="0.734375"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M136.937 108.269C141.176 105.244 145.888 102.947 150.882 101.47"
            stroke="#171717"
            strokeWidth="0.734375"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M151.688 97.9815C152.565 93.3253 154.766 89.0209 158.028 85.5837C158.349 88.8321 157.642 92.0991 156.007 94.9243C160.706 89.4598 167.138 85.7737 174.227 84.4824C171.332 88.5264 167.417 91.7312 162.881 93.7708C165.46 92.7268 168.061 91.6729 170.799 91.176C173.537 90.6791 176.46 90.775 178.975 91.9611C174.73 94.456 169.991 95.9942 165.09 96.4682C169.46 96.1306 173.823 97.2094 177.533 99.5452C178.108 99.9089 178.736 100.579 178.389 101.164C178.143 101.583 177.56 101.618 177.074 101.605L152.772 100.91L153.4 100.962"
            stroke="#171717"
            strokeWidth="0.734375"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M86.6338 123.776C91.2941 121.495 96.3415 120.109 101.513 119.688"
            stroke="#171717"
            strokeWidth="0.734375"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M83.3898 122.834C79.9153 121.64 76.1308 121.708 72.7012 123.026C71.2538 123.587 69.7148 124.643 69.6658 126.196C71.5519 126.23 73.4381 126.265 75.3242 126.298C71.1414 130.129 67.5746 134.582 64.7492 139.5C64.5274 139.884 64.3281 140.412 64.642 140.721C64.9832 141.062 65.5563 140.781 65.9425 140.494C68.7679 138.399 71.594 136.305 74.4211 134.211C71.4411 137.423 69.3209 141.337 68.2571 145.587C68.1202 146.14 68.14 146.933 68.7006 147.04C69.0844 147.114 69.4105 146.773 69.6524 146.464C71.7387 143.779 73.6567 140.968 75.3954 138.045C73.8182 141.304 72.2131 144.67 71.9945 148.285C71.9644 148.759 72.0792 149.384 72.5475 149.451C72.9786 149.514 73.2701 149.035 73.4395 148.634L83.0824 125.59"
            stroke="#171717"
            strokeWidth="0.734375"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M84.8925 107.472C86.856 108.872 90.1822 109.832 91.3606 110.14C94.9334 111.077 98.6733 111.244 102.348 111.119C111.28 110.813 120.295 108.952 128.1 104.552C135.906 100.152 142.419 93.0383 145.048 84.4053C142.616 86.2167 140.185 88.0297 137.755 89.8443C131.529 94.4895 124.946 99.2849 117.28 100.341C111.674 101.114 106.02 99.7983 100.378 99.3647C94.7353 98.9312 88.5404 99.5865 84.412 103.498C83.9863 103.853 83.6592 104.312 83.4627 104.831C83.1635 105.818 83.8262 106.71 84.8925 107.472Z"
            stroke="#171717"
            strokeWidth="0.734375"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M101.902 167.931C105.109 166.391 108.453 166.894 111.779 168.157C115.105 169.421 118.119 171.382 121.379 172.812C126.851 175.204 132.876 176.046 138.794 175.246"
            stroke="#171717"
            strokeWidth="0.734375"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M115.102 185.576C119.83 187.801 125.571 186.316 129.971 183.498C133.372 181.32 136.23 178.433 139.187 175.67C140.057 174.855 140.933 174.053 141.836 173.28C148.259 167.797 155.94 163.927 163.925 161.177"
            stroke="#171717"
            strokeWidth="0.734375"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M126.232 124.701C125.844 127.327 126.02 130.004 126.746 132.557"
            stroke="#171717"
            strokeWidth="0.734375"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M125.04 133.615C124.988 137.485 124.935 141.354 124.881 145.223"
            stroke="#171717"
            strokeWidth="0.734375"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M128.584 139.834C129.082 143.663 129.581 147.494 130.079 151.327"
            stroke="#171717"
            strokeWidth="0.734375"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M126.102 153.896C125.788 157.337 125.474 160.776 125.16 164.214"
            stroke="#171717"
            strokeWidth="0.734375"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M123.623 122C123.625 122 123.631 122.002 123.633 122.002C124.584 122.189 125.564 122.169 126.506 121.942"
            stroke="#171717"
            strokeWidth="0.734375"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M94.1615 201.367C94.6898 198.572 111.754 195.734 132.812 194.984C154.267 194.219 171.742 195.908 171.844 198.756V198.781C171.844 201.618 154.573 204.533 133.181 205.296C111.724 206.061 94.2511 204.372 94.149 201.524C94.1468 201.471 94.151 201.419 94.1615 201.367Z"
            stroke="#171717"
            strokeWidth="0.734375"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M174.837 202.483C176.247 202.127 177.727 201.733 178.778 200.729C179.648 199.895 180.084 198.499 179.65 197.43C179.559 197.206 179.429 196.998 179.266 196.818C178.656 196.146 177.702 195.939 176.81 195.772C175.11 195.453 173.41 195.149 171.707 194.86C152.478 191.593 132.927 190.271 113.418 190.535C108.871 190.597 104.274 190.752 99.8866 191.953C98.4122 192.353 96.978 192.887 95.6013 193.549C92.8596 194.875 90.4066 196.744 88.6524 199.213C86.0165 202.92 85.2317 208.105 87.4118 212.096C89.9182 216.69 95.4094 218.641 100.472 219.965C121.842 225.53 144.116 226.719 165.957 223.46C170.544 222.775 175.432 221.719 178.581 218.315C180.846 215.866 181.382 212.41 182.094 209.152C182.777 206.027 181.342 201.079 179.705 197.406C179.687 197.364 179.667 197.322 179.65 197.282"
            stroke="#171717"
            strokeWidth="0.734375"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M49.534 101.706C52.1533 110.84 54.7576 120.709 51.5369 129.648C50.7587 131.808 49.5864 133.925 47.7025 135.237C45.8186 136.549 43.1036 136.857 41.2581 135.491C39.4126 134.125 39.0951 130.93 40.9506 129.578C43.3006 127.864 46.857 130.458 46.9884 133.363C47.1199 136.269 45.0477 138.803 42.8186 140.671C40.5896 142.54 38.0207 144.139 36.4891 146.611C35.1948 148.7 34.7966 151.439 35.8771 153.646C36.9576 155.853 39.6851 157.256 42.0086 156.456C44.3322 155.656 45.5718 152.445 44.0947 150.482"
            stroke="#171717"
            strokeWidth="0.734375"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M172.144 119.648C171.082 124.862 170.032 130.358 171.679 135.418C173.326 140.478 178.694 144.747 183.792 143.222C186.162 142.512 188.272 139.804 187.048 137.656C185.8 135.468 181.996 136.211 181.032 138.539C180.068 140.866 181.394 143.628 183.41 145.138C185.425 146.648 187.972 147.211 190.423 147.791C192.873 148.372 195.414 149.061 197.287 150.746C200.406 153.553 200.673 158.893 197.986 162.116C196.584 163.797 194.376 164.927 192.214 164.582C190.053 164.237 188.179 162.134 188.507 159.971"
            stroke="#171717"
            strokeWidth="0.734375"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M57.796 58.1692L54.6348 56.0518"
            stroke="#171717"
            strokeWidth="0.734375"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M54.874 58.9264C54.9443 58.1915 55.1717 57.4801 55.5408 56.8407C55.91 56.2016 56.4123 55.6488 57.0138 55.2207"
            stroke="#171717"
            strokeWidth="0.734375"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M44.8891 121.189L42.5146 119.078"
            stroke="#171717"
            strokeWidth="0.734375"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M42.1582 121.927L44.0649 118.531"
            stroke="#171717"
            strokeWidth="0.734375"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M189.443 132.797C189.398 132.302 189.231 131.826 188.958 131.41C188.685 130.994 188.314 130.652 187.878 130.413"
            stroke="#171717"
            strokeWidth="0.734375"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M187.346 132.796L189.984 130.686"
            stroke="#171717"
            strokeWidth="0.734375"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M186.026 176.323L184.18 173.421"
            stroke="#171717"
            strokeWidth="0.734375"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M183.124 176.324L186.026 173.949"
            stroke="#171717"
            strokeWidth="0.734375"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M80.8206 111.583C76.288 109.337 72.4494 105.904 69.7139 101.649"
            stroke="#171717"
            strokeWidth="0.734375"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M152.815 76.0833C154.58 66.308 154.601 56.298 152.878 46.5156"
            stroke="#171717"
            strokeWidth="0.734375"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M163.374 38.0978L165.773 27.7539"
            stroke="#171717"
            strokeWidth="0.734375"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M167.052 49.4555L192.05 28.2754"
            stroke="#171717"
            strokeWidth="0.734375"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M169.682 57.5596L179.431 59.7221"
            stroke="#171717"
            strokeWidth="0.734375"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M92.9023 178.698C93.5099 174.871 94.8536 171.198 96.8594 167.882"
            stroke="#171717"
            strokeWidth="0.734375"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M66.5105 46.2767L67.8251 47.9168C67.8647 47.9665 67.8882 48.0267 67.8926 48.0901C67.8968 48.1535 67.8816 48.2164 67.8488 48.2707C67.8163 48.3253 67.7678 48.3682 67.7098 48.3941C67.652 48.4201 67.5874 48.4276 67.5252 48.4159L65.4601 48.0242C65.3997 48.0127 65.3373 48.0193 65.2805 48.0433C65.2239 48.0673 65.1757 48.1075 65.1419 48.1589L63.9882 49.916C63.9535 49.9691 63.903 50.0102 63.8443 50.0337C63.7853 50.0575 63.7207 50.0624 63.6587 50.0482C63.5968 50.034 63.541 50.0009 63.4984 49.9539C63.4558 49.9067 63.4289 49.8477 63.4208 49.7848L63.1569 47.6999C63.1491 47.6392 63.1236 47.5819 63.0835 47.5356C63.0431 47.4894 62.9902 47.4558 62.931 47.4397L60.9036 46.8855C60.8424 46.8688 60.7876 46.8338 60.7469 46.7851C60.7063 46.7364 60.6816 46.6764 60.6759 46.6132C60.6705 46.5501 60.6845 46.4867 60.7161 46.4316C60.7477 46.3765 60.7954 46.3327 60.8529 46.3055L62.7537 45.4084C62.8091 45.3822 62.8556 45.3401 62.8871 45.2877C62.9187 45.2351 62.9341 45.1744 62.9312 45.1129L62.8318 43.0136C62.8286 42.9502 62.845 42.8875 62.8788 42.8337C62.9126 42.7801 62.962 42.7379 63.0203 42.713C63.0788 42.6883 63.1434 42.6821 63.2056 42.6951C63.2675 42.7081 63.3241 42.7399 63.3677 42.7862L64.8083 44.3166C64.8504 44.3614 64.9047 44.3925 64.9644 44.4064C65.0242 44.4202 65.0866 44.416 65.1441 44.3942L67.11 43.6508C67.1695 43.6283 67.2344 43.6246 67.2961 43.64C67.3578 43.6557 67.4131 43.6897 67.4549 43.7377C67.4966 43.7859 67.5225 43.8456 67.5291 43.9088C67.5357 43.9719 67.5228 44.0358 67.4922 44.0914L66.4817 45.9344C66.4523 45.988 66.4393 46.049 66.4444 46.11C66.4496 46.1709 66.4726 46.2289 66.5105 46.2767Z"
            fill="#171717"
          />
          <path
            d="M164.434 132.494L165.298 133.573C165.324 133.605 165.34 133.645 165.343 133.687C165.346 133.728 165.336 133.77 165.314 133.806C165.293 133.842 165.261 133.87 165.223 133.887C165.184 133.904 165.142 133.909 165.101 133.901L163.743 133.643C163.704 133.636 163.663 133.64 163.626 133.656C163.588 133.672 163.557 133.698 163.534 133.732L162.776 134.887C162.753 134.922 162.72 134.949 162.681 134.964C162.642 134.98 162.6 134.983 162.559 134.974C162.519 134.964 162.482 134.943 162.454 134.912C162.426 134.881 162.408 134.842 162.403 134.801L162.228 133.43C162.223 133.39 162.206 133.353 162.18 133.322C162.153 133.292 162.118 133.27 162.079 133.259L160.746 132.894C160.706 132.883 160.67 132.86 160.643 132.828C160.616 132.796 160.6 132.757 160.596 132.715C160.593 132.674 160.602 132.632 160.623 132.596C160.644 132.56 160.675 132.531 160.713 132.513L161.962 131.923C161.999 131.906 162.03 131.878 162.05 131.844C162.071 131.809 162.081 131.769 162.079 131.729L162.014 130.348C162.012 130.307 162.023 130.265 162.045 130.23C162.067 130.195 162.1 130.167 162.138 130.151C162.176 130.135 162.219 130.131 162.26 130.139C162.3 130.148 162.338 130.168 162.366 130.199L163.313 131.205C163.341 131.235 163.377 131.255 163.416 131.264C163.455 131.273 163.496 131.271 163.534 131.256L164.826 130.767C164.866 130.752 164.908 130.75 164.949 130.76C164.989 130.77 165.026 130.792 165.053 130.824C165.08 130.855 165.097 130.895 165.102 130.936C165.106 130.978 165.098 131.019 165.078 131.056L164.413 132.268C164.394 132.303 164.386 132.344 164.389 132.384C164.393 132.424 164.408 132.463 164.434 132.494Z"
            fill="#171717"
          />
          <g opacity="0.1">
            <path
              d="M123.892 121.457C122.743 122.114 122.029 123.097 121.184 124.096C120.262 125.227 119.405 126.41 118.617 127.639C117.155 129.838 115.781 132.092 114.495 134.402C111.905 139.073 109.615 143.904 107.64 148.867C103.401 159.427 100.412 170.38 98.0192 181.49C97.3228 184.723 96.6684 187.966 96.0347 191.213C95.7326 192.759 95.4347 194.306 95.1407 195.853C94.8136 197.567 94.2457 199.4 94.4043 201.148C94.5852 203.144 97.4802 204.432 98.5526 202.272C99.2214 200.924 99.416 199.551 99.6889 198.084C99.9626 196.613 100.239 195.141 100.518 193.671C101.061 190.807 101.624 187.946 102.205 185.09C103.361 179.461 104.629 173.854 106.117 168.304C108.88 158.001 112.223 147.93 117.165 138.442C118.517 135.846 119.974 133.309 121.536 130.831C122.3 129.624 123.13 128.464 123.905 127.266C124.739 125.978 125.729 124.663 126.134 123.18C126.207 122.889 126.193 122.583 126.094 122.299C125.996 122.016 125.816 121.767 125.578 121.584C125.34 121.401 125.054 121.292 124.754 121.269C124.455 121.246 124.155 121.312 123.892 121.457H123.892Z"
              fill="#171717"
            />
          </g>
          <g opacity="0.1">
            <path
              d="M69.8479 93.5443C70.7399 95.4841 72.1759 97.0165 73.6218 98.5587C75.0416 100.073 76.528 101.54 78.0433 102.957C81.1085 105.825 84.6309 108.315 88.1659 110.563C95.2644 115.071 102.989 118.506 111.091 120.759C115.68 122.018 120.367 122.889 125.103 123.363C126.331 123.489 127.386 122.223 127.386 121.079C127.386 119.739 126.335 118.923 125.103 118.796C109.364 117.177 94.2791 110.6 82.0926 100.559C80.3791 99.1471 78.6834 97.7437 77.0617 96.2231C75.3464 94.6158 73.8005 92.8695 71.8256 91.5676C70.5064 90.6979 69.2964 92.3476 69.8474 93.5456L69.8479 93.5443Z"
              fill="#171717"
            />
          </g>
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_8679_51048"
            x1="95.6016"
            y1="177.931"
            x2="171.707"
            y2="177.931"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#9796F0" />
            <stop offset="1" stopColor="#FBC7D4" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_8679_51048"
            x1="84.7852"
            y1="103.266"
            x2="145.63"
            y2="103.266"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#9796F0" />
            <stop offset="1" stopColor="#FBC7D4" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_8679_51048"
            x1="94.0566"
            y1="195.935"
            x2="171.864"
            y2="195.935"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#9796F0" />
            <stop offset="1" stopColor="#FBC7D4" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_8679_51048"
            x1="94.1484"
            y1="200.14"
            x2="171.844"
            y2="200.14"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#9796F0" />
            <stop offset="1" stopColor="#FBC7D4" />
          </linearGradient>
        </defs>
      </svg>
    </>
  );
};
