import loadComponents from './components';
import loadBlocks from './blocks';

const options = (editor, opts = {}) => {
  const options = {
    ...{
      // Label of the custom code block
      blockLabel: 'Insert Tag',
      blockCustomCode: {},
      ...opts,
    },
  };

  loadBlocks(editor, options);
  loadComponents(editor, options);
};
export default options;
