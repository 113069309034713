import { EyeOutlined, LoadingOutlined, PaperClipOutlined, WarningOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { onFileDropHandler } from 'components/emmaccen/shared-functions/onFileDropHandler';
import { onSystemFileSelected } from 'components/emmaccen/shared-functions/onSystemFileSelected';
import { useEffect, useRef, useState } from 'react';
import { uploadFile } from 'utils/service';

const LoadAttachment = ({ open, onOk, onCancel, files = [], setFiles, mailId, deleteFile, imLoading }) => {
  const [isUploading, setIsUploading] = useState(false);
  const fileInputRef = useRef(null);

  const updateFileState = (id, status, location, filename, createdAt, oldId) => {
    setFiles((previousFiles) =>
      previousFiles.map((file) =>
        file.id === oldId ? { ...file, id, status, src: location, filename, createdAt } : file
      )
    );
  };

  // useEffect(() => {
  //   updateMailDetails((prev) => ({
  //     ...prev,
  //     attachments: files?.filter((file) => file.status !== 'ERROR').map((file) => file),
  //   }));
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [files]);

  useEffect(() => {
    isUploading &&
      files.forEach((file) => {
        file.status !== 'UPLOADED' &&
          uploadFile(file.file, file.id, mailId)
            .then((data) => {
              const { id, status, location, filename, createdAt } = data.data.data;
              updateFileState(id, status, location, filename, createdAt, file.id);
            })
            .catch((err) => {
              updateFileState(file.id, 'ERROR', file.src, file.filename);
            })
            .finally(() => {
              setIsUploading(false);
            });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUploading]);

  const onDragHandler = (ev) => {
    ev.preventDefault();
  };

  const LoadFilesUi = () =>
    files?.map((fileData, index) => {
      const { filename, id } = fileData;
      return (
        <div
          className={fileData.status + ' flex justify-between items-center h-10 w-full rounded borderShade1 p-6'}
          key={index}
        >
          <div title={filename} className="flex">
            <PaperClipOutlined className="text-xl" />
            <p className="mx-3">{filename.slice(0, 15).concat('...')}</p>
            {fileData?.status === 'WAITING' ? (
              <div className="flex items-center">
                <LoadingOutlined />
              </div>
            ) : (
              fileData?.status === 'ERROR' && (
                <div className="flex items-center">
                  <WarningOutlined className="text-xl" />
                </div>
              )
            )}
          </div>
          <div className="flex items-center">
            {fileData?.status === 'ERROR' && <p className="mr-3 pointer underline">Retry</p>}
            {<EyeOutlined className="text-xl mr-3" onClick={() => window.open(fileData.src)} />}
            <Button loading={imLoading?.id === id} onClick={() => deleteFile(fileData)} danger size="small">
              Remove
            </Button>
          </div>
        </div>
      );
    });

  return (
    <Modal className="emmaccen" open={open} onOk={onOk} onCancel={onCancel} footer={null} centered>
      <div className="p-5 attachFileModal">
        <h1 className="text-center title">Attach Files</h1>
        <label
          htmlFor="fileUpload"
          title="Drag and Drop file(s) to be attached to this email"
          onDrop={(e) => onFileDropHandler(e, files, setFiles, setIsUploading)}
          onDragOver={onDragHandler}
          className="block h-32 w-full my-5 cursor-pointer fileUpload"
        >
          <input
            type="file"
            onChange={(e) => onSystemFileSelected(e, files, setFiles, setIsUploading, fileInputRef)}
            className=""
            id="fileUpload"
            multiple={true}
            ref={fileInputRef}
          ></input>
          <div className="centered h-full">
            <p className="details">
              Attach files by dragging & dropping or <span className="primaryColor">selecting them</span>.
            </p>
          </div>
        </label>
      </div>
      <div className="flex flex-wrap my-5">{LoadFilesUi()}</div>
      <div className="flex justify-end">
        <Button onClick={onOk} type="primary">
          Done
        </Button>
      </div>
    </Modal>
  );
};

export default LoadAttachment;
