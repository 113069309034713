import React, { useContext, forwardRef } from 'react';
import { Form, Input } from 'antd';
import { joinCampaignContext } from '../../contexts/joincampaign';

const ConfirmationPage = forwardRef(function ConfirmationPage(props, ref) {
  const { state } = useContext(joinCampaignContext);
  const { setLink } = state;

  return (
    <div>
      <div className="confirmation-page ">
        <>
          <Form className="flex flex-col">
            <Form.Item className="input-label" label="Redirect URL"></Form.Item>
            <div>
              <Input
                type="url"
                defaultValue={setLink ? setLink : 'https://'}
                ref={ref}
                placeholder="https://www.example.com"
                style={{ height: '35px' }}
                data-test="confirmation-link-input"
              />
            </div>
          </Form>
        </>
      </div>
      <p className="text-base text-black p-5 pb-10">
        Use this section to display custom links after your audience have successfully subscribed. For example, you
        might link to a custom thank you page on your website.
      </p>
    </div>
  );
});

export default ConfirmationPage;
