import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import useNoAuth from '../../hooks/useNoAuth';
// import LoadingScreen from 'components/LoadingScreen';

const NonProtectedRoute = () => {
  const [isLoading, setIsLoading] = useState(true);
  const noAuth = useNoAuth(() => setIsLoading(false));

  return (
    !isLoading &&
    !noAuth && (
      <>
        <Outlet />
      </>
    )
  );
};

export default NonProtectedRoute;
