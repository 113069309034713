import { Table } from 'antd';
import React from 'react';
import moment from 'moment';

import { thousandFormatterFloat } from '../../utils/thousandFormatter';

export default function TransactionsTable({ loading, transactions }) {
  const currencies = {
    USD: '$',
    NG: '₦',
  };

  const columns = [
    {
      title: 'DATE',
      dataIndex: 'createdAt',
      render: (createdAt) => moment(createdAt).format('YYYY/M/DD'),
    },
    {
      title: 'PLAN TYPE',
      dataIndex: 'planType',
    },
    {
      title: 'AMOUNT',
      render: (record) => (
        <div>
          {record.currency ? currencies[record.currency] : '₦'}
          {thousandFormatterFloat(record.amount)}
        </div>
      ),
    },
    {
      title: 'UNITS',
      dataIndex: 'units',
    },
    {
      title: 'EXPIRES',
      dataIndex: 'expiresAt',
      render: (expiresAt) => <div>{Boolean(expiresAt) ? moment(expiresAt).format('YYYY/MM/DD') : '--/--/----'}</div>,
    },
    // {
    //   title: '',
    //   dataIndex: 'receiptUrl',
    //   render: (receiptUrl) => (
    //     <a href={receiptUrl} download>
    //       <DownloadIcon />
    //     </a>
    //   ),
    // },
  ];

  return (
    <Table
      scroll={{ x: '900px' }}
      loading={loading}
      style={{ height: '100%' }}
      rowKey={'_id'}
      columns={columns}
      dataSource={transactions}
      pagination={false}
    />
  );
}
