import { EditFilled } from '@ant-design/icons';
import { Button, Form, Input,Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { useUploadProfilePictureMutation } from 'redux/featureApi/teamCollabApiSlice';
import { showToastMessage } from 'utils/Toast';
import {
  containAzDchar,
  containAzDcharDot,
  containAzchar,
  min3char,
  mustBeValidUrl,
  mustHaveLetters,
  acceptedPhoneNumber,
  validatePhoneNumberKeyDown,
} from 'utils/formRules';
import { accountSettings, me } from 'utils/service';

export default function AccountDetails() {
  const [data, setData] = useState(JSON.parse(localStorage.getItem('userDetails')));
  const [imageName, setImageName] = useState('');
  const [preview_image, setpreview_image] = useState('');
  const [file, setFile] = useState('');
  const [loading, setLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [settingForm] = Form.useForm();

  useEffect(() => {
    getProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [uploadProfilePicture] = useUploadProfilePictureMutation();

  const onImageChange = (e) => {
    setFile(e.target.files[0]);
    let preview = URL.createObjectURL(e.target.files[0]);
    setpreview_image(preview);
    setImageName(e.target.files[0].name);
  };

  const SaveImage = async () => {
    setImageLoading(true);
    const payload = new FormData();
    payload.append('file', file);

    const allowedTypes = ['png', 'jpg', 'jpeg'];
    const fileExtensionArr = file.name.toLowerCase().split('.');
    const fileExtension = fileExtensionArr[fileExtensionArr.length - 1];

    if (!allowedTypes.includes(fileExtension)) {
      setImageLoading(false);
      setImageName('');
      showToastMessage({
        type: 'error',
        title: 'Error',
        description: 'Only PNG, JPG, JPEG file formats are allowed',
      });
      return;
    }

    let errObj = {};
    try {
      const data = await uploadProfilePicture(payload);
      console.log(data);

      if (data.error) {
        errObj = data.error;
        throw new Error(data);
      }

      getProfile();
      setImageLoading(false);
      setLoading(false);
      setImageName('');
      showToastMessage({
        type: 'success',
        title: 'Updated',
        description: 'Image Updated',
      });
    } catch (error) {
      console.log(errObj);
      if (errObj) {
        showToastMessage({
          type: 'error',
          title: 'Error',
          description: errObj.data.message,
        });
        setImageLoading(false);
        setImageName('');
      }
    }
  };

  const onFinishSetting = async (values) => {
    setLoading(true);
    try {
      const { data } = await accountSettings(values);
      if (data?.success) {
        setLoading(false);
        updateForm(data.data.data);
        showToastMessage({
          type: 'success',
          title: 'Update Successful',
          description: 'Account Details updated successfully',
        });
      }
    } catch (error) {
      setLoading(false);
      if (error.response?.data?.success === false) {
        showToastMessage({
          type: 'error',
          title: 'Error',
          description: error.response.data?.message,
        });
      }
    }
  };
  const onFinishFailedSetting = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const getProfile = async () => {
    let data = await me();
    if (data) {
      setData(data);
      updateForm(data?.user);
    }
  };

  const updateForm = (data) => {
    settingForm.setFieldsValue({
      fullname: data?.fullname,
      email: data?.email,
      phoneNumber: data?.phoneNumber,
      language: data?.language,
      companyName: data?.companyName,
      website: data?.website,
      city: data?.city,
      state: data?.state,
      officeAddress: data?.officeAddress ?? '',
    });
  };

  const validatePhoneNumber = async (_, value) => {
    const result = acceptedPhoneNumber(value);

    if (result.status) {
      return Promise.resolve();
    } else {
      return Promise.reject(result.message);
    }
  };

  return (
    <div className="mt-5 AccountSettingsContainer borderShade1">
      <div className="profileWrapper">
        <div className="relative">
          <img
            alt="profile"
            className="accountAvatar"
            src={preview_image ? preview_image : data?.user?.picture ?? '/assets/avatar.svg'}
          ></img>
          <label className="uploadPicture centered" htmlFor="file">
            <input
              dataTestId="file-input-testId"
              type="file"
              name="file"
              id="file"
              onChange={(e) => onImageChange(e)}
            />
            <EditFilled />
          </label>
        </div>

        {imageName && (
          <Button
            dataTestId="upload-image-button-testId"
            loading={imageLoading}
            className="mt-4 ml-2"
            type="primary"
            size="middle"
            onClick={SaveImage}
          >
            Upload Image
          </Button>
        )}
      </div>
      <Form
        name="basic"
        initialValues={{
          remember: true,
        }}
        form={settingForm}
        onFinish={onFinishSetting}
        onFinishFailed={onFinishFailedSetting}
      >
        <div className="grid2 inputLists">
          <div>
            <label>Full Name</label>
            <Form.Item
              name="fullname"
              rules={[
                {
                  required: true,
                  message: 'Please input your name!',
                },
                ...min3char,
                ...mustHaveLetters,
                ...containAzDchar,
              ]}
            >
              <Input placeholder="Jane Doe" className="form-control" />
            </Form.Item>
          </div>
          <div>
            <label>Email Address</label>
            <Tooltip
            placement="topLeft"
            title={'This field can only be viewed and cannot be edited'}
            color="blue"
          >
            <Form.Item
              name="email"
              
            >
              <Input placeholder="Janedoe@email.com" className="form-control" readOnly />
            </Form.Item>
          </Tooltip>
          </div>
          <div>
            <label>Phone Number</label>
            <Form.Item
              name="phoneNumber"
              rules={[
                {
                  required: true,
                  message: 'Please input your phone number!',
                },
                { validator: validatePhoneNumber },
              ]}
            >
              <Input
                placeholder="Enter a valid phone number"
                className="form-control"
                onKeyDown={validatePhoneNumberKeyDown}
              />
            </Form.Item>
          </div>
          <div>
            <label>Language</label>
            <Form.Item name="language">
              <Input placeholder="Enter your language" className="form-control" />
            </Form.Item>
          </div>
          <div>
            <label>Company Name</label>
            <Form.Item name="companyName" rules={[...min3char, ...containAzDcharDot, ...mustHaveLetters]}>
              <Input placeholder="Enter you company name" className="form-control" />
            </Form.Item>
          </div>
          <div>
            <label>Website</label>
            <Form.Item name="website" rules={mustBeValidUrl}>
              <Input placeholder="Enter your websit url" className="form-control" />
            </Form.Item>
          </div>
          <div>
            <label>City</label>
            <Form.Item name="city" rules={[...min3char, ...containAzchar]}>
              <Input placeholder="Enter your city" className="form-control" />
            </Form.Item>
          </div>
          <div>
            <label>State</label>
            <Form.Item name="state" rules={[...min3char, ...containAzchar]}>
              <Input placeholder="Enter your state" className="form-control" />
            </Form.Item>
          </div>
        </div>
        <div>
          <label>Office Address</label>
          <Form.Item name="officeAddress">
            <textarea cols="4" placeholder="Enter your address" className="form-control" />
          </Form.Item>
          <div className="mb-4 text-xs">
            Your contact details are safe with us. It will be automatically included in your email footers to ensure you
            are legally covered when sending bulk email.
          </div>
        </div>
        <div className="flex justify-end">
          <Button type="primary" size={'large'} htmlType="submit" loading={loading}>
            Save Changes
          </Button>
        </div>
      </Form>
    </div>
  );
}
