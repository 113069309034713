import { authorizationStep, trxType } from '../../../utils/constants';

import {
  SET_PLAN,
  SET_PRICE,
  SET_LOADING,
  SET_PLANS,
  SET_QUANTITY,
  SET_CARD,
  SET_CARDS,
  SET_MAKING_TRANSACTION,
  SET_RAW_CARD_DATA,
  SET_AUTHORIZATION_STEP,
  SET_TRX_REF,
  SET_TRX_STATUS,
  SET_DISPLAY_TEXT,
  SET_DEFAULT_STATE,
  SET_TRX_TYPE,
  SET_SAVE_CARD,
  SET_CURRENCY,
  SET_PAYG_RATES,
  SET_TOPUP_RATES,
} from './types';

export const reducer = (state, action) => {
  switch (action.type) {
    case SET_PLAN:
      return (state = {
        ...state,
        plan: action.payload,
      });
    case SET_PLANS:
      return (state = {
        ...state,
        plans: action.payload,
      });
    case SET_LOADING:
      return (state = {
        ...state,
        loading: action.payload,
      });
    case SET_PRICE:
      const { payload } = action;
      const price = payload;
      return (state = {
        ...state,
        price: price,
      });
    case SET_QUANTITY:
      return (state = {
        ...state,
        quantity: action.payload,
      });

    case SET_CARD:
      return (state = {
        ...state,
        card: action.payload,
      });

    case SET_RAW_CARD_DATA:
      return (state = {
        ...state,
        card_data: action.payload,
      });

    case SET_CARDS:
      return (state = {
        ...state,
        cards: action.payload,
      });

    case SET_MAKING_TRANSACTION:
      return (state = {
        ...state,
        making_transaction: action.payload,
      });
    case SET_AUTHORIZATION_STEP:
      return (state = {
        ...state,
        authorization_step: action.payload,
      });
    case SET_TRX_REF:
      return (state = {
        ...state,
        ongoing_trx_ref: action.payload,
      });

    case SET_TRX_STATUS:
      return (state = {
        ...state,
        ongoing_trx_status: action.payload,
      });
    case SET_DISPLAY_TEXT:
      return (state = {
        ...state,
        display_text: action.payload,
      });
    case SET_TRX_TYPE:
      return (state = {
        ...state,
        transaction_type: action.payload,
      });

    case SET_SAVE_CARD:
      return (state = {
        ...state,
        save_card: action.payload,
      });

    case SET_CURRENCY:
      return (state = {
        ...state,
        currency: action.payload,
      });

    case SET_PAYG_RATES:
      return (state = {
        ...state,
        payg_rates: action.payload,
      });

    case SET_TOPUP_RATES:
      return (state = {
        ...state,
        topup_rates: action.payload,
      });

    case SET_DEFAULT_STATE:
      return (state = {
        ...state,
        plan: { _id: null, payg: false, topup: false },
        price: 0.0,
        currency: 'USD',
        payg_rates: {},
        topup_rates: {},
        loading: false,
        plans: [],
        quantity: 0,
        card: {},
        card_data: {},
        authorization_code: '',
        cards: [],
        making_transaction: false,
        authorization_step: authorizationStep.none,
        ongoing_trx_ref: '',
        ongoing_trx_status: '',
        display_text: '',
        transaction_type: trxType.none,
        save_card: false,
      });
    default:
      return state;
  }
};
