import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { setAuthToken } from '../utils/service';
import { showToastMessage } from '../utils/Toast';
import { notification } from 'antd';

const useRequireAuth = (callback) => {
  const [userAuth, setUserAuth] = useState(undefined);
  const navigate = useNavigate();
  const location = useLocation();

  const token = Cookies.get('token');
  // If user is false that means we're not
  // logged in and should redirect.

  const clearUserData = () => {
    localStorage.removeItem('userDetails');
    Cookies.remove('token');
  };
  const isTokenExpired = (token) => {
    const authToken = token.split('.');
    const expiry = new Date(atob(authToken[1])).exp * 1000;
    const today = new Date();
    return today >= expiry;
  };

  useEffect(() => {
    const toastKey = Date.now().toString(20) + Math.random().toString(20).substring(2);

    if (!token) {
      showToastMessage({
        type: 'error',
        title: 'Error',
        description: 'Please, login 😊',
        key: toastKey,
      });
      setUserAuth(false);
      clearUserData();
      navigate('/login', { state: { from: location.pathname } });
    } else {
      isTokenExpired(token)
        ? (() => {
            showToastMessage({
              type: 'warning',
              title: 'Session',
              description: 'Session has expired. Please login',
              key: toastKey,
            });
            clearUserData();
            navigate('/login', { state: { from: location.pathname } });
          })()
        : setAuthToken(token);
      setUserAuth(true);
    }
    callback();
    /* eslint-disable */
    return () => {
      if (location.pathname === '/') {
        notification.destroy(toastKey);
      }
    };
  }, []);

  return userAuth;
};
export default useRequireAuth;
