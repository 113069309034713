import { Form, Input, Button } from 'antd';
import React, { useState } from 'react';
import { EmailSettings } from '../../utils/service';
import { showToastMessage } from '../../utils/Toast';
import { mustHaveLetters } from '../../utils/formRules';

export default function EmailConfig() {
  const [data] = useState(JSON.parse(localStorage.getItem('userDetails')));
  const [loading, setLoading] = useState(false);
  const [emailConfigForm] = Form.useForm();

  const emailConfig = ({ emailReplyTo, emailFrom }) => {
    setLoading(true);
    EmailSettings({
      emailReplyTo,
      emailFrom,
    })
      .then(() => {
        setLoading(false);
        showToastMessage({
          type: 'success',
          title: 'Updated',
          description: 'Email Settings Updated',
        });
      })
      .catch((error) => {
        setLoading(false);
        showToastMessage({
          type: 'error',
          title: 'Error',
          description: error.response?.data?.error,
        });
      });
  };

  const onFinishFailedSetting = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Form
      name="basic"
      initialValues={{
        remember: true,
      }}
      onFinish={emailConfig}
      onFinishFailed={onFinishFailedSetting}
      className="emailConfig mt-5"
    >
      <div>
        <label>From</label>
        <Form.Item
          name="emailFrom"
          form={emailConfigForm}
          initialValue={data?.emailSettings?.emailFrom}
          rules={[
            {
              required: true,
              message: 'This field is required!',
            },
            {
              pattern: new RegExp(/^[a-zA-Z- ]+$/i),
              message: 'Cannot contain numbers and special characters except -',
            },
            ...mustHaveLetters,
          ]}
        >
          <Input placeholder="Jane Doe" className="form-control" />
        </Form.Item>
      </div>
      <div>
        <label>Reply to</label>
        <Form.Item
          name="emailReplyTo"
          initialValue={data?.emailSettings?.emailReplyTo}
          rules={[
            {
              required: true,
              message: 'This field is required!',
            },
            {
              type: 'email',
              message: 'please input valid email address',
            },
          ]}
        >
          <Input placeholder="help@maildrip.com" className="form-control" />
        </Form.Item>
      </div>
      <div>
        <Button type="primary" size={'large'} htmlType="submit" loading={loading}>
          Save Changes
        </Button>
      </div>
    </Form>
  );
}
