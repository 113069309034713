import React from 'react';
import styles from '../../styles/formfield.module.scss';
import SelectComponent from './SelectComponent';

import { LoadingOutlined } from '@ant-design/icons';

const FormField = ({ selectContact, setSelectedContact, fetchingGroups, contactGroups }) => {
  // Event handler for select change
  const handleSelectContact = (option) => {
    const isSelect = selectContact?.find((select) => select?.id === option?._id);
    if (isSelect) {
      return;
    } else {
      setSelectedContact([...selectContact, { title: option?.title, id: option._id }]);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.inner_container}>
        <div className={styles.contact}>
          <p className={styles.text}>Add leads to contact group</p>
          <SelectComponent
            text="Linked contact group(s)"
            selectedValues={selectContact}
            handleSelectChange={handleSelectContact}
            options={contactGroups}
            loading={fetchingGroups && <LoadingOutlined />}
            setSelectedValues={setSelectedContact}
          />
        </div>
      </div>
    </div>
  );
};

export default FormField;
