import { Table } from 'antd';

const MdTable = ({
  scroll,
  sticky,
  rowKey,
  rowSelection,
  showHeader,
  onRow,
  columns,
  loading,
  dataSource,
  rowClassName,
  pagination = false,
}) => {
  return (
    <Table
      scroll={scroll}
      sticky={sticky}
      rowKey={rowKey}
      rowSelection={rowSelection}
      showHeader={showHeader}
      columns={columns}
      loading={loading}
      onRow={onRow}
      dataSource={dataSource}
      rowClassName={rowClassName}
      pagination={pagination}
      data-test="campaign-table"
    />
  );
};

export default MdTable;
