import React from 'react';
import styles from '../../styles/share.module.scss';
import party from 'asset/Icons/party.svg';

const Share = ({ link }) => {
  return (
    <div className={styles.container}>
      <div className={styles.inner_container}>
        <img src={party} alt="party" />
        <p className={styles.text}>Your opt-in page is ready</p>
        <div className={styles.clipbox}>
          <p className={styles.clipboard}>{link}</p>
        </div>
      </div>
    </div>
  );
};

export default Share;
