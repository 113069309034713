import DeleteModal from 'components/Modal/DeleteModal';

export const MdDeleteEmailFromCampaign = ({ id, handleModal, isModalVisible, isDeleting, handleDeleteEmail }) => {
  return (
    <DeleteModal
      open={isModalVisible}
      onCancel={() => handleModal({ id: '' })}
      deleteFunction={() => handleDeleteEmail(isDeleting.id)}
      header="email"
      description={
        isDeleting?.activeMail === 'deleted'
          ? 'This email will be deleted permanently, and you will not be able to retrieve it'
          : 'This email will be moved to the delete folder'
      }
      deleteButtonDataTestId={"delete-email-submit-btn"}
    />
  );
};