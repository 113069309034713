import React from 'react'

const InfoIcon = () => {
  return (
    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.25 12H9.5V9H8.75M9.5 6H9.5075M16.25 9C16.25 12.7279 13.2279 15.75 9.5 15.75C5.77208 15.75 2.75 12.7279 2.75 9C2.75 5.27208 5.77208 2.25 9.5 2.25C13.2279 2.25 16.25 5.27208 16.25 9Z"
        stroke="#1677FF"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default InfoIcon