import { Form, Input, Modal } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import dummyImage from '../../asset/Icons/dummy-profile.svg';
import attachment from '../../asset/images/Attachment-icon.svg';
import typeWriterHuman from '../../asset/images/banner_image.png';
import PageHeader from '../../components/Header/PageHeader';
import '../../styles/instantemails.scss';
import InstantEmailTableWrapper from './InstantEmailTableWrapper';

import {
  useAddInstantEmailIdMutation,
  useCloneInstantEmailMutation,
  useGetAllInstantEmailsQuery,
} from '../../redux/featureApi/instantEmailApiSlice';

import { MdGlobalButton, MdTypography } from 'components/global';

import { mustContainAtLeastOneChar } from 'utils/formRules';
import { showToastMessage } from '../../utils/Toast';

function InstantEmails() {
  const navigate = useNavigate();
  const [imVisible, setImVisible] = useState({ id: '' });
  const [emailDetails, setEmailDetails] = useState(null);
  const [status, setStatus] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [createNewInstantEmailForm] = Form.useForm();

  const [createInstantEmailService, { isLoading: isCreatingNewInstantEmail }] = useAddInstantEmailIdMutation();
  const [cloneInstantEMailService] = useCloneInstantEmailMutation();

  //get all instant emails with rtk
  const { isFetching: fetchingAllInstantEmails } = useGetAllInstantEmailsQuery({ status, search: searchTerm, page: 0 });

  const updateMailDetails = (details) => {
    setEmailDetails(details);
  };

  const updateImVisibility = (id) => {
    setImVisible(id);
  };

  const createInstantEmailFn = (instantEmailTitle) => {
    createInstantEmailService({ title: instantEmailTitle })
      .unwrap()
      .then((res) => {
        const instantEmailId = res?.data?.data?._id;
        navigate(`/instant-emails/drafts/${instantEmailId}`);
      })
      .catch((err) => {
        showToastMessage({
          type: 'error',
          title: 'Error',
          description: 'Oops! An error occured',
        });
      });
  };

  const cloneInstantEmailFn = () => {
    cloneInstantEMailService({ mailId: emailDetails?._id })
      .unwrap()
      .then((res) => {
        const instantEmailId = res?.data?.data?._id;
        // this consditional statement checks if it has an existing typeOfMail.
        if (res?.data?.data?.typeOfMail === 'speditor') {
          navigate(`/instant-emails/drafts/${instantEmailId}/speditor?action=$`);
        } else if (res?.data?.data?.typeOfMail === 'text-editor') {
          navigate(`/instant-emails/${instantEmailId}/text-editor?action`);
        }
      })
      .catch((err) => {
        showToastMessage({
          type: 'error',
          title: 'Duplicate Instant Email',
          description: 'An error occured, Please try again',
        });
      });
  };

  const CreateInstantEmailModal = () => {
    return (
      <Modal
        open={imVisible.id === 'creatingInstantEmailModal'}
        onCancel={() => setImVisible({ id: '' })}
        footer={null}
        className="emmaccen create-instantEmail-modal"
        width={'412px'}
        centered
      >
        <div>
          <MdTypography variant={'h5Medium'} className="text-center title">
            Create Instant Email
          </MdTypography>
          <div className="info-container">
            <div>
              <MdTypography className="modalText">
                This will be created as a new email, and not under any specific campaign. Please enter the title of the
                email you would like to create and proceed.
              </MdTypography>
            </div>
            <img src={typeWriterHuman} alt="" />
          </div>
          <div>
            <Form
              form={createNewInstantEmailForm}
              name="basic"
              onFinish={({ title }) => {
                createInstantEmailFn(title.replace(/^\s+/, ''));
              }}
              size="large"
              onFinishFailed={() => null}
            >
              <div className="email-title">
                <label className="mb-2 formLabel">Email title</label>
                <Form.Item
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: 'Please give your instant email a title',
                    },
                    ...mustContainAtLeastOneChar,
                  ]}
                >
                  <Input data-test="email-input" />
                </Form.Item>
              </div>

              <div className="centered">
                <MdGlobalButton
                  dataTestId="create-instant-email-submit"
                  id={'primary_lg'}
                  type="primary"
                  htmlType="submit"
                  loading={isCreatingNewInstantEmail}
                >
                  <MdTypography variant={'h6Medium'} style={{ color: '#ffffff' }}>
                    Proceed
                  </MdTypography>
                </MdGlobalButton>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    );
  };

  const createInstantEmailModalFn = () => {
    setImVisible({ id: 'creatingInstantEmailModal' });
  };

  const PreviewInstantEmailModal = () => {
    const infoImage = JSON.parse(localStorage.getItem('workspaceDetails'))
      ? JSON.parse(localStorage.getItem('workspaceDetails'))[0]?.picture
      : dummyImage;
    return (
      <Modal
        className="preview-instantEmail-modal"
        open={imVisible?.id === 'previewInstantEmailModal'}
        footer={null}
        width={'497px'}
        onCancel={() => setImVisible({ id: '' })}
        centered
      >
        <div className="preview-container">
          <div className="preview-header">
            <div className="info">
              <img src={infoImage || '/assets/avatar.svg'} alt="wokspace" />
              <div>
                <p className="color-dark">{emailDetails?.from}</p>
                <p className="color-light">from: {emailDetails?.to}</p>
              </div>
            </div>
            <div className="subject">
              <p className="color-light">Subject:</p>
              <p className="color-dark">{emailDetails?.subject}</p>
            </div>
          </div>
          <div className="preview-body" dangerouslySetInnerHTML={{ __html: emailDetails?.body }} />
          <div className="preview-footer">
            <img src={attachment} alt="Attachments" />
            <p>{emailDetails?.attachments.length} Attachment(s)</p>
          </div>
        </div>
      </Modal>
    );
  };

  const changeListView = async (val) => setStatus(val);

  return (
    <div className="h-full">
      {/* <DeleteModal /> */}
      <CreateInstantEmailModal />
      <PreviewInstantEmailModal />
      <>
        <div>
          <PageHeader
            title="Instant Emails"
            description="Create quick emails, schedule now or later"
            searchPlaceholder="Search Instant Emails"
            image={typeWriterHuman}
            loading={isCreatingNewInstantEmail}
            logic={createInstantEmailModalFn}
            placeholder="Search Instant Emails"
            searching={fetchingAllInstantEmails}
            onSearchCallback={(value) => setSearchTerm(value)}
          />
        </div>

        <InstantEmailTableWrapper
          status={status}
          searchTerm={searchTerm}
          emailDetails={emailDetails}
          cloneInstantEmailFn={cloneInstantEmailFn}
          updateMailDetails={updateMailDetails}
          updateImVisibility={updateImVisibility}
          changeListView={changeListView}
          tableHeight={'550px'}
        />
      </>
    </div>
  );
}

export default InstantEmails;
