import React, { useContext } from 'react';
import { joinCampaignContext } from '../../../contexts/joincampaign';

import { PlusOutlined } from '@ant-design/icons';
import { FormFieldItem } from './FormFieldsInputs';
import FormItem from './FormItem';

function FormFields({ onClickAddField }) {
  const { state } = useContext(joinCampaignContext);
  const { formFields } = state;
  if (!formFields || formFields.length < 1) {
    return <p></p>;
  }

  return (
    <div className="contactListMenu ">
      {formFields.map((item, index) => (
        <FormItem key={index} defaultChecked={item.enabled} disabled={item.enabled} title={item.name}>
          <FormFieldItem
            placeholder={item.placeholder}
            name={item.name}
            required={item.required}
            deletable={item.deletable}
            // enabled={item.enabled}
          />
        </FormItem>
      ))}
      <div
        onClick={onClickAddField}
        data-test="add-field"
        className="p-5 hover:bg-gray-100 w-full pointer border-2 border-dashed border-gray-200 mb-5 flex items-center justify-center space-x-2"
      >
        <PlusOutlined className="pr-2 text-blue-400" /> <p className="text-lg text-black">Add Field</p>
      </div>
    </div>
  );
}

export default FormFields;
