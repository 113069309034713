import { apiAuthSlice } from './apiAuthSlice';

export const optInPageApiSlice = apiAuthSlice.enhanceEndpoints({}).injectEndpoints({
  endpoints: (builder) => ({
    getOptInPage: builder.query({
      query: (search) => ({
        url: `/opt-in-pages?search=${search}`,
        method: 'GET',
      }),
      providesTags:['optInPage'],
    }),
    createOptInPageTitle: builder.mutation({
      query: ({ payload }) => ({
        url: `/opt-in-pages`,
        method: 'POST',
        body: { ...payload },
      }),
      invalidatesTags: ['optInPageList'],
    }),
    deleteOptInPage: builder.mutation({
      query: (pageId) => ({
        url: `/opt-in-pages/${pageId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['optInPageList'],
    }),

    togglepublishStatus: builder.mutation({
      query: ({ payload, optInPageId, type = 'publish' }) => ({
        url: `/opt-in-pages/${optInPageId}/${type}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ({ id }) => [{ type: 'optInPage', id }],
    }),
    addContactToOptInPage: builder.mutation({
      query: ({ payload, optInPageId }) => ({
        url: `/opt-in-pages/${optInPageId}`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ({ id }) => [{ type: 'optInPage', id }],
    }),
    getSpecificOptInPage: builder.query({
      query: (optInPageId) => ({
        url: `/opt-in-pages/${optInPageId}`,
        method: 'GET',
      }),
      // transformResponse: (response) => response?.data,
      providesTags: ['optInPageList'],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetOptInPageQuery,
  useGetSpecificOptInPageQuery,
  useCreateOptInPageTitleMutation,
  useDeleteOptInPageMutation,
  useTogglepublishStatusMutation,
  useAddContactToOptInPageMutation,
} = optInPageApiSlice;
