import React from 'react';

export const LaptopMan = () => {
  return (
    <svg width="161" height="152" viewBox="0 0 161 152" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_207_4030)">
        <path
          d="M6.91459 69.7705C7.80911 69.7705 8.53427 69.0454 8.53427 68.1508C8.53427 67.2562 7.80911 66.5311 6.91459 66.5311C6.02007 66.5311 5.29492 67.2562 5.29492 68.1508C5.29492 69.0454 6.02007 69.7705 6.91459 69.7705Z"
          stroke="#2C2C2C"
          strokeWidth="0.622951"
        />
        <path
          d="M10.0883 68.3157C13.3552 67.9794 18.4813 68.3771 19.0872 70.9041C19.6931 73.4312 17.3795 74.3554 16.147 74.5017C11.4545 75.2475 5.23242 74.8965 5.23242 70.9041"
          stroke="#2C2C2C"
          strokeWidth="0.622951"
          strokeLinecap="round"
        />
        <path
          d="M8.90893 72.1377C9.11535 72.1377 9.2827 71.9704 9.2827 71.7639C9.2827 71.5575 9.11535 71.3901 8.90893 71.3901C8.70249 71.3901 8.53516 71.5575 8.53516 71.7639C8.53516 71.9704 8.70249 72.1377 8.90893 72.1377Z"
          stroke="#2C2C2C"
          strokeWidth="0.622951"
        />
        <path
          d="M12.5205 71.7639C12.5205 71.9454 12.3514 72.1377 12.0845 72.1377C11.8176 72.1377 11.6484 71.9454 11.6484 71.7639C11.6484 71.5824 11.8176 71.3901 12.0845 71.3901C12.3514 71.3901 12.5205 71.5824 12.5205 71.7639Z"
          stroke="#2C2C2C"
          strokeWidth="0.622951"
        />
        <path
          d="M15.2624 72.1377C15.4689 72.1377 15.6362 71.9704 15.6362 71.7639C15.6362 71.5575 15.4689 71.3901 15.2624 71.3901C15.0561 71.3901 14.8887 71.5575 14.8887 71.7639C14.8887 71.9704 15.0561 72.1377 15.2624 72.1377Z"
          stroke="#2C2C2C"
          strokeWidth="0.622951"
        />
        <path
          d="M129.823 24.1703C130.649 24.1703 131.318 23.5009 131.318 22.6752C131.318 21.8495 130.649 21.1801 129.823 21.1801C128.997 21.1801 128.328 21.8495 128.328 22.6752C128.328 23.5009 128.997 24.1703 129.823 24.1703Z"
          stroke="#2C2C2C"
          strokeWidth="0.622951"
        />
        <path
          d="M132.76 21.3674C133.855 21.3674 136.986 21.3674 138.459 21.3674C139.651 21.3674 140.225 21.6313 140.225 23.4782C140.225 25.2727 140.21 27.5972 140.225 28.5354C140.235 29.1949 139.908 29.9249 138.811 30.0304C137.716 30.136 133.319 30.0744 131.257 30.0304C130.491 30.0304 129.137 29.5964 129.137 27.8317V25.8528"
          stroke="#2C2C2C"
          strokeWidth="0.622951"
          strokeLinecap="round"
        />
        <path d="M132.004 24.4821L134.745 26.3509" stroke="#2C2C2C" strokeWidth="0.622951" strokeLinecap="round" />
        <path d="M134.744 26.3507L139.728 21.7409" stroke="#2C2C2C" strokeWidth="0.622951" strokeLinecap="round" />
        <path
          d="M136.008 25.2292L137.37 26.5997L138.731 27.9702"
          stroke="#2C2C2C"
          strokeWidth="0.622951"
          strokeLinecap="round"
        />
        <path d="M131.006 27.9702L133.015 25.2292" stroke="#2C2C2C" strokeWidth="0.622951" strokeLinecap="round" />
        <path
          d="M140.78 117.611C140.367 118.025 140.009 118.383 139.764 118.629C139.122 119.271 138.449 118.897 138.193 118.629L134.104 114.689C133.499 114.127 132.988 113.573 130.707 111.396C130.473 111.162 130.103 110.565 130.492 110.046C130.879 109.528 133.061 106.808 134.104 105.512C134.419 105.054 135.107 104.509 135.982 105.324C136.737 106.026 139.325 108.455 141.535 110.302"
          stroke="#2C2C2C"
          strokeWidth="0.622951"
          strokeLinecap="round"
        />
        <path
          d="M134.059 105.652C134.187 108.016 135.054 111.82 139.541 113.252"
          stroke="#2C2C2C"
          strokeWidth="0.622951"
          strokeLinecap="round"
        />
        <path
          d="M145.435 113.435C145.386 113.877 145.355 114.341 145.362 114.736M145.566 112.708C145.519 112.981 145.472 113.095 145.435 113.435L145.566 112.708ZM147.39 116.104C147.215 116.634 146.078 117.766 143.978 117.737C141.878 117.708 141.235 115.856 141.168 114.994C141.106 114.205 141.412 112.686 142.529 111.737C143.654 110.78 145.085 110.754 146.353 111.737C147.405 112.551 147.403 113.796 147.216 114.447C147.104 114.833 146.683 115.213 145.903 115.675C145.514 115.906 145.377 115.428 145.362 114.736L147.39 116.104ZM145.435 113.435C145.229 112.912 143.651 112.304 143.054 113.435C142.438 114.602 142.909 115.585 143.309 115.777C143.82 116.023 144.57 116.152 145.362 114.736L145.435 113.435Z"
          stroke="#2C2C2C"
          strokeWidth="0.622951"
          strokeLinecap="round"
        />
        <path
          d="M34.8858 97.8745C34.4379 96.1453 32.9506 92.9563 30.5853 94.0342C28.22 95.1122 29.5997 97.0434 30.5853 97.8745C31.2485 98.3236 32.6743 98.8041 33.0715 97.1333C33.4687 95.4625 31.7949 94.7753 31.3244 95.5165C31.0764 95.8084 30.7962 96.5405 31.6604 97.1333"
          stroke="#2C2C2C"
          strokeWidth="0.622951"
          strokeLinecap="round"
        />
        <path
          d="M45.3228 12.8021C45.3161 12.7821 45.3184 12.7704 45.3209 12.763C45.3244 12.7534 45.332 12.7418 45.345 12.7324C45.3579 12.7229 45.3711 12.719 45.3814 12.7187C45.3892 12.7183 45.401 12.7197 45.4182 12.732L47.9595 14.5561C48.2006 14.7293 48.5258 14.7274 48.765 14.5515L51.2852 12.6984C51.3023 12.6859 51.314 12.6844 51.3218 12.6847C51.332 12.6849 51.3454 12.6886 51.3584 12.698C51.3714 12.7073 51.3793 12.7188 51.3827 12.7284C51.3855 12.7357 51.3879 12.7473 51.3814 12.7675L50.4319 15.7481C50.3417 16.0309 50.444 16.3396 50.6852 16.5127L50.8668 16.2596L50.6852 16.5127L53.2263 18.3369C53.2435 18.3493 53.2486 18.36 53.2508 18.3675C53.2537 18.3773 53.2542 18.3911 53.2494 18.4065C53.2445 18.4217 53.236 18.4326 53.228 18.439C53.2218 18.4438 53.2116 18.4497 53.1904 18.4498L50.0622 18.4678C49.7654 18.4694 49.5034 18.6621 49.4132 18.9449L48.4637 21.9255C48.4572 21.9457 48.4485 21.9538 48.4422 21.9581C48.4337 21.964 48.4207 21.9687 48.4047 21.9689C48.3886 21.969 48.3756 21.9643 48.3672 21.9586C48.3607 21.9543 48.3518 21.9462 48.3452 21.9261L47.3615 18.9566C47.2682 18.6748 47.004 18.4852 46.7071 18.4869L43.579 18.5049C43.5579 18.505 43.5474 18.4992 43.5413 18.4944C43.5331 18.4882 43.5246 18.4773 43.5195 18.4621C43.5145 18.4469 43.5149 18.4331 43.5176 18.4233C43.5199 18.4157 43.5247 18.4048 43.5418 18.3924L46.0619 16.5392C46.3011 16.3633 46.3998 16.0534 46.3064 15.7716L45.3228 12.8021Z"
          stroke="#2C2C2C"
          strokeWidth="0.622951"
        />
        <path d="M83.9121 69.7596V80.3498" stroke="#2C2C2C" strokeWidth="0.622951" strokeLinecap="round" />
        <path d="M91.8848 71.0055V81.0973" stroke="#2C2C2C" strokeWidth="0.622951" strokeLinecap="round" />
        <path
          d="M73.2342 116.866C70.6012 117.194 64.4374 118.403 63.0872 120.862C62.6822 121.599 62.2123 123.394 63.5732 124.673C64.9344 125.951 67.4214 125.616 68.4948 125.287L74.7532 122.767"
          stroke="#2C2C2C"
          strokeWidth="0.622951"
          strokeLinecap="round"
        />
        <path
          d="M109.115 98.3114C104.202 98.4696 82.5034 108.974 72.2681 114.207C71.3966 119.973 75.0746 124.113 77.0226 125.463C87.2249 122.501 108.164 116.498 110.304 116.182C112.443 115.866 114.563 114.339 115.355 113.615C120.902 107.138 116.808 101.636 114.068 99.6938C112.117 98.3115 110.433 98.269 109.115 98.3114Z"
          fill="#2C2C2C"
        />
        <path
          d="M63.097 96.8418C66.5779 97.074 81.763 102.959 88.9579 106.044L72.5033 114.266C65.1393 116.425 60.6155 115.714 59.2339 115.249C51.2593 111.407 54.3838 101.928 57.5522 98.4168C59.5943 96.6788 61.8497 96.7587 63.097 96.8418Z"
          fill="#2C2C2C"
        />
        <path
          d="M101.837 117.987C104.013 119.032 105.189 120.402 105.786 121.525C107.064 124.451 104.769 126.208 102.149 125.382C100.701 124.925 100.359 124.619 99.5923 123.983C99.2031 123.66 97.3856 121.972 95.3105 119.977"
          stroke="#2C2C2C"
          strokeWidth="0.622951"
          strokeLinecap="round"
        />
        <path
          d="M60.0625 68.0662L61.7329 98.4214C61.7692 99.082 62.3153 99.5989 62.9769 99.5989H109.578C110.266 99.5989 110.824 99.0411 110.824 98.353V67.347C110.824 66.6523 110.256 66.0919 109.561 66.1013L61.2897 66.752C60.5814 66.7615 60.0235 67.3589 60.0625 68.0662Z"
          fill="#2C2C2C"
        />
        <path
          d="M109.638 100.035H62.668C62.0143 100.035 61.4844 100.565 61.4844 101.219C61.4844 101.872 62.0143 102.402 62.668 102.402H109.638C110.292 102.402 110.822 101.872 110.822 101.219C110.822 100.565 110.292 100.035 109.638 100.035Z"
          fill="#2C2C2C"
        />
        <path
          d="M92.0098 52.9399C96.3496 52.8776 105.902 56.1792 109.702 66.5825"
          stroke="#2C2C2C"
          strokeWidth="0.622951"
          strokeLinecap="round"
        />
        <path
          d="M82.4786 52.8783C72.5737 54.8094 67.8393 59.0455 64.1016 67.2062"
          stroke="#2C2C2C"
          strokeWidth="0.622951"
          strokeLinecap="round"
        />
        <path d="M115.619 62.7308L118.049 58.6816" stroke="#2C2C2C" strokeWidth="0.622951" strokeLinecap="round" />
        <path d="M118.049 63.8526L121.35 61.1116" stroke="#2C2C2C" strokeWidth="0.622951" strokeLinecap="round" />
        <path
          d="M118.547 65.7214C119.191 65.9705 120.652 66.2073 121.35 65.1607"
          stroke="#2C2C2C"
          strokeWidth="0.622951"
          strokeLinecap="round"
        />
        <path d="M54.8197 61.973L51.082 58.1107" stroke="#2C2C2C" strokeWidth="0.622951" strokeLinecap="round" />
        <path d="M58.6196 61.3495L56.377 55.5561" stroke="#2C2C2C" strokeWidth="0.622951" strokeLinecap="round" />
        <path d="M53.8853 64.1533L51.082 63.0943" stroke="#2C2C2C" strokeWidth="0.622951" strokeLinecap="round" />
        <path
          d="M112.193 73.5703C114.498 77.1834 117.115 81.4817 112.193 89.5695"
          stroke="#2C2C2C"
          strokeWidth="0.622951"
          strokeLinecap="round"
        />
        <path
          d="M92.0719 53.2519C92.6325 51.6322 92.1217 50.5423 91.6234 50.3346C88.6332 53.0756 84.0981 52.0581 82.4161 50.3969C81.8181 50.9451 82.167 52.5666 82.4161 53.065C85.6555 56.6532 90.2653 54.643 92.0719 53.2519Z"
          fill="#2C2C2C"
        />
        <path
          d="M78.8027 63.2194L79.8032 61.6838C80.7782 60.1871 82.676 59.5896 84.3327 60.2576L86.7169 61.219C88.2044 61.8189 89.9088 61.4027 90.9526 60.185L93.6913 56.9899"
          stroke="#2C2C2C"
          strokeWidth="0.622951"
          strokeLinecap="round"
        />
        <path
          d="M59.3048 75.8651C58.3704 78.0662 57.2616 83.4028 60.3016 87.1405"
          stroke="#2C2C2C"
          strokeWidth="0.622951"
          strokeLinecap="round"
        />
        <path
          d="M100.662 27.7864C99.2911 32.7077 98.6059 33.1438 96.6124 35.3241C96.6124 33.1693 96.2423 29.5525 96.2423 28.0464C89.7151 28.0464 84.2065 25.3819 82.2681 24.0497C82.3376 28.5677 78.1019 31.5219 78.1019 31.5219C78.1019 31.5219 78.7094 33.3465 79.0567 37.4302C79.3344 40.6971 78.5359 40.0658 78.1019 39.3416C76.8521 35.3102 74.8035 36.3875 73.9355 37.4302C63.2847 20.1241 79.6642 16.7801 82.7888 17.0987C85.1496 15.0829 88.4422 13.5033 90.7567 13.2717C99.1042 12.436 102.884 19.8067 100.662 27.7864Z"
          fill="#2C2C2C"
        />
        <path
          d="M78.248 42.4215C79.3694 49.2672 86.8956 54.1897 92.3267 48.6443C97.7579 43.0988 96.6711 32.9482 96.0907 28.2115"
          stroke="#2C2C2C"
          strokeWidth="0.622951"
          strokeLinecap="round"
        />
        <path
          d="M73.813 37.0056C73.1277 38.6876 73.7706 42.4146 78.2484 42.4146"
          stroke="#2C2C2C"
          strokeWidth="0.622951"
          strokeLinecap="round"
        />
        <path
          d="M75.7872 37.9299C75.6881 38.2897 75.7674 39.1426 76.8772 39.6742"
          stroke="#2C2C2C"
          strokeWidth="0.622951"
          strokeLinecap="round"
        />
        <path
          d="M95.9395 41.5429C97.4009 40.9335 98.4313 38.6773 98.0348 35.0642"
          stroke="#2C2C2C"
          strokeWidth="0.622951"
          strokeLinecap="round"
        />
        <path
          d="M87.7168 45.6542C89.1496 46.028 89.059 44.9994 90.1463 44.0969"
          stroke="#2C2C2C"
          strokeWidth="0.622951"
          strokeLinecap="round"
        />
        <path
          d="M91.995 37.886C92.511 37.886 92.9294 37.4676 92.9294 36.9516C92.9294 36.4355 92.511 36.0172 91.995 36.0172C91.4789 36.0172 91.0605 36.4355 91.0605 36.9516C91.0605 37.4676 91.4789 37.886 91.995 37.886Z"
          fill="#2C2C2C"
        />
        <path
          d="M83.7704 38.0108C84.2864 38.0108 84.7048 37.5925 84.7048 37.0764C84.7048 36.5603 84.2864 36.142 83.7704 36.142C83.2543 36.142 82.8359 36.5603 82.8359 37.0764C82.8359 37.5925 83.2543 38.0108 83.7704 38.0108Z"
          fill="#2C2C2C"
        />
        <path
          d="M81.7363 34.9393C81.9329 34.3603 82.7814 33.2892 84.6019 33.6366"
          stroke="#2C2C2C"
          strokeWidth="0.622951"
          strokeLinecap="round"
        />
        <path
          d="M90.457 33.8185C90.8912 33.4423 92.0722 32.9158 93.3226 33.8185"
          stroke="#2C2C2C"
          strokeWidth="0.622951"
          strokeLinecap="round"
        />
        <path
          d="M87.4424 36.4346C87.2972 37.6648 87.1986 40.1595 87.9648 40.2969"
          stroke="#2C2C2C"
          strokeWidth="0.622951"
          strokeLinecap="round"
        />
        <path
          d="M123.344 92.944C139.977 97.5539 169.853 102.849 156.298 87.1505C152.021 82.9352 145.708 77.9932 135.554 73.6326"
          stroke="#2C2C2C"
          strokeWidth="0.622951"
          strokeLinecap="round"
        />
        <path
          d="M141.347 60.736C151.688 54.112 168.345 40.4279 152.248 38.6836C136.151 36.9393 121.454 42.9819 116.117 46.2213"
          stroke="#2C2C2C"
          strokeWidth="0.622951"
          strokeLinecap="round"
        />
        <path
          d="M101.292 51.0561C108.145 36.2921 119.233 5.69282 108.768 1.40692C95.6859 -3.95046 78.7416 11.4987 64.9121 48.3774"
          stroke="#2C2C2C"
          strokeWidth="0.622951"
          strokeLinecap="round"
        />
        <path
          d="M49.6709 38.338C34.3463 29.949 3.06167 17.0455 0.520026 32.5445C-2.02161 48.0436 29.0304 60.9719 44.8742 65.4986"
          stroke="#2C2C2C"
          strokeWidth="0.622951"
          strokeLinecap="round"
        />
        <path
          d="M48.9432 73.6326C26.5792 85.0326 -1.4424 100.799 14.0791 109.016C20.4331 112.38 36.6921 110.324 44.1675 107.272"
          stroke="#2C2C2C"
          strokeWidth="0.622951"
          strokeLinecap="round"
        />
        <path
          d="M85.5314 152C102.562 152 116.367 150.327 116.367 148.263C116.367 146.198 102.562 144.525 85.5314 144.525C68.5011 144.525 54.6953 146.198 54.6953 148.263C54.6953 150.327 68.5011 152 85.5314 152Z"
          fill="#2C2C2C"
        />
      </g>
      <defs>
        <clipPath id="clip0_207_4030">
          <rect width="160.098" height="152" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
